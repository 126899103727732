/* components */
/* 3rd party lib */
import React from "react";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faVideo } from "@fortawesome/pro-solid-svg-icons";
/* Util */

interface PostTypeIconProps {
  isVideo: boolean;
  className?: string;
  top?: string;
  right?: string;
  style?: any;
}

type Props = PostTypeIconProps;

const PostTypeIcon: React.FC<Props> = ({ isVideo, className, top, right, style }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Box
      className={className}
      sx={{
        zIndex: 2,
        background: "white",
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        display: "grid",
        placeItems: "center",
        position: "absolute",
        top: top ?? "4px",
        right: right ?? "4px",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.3)",
        ...style,
      }}
    >
      {isVideo ? <FontAwesomeIcon icon={faVideo} color={"#e75a5a"} /> : <FontAwesomeIcon icon={faImage} color={"#5ea9d1"} />}
    </Box>
  );
};

export default PostTypeIcon;
