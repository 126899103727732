import { Grid, Stack, Typography, TypographyVariant } from "@mui/material";
import React from "react";
import useMediaQueries from "src/hooks/use-mediaqueries";
/* components */
/* 3rd party lib */
/* Util */
interface DataDisplayProps {
  label: string;
  value: React.ReactNode;
  variant: TypographyVariant;
  alignCenter?: boolean;
  fontColor?: string;
  sameRow?: boolean;
  fontWeight?: number;
  labelGridWidth?: number;
  contentGridWidth?: number;
}

type Props = DataDisplayProps;

const DataDisplay: React.FC<Props> = ({
  label,
  value,
  variant,
  fontWeight = 500,
  labelGridWidth = 3,
  contentGridWidth = 9,
  alignCenter = true,
  fontColor,
  sameRow = false,
}) => {
  const { smDown } = useMediaQueries();

  return (
    <Grid container>
      <Grid
        item
        xs={sameRow ? 2 : 12}
        // xs={3.3}
        sm={labelGridWidth}
        // md={3}
        lg={labelGridWidth}
        sx={{
          xs: { mb: 1 },
          whiteSpace: "pre-line",
          display: "flex",
          alignItems: alignCenter ? "center" : "flex-start",
        }}
      >
        <Stack direction="row" alignItems="center">
          {typeof label === "string" ? (
            <Typography
              variant={variant ? variant : smDown ? "body1" : "body2"}
              fontWeight={fontWeight}
              // color={fontColor ? fontColor : (theme.palette.neutral as any)[500]}
              sx={{ paddingTop: alignCenter ? 0 : "8px", pb: smDown ? 0.5 : 0 }}
            >
              {label}
            </Typography>
          ) : (
            label
          )}
          &nbsp;
        </Stack>
      </Grid>
      <Grid
        item
        xs={sameRow ? 10 : 12}
        sm={contentGridWidth}
        lg={contentGridWidth}
        sx={{ display: "flex", alignItems: alignCenter ? "center" : "flex-start" }}
      >
        {typeof value === "string" ? (
          <Typography variant={variant ? variant : "body2"} fontWeight={400}>
            {value !== "" && value !== null && value !== undefined && value.toLowerCase() !== "Invalid Date".toLowerCase() ? value : "-"}
          </Typography>
        ) : (
          <>{value && value !== "invalid date" ? value : "-"}</>
        )}
      </Grid>
    </Grid>
  );
};
export default DataDisplay;
