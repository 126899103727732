import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
/* components */
/* 3rd party lib */
/* Util */
interface AddAccountButtonProps {
  handleAction: () => void;
  viewMode: "table" | "grid";
}

type Props = AddAccountButtonProps;

const AddAccountButton: React.FC<Props> = ({ viewMode, handleAction }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Button
        className="step-1"
        variant="contained"
        sx={{
          width: "100%",
          display: "grid",
          height: viewMode === "table" ? "70px" : "100%",
          p: "7px",
          minHeight: viewMode === "grid" ? "100px" : "unset",
          borderRadius: "8px",
          placeItems: "center",
          cursor: "pointer",
          userSelect: "none",
        }}
        onClick={handleAction}
      >
        <Stack spacing={viewMode === "table" ? 1 : 0} alignItems="center" direction={viewMode === "table" ? "row" : "column"}>
          <FontAwesomeIcon icon={faPlus} size={viewMode === "table" ? "xl" : "3x"} />
          <Typography variant="body2">Click to Track New Account</Typography>
        </Stack>
      </Button>
    </>
  );
};

export default AddAccountButton;
