import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { InputAdornment, Input, IconButton } from "@mui/material";
import { getTranslateString } from "src/utils/translate";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { SEARCH } from "src/constants/translate-keys/common";

interface SearchBarProps {
  autoFocus?: boolean;
  placeholder: string;
  onSearch: (value: string) => void;
}

type Props = SearchBarProps;

const SearchBar: React.FC<Props> = props => {
  const { autoFocus = false } = props;
  const { t } = useTranslation();
  const [term, setTerm] = useState("");
  const { placeholder = getTranslateString(t, SEARCH), onSearch } = props;

  const handleClearClick = () => {
    setTerm("");
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      onSearch(term);
      // make a request after 1 second since there's no typing
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [term, onSearch]);

  return (
    <Input
      fullWidth
      value={term}
      disableUnderline
      autoFocus={autoFocus}
      placeholder={placeholder}
      sx={{ fontSize: "14px" }}
      onChange={e => {
        setTerm(e.target.value);
      }}
      endAdornment={
        <>
          {term !== "" && (
            <IconButton onClick={handleClearClick}>
              <HighlightOffIcon />
            </IconButton>
          )}
        </>
      }
      startAdornment={
        <InputAdornment position="start">
          <SearchRoundedIcon fontSize="small" />
        </InputAdornment>
      }
    />
  );
};

export default SearchBar;
