import React, { useEffect, useState } from "react";
/* components */
/* 3rd party lib */
import useSWR from "swr";
import moment from "moment";
import { useParams } from "react-router-dom";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { apiEndpoint } from "src/contexts/http-context";
import { ISubscriptionCreditData, TSubscriptionCredit } from "src/types/common";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Tab, Tabs, Theme, Typography, useTheme } from "@mui/material";
/* Util */
import useBackground from "src/hooks/use-background";
import NoDataFound from "src/components/common/feedback/no-data-found";
import SpinnerComponent from "src/components/common/feedback/spinner";
import MobilePagination from "src/components/common/navigation/mobile-pagination";
import BalanceProgress from "src/components/common/data-display/credit-balance";

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  target: string;
  theme: Theme;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, theme, target, ...other } = props;

  const background = useBackground({});

  return (
    <div
      role="tabpanel"
      hidden={value !== target}
      id={`simple-tabpanel-${target}`}
      aria-labelledby={`simple-tab-${target}`}
      {...other}
      style={{
        background: background,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {value === target && <Box sx={{ p: 1, width: "100%", minWidth: "300px" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface CreditBalanceDialogProps {
  open: boolean;
  onClose: () => void;
}

export type TSubscriptionType = "ongoing" | "expired";

type Props = CreditBalanceDialogProps;

const CreditBalanceDialog: React.FC<Props> = ({ open, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const params = useParams();
  const { user_id } = params;
  const { mdDown } = useMediaQueries();
  const [currentPage, setCurrentPage] = useState(1);
  const [tab, setTab] = useState<TSubscriptionType>("ongoing");
  const { data, mutate } = useSWR<ISubscriptionCreditData>([
    user_id && `${apiEndpoint.SUBSCRIPTION_ACCOUNT_CREDIT(user_id)}?page=${currentPage}&active=${tab === "ongoing" ? true : false}`,
  ]);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleChange = (event: React.SyntheticEvent, newValue: TSubscriptionType) => {
    // setValue(newValue);
    setTab(newValue);
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (open) {
      mutate();
    }
  }, [open, mutate]);
  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog maxWidth="sm" fullWidth={mdDown} open={open} onClose={onClose}>
      <DialogTitle sx={{ p: 2 }}>
        <Stack direction="row" spacing={3}>
          <Typography variant="inherit" sx={{ flexGrow: 1 }}>
            View Credit Balance
          </Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ overflowX: "hidden" }}>
        <Stack sx={{ py: 2, width: mdDown ? "auto" : "100%" }} alignItems="center">
          <Stack alignItems="center">
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={tab}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
              sx={{ pb: 1 }}
            >
              <Tab
                {...a11yProps(0)}
                value={"ongoing"}
                label={
                  <Stack direction="row" alignItems={"center"} spacing={1}>
                    <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
                      Ongoing
                    </Typography>
                  </Stack>
                }
              />
              <Tab
                {...a11yProps(1)}
                value={"expired"}
                label={
                  <Stack direction="row" alignItems={"center"} spacing={1}>
                    <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
                      Expired
                    </Typography>
                  </Stack>
                }
              />
            </Tabs>
          </Stack>

          <CustomTabPanel theme={theme} value={tab} target={"ongoing"}>
            <Stack spacing={1}>
              {data ? (
                data.count === 0 ? (
                  <NoDataFound image />
                ) : (
                  <>
                    {data.results.map(child => (
                      <CreditBalanceCard theme={theme} tab={tab} key={child.id} credit={child} />
                    ))}
                    {data.count > 0 && (
                      <MobilePagination
                        count={data.count}
                        page={currentPage}
                        limit={data.limit}
                        totalPage={data.total_pages}
                        onPageChange={(e, page) => setCurrentPage(page + 1)}
                      />
                    )}
                  </>
                )
              ) : (
                <SpinnerComponent modifier={1.2} />
              )}
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel theme={theme} value={tab} target={"expired"}>
            <Stack spacing={1}>
              {data ? (
                data.count === 0 ? (
                  <NoDataFound image />
                ) : (
                  <>
                    {data.results.map(child => (
                      <CreditBalanceCard theme={theme} tab={tab} key={child.id} credit={child} />
                    ))}
                    {data.count > 0 && (
                      <MobilePagination
                        count={data.count}
                        page={currentPage}
                        limit={data.limit}
                        totalPage={data.total_pages}
                        onPageChange={(e, page) => setCurrentPage(page + 1)}
                      />
                    )}
                  </>
                )
              ) : (
                <SpinnerComponent modifier={1.2} />
              )}
            </Stack>
          </CustomTabPanel>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CreditBalanceDialog;

const CreditBalanceCard = ({ theme, tab, credit }: { theme: Theme; tab: TSubscriptionType; credit: TSubscriptionCredit }) => {
  return (
    <Paper sx={{ p: 2, opacity: tab === "expired" ? 0.6 : 1 }}>
      <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <BalanceProgress
            balance={credit.current_credit}
            total={credit.initial_credit}
            color={tab === "ongoing" ? theme.palette.primary.main : (theme.palette as any).neutral[300]}
          />
          <Stack>
            {/* <Typography variant="body1" color="text.secondary">
              Credit balance: {credit.current_credit}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Type: {credit.type}
            </Typography> */}
            <Typography variant="body1" fontWeight={400} color={"neutral.400"}>
              <span style={{ fontSize: "14px" }}>Expired Date:</span> <br />
            </Typography>
            <Typography variant="h6" fontWeight={500} color={tab === "ongoing" ? "text.secondary" : "error.light"}>
              {moment(credit.expired_date).format("DD/MM/YYYY")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};
