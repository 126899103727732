import React, { useState } from "react";
import MobileViewListSortDialog from "src/components/common/feedback/mobile-view-list-sort-dialog";
import NoDataFound from "src/components/common/feedback/no-data-found";
import _ from "lodash";
import {
  Box,
  Checkbox,
  Grid,
  // IconButton,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  // Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { /* faEllipsis, */ IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { getDynamicIndex } from "src/utils/general";
import { /*  Link, */ useNavigate } from "react-router-dom";
/* components */
/* 3rd party lib */
/* Util */

type TAnchorEl = Element | ((element: Element) => Element) | null | undefined;

type TRowAction = {
  icon: IconDefinition;
  label: string;
  onClick: (rowData: any) => void;
  toolTip: () => void;
  navigation?: { url: (rowData: any) => void };
};

export interface IListMetaData {
  primary: any;
  secondary: any;
  content: any;
  sortFields: { key: string; label: string }[];
  fields: { path: string; content: any; id: string; gridProps: any; label: string }[];
}

interface PaginationListProps {
  page: number;
  rowsPerPage: number;
  checkboxSelection: boolean;
  data: any;
  dataIndicator?: "id";
  metadata: IListMetaData;
  onSelect?: any;
  onSort: (key: any) => void;
  rowAction?: (rowData: any) => void;
  rowActions?: TRowAction[];
  selected?: any[];
  sort: { [key: string]: string };
  rowClick: boolean;
  listItemStyle?: any;
}

type Props = PaginationListProps;

const PaginationList: React.FC<Props> = props => {
  const {
    page,
    rowAction,
    rowsPerPage,
    checkboxSelection,
    data,
    dataIndicator = "id",
    metadata,
    onSelect,
    onSort,
    rowActions = [],
    selected = [],
    sort,
    rowClick,
    listItemStyle = {},
  } = props;

  const theme = useTheme();
  const { mode } = theme.palette;
  const navigate = useNavigate();
  const { smDown, mdDown } = useMediaQueries();
  const { content, fields = [], primary, secondary, sortFields } = metadata;
  const [selectedRows, setSelectedRows] = useState(!Array.isArray(selected) && !!selected ? [selected] : selected);
  const [sortDialog, setSortDialog] = useState(false);
  const [moreActionsMenu, setMoreActionsMenu] = useState<{ anchorEl: TAnchorEl | null; rowData: any | null }>({ anchorEl: null, rowData: null });

  const handleSelectRow = (data: any) => {
    if (checkboxSelection) {
      const clonedSelectedRows = [...selectedRows];
      const index = clonedSelectedRows.findIndex(row => row[dataIndicator] === data[dataIndicator]);

      if (index !== -1) {
        clonedSelectedRows.splice(index, 1);
      } else {
        clonedSelectedRows.push(data);
      }

      setSelectedRows(clonedSelectedRows);
      onSelect(clonedSelectedRows);
    } else {
      onSelect(data);
    }
  };

  // const renderActions = (rowData: any) => {
  //   const renderSingleAction = (action: any) => {
  //     const content = action.navigation ? (
  //       <Link to={action.navigation.url(rowData)}>
  //         <IconButton component="a">
  //           <FontAwesomeIcon icon={action.icon} size="xs" />
  //         </IconButton>
  //       </Link>
  //     ) : (
  //       <IconButton onClick={() => action.onClick(rowData)} {...action.buttonProps}>
  //         <FontAwesomeIcon icon={action.icon} size="xs" />
  //       </IconButton>
  //     );

  //     return action.tooltip ? (
  //       <Tooltip arrow title={action.tooltip(rowData)}>
  //         <span>{content}</span>
  //       </Tooltip>
  //     ) : (
  //       content
  //     );
  //   };

  //   return rowActions.length > 1 ? (
  //     <IconButton onClick={e => setMoreActionsMenu({ anchorEl: e.currentTarget, rowData })}>
  //       <FontAwesomeIcon icon={faEllipsis} />
  //     </IconButton>
  //   ) : (
  //     renderSingleAction(rowActions[0])
  //   );
  // };

  return (
    <>
      <MuiList disablePadding>
        {data &&
          data.map((d: any, index: number) => {
            // const props = {
            //   key: d[dataIndicator] || index,
            //   divider: index + 1 !== data.length,
            //   secondaryAction: !_.isEmpty(rowActions) && renderActions(d),
            //   selected: selected,
            //   sx: { minHeight: 64 },
            // };
            const localSelected = selectedRows ? selectedRows.findIndex(row => row[dataIndicator] === d[dataIndicator]) !== -1 : false;
            const listContent = content ? (
              content(d, index, page, rowsPerPage)
            ) : (
              <>
                <Box>
                  {primary && (
                    <>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography color="text.secondary" variant="body1">
                          {getDynamicIndex(page - 1, rowsPerPage, index + 1)}.
                        </Typography>

                        <Typography sx={{ pb: 0.5 }} color="text.secondary" component="div" fontWeight={500} variant={smDown ? "body1" : "h6"}>
                          {primary.content ? primary.content(d) : _.get(d, primary.path)}
                        </Typography>
                      </Stack>
                    </>
                  )}
                  {secondary && (
                    <Typography color="textSecondary" component="div" variant="caption" fontStyle="italic">
                      {secondary.content ? secondary.content(d) : _.get(d, secondary.path)}
                    </Typography>
                  )}
                </Box>
                {!_.isEmpty(fields) && (
                  <Paper
                    variant={mdDown ? "elevation" : "outlined"}
                    elevation={0}
                    sx={{ mt: mdDown ? 0 : 1, px: 1, background: "none" }}
                    onClick={() => {
                      if (rowClick) {
                        if (rowAction) {
                          rowAction(d);
                        }

                        // allow user to click on the row in the list to redirect
                        if (rowActions) {
                          if (rowActions.length === 1) {
                            rowActions.forEach(child => {
                              if (Object.keys(child).includes("navigation") && child.navigation) {
                                navigate(`${child.navigation.url(d)}`);
                              } else {
                                child.onClick(d);
                              }
                            });
                          } else if (rowActions.length > 1) {
                            if (Object.keys(rowActions[0]).includes("navigation")) {
                              navigate(`${rowActions[0].navigation?.url(d)}`);
                            } else {
                              rowActions[0].onClick(d);
                            }
                          }
                        }
                      }
                    }}
                  >
                    <Box pl={mdDown ? 0 : 2} pr={2} py={1}>
                      <Grid container spacing={mdDown ? 0.5 : 2}>
                        {fields.map((field, fieldIndex) => {
                          const { path, content, id, gridProps, label } = field;
                          let fieldContent = null;
                          if (content) {
                            if (typeof content(d) === "string") {
                              fieldContent = content(d) !== "" ? content(d) : "-";
                            } else {
                              fieldContent = content(d);
                            }
                          } else {
                            fieldContent = _.get(d, path);
                          }
                          return (
                            <Grid key={id || fieldIndex} item xs={12} sm={12} md={4} {...gridProps}>
                              <Stack
                                alignItems={mdDown ? "flex-start" : "initial"}
                                direction={mdDown ? "row" : "column"}
                                spacing={mdDown ? 1 : 0}
                                sx={{ wordBreak: "break-word" }}
                              >
                                <Typography
                                  color="textSecondary"
                                  component="div"
                                  fontWeight={600}
                                  variant={smDown ? "caption" : "body2"}
                                  sx={{ whiteSpace: "nowrap" }}
                                >
                                  {label} {mdDown && ":"}
                                </Typography>
                                <Typography component="div" fontWeight={500} variant={smDown ? "caption" : "body2"}>
                                  {fieldContent || "-"}
                                </Typography>
                              </Stack>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Paper>
                )}
              </>
            );

            return checkboxSelection || onSelect ? (
              <ListItemButton onClick={() => handleSelectRow(d)}>
                {checkboxSelection && (
                  <ListItemIcon>
                    <Checkbox edge="start" checked={localSelected} disableRipple size="small" />
                  </ListItemIcon>
                )}
                {listContent}
              </ListItemButton>
            ) : (
              <ListItem
                key={`listitem_${index}`}
                sx={{
                  ...listItemStyle,
                  "&:nth-of-type(odd)": {
                    backgroundColor: (theme.palette.neutral as any)[mode === "dark" ? 800 : 100],
                  },
                  cursor: rowClick && rowAction ? "pointer" : "default",
                  "&:hover": {
                    transition: "all 0.5s ease",
                    background: rowClick && rowAction && (theme.palette.neutral as any)[mode === "dark" ? 1000 : 300],
                  },
                }}
              >
                <ListItemText>{listContent}</ListItemText>
              </ListItem>
            );
          })}
      </MuiList>

      {data && (
        <>
          {data.length === 0 && (
            <Box padding={2}>
              <NoDataFound image />
            </Box>
          )}
        </>
      )}
      {sort !== undefined && (
        <MobileViewListSortDialog open={sortDialog} onClose={() => setSortDialog(false)} sort={sort} sortFields={sortFields} onSort={onSort} />
      )}

      <MoreActionsMenu
        anchorEl={moreActionsMenu.anchorEl}
        onClose={() => setMoreActionsMenu({ anchorEl: null, rowData: null })}
        rowActions={rowActions}
        rowData={moreActionsMenu.rowData}
        moreActionsMenu={moreActionsMenu}
        setMoreActionsMenu={setMoreActionsMenu}
      />
    </>
  );
};

export default PaginationList;

interface MoreActionsMenuProps {
  onClose: () => void;
  anchorEl: any;
  rowActions: TRowAction[];
  rowData: any;
  moreActionsMenu: {
    anchorEl: TAnchorEl | null;
    rowData: any | null;
  };
  setMoreActionsMenu: React.Dispatch<
    React.SetStateAction<{
      anchorEl: TAnchorEl | null;
      rowData: any | null;
    }>
  >;
}

const MoreActionsMenu: React.FC<MoreActionsMenuProps> = props => {
  const { onClose, anchorEl, rowActions, rowData, moreActionsMenu, setMoreActionsMenu } = props;
  // const router = useRouter();
  const navigate = useNavigate();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      onClose={onClose}
      open={anchorEl !== null}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuList disablePadding>
        {rowActions.map((action: TRowAction, index: number) => (
          <MenuItem
            divider={rowActions.length !== index + 1}
            key={index}
            onClick={() => {
              if (action.navigation) {
                navigate(`${action.navigation.url(rowData)}`);
              } else {
                action.onClick(rowData);
                setMoreActionsMenu({ ...moreActionsMenu, anchorEl: null }); //close the menu
              }
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={action.icon} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontWeight: 600, variant: "body2" }}>{action.label}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
