import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
  // MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
  MRT_FullScreenToggleButton as MRTFullScreenToggleButton,
  MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
  MRT_DensityState as MRTDensityState,
  MRT_ColumnPinningState as MRTColumnPinningState,
  MRT_ColumnDef as MRTColumnDef,
  MRT_RowSelectionState as MRTRowSelectionState,
} from "material-react-table";
import { useTranslation } from "react-i18next";
import useVersion from "src/hooks/use-version";
import { getTranslateString } from "src/utils/translate";
import { faEllipsis } from "@fortawesome/pro-regular-svg-icons";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreActionsMenu, { TMoreActionsMenu } from "src/components/common/data-display/more-actions-menu";
import { BUTTON_CLEAR_FILTERS, BUTTON_CLEAR_SORTING } from "src/constants/translate-keys/common";
import { Badge, Box, Button, Grid, IconButton, Stack, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const showActionWhenHovered = (oriPixel = 8, hoveredPixel = 0) => ({
  "& .Mui-TableHeadCell-Content": {
    position: "relative !important",
    height: "30px",
    transform: `translateY(${oriPixel}px)`,
    ".Mui-TableHeadCell-Content-Actions": {
      "& .MuiIconButton-root:first-of-type": {
        display: "none",
      },
      "& .MuiIconButton-root:nth-of-type(2)": {
        opacity: "0",
      },
    },
    "&:hover": {
      transform: `translateY(${hoveredPixel}px)`,
      ".Mui-TableHeadCell-Content-Actions": {
        "& .MuiIconButton-root:first-of-type": {
          display: "inline-flex",
        },
        "& .MuiIconButton-root:nth-of-type(2)": {
          opacity: "0.6",
          "&:hover": {
            opacity: "1",
          },
        },
      },
    },
  },
});

interface DndTableProps {
  id: any;
  data: any;
  error: any;
  page: any;
  count: any;
  isLoading?: any;
  rowAction: any;
  rowActions: any;
  rowClick?: boolean;
  rowsPerPage: number;
  onPageChange: any;
  handleSearch: any;
  resetFilters: () => void;
  handleSort: (values: any) => void;
  handleFilter: (values: any) => void;
  columnOrder: any;
  columnVisibility?: any;
  extremeCompact: boolean;
  showGlobalFilter: boolean;
  disableAdvance?: boolean;
  columns: MRTColumnDef<{}>[];
  enableStickyHeader?: boolean;
  customActions?: React.ReactNode;
  columnPinning?: MRTColumnPinningState | undefined;
  rowSelectKey?: string;
  onRowSelect?: (key: any) => void;
  rowSelection?: MRTRowSelectionState;
  anchorRedirect?: (rowData: any) => void;
}

type Props = DndTableProps;

const DNDTable: React.FC<Props> = ({
  id,
  data,
  error,
  page,
  count,
  columns,
  rowAction,
  rowActions,
  rowsPerPage,
  onPageChange,
  handleSort,
  handleSearch,
  handleFilter,
  resetFilters,
  columnOrder,
  rowSelectKey,
  onRowSelect,
  isLoading,
  rowClick = false,
  rowSelection = {},
  columnVisibility = {},
  columnPinning = null,
  extremeCompact = false,
  enableStickyHeader = false,
  showGlobalFilter = true,
  disableAdvance = false,
  customActions = null,
}) => {
  const localStorageKey = "tableConfig";
  const storedConfigJSON = localStorage.getItem(localStorageKey);
  // Parse the storedConfigJSON and extract relevant values
  const parsedStoredConfig = storedConfigJSON && JSON.parse(storedConfigJSON)[id] ? JSON.parse(storedConfigJSON)[id] : {};
  const selectedCount = rowSelection && Object.values(rowSelection).filter(child => child === true).length;

  // Initialize state variables with default values if necessary
  const defaultColumnSizing = parsedStoredConfig.column_sizing || {};
  const defaultColumnPinning = parsedStoredConfig.column_pinning || (columnPinning ? columnPinning : { right: [], left: [] });
  const defaultColumnOrder = parsedStoredConfig.column_order || columnOrder;
  const defaultColumnVisibility = parsedStoredConfig.column_visibility || columnVisibility;

  const theme = useTheme();
  const { mode } = theme.palette;
  const { t } = useTranslation();
  const version = useVersion();
  const navigate = useNavigate();
  const globalFilterRef = useRef<string | null>(null);
  const [sorting, setSorting] = useState([]);

  const [density, setDensity] = useState<MRTDensityState | undefined>("compact");
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  //optionally, you can manage the row selection state yourself

  const [localColumnSizing, setLocalColumnSizing] = useState(defaultColumnSizing);
  const [localColumnPinning, setLocalColumnPinning] = useState<MRTColumnPinningState | undefined>(defaultColumnPinning);
  const [localColumnOrder, setLocalColumnOrder] = useState(defaultColumnOrder);
  const [localColumnVisibility, setLocalColumnVisibility] = useState(defaultColumnVisibility);

  const [moreActionsMenu, setMoreActionsMenu] = useState<TMoreActionsMenu>({
    anchorEl: null,
    rowData: null,
  });

  const enableAdvanceTableFeature = process.env.REACT_APP_ADVANCE_TABLE_FEATURE === "true" && !disableAdvance ? true : false;
  // const enableAdvanceTableFeature = false;

  const rowActionsExist = rowActions && !_.isEmpty(rowActions) && rowActions.length > 0;
  /* 
  We retrieve the stored configuration from localStorage and parse it as JSON. If there is no stored configuration, we initialize it as an empty object.
  Then, we check if the given id exists in the stored configuration and if it has a valid column_order property. We ensure that the column_order is an array and has a length greater than 0.
  If the conditions are met, we set the localColumnState with the stored column value. Otherwise, we set it with the default column value.
  */
  useEffect(() => {
    const localStorageKey = "tableConfig";
    const storedConfigJSON = localStorage.getItem(localStorageKey);

    if (storedConfigJSON) {
      const storedConfig = JSON.parse(storedConfigJSON);

      if (storedConfig && id in storedConfig) {
        const { column_order, column_visibility, column_pinning, column_sizing } = storedConfig[id];

        if (Array.isArray(column_order) && column_order.length > 0) {
          setLocalColumnOrder(column_order);
        }

        if (column_visibility) {
          setLocalColumnVisibility(column_visibility);
        }

        if (column_pinning) {
          setLocalColumnPinning(column_pinning);
        }

        if (column_sizing) {
          setLocalColumnSizing(column_sizing);
        }
      }
    }
  }, [id]);

  /* 
  We start by retrieving the stored configuration from localStorage and parsing it as JSON. If there is no stored configuration, we initialize it as an empty object.
  Next, we create the updated configuration by spreading the stored configuration, and then updating the specific column_order property for the given id.
  Finally, we save the updated configuration back to localStorage.
  */
  useEffect(() => {
    if (id === undefined) return;
    const tableConfigKey = "tableConfig";
    const storedConfigJSON = localStorage.getItem(tableConfigKey);

    let storedConfig = {};
    if (storedConfigJSON) {
      storedConfig = JSON.parse(storedConfigJSON);
    }

    const updatedConfig = {
      ...storedConfig,
      [id]: {
        ...(storedConfig as any)[id],
        column_order: localColumnOrder,
        column_sizing: localColumnSizing,
        column_pinning: localColumnPinning,
        column_visibility: localColumnVisibility,
      },
    };

    localStorage.setItem(tableConfigKey, JSON.stringify(updatedConfig));
  }, [id, version, localColumnOrder, localColumnSizing, localColumnVisibility, localColumnPinning]);

  useEffect(() => {
    handleSort(sorting);
  }, [handleSort, sorting]);

  useEffect(() => {
    if (globalFilterRef.current !== globalFilter) {
      globalFilterRef.current = globalFilter;
      handleSearch(globalFilterRef.current);
    }
  }, [handleSearch, globalFilter]);

  useEffect(() => {
    let filtersObj: { [key: string]: string } = {};
    if (columnFilters.length > 0) {
      columnFilters.forEach((child: any) => {
        filtersObj[child.id] = child.value;
      });
      handleFilter(filtersObj);
    } else {
      resetFilters();
    }
  }, [resetFilters, handleFilter, globalFilter, columnFilters]);

  return (
    <>
      <MaterialReactTable
        // ================= states =================
        columns={columns}
        data={data ?? []}
        initialState={{
          showColumnFilters: true,
        }}
        state={{
          sorting,
          rowSelection,
          density: density,
          showProgressBars: false,
          columnFilters: columnFilters,
          isLoading: isLoading,
          columnOrder: localColumnOrder ?? [],
          columnSizing: localColumnSizing,
          columnPinning: localColumnPinning,
          columnVisibility: localColumnVisibility,
        }}
        // ================= options =================
        manualPagination
        manualFiltering
        manualSorting
        enableDensityToggle
        sortDescFirst={false}
        enableStickyHeader={enableStickyHeader}
        enableHiding={enableAdvanceTableFeature}
        enablePinning={enableAdvanceTableFeature}
        enableColumnResizing={enableAdvanceTableFeature}
        enableColumnDragging={enableAdvanceTableFeature}
        enableColumnOrdering={enableAdvanceTableFeature}
        enableMultiSort={false}
        // maxMultiSortColCount={3}
        // enableColumnVirtualization
        positionPagination="both"
        positionActionsColumn="last"
        isMultiSortEvent={() => false}
        columnResizeMode="onEnd"
        enableRowActions={rowActionsExist}
        // ================= functions =================
        onDensityChange={setDensity as any}
        onSortingChange={setSorting as any}
        onColumnFiltersChange={setColumnFilters as any}
        onGlobalFilterChange={setGlobalFilter}
        onColumnSizingChange={setLocalColumnSizing}
        onColumnPinningChange={setLocalColumnPinning as any}
        onColumnVisibilityChange={setLocalColumnVisibility}
        onColumnOrderChange={(value: any) => setLocalColumnOrder(value)}
        // ================= props =================
        // muiTableContainerProps={{}}
        muiTableHeadRowProps={() => ({
          sx: {
            background: mode === "light" ? "white" : (theme.palette.neutral as any)[900],
            ".Mui-TableHeadCell-ResizeHandle-Wrapper": {
              pr: 0,
            },
            ".MuiDivider-root": {
              borderColor: "#98989870",
            },
            ".MuiBadge-root": {
              paddingRight: "4px",
              marginRight: "2.5px",
            },
            ".MuiBadge-badge": {
              marginTop: "2px",
              paddingLeft: "10px",
              color: theme.palette.error.light,
            },
            ".MuiSvgIcon-root": {
              color: (theme.palette.neutral as any)[mode === "light" ? 600 : 100],
            },
          },
        })}
        // muiTableHeadCellProps={() => ({
        //   sx: {
        //     ".Mui-TableHeadCell-Content-Wrapper": {
        //       whiteSpace: "nowrap !important",
        //     },
        //   },
        // })}
        muiTableBodyCellProps={() => ({
          sx:
            extremeCompact && density === "compact"
              ? { fontWeight: "normal", fontSize: "12px", py: 0, px: 1, borderRight: "1px solid #E6E8F0" }
              : { fontWeight: "normal", fontSize: "14px" },
        })}
        muiTableBodyRowProps={({ row }: { row: any }) => {
          return {
            //   component: (props: any) =>
            //     anchorRedirect ? (
            //       <a href={anchorRedirect(row.original)} rel="noopener noreferrer" {...props}>
            //         {props.children}
            //       </a>
            //     ) : (
            //       props.children
            //     ),
            onClick: () => {
              if (rowSelectKey && onRowSelect) {
                if (selectedCount >= 10 && !rowSelection[row.original[rowSelectKey]]) {
                  // Prevent selection when already 10 items are selected
                  // >= 10 && its unselected, then cant click
                  return;
                }
                // Toggle the selection state for the row
                onRowSelect(row.original[rowSelectKey]);
              } else {
                if (rowAction) {
                  rowAction(row.original);
                }
              }
            },
            selected: rowSelectKey ? rowSelection[row.original[rowSelectKey]] : undefined,
            sx: {
              "&:nth-of-type(odd)": {
                backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[800] : (theme.palette.neutral as any)[100],
              },
              "&:nth-of-type(even)": {
                backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[900] : "white",
              },
              background: rowSelectKey && rowSelection[row.original[rowSelectKey]] ? `${theme.palette.primary.dark} !important` : "",
              "&:hover": {
                background:
                  rowSelectKey && rowSelection[row.original[rowSelectKey]]
                    ? `${theme.palette.primary.main} !important`
                    : mode === "light"
                    ? alpha((theme.palette.neutral as any)[200], 0.5)
                    : (theme.palette.neutral as any)[1000],
                td: {
                  background: "rgba(95, 110, 133, 0.04)",
                },
              },
              cursor:
                selectedCount >= 10 && rowSelectKey && !rowSelection[row.original[rowSelectKey]] ? "not-allowed" : rowClick ? "pointer" : "default", //you might want to change the cursor too when adding an onClick
            },
          };
        }}
        muiToolbarAlertBannerProps={
          error
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        muiBottomToolbarProps={() => ({
          sx: {
            "& .Mui-disabled": {
              "& .MuiSvgIcon-root": {
                color: (theme.palette.grey as any)[mode === "light" ? 300 : 600],
              },
            },
            "& .MuiSvgIcon-root": {
              color: (theme.palette.grey as any)[mode === "light" ? 400 : 100],
            },
          },
        })}
        muiTopToolbarProps={() => ({
          sx: {
            "& .Mui-disabled": {
              "& .MuiSvgIcon-root": {
                color: (theme.palette.grey as any)[mode === "light" ? 300 : 600],
              },
            },
            "& .MuiSvgIcon-root": {
              color: (theme.palette.grey as any)[mode === "light" ? 400 : 100],
            },
            "& .MuiBox-root": {
              "& .MuiSvgIcon-root": {
                color: (theme.palette.neutral as any)[mode === "light" ? 550 : 100],
              },
            },
            ".MuiTablePagination-root": {
              marginTop: "0 !important",
            },
            ".MuiInputBase-root": {
              svg: {
                fontSize: "20px",
              },
              input: {
                fontSize: "14px",
              },
            },
          },
        })}
        muiTableBodyCellSkeletonProps={{
          width: "90%",
        }}
        muiTablePaginationProps={{
          onPageChange: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
            onPageChange(page + 1);
          },
          showFirstButton: true,
          showLastButton: true,
          count: count,
          page: page - 1,
          //@ts-ignore
          rowsPerPage: rowsPerPage,
          rowsPerPageOptions: [rowsPerPage],
          labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => {
            if (from === count) {
              // if its the last page with last single item, then show "no of no"
              return `${from} of ${count !== -1 ? count : `more than ${to}`}`;
            }
            return `${from} – ${to} of ${count !== -1 ? count : `more than ${to}`}`;
          },
        }}
        muiTableHeadCellFilterTextFieldProps={{
          sx: {
            width: "100%",
            // "& .MuiButtonBase-root": {
            //   display: "none !important",
            // },
            input: {
              fontSize: "12px",
              "& + .MuiInputAdornment-root": {
                display: "none",
              },
              // "&:focus": {
              //   "& + .MuiInputAdornment-root": {
              //     display: "flex",
              //   },
              // },
              // "&:focus": {
              //   "& + .MuiInputAdornment-root": {
              //     // "& button": {
              //     display: "inline-flex",
              //     // },
              //   },
              // },
            },
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              sx: {
                ".Mui-TableHeadCell-Content": {
                  justifyContent: "center !important",
                },
                transform: "translateY(7px)",
              },
            },
            size: 60,
          },
        }}
        // ================= custom toolbar =================
        renderTopToolbarCustomActions={({ table }: { table: any }) => {
          return (
            <Box>
              <Stack alignItems="center" direction="row" spacing={1}>
                {customActions}
                {rowSelectKey && !!onRowSelect && rowSelection && <Typography variant="body1">{selectedCount} / 10 Selected</Typography>}
                {columnFilters.length > 0 ? (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      resetFilters();
                      setColumnFilters([]);
                    }}
                    size="small"
                    startIcon={<FontAwesomeIcon icon={faCircleXmark} />}
                  >
                    {getTranslateString(t, BUTTON_CLEAR_FILTERS)}
                  </Button>
                ) : (
                  <Box />
                )}
                {sorting.length > 0 && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => table.resetSorting(true)}
                    startIcon={<FontAwesomeIcon icon={faCircleXmark} />}
                  >
                    {getTranslateString(t, BUTTON_CLEAR_SORTING)}
                  </Button>
                )}
              </Stack>
            </Box>
          );
        }}
        renderToolbarInternalActions={({ table }: { table: any }) => (
          <>
            {!disableAdvance && (
              <>
                {showGlobalFilter && (
                  <Box position="relative">
                    <MRTToggleGlobalFilterButton table={table} />
                    <Badge
                      sx={{ position: "absolute", right: "8px", top: "8px" }}
                      color="error"
                      invisible={globalFilter === "" || !!!globalFilter}
                      variant="dot"
                    />
                  </Box>
                )}
                {enableAdvanceTableFeature && <MRTShowHideColumnsButton table={table} />}
                {/* <MRT_ToggleFiltersButton table={table} /> */}
                <MRTToggleDensePaddingButton table={table} />
                {enableAdvanceTableFeature && <MRTFullScreenToggleButton table={table} />}
              </>
            )}
          </>
        )}
        // ================= row action =================
        renderRowActions={({ row }: { row: any }) => (
          <>
            {rowActions && !_.isEmpty(rowActions) && (
              <Box>
                {rowActions.length > 1 ? (
                  <Box>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        setMoreActionsMenu({ anchorEl: e.currentTarget, rowData: row.original });
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsis} />
                    </IconButton>
                  </Box>
                ) : (
                  <Box>
                    <Grid container flexWrap="nowrap" justifyContent="space-around" spacing={1}>
                      {rowActions.map((action: any, index: number) => {
                        const content = action.navigation ? (
                          <IconButton
                            component="a"
                            onClick={e => {
                              e.stopPropagation();
                              navigate(action.navigation.url(row.original));
                            }}
                          >
                            <FontAwesomeIcon icon={action.icon} size="xs" />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              action.onClick(row.original);
                            }}
                            {...action.buttonProps}
                          >
                            <FontAwesomeIcon icon={action.icon} size="xs" />
                          </IconButton>
                        );

                        return (
                          <Grid key={index} item xs="auto">
                            {action.tooltip ? (
                              <Tooltip arrow title={action.tooltip(row.original)}>
                                <span>{content}</span>
                              </Tooltip>
                            ) : (
                              content
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      />
      <MoreActionsMenu
        anchorEl={moreActionsMenu.anchorEl}
        onClose={() => setMoreActionsMenu({ anchorEl: null, rowData: null })}
        rowActions={rowActions}
        rowData={moreActionsMenu.rowData}
        moreActionsMenu={moreActionsMenu}
        setMoreActionsMenu={setMoreActionsMenu}
      />
    </>
  );
};

// permissions && permissions.CHANGE_CONTACT
//   ? [
//       {
//         icon: faPenToSquare,
//         label: getTranslateString(t, EDIT),
//         onClick: rowData => setUpdateDialog({ open: true, data: rowData }),
//         tooltip: () => getTranslateString(t, EDIT),
//       },
//     ]
//   : []

// Add the displayName to the component
DNDTable.displayName = "DNDTable";

export default DNDTable;

export const NUMBER_CELL_TRANSLATE = {
  ".Mui-TableHeadCell-Content": {
    display: "none",
  },
  transform: `translateY(${process.env.NEXT_PUBLIC_ADVANCE_TABLE_FEATURE === "true" ? 8 : 2}px)`,
};
