/* components */
import DateFilter from "src/components/common/inputs/date-filter";
import SpinnerComponent from "src/components/common/feedback/spinner";
import GoToTopButton from "src/components/common/inputs/go-to-top-button";
import WordCloudChart from "src/components/common/data-display/charts/word-cloud";
import PostViewer from "src/components/profile/instagram/profile-overview/posts-viewer";
import PostsCharts from "src/components/profile/instagram/profile-overview/posts-charts";
import PostsCalendar from "src/components/profile/instagram/profile-overview/posts-calendar";
import PieChart, { TPieChartData } from "src/components/common/data-display/charts/pie-chart";
/* 3rd party lib */
import useSWR from "swr";
import moment from "moment";
import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Step } from "react-joyride";
import { faChartScatter3d, faPieChart } from "@fortawesome/pro-solid-svg-icons";
import { Box, FormControl, Grid, Link, Paper, Stack, TextField, Theme, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";

/* Util */
import useHttp from "src/hooks/use-http";
import { TSocialMedia } from "src/types/common";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { IFormDateFilter, TPagination } from "src/types/common";
import { useResizeObserver } from "src/hooks/use-resizeobserver";
import { IInstaProfileHashtagData, IInstaProfilePostData, TInstaProfilePost, TInstaProfileStatistics } from "src/types/instagram";

import { faBook } from "@fortawesome/pro-regular-svg-icons";
import { TUTORIALS } from "src/utils/constants";
import JoyrideComponent from "src/components/comparison/profiles/overview-joyride";
import ArrowOverflowBox from "src/components/common/layout/arrow-overflow-box";
import PostsHeatmap from "../../posts-heatmap";

export type TSortPost = "count_play" | "count_like" | "count_comment" | "date_posted";

export const overviewSteps = (theme: Theme): Step[] => [
  {
    target: "body",
    placement: "center",
    content: (
      <Typography textAlign={"left"}>This page provides details about this profile. Let’s quickly review some of the key features.</Typography>
    ),
  },
  {
    target: ".overview-step1",
    placement: "bottom",
    content: (
      <Stack spacing={2}>
        <Typography textAlign={"left"}>You can directly select this profile for comparison on the comparison page by clicking here.</Typography>
      </Stack>
    ),
  },
  {
    target: ".overview-step2",
    content: (
      <Stack spacing={2}>
        <Typography>Click here to favourite this profile and view it later on the favourite page.</Typography>
      </Stack>
    ),
  },
  {
    target: ".overview-step3",
    content: (
      <Stack spacing={2}>
        <Typography textAlign="left">The 'Overview' tab displays general and posting activity statistics.</Typography>
      </Stack>
    ),
  },
  {
    target: ".overview-step4",
    content: (
      <Stack spacing={2}>
        <Typography textAlign="left">The 'Posts' tab displays all posts made by this profile within the selected time frame.</Typography>
      </Stack>
    ),
  },
  {
    target: ".overview-step5",
    content: (
      <Stack spacing={2}>
        <Typography textAlign="left">
          The 'Statistics' tab displays daily updates of the profile's general statistics since tracking began, such as Total Plays, Total Likes, and
          Followers.
        </Typography>
      </Stack>
    ),
  },
  {
    target: ".overview-step6",
    content: (
      <Stack spacing={2}>
        <Typography textAlign="left">
          The 'Hashtags' tab displays the hashtags used by this profile, their usage frequency, and the attention they garnered within the selected
          time frame.
        </Typography>
      </Stack>
    ),
  },
  {
    target: ".overview-step7",
    content: (
      <Stack spacing={2}>
        <Typography textAlign="left">This is where you can select your preferred time frame to view data according to your plan. </Typography>
      </Stack>
    ),
  },
  {
    target: ".overview-step8",
    placement: "right-start",
    content: (
      <Stack spacing={2}>
        <Typography textAlign="left">
          You can view changes in profile statistics at the top, along with the number of posts made, on the calendar.
        </Typography>
      </Stack>
    ),
  },
  {
    target: ".overview-step9",
    placement: "left",
    content: (
      <Stack spacing={2}>
        <Typography textAlign="left">
          This section displays the profile's posting activity, including the number of posts made each day of the week, as well as daily and hourly
          details.
        </Typography>
      </Stack>
    ),
  },
  {
    target: ".overview-step10",
    content: (
      <Stack spacing={2}>
        <Typography textAlign="left">
          You can easily view all the hashtags used by this profile, along with the plays, likes, and comments they garnered.
        </Typography>
      </Stack>
    ),
  },
  {
    target: ".overview-step11",

    content: (
      <Stack spacing={2}>
        <Typography textAlign="left">
          The Posting Heatmap helps you identify the best times to post on social media by visualizing engagement levels across different days and
          hours.
        </Typography>
        <Typography textAlign="left">
          {theme.palette.mode === "light" ? "Darker cells" : "Lighter cells"} indicate higher engagement, while
          {theme.palette.mode === "light" ? " lighter cells" : " darker cells"} show lower activity.
        </Typography>
        <Typography textAlign="left">
          You can switch between metrics like likes, comments, and engagement rate to see how your content performs at different times.
        </Typography>
        <Typography textAlign="left">
          Hover over any cell for detailed stats, and use these insights to optimize your posting schedule for maximum reach and interaction.
        </Typography>
      </Stack>
    ),
  },
  {
    target: "body",
    placement: "center",
    content: (
      <Stack spacing={2}>
        <Typography>You’re all set—enjoy exploring!</Typography>
      </Stack>
    ),
    locale: {
      last: "Complete",
    },
  },
];
interface ProfileOverviewProps {
  socialMedia: TSocialMedia;
  formik: FormikProps<IFormDateFilter>;
}

type Props = ProfileOverviewProps;

const ProfileOverview: React.FC<Props> = ({ formik, socialMedia }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const params = useParams();
  const { apiEndpoint } = useHttp();
  const { smDown } = useMediaQueries();
  const [display, setDisplay] = useState("pie");
  const { width: wordCloudWidth, height: wordCloudHeight, observedDivRef } = useResizeObserver();
  const [piechartData, setPiechartData] = useState<TPieChartData | null>(null);
  const [wordCloudData, setWordCloudData] = useState<{ text: string; value: number }[] | null>(null);
  const [convertedTitle, setConvertedTitle] = useState("");
  const [selected, setSelected] = useState<TInstaProfilePost | null>(null);
  const [pagination, setPagination] = useState<TPagination>({
    currPage: 1,
    count: 0,
    limit: 0,
    total_pages: 0,
  });

  const METRICS: Record<string, { key: string; filterObj: { metric?: string; ordering: string } }> = {
    post_count: { key: "post_count", filterObj: { ordering: "-post_count" } },
    top_play: { key: "top_play", filterObj: { metric: "play", ordering: "-top_play" } },
    top_like: { key: "top_like", filterObj: { metric: "like", ordering: "-top_like" } },
    top_comment: { key: "top_comment", filterObj: { metric: "comment", ordering: "-top_comment" } },
  };

  const [sortPost, setSortPost] = useState<TSortPost>("count_play");
  const [metric, setMetric] = useState(METRICS.post_count.key);
  const [metricFilter, setMetricFilter] = useState(METRICS.post_count.filterObj);
  const [filterSize, setFilterSize] = useState(20);

  /* STATISTICS FOR CHARTS */
  const statisticConfig = useMemo(() => {
    return {
      params: {
        date_posted_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
        date_posted_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
      },
    };
  }, [formik]);
  const { data: postStatisticsData } = useSWR<TInstaProfileStatistics>(
    params && [apiEndpoint.INSTAGRAM_POST_STATISTICS(params.username), statisticConfig]
  );

  /* PROFILE POSTS LISTING */
  const postsConfig = useMemo(() => {
    return {
      params: {
        page: pagination.currPage,
        ordering: `-${sortPost}`,
        date_posted_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
        date_posted_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
      },
    };
  }, [formik, sortPost, pagination]);
  const { data } = useSWR<IInstaProfilePostData>(params && [apiEndpoint.INSTAGRAM_PROFILE_POSTS(params.username), postsConfig]);

  /* HASHTAG */
  const hashtagConfig = useMemo(() => {
    return {
      params: {
        ...metricFilter,
        page_size: filterSize,
        date_posted_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
        date_posted_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
      },
    };
  }, [formik, filterSize, metricFilter]);
  const { data: hashtagData, isValidating } = useSWR<IInstaProfileHashtagData>([apiEndpoint.INSTAGRAM_HASHTAGS(params.username), hashtagConfig]);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  // const steps: Step[] = [
  //   {
  //     target: "body",
  //     placement: "center",
  //     content: (
  //       <Typography textAlign={"left"}>This page provides details about this profile. Let’s quickly review some of the key features.</Typography>
  //     ),
  //   },
  //   {
  //     target: ".overview-step1",
  //     placement: "bottom",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography textAlign={"left"}>You can directly select this profile for comparison on the comparison page by clicking here.</Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     target: ".overview-step2",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography>Click here to favourite this profile and view it later on the favourite page.</Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     target: ".overview-step3",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography textAlign="left">The 'Overview' tab displays general and posting activity statistics.</Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     target: ".overview-step4",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography textAlign="left">The 'Posts' tab displays all posts made by this profile within the selected time frame.</Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     target: ".overview-step5",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography textAlign="left">
  //           The 'Statistics' tab displays daily updates of the profile's general statistics since tracking began, such as Total Plays, Total Likes,
  //           and Followers.
  //         </Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     target: ".overview-step6",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography textAlign="left">
  //           The 'Hashtags' tab displays the hashtags used by this profile, their usage frequency, and the attention they garnered within the selected
  //           time frame.
  //         </Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     target: ".overview-step7",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography textAlign="left">This is where you can select your preferred time frame to view data according to your plan. </Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     target: ".overview-step8",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography textAlign="left">
  //           You can view changes in profile statistics at the top, along with the number of posts made, on the calendar.
  //         </Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     target: ".overview-step9",
  //     placement: "left",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography textAlign="left">
  //           This section displays the profile's posting activity, including the number of posts made each day of the week, as well as daily and hourly
  //           details.
  //         </Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     target: ".overview-step10",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography textAlign="left">
  //           You can easily view all the hashtags used by this profile, along with the plays, likes, and comments they garnered.
  //         </Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     target: "body",
  //     placement: "center",
  //     content: (
  //       <Stack spacing={2}>
  //         <Typography>You’re all set—enjoy exploring!</Typography>
  //       </Stack>
  //     ),
  //     locale: {
  //       last: "Complete",
  //     },
  //   },
  // ];

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (data) {
      if (data.results.length > 0) {
        setSelected(data.results[0]);
        setPagination(prev => {
          return { ...prev, count: data.count, limit: data.limit, total_pages: data.total_pages };
        });
      }
    }
  }, [data]);

  // process hashtag
  useEffect(() => {
    if (hashtagData) {
      if (hashtagData.results.length > 0) {
        // const filter = "post_count";
        let tempPiechartData: TPieChartData = [];
        let tempCloudData: { text: string; value: number }[] = [];

        for (const hashtag of hashtagData.results) {
          tempPiechartData.push({
            name: hashtag.name,
            value: isNaN((hashtag as any)[metric]) ? parseInt((hashtag as any)[metric] as any) : (hashtag as any)[metric],
          });
          tempCloudData.push({
            text: `#${hashtag.name}`,
            value: isNaN((hashtag as any)[metric]) ? parseInt((hashtag as any)[metric] as any) : (hashtag as any)[metric],
          });
        }
        setPiechartData(tempPiechartData);
        setWordCloudData(tempCloudData);
      } else {
        setPiechartData([]);
        setWordCloudData([]);
      }
    }
  }, [hashtagData, metric]);

  useEffect(() => {
    if (selected) {
      const title = selected.title.replace(/\n/g, "<br/> ");
      // Split the description into words
      const words = title.split(/\s+/);

      // Define a function to convert hashtags to links
      function convertHashtagsToLinks(word: string) {
        if (word.startsWith("#")) {
          const hashtag = word.slice(1); // Remove the '#' character
          return `<a style="color:${theme.palette.primary.main}" href="/profile/${socialMedia}/${params.username}/hashtags/${hashtag}">#${hashtag}</a>`;
        }
        return word;
      }

      // Map the words, converting hashtags to links where needed
      const convertedWords = words?.map(convertHashtagsToLinks);

      // Join the words back into a single string
      const convertedTitle = convertedWords?.join(" ");
      setConvertedTitle(convertedTitle);
    }
  }, [theme, socialMedia, selected, params]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <JoyrideComponent
        run={run}
        setRun={setRun}
        stepIndex={stepIndex}
        steps={overviewSteps(theme)}
        setStepIndex={setStepIndex}
        tutorialSection={TUTORIALS.OVERVIEW}
      />
      <Grid container spacing={1} sx={{ height: { xs: "auto", lg: "auto" } }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 1 }}>
            <ArrowOverflowBox>
              <Stack direction="row" alignItems="center" justifyContent={"flex-end"}>
                <Box className="overview-step7">
                  <DateFilter formik={formik} />
                </Box>
              </Stack>
            </ArrowOverflowBox>
          </Paper>
        </Grid>

        {/* left grid */}
        <Grid item xs={12} lg={7}>
          <Stack sx={{ height: "100%" }} spacing={0}>
            <PostsCalendar socialMedia={socialMedia} formik={formik} data={postStatisticsData} theme={theme} />
            <Paper ref={observedDivRef} sx={{ mt: 1, p: 2, height: "100%" }} className="overview-step10">
              <Stack sx={{ height: "100%" }}>
                <Typography variant="body1">Hashtags </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent={smDown ? "center" : "flex-end"}
                  sx={{
                    zIndex: 1,
                    mt: smDown ? 1 : 0,
                  }}
                >
                  <Box>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        hiddenLabel
                        value={metric}
                        onChange={e => {
                          setMetric(e.target.value);
                          setMetricFilter(METRICS[e.target.value].filterObj);
                        }}
                        select
                        size="small"
                        sx={{
                          width: "110px",
                          "& .MuiNativeSelect-select": {
                            textTransform: "capitalize",
                          },
                        }}
                        SelectProps={{ native: true }}
                      >
                        <option value={METRICS.post_count.key}>Posts</option>
                        <option value={METRICS.top_play.key}>Play</option>
                        <option value={METRICS.top_like.key}>Like</option>
                        <option value={METRICS.top_comment.key}>Comment</option>
                      </TextField>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        hiddenLabel
                        value={filterSize}
                        onChange={e => {
                          setFilterSize(parseInt(e.target.value));
                        }}
                        select
                        size="small"
                        sx={{
                          width: "110px",
                          "& .MuiNativeSelect-select": {
                            textTransform: "capitalize",
                          },
                        }}
                        SelectProps={{ native: true }}
                      >
                        <option value={10}>Top 10</option>
                        <option value={20}>Top 20</option>
                        <option value={50}>Top 50</option>
                        <option value={100}>Top 100</option>
                      </TextField>
                    </FormControl>
                  </Box>

                  <Box>
                    <ToggleButtonGroup
                      color="primary"
                      value={display}
                      exclusive
                      onChange={(_e, value) => {
                        if (value) setDisplay(value);
                      }}
                      aria-label="Platform"
                    >
                      <ToggleButton value="pie">
                        <FontAwesomeIcon icon={faPieChart} />
                      </ToggleButton>
                      <ToggleButton value="cloud">
                        <FontAwesomeIcon icon={faChartScatter3d} />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Stack>
                <Box
                  sx={{
                    zIndex: 0,
                    position: "relative",
                    height: { xs: "300px", lg: "400px" },
                    display: "grid",
                    placeItems: "center",
                    pt: 1,
                  }}
                >
                  <Box sx={{ position: "absolute", bottom: "30px", right: 0 }}>{isValidating && <SpinnerComponent modifier={0.3} />}</Box>
                  {display === "pie" ? (
                    <PieChart data={piechartData} />
                  ) : (
                    <Box sx={{ height: "100%", width: "100%" }}>
                      <Box sx={{ transform: wordCloudWidth < 320 ? "translateY(40px)" : "translateY(16px)" }}>
                        <WordCloudChart width={wordCloudWidth} height={wordCloudHeight} data={wordCloudData ?? []} />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Paper>
          </Stack>
        </Grid>
        {/* right grid */}
        <Grid item xs={12} lg={5} className="overview-step9">
          <Box sx={{ height: "100%" }}>
            <PostsCharts data={postStatisticsData} theme={theme} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <PostsHeatmap formik={formik} socialMedia="instagram" postStats={postStatisticsData ? postStatisticsData.post_stats : []} />
        </Grid>

        <Grid item xs={12}>
          <PostViewer
            sortPost={sortPost}
            setSortPost={setSortPost}
            pagination={pagination}
            setPagination={setPagination}
            data={data}
            theme={theme}
            convertedTitle={convertedTitle}
            selected={selected}
            setSelected={setSelected}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, mb: 1 }}>
        <Link
          sx={{ cursor: "pointer", userSelect: "none" }}
          onClick={() => {
            setRun(true);
            setStepIndex(0);
          }}
        >
          <Typography>
            <FontAwesomeIcon icon={faBook} />
            &nbsp;Need help? View all our key features with a guided walkthrough here.
          </Typography>
        </Link>
      </Box>
      <GoToTopButton />
    </>
  );
};

export default ProfileOverview;
