import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
/* components */
import SpinnerComponent from "src/components/common/feedback/spinner";
import NoDataFound from "src/components/common/feedback/no-data-found";

/* 3rd party lib */
import { useNavigate } from "react-router-dom";
import { Box, Grid, Pagination, Paper, Popover, Stack, Typography, useTheme } from "@mui/material";

/* Util */
import routes from "src/routes";
import useHttp from "src/hooks/use-http";
import { IFormDateFilter, TPagination } from "src/types/common";
import { IInstaProfilePostData, TInstaProfilePost } from "src/types/instagram";
import InstaProfilePostCard from "src/components/comparison/profiles/instagram/profile-post-card";
import useMediaQueries from "src/hooks/use-mediaqueries";
import useCompare from "src/hooks/use-compare";
import DateFilter from "src/components/common/inputs/date-filter";
import { useFormik } from "formik";
import moment from "moment";
import ArrowOverflowBox from "src/components/common/layout/arrow-overflow-box";

interface InstaPostGridProps {
  params?: any;
  inDialog?: boolean;
  search?: string;
  username?: string;
  multiSelect?: boolean;
  onSelectPost?: (rowData: TInstaProfilePost) => void;
}

type Props = InstaPostGridProps;

const InstaPostGrid: React.FC<Props> = ({ params = {}, multiSelect = false, inDialog = false, search, username, onSelectPost }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const navigate = useNavigate();
  const { apiEndpoint } = useHttp();
  const { handleMultiPostSelect } = useCompare(username);
  const { ssDown, mdDown, ssUp, xsUp, smUp, mdUp, lgUp, xlUp } = useMediaQueries();

  const formik = useFormik<IFormDateFilter>({
    validateOnChange: false,
    initialValues: {
      start_min: null,
      start_max: null,
    },
    onSubmit: () => {},
  });

  let gridSpacing = inDialog
    ? {
        xs: 4,
        sm: 3,
        md: 2.4,
        lg: 1.71,
        xl: 1.71,
      }
    : {
        xs: ssDown ? 6 : 4,
        sm: 3,
        md: 2,
        lg: 1.2,
        xl: 1.2,
      };

  const [pagination, setPagination] = useState<TPagination>({
    currPage: 1,
    count: 0,
    limit: 0,
    total_pages: 0,
  });
  const config = useMemo(() => {
    let pageSize = 25;

    if (xlUp) {
      pageSize = 30;
    } else if (lgUp) {
      pageSize = 30;
    } else if (mdUp) {
      pageSize = 18;
    } else if (smUp) {
      pageSize = 16;
    } else if (ssUp) {
      pageSize = 9;
    } else if (xsUp) {
      pageSize = 8;
    }
    return {
      params: {
        ...params,
        q: search,
        page_size: pageSize,
        page: pagination.currPage,
        date_posted_min: formik.values.start_min ? moment(formik.values.start_min).format("YYYY-MM-DD") : "",
        date_posted_max: formik.values.start_max ? moment(formik.values.start_max).format("YYYY-MM-DD") : "",
      },
    };
  }, [xsUp, ssUp, smUp, mdUp, lgUp, xlUp, params, search, formik, pagination]);

  const [popover, setPopover] = useState<{ anchorEl: HTMLElement | null; title: string | null }>({ anchorEl: null, title: null });

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopover({ ...popover, anchorEl: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setPopover({ ...popover, anchorEl: null });
  };

  const open = Boolean(popover.anchorEl);

  const { data } = useSWR<IInstaProfilePostData>(username && [apiEndpoint.INSTAGRAM_PROFILE_POSTS(username), config]);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleNavigate = (rowData: TInstaProfilePost) => {
    if (onSelectPost) {
      onSelectPost(rowData);
    } else {
      navigate(`${routes.INSTA_PROFILE}/${username}`, {
        state: { prevPage: routes.ROOT },
      });
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    setPagination(prev => {
      return { ...prev, currPage: 1 };
    });
  }, [search]);

  useEffect(() => {
    if (data) {
      setPagination(prev => {
        return { ...prev, count: data.count, limit: data.limit, total_pages: data.total_pages };
      });
    }
  }, [data]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Paper sx={{ minHeight: data && data.results.length > 0 ? "610px" : "auto", p: 1 }}>
        <ArrowOverflowBox fullWidth>
          <Stack
            spacing={1}
            direction={mdDown ? "column" : "row"}
            alignItems={mdDown ? "flex-end" : "center"}
            justifyContent={"space-between"}
            sx={{ pb: 1, pr: 2, width: "100%" }}
          >
            <DateFilter clearableDate formik={formik} />
            <Pagination
              page={pagination?.currPage}
              onChange={(e, page) => {
                if (pagination && page !== pagination.currPage) {
                  setPagination({ ...pagination, currPage: page });
                }
              }}
              count={pagination?.total_pages ?? 0}
              showFirstButton
              showLastButton
            />
          </Stack>
        </ArrowOverflowBox>
        {data ? (
          data.results.length === 0 ? (
            <Box>
              <Paper sx={{ paddingTop: 2, paddingBottom: 2 }}>{<NoDataFound image />}</Paper>
            </Box>
          ) : (
            <Grid container spacing={1}>
              {data.results.map((child, index) => {
                return (
                  <Grid
                    key={`'insta_grid_${index}`}
                    item
                    xs={gridSpacing.xs}
                    sm={gridSpacing.sm}
                    md={gridSpacing.md}
                    lg={gridSpacing.lg}
                    xl={gridSpacing.xl}
                    sx={{ display: "grid", placeItems: "center" }}
                  >
                    <InstaProfilePostCard
                      inDialog
                      data={child}
                      index={index}
                      postFullSize
                      metric={"count_play"}
                      username={username}
                      setPopover={setPopover}
                      onClickAction={multiSelect ? handleMultiPostSelect : handleNavigate}
                      handlePopoverOpen={handlePopoverOpen}
                      handlePopoverClose={handlePopoverClose}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )
        ) : (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              minHeight: "610px",
              width: "100%",
              background: theme.palette.mode === "dark" ? (theme.palette.neutral as any)[800] : "white",
            }}
          >
            <SpinnerComponent color="white" />
          </Box>
        )}
        <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 1, pr: 2 }}>
          <Pagination
            page={pagination?.currPage}
            onChange={(e, page) => {
              if (pagination && page !== pagination.currPage) {
                setPagination({ ...pagination, currPage: page });
              }
            }}
            count={pagination?.total_pages ?? 0}
            showFirstButton
            showLastButton
          />
        </Stack>
      </Paper>

      <Popover
        id="insta-grid-post-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={popover.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {popover.title && (
          <Typography variant="body2" sx={{ p: 1, maxWidth: "500px" }}>
            <div style={{ whiteSpace: "normal" }} dangerouslySetInnerHTML={{ __html: popover.title.replace(/\n/g, "<br/> ") }} />
          </Typography>
        )}
      </Popover>
    </>
  );
};

export default InstaPostGrid;
