import { Box } from "@mui/material";
import SpinnerComponent from "src/components/common/feedback/spinner";

export const SplashScreen = () => {
  const theme = localStorage.getItem("theme");
  return (
    <Box sx={{ background: theme && theme === "dark" ? "#1F2937" : "white", height: "100vh", display: "grid", placeItems: "center" }}>
      <SpinnerComponent color={theme && theme === "dark" ? "white" : "#0D99FF"} />
    </Box>
  );
};
