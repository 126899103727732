import React from "react";
import OuterLayout from "src/components/common/layout/outer-layout";
import { Box, Grid, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import ProfileHashtagBanner from "src/components/profile/tiktok/profile-hashtag-banner";
import useBackground from "src/hooks/use-background";
import TikTokPostGrid from "src/pages/listing/tiktok/tiktok-post-grid";
import { TTikTokProfilePost } from "src/types/tiktok";

export interface IHashtagPostData {
  title: string;
  post_id: string;
  post_type: string;
  image: string;
  is_video: boolean;
  shortcode: string;
  taken_at_timestamp: string;
  date_posted: string;
  count_like: number;
  count_comment: number;
  count_view: number | null;
  count_play: number;
  insta_link: string;
  created: string;
  modified: string;
  downloaded_image: string;
}

interface HashtagProps {}

type Props = HashtagProps;

const Hashtag: React.FC<Props> = () => {
  const params = useParams();
  const background = useBackground({});
  const { hashtag, username } = params;

  return (
    <OuterLayout maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProfileHashtagBanner />
        </Grid>
        <Grid item xs={12}>
          {/* <Paper elevation={5} sx={{ width: "100%", background: `linear-gradient(to right, ${themeColors["instagram"]})` }}> */}
          <Paper elevation={5} sx={{ width: "100%", background: background }}>
            <Box p={1}>
              <TikTokPostGrid
                username={username}
                params={{ hashtags: hashtag }}
                onSelectPost={(data: TTikTokProfilePost) => window.open(data.tiktok_link, "_blank")}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </OuterLayout>
  );
};

export default Hashtag;
