/**
 * Calculate the dynamic index based on pagination
 * @param   {number} currentPage  Current page number
 * @param   {number} rowsPerPage  Item rows per page
 * @param   {number} itemIndex   index of current item
 * @return  {number} e.g. if currentPage is 1, rowsPerPage is 10 and itemIndex is 5, the result will be 15
 */

export const getDynamicIndex = (currentPage: number, rowsPerPage: number, itemIndex: number) => {
  if (currentPage < 0 || rowsPerPage < 0 || itemIndex < 0) return "";
  return currentPage * rowsPerPage + itemIndex;
};

export const formatNumberWithSuffix = (num: number | null | undefined) => {
  if (num === null || num === undefined) return "-";

  if (Math.abs(num) >= 1000000000) {
    return (num / 1000000000).toFixed(1) + "B";
  } else if (Math.abs(num) >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (Math.abs(num) >= 10000) {
    return (num / 1000).toFixed(1) + "K";
  } else if (Math.abs(num) >= 1000) {
    return num.toLocaleString();
  } else {
    return num;
  }
};

export const countUpSuffix = (num: number | null | undefined) => {
  if (num === null || num === undefined) return { num: "-", unit: "" };

  if (num >= 1000000000) {
    return { num: (num / 1000000000).toFixed(1), unit: "B" };
  } else if (num >= 1000000) {
    return { num: (num / 1000000).toFixed(1), unit: "M" };
  } else if (num >= 10000) {
    return { num: (num / 1000).toFixed(1), unit: "K" };
  } else {
    return { num: num, unit: "" };
  }
};

export const hasDecimalPlaces = (num: number) => {
  if (!Number.isFinite(num)) {
    // Handle Infinity, -Infinity, and NaN cases
    return false;
  }

  return num % 1 !== 0;
};

export const nullUndefinedReturnDash = (data: string | number | null | undefined) => {
  return data === null ||
    data === undefined ||
    data === "" ||
    (typeof data === "string" && data.toLowerCase() === "invalid date") ||
    (typeof data === "number" && isNaN(data))
    ? "-"
    : data;
};

export const checkObjectNotEmpty = (data: any) => {
  return Object.keys(data).length > 0;
};

// Function to check if any object in the array contains the key
export const checkArrayHasKey = (objects: any[], key: string): boolean => {
  return objects.some(obj => key in obj);
};

export const updateUrlParams = (currentUrl: string, newParams: any): string => {
  const url = new URL(currentUrl);
  const params = url.searchParams;

  // Add new parameters
  for (const [key, value] of Object.entries(newParams)) {
    if (value !== null && value !== undefined) {
      params.set(key, value.toString());
    } else {
      params.delete(key); // Remove parameter if value is null or undefined
    }
  }

  return url.toString();
};

function isEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

export function checkAllChildrenEmpty(parentObj: any) {
  for (let key in parentObj) {
    if (parentObj.hasOwnProperty(key)) {
      if (!isEmpty(parentObj[key])) {
        return false;
      }
    }
  }
  return true;
}
