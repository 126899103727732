import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
/* components */
import SpinnerComponent from "src/components/common/feedback/spinner";
import NoDataFound from "src/components/common/feedback/no-data-found";
/* 3rd party lib */
import moment from "moment";
import { FormikProps } from "formik";
import { Box, Grid, Pagination, Paper, Popover, Stack, Typography, useTheme } from "@mui/material";

/* Util */
import useHttp from "src/hooks/use-http";
import useBackground from "src/hooks/use-background";
import { IFormDateFilter, TPagination } from "src/types/common";
import { ITikTokProfilePostData, TTikTokProfilePost } from "src/types/tiktok";
import TikTokProfilePostCard from "src/components/comparison/profiles/tiktok/profile-post-card";
import useCompare from "src/hooks/use-compare";

interface TikTokPostHashtagGridProps {
  hashtagSearch: string;
  handleClose?: () => void;
  onSelectPost?: (postId: string) => void;
  formik: FormikProps<IFormDateFilter>;
}

type Props = TikTokPostHashtagGridProps;

const TikTokPostHashtagGrid: React.FC<Props> = ({ formik, hashtagSearch, handleClose, onSelectPost }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { start_min, start_max } = formik.values;
  const { apiEndpoint } = useHttp();
  const { handleMultiPostSelect } = useCompare();
  const [pagination, setPagination] = useState<TPagination>({
    currPage: 1,
    count: 0,
    limit: 0,
    total_pages: 0,
  });
  const config = useMemo(() => {
    return {
      params: {
        hashtags: hashtagSearch,
        date_posted_min: start_min ? moment(start_min).format("YYYY-MM-DD") : "",
        date_posted_max: start_max ? moment(start_max).format("YYYY-MM-DD") : "",
        page: pagination.currPage,
      },
    };
  }, [hashtagSearch, start_min, start_max, pagination]);
  const background = useBackground({});
  const { data } = useSWR<ITikTokProfilePostData>([apiEndpoint.TIKTOK_POSTS, config]);

  const [popover, setPopover] = useState<{ anchorEl: HTMLElement | null; title: string | null }>({ anchorEl: null, title: null });

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopover({ ...popover, anchorEl: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setPopover({ ...popover, anchorEl: null });
  };
  const open = Boolean(popover.anchorEl);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleOnClick = (rowData: TTikTokProfilePost) => {
    handleMultiPostSelect(rowData);
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (data) {
      setPagination(prev => {
        return { ...prev, count: data.count, limit: data.limit, total_pages: data.total_pages };
      });
    }
  }, [data]);

  const PaginationComponent = () => (
    <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 1, pr: 3 }}>
      <Pagination
        page={pagination?.currPage}
        onChange={(e, page) => {
          if (pagination && page !== pagination.currPage) {
            setPagination({ ...pagination, currPage: page });
          }
        }}
        count={pagination?.total_pages ?? 0}
        showFirstButton
        showLastButton
      />
    </Stack>
  );

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      {data && data.results.length !== 0 && <PaginationComponent />}
      <Paper sx={{ minHeight: data && data.results.length > 0 ? "610px" : "auto", background: background, p: 1 }}>
        {data ? (
          data.results.length === 0 ? (
            <Paper sx={{ p: 2 }}>{<NoDataFound image />}</Paper>
          ) : (
            <Grid container spacing={1}>
              {data.results.map((child, index) => {
                const { profile } = child;
                return (
                  <Grid key={`'tiktok_grid_${index}`} item xs={4} sm={3} md={2.4} lg={1.71} sx={{ display: "grid", placeItems: "center" }}>
                    <TikTokProfilePostCard
                      key={`tiktok_profile_post_card_${index}`}
                      inDialog
                      postFullSize
                      showProfile
                      data={child}
                      index={index}
                      metric={"count_play"}
                      setPopover={setPopover}
                      username={profile.username}
                      onClickAction={handleOnClick}
                      handlePopoverOpen={handlePopoverOpen}
                      handlePopoverClose={handlePopoverClose}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )
        ) : (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              minHeight: "610px",
              width: "100%",
              background: theme.palette.mode === "dark" ? (theme.palette.neutral as any)[800] : "white",
            }}
          >
            <SpinnerComponent color="white" />
          </Box>
        )}
      </Paper>
      {data && data.results.length !== 0 && <PaginationComponent />}

      <Popover
        id="post-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={popover.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {popover.title && (
          <Typography variant="body2" sx={{ p: 1, maxWidth: "500px" }}>
            <div style={{ fontSize: "12px", whiteSpace: "normal" }} dangerouslySetInnerHTML={{ __html: popover.title.replace(/\n/g, "<br/> ") }} />
          </Typography>
        )}
      </Popover>
    </>
  );
};

export default TikTokPostHashtagGrid;
