import React from "react";
/* components */
/* 3rd party lib */
import { Autocomplete, Box, Stack, TextField, Typography, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { FormikProps } from "formik";
import { IFormikSuperadminFilter } from "src/pages/admin/superadmin";
/* Util */
interface SubscriptionDropdownProps {
  formik: FormikProps<IFormikSuperadminFilter>;
  value: { label: string; value: string } | undefined | null;
  setValue: React.Dispatch<
    React.SetStateAction<
      | {
          label: string;
          value: string;
        }
      | null
      | undefined
    >
  >;
}

type Props = SubscriptionDropdownProps;

const StyledTextField = styled(TextField)(() => {
  return {
    "& .MuiInputBase-root": {
      paddingLeft: "0",
      paddingBottom: "0",
      paddingTop: "0",
      flexWrap: "nowrap",
      height: "25px",
    },
    "& .MuiInputBase-input": {
      fontSize: "12px",
    },
    "& .MuiFilledInput-input": {
      paddingBottom: "9px !important",
      paddingTop: "8px !important",
    },
  };
});

const SubscriptionDropdown: React.FC<Props> = ({ value, setValue, formik }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  const subscriptionStatus = [
    { label: "Active", value: "active" },
    { label: "Expired", value: "expired" },
    { label: "Expires Soon", value: "expires_soon" },
  ];
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Autocomplete
      id="subscription-status-select"
      getOptionLabel={option => (option.label ? option.label : "")}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={subscriptionStatus}
      value={value}
      renderOption={(props, option) => {
        return (
          <Stack direction="row" spacing={1} component="li" {...props} sx={{ textTransform: "uppercase" }}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Box
                sx={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  background:
                    option.label.toLowerCase() === "expired"
                      ? theme.palette.error.main
                      : option.label.toLowerCase() === "active"
                      ? theme.palette.success.main
                      : theme.palette.warning.main,
                }}
              />
              <Typography variant="caption" fontWeight={400}>
                {option.label}
              </Typography>
            </Stack>
          </Stack>
        );
      }}
      renderInput={params => (
        <StyledTextField variant="standard" placeholder="Filter status" hiddenLabel {...params} InputLabelProps={{ shrink: true }} />
      )}
      onChange={(_, value, reason) => {
        setValue(value);
        formik.setFieldValue("subscription_status", value);
      }}
    />
  );
};

export default SubscriptionDropdown;
