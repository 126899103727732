import React, { useEffect, useRef } from "react";
/* components */
/* 3rd party lib */
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { getTranslateString } from "src/utils/translate";
import { BUTTON_CANCEL } from "src/constants/translate-keys/common";
import DataDisplay from "src/components/common/inputs/data-display";
import { ICurrentActivePostPreset } from "src/pages/comparisons/posts/posts";
import { ICurrentActiveProfilePreset } from "src/pages/comparisons/profiles/profiles";
/* Util */

interface IFormValues {
  name: string;
  createPresetWithProfiles: boolean;
}

interface CrudPresetDialogProps {
  open: boolean;
  isProfile?: boolean;
  onClose: () => void;
  crud: "create" | "update";
  currentActive: ICurrentActiveProfilePreset | ICurrentActivePostPreset;
  onSave: (name: string, createPresetWithProfiles: boolean) => Promise<void>;
}

type Props = CrudPresetDialogProps;

const CrudPresetDialog: React.FC<Props> = ({ open, crud, onClose, onSave, currentActive, isProfile = false }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { t } = useTranslation();
  const nameFieldRef = useRef<HTMLDivElement | null>(null);
  const { smDown } = useMediaQueries();

  const handleClose = (resetForm: any) => {
    resetForm();
    onClose();
  };

  const formik = useFormik<IFormValues>({
    validateOnChange: false,
    initialValues: {
      name: "",
      createPresetWithProfiles: false,
    },
    onSubmit: values => {
      onSave(values.name, values.createPresetWithProfiles);
      handleClose(() => formik.resetForm());
    },
  });

  useEffect(() => {
    if (open && !currentActive.preset) {
      formik.setFieldValue("createPresetWithProfiles", true);
    }

    const timeout = setTimeout(() => {
      if (open && nameFieldRef.current) {
        nameFieldRef.current.focus();
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, currentActive]);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog open={open} onClose={() => handleClose(() => formik.resetForm())} maxWidth="sm" fullWidth fullScreen={smDown}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {crud === "create" ? <Typography variant="inherit">Create Preset</Typography> : <Typography variant="inherit">Name Preset</Typography>}
          <IconButton onClick={() => handleClose(() => formik.resetForm())}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <form id="presetForm" onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            ...(theme as any).custom.scroll,
          }}
        >
          <Paper sx={{ p: 2 }} elevation={5}>
            <Grid container>
              <Grid item xs={12}>
                <DataDisplay
                  label={"Title"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <TextField
                      // inputRef={nameFieldRef}
                      inputProps={{
                        ref: nameFieldRef,
                      }}
                      // ref={nameFieldRef}
                      // autoFocus={true}
                      fullWidth
                      variant="outlined"
                      hiddenLabel
                      name="name"
                      size="small"
                      required
                      onChange={formik.handleChange}
                    />
                  }
                />
              </Grid>
              {crud === "create" && (
                <Grid item xs={12}>
                  <FormControlLabel
                    onChange={formik.handleChange}
                    name="createPresetWithProfiles"
                    control={
                      <Checkbox size="small" value={formik.values.createPresetWithProfiles} checked={formik.values.createPresetWithProfiles} />
                    }
                    label={<Typography variant="body2">Create preset with currently selected {isProfile ? "profiles" : "posts"}</Typography>}
                    sx={{ fontSize: "14px" }}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} size="small">
            {getTranslateString(t, BUTTON_CANCEL)}
          </Button>
          <Button form="presetForm" type="submit" variant="contained" color="primary" fullWidth size="small">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CrudPresetDialog;
