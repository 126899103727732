import { useMediaQuery } from "@mui/material";

const useMediaQueries = () => {
  const xsDown = useMediaQuery((theme: any) => theme.breakpoints.down("xs"), { noSsr: true });
  const xsUp = useMediaQuery((theme: any) => theme.breakpoints.up("xs"), { noSsr: true });
  const ssDown = useMediaQuery((theme: any) => theme.breakpoints.down("ss"), { noSsr: true });
  const ssUp = useMediaQuery((theme: any) => theme.breakpoints.up("ss"), { noSsr: true });
  const smDown = useMediaQuery((theme: any) => theme.breakpoints.down("sm"), { noSsr: true });
  const smUp = useMediaQuery((theme: any) => theme.breakpoints.up("sm"), { noSsr: true });
  const mdUp = useMediaQuery((theme: any) => theme.breakpoints.up("md"), { noSsr: true });
  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"), { noSsr: true });
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"), { noSsr: true });
  const lgDown = useMediaQuery((theme: any) => theme.breakpoints.down("lg"), { noSsr: true });
  const xlUp = useMediaQuery((theme: any) => theme.breakpoints.up("xl"), { noSsr: true });
  const xlDown = useMediaQuery((theme: any) => theme.breakpoints.down("xl"), { noSsr: true });
  return { xsDown, xsUp, ssDown, ssUp, smDown, smUp, mdDown, mdUp, lgDown, lgUp, xlUp, xlDown };
};
export default useMediaQueries;
