import React, { useEffect, useState } from "react";
/* components */
import OuterLayout from "src/components/common/layout/outer-layout";

/* 3rd party lib */
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { Box, Button, CircularProgress, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";

/* Util */
import useHttp from "src/hooks/use-http";
import Search from "src/images/search.png";
import { RootState } from "src/store/root-reducer";
import { useLocation, useNavigate } from "react-router-dom";
import useMediaQueries from "src/hooks/use-mediaqueries";
import SocialMediaDropdown from "src/components/common/inputs/social-media-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";

interface SearchPageProps {}

type Props = SearchPageProps;

const SearchPage: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { http, apiEndpoint } = useHttp();
  const location = useLocation();
  const navigate = useNavigate();
  const { smDown, mdDown } = useMediaQueries();
  const locationParam = location.state;
  const [search, setSearch] = useState("");
  const [prevUsername, setPrevUsername] = useState<string | null>(null);

  const [profileSearchLoading, setProfileSearchLoading] = useState(false);
  const [error, setError] = useState({ show: false, message: "" });
  const { socialMedia } = useSelector((state: RootState) => state.general);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const onClear = () => {
    setSearch("");
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      // Clear the input value when the Escape key is pressed
      setSearch("");
    }
  };

  const handleSearchProfile = async (e: any) => {
    e.preventDefault();
    setProfileSearchLoading(true);
    try {
      if (socialMedia === "instagram") {
        const result = await http.post(apiEndpoint.INSTAGRAM_PROFILE(search));
        navigate(`/profile/${socialMedia}/${result.data.username}`);
      } else {
        const result = await http.post(apiEndpoint.TIKTOK_PROFILE(search));
        navigate(`/profile/${socialMedia}/${result.data.username}`);
      }
      setProfileSearchLoading(false);
    } catch (error: any) {
      console.error(error);
      if (error && error.response && error.response.data) {
        setError({ show: true, message: "Invalid Username" });
      }
      setProfileSearchLoading(false);
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    // Check if the username has changed from the previous state
    if (search !== prevUsername) {
      setError({ show: false, message: "" });
    }
    // Update the previous username
    setPrevUsername(search);
  }, [search, prevUsername]);

  useEffect(() => {
    if (locationParam) {
      setSearch(locationParam.searchKey);
    }
  }, [locationParam]);

  /* ================================================== */
  /* ================================================== */
  return (
    <OuterLayout maxWidth={"xl"}>
      <Paper sx={{ p: smDown ? 1 : 2, display: "grid", placeItems: "center" }}>
        <Stack spacing={2} sx={{ width: mdDown ? "100%" : "800px" }}>
          <Typography variant="h5" fontWeight={400} color="text.secondary">
            Track a new profile
          </Typography>

          <form id="searchForm" onSubmit={handleSearchProfile}>
            <Stack direction={smDown ? "column" : "row"} alignItems="center" spacing={1}>
              <Stack direction={"row"} alignItems="center" spacing={1} sx={{ width: "100%" }}>
                {socialMedia && <SocialMediaDropdown />}
                <Paper elevation={0} sx={{ width: "100%" }}>
                  <TextField
                    onFocus={() => setError({ show: false, message: "" })}
                    onKeyDown={handleKeyDown}
                    variant={"outlined"}
                    hiddenLabel
                    fullWidth
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        py: "14px !important",
                      },
                    }}
                    InputProps={{
                      startAdornment: !smDown && (
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      ),
                      endAdornment: (
                        <>
                          {search !== "" && (
                            <IconButton onClick={onClear} sx={{ padding: 0 }}>
                              <CloseIcon sx={{ height: "20px", width: "20px", mb: "3px" }} />
                            </IconButton>
                          )}
                        </>
                      ),
                    }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Paper>
                <Button type="submit" form="searchForm" size="large" variant="contained" sx={{ px: 4, py: profileSearchLoading ? 1.65 : 1.2 }}>
                  {profileSearchLoading ? (
                    <Box sx={{ display: "grid", placeItems: "center" }}>
                      <CircularProgress size={20} color="inherit" />
                    </Box>
                  ) : smDown ? (
                    <Box>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Box>
                  ) : (
                    "Search"
                  )}
                </Button>
              </Stack>
            </Stack>
          </form>

          {error.show && (
            <Typography variant="body1" color="error.light" fontWeight={500}>
              {search.length >= 50 ? "Username cannot be more than 50 characters" : error.message}
            </Typography>
          )}
          <Stack alignItems="center">
            <img src={Search} alt="insta" width={mdDown ? "80%" : "600px"} />
          </Stack>
        </Stack>
      </Paper>
    </OuterLayout>
  );
};

export default SearchPage;
