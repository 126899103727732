import React /* useState */ from "react";
// import useMediaQueries from "src/hooks/use-mediaqueries";
// import InfoToggleGroup from "src/pages/info/info-toggle-group";
import OuterLayout from "src/components/common/layout/outer-layout";
// import InfoTab, { CustomTabPanel, TInfoTab } from "src/pages/info/info-tab";
import { Box, /*  Grid, */ Paper, Stack, Typography /* useTheme */ } from "@mui/material";
import GoToTopButton from "src/components/common/inputs/go-to-top-button";

interface InfoAboutProps {}

type Props = InfoAboutProps;

const InfoAbout: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  // const theme = useTheme();
  // const { mdDown } = useMediaQueries();
  // const [view, setView] = useState<TInfoTab>("about");

  const content = (
    <Paper elevation={10} sx={{ width: "100%" }}>
      <Box sx={{ p: 4 }}>
        <Stack direction="row" alignItems="center" justifyContent={"space-between"} mb={4}>
          <Typography textAlign="left" variant="h4" fontWeight={500} color="primary.main">
            ABOUT US
          </Typography>
        </Stack>
        <Stack spacing={2} alignItems="flex-start" textAlign={"left"}>
          <Stack spacing={2}>
            <Stack>
              <Typography color="primary" variant="h6" marginBottom="10px">
                DATA
              </Typography>
              <Typography variant="body1" marginBottom="8px">
                goIntec compiles data from YouTube, Twitter, Twitch, Daily Motion, Mixer, and Instagram and uses the data to make statistical graphs
                and charts that track progress and growth. We include information such as estimated earnings and future projections, providing both
                numerical data and easy-to-read graphs. Statistics are freely available to anyone using our website or smartphone apps. Social Blade
                currently tracks 23+ million YouTube channels, 6+ million Twitter Profiles, 5+ million Twitch channels, 206+ thousand Daily Motion
                users, and 416+ thousand Mixer Streamers. goIntec reaches over 7 million unique visitors every month with a good portion of those
                users checking the site every day.
              </Typography>
            </Stack>

            <Stack>
              <Typography color="primary" variant="h6" marginBottom="10px">
                INDUSTRY NEWS & COMMUNITY
              </Typography>
              <Typography variant="body1">
                goIntec operates a news blog that reports on the top stories in the digital media industry. We aim to bring you fast, timely updates
                on everything online video and social media, as well as full coverage of prominent events and breaking news.
                <br />
                <br />
                goIntec also help foster a sense of community between our users through the use of our social media outlets, newsletters, and blog
                posts. We frequently highlight up-and-coming YouTube creators around our website and on our homepage. We attend industry events and
                host meet-ups in an effort to bring online communities to the third dimension.
              </Typography>
            </Stack>
            <Stack>
              <Typography color="primary" variant="h6" marginBottom="10px">
                SUPPORT
              </Typography>
              <Typography variant="body1">
                goIntec provides support for our users through several channels. We have a support ticket system where questions about our products
                and services can be asked and answered. We also provide more general support through our social media and live chat system.
                <br />
                <br />
                For questions directed to our experts that aren't about goIntec's products, we offer premium consulting and channel management
                services to help users grow their online presence and establish personal brands.
              </Typography>
            </Stack>
            <Stack>
              <Typography color="primary" variant="h6" marginBottom="10px">
                HISTORY
              </Typography>
              <Typography variant="body1">
                goIntec was founded in 2008 by Jason Urgo to track statistics of the then-popular website Digg. In 2010, we switched to tracking
                YouTube statistics. Following the breakout success of YouTube statistics we added a number of other platforms such as Twitch
                statistics in 2013, Instagram statistics in 2014, Twitter statistics in 2016, Daily Motion statistics in 2017, and Mixer statistics in
                2018.
                <br />
                <br />
                goIntec was officially incorporated in 2012, and has gone from being Urgo's part-time hobby to a full-fledged business with a full
                team helping to run the company.
              </Typography>
            </Stack>
            <Stack>
              <Typography color="primary" variant="h6" marginBottom="10px">
                FUTURE GOALS
              </Typography>
              <Typography variant="body1">
                goIntec is constantly looking towards the future, and how we can expand to report on more social media sites, provide better services
                to our users, and make data as accessible as possible.
                <br />
                <br />
                We also strive to increase our reach through reporting more industry news on our blog and by forming business relationships with
                respected organizations such as YouTube, Twitch, Amazon, BroadbandTV, Semaphore, and more.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <OuterLayout maxWidth={"lg"}>
      <GoToTopButton />
      {content}
      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={2.5}>
          {!mdDown && (
            <Paper elevation={10} sx={{ py: 2 }}>
              <Stack spacing={2} alignItems="center" justifyContent={"space-between"} sx={{ py: 1, px: 2 }}>
                <Box sx={{ mt: 2, width: "100%" }}>
                  <InfoToggleGroup view={view} setView={setView} />
                </Box>
              </Stack>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={9.5}>
          {mdDown && <InfoTab tab={view} />}
          {mdDown ? (
            <>
              <CustomTabPanel theme={theme} value={view} target={"about"}>
                {content}
              </CustomTabPanel>
            </>
          ) : (
            <>{view === "about" && content}</>
          )}
        </Grid>
      </Grid> */}
    </OuterLayout>
  );
};

export default InfoAbout;
