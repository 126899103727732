import React from "react";
/* components */
import PercentDiff from "src/components/common/data-display/percent-diff";
/* 3rd party lib */
import { faCrown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Stack, TableCell, useTheme } from "@mui/material";
/* Util */
import { ICompareInstaPost } from "src/types/instagram";
import { formatNumberWithSuffix } from "src/utils/general";
import { TDisplayMode } from "src/pages/comparisons/profiles/profiles";
import { useComparisonPostContext } from "src/contexts/comparison-post-context";

interface TableRowCellProps {
  className?: string;
  column: any;
  // postObj: TInstaData | TTikTokData | null;
  postObj?: ICompareInstaPost | null;
  index: number;
  columnWidth?: string;
  largestNumDict: { [key: string]: number } | {};
  percentageDiff?: TDisplayMode;
}

type Props = TableRowCellProps;

export const largestNumColor = "#46c46c";

const TableRowCell: React.FC<Props> = ({ className, columnWidth, percentageDiff, column, postObj, index, largestNumDict }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  let largestNum = (largestNumDict as any)[column.key];

  const postWithEngagement = (key: string) => {
    if (key === "engagement_rate") {
      return postObj && postObj.post.engagement_stats[key];
    }

    return postObj && postObj.date_last[key];
  };

  // const actualNum = postObj && (postObj as any).statistics.length > 0 && (postObj as any).statistics[postObj.statistics.length - 1][column.key];
  // const stat = postObj && (postObj as any).statistics.length > 0 && (postObj as any).statistics[postObj.statistics.length - 1][column.key];

  let actualNum = postWithEngagement(column.key);

  const { tableDirection } = useComparisonPostContext();
  const stat = postWithEngagement(column.key);

  if (column.content) {
    return (
      <TableCell sx={column.cellStyle} className={`${mode === "light" ? "tablecell" : "tablecell-dark"} text-center ${className}`}>
        {column.content(postObj, index)}
      </TableCell>
    );
  } else {
    return (
      <TableCell
        sx={{
          ...column.cellStyle,
          position: "relative",
          width: columnWidth,
          fontWeight: actualNum && largestNum === actualNum && actualNum > 0 ? 600 : 500,
          color: actualNum && largestNum === actualNum && actualNum > 0 ? largestNumColor : theme.palette.text.secondary,
          minWidth: tableDirection === "horizontal" ? "60px" : "160px",
        }}
        className={`${mode === "light" ? "tablecell" : "tablecell-dark"} text-center ${className}`}
      >
        {/* original absolute crown */}

        <Grid container>
          {tableDirection === "vertical" && (
            <Grid item xs={2}>
              {actualNum && largestNum === actualNum && actualNum > 0 ? (
                <Box
                  sx={{
                    "& svg": {
                      color: "#ffd83b",
                    },
                  }}
                >
                  <FontAwesomeIcon icon={faCrown} size="lg" />
                </Box>
              ) : (
                <Box />
              )}
            </Grid>
          )}

          <Grid item xs={tableDirection === "horizontal" ? 12 : 8} alignItems="center" justifyContent={"center"} display="flex">
            <Stack spacing={0.5} direction="row" alignItems="center">
              {tableDirection === "horizontal" && (
                <>
                  {actualNum && largestNum === actualNum && actualNum > 0 ? (
                    <Box
                      sx={{
                        lineHeight: "14px",
                        "& svg": {
                          color: "#ffd83b",
                          // transform: "rotate(30deg)",
                        },
                      }}
                    >
                      <FontAwesomeIcon icon={faCrown} size={tableDirection === "horizontal" ? "sm" : "lg"} />
                    </Box>
                  ) : (
                    <Box />
                  )}
                </>
              )}
              <Box sx={{ fontSize: tableDirection === "horizontal" ? "14px" : "15px", lineHeight: "14px" }}>
                {/* 
            @TODO
              attempting to add total posts for tiktok in comparison
              {postObj
                ? column.key === "count_post"
                  ? (postObj as any)["category_name" in postObj ? "count_post" : "count_video"] &&
                    formatNumberWithSuffix((postObj as any)["category_name" in postObj ? "count_post" : "count_video"])
                  : ((postObj as any)[column.key] && formatNumberWithSuffix((postObj as any)[column.key])) ?? ""
                : ""}

              */}
                {postObj
                  ? (stat && typeof stat === "number" && `${formatNumberWithSuffix(stat)}${column.key === "engagement_rate" ? "%" : ""}`) ?? ""
                  : ""}
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={tableDirection === "horizontal" ? 12 : 2}>
            <Stack direction="row" justifyContent={tableDirection === "horizontal" ? "center" : "flex-end"}>
              {percentageDiff && postObj && (
                <PercentDiff
                  simpleDisplay
                  fontSize={11}
                  tableDirection={tableDirection}
                  displayMode={percentageDiff}
                  difference={postObj.gained[`${column.key}_num`]}
                  percentage={postObj.gained[`${column.key}_percentage`]}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </TableCell>
    );
  }
};

export default TableRowCell;
