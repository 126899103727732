import React, { useEffect, useState } from "react";
/* components */
/* 3rd party lib */

//@ts-ignore
import ReactRecaptcha3 from "react-google-recaptcha3";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Grid,
  Link,
  styled,
  Stack,
  Button,
  TextField,
  Typography,
  FormControl,
  IconButton,
  useTheme,
  lighten,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { getTranslateString } from "src/utils/translate";
import { FORM_WARNING_PASSWORD_MUST_MATCH, FORM_WARNING_REQUIRED, FORM_WARNING_VALID_EMAIL } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
import { useDispatch } from "react-redux";
import { setTheme } from "src/slices/general";
// import { useSelector } from "react-redux";
// import { RootState } from "src/store";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import useMediaQueries from "src/hooks/use-mediaqueries";
import SignUpImg from "src/images/signup_bg.png";
import LogoLight from "src/images/logo_light.png";
import LogoDark from "src/images/logo_dark.png";
import { toast } from "react-hot-toast";
import useHttp from "src/hooks/use-http";
import SpinnerComponent from "src/components/common/feedback/spinner";
import axios from "axios";
import { apiEndpoint } from "src/contexts/http-context";
import InfoPrivacy from "./info/privacy";

const StyledTextField = styled(TextField)(() => ({
  "& input": {
    overflow: "hidden",
    borderRadius: "8px",
    padding: "20px 8px",
  },
  "& .MuiInput-root": {
    overflow: "hidden",
    borderRadius: "8px",
  },
}));

/* Util */
interface SignUpProps {}

type Props = SignUpProps;

const schema = (t: any) =>
  Yup.object().shape({
    password1: Yup.string()
      .required(getTranslateString(t, FORM_WARNING_REQUIRED))
      .oneOf([Yup.ref("password2")], getTranslateString(t, FORM_WARNING_PASSWORD_MUST_MATCH)),
    password2: Yup.string()
      .required(getTranslateString(t, FORM_WARNING_REQUIRED))
      .oneOf([Yup.ref("password1")], getTranslateString(t, FORM_WARNING_PASSWORD_MUST_MATCH)),
    email: Yup.string().email(getTranslateString(t, FORM_WARNING_VALID_EMAIL)).required(getTranslateString(t, FORM_WARNING_REQUIRED)),
  });

const SignUp: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const isDevelopment = process.env.NODE_ENV === "development";

  const { t } = useTranslation();
  const bottomHeight = 60;
  const navigate = useNavigate();
  const { mdDown } = useMediaQueries();
  const [showPassword1, setShowPassword1] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  // const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const { http } = useHttp();
  const theme = useTheme();
  const { mode } = theme.palette;
  const dispatch = useDispatch();
  const formik = useFormik({
    validateOnChange: false,
    validationSchema: schema(t),
    initialValues: {
      password1: "",
      password2: "",
      email: "",
      tns: false,
    },
    onSubmit: async values => {
      setLoading(true);

      try {
        let token = "";

        // Get reCAPTCHA token only if not in development mode
        if (!isDevelopment) {
          token = await ReactRecaptcha3.getToken();
        }

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiEndpoint.SIGN_UP.substring(1)}`, {
          email: values.email,
          password1: values.password1,
          password2: values.password2,
          ...(token && { recaptcha_token: token }), // Include recaptcha_token only if it's present
        });

        const data = response.data;

        if (data) {
          localStorage.setItem("accessToken", data.access);
          localStorage.setItem("refreshToken", data.refresh);
          http.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;
        }

        setLoading(false);
        navigate(routes.VERIFY);
      } catch (error: any) {
        if (error && error.response && error.response.data) {
          if (error.response.data.password1) {
            toast.error(error.response.data.password1.join(" "));
          }
          if (error.response.data.email) {
            toast.error(error.response.data.email.join(" "));
          }
          if (error.response.data.detail) {
            toast.error(error.response.data.detail);
          }
          if (error.response.data.non_field_errors) {
            toast.error(error.response.data.non_field_errors.join(" "));
          }
          if (error.response.data.error) {
            toast.error(error.response.data.error);
          }
        }
        setLoading(false);
        console.error(error);
      }
    },
  });
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate(routes.HOME, { replace: true });
  //   }
  // }, [isAuthenticated, navigate]);
  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_KEY).then((status: any) => {
      // status: success/error
      // success - script is loaded and greaptcha is ready
      // error - script is not loaded
    });
  }, []);
  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Box>
        <Button onClick={() => navigate(routes.ROOT)} sx={{ position: "absolute", left: "16px", top: "16px" }}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <img src={mode === "light" ? LogoLight : LogoDark} alt="logo" height="40px" />
          </Stack>
        </Button>

        <IconButton
          sx={{ ml: 1, position: "absolute", right: "16px", top: "16px" }}
          onClick={() => {
            if (mode === "dark") {
              dispatch(setTheme("light"));
            } else {
              dispatch(setTheme("dark"));
            }
          }}
        >
          {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        <Grid container sx={{ height: "100vh", width: "100vw" }}>
          {!mdDown && (
            <Grid item xs={12} md={6} lg={7} xl={8} sx={{ display: "grid", placeItems: "center" }}>
              <img alt="sign_up_illustration" style={{ borderRadius: "8px" }} src={SignUpImg} width="50%" />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            xl={4}
            sx={{
              display: "grid",
              placeItems: "center",
              background:
                mode === "light" ? (mdDown ? "white" : lighten((theme.palette.neutral as any)[100], 0.1)) : (theme.palette.neutral as any)[900],
            }}
          >
            <Box
              py={3}
              sx={{
                display: "grid",
                placeItems: "center",
                height: `calc(100% - ${bottomHeight}px)`,
                width: { xs: "90%", sm: "400px", md: "90%" },
              }}
            >
              <Box>
                <Stack alignItems={mdDown ? "center" : "flex-start"} spacing={1} mb={3}>
                  <Typography variant="h4" color="secondary.main" fontWeight="bolder">
                    Just a few more steps
                  </Typography>
                  <Typography variant="body1" textAlign={mdDown ? "center" : "start"}>
                    Create your free account today and unlock insights into performance of your favourite social media accounts.
                  </Typography>
                </Stack>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <StyledTextField
                          hiddenLabel
                          type="email"
                          variant="outlined"
                          name="email"
                          placeholder="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.errors.email ? true : false}
                          helperText={formik.errors.email}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <StyledTextField
                          variant="outlined"
                          hiddenLabel
                          name="password1"
                          placeholder="Password"
                          value={formik.values.password1}
                          onChange={formik.handleChange}
                          error={formik.errors.password1 ? true : false}
                          helperText={formik.errors.password1}
                          InputProps={{
                            autoComplete: "current-password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  tabIndex={-1}
                                  sx={{ color: theme.palette.primary.main }}
                                  onClick={() => setShowPassword1(!showPassword1)}
                                  edge="end"
                                >
                                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showPassword1 ? "text" : "password"}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <StyledTextField
                          variant="outlined"
                          hiddenLabel
                          name="password2"
                          placeholder="Confirm Password"
                          value={formik.values.password2}
                          onChange={formik.handleChange}
                          error={formik.errors.password2 ? true : false}
                          helperText={formik.errors.password2}
                          InputProps={{
                            autoComplete: "current-password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  tabIndex={-1}
                                  sx={{ color: theme.palette.primary.main }}
                                  onClick={() => setShowPassword2(!showPassword2)}
                                  edge="end"
                                >
                                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showPassword2 ? "text" : "password"}
                          sx={{
                            "& input:-webkit-autofill": {
                              "-webkit-box-shadow": formik.values.password2
                                ? `0 0 0 100px ${mode === "dark" ? (theme.palette.neutral as any)[800] : "#ffffff"} inset !important`
                                : "none !important",
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <Stack direction="row" alignItems="center" justifyContent={"flex-start"}>
                        <FormControlLabel
                          control={<Checkbox checked={formik.values.tns} onChange={e => formik.setFieldValue("tns", e.target.checked)} />}
                          label={
                            <Stack direction="row" alignItems="center" justifyContent={"flex-start"}>
                              <Typography variant="body1" sx={{ whiteSpace: "nowwrap" }}>
                                By signing up you agree to our&nbsp;
                                <Link
                                  onClick={() => setOpenPrivacyPolicy(true)}
                                  variant="body1"
                                  color="primary.main"
                                  underline="hover"
                                  sx={{ cursor: "pointer", whiteSpace: "nowwrap" }}
                                >
                                  Privacy Policy & Terms
                                </Link>
                              </Typography>
                            </Stack>
                          }
                          componentsProps={{ typography: { fontSize: 14 } }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={formik.isSubmitting || loading || formik.values.tns === false}
                      endIcon={loading ? <SpinnerComponent color="white" modifier={0.4} /> : null}
                    >
                      SIGN UP
                    </Button>
                  </Box>
                  <Box sx={{ mt: 2, userSelect: "none" }}>
                    <Typography>
                      Already have an account?&nbsp;
                      <Link sx={{ cursor: "pointer" }} href={routes.LOGIN}>
                        Login Here
                      </Link>
                    </Typography>
                  </Box>
                </form>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <InfoPrivacy open={openPrivacyPolicy} onClose={() => setOpenPrivacyPolicy(false)} isDialog />
    </>
  );
};

export default SignUp;
