import React, { useRef } from "react";
/* components */
/* 3rd party lib */
import ReactEcharts from "echarts-for-react";
import NoDataFound from "src/components/common/feedback/no-data-found";
import SpinnerComponent from "src/components/common/feedback/spinner";
import { alpha, useTheme } from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";
/* Util */
interface LineChartProps {
  simple?: boolean;
  xAxisData: string[];
  yAxisData: number[];
  dataZoom?: boolean;
  onDataZoom?: Function;
  emptyChart?: boolean;
  color: { line: string; area: string };
}

export interface IDataZoom {
  animation: any;
  dataZoomId: string;
  end: number;
  from: string;
  start: number;
  type: string;
}

type Props = LineChartProps;

const LineChart: React.FC<Props> = ({ color, emptyChart, simple = false, dataZoom = false, onDataZoom, xAxisData, yAxisData }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const { mode } = theme.palette;
  const { lgUp } = useMediaQueries();
  const chartRef = useRef<ReactEcharts | null>(null);

  // Calculate min and max dynamically as a percentage of the data range
  const dataRange = Math.max(...yAxisData) - Math.min(...yAxisData);
  const min = Math.max(0, Math.floor(Math.min(...yAxisData) - dataRange * 0.1)); // Ensure min is non-negative and round down to the nearest integer
  const max = Math.ceil(Math.max(...yAxisData) + dataRange * 0.1); // Round up to the nearest integer

  const options = {
    grid: {
      top: simple ? 0 : 20,
      bottom: simple ? 0 : 60,
      left: simple ? 10 : lgUp ? 20 : 40,
      right: simple ? 10 : lgUp ? 20 : 40,
      containLabel: true,
    },
    animationDuration: 500,
    xAxis: {
      show: simple ? false : true,
      type: "category",
      boundaryGap: false,

      data: xAxisData,
      axisLabel: {
        show: simple ? false : true,
        color: mode === "light" ? "rgb(110, 112, 121)" : "white", // Change the font color for the xAxis labels
      },
    },
    yAxis: {
      type: "value",
      min: min,
      max: max,
      show: simple ? false : true,
      axisLabel: {
        show: simple ? false : true,
        color: mode === "light" ? "rgb(110, 112, 121)" : "white", // Change the font color for the yAxis labels
      },
      splitLine: {
        show: true, // Set to true to display grid lines
        lineStyle: {
          color: (theme.palette.neutral as any)[mode === "dark" ? 700 : 200], // Specify the color for the grid lines
        },
      },
    },
    series: [
      {
        data: yAxisData,
        type: "line",
        smooth: true,
        showSymbol: yAxisData.length === 1 ? true : false,
        areaStyle: simple
          ? null
          : {
              color: alpha(color.area, mode === "light" ? 1 : 0.5),
            },
        lineStyle: {
          color: color.line, // Change the line color to blue
        },
        itemStyle: {
          color: color.line, // Change the marker color to red
        },
        emphasis: {
          focus: "series",
        },
      },
    ],
    tooltip: {
      show: simple ? false : true,
      trigger: "axis",
    },

    dataZoom: dataZoom
      ? [
          {
            type: "inside",
          },
          {
            type: "slider",
            start: xAxisData.length > 24 ? 80 : 0, // Initial start position (0%)
            end: xAxisData.length > 24 ? 100 : 100, // Initial end position (20%)
          },
        ]
      : [],
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      {xAxisData ? (
        xAxisData.length === 0 ? (
          emptyChart ? null : (
            <NoDataFound image />
          )
        ) : (
          <ReactEcharts
            ref={chartRef}
            option={options}
            style={{ width: "100%", height: "100%", WebkitTapHighlightColor: "transparent" }}
            opts={{ renderer: "svg" }} // Use SVG renderer for better resizing
            onEvents={onDataZoom ? { dataZoom: onDataZoom } : undefined}
            //@ts-ignore
            autoResize={true} // Enable autoResize to make the height flexible
          />
        )
      ) : (
        <SpinnerComponent modifier={1.2} />
      )}
    </>
  );
};

export default LineChart;
