import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Helper for populating a JSON with all the translation strings.
// With this, each locale will be populated with namespaces based on the filename
const compileStrings = ctx => {
  const compiled = { resources: {}, ns: [] };
  // This regex does not work for en-US, en-UK, etc.
  const fileRegex = /(?<context>\w*)\/(?<namespace>\w+)\/(?<fileName>\w+)\.(?<locale>\w+)\.json$/;

  ctx.keys().forEach(file => {
    const { fileName, namespace, locale } = file.match(fileRegex).groups;

    compiled.ns.push(namespace);
    compiled.resources[locale] = !!compiled.resources[locale] ? compiled.resources[locale] : {};
    compiled.resources[locale][fileName] = ctx(file);
  });

  // Filter unique namespaces
  compiled.ns = compiled.ns.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return compiled;
};

const strings = compileStrings(require.context("../public/locales", true, /\.\w+\.json$/));

i18n.use(initReactI18next).init({
  resources: strings.resources,
  lng: "en",
  debug: false,
  fallbackLng: "en",
  whitelist: ["en", "cn", "bm"],
  interpolation: {
    escapeValue: false,
  },
});
