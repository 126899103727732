import React from "react";
/* components */
import PresetSelectionList from "src/components/comparison/preset-selection-list";
/* 3rd party lib */
/* Util */
import { useComparisonProfileContext } from "src/contexts/comparison-profile-context";
import { ICurrentActivePostPreset } from "src/pages/comparisons/posts/posts";
import { ICurrentActiveProfilePreset } from "src/pages/comparisons/profiles/profiles";
import { IProfilePresetData } from "src/types/instagram";

interface ProfilePresetSelectionListProps {
  tableHeight?: number;
  onClose?: () => void;
}

type Props = ProfilePresetSelectionListProps;

const ProfilePresetSelectionList: React.FC<Props> = ({ onClose, tableHeight }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  // passing these into presetsleection list instead of using context because the component is shared with post
  const {
    collapse,
    presetData,
    currentActive,
    triggerVibration,
    setCollapse,
    setPresetDialog,
    setCurrentActive,
    setProfileUsernames,
    setEditPresetDialog,
    setCrudPresetDialog,
  } = useComparisonProfileContext();

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */

  return (
    <>
      <PresetSelectionList
        collapse={collapse}
        setCollapse={setCollapse}
        tableHeight={tableHeight}
        currentActive={currentActive}
        setPresetDialog={setPresetDialog}
        triggerVibration={triggerVibration}
        setCrudPresetDialog={setCrudPresetDialog}
        presetData={presetData as IProfilePresetData[]}
        setCurrentActive={setCurrentActive as React.Dispatch<React.SetStateAction<ICurrentActiveProfilePreset | ICurrentActivePostPreset>>}
        handlePresetClick={(index, preset) => {
          if (preset && "profiles" in preset) {
            setCurrentActive({ index: index, preset: preset });

            if (preset.profiles.length === 0) {
              // if the length is 0, set the array to empty strings
              setProfileUsernames(["", ""]);
            } else {
              if (preset.profiles.length === 1) {
                setProfileUsernames([preset.profiles[0].username, ""]);
              } else {
                setProfileUsernames(preset.profiles.map(child => child.username));
              }
            }
          }
          if (onClose) {
            onClose();
          }
        }}
        handleEditPreset={(e, preset) => {
          if (preset && "profiles" in preset) {
            e.stopPropagation();
            setEditPresetDialog({ open: true, preset });
          }
        }}
      />
    </>
  );
};

export default ProfilePresetSelectionList;
