import React from "react";
/* components */
import DataDisplay from "src/components/common/inputs/data-display";
/* 3rd party lib */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  useTheme,
  OutlinedInput,
  Paper,
} from "@mui/material";
import useSWR from "swr";
import moment from "moment";
import { FormikProps } from "formik";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Util */
import useHttp from "src/hooks/use-http";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { ISubscriptionPackageData, TSubscriptionPlan } from "src/types/common";
import { ICreatePlanDialog, IFormikSubscriptionPlan } from "src/pages/admin/subscription-plan-table";
import { faCalendarClock, faTurnUp } from "@fortawesome/pro-solid-svg-icons";
import { SUBSCRIPION_KEYS } from "src/utils/constants";

interface CreateSubscriptionDialogProps {
  loading: boolean;
  onClose: () => void;
  createDialogOpen: ICreatePlanDialog;
  formik: FormikProps<IFormikSubscriptionPlan>;
  currentPlanData: TSubscriptionPlan | undefined;
  setCreateDialogOpen: React.Dispatch<React.SetStateAction<ICreatePlanDialog>>;
}

interface RootProps {
  maxWidth?: number | string;
}

const StyledDateTextField = styled(TextField, { shouldForwardProp: props => props !== "disablePadding" })<RootProps>(({ maxWidth }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    paddingBottom: "9px !important",
    paddingTop: "8px !important",
    maxWidth: maxWidth,
    width: "100%",
  },
}));

type Props = CreateSubscriptionDialogProps;

const CreateSubscriptionDialog: React.FC<Props> = ({ createDialogOpen, formik, loading, onClose, currentPlanData, setCreateDialogOpen }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { apiEndpoint } = useHttp();
  const { smDown, mdDown } = useMediaQueries();
  const currentPlanExist = currentPlanData && currentPlanData.account !== null;
  const currentPlanExpired = currentPlanData && currentPlanData.subscription_status === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS;
  const { data } = useSWR<ISubscriptionPackageData>([apiEndpoint.SUBSCRIPTION_PLAN_PACKAGE]);
  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleFocus = (event: any) => {
    event.target.select();
  };

  const getDurationDifference = (start: string, end: string) => {
    return Math.abs(moment(start).diff(moment(end), "days"));
  };

  const subscriptionButtonStyle = {
    display: "grid",
    placeItems: "center",
    textAlign: "center",
    width: "50%",
    py: 3,
    cursor: "pointer",
    userSelect: "none",
    minHeight: "277px",
    "&:hover": {
      background: theme.palette.primary.main,
      transition: "all 0.5s ease",
      ".MuiTypography-root": {
        color: "white",
      },
      ".svg-inline--fa": {
        color: "white",
      },
    },
    "&:active": {
      background: theme.palette.mode === "light" ? "white" : (theme.palette.neutral as any)[900],
      transition: "none",
      ".MuiTypography-root": {
        color: theme.palette.mode === "light" ? theme.palette.grey[500] : "white",
      },
      ".svg-inline--fa": {
        color: theme.palette.primary.main,
      },
    },
  };

  const closeDialog = () => {
    setCreateDialogOpen({ open: false, planType: "choose" });
    onClose();
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog maxWidth="md" fullWidth open={createDialogOpen.open} onClose={closeDialog}>
      <form id="create_account_form" onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ p: 2 }}>
          <Stack alignItems={"center"} direction="row" spacing={3}>
            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              Create Subscription Plan
            </Typography>
            <IconButton onClick={closeDialog}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {createDialogOpen.planType === "choose" && (
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Paper
                elevation={10}
                onClick={() => {
                  formik.setFieldValue("start_time", moment());

                  setCreateDialogOpen({ ...createDialogOpen, planType: "immediate" });
                }}
                sx={subscriptionButtonStyle}
              >
                <Stack>
                  <Box sx={{ py: 2 }}>
                    <FontAwesomeIcon icon={faTurnUp} color={theme.palette.primary.main} size="3x" />
                  </Box>
                  <Typography variant="h6" fontWeight={700}>
                    Upgrade Now
                  </Typography>
                  <Typography variant="body2" color="grey.500">
                    (Start From Today)
                  </Typography>
                </Stack>
              </Paper>
              <Paper
                elevation={10}
                onClick={() => {
                  if (currentPlanData) {
                    formik.setFieldValue("start_time", moment(currentPlanData.end_time).add("1", "days"));
                  }

                  setCreateDialogOpen({ ...createDialogOpen, planType: "after" });
                }}
                sx={subscriptionButtonStyle}
              >
                <Stack>
                  <Box sx={{ py: 2 }}>
                    <FontAwesomeIcon icon={faCalendarClock} color={theme.palette.primary.main} size="3x" />
                  </Box>
                  <Typography variant="h6" fontWeight={700}>
                    Upgrade Later
                  </Typography>
                  <Typography variant="body2" color="grey.500">
                    (After Current Plan Ends)
                  </Typography>
                </Stack>
              </Paper>
            </Stack>
          )}

          {createDialogOpen.planType === "immediate" && (
            <Paper sx={{ p: 2, mt: 2 }} elevation={5}>
              <Grid container sx={{ pt: 2 }} spacing={2}>
                {currentPlanExist && !currentPlanExpired && (
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <FontAwesomeIcon icon={faTurnUp} color={theme.palette.primary.main} size="2x" />
                      <Typography variant="h6" fontWeight={700}>
                        Upgrade Now
                      </Typography>
                    </Stack>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <DataDisplay
                    alignCenter={false}
                    label={"Start Date"}
                    variant={"body2"}
                    value={
                      <MobileDatePicker
                        closeOnSelect
                        mask="__-__-____"
                        // name="start_max"
                        value={formik.values.start_time}
                        inputFormat={"DD-MM-YYYY"}
                        onChange={date => {
                          const start_time = date;
                          formik.setFieldValue("start_time", start_time);
                          if (formik.values.end_time && start_time) {
                            // if end time exist, then get the duration difference
                            formik.setFieldValue("duration", getDurationDifference(start_time, formik.values.end_time));
                            if (moment(formik.values.end_time).isBefore(moment(start_time))) {
                              //  if end time is less than start time then change end time to start time
                              formik.setFieldValue("end_time", start_time);
                              formik.setFieldValue("duration", getDurationDifference(start_time, start_time));
                            }
                          }
                        }}
                        renderInput={params => (
                          <StyledDateTextField
                            {...params}
                            variant="outlined"
                            name="start_time"
                            hiddenLabel
                            maxWidth={"100%"}
                            error={formik.errors.start_time ? true : false}
                            helperText={formik.errors.start_time}
                            placeholder={mdDown ? "Start Date" : "Click to enter start date"}
                            value={moment(formik.values.start_time).format("YYYY-MM-DD")}
                          />
                        )}
                        InputProps={{
                          required: true,
                          // Add the icon as the end adornment
                          endAdornment: (
                            <InputAdornment position="end">
                              <FontAwesomeIcon icon={faCalendar} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    alignCenter={false}
                    label={"End Date"}
                    variant={"body2"}
                    value={
                      <MobileDatePicker
                        closeOnSelect
                        mask="__-__-____"
                        // name="start_max"
                        minDate={moment(formik.values.start_time).format("YYYY-MM-DD")}
                        value={formik.values.end_time}
                        inputFormat={"DD-MM-YYYY"}
                        onChange={date => {
                          const end_time = date;
                          formik.setFieldValue("end_time", end_time);
                          if (formik.values.start_time && end_time) {
                            // if end time exist, then get the duration difference
                            formik.setFieldValue("duration", getDurationDifference(end_time, formik.values.start_time));
                          }
                        }}
                        renderInput={params => (
                          <StyledDateTextField
                            {...params}
                            required
                            variant="outlined"
                            name="end_time"
                            hiddenLabel
                            maxWidth={"100%"}
                            error={formik.errors.end_time ? true : false}
                            helperText={formik.errors.end_time}
                            placeholder={mdDown ? "End Date" : "Click to enter end date"}
                            value={moment(formik.values.end_time).format("YYYY-MM-DD")}
                          />
                        )}
                        InputProps={{
                          required: true,
                          // Add the icon as the end adornment
                          endAdornment: (
                            <InputAdornment position="end">
                              <FontAwesomeIcon icon={faCalendar} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    label={"Duration (Days)"}
                    variant={"body2"}
                    alignCenter={false}
                    value={
                      <Box sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                          <TextField
                            type="number"
                            value={formik.values.duration}
                            fullWidth
                            variant="outlined"
                            hiddenLabel
                            name="duration"
                            size="small"
                            placeholder="Enter Duration"
                            onChange={e => {
                              formik.handleChange(e);
                              // when duration is changed, make sure both start and end time exist and add time to start
                              if (formik.values.start_time) {
                                formik.setFieldValue("end_time", moment(formik.values.start_time).add(e.target.value, "days"));
                              }
                            }}
                            InputProps={{ inputProps: { min: 0 }, onFocus: handleFocus, onClick: handleFocus }}
                          />
                        </FormControl>
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    label={"Package"}
                    variant={"body2"}
                    value={
                      <Box sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                          <Select
                            value={formik.values.package}
                            onChange={e => {
                              formik.setFieldValue("package", e.target.value);
                            }}
                            size="small"
                            displayEmpty
                            required
                            placeholder="Select Package"
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={selected => {
                              if ((selected && selected.toString().toLowerCase() === "null") || selected === null) {
                                return (
                                  <Typography fontStyle={"normal"} variant="body2" fontWeight={"light"} color={"text.disabled"}>
                                    Select a Package
                                  </Typography>
                                );
                              } else {
                                return data?.results.filter(child => child.id === parseInt(selected))[0].title;
                              }
                            }}
                            input={
                              <OutlinedInput
                                size="small"
                                sx={{
                                  ".MuiNativeSelect-select": {
                                    background: "none",
                                  },
                                  ".MuiSelect-select": {
                                    background: "none",
                                  },

                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: formik.errors.package && formik.values.package === "null" ? "#D14343" : "",
                                  },
                                }}
                              />
                            }
                          >
                            <MenuItem value={"null"} sx={{ textTransform: "uppercase", color: theme.palette.text.disabled }}>
                              Select a package
                            </MenuItem>

                            {data?.results.map(child => (
                              <MenuItem value={child.id} key={child.id}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <Typography variant="body1">{child.title}</Typography>
                                </Stack>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {formik.errors.package && formik.values.package === "null" && <FormHelperText error>{formik.errors.package}</FormHelperText>}
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    label={"Remark"}
                    alignCenter={false}
                    variant={smDown ? "caption" : "body2"}
                    value={
                      <TextField
                        value={formik.values.remark}
                        fullWidth
                        variant="outlined"
                        hiddenLabel
                        name="remark"
                        size="small"
                        multiline={true}
                        minRows={2}
                        placeholder="Enter Remark"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
          {createDialogOpen.planType === "after" && (
            <Paper sx={{ p: 2, mt: 2 }} elevation={5}>
              <Grid container sx={{ pt: 2 }} spacing={2}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <FontAwesomeIcon icon={faCalendarClock} color={theme.palette.primary.main} size="2x" />
                    <Typography variant="h6" fontWeight={700}>
                      Upgrade Later
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <DataDisplay
                    alignCenter={false}
                    label={"Start Date"}
                    variant={"body2"}
                    value={
                      <MobileDatePicker
                        closeOnSelect
                        mask="__-__-____"
                        // name="start_max"
                        value={formik.values.start_time}
                        inputFormat={"DD-MM-YYYY"}
                        onChange={date => {
                          const start_time = date;
                          formik.setFieldValue("start_time", start_time);
                          if (formik.values.end_time && start_time) {
                            // if end time exist, then get the duration difference
                            formik.setFieldValue("duration", getDurationDifference(start_time, formik.values.end_time));
                            if (moment(formik.values.end_time).isBefore(moment(start_time))) {
                              //  if end time is less than start time then change end time to start time
                              formik.setFieldValue("end_time", start_time);
                              formik.setFieldValue("duration", getDurationDifference(start_time, start_time));
                            }
                          }
                        }}
                        renderInput={params => (
                          <StyledDateTextField
                            {...params}
                            variant="outlined"
                            name="start_time"
                            hiddenLabel
                            maxWidth={"100%"}
                            error={formik.errors.start_time ? true : false}
                            helperText={formik.errors.start_time}
                            placeholder={mdDown ? "Start Date" : "Click to enter start date"}
                            value={moment(formik.values.start_time).format("YYYY-MM-DD")}
                          />
                        )}
                        InputProps={{
                          required: true,
                          // Add the icon as the end adornment
                          endAdornment: (
                            <InputAdornment position="end">
                              <FontAwesomeIcon icon={faCalendar} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    alignCenter={false}
                    label={"End Date"}
                    variant={"body2"}
                    value={
                      <MobileDatePicker
                        closeOnSelect
                        mask="__-__-____"
                        // name="start_max"
                        minDate={moment(formik.values.start_time).format("YYYY-MM-DD")}
                        value={formik.values.end_time}
                        inputFormat={"DD-MM-YYYY"}
                        onChange={date => {
                          const end_time = date;
                          formik.setFieldValue("end_time", end_time);
                          if (formik.values.start_time && end_time) {
                            // if end time exist, then get the duration difference
                            formik.setFieldValue("duration", getDurationDifference(end_time, formik.values.start_time));
                          }
                        }}
                        renderInput={params => (
                          <StyledDateTextField
                            {...params}
                            required
                            variant="outlined"
                            name="end_time"
                            hiddenLabel
                            maxWidth={"100%"}
                            error={formik.errors.end_time ? true : false}
                            helperText={formik.errors.end_time}
                            placeholder={mdDown ? "End Date" : "Click to enter end date"}
                            value={moment(formik.values.end_time).format("YYYY-MM-DD")}
                          />
                        )}
                        InputProps={{
                          required: true,
                          // Add the icon as the end adornment
                          endAdornment: (
                            <InputAdornment position="end">
                              <FontAwesomeIcon icon={faCalendar} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    label={"Duration (Days)"}
                    variant={"body2"}
                    alignCenter={false}
                    value={
                      <Box sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                          <TextField
                            type="number"
                            value={formik.values.duration}
                            fullWidth
                            variant="outlined"
                            hiddenLabel
                            name="duration"
                            size="small"
                            placeholder="Enter Duration"
                            onChange={e => {
                              formik.handleChange(e);
                              // when duration is changed, make sure both start and end time exist and add time to start
                              if (formik.values.start_time) {
                                formik.setFieldValue("end_time", moment(formik.values.start_time).add(e.target.value, "days"));
                              }
                            }}
                            InputProps={{ inputProps: { min: 0 }, onFocus: handleFocus, onClick: handleFocus }}
                          />
                        </FormControl>
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    label={"Package"}
                    variant={"body2"}
                    value={
                      <Box sx={{ width: "100%" }}>
                        <FormControl fullWidth>
                          <Select
                            value={formik.values.package}
                            onChange={e => {
                              formik.setFieldValue("package", e.target.value);
                            }}
                            size="small"
                            displayEmpty
                            required
                            placeholder="Select Package"
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={selected => {
                              if ((selected && selected.toString().toLowerCase() === "null") || selected === null) {
                                return (
                                  <Typography fontStyle={"normal"} variant="body2" fontWeight={"light"} color={"text.disabled"}>
                                    Select a Package
                                  </Typography>
                                );
                              } else {
                                return data?.results.filter(child => child.id === parseInt(selected))[0].title;
                              }
                            }}
                            input={
                              <OutlinedInput
                                size="small"
                                sx={{
                                  ".MuiNativeSelect-select": {
                                    background: "none",
                                  },
                                  ".MuiSelect-select": {
                                    background: "none",
                                  },

                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: formik.errors.package && formik.values.package === "null" ? "#D14343" : "",
                                  },
                                }}
                              />
                            }
                          >
                            <MenuItem value={"null"} sx={{ textTransform: "uppercase", color: theme.palette.text.disabled }}>
                              Select a package
                            </MenuItem>

                            {data?.results.map(child => (
                              <MenuItem value={child.id} key={child.id}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <Typography variant="body1">{child.title}</Typography>
                                </Stack>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {formik.errors.package && formik.values.package === "null" && <FormHelperText error>{formik.errors.package}</FormHelperText>}
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DataDisplay
                    label={"Remark"}
                    alignCenter={false}
                    variant={smDown ? "caption" : "body2"}
                    value={
                      <TextField
                        value={formik.values.remark}
                        fullWidth
                        variant="outlined"
                        hiddenLabel
                        name="remark"
                        size="small"
                        multiline={true}
                        minRows={2}
                        placeholder="Enter Remark"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </DialogContent>
      </form>
      {createDialogOpen.planType !== "choose" && (
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              if (currentPlanExist && !currentPlanExpired) {
                // if current plan exist, then back button supposed to change the dialog back to choose section
                setCreateDialogOpen({ ...createDialogOpen, planType: "choose" });
              } else {
                // if no plan exist, then whole dialog should be closed
                setCreateDialogOpen({ open: false, planType: null });
              }
            }}
          >
            {currentPlanExist && !currentPlanExpired ? "Back" : "Cancel"}
          </Button>
          <Button form="create_account_form" type="submit" disabled={loading} variant="contained" color={"primary"}>
            Create
            {loading && <CircularProgress sx={{ ml: 1 }} size={18} color="inherit" />}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CreateSubscriptionDialog;
