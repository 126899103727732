/* components */
import DiffCount from "src/components/common/data-display/diff-count";
import DataTableList from "src/components/common/data-display/data-table-list";
import ProfileWidgets from "src/components/profile/instagram/profile-widgets";
/* 3rd party lib */
import moment from "moment";
import { FormikProps } from "formik";
import { Box, Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
/* Util */
import useHttp from "src/hooks/use-http";
import { TABLE_ID } from "src/utils/constants";
import { getDynamicIndex } from "src/utils/general";
import { IFormDateFilter } from "src/types/common";
import { NUMBER_CELL_TRANSLATE, showActionWhenHovered } from "src/components/common/data-display/dnd-table";

export interface IProfileHistoryData {
  date: string;
  count_follower: number;
  count_following: number;
  count_post: number;
  total_comment: number;
  total_like: number;
  total_view: number;
  total_play: number;
  diff_count_follower: number;
  diff_count_following: number;
  diff_count_post: number;
  diff_total_comment: number;
  diff_total_like: number;
  diff_total_view: number;
  diff_total_play: number;
}

interface ProfileStatisticsProps {
  username: string | undefined;
  formik: FormikProps<IFormDateFilter>;
}

type Props = ProfileStatisticsProps;

const ProfileStatistics: React.FC<Props> = ({ username, formik }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { apiEndpoint } = useHttp();
  const dataTableListRef = useRef(null);
  const [checkPagination, setCheckPagination] = useState({ currentPage: 0, rowsPerPage: 0 });
  const [shouldRenderBottomComponent, setShouldRenderBottomComponent] = useState(false);

  const columns: MRT_ColumnDef<{}>[] = useMemo(
    () => [
      {
        accessorKey: "no",
        header: "#",
        size: 0,
        muiTableHeadCellProps: {
          sx: {
            ...NUMBER_CELL_TRANSLATE,
          },
        },
        enablePinning: false,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnDragging: false,

        Cell: ({ row }) => {
          const { currentPage, rowsPerPage } = checkPagination;
          return (
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
              {getDynamicIndex(currentPage - 1, rowsPerPage, row.index + 1)}.
            </Typography>
          );
        },
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 100,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as IProfileHistoryData;
          return moment(rowData.date).format("DD/MM/YYYY");
        },
      },
      {
        accessorKey: "count_following",
        size: 130,
        header: "Following",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as IProfileHistoryData;
          return <DiffCount count={rowData.count_following} diff={rowData.diff_count_following} />;
        },
      },
      {
        accessorKey: "count_follower",
        header: "Followers",
        size: 130,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as IProfileHistoryData;
          return <DiffCount count={rowData.count_follower} diff={rowData.diff_count_follower} />;
        },
      },
      {
        accessorKey: "count_post",
        header: "Posts",
        size: 130,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as IProfileHistoryData;
          return <DiffCount count={rowData.count_post} diff={rowData.diff_count_post} />;
        },
      },
      {
        accessorKey: "total_comment",
        size: 130,
        header: "Total Comments",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as IProfileHistoryData;
          return <DiffCount count={rowData.total_comment} diff={rowData.diff_total_comment} />;
        },
      },
      {
        accessorKey: "total_like",
        size: 130,
        header: "Total Likes",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as IProfileHistoryData;
          return <DiffCount count={rowData.total_like} diff={rowData.diff_total_like} />;
        },
      },
      {
        accessorKey: "total_play",
        header: "Total Plays",
        size: 130,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as IProfileHistoryData;
          return <DiffCount count={rowData.total_play} diff={rowData.diff_total_play} />;
        },
      },
    ],
    [checkPagination]
  );

  const listMetadata = {
    primary: { path: "date" },
    sortFields: [],
    fields: [
      {
        path: "count_following",
        label: "Following",
      },
      {
        path: "count_follower",
        label: "Followers",
      },
      {
        path: "count_post",
        label: "Posts",
      },
    ],
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    // Introduce a delay before rendering the bottom component
    const delay = setTimeout(() => {
      setShouldRenderBottomComponent(true);
    }, 500); // Adjust the delay time as needed (2000 milliseconds in this example)

    return () => {
      clearTimeout(delay); // Clear the timeout to avoid any memory leaks
    };
  }, []);

  /* ================================================== */
  /* ================================================== */
  return (
    <Stack spacing={1}>
      <ProfileWidgets formik={formik} />
      {shouldRenderBottomComponent && (
        <Box sx={{ borderRadius: "8px", overflow: "hidden" }}>
          <DataTableList
            id={TABLE_ID.PROFILE_HISTORY}
            columns={columns}
            columnOrder={columns.map(c => c.accessorKey)}
            setCheckPagination={setCheckPagination}
            dataTableListRef={dataTableListRef}
            listMetadata={listMetadata}
            showGlobalFilter={false}
            enableMobileList={false}
            search={{
              key: "q",
              label: "Search username",
            }}
            url={`${apiEndpoint.INSTAGRAM_PROFILE_HISTORY(username)}?timestamp_min=${moment(formik.values.start_min).format(
              "YYYY-MM-DD"
            )}&timestamp_max=${moment(formik.values.start_max).format("YYYY-MM-DD")}`}
          />
        </Box>
      )}
    </Stack>
  );
};

export default ProfileStatistics;
