import React, { useEffect, useRef, useState } from "react";
/* components */
import TikTokProfileListing from "src/pages/listing/tiktok/tiktok-profile-listing";
import InstaProfileListing from "src/pages/listing/instagram/insta-profile-listing";
import InstaPostListing from "src/pages/listing/instagram/insta-post-listing";
import TikTokPostListing from "src/pages/listing/tiktok/tiktok-post-listing";
import DelaySubmitInput from "src/components/common/inputs/delay-submit-input";
import MobileSortDrawer from "src/components/common/inputs/mobile-sort-drawer";
/* 3rd party lib */
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { faLeft } from "@fortawesome/pro-solid-svg-icons";
/* Util */
import Insta from "src/images/insta.png";
import TikTok from "src/images/darkModeTikTok.png";
import useData from "src/hooks/use-data";
import { RootState } from "src/store/root-reducer";
import { IFormDateFilter } from "src/types/common";
import useMediaQueries from "src/hooks/use-mediaqueries";
import DateFilter from "src/components/common/inputs/date-filter";
import InstaPostHashtagListing from "src/pages/listing/instagram/insta-post-hashtag-listing";
import TiktokPostHashtagListing from "src/pages/listing/tiktok/tiktok-post-hashtag-listing";
import ComparisonAvatarGroup from "src/components/comparison/comparison-avatar-group";

interface PostsDialogProps {
  open: boolean;
  onClose: () => void;
  onSelectPost: (postId: string) => void;
}

type Props = PostsDialogProps;

const PostsDialog: React.FC<Props> = ({ open, onClose, onSelectPost }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const sortFields = [
    { key: "title", label: "Post Title" },
    { key: "engagement_rate", label: "Engagement Rate" },
    { key: "count_play", label: "Plays" },
    { key: "count_like", label: "Likes" },
    { key: "count_comment", label: "Comments" },
    { key: "date_posted", label: "Date Posted" },
  ];

  const theme = useTheme();
  const { sort, dispatchData } = useData();
  const [search, setSearch] = useState("");
  const [sortDialog, setSortDialog] = useState(false);
  const [postSearch, setPostSearch] = useState("");
  const { smDown } = useMediaQueries();
  const dataTableListRef = useRef(null);
  const { socialMedia } = useSelector((state: RootState) => state.general);
  const { postAvatarGroup } = useSelector((state: RootState) => state.compare);

  const [expandProfile, setExpandProfile] = useState<string | null>(null);
  const [showHashtagSearch, setShowHashtagSearch] = useState(false);
  const [hashtagSearch, setHashtagSearch] = useState("");

  useEffect(() => {
    if (showHashtagSearch) {
      setExpandProfile(null);
    }
  }, [showHashtagSearch]);

  // last 7 days
  // const startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
  // get the end of the current month
  // const endDate = moment().format("YYYY-MM-DD");

  const formik = useFormik<IFormDateFilter>({
    validateOnChange: false,
    initialValues: {
      start_min: null,
      start_max: null,
    },
    onSubmit: () => {},
  });

  const handleClose = () => {
    onClose();
    setSearch("");
    formik.resetForm();
    setPostSearch("");
    setHashtagSearch("");
    setExpandProfile(null);
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  const toggleText = `${(theme.palette.neutral as any)[400]} !important`;

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth fullScreen={smDown}>
        <DialogTitle>
          <Stack direction="row" spacing={1} alignItems="center">
            {socialMedia === "instagram" ? (
              <img src={Insta} alt="insta" width="25px" height="25px" />
            ) : (
              <img src={TikTok} alt="tiktok" width="25px" height="25px" />
            )}
            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              Select Posts
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1} sx={{ my: 2 }}>
            <Stack alignItems="center">
              <ToggleButtonGroup
                value={showHashtagSearch}
                exclusive
                sx={{ height: "46px", "& .MuiButtonBase-root": { border: `1px solid ${(theme.palette as any).neutral[400]}` } }}
                onChange={(_e, value) => {
                  if (value) {
                    if (value === "true") {
                      setShowHashtagSearch(true);
                    } else {
                      setShowHashtagSearch(false);
                    }
                  }
                }}
                aria-label="Platform"
              >
                <ToggleButton
                  title="Search Username"
                  value="false"
                  sx={{
                    background: !showHashtagSearch ? `${theme.palette.secondary.main} !important` : "",
                    color: !showHashtagSearch ? "white !important" : toggleText,
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography textTransform={"capitalize"} variant="body2" color={!showHashtagSearch ? "white !important" : toggleText}>
                      Search by Username @
                    </Typography>
                  </Stack>
                </ToggleButton>
                <ToggleButton
                  title="Search Hashtag"
                  value="true"
                  sx={{
                    background: showHashtagSearch ? `${theme.palette.secondary.main} !important` : "",
                    color: showHashtagSearch ? "white !important" : toggleText,
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography textTransform={"capitalize"} variant="body2" color={showHashtagSearch ? "white !important" : toggleText}>
                      Search by Hashtag #
                    </Typography>
                  </Stack>
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Paper sx={{ p: 1 }}>
              <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {expandProfile && (
                    <Button
                      startIcon={<FontAwesomeIcon icon={faLeft} />}
                      variant="contained"
                      sx={{ pr: 5, pl: 4 }}
                      onClick={() => setExpandProfile(null)}
                    >
                      Back
                    </Button>
                  )}
                  <Box sx={{ width: { lg: "250px" } }}>
                    <DelaySubmitInput
                      value={showHashtagSearch ? hashtagSearch : expandProfile ? postSearch : search}
                      styles={{ "& .MuiOutlinedInput-root": { height: "37px !important" } }}
                      variant="outlined"
                      setValue={showHashtagSearch ? setHashtagSearch : expandProfile ? setPostSearch : setSearch}
                      clearInput={showHashtagSearch ? hashtagSearch === "" : expandProfile ? postSearch === "" : search === ""}
                      placeholder={showHashtagSearch ? "Search post by hashtag" : `Search ${socialMedia} ${expandProfile ? "post" : "username"}`}
                    />
                  </Box>
                  {showHashtagSearch && (
                    <DateFilter
                      clearableDate
                      additionalSlideInComponent={
                        <Box sx={{ width: "250px" }}>
                          <DelaySubmitInput
                            value={showHashtagSearch ? hashtagSearch : expandProfile ? postSearch : search}
                            styles={{ "& .MuiOutlinedInput-root": { height: "37px !important" } }}
                            variant="outlined"
                            setValue={showHashtagSearch ? setHashtagSearch : expandProfile ? setPostSearch : setSearch}
                            clearInput={showHashtagSearch ? hashtagSearch === "" : expandProfile ? postSearch === "" : search === ""}
                            placeholder={
                              showHashtagSearch ? "Search post by hashtag" : `Search ${socialMedia} ${expandProfile ? "post" : "username"}`
                            }
                          />
                        </Box>
                      }
                      formik={formik}
                    />
                  )}
                </Stack>

                <ComparisonAvatarGroup type="post" avatarGroup={postAvatarGroup} />
              </Stack>
            </Paper>

            {showHashtagSearch ? (
              socialMedia === "instagram" ? (
                <InstaPostHashtagListing hashtagSearch={hashtagSearch} handleClose={handleClose} formik={formik} onSelectPost={onSelectPost} />
              ) : (
                <TiktokPostHashtagListing hashtagSearch={hashtagSearch} handleClose={handleClose} formik={formik} onSelectPost={onSelectPost} />
              )
            ) : (
              <>
                {expandProfile ? (
                  <Stack spacing={1}>
                    {socialMedia === "instagram" && (
                      <InstaPostListing
                        selection
                        search={postSearch}
                        username={expandProfile}
                        onSelectPost={postId => {
                          onSelectPost(postId);
                          handleClose();
                        }}
                        handleClose={handleClose}
                      />
                    )}
                    {socialMedia === "tiktok" && (
                      <TikTokPostListing
                        selection
                        search={postSearch}
                        username={expandProfile}
                        onSelectPost={postId => {
                          onSelectPost(postId);
                          handleClose();
                        }}
                        handleClose={handleClose}
                      />
                    )}
                  </Stack>
                ) : (
                  <>
                    {socialMedia === "instagram" && (
                      <InstaProfileListing
                        search={search}
                        id="post_dialog"
                        multiPostSelect
                        showTrackButton={false}
                        showGlobalFilter={false}
                        columnVisibility={{ action: false }}
                        dataTableListRef={dataTableListRef}
                        onSelectProfile={profile => {
                          setExpandProfile(profile.username);
                        }}
                      />
                    )}
                    {socialMedia === "tiktok" && (
                      <TikTokProfileListing
                        search={search}
                        id="post_dialog"
                        multiPostSelect
                        columnVisibility={{ action: false }}
                        showGlobalFilter={false}
                        showTrackButton={false}
                        dataTableListRef={dataTableListRef}
                        onSelectProfile={profile => {
                          setExpandProfile(profile.username);
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
      <MobileSortDrawer
        open={sortDialog}
        sort={sort}
        sortFields={sortFields}
        onSort={key => {
          dispatchData({ type: "SET_SORT", payload: key });
          // dispatch(setPage(1));
        }}
        onDrawerClose={() => setSortDialog(false)}
      />
    </>
  );
};

export default PostsDialog;
