import React from "react";
/* components */
/* 3rd party lib */
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import useMediaQueries from "src/hooks/use-mediaqueries";
import DataDisplay from "src/components/common/inputs/data-display";
import { ICreateAccountFormValues } from "src/pages/admin/superadmin";

/* Util */
interface CreateAccountDialogProps {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  formik: FormikProps<ICreateAccountFormValues>;
}

type Props = CreateAccountDialogProps;

const CreateAccountDialog: React.FC<Props> = ({ formik, open, loading, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { mdDown } = useMediaQueries();

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog maxWidth="md" fullWidth={mdDown} open={open} onClose={onClose}>
      <form id="create_account_form" onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ p: 2 }}>
          <Stack direction="row" spacing={3}>
            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              Create User Account
            </Typography>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Paper elevation={5} sx={{ mt: 2, p: 2 }}>
            <Stack spacing={2} sx={{ py: 2 }}>
              <Box sx={{ width: mdDown ? "auto" : "600px" }}>
                <DataDisplay
                  label={"Name"}
                  variant={"body2"}
                  value={
                    <FormControl fullWidth>
                      <TextField
                        value={formik.values.name}
                        fullWidth
                        variant="outlined"
                        hiddenLabel
                        name="name"
                        size="small"
                        placeholder="Enter name"
                        onChange={formik.handleChange}
                        error={formik.errors.name ? true : false}
                        helperText={formik.errors?.name}
                      />
                    </FormControl>
                  }
                />
              </Box>
              <DataDisplay
                label={"Account Number"}
                variant={"body2"}
                value={
                  <TextField
                    value={formik.values.account_number}
                    fullWidth
                    variant="outlined"
                    hiddenLabel
                    name="account_number"
                    size="small"
                    placeholder="Enter account number"
                    onChange={formik.handleChange}
                  />
                }
              />

              <DataDisplay
                label={"Email"}
                variant={"body2"}
                value={
                  <TextField
                    fullWidth
                    value={formik.values.email}
                    placeholder="Enter email"
                    variant="outlined"
                    hiddenLabel
                    name="email"
                    type="email"
                    size="small"
                    onChange={formik.handleChange}
                    error={formik.errors.email ? true : false}
                    helperText={formik.errors?.email}
                  />
                }
              />
              <DataDisplay
                label={"Phone Number"}
                variant={"body2"}
                value={
                  <TextField
                    fullWidth
                    value={formik.values.phone_num}
                    placeholder="Enter phone number"
                    variant="outlined"
                    hiddenLabel
                    name="phone_num"
                    type="tel"
                    size="small"
                    onChange={formik.handleChange}
                    error={formik.errors.phone_num ? true : false}
                    helperText={formik.errors?.phone_num}
                  />
                }
              />
              <DataDisplay
                label={"Company"}
                variant={"body2"}
                value={
                  <TextField
                    fullWidth
                    value={formik.values.company_name}
                    placeholder="Enter Company Name"
                    variant="outlined"
                    hiddenLabel
                    name="company_name"
                    size="small"
                    onChange={formik.handleChange}
                  />
                }
              />
              <DataDisplay
                label={"Country"}
                variant={"body2"}
                value={
                  <Box sx={{ width: "100%" }}>
                    <FormControl fullWidth>
                      <Select
                        value={formik.values.country}
                        onChange={e => {
                          formik.setFieldValue("country", e.target.value);
                        }}
                        size="small"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        // sx={
                        //   inputStyle ?? {
                        //     "& .MuiOutlinedInput-input": {
                        //       py: "12px !important",
                        //     },
                        //   }
                        // }
                      >
                        <MenuItem value={"SG"}>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="body1">Singapore</Typography>
                          </Stack>
                        </MenuItem>
                        <MenuItem value={"MY"}>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="body1">Malaysia</Typography>
                          </Stack>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                }
              />
              <DataDisplay
                label={"Account Status"}
                variant={"body2"}
                value={
                  <FormControlLabel
                    control={
                      <Checkbox checked={formik.values.account_status} onChange={e => formik.setFieldValue("account_status", e.target.checked)} />
                    }
                    label={"Active"}
                    componentsProps={{ typography: { fontSize: 14 } }}
                  />
                }
              />
              <DataDisplay
                label={"Remark"}
                variant={"body2"}
                alignCenter={false}
                value={
                  <TextField
                    fullWidth
                    value={formik.values.remark}
                    placeholder="Enter remark"
                    variant="outlined"
                    hiddenLabel
                    name="remark"
                    size="small"
                    multiline
                    minRows={2}
                    onChange={formik.handleChange}
                  />
                }
              />
            </Stack>
          </Paper>
        </DialogContent>
      </form>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button form="create_account_form" type="submit" disabled={loading} variant="contained" color={"primary"}>
          Save
          {loading && <CircularProgress sx={{ ml: 1 }} size={18} color="inherit" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAccountDialog;
