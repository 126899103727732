import { Stack } from "@mui/material";
import { FormikProps } from "formik";
import { IFormDateFilter } from "src/types/common";
import TikTokPostHashtagGrid from "src/pages/listing/tiktok/tiktok-post-hashtag-grid";

interface TikTokPostHashtagListingProps {
  hashtagSearch: string;
  handleClose?: () => void;
  onSelectPost?: (postId: string) => void;
  formik: FormikProps<IFormDateFilter>;
}

type Props = TikTokPostHashtagListingProps;

const TikTokPostHashtagListing: React.FC<Props> = ({ formik, hashtagSearch, handleClose, onSelectPost }) => {
  return (
    <Stack spacing={1}>
      <TikTokPostHashtagGrid hashtagSearch={hashtagSearch} handleClose={handleClose} formik={formik} onSelectPost={onSelectPost} />
    </Stack>
  );
};
export default TikTokPostHashtagListing;
