import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import React from "react";
/* components */
/* 3rd party lib */
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
/* Util */

/**
 * BalanceProgress component displays a progress indicator either as a gauge or a circular progress indicator.
 *
 * @component
 * @example
 * <BalanceProgress color="primary" balance={50} total={100} gauge={true} />
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.color] - The color of the progress indicator (for circular progress). Defaults to the primary color from the theme.
 * @param {number} [props.balance] - The current balance to display. Defaults to 0.
 * @param {number} [props.total] - The total value used to calculate the progress percentage. Defaults to 0.
 * @param {boolean} [props.gauge] - Whether to display the gauge chart or the circular progress indicator. Defaults to false.
 *
 * @returns {JSX.Element} The rendered component.
 */
interface BalanceProgressProps {
  color?: string;
  balance?: number;
  total?: number;
  gauge?: boolean;
}

type Props = BalanceProgressProps;

const BalanceProgress: React.FC<Props> = props => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { gauge = false, color = theme.palette.primary.main, balance = 0, total = 0 } = props;
  /* ================================================== */
  /*  method */
  /* ================================================== */

  /**
   * Calculates the font size for the gauge value text based on the length of the number.
   *
   * @param {number} number - The number to determine the font size for.
   * @returns {number} The calculated font size.
   */
  const calculateFontSize = (number: number) => {
    const baseFontSize = 35;
    const length = number.toString().length;

    // Adjust font size based on length, you can tweak the formula as needed
    return baseFontSize - length * 2;
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */

  return (
    <>
      <Box sx={{ position: "relative" }} className="step-3">
        {gauge ? (
          <>
            <Gauge
              width={120}
              height={100}
              value={balance < 0 ? 0 : balance}
              valueMin={0}
              valueMax={total}
              startAngle={-110}
              endAngle={110}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: calculateFontSize(balance),
                  transform: "translate(0px, -8px)",
                },
              }}
              text={({ value, valueMax }) => `${balance < 0 ? 0 : balance}`}
            />
          </>
        ) : (
          <>
            <CircularProgress
              variant="determinate"
              sx={{
                color: theme => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
              }}
              size={80}
              thickness={4}
              value={100}
            />
            <CircularProgress
              sx={{ position: "absolute", left: 0, color: color }}
              size={80}
              variant="determinate"
              value={(balance / total) * 100 * -1}
              thickness={4}
            />

            <Box
              sx={{
                top: "50%",
                left: "50%",
                // bottom: 0,
                // right: 0,
                position: "absolute",
                display: "grid",
                placeItems: "center",
                transform: "translate(-50%, -53%)",
              }}
            >
              <Typography variant="body1" fontSize={balance > 100 ? 30 : 35} component="div" color="text.secondary">{`${balance}`}</Typography>
              {/* <Typography variant="body1" fontSize={18} component="div" color="text.secondary">{`${balance} / ${total}`}</Typography> */}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default BalanceProgress;
