import React, { useCallback, useEffect, useState } from "react";
/* components */
import DateFilter from "src/components/common/inputs/date-filter";
import OuterLayout from "src/components/common/layout/outer-layout";
import PresetDialog from "src/components/common/dialog/profile-preset-dialog";
import ToggleComponent from "src/components/comparison/toggle-component";
import ProfileDialog from "src/components/common/dialog/profile-dialog";
import MultiProfileDialog from "src/components/common/dialog/profile-multiselect-dialog";
import CrudPresetDialog from "src/components/common/dialog/crud-preset-dialog";
import EditPresetDialog from "src/components/common/dialog/edit-preset-dialog";
import GoToTopButton from "src/components/common/inputs/go-to-top-button";
import TiktokCompare from "src/pages/comparisons/profiles/tiktok";
import InstagramProfileCompare from "src/pages/comparisons/profiles/instagram";
import JoyrideComponent from "src/components/comparison/profiles/overview-joyride";
import ArrowOverflowBox from "src/components/common/layout/arrow-overflow-box";
/* 3rd party lib */
import _ from "lodash";
import useSWR from "swr";
import moment from "moment";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { faArrowDownBigSmall, faRefresh } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Step } from "react-joyride";

import { Box, Button, Grid, Link, Paper, Stack, ToggleButton, Tooltip, Typography, useTheme } from "@mui/material";
/* Util */
import routes from "src/routes";
import "src/styles/comparison.scss";
import useHttp from "src/hooks/use-http";
import { useDispatch, useSelector } from "react-redux";
import FeatureLock from "src/images/lock.png";
import CompareImg from "src/images/compare.png";
import { RootState } from "src/store/root-reducer";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { checkObjectNotEmpty } from "src/utils/general";
import { IProfilePresetData } from "src/types/instagram";
import { IProfileAvatarGroup, IFormDateFilter, TTriggerVibration, IWalkthrough } from "src/types/common";
import { ProfileComparisonContextProvider } from "src/contexts/comparison-profile-context";
import SubscriptionPlanDialog from "src/components/common/dialog/subscription-plan-dialog";
import { setProfileAvatarGroup, setMultiProfile } from "src/slices/compare";
import ExpiredOverlay from "src/components/common/data-display/expired-overlay";
import { setTutorialInProgress } from "src/slices/general";
import LightReorderGif from "src/images/gifs/reorder_light.gif";
import DarkReorderGif from "src/images/gifs/reorder_dark.gif";
import LightOrientationGif from "src/images/gifs/orientation_light.gif";
import DarkOrientationGif from "src/images/gifs/orientation_dark.gif";
import LightPercentageGif from "src/images/gifs/percentage_light.gif";
import DarkPercentageGif from "src/images/gifs/percentage_dark.gif";
import { faBook } from "@fortawesome/pro-regular-svg-icons";
import { TUTORIALS } from "src/utils/constants";

export type TDisplayMode = "percentage" | "number" | null;
export type TTableDirection = "horizontal" | "vertical";

export interface IEditPresetDialog {
  open: boolean;
  preset: IProfilePresetData | null;
}
export interface ICurrentActiveProfilePreset {
  index: number;
  preset: IProfilePresetData | null;
}

export const minCompare = 2;
export const maxPreset = 10;

interface ComparisonByProfilesProps {}

type Props = ComparisonByProfilesProps;

const ComparisonByProfiles: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationParams = location.state;
  const { smDown, mdDown, smUp, lgUp } = useMediaQueries();
  const [mounted, setMounted] = useState(false);
  const [maxCompare, setMaxCompare] = useState(10);
  const { socialMedia } = useSelector((state: RootState) => state.general);
  const { user } = useSelector((state: RootState) => state.auth);
  const [profileDialog, setProfileDialog] = useState({ index: -1, open: false });
  const [addMoreDialog, setAddMoreDialog] = useState(false);
  const [presetDialog, setPresetDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [crudPresetDialog, setCrudPresetDialog] = useState({ create: false, update: false });
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  // const [switchingToPost, setSwitchingToPost] = useState(false); //want to prevent navigating back when switching to post
  const [editPresetDialog, setEditPresetDialog] = useState<IEditPresetDialog>({ open: false, preset: null });
  const [displayMode, setDisplayMode] = useState<TDisplayMode>("number");
  const [tableDirection, setTableDirection] = useState<TTableDirection>("vertical");
  // const [profileExist, setProfileExist] = useState<string | null>(null);
  const { http, apiEndpoint } = useHttp();
  const [profileUsernames, setProfileUsernames] = useState<string[]>(["", ""]);
  const [openSortProfile, setOpenSortProfile] = useState(false);
  const [triggerVibration, setTriggerVibration] = useState<TTriggerVibration>(null);
  const [collapse, setCollapse] = useState<{ [key: string]: boolean } | null>(null);
  const [currentActive, setCurrentActive] = useState<ICurrentActiveProfilePreset>({ index: -1, preset: null });
  const instaTableHeight = currentActive && currentActive.preset ? 730 : 630;
  const tiktokTableHeight = currentActive && currentActive.preset ? 810 : 710;
  const [prevSocialMedia, setPrevSocialMedia] = useState(socialMedia);

  // last 7 days
  const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
  // get the end of the current month
  const endDate = moment().format("YYYY-MM-DD");

  const formik = useFormik<IFormDateFilter>({
    initialValues: {
      start_min: startDate,
      start_max: endDate,
    },
    onSubmit: () => {},
  });

  const { data: presetData, mutate } = useSWR<IProfilePresetData[]>([
    socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_COMPARE_PROFILE_PRESET : apiEndpoint.TIKTOK_COMPARE_PROFILE_PRESET,
  ]);

  // instruction steps
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [activeRun, setActiveRun] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [manualTrigger, setManualTrigger] = useState(false);
  const { data: walkthrough } = useSWR<IWalkthrough[]>([apiEndpoint.INTERACTIVE_WALKTHROUGH_STATUS]);

  const walkthroughImageWidth = smDown ? "100%" : "600px";

  const steps: Step[] = [
    {
      target: "body",
      placement: "center",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth} alignItems="center" justifyContent={"center"}>
          <Typography>This page lets you compare performance of multiple profiles.</Typography>
          <img src={CompareImg} alt="compare" width="100%" />
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".step-1",
      content: (
        <Stack spacing={2}>
          <Typography textAlign={"left"}>
            Click here to start adding profiles and save your comparison as a preset. This allows you to easily reopen your previous comparisons next
            time.
          </Typography>
        </Stack>
      ),
    },
    {
      target: ".step-2",
      content: (
        <Stack spacing={2}>
          <Typography textAlign={"left"}> You can also create a preset first and add profiles to it later.</Typography>
        </Stack>
      ),
    },
    {
      target: ".step-3",
      content: (
        <Stack spacing={2}>
          <Typography textAlign={"left"}> Switch to other social media platforms for more comparisons.</Typography>
        </Stack>
      ),
    },
    {
      target: ".step-4",
      content: (
        <Stack spacing={2}>
          <Typography textAlign="left"> Set the view to full window size for a better perspective.</Typography>
        </Stack>
      ),
    },
    {
      target: ".step-5",
      content: (
        <Stack spacing={2}>
          <Typography> Compare posts or campaigns here if your plan includes this feature.</Typography>
        </Stack>
      ),
      locale: {
        last: "Complete",
      },
    },
  ];

  const activeSteps: Step[] = [
    {
      target: ".active-step1",
      placement: "bottom",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth}>
          <Typography>Reorder your profiles to organize them as needed.</Typography>
          <img src={theme.palette.mode === "light" ? LightReorderGif : DarkReorderGif} alt="Start Tracking" width="100%" />
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".active-step2",
      placement: "top",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth}>
          <Typography>Switch the orientation to view profiles in different layouts.</Typography>
          <img src={theme.palette.mode === "light" ? LightOrientationGif : DarkOrientationGif} alt="Start Tracking" width="100%" />
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".active-step3",
      placement: "bottom",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth}>
          <Typography>Select to view data by percentage or by actual numbers.</Typography>

          <img src={theme.palette.mode === "light" ? LightPercentageGif : DarkPercentageGif} alt="Start Tracking" width="100%" />
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".active-step4",
      content: (
        <Stack spacing={2} alignItems="center" justifyContent={"center"}>
          <Typography>Change or remove profiles from your preset here.</Typography>
        </Stack>
      ),
    },
    {
      target: ".active-step5",
      content: (
        <Stack spacing={2} alignItems="center" justifyContent={"center"}>
          <Typography>Clear all profiles from the current preset with this option.</Typography>
        </Stack>
      ),
    },
    {
      target: ".active-step6",
      content: (
        <Stack spacing={2} alignItems="center" justifyContent={"center"}>
          <Typography>View detailed charts and statistics for all selected profiles here.</Typography>
        </Stack>
      ),
    },
    {
      target: ".active-step7",
      content: (
        <Stack spacing={2} alignItems="center" justifyContent={"center"}>
          <Typography>Check out the top posts based on the selected criteria here.</Typography>
        </Stack>
      ),
      locale: {
        last: "Complete",
      },
    },
  ];
  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleDeselectAll = () => {
    setProfileUsernames(["", ""]); //when delete preset, set back to empty selection
    setCurrentActive({ index: -1, preset: null });
  };

  const checkArrayExist = useCallback(
    (currArray: string[]) => {
      if (presetData) {
        for (let i = 0; i < presetData.length; i++) {
          const objArray = presetData[i];
          let targetArray = objArray?.profiles.map(child => child.username);

          // Sort both arrays before comparing to ignore sequence
          if (_.isEqual(currArray.slice().sort(), targetArray.slice().sort())) {
            // if array same that means it exist
            return true;
          }
        }
      }
      return false;
    },
    [presetData]
  );

  const handleSavePreset = async (name: string, createPresetWithProfiles: boolean, avatarGroups?: string[]) => {
    try {
      const { data: newlyCreatedPreset }: { data: IProfilePresetData } = await http.post(
        socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_COMPARE_PROFILE_PRESET : apiEndpoint.TIKTOK_COMPARE_PROFILE_PRESET,
        {
          title: name,
          profiles: createPresetWithProfiles
            ? avatarGroups
              ? avatarGroups.filter(child => child !== "")
              : profileUsernames.filter(child => child !== "")
            : [],
        }
      );

      toast.success("Saved preset to list");

      mutate();

      const { data: newlyUpdatedPresetList }: { data: IProfilePresetData[] } = await http.get(
        socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_COMPARE_PROFILE_PRESET : apiEndpoint.TIKTOK_COMPARE_PROFILE_PRESET
      );

      const foundObjIndex = newlyUpdatedPresetList.findIndex(preset => preset.id === newlyCreatedPreset.id);

      const tempUsernames = newlyUpdatedPresetList[foundObjIndex].profiles.map(child => child.username);

      setProfileUsernames(
        newlyUpdatedPresetList[foundObjIndex].profiles.length === 0 ? ["", ""] : tempUsernames.length === 1 ? [...tempUsernames, ""] : tempUsernames
      );
      setCurrentActive({ index: foundObjIndex, preset: newlyUpdatedPresetList[foundObjIndex] });
      setPresetDialog(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditPreset = async (id: number, title: string, profiles?: string[]) => {
    try {
      await http.put(
        socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_COMPARE_PROFILE_PRESET_EDIT(id) : apiEndpoint.TIKTOK_COMPARE_PROFILE_PRESET_EDIT(id),
        {
          title: title,
          profiles: profiles
            ? profiles.filter(child => child !== "")
            : editPresetDialog.preset && editPresetDialog.preset.profiles.map(child => child.username),
        }
      );
      toast.success(`Edited Preset - ${title}`);
      mutate();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeletePreset = async (id: number) => {
    try {
      await http.delete(
        socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_COMPARE_PROFILE_PRESET_EDIT(id) : apiEndpoint.TIKTOK_COMPARE_PROFILE_PRESET_EDIT(id)
      );
      toast.success("Deleted Preset");
      setShowDeleteDialog(false);
      handleDeselectAll();
      setEditPresetDialog({ open: false, preset: null });
      mutate();
    } catch (err) {
      console.error(err);
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    // if profile exist or selected, set active run to true
    if (!walkthrough) return;
    if (run && !manualTrigger) return;

    const tempWalkthrough = walkthrough.find(child => child.section === TUTORIALS.COMPARE_WIDGET);

    // auto trigger the tutorial if this walkthrough is not being viewed before
    if (profileUsernames.filter(child => child !== "").length > 0 && ((!tempWalkthrough?.viewed && !manualTrigger) || manualTrigger)) {
      setActiveRun(true);
      setActiveStepIndex(0);
      setManualTrigger(false);
      dispatch(setTutorialInProgress(true));
    }
  }, [run, manualTrigger, dispatch, walkthrough, profileUsernames]);

  useEffect(() => {
    if (profileUsernames.length > 8) {
      setTableDirection("horizontal");
    } else {
      setTableDirection("vertical");
    }
  }, [profileUsernames, setTableDirection]);

  useEffect(() => {
    if (locationParams && locationParams.profile && !mounted) {
      // if there's a profile, then initalize the first item with it
      setProfileUsernames([locationParams.profile, ""]);
      // only do this for first mount
      setMounted(true);
    }
  }, [locationParams, mounted]);

  useEffect(() => {
    // Check if the socialMedia has changed from the previous state
    if (socialMedia !== prevSocialMedia) {
      // Update the previous username
      setPrevSocialMedia(socialMedia);
      // if change profile reset
      setRun(false);
      setStepIndex(0);
      setActiveRun(false);
      setActiveStepIndex(0);
      setManualTrigger(false);
      handleDeselectAll();
    }
  }, [prevSocialMedia, socialMedia]);

  useEffect(() => {
    if (presetData) {
      const result = (presetData as any).reduce((acc: { [key: string]: boolean }, curr: any) => {
        acc[curr.id] = false;
        return acc;
      }, {});

      setCollapse(state => {
        return { ...result, ...state };
      });

      setCurrentActive(prevState => {
        // change the name of the current active preset when edited
        if (prevState && prevState.preset) {
          const preset = presetData.find(child => child.id === prevState?.preset?.id);
          return { ...prevState, preset: preset ?? null };
        }
        return prevState;
      });
    }
  }, [presetData]);

  useEffect(() => {
    if (user && checkObjectNotEmpty(user.subscription) && user.subscription.package.max_compare_profile_limit) {
      setMaxCompare(user.subscription.package.max_compare_profile_limit);
    }
  }, [user]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Delete") {
        if (currentActive.preset) {
          setEditPresetDialog({ open: true, preset: currentActive.preset });
          setShowDeleteDialog(true);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      dispatch(setMultiProfile({}));
      dispatch(setProfileAvatarGroup(null));
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, currentActive]);

  // useEffect(() => {
  //   return () => {
  //     if (!switchingToPost) {
  //       navigate(routes.COMPARISON_PROFILES); //clear the state inside the location react router dom, if not when refresh the old username will keep being initiailized in the first position of the array
  //     }
  //   };
  // }, [navigate, location, switchingToPost]);

  const addMoreDisabled = profileUsernames.length >= maxCompare || profileUsernames.some(child => child === "");
  const profileExist = profileUsernames.some(child => child !== "");
  const savePresetDisabled =
    profileUsernames.filter(child => child !== "").length < minCompare ||
    // (presetData && presetData.length === maxPreset) ||
    profileUsernames.every(child => child === "") ||
    checkArrayExist(profileUsernames);

  /* ================================================== */
  /* ================================== ================ */

  return (
    <>
      {user?.subscription && checkObjectNotEmpty(user.subscription) && user.subscription.package.is_compare_profiles && (
        <>
          <JoyrideComponent
            run={run}
            setRun={setRun}
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            steps={steps}
            tutorialSection={TUTORIALS.COMPARE}
          />

          <JoyrideComponent
            run={activeRun}
            setRun={setActiveRun}
            stepIndex={activeStepIndex}
            setStepIndex={setActiveStepIndex}
            steps={activeSteps}
            tutorialSection={TUTORIALS.COMPARE_WIDGET}
          />
        </>
      )}

      <OuterLayout maxWidth={"xl"}>
        <Grid container spacing={2} sx={{ pb: 6 }}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box sx={{ py: 2, px: 2, flexGrow: 1 }}>
                <Typography variant="h6" color="text.secondary" fontWeight="normal" textAlign={"left"}>
                  Profiles Comparison
                </Typography>
              </Box>

              <Button
                size="small"
                color="secondary"
                variant="contained"
                sx={{ py: 1.8 }}
                className="step-5"
                onClick={() => {
                  // setSwitchingToPost(true);
                  navigate(routes.COMPARISON_POSTS);
                  dispatch(setMultiProfile({}));
                  dispatch(setProfileAvatarGroup(null));
                }}
                endIcon={<FontAwesomeIcon icon={faRefresh} size="sm" />}
              >
                <Typography variant="body1" whiteSpace="nowrap" fontWeight={600}>
                  Posts/Campaign {smUp && "Compare"}
                </Typography>
              </Button>
            </Stack>
          </Grid>

          <>
            {checkObjectNotEmpty(user?.subscription) && user?.subscription?.package.is_compare_profiles && checkObjectNotEmpty(user.subscription) ? (
              <>
                {!lgUp && profileExist && (
                  <Grid item xs={12}>
                    <Paper elevation={5} sx={{ p: 1 }}>
                      <ArrowOverflowBox>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={1}>
                          <Tooltip arrow title="Reorder Profiles">
                            <ToggleButton
                              className="active-step1"
                              value="check"
                              onClick={() => setOpenSortProfile(true)}
                              sx={{
                                "&:hover": {
                                  background: theme.palette.primary.main,
                                  color: "white",
                                },
                                "&:active": {
                                  background: "white",
                                  color: theme.palette.primary.main,
                                },
                                color: theme.palette.primary.main,
                              }}
                            >
                              <FontAwesomeIcon icon={faArrowDownBigSmall} style={{ transform: "rotate(-90deg)" }} />
                            </ToggleButton>
                          </Tooltip>
                          <ToggleComponent
                            displayMode={displayMode}
                            tableDirection={tableDirection}
                            setDisplayMode={setDisplayMode}
                            profileOrPosts={profileUsernames}
                            setTableDirection={setTableDirection}
                          />
                          <DateFilter formik={formik} />
                        </Stack>
                      </ArrowOverflowBox>
                    </Paper>
                  </Grid>
                )}

                <ProfileComparisonContextProvider
                  theme={theme}
                  formik={formik}
                  endDate={endDate}
                  collapse={collapse}
                  startDate={startDate}
                  minCompare={minCompare}
                  maxCompare={maxCompare}
                  presetData={presetData}
                  profileExist={profileExist}
                  displayMode={displayMode}
                  addMoreDialog={addMoreDialog}
                  currentActive={currentActive}
                  profileDialog={profileDialog}
                  openSortProfile={openSortProfile}
                  addMoreDisabled={addMoreDisabled}
                  tableDirection={tableDirection}
                  editPresetDialog={editPresetDialog}
                  profileUsernames={profileUsernames}
                  savePresetDisabled={savePresetDisabled}
                  triggerVibration={triggerVibration}
                  openFullScreenDialog={openFullScreenDialog}
                  setCollapse={setCollapse}
                  checkArrayExist={checkArrayExist}
                  handleEditPreset={handleEditPreset}
                  setProfileDialog={setProfileDialog}
                  setAddMoreDialog={setAddMoreDialog}
                  setCurrentActive={setCurrentActive}
                  setDisplayMode={setDisplayMode}
                  setTableDirection={setTableDirection}
                  setPresetDialog={setPresetDialog}
                  handleDeselectAll={handleDeselectAll}
                  setProfileUsernames={setProfileUsernames}
                  setCrudPresetDialog={setCrudPresetDialog}
                  setEditPresetDialog={setEditPresetDialog}
                  setOpenSortProfile={setOpenSortProfile}
                  setTriggerVibration={setTriggerVibration}
                  setOpenFullScreenDialog={setOpenFullScreenDialog}
                  tableHeight={socialMedia === "instagram" ? instaTableHeight : tiktokTableHeight}
                >
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ position: "relative", mt: user && user.expired ? 1 : 0, px: user && user.expired ? 2 : 0 }}>
                        {user && user.expired && <ExpiredOverlay />}
                        {socialMedia === "instagram" ? <InstagramProfileCompare /> : <TiktokCompare />}
                      </Box>
                    </Grid>
                    {(!lgUp || openFullScreenDialog) && (
                      <PresetDialog
                        open={presetDialog}
                        presetData={presetData}
                        currentActive={currentActive}
                        setCurrentActive={setCurrentActive}
                        onClose={() => setPresetDialog(false)}
                        setEditPresetDialog={setEditPresetDialog}
                        setProfileUsernames={setProfileUsernames}
                      />
                    )}

                    {/* this is changing profile */}
                    <ProfileDialog
                      open={profileDialog.open}
                      onClose={() => {
                        dispatch(setMultiProfile({}));
                        dispatch(setProfileAvatarGroup(null));
                        setProfileDialog({ index: -1, open: false });
                      }}
                      onSelectProfile={(username: string) => {
                        if (!buttonClicked) {
                          setButtonClicked(true);

                          if (profileUsernames.includes(username)) {
                            toast.error("Profile already exist!");
                          } else {
                            const replaceItemInArray = (array: string[]) => {
                              let temp = array.slice();
                              temp[profileDialog.index] = username;
                              return temp;
                            };

                            // this is to change the view locally
                            setProfileUsernames(prevState => {
                              if (username) {
                                let temp = replaceItemInArray(prevState);
                                return temp;
                              } else {
                                return prevState;
                              }
                            });

                            // this is to change at api level
                            if (currentActive.preset) {
                              let temp = replaceItemInArray(profileUsernames);
                              handleEditPreset(currentActive.preset.id, currentActive.preset.title, temp);
                            }
                          }

                          setTimeout(() => {
                            setButtonClicked(false);
                          }, 500);
                        }
                      }}
                    />

                    {/* this is adding profile */}
                    {/* <ProfileDialog
                      open={addMoreDialog}
                      onClose={() => setAddMoreDialog(false)}
                      onSelectProfile={(username: string) => {
                        if (!buttonClicked) {
                          setButtonClicked(true);
                          if (profileUsernames.includes(username)) {
                            toast.error("Profile already exist!");
                          } else {
                            setProfileUsernames(prevState => [...prevState, username]);
                            if (currentActive.preset) {
                              handleEditPreset(currentActive.preset.id, currentActive.preset.title, [...profileUsernames, username]);
                            }
                          }

                          setTimeout(() => {
                            setButtonClicked(false);
                          }, 500);
                        }
                      }}
                    /> */}

                    <MultiProfileDialog
                      open={addMoreDialog}
                      handleSavePreset={handleSavePreset}
                      onClose={() => {
                        dispatch(setMultiProfile({}));
                        dispatch(setProfileAvatarGroup(null));
                        setAddMoreDialog(false);
                      }}
                      onSelectProfiles={(profiles: IProfileAvatarGroup[]) => {
                        let tempProfiles = profiles.map(profile => profile.username);
                        // if there's only 1 item, set the other to be empty string
                        if (tempProfiles.length === 1) {
                          tempProfiles.push("");
                        } else if (tempProfiles.length === 0) {
                          tempProfiles = ["", ""];
                        }

                        setProfileUsernames(tempProfiles.reverse());
                        if (currentActive.preset) {
                          handleEditPreset(currentActive.preset.id, currentActive.preset.title, tempProfiles);
                        }
                        setAddMoreDialog(false);
                        dispatch(setMultiProfile({}));
                        dispatch(setProfileAvatarGroup(null));
                      }}
                    />

                    <EditPresetDialog
                      preset={editPresetDialog.preset}
                      open={editPresetDialog.open}
                      onClose={() => setEditPresetDialog({ open: false, preset: null })}
                      onEdit={handleEditPreset}
                      onDelete={handleDeletePreset}
                      showDeleteDialog={showDeleteDialog}
                      setShowDeleteDialog={setShowDeleteDialog}
                    />
                    <CrudPresetDialog
                      crud="create"
                      isProfile
                      currentActive={currentActive}
                      open={crudPresetDialog.create}
                      onClose={() => setCrudPresetDialog({ ...crudPresetDialog, create: false })}
                      onSave={handleSavePreset}
                    />
                    <CrudPresetDialog
                      crud="update"
                      isProfile
                      currentActive={currentActive}
                      open={crudPresetDialog.update}
                      onClose={() => setCrudPresetDialog({ ...crudPresetDialog, update: false })}
                      onSave={handleSavePreset}
                    />
                  </>
                </ProfileComparisonContextProvider>
              </>
            ) : (
              <Grid item xs={12}>
                <Paper
                  elevation={5}
                  sx={{
                    p: 4,
                    borderRadius: "8px",
                  }}
                >
                  <Stack spacing={2} alignItems="center">
                    <Typography variant="body1" color="text.secondary">
                      Your plan does not have access to <b>Profiles Comparison</b>. <br />
                      Please upgrade your plan to unlock this feature.
                    </Typography>

                    <Box sx={{ width: mdDown ? (smDown ? "80%" : "50vw") : "400px" }}>
                      <img src={FeatureLock} alt="lock" width="100%" height="100%" />
                    </Box>

                    <Box>
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate(routes.PRICING);
                        }}
                      >
                        GET IT IN PRO
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            )}
          </>
          {user?.subscription && checkObjectNotEmpty(user.subscription) && user.subscription.package.is_compare_profiles && (
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Link
                sx={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => {
                  setRun(true);
                  setStepIndex(0);
                  setManualTrigger(true);
                }}
              >
                <Typography>
                  <FontAwesomeIcon icon={faBook} />
                  &nbsp;Need help? View all our key features with a guided walkthrough here.
                </Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      </OuterLayout>

      <SubscriptionPlanDialog open={showSubscriptionDialog} onClose={() => setShowSubscriptionDialog(false)} />

      <GoToTopButton />
    </>
  );
};

export default ComparisonByProfiles;
