import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "src/store//root-reducer";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Import the storage engine
import { createFilter } from "redux-persist-transform-filter";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

const generalSubsetFilter = createFilter("general", ["muiTab", "theme", "socialMedia"]);
const layoutSubsetFilter = createFilter("layout", ["viewMode"]);
// const authSubsetFilter = createFilter("auth", ["user"]);

// it has to migrate for new version
const migrations = {
  0: (state: any) => {
    return {
      ...state,
      general: {
        ...state.general,
        socialMedia: "instagram",
      },
    };
  },
  // 1: (state: any) => {
  //   return {
  //     ...state,
  //     auth: {
  //       ...state.auth,
  //       user: null,
  //     },
  //   };
  // },
};

const persistConfig = {
  key: "root", // Key for the local storage
  storage, // The storage engine
  version: 0,
  whitelist: ["general", "layout"],
  stateReconciler: autoMergeLevel2,
  transforms: [generalSubsetFilter, layoutSubsetFilter],
  migrate: createMigrate(migrations, { debug: true }),
};
const persistedReducer = persistReducer(persistConfig, rootReducer as any);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create a persistor object for persisting and rehydrating the store

// Create a persistor object for persisting and rehydrating the store
const persistor = persistStore(store);

export { store, persistor };

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
