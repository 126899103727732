/* components */
import DateFilter from "src/components/common/inputs/date-filter";
import TikTokPostCompare from "src/pages/comparisons/posts/tiktok";
import OuterLayout from "src/components/common/layout/outer-layout";
import PostsDialog from "src/components/common/dialog/posts-dialog";
import InstagramPostCompare from "src/pages/comparisons/posts//instagram";
import GoToTopButton from "src/components/common/inputs/go-to-top-button";
import ToggleComponent from "src/components/comparison/toggle-component";
import EditPresetDialog from "src/components/common/dialog/edit-preset-dialog";
import ExpiredOverlay from "src/components/common/data-display/expired-overlay";
import MultiPostDialog from "src/components/common/dialog/posts-multiselect-dialog";
import SubscriptionPlanDialog from "src/components/common/dialog/subscription-plan-dialog";
/* 3rd party lib */
import _ from "lodash";
import moment from "moment";
import { Step } from "react-joyride";
import React, { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { faArrowDownBigSmall, faRefresh } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Link, Paper, Stack, ToggleButton, Tooltip, Typography, useTheme } from "@mui/material";
/* Util */
import LightReorderGif from "src/images/gifs/post_reorder_light.gif";
import DarkReorderGif from "src/images/gifs/post_reorder_dark.gif";
import LightOrientationGif from "src/images/gifs/post_orientation_light.gif";
import DarkOrientationGif from "src/images/gifs/post_orientation_dark.gif";
import LightPercentageGif from "src/images/gifs/post_perct_light.gif";
import DarkPercentageGif from "src/images/gifs/post_perct_dark.gif";

import routes from "src/routes";
import useHttp from "src/hooks/use-http";
import FeatureLock from "src/images/lock.png";
import CompareImg from "src/images/compare.png";
import { RootState } from "src/store/root-reducer";
import { IPostPresetData } from "src/types/instagram";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { IFormDateFilter, IPostAvatarGroup, IWalkthrough, TTriggerVibration } from "src/types/common";
import PostPresetDialog from "src/components/common/dialog/post-preset-dialog";
import CrudPresetDialog from "src/components/common/dialog/crud-preset-dialog";
import { PostComparisonContextProvider } from "src/contexts/comparison-post-context";
import { checkObjectNotEmpty } from "src/utils/general";
import { setMultiPost, setPostAvatarGroup } from "src/slices/compare";
import { faBook } from "@fortawesome/pro-regular-svg-icons";
import JoyrideComponent from "src/components/comparison/profiles/overview-joyride";
import { TUTORIALS } from "src/utils/constants";
import { setTutorialInProgress } from "src/slices/general";
import ArrowOverflowBox from "src/components/common/layout/arrow-overflow-box";

export type TDisplayMode = "percentage" | "number" | null;
export type TTableDirection = "horizontal" | "vertical";

export interface IEditPostPresetDialog {
  open: boolean;
  preset: IPostPresetData | null;
}

export interface ICrudPresetDialog {
  create: boolean;
  update: boolean;
}

export interface ICurrentActivePostPreset {
  index: number;
  preset: IPostPresetData | null;
}

interface ComparisonByPostsProps {}

type Props = ComparisonByPostsProps;

const ComparisonByPosts: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const minCompare = 2;
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { http, apiEndpoint } = useHttp();
  const [maxCompare, setMaxCompare] = useState(10);
  const { smDown, mdDown, smUp, lgUp } = useMediaQueries();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [postIds, setPostIds] = useState<string[]>(["", ""]);
  const [currentActive, setCurrentActive] = useState<ICurrentActivePostPreset>({ index: -1, preset: null });
  const { socialMedia } = useSelector((state: RootState) => state.general);
  const { user } = useSelector((state: RootState) => state.auth);
  const [postDialog, setPostDialog] = useState({ index: -1, open: false });
  const [presetDialog, setPresetDialog] = useState(false);
  const [addMoreDialog, setAddMoreDialog] = useState(false);
  const [crudPresetDialog, setCrudPresetDialog] = useState({ create: false, update: false });
  const [openFullScreenDialog, setOpenFullScreenDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editPresetDialog, setEditPresetDialog] = useState<IEditPostPresetDialog>({ open: false, preset: null });
  const [displayMode, setDisplayMode] = useState<TDisplayMode>("number");
  const [openSortPost, setOpenSortPost] = useState(false);
  const [triggerVibration, setTriggerVibration] = useState<TTriggerVibration>(null);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const [collapse, setCollapse] = useState<{ [key: string]: boolean } | null>(null);
  const instaTableHeight = currentActive && currentActive.preset ? 730 : 630;
  const tiktokTableHeight = currentActive && currentActive.preset ? 830 : 730;
  const [tableDirection, setTableDirection] = useState<TTableDirection>("vertical");
  const [prevSocialMedia, setPrevSocialMedia] = useState(socialMedia);

  // last 7 days
  const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
  // get the end of the current month
  const endDate = moment().format("YYYY-MM-DD");

  const formik = useFormik<IFormDateFilter>({
    validateOnChange: false,
    initialValues: {
      start_min: startDate,
      start_max: endDate,
    },
    onSubmit: () => {},
  });

  const { data: presetData, mutate } = useSWR<IPostPresetData[]>([
    socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_COMPARE_POST_PRESET : apiEndpoint.TIKTOK_COMPARE_POST_PRESET,
  ]);

  // instruction steps
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [activeRun, setActiveRun] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [manualTrigger, setManualTrigger] = useState(false);
  const { data: walkthrough } = useSWR<IWalkthrough[]>([apiEndpoint.INTERACTIVE_WALKTHROUGH_STATUS]);

  const walkthroughImageWidth = smDown ? "100%" : "600px";

  const steps: Step[] = [
    {
      target: "body",
      placement: "center",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth} alignItems="center" justifyContent={"center"}>
          <Typography>This page lets you compare performance of multiple posts.</Typography>
          <img src={CompareImg} alt="compare" width="100%" />
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".step-1",
      content: (
        <Stack spacing={2}>
          <Typography textAlign={"left"}>
            Click here to start adding posts and save your comparison as a preset. This allows you to easily reopen your previous comparisons next
            time.
          </Typography>
        </Stack>
      ),
    },
    {
      target: ".step-2",
      content: (
        <Stack spacing={2}>
          <Typography textAlign={"left"}> You can also create a preset first and add posts to it later.</Typography>
        </Stack>
      ),
    },
    {
      target: ".step-3",
      content: (
        <Stack spacing={2}>
          <Typography textAlign={"left"}> Switch to other social media platforms for more comparisons.</Typography>
        </Stack>
      ),
    },
    {
      target: ".step-4",
      content: (
        <Stack spacing={2}>
          <Typography textAlign="left"> Set the view to full window size for a better perspective.</Typography>
        </Stack>
      ),
    },
    {
      target: ".step-5",
      content: (
        <Stack spacing={2}>
          <Typography> Compare profiles here if your plan includes this feature.</Typography>
        </Stack>
      ),
      locale: {
        last: "Complete",
      },
    },
  ];

  const activeSteps: Step[] = [
    {
      target: ".active-step1",
      placement: "bottom",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth}>
          <Typography>Reorder your posts to organize them as needed.</Typography>
          <img src={theme.palette.mode === "light" ? LightReorderGif : DarkReorderGif} alt="Start Tracking" width="100%" />
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".active-step2",
      placement: "top",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth}>
          <Typography>Switch the orientation to view profiles in different layouts.</Typography>
          <img src={theme.palette.mode === "light" ? LightOrientationGif : DarkOrientationGif} alt="Start Tracking" width="100%" />
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".active-step3",
      placement: "bottom",
      content: (
        <Stack spacing={2} width={walkthroughImageWidth}>
          <Typography>Select to view data by percentage or by actual numbers.</Typography>
          <img src={theme.palette.mode === "light" ? LightPercentageGif : DarkPercentageGif} alt="Start Tracking" width="100%" />
        </Stack>
      ),
      styles: smDown
        ? {}
        : {
            options: {
              width: "auto",
            },
          },
    },
    {
      target: ".active-step4",
      placement: "right",
      content: (
        <Stack spacing={2} alignItems="center" justifyContent={"center"}>
          <Typography>Change or remove posts from your preset here.</Typography>
        </Stack>
      ),
    },
    {
      target: ".active-step5",
      content: (
        <Stack spacing={2} alignItems="center" justifyContent={"center"}>
          <Typography>Clear all posts from the current preset with this option.</Typography>
        </Stack>
      ),
    },
    {
      target: ".active-step6",
      content: (
        <Stack spacing={2} alignItems="center" justifyContent={"center"}>
          <Typography>View detailed charts and statistics for all selected posts here.</Typography>
        </Stack>
      ),
      locale: {
        last: "Complete",
      },
    },
  ];

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleDeselectAll = () => {
    setPostIds(["", ""]); //when delete preset, set back to empty selection
    setCurrentActive({ index: -1, preset: null });
  };

  const checkArrayExist = useCallback(
    (currArray: string[]) => {
      if (presetData) {
        for (let i = 0; i < presetData.length; i++) {
          const objArray = presetData[i];
          let targetArray = objArray?.posts.map(child => child.post_id);

          // Sort both arrays before comparing to ignore sequence
          if (_.isEqual(currArray.slice().sort(), targetArray.slice().sort())) {
            // if array same that means it exist
            return true;
          }
        }
      }
      return false;
    },
    [presetData]
  );

  const handleSavePreset = async (name: string, createPresetWithPosts: boolean, avatarGroups?: string[]) => {
    try {
      const { data: newlyCreatedPreset }: { data: IPostPresetData } = await http.post(
        socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_COMPARE_POST_PRESET : apiEndpoint.TIKTOK_COMPARE_POST_PRESET,
        {
          title: name,
          posts: createPresetWithPosts ? (avatarGroups ? avatarGroups.filter(child => child !== "") : postIds.filter(child => child !== "")) : [],
        }
      );
      toast.success("Saved preset to list");
      mutate();
      const { data: newlyUpdatedPresetList }: { data: IPostPresetData[] } = await http.get(
        socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_COMPARE_POST_PRESET : apiEndpoint.TIKTOK_COMPARE_POST_PRESET
      );

      const foundObjIndex = newlyUpdatedPresetList.findIndex(preset => preset.id === newlyCreatedPreset.id);

      const tempPostIds = newlyUpdatedPresetList[foundObjIndex].posts.map(child => child.post_id);

      setPostIds(newlyUpdatedPresetList[foundObjIndex].posts.length === 0 ? ["", ""] : tempPostIds.length === 1 ? [...tempPostIds, ""] : tempPostIds);

      setCurrentActive({ index: foundObjIndex, preset: newlyUpdatedPresetList[foundObjIndex] });
      setPresetDialog(false);
    } catch (err) {
      console.error(err);
    }
  };
  const handleEditPreset = async (id: number, title: string, postIds?: string[]) => {
    try {
      await http.put(
        socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_COMPARE_POST_PRESET_EDIT(id) : apiEndpoint.TIKTOK_COMPARE_POST_PRESET_EDIT(id),
        {
          title: title,
          posts: postIds
            ? postIds.filter(child => child !== "")
            : editPresetDialog.preset && editPresetDialog.preset.posts.map(child => child.post_id),
        }
      );

      toast.success(`Edited Preset - ${title}`);
      mutate();
    } catch (err) {
      console.error(err);
    }
  };
  const handleDeletePreset = async (id: number) => {
    try {
      await http.delete(
        socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_COMPARE_POST_PRESET_EDIT(id) : apiEndpoint.TIKTOK_COMPARE_POST_PRESET_EDIT(id)
      );
      toast.success("Deleted Preset");
      setShowDeleteDialog(false);
      handleDeselectAll();
      setEditPresetDialog({ open: false, preset: null });
      mutate();
    } catch (err) {
      console.error(err);
    }
  };

  const addMoreDisabled = postIds.length >= maxCompare || postIds.some(child => child === "");
  const postExists = postIds.some(child => child !== "");
  const savePresetDisabled =
    postIds.filter(child => child !== "").length < minCompare || postIds.every(child => child === "") || checkArrayExist(postIds);
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    // if profile exist or selected, set active run to true
    if (!walkthrough) return;
    if (run && !manualTrigger) return;
    const tempWalkthrough = walkthrough.find(child => child.section === TUTORIALS.COMPARE_WIDGET);
    // auto trigger the tutorial if this walkthrough is not being viewed before
    if (postIds.filter(child => child !== "").length > 0 && ((!tempWalkthrough?.viewed && !manualTrigger) || manualTrigger)) {
      setActiveRun(true);
      setActiveStepIndex(0);
      setManualTrigger(false);
      dispatch(setTutorialInProgress(true));
    }
  }, [run, manualTrigger, dispatch, walkthrough, postIds]);

  useEffect(() => {
    if (postIds.length > 8) {
      setTableDirection("horizontal");
    } else {
      setTableDirection("vertical");
    }
  }, [postIds, setTableDirection]);

  useEffect(() => {
    if (socialMedia !== prevSocialMedia) {
      // Update the previous username
      setPrevSocialMedia(socialMedia);
      setRun(false);
      setStepIndex(0);
      setActiveRun(false);
      setActiveStepIndex(0);
      setManualTrigger(false);
      handleDeselectAll();
    }
  }, [prevSocialMedia, socialMedia]);

  useEffect(() => {
    if (presetData) {
      const result = (presetData as any).reduce((acc: { [key: string]: boolean }, curr: any) => {
        acc[curr.id] = false;
        return acc;
      }, {});

      setCollapse(state => {
        return { ...result, ...state };
      });

      setCurrentActive(prevState => {
        // change the name of the current active preset when edited
        if (prevState && prevState.preset) {
          const preset = presetData.find(child => child.id === prevState?.preset?.id);
          return { ...prevState, preset: preset ?? null };
        }
        return prevState;
      });
    }
  }, [presetData]);

  useEffect(() => {
    if (user && checkObjectNotEmpty(user.subscription) && user.subscription.package.max_compare_post_limit) {
      setMaxCompare(user.subscription.package.max_compare_post_limit);
    }
  }, [user]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Delete") {
        if (currentActive.preset) {
          setEditPresetDialog({ open: true, preset: currentActive.preset });
          setShowDeleteDialog(true);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      dispatch(setMultiPost({}));
      dispatch(setPostAvatarGroup(null));
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, currentActive]);
  /* ================================================== */
  /* ================================================== */
  return (
    <>
      {user?.subscription && checkObjectNotEmpty(user.subscription) && user.subscription.package.is_compare_posts && (
        <>
          <JoyrideComponent
            run={run}
            setRun={setRun}
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            steps={steps}
            tutorialSection={TUTORIALS.COMPARE}
          />

          <JoyrideComponent
            run={activeRun}
            setRun={setActiveRun}
            stepIndex={activeStepIndex}
            setStepIndex={setActiveStepIndex}
            steps={activeSteps}
            tutorialSection={TUTORIALS.COMPARE_WIDGET}
          />
        </>
      )}

      <OuterLayout maxWidth={"xl"}>
        <Grid container spacing={2} sx={{ pb: 6 }}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box sx={{ py: 2, px: 2, flexGrow: 1 }}>
                <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                  <Typography variant="h6" color="text.secondary" fontWeight="normal" textAlign={"left"}>
                    Posts/Campaign Comparison
                  </Typography>
                </Stack>
              </Box>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                sx={{ py: 1.8 }}
                className="step-5"
                endIcon={<FontAwesomeIcon icon={faRefresh} size="sm" />}
                onClick={() => {
                  navigate(routes.COMPARISON_PROFILES);
                  dispatch(setMultiPost({}));
                  dispatch(setPostAvatarGroup(null));
                }}
              >
                <Typography variant="body1" whiteSpace="nowrap" fontWeight={600}>
                  Profiles {smUp && "Compare"}
                </Typography>
              </Button>
            </Stack>
          </Grid>
          <>
            {checkObjectNotEmpty(user?.subscription) && user?.subscription.package.is_compare_posts ? (
              <>
                {!lgUp && postExists && (
                  <Grid item xs={12}>
                    <Paper elevation={5} sx={{ p: 1 }}>
                      <ArrowOverflowBox>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={1}>
                          <Tooltip arrow title="Reorder Posts">
                            <ToggleButton
                              value="check"
                              onClick={() => setOpenSortPost(true)}
                              sx={{
                                "&:hover": {
                                  background: theme.palette.primary.main,
                                  color: "white",
                                },
                                "&:active": {
                                  background: "white",
                                  color: theme.palette.primary.main,
                                },
                                color: theme.palette.primary.main,
                              }}
                            >
                              <FontAwesomeIcon icon={faArrowDownBigSmall} style={{ transform: "rotate(-90deg)" }} />
                            </ToggleButton>
                          </Tooltip>
                          <ToggleComponent
                            displayMode={displayMode}
                            tableDirection={tableDirection}
                            setDisplayMode={setDisplayMode}
                            profileOrPosts={postIds}
                            setTableDirection={setTableDirection}
                          />
                          <DateFilter formik={formik} />
                        </Stack>
                      </ArrowOverflowBox>
                    </Paper>
                  </Grid>
                )}
                <PostComparisonContextProvider
                  theme={theme}
                  formik={formik}
                  endDate={endDate}
                  startDate={startDate}
                  postIds={postIds}
                  collapse={collapse}
                  minCompare={minCompare}
                  maxCompare={maxCompare}
                  postDialog={postDialog}
                  presetData={presetData}
                  postExists={postExists}
                  displayMode={displayMode}
                  openSortPost={openSortPost}
                  addMoreDialog={addMoreDialog}
                  currentActive={currentActive}
                  tableDirection={tableDirection}
                  addMoreDisabled={addMoreDisabled}
                  triggerVibration={triggerVibration}
                  savePresetDisabled={savePresetDisabled}
                  openFullScreenDialog={openFullScreenDialog}
                  setCollapse={setCollapse}
                  setPostIds={setPostIds}
                  setDisplayMode={setDisplayMode}
                  setPostDialog={setPostDialog}
                  checkArrayExist={checkArrayExist}
                  setAddMoreDialog={setAddMoreDialog}
                  setCurrentActive={setCurrentActive}
                  setPresetDialog={setPresetDialog}
                  setOpenSortPost={setOpenSortPost}
                  setTableDirection={setTableDirection}
                  handleEditPreset={handleEditPreset}
                  handleDeselectAll={handleDeselectAll}
                  setTriggerVibration={setTriggerVibration}
                  setCrudPresetDialog={setCrudPresetDialog}
                  setEditPresetDialog={setEditPresetDialog}
                  setOpenFullScreenDialog={setOpenFullScreenDialog}
                  tableHeight={socialMedia === "instagram" ? instaTableHeight : tiktokTableHeight}
                >
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ position: "relative", mt: user && user.expired ? 1 : 0, px: user && user.expired ? 2 : 0 }}>
                        {user && user.expired && <ExpiredOverlay />}
                        {socialMedia === "instagram" ? <InstagramPostCompare /> : <TikTokPostCompare />}
                      </Box>
                    </Grid>

                    {(!lgUp || openFullScreenDialog) && (
                      <PostPresetDialog
                        open={presetDialog}
                        presetData={presetData}
                        setPostIds={setPostIds}
                        currentActive={currentActive}
                        setCurrentActive={setCurrentActive}
                        onClose={() => setPresetDialog(false)}
                        setEditPresetDialog={setEditPresetDialog}
                      />
                    )}

                    <PostsDialog
                      open={postDialog.open}
                      onClose={() => {
                        dispatch(setMultiPost({}));
                        dispatch(setPostAvatarGroup(null));
                        setPostDialog({ index: -1, open: false });
                      }}
                      onSelectPost={(postId: string) => {
                        if (!buttonClicked) {
                          setButtonClicked(true);

                          if (postIds.includes(postId)) {
                            toast.error("Post already exist!");
                          } else {
                            const replaceItemInArray = (array: string[]) => {
                              let temp = array.slice();
                              temp[postDialog.index] = postId;
                              return temp;
                            };

                            // this is to change the view locally
                            setPostIds(prevState => {
                              if (postId) {
                                let temp = replaceItemInArray(prevState);
                                return temp;
                              } else {
                                return prevState;
                              }
                            });

                            // this is to change at api level
                            if (currentActive.preset) {
                              let temp = replaceItemInArray(postIds);
                              handleEditPreset(currentActive.preset.id, currentActive.preset.title, temp);
                            }
                          }

                          setTimeout(() => {
                            setButtonClicked(false);
                          }, 500);
                          setPostDialog({ index: -1, open: false });
                        }
                      }}
                    />
                    {/* <PostsDialog
                      open={addMoreDialog}
                      onClose={() => setAddMoreDialog(false)}
                      onSelectPost={(postId: string) => {
                        if (!buttonClicked) {
                          setButtonClicked(true);
                          if (postIds.includes(postId)) {
                            toast.error("Post already exist!");
                          } else {
                            setPostIds(prevState => [...prevState, postId]);
                            if (currentActive.preset) {
                              handleEditPreset(currentActive.preset.id, currentActive.preset.title, [...postIds, postId]);
                            }
                          }

                          setTimeout(() => {
                            setButtonClicked(false);
                          }, 500);
                        }
                      }}
                    /> */}

                    <MultiPostDialog
                      open={addMoreDialog}
                      handleSavePreset={handleSavePreset}
                      onClose={() => {
                        dispatch(setMultiPost({}));
                        dispatch(setPostAvatarGroup(null));
                        setAddMoreDialog(false);
                      }}
                      onSelectPosts={(posts: IPostAvatarGroup[]) => {
                        let tempPosts = posts.map(post => post.postId);
                        // if there's only 1 item, set the other to be empty string
                        if (tempPosts.length === 1) {
                          tempPosts.push("");
                        } else if (tempPosts.length === 0) {
                          tempPosts = ["", ""];
                        }
                        setPostIds(tempPosts);
                        if (currentActive.preset) {
                          handleEditPreset(currentActive.preset.id, currentActive.preset.title, tempPosts);
                        }
                        setAddMoreDialog(false);
                        dispatch(setMultiPost({}));
                        dispatch(setPostAvatarGroup(null));
                      }}
                    />

                    <EditPresetDialog
                      preset={editPresetDialog.preset}
                      open={editPresetDialog.open}
                      onClose={() => setEditPresetDialog({ open: false, preset: null })}
                      onEdit={handleEditPreset}
                      onDelete={handleDeletePreset}
                      showDeleteDialog={showDeleteDialog}
                      setShowDeleteDialog={setShowDeleteDialog}
                    />
                    <CrudPresetDialog
                      crud="create"
                      currentActive={currentActive}
                      open={crudPresetDialog.create}
                      onClose={() => setCrudPresetDialog({ ...crudPresetDialog, create: false })}
                      onSave={handleSavePreset}
                    />
                    <CrudPresetDialog
                      crud="update"
                      currentActive={currentActive}
                      open={crudPresetDialog.update}
                      onClose={() => setCrudPresetDialog({ ...crudPresetDialog, update: false })}
                      onSave={handleSavePreset}
                    />
                  </>
                </PostComparisonContextProvider>
              </>
            ) : (
              <Grid item xs={12}>
                <Paper
                  elevation={5}
                  sx={{
                    p: 4,
                    borderRadius: "8px",
                  }}
                >
                  <Stack spacing={2} alignItems="center">
                    <Typography variant="body1" color="text.secondary">
                      Your plan does not have access to <b>Posts or Campaign Comparison</b>. <br />
                      Please upgrade your plan to unlock this feature.
                    </Typography>

                    <Box sx={{ width: mdDown ? (smDown ? "80%" : "50vw") : "400px" }}>
                      <img src={FeatureLock} alt="lock" width="100%" height="100%" />
                    </Box>

                    <Box>
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate(routes.PRICING);
                        }}
                      >
                        GET IT IN PRO
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            )}
          </>
          {user?.subscription && checkObjectNotEmpty(user.subscription) && user.subscription.package.is_compare_posts && (
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Link
                sx={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => {
                  setRun(true);
                  setStepIndex(0);
                  setManualTrigger(true);
                }}
              >
                <Typography>
                  <FontAwesomeIcon icon={faBook} />
                  &nbsp;Need help? View all our key features with a guided walkthrough here.
                </Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      </OuterLayout>

      <SubscriptionPlanDialog open={showSubscriptionDialog} onClose={() => setShowSubscriptionDialog(false)} />

      <GoToTopButton />
    </>
  );
};

export default ComparisonByPosts;
