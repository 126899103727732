import React, { useState } from "react";
/* components */
/* 3rd party lib */
import { Link } from "react-router-dom";
import { Box, Collapse, Divider, Drawer, List, ListItem, ListItemButton, Stack, Typography, useTheme } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
/* Util */
import routes from "src/routes";
import LogoLight from "src/images/logo_light.png";
import LogoDark from "src/images/logo_dark.png";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectanglePro, faHome, faScaleBalanced, faStar, faUser, faUsersGear } from "@fortawesome/pro-regular-svg-icons";
import { RootState } from "src/store/root-reducer";
import { useSelector } from "react-redux";
import { ProColor } from "src/utils/constants";

interface NavigationDrawerProps {
  open: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

type Props = NavigationDrawerProps;

const NavigationDrawer: React.FC<Props> = ({ open, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { mode } = theme.palette;
  const { smDown } = useMediaQueries();
  const { user } = useSelector((state: RootState) => state.auth);
  const [openSubMenus, setOpenSubMenus] = useState<{ [key: string]: boolean }>({});

  const hasCredit = process.env.REACT_APP_CREDIT === "true";
  const navigationListItems = [
    {
      label: "Dashboard",
      url: routes.ROOT,
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color={theme.palette.text.secondary} size={"lg"} />,
    },
    {
      label: "Favourites",
      url: routes.FAVOURITES,
      show: true,
      icon: <FontAwesomeIcon icon={faStar} color={theme.palette.text.secondary} size={"lg"} />,
    },
    {
      label: "Comparison",
      show: true,
      icon: <FontAwesomeIcon icon={faScaleBalanced} color={theme.palette.text.secondary} size={"lg"} />,
      children: [
        {
          label: "By Profiles",
          url: routes.COMPARISON_PROFILES,
          icon: (
            <span>
              <Box
                sx={{
                  fontSize: "10px",
                  fontWeight: 800,
                  px: "1px",
                  border: `2px solid ${theme.palette.primary.main}`,
                  color: theme.palette.primary.main,
                  borderRadius: "3px",
                }}
              >
                LITE
              </Box>
            </span>
          ),
        },
        {
          label: "By Posts/Campaign",
          url: routes.COMPARISON_POSTS,
          icon: <FontAwesomeIcon icon={faRectanglePro} color={ProColor} size={"lg"} />,
        },
      ],
    },
    {
      label: "My Account",
      url: `${routes.MY_ACCOUNT}#profile`,
      show: hasCredit,
      icon: <FontAwesomeIcon icon={faUser} color={theme.palette.text.secondary} size={"lg"} />,
    },
    {
      label: "Account Management",
      url: routes.SUPERADMIN,
      show: user?.isAdmin,
      icon: <FontAwesomeIcon icon={faUsersGear} color={theme.palette.text.secondary} size={"lg"} />,
    },
  ];

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleSubMenuClick = (index: number) => {
    setOpenSubMenus(prevOpenSubMenus => ({
      ...prevOpenSubMenus,
      [index]: !prevOpenSubMenus[index], // Toggle the state for the clicked index
    }));
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Drawer anchor={"left"} open={open} onClose={onClose}>
        <List sx={{ minWidth: smDown ? "80vw" : 400 }}>
          <ListItem disablePadding>
            <ListItemButton>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Link to="/">
                  <img src={mode === "light" ? LogoLight : LogoDark} alt="logo" /* width="34px" */ height="28px" />
                </Link>
                {/* <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    flexGrow: 1,
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  Intec
                </Typography> */}
              </Stack>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          {navigationListItems.map((item, index) =>
            item.children ? (
              <div key={index}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleSubMenuClick(index)}>
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width="100%">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Stack alignItems="center" sx={{ width: "36px" }}>
                          {item.icon}
                        </Stack>
                        <Typography variant="body1" color="text.secondary">
                          {item.label}
                        </Typography>
                      </Stack>

                      {item.children && // Check if children exist
                        (openSubMenus[index] ? (
                          <ExpandMoreIcon /> // Render the open icon
                        ) : (
                          <ChevronRightIcon /> // Render the closed icon
                        ))}
                    </Stack>
                  </ListItemButton>
                </ListItem>
                {item.children && (
                  <Collapse in={openSubMenus[index]}>
                    <List sx={{ py: 0, background: mode === "light" ? (theme.palette.neutral as any)[100] : (theme.palette.neutral as any)[800] }}>
                      {item.children.map((childItem, childIndex) => (
                        <ListItem key={childIndex} disablePadding>
                          <ListItemButton component={Link} to={childItem.url} sx={{ py: 2, pl: 8 }} onClick={e => onClose(e)}>
                            <Typography variant="body1" color="text.secondary" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                              {childItem.label} {childItem.icon ? childItem.icon : null}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </div>
            ) : (
              item.show && (
                <div key={index}>
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to={item.url} onClick={e => onClose(e)}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Stack alignItems="center" sx={{ width: "36px" }}>
                          {item.icon}
                        </Stack>
                        <Typography variant="body1" color="text.secondary">
                          {item.label}
                        </Typography>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                </div>
              )
            )
          )}
        </List>

        <Box sx={{ px: 2, position: "absolute", bottom: "8px" }}>
          <Typography color="text.secondary" variant="subtitle2" sx={{ wordBreak: "break-word" }}>
            v{localStorage.getItem("version") ?? ""}
          </Typography>
        </Box>
      </Drawer>
    </>
  );
};

export default NavigationDrawer;
