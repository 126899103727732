import { Box, Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslateString } from "src/utils/translate";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { BUTTON_BACK, NO_PERMISSION, PERMISSION_ACCESS_DENIED } from "src/constants/translate-keys/common";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LayoutRootState } from "src/slices/layout";
import PermissionDenied from "src/images/permission_denied.png";

interface NoPermissionProps {
  shadow?: boolean;
  fullWidth?: boolean;
}

type Props = NoPermissionProps;

const NoPermission: React.FC<Props> = ({ shadow = true, fullWidth = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const { navbar } = useSelector((state: { layout: LayoutRootState }) => state.layout);

  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        height: `calc(100% - ${navbar.height}px)`,
      }}
    >
      <Card elevation={shadow ? 10 : 0} sx={{ textAlign: "center", px: 2, minWidth: fullWidth ? "100%" : smDown ? "100%" : "500px" }}>
        <CardContent>
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h5">{getTranslateString(t, PERMISSION_ACCESS_DENIED)}</Typography>
            <Box sx={{ position: "relative", height: "100px", width: "100px" }}>
              <img alt={"permission_denied"} src={PermissionDenied} style={{ objectFit: "contain" }} />
            </Box>
            <Typography variant="body1" color="text.secondary">
              {getTranslateString(t, NO_PERMISSION)}
            </Typography>
          </Stack>
        </CardContent>
        <CardActions sx={{ display: "grid", placeItems: "center" }}>
          <Button variant="contained" size="small" onClick={() => navigate(-1)}>
            {getTranslateString(t, BUTTON_BACK)}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default NoPermission;
