/* components */
import DataTableList from "src/components/common/data-display/data-table-list";
/* 3rd party lib */
import moment from "moment";
import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { Box, Paper, Typography } from "@mui/material";
/* Util */
import useHttp from "src/hooks/use-http";
import { TABLE_ID } from "src/utils/constants";
import { IActivityLog } from "src/types/common";
import { showActionWhenHovered } from "src/components/common/data-display/dnd-table";
import GoToTopButton from "src/components/common/inputs/go-to-top-button";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface ActivityLogTableProps {
  user_id?: string;
}

type Props = ActivityLogTableProps;

const ActivityLogTable: React.FC<Props> = ({ user_id }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { apiEndpoint } = useHttp();
  const { smDown } = useMediaQueries();
  const activityLogColDefinition: MRT_ColumnDef<{}>[] = useMemo(
    () => [
      // {
      //   accessorKey: "no",
      //   header: "#",
      //   size: 0,
      //   muiTableHeadCellProps: {
      //     sx: {
      //       ...NUMBER_CELL_TRANSLATE,
      //     },
      //   },
      //   filterVariant: "text",
      //   enablePinning: false,
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnFilter: false,
      //   enableColumnOrdering: false,
      //   enableColumnDragging: false,

      //   Cell: ({ row }) => {
      //     const { currentPage, rowsPerPage } = checkActivityLogPagination;
      //     return (
      //       <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
      //         {getDynamicIndex(currentPage - 1, rowsPerPage, row.index + 1)}.
      //       </Typography>
      //     );
      //   },
      // },
      {
        accessorKey: "type",
        header: "Type",
        size: 35,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        Cell: ({ row }) => {
          const rowData = row.original as IActivityLog;
          return (
            <Typography variant="body2" fontWeight={500} color={rowData.type === "add" ? "success.main" : "error.main"}>
              {rowData.type === "add" ? "Add" : "Remove"}
            </Typography>
          );
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
      },
      {
        accessorKey: "created",
        header: "Created",
        size: 60,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as IActivityLog;
          return <Typography variant="body2">{moment(rowData.created).format("DD MMM YYYY (hh:mm A)")}</Typography>;
        },
      },
    ],
    []
  );

  const activityLogMetaData = {
    primary: {
      path: "id",
      content: (rowData: IActivityLog) => (
        <Typography variant="h6" fontWeight={500} color={rowData.type === "add" ? "success.main" : "error.main"}>
          {rowData.type === "add" ? "Add" : "Remove"} #{rowData.id}
        </Typography>
      ),
    },
    sortFields: [],
    fields: [
      {
        path: "created",
        label: "Created",
        content: (rowData: IActivityLog) =>
          rowData.created && (
            <Typography variant="body2" color="text.secondary">
              {moment(rowData.created).format("DD MMM YYYY (hh:mm A)")}
            </Typography>
          ),
      },
      {
        path: "description",
        label: "Description",
        content: (rowData: IActivityLog) => (
          <Typography variant="body2" color="text.secondary">
            {rowData.description}
          </Typography>
        ),
      },
    ],
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Paper elevation={10} sx={{ width: "100%" }}>
      <Box sx={{ p: smDown ? 2 : 4 }}>
        <Typography sx={{ mb: smDown ? 2 : 4 }} textAlign="left" variant="h5" fontWeight={500} color="primary.main">
          Activity Log
        </Typography>

        <DataTableList
          mobilePaginationStickBottom={false}
          id={TABLE_ID.ACTIVITY_LOG}
          columns={activityLogColDefinition}
          columnOrder={activityLogColDefinition.map(c => c.accessorKey)}
          listMetadata={activityLogMetaData}
          showGlobalFilter
          search={{
            key: "q",
            label: "Search",
          }}
          listItemStyle={{
            "&:nth-of-type(odd)": {
              backgroundColor: "#f4f4f4c0",
            },
          }}
          url={apiEndpoint.ACTIVITY_LOG(user_id)}
        />
        <GoToTopButton />
      </Box>
    </Paper>
  );
};

export default ActivityLogTable;
