/* components */
import DataDisplay from "src/components/common/inputs/data-display";
/* 3rd party lib */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  styled,
  Switch,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FormikProps, FormikState, useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

/* Util */
import { getTranslateString } from "src/utils/translate";
import { FORM_WARNING_PASSWORD_MUST_MATCH, FORM_WARNING_REQUIRED, FORM_WARNING_VALID_EMAIL } from "src/constants/translate-keys/common";
import useSWR from "swr";
import useHttp from "src/hooks/use-http";
import { IUserEmail, TSubscriptionAccount } from "src/types/common";
import { toast } from "react-hot-toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root-reducer";
import moment from "moment";
import { nullUndefinedReturnDash } from "src/utils/general";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface UserCredentialProps {
  user_id: string | undefined;
}

type Props = UserCredentialProps;

const CustomSwitch = styled(Switch)(({ theme }) => {
  const { mode } = theme.palette;
  return {
    "& .MuiSwitch-switchBase": {
      color: mode === "dark" ? (theme.palette as any).neutral[400] : "default",
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: mode === "dark" ? (theme.palette as any).neutral[500] : "default",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: mode === "dark" ? theme.palette.primary.main : "default",
      "&:hover": {
        backgroundColor: mode === "dark" ? alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity) : "default",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: mode === "dark" ? theme.palette.primary.main : "default",
    },
  };
});

interface IPasswordFormValues {
  new_password1: string;
  new_password2: string;
}

const schema = (t: any) =>
  Yup.object().shape({
    new_password1: Yup.string()
      .required(getTranslateString(t, FORM_WARNING_REQUIRED))
      .oneOf([Yup.ref("new_password2")], getTranslateString(t, FORM_WARNING_PASSWORD_MUST_MATCH)),
    new_password2: Yup.string()
      .required(getTranslateString(t, FORM_WARNING_REQUIRED))
      .oneOf([Yup.ref("new_password1")], getTranslateString(t, FORM_WARNING_PASSWORD_MUST_MATCH)),
  });

const UserCredential: React.FC<Props> = ({ user_id }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { t } = useTranslation();
  const { http, apiEndpoint } = useHttp();
  const theme = useTheme();
  const { smDown } = useMediaQueries();
  const { user } = useSelector((state: RootState) => state.auth);
  const [showError, setShowError] = useState<{ show: boolean; messages: string[] }>({ show: false, messages: [] });
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  const { data: userProfileData } = useSWR<TSubscriptionAccount>([user_id && apiEndpoint.SUBSCRIPTION_ACCOUNT_DETAIL(user_id)]);

  const emailSchema = (t: any) =>
    Yup.object().shape({
      email: Yup.string().email(getTranslateString(t, FORM_WARNING_VALID_EMAIL)).required(getTranslateString(t, FORM_WARNING_REQUIRED)),
    });

  const { data: emailData, mutate } = useSWR<IUserEmail>([user_id && apiEndpoint.SUBSCRIPTION_ACCOUNT_EMAIL(user_id)]);

  const handleUpdatePassword = async (
    values: IPasswordFormValues,
    resetForm: (nextState?: Partial<FormikState<IPasswordFormValues>> | undefined) => void
  ) => {
    try {
      await http.put(apiEndpoint.SUBSCRIPTION_ACCOUNT_PASSWORD(user_id), values);
      mutate();
      setShowPasswordDialog(false);
      toast.success("Set password successfully.");
      resetForm();
    } catch (err: any) {
      console.error(err);
      if (err && err.response && err.response.data && err.response.data.new_password2) {
        setShowError({ show: true, messages: err.response.data.new_password2 });
      }
      toast.error("Something went wrong when setting password.");
    }
  };
  const handleUpdateEmail = async (email: string) => {
    try {
      await http.put(apiEndpoint.SUBSCRIPTION_ACCOUNT_EMAIL(user_id), {
        email: email,
      });
      mutate();
      setShowEmailDialog(false);
      if (emailData) {
        toast.success("Email updated successfully.");
      } else {
        toast.success("Email has been created, please proceed to create password.", { duration: 2000 });
      }
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong when setting email.");
    }
  };
  const handleUpdateActive = async (is_active: boolean) => {
    try {
      await http.patch(apiEndpoint.SUBSCRIPTION_ACCOUNT_EMAIL(user_id), {
        is_active: is_active,
      });
      toast.success("Account active status changed.");
    } catch (err) {
      console.error(err);
      toast.error("Something's wrong when updating account active status.");
    }
  };

  const emailFormik = useFormik<{ email: string; is_active: boolean }>({
    validationSchema: emailSchema(t),
    validateOnChange: false,
    initialValues: {
      email: "",
      is_active: false,
    },
    onSubmit: values => {
      handleUpdateEmail(values.email);
    },
  });

  const passwordFormik = useFormik<IPasswordFormValues>({
    validateOnChange: false,
    validationSchema: schema(t),
    initialValues: {
      new_password1: "",
      new_password2: "",
    },
    onSubmit: (values, { resetForm }) => {
      handleUpdatePassword(values, resetForm);
      setShowError({ show: false, messages: [] });
    },
  });

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (userProfileData && userProfileData.email && userProfileData.email !== "") {
      emailFormik.setFieldValue("email", userProfileData.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData]);

  useEffect(() => {
    if (emailData) {
      emailFormik.setFieldValue("email", emailData.email);
      emailFormik.setFieldValue("is_active", emailData.is_active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailData]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Paper elevation={10} sx={{ width: "100%" }}>
        <Box sx={{ p: smDown ? 2 : 4 }}>
          <Box mb={2}>
            <Typography sx={{ pb: 2.5 }} textAlign="left" variant="h5" fontWeight={500} color="primary.main">
              User Credentials
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {emailData && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <DataDisplay
                    label={"Active Status"}
                    variant={"body2"}
                    value={
                      <FormGroup sx={{ userSelect: "none" }}>
                        <Tooltip arrow title={emailFormik.values.is_active ? "Toggle to disable user's login" : "Toggle to enable user's login"}>
                          <FormControlLabel
                            control={
                              <CustomSwitch
                                name="is_active"
                                disabled={
                                  emailData === undefined || emailData.email === null || emailData.email === undefined || emailData.email === ""
                                }
                              />
                            }
                            disabled={user?.id === emailData?.id}
                            checked={emailFormik.values.is_active}
                            onChange={(e, checked) => {
                              emailFormik.setFieldValue("is_active", checked);
                              handleUpdateActive(checked);
                            }}
                            label={emailFormik.values.is_active ? "Active" : "Inactive"}
                          />
                        </Tooltip>
                      </FormGroup>
                    }
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <DataDisplay
                  label={"Email"}
                  variant={"body2"}
                  value={
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} sx={{ width: "100%" }}>
                      {emailData ? (
                        <Typography variant="body2" fontWeight={400} color="grey.400">
                          {emailData.email && emailData.email !== "" ? emailData.email : "-"}
                        </Typography>
                      ) : (
                        <Button variant="contained" onClick={() => setShowEmailDialog(true)}>
                          <Typography>Setup email</Typography>
                        </Button>
                      )}
                      {emailData && (
                        <Button variant="text" sx={{ "&:hover": { color: theme.palette.primary.main } }} onClick={() => setShowEmailDialog(true)}>
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                      )}
                    </Stack>
                  }
                />
              </FormControl>
            </Grid>
            {emailData && (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <DataDisplay
                      label={"Password"}
                      variant={"body2"}
                      value={
                        <Button variant="outlined" onClick={() => setShowPasswordDialog(true)}>
                          Set Password
                        </Button>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <DataDisplay
                      label={"Last Login"}
                      variant={"body2"}
                      value={
                        <Stack direction="row" alignItems="center" justifyContent={"flex-start"} sx={{ width: "100%" }}>
                          <Typography variant="body2" fontWeight={400} color="grey.400">
                            {emailData && emailData.email && emailData.email !== ""
                              ? nullUndefinedReturnDash(moment(emailData.last_login).format("YYYY-MM-DD (h:mm A)"))
                              : "-"}
                          </Typography>
                        </Stack>
                      }
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            {/* <Grid item xs={12}>
          <FormControl fullWidth>
            <DataDisplay
              label={"Phone"}
              variant={"body2"}
              alignCenter={false}
              value={
                <TextField
                  variant="outlined"
                  placeholder="+65"
                  hiddenLabel
                  name="phone_number"
                  fullWidth
                  size="small"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                  error={formik.errors.phone_number ? true : false}
                  helperText={
                    formik.errors && formik.errors.phone_number ? (
                      formik.errors.phone_number
                    ) : (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <FontAwesomeIcon icon={faCircleInfo} size="lg" />
                        <Typography variant="caption" color="text.secondary">
                          Please contact admin to make changes to phone number
                        </Typography>
                      </Stack>
                    )
                  }
                  inputProps={{
                    readOnly: !formik.values.is_active,
                  }}
                />
              }
            />
          </FormControl>
        </Grid> */}
          </Grid>
        </Box>
      </Paper>

      <ChangeEmailDialog data={emailData} theme={theme} open={showEmailDialog} formik={emailFormik} onClose={() => setShowEmailDialog(false)} />
      <ChangePasswordDialog
        theme={theme}
        showError={showError}
        open={showPasswordDialog}
        formik={passwordFormik}
        onClose={() => {
          setShowPasswordDialog(false);
          passwordFormik.resetForm();
        }}
      />
    </>
  );
};

export default UserCredential;

interface IChangeEmailDialog {
  data: IUserEmail | undefined;
  theme: Theme;
  open: boolean;
  onClose: () => void;
  formik: FormikProps<{ email: string; is_active: boolean }>;
}

const ChangeEmailDialog = ({ open, data, theme, onClose, formik }: IChangeEmailDialog) => {
  return (
    <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle sx={{ p: 2 }}>
        <Stack direction="row" spacing={3}>
          <Typography variant="inherit" sx={{ flexGrow: 1 }}>
            {data ? "Update Email Address" : "Setup Email Address"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit} id="emailForm">
        <DialogContent sx={{ overflowX: "hidden" }}>
          <Paper sx={{ p: 2, mt: 2 }} elevation={5}>
            <Stack sx={{ py: 2 }} alignItems="center">
              <FormControl fullWidth>
                <Alert variant="outlined" severity="info" sx={{ mb: 2, background: alpha(theme.palette.primary.main, 0.15) }}>
                  <AlertTitle>Verification</AlertTitle>A verification email will be sent to your email.
                </Alert>
                <DataDisplay
                  label={"Email"}
                  variant={"body2"}
                  value={
                    <TextField
                      variant="outlined"
                      placeholder="Enter email address here"
                      hiddenLabel
                      name="email"
                      fullWidth
                      size="small"
                      required
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.errors.email ? true : false}
                      helperText={formik.errors.email}
                    />
                  }
                />
              </FormControl>
            </Stack>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" form="emailForm" variant="contained" color={"primary"} disabled={formik.values.email === data?.email ?? ""}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

interface IChangePasswordDialog {
  theme: Theme;
  open: boolean;
  onClose: () => void;
  formik: FormikProps<IPasswordFormValues>;
  showError: { show: boolean; messages: string[] };
}

const ChangePasswordDialog = ({ open, onClose, theme, showError, formik }: IChangePasswordDialog) => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  return (
    <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle sx={{ p: 2 }}>
        <Stack direction="row" spacing={3}>
          <Typography variant="inherit" sx={{ flexGrow: 1 }}>
            Change Password
          </Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit} id="emailForm">
        <DialogContent sx={{ overflowX: "hidden" }}>
          <Paper sx={{ p: 2, mt: 2 }} elevation={5}>
            <Stack spacing={2} sx={{ py: 2 }} alignItems="center">
              <FormControl fullWidth>
                <DataDisplay
                  label={"New Password"}
                  variant={"body2"}
                  value={
                    <TextField
                      value={formik.values.new_password1}
                      fullWidth
                      variant="outlined"
                      hiddenLabel
                      name="new_password1"
                      size="small"
                      required
                      autoComplete="current-password"
                      error={formik.errors.new_password1 ? true : false}
                      helperText={formik.errors.new_password1}
                      onChange={formik.handleChange}
                      type={showPassword1 ? "text" : "password"}
                      InputProps={{
                        endAdornment: formik.values.new_password1 !== "" && (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword1(!showPassword1)} edge="end">
                              {showPassword1 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <DataDisplay
                  label={"Confirm New Password"}
                  variant={"body2"}
                  value={
                    <TextField
                      value={formik.values.new_password2}
                      fullWidth
                      variant="outlined"
                      hiddenLabel
                      name="new_password2"
                      size="small"
                      required
                      autoComplete="current-password"
                      error={formik.errors.new_password2 ? true : false}
                      helperText={formik.errors.new_password2}
                      onChange={formik.handleChange}
                      type={showPassword2 ? "text" : "password"}
                      InputProps={{
                        endAdornment: formik.values.new_password2 !== "" && (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                              {showPassword2 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                />
              </FormControl>
              {showError.show && (
                <Alert variant="outlined" severity="error" sx={{ mb: 2, background: alpha(theme.palette.error.main, 0.15) }}>
                  {showError.messages.map(errorMsg => (
                    <Typography variant="body2">{errorMsg}</Typography>
                  ))}
                </Alert>
              )}
            </Stack>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" form="emailForm" variant="contained" color={"primary"}>
            Change
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
