import { Pagination } from "@mui/material";
import React from "react";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { TPagination } from "src/types/common";
import MobilePagination from "./mobile-pagination";
/* components */
/* 3rd party lib */
/* Util */
interface FullAndMobilePaginationProps {
  pagination: TPagination;
  setPagination: React.Dispatch<React.SetStateAction<TPagination>>;
}

type Props = FullAndMobilePaginationProps;

const FullAndMobilePagination: React.FC<Props> = ({ pagination, setPagination }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { smDown } = useMediaQueries();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      {smDown ? (
        <MobilePagination
          count={pagination.count}
          page={pagination.currPage}
          limit={pagination.limit}
          totalPage={pagination.total_pages}
          onPageChange={(e, page) => {
            if (pagination && page + 1 !== pagination.currPage) {
              setPagination({ ...pagination, currPage: page + 1 });
            }
          }}
        />
      ) : (
        <Pagination
          page={pagination?.currPage}
          onChange={(e, page) => {
            if (pagination && page !== pagination.currPage) {
              setPagination({ ...pagination, currPage: page });
            }
          }}
          count={pagination?.total_pages ?? 0}
          showFirstButton
          showLastButton
        />
      )}
    </>
  );
};

export default FullAndMobilePagination;
