import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, AvatarGroup, Box, Popover, Tooltip, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { setMultiPost, setMultiProfile, setPostAvatarGroup, setProfileAvatarGroup } from "src/slices/compare";
import { RootState } from "src/store/root-reducer";
import { IPostAvatarGroup, IProfileAvatarGroup } from "src/types/common";
import { checkArrayHasKey, checkObjectNotEmpty } from "src/utils/general";
/* components */
/* 3rd party lib */
/* Util */
interface ComparisonAvatarGroupProps {
  type: "post" | "profile";
  avatarGroup?: IProfileAvatarGroup[] | IPostAvatarGroup[] | null;
}

type Props = ComparisonAvatarGroupProps;

const ComparisonAvatarGroup: React.FC<Props> = ({ type, avatarGroup }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const dispatch = useDispatch();
  const { mode } = theme.palette;
  const { smDown, mdDown } = useMediaQueries();
  const { user } = useSelector((state: RootState) => state.auth);
  const { multiPost, multiProfile } = useSelector((state: RootState) => state.compare);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handlePopoverClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Stack sx={{ cursor: "pointer" }} direction="row" alignItems={"center"} spacing={1} onClick={e => handlePopoverClick(e)}>
        {avatarGroup && (
          <AvatarGroup
            spacing={-1}
            max={smDown ? 3 : mdDown ? 6 : 10}
            sx={{
              flexDirection: "row !important",
              ".MuiAvatarGroup-root": {
                flexDirection: "row !important",
              },
              ".MuiAvatar-root:last-of-type": {
                // marginLeft: "-8px",
              },
              ".MuiAvatar-root:first-of-type": {
                background: "none",
                border: "none",
                color: theme.palette.text.secondary,
                fontSize: "20px",
                marginRight: "4px",
              },
            }}
          >
            {avatarGroup.map((child, index) => (
              <Tooltip
                arrow
                key={`avatar_${index}`}
                title={
                  type === "post" && "title" in child ? (
                    <Stack>
                      <Typography variant="caption">@{child.username}</Typography>
                      <Typography variant="caption">{child.title}</Typography>
                    </Stack>
                  ) : (
                    "display_name" in child && (
                      <Box sx={{ textAlign: "center" }}>
                        {child.display_name}
                        <br />@{child.username}
                      </Box>
                    )
                  )
                }
              >
                <Avatar
                  variant={type === "profile" ? "circular" : "rounded"}
                  alt={child.username}
                  src={child.downloaded_image}
                  sx={{ width: type === "profile" ? "40px" : "30px", border: `1px solid ${theme.palette.text.secondary} !important` }}
                />
              </Tooltip>
            ))}
          </AvatarGroup>
        )}

        {avatarGroup && checkObjectNotEmpty(user?.subscription) && (
          <Typography variant="body1" fontSize={18}>
            <span style={{ fontWeight: "bolder" }}> {avatarGroup.length}</span>/
            <span style={{ color: (theme.palette.neutral as any)[mode === "dark" ? 400 : 500] }}>
              {user?.isAdmin ? (
                <>&#8734;</>
              ) : (
                <>{type === "profile" ? user?.subscription.package.max_compare_profile_limit : user?.subscription.package.max_compare_post_limit}</>
              )}
            </span>
          </Typography>
        )}
      </Stack>

      {avatarGroup && (
        <Popover
          id={`${type}_popover`}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{
            "& .MuiPaper-root": {
              ...(theme as any).custom.scroll,
            },
          }}
        >
          {avatarGroup
            .slice()
            .reverse()
            .map((child, index) => (
              <Stack
                key={`popover_${index}`}
                alignItems="center"
                direction="row"
                justifyContent={"space-between"}
                sx={{
                  py: 0.5,
                  px: 1,
                  "&:not(:last-child)": {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  },
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2"> {index + 1}.</Typography>
                  <Avatar
                    variant={type === "profile" ? "circular" : "rounded"}
                    alt={child.username}
                    src={child.downloaded_image}
                    sx={{ width: type === "profile" ? "initial" : "30px", border: `1px solid ${theme.palette.text.secondary} !important` }}
                  />

                  {type === "profile" && "display_name" in child ? (
                    <Stack>
                      <Typography variant="body2">{child.display_name}</Typography>
                      <Typography variant="caption">@{child.username}</Typography>
                    </Stack>
                  ) : (
                    "title" in child && (
                      <Tooltip
                        placement="right-end"
                        title={
                          <Stack>
                            <Typography variant="caption">@{child.username}</Typography>
                            <Typography variant="caption">{child.title}</Typography>
                          </Stack>
                        }
                        arrow
                      >
                        <Stack spacing={1}>
                          <Typography variant="caption">@{child.username}</Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              cursor: "default",
                              display: "-webkit-box",
                              overflow: "hidden",
                              // whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              "-webkit-box-orient": "vertical",
                              WebkitLineClamp: 2,
                              width: "200px",
                            }}
                          >
                            {child.title}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    )
                  )}
                </Stack>
                <Box
                  sx={{
                    pr: 0.5,
                    pl: 1.5,
                    cursor: "pointer",
                    "&:hover": {
                      ".fa-circle-xmark": {
                        color: (theme.palette.neutral as any)[theme.palette.mode === "dark" ? 300 : 500],
                      },
                    },
                    "&:active": {
                      color: (theme.palette.neutral as any)[400],
                    },
                  }}
                  onClick={() => {
                    if (type === "post" && "postId" in child) {
                      let tempMultiPost = { ...multiPost };

                      // if already exist then remove it from the dict
                      if (tempMultiPost[child.username] && tempMultiPost[child.username][child.postId]) {
                        // if username and post exist in the dict, remove it
                        let tempPostId = child.postId;
                        const tempUsernameLevel = { ...tempMultiPost[child.username] };
                        delete tempUsernameLevel[tempPostId];
                        tempMultiPost[child.username] = tempUsernameLevel;
                        // if nothing else left inside the nested child object, remove the parent
                        if (Object.keys(tempMultiPost[child.username]).length === 0) delete tempMultiPost[child.username];
                      }
                      dispatch(setMultiPost(tempMultiPost));

                      if (checkArrayHasKey(avatarGroup, "postId")) {
                        let tempAvatarGroup = [...avatarGroup];
                        // add to the front, remove from the array
                        if (tempAvatarGroup.find(avatarGroupChild => "postId" in avatarGroupChild && avatarGroupChild.postId === child.postId)) {
                          // if found in the array, remove it
                          tempAvatarGroup = tempAvatarGroup.filter(
                            avatarGroupChild => "postId" in avatarGroupChild && avatarGroupChild.postId !== child.postId
                          );
                        }

                        dispatch(setPostAvatarGroup(tempAvatarGroup));
                      }
                    } else {
                      let tempMultiProfile = { ...multiProfile };
                      let tempAvatarGroup = [...avatarGroup];
                      // remove avatar based on username
                      tempAvatarGroup = tempAvatarGroup.filter(avatarGroupChild => avatarGroupChild.username !== child.username);

                      dispatch(setProfileAvatarGroup(tempAvatarGroup));

                      if (tempMultiProfile[child.username]) {
                        // remove the rowData from the dict if it's already exist
                        delete tempMultiProfile[child.username];
                      } else {
                        tempMultiProfile = { ...tempMultiProfile, [child.username]: child.username };
                      }
                      dispatch(setMultiProfile(tempMultiProfile));
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faCircleXmark} color={(theme.palette.neutral as any)[400]} />
                </Box>
              </Stack>
            ))}
        </Popover>
      )}
    </>
  );
};

export default ComparisonAvatarGroup;
