import { Theme } from "@mui/material";
import { FormikProps } from "formik";
import { createContext, useContext } from "react";
import { IProfilePresetData } from "src/types/instagram";
import { ICurrentActiveProfilePreset, IEditPresetDialog, TDisplayMode, TTableDirection } from "src/pages/comparisons/profiles/profiles";
import { IFormDateFilter, TTriggerVibration } from "src/types/common";
import { ICrudPresetDialog } from "src/pages/comparisons/posts/posts";

export type TCollapse = { [key: string]: boolean } | null;

// Define the type for context values
export interface IComparisonContext {
  theme: Theme;
  endDate: string;
  startDate: string;
  minCompare: number;
  maxCompare: number;
  profileExist: boolean;
  tableHeight: number;
  profileDialog: {
    index: number;
    open: boolean;
  };
  setProfileDialog: React.Dispatch<
    React.SetStateAction<{
      index: number;
      open: boolean;
    }>
  >;
  collapse: TCollapse;
  addMoreDialog: boolean;
  addMoreDisabled: boolean;
  displayMode: TDisplayMode;
  profileUsernames: string[];
  openSortProfile: boolean;
  savePresetDisabled: boolean;
  openFullScreenDialog: boolean;
  tableDirection: TTableDirection;
  editPresetDialog: IEditPresetDialog;
  triggerVibration: TTriggerVibration;
  formik: FormikProps<IFormDateFilter>;
  currentActive: ICurrentActiveProfilePreset;
  presetData: IProfilePresetData[] | undefined;
  handleDeselectAll: () => void;
  checkArrayExist: (currArray: string[]) => boolean;
  setCollapse: React.Dispatch<React.SetStateAction<TCollapse>>;
  setPresetDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setAddMoreDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSortProfile: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayMode: React.Dispatch<React.SetStateAction<TDisplayMode>>;
  setProfileUsernames: React.Dispatch<React.SetStateAction<string[]>>;
  setTableDirection: React.Dispatch<React.SetStateAction<TTableDirection>>;
  setCrudPresetDialog: React.Dispatch<React.SetStateAction<ICrudPresetDialog>>;
  setCurrentActive: React.Dispatch<React.SetStateAction<ICurrentActiveProfilePreset>>;
  setEditPresetDialog: React.Dispatch<React.SetStateAction<IEditPresetDialog>>;
  setTriggerVibration: React.Dispatch<React.SetStateAction<TTriggerVibration>>;
  setOpenFullScreenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditPreset: (id: number, title: string, profiles?: string[]) => Promise<void>;
}

export const ComparisonContext = createContext<IComparisonContext | undefined>(undefined);

// Create a provider to wrap components that need access to these values
export const ProfileComparisonContextProvider: React.FC<
  IComparisonContext & {
    children: React.ReactNode;
  }
> = ({
  theme,
  endDate,
  formik,
  collapse,
  startDate,
  children,
  tableHeight,
  displayMode,
  minCompare,
  maxCompare,
  presetData,
  profileDialog,
  profileExist,
  addMoreDialog,
  currentActive,
  addMoreDisabled,
  editPresetDialog,
  openFullScreenDialog,
  savePresetDisabled,
  openSortProfile,
  tableDirection,
  profileUsernames,
  triggerVibration,
  setCollapse,
  handleEditPreset,
  handleDeselectAll,
  setAddMoreDialog,
  setDisplayMode,
  setPresetDialog,
  setProfileDialog,
  checkArrayExist,
  setCurrentActive,
  setTableDirection,
  setCrudPresetDialog,
  setEditPresetDialog,
  setOpenSortProfile,
  setProfileUsernames,
  setTriggerVibration,
  setOpenFullScreenDialog,
}) => {
  const contextValues: IComparisonContext = {
    theme: theme,
    formik: formik,
    collapse: collapse,
    endDate: endDate,
    startDate: startDate,
    minCompare: minCompare,
    maxCompare: maxCompare,
    presetData: presetData,
    tableHeight: tableHeight,
    displayMode: displayMode,
    profileExist: profileExist,
    profileDialog: profileDialog,
    addMoreDialog: addMoreDialog,
    currentActive: currentActive,
    tableDirection: tableDirection,
    openSortProfile: openSortProfile,
    addMoreDisabled: addMoreDisabled,
    editPresetDialog: editPresetDialog,
    profileUsernames: profileUsernames,
    triggerVibration: triggerVibration,
    savePresetDisabled: savePresetDisabled,
    openFullScreenDialog: openFullScreenDialog,
    setCollapse: setCollapse,
    setDisplayMode: setDisplayMode,
    setPresetDialog: setPresetDialog,
    checkArrayExist: checkArrayExist,
    handleEditPreset: handleEditPreset,
    setAddMoreDialog: setAddMoreDialog,
    setProfileDialog: setProfileDialog,
    setCurrentActive: setCurrentActive,
    handleDeselectAll: handleDeselectAll,
    setTableDirection: setTableDirection,
    setCrudPresetDialog: setCrudPresetDialog,
    setEditPresetDialog: setEditPresetDialog,
    setProfileUsernames: setProfileUsernames,
    setOpenSortProfile: setOpenSortProfile,
    setTriggerVibration: setTriggerVibration,
    setOpenFullScreenDialog: setOpenFullScreenDialog,
  };

  return <ComparisonContext.Provider value={contextValues}>{children}</ComparisonContext.Provider>;
};

export const useComparisonProfileContext = (): IComparisonContext => {
  const context = useContext(ComparisonContext);

  if (!context) {
    throw new Error("useComparisonProfileContext must be used within a ProfileComparisonContextProvider");
  }

  return context;
};
