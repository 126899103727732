/* components */
import TikTokProfileListing from "src/pages/listing/tiktok/tiktok-profile-listing";
import InstaProfileListing from "src/pages/listing/instagram/insta-profile-listing";
import DelaySubmitInput from "src/components/common/inputs/delay-submit-input";
/* 3rd party lib */
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
/* Util */
import Insta from "src/images/insta.png";
import TikTok from "src/images/darkModeTikTok.png";
import { RootState } from "src/store/root-reducer";
import useMediaQueries from "src/hooks/use-mediaqueries";
import ComparisonAvatarGroup from "src/components/comparison/comparison-avatar-group";

interface ProfileDialogProps {
  open: boolean;
  onClose: () => void;
  onSelectProfile: (profile: string) => void;
}

type Props = ProfileDialogProps;

const ProfileDialog: React.FC<Props> = ({ open, onClose, onSelectProfile }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { smDown } = useMediaQueries();
  const [search, setSearch] = useState("");
  const dataTableListRef = useRef<any | null>(null);
  const { socialMedia } = useSelector((state: RootState) => state.general);
  const { profileAvatarGroup } = useSelector((state: RootState) => state.compare);
  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleClose = () => {
    onClose();
    setSearch("");
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    return () => {
      // clear when unmount
      setSearch("");
    };
  }, []);

  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth fullScreen={smDown}>
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="center">
          {socialMedia === "instagram" ? (
            <img src={Insta} alt="insta" width="25px" height="25px" />
          ) : (
            <img src={TikTok} alt="tiktok" width="25px" height="25px" />
          )}

          <Typography variant="inherit" sx={{ flexGrow: 1 }}>
            Select Profile
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ my: 2 }}>
          <Paper sx={{ p: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
              <Box sx={{ width: { lg: "300px" } }}>
                <DelaySubmitInput
                  value={search}
                  variant="outlined"
                  setValue={setSearch}
                  clearInput={search === ""}
                  placeholder={`Filter ${socialMedia} username`}
                />
              </Box>
              <ComparisonAvatarGroup type="profile" avatarGroup={profileAvatarGroup} />
            </Stack>
          </Paper>
          {socialMedia === "instagram" && (
            <InstaProfileListing
              search={search}
              id="profile_dialog"
              showTrackButton={false}
              showGlobalFilter={false}
              columnVisibility={{ action: false }}
              dataTableListRef={dataTableListRef}
              onSelectProfile={profile => {
                onSelectProfile(profile.username);
                handleClose();
              }}
            />
          )}
          {socialMedia === "tiktok" && (
            <TikTokProfileListing
              search={search}
              id="profile_dialog"
              columnVisibility={{ action: false }}
              showTrackButton={false}
              showGlobalFilter={false}
              dataTableListRef={dataTableListRef}
              onSelectProfile={profile => {
                onSelectProfile(profile.username);
                handleClose();
              }}
            />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ProfileDialog;
