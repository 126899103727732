import { Theme } from "@mui/material";
import { FormikProps } from "formik";
import { createContext, useContext } from "react";
import { IPostPresetData } from "src/types/instagram";
import { TDisplayMode } from "src/pages/comparisons/profiles/profiles";
import { ICrudPresetDialog, ICurrentActivePostPreset, IEditPostPresetDialog, TTableDirection } from "src/pages/comparisons/posts/posts";
import { IFormDateFilter, TTriggerVibration } from "src/types/common";
import { TCollapse } from "src/contexts/comparison-profile-context";

// Define the type for context values
export interface IComparisonContext {
  theme: Theme;
  endDate: string;
  startDate: string;
  minCompare: number;
  maxCompare: number;
  addMoreDialog: boolean;
  currentActive: ICurrentActivePostPreset;
  postExists: boolean;
  collapse: TCollapse;
  tableHeight: number;
  postDialog: {
    index: number;
    open: boolean;
  };
  triggerVibration: TTriggerVibration;
  setPostDialog: React.Dispatch<
    React.SetStateAction<{
      index: number;
      open: boolean;
    }>
  >;
  postIds: string[];
  displayMode: TDisplayMode;
  addMoreDisabled: boolean;
  openFullScreenDialog: boolean;
  openSortPost: boolean;
  savePresetDisabled: boolean;
  formik: FormikProps<IFormDateFilter>;
  handleDeselectAll: () => void;
  tableDirection: TTableDirection;
  setCollapse: React.Dispatch<React.SetStateAction<TCollapse>>;
  setAddMoreDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setCrudPresetDialog: React.Dispatch<React.SetStateAction<ICrudPresetDialog>>;
  setDisplayMode: React.Dispatch<React.SetStateAction<TDisplayMode>>;
  setPresetDialog: React.Dispatch<React.SetStateAction<boolean>>;
  presetData: IPostPresetData[] | undefined;
  checkArrayExist: (currArray: string[]) => boolean;
  setCurrentActive: React.Dispatch<React.SetStateAction<ICurrentActivePostPreset>>;
  setEditPresetDialog: React.Dispatch<React.SetStateAction<IEditPostPresetDialog>>;
  setPostIds: React.Dispatch<React.SetStateAction<string[]>>;
  setTriggerVibration: React.Dispatch<React.SetStateAction<TTriggerVibration>>;
  setOpenSortPost: React.Dispatch<React.SetStateAction<boolean>>;
  setTableDirection: React.Dispatch<React.SetStateAction<TTableDirection>>;
  setOpenFullScreenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditPreset: (id: number, title: string, postIds?: string[]) => Promise<void>;
}

export const ComparisonContext = createContext<IComparisonContext | undefined>(undefined);

// Create a provider to wrap components that need access to these values
export const PostComparisonContextProvider: React.FC<
  IComparisonContext & {
    children: React.ReactNode;
  }
> = ({
  theme,
  endDate,
  formik,
  postIds,
  startDate,
  children,
  displayMode,
  minCompare,
  maxCompare,
  presetData,
  tableHeight,
  postDialog,
  collapse,
  postExists,
  addMoreDialog,
  currentActive,
  tableDirection,
  addMoreDisabled,
  savePresetDisabled,
  openSortPost,
  triggerVibration,
  openFullScreenDialog,
  setPostIds,
  setCollapse,
  setAddMoreDialog,
  setCrudPresetDialog,
  setDisplayMode,
  setPresetDialog,
  setPostDialog,
  checkArrayExist,
  setCurrentActive,
  setEditPresetDialog,
  setTableDirection,
  setTriggerVibration,
  setOpenSortPost,
  handleDeselectAll,
  handleEditPreset,
  setOpenFullScreenDialog,
}) => {
  const contextValues: IComparisonContext = {
    theme: theme,
    formik: formik,
    collapse: collapse,
    endDate: endDate,
    postIds: postIds,
    startDate: startDate,
    minCompare: minCompare,
    maxCompare: maxCompare,
    presetData: presetData,
    postDialog: postDialog,
    postExists: postExists,
    tableHeight: tableHeight,
    displayMode: displayMode,
    openSortPost: openSortPost,
    addMoreDialog: addMoreDialog,
    currentActive: currentActive,
    tableDirection: tableDirection,
    addMoreDisabled: addMoreDisabled,
    triggerVibration: triggerVibration,
    savePresetDisabled: savePresetDisabled,
    openFullScreenDialog: openFullScreenDialog,
    setPostIds: setPostIds,
    setCollapse: setCollapse,
    setPostDialog: setPostDialog,
    setDisplayMode: setDisplayMode,
    setPresetDialog: setPresetDialog,
    setOpenSortPost: setOpenSortPost,
    checkArrayExist: checkArrayExist,
    setAddMoreDialog: setAddMoreDialog,
    handleEditPreset: handleEditPreset,
    handleDeselectAll: handleDeselectAll,
    setCurrentActive: setCurrentActive,
    setTableDirection: setTableDirection,
    setCrudPresetDialog: setCrudPresetDialog,
    setEditPresetDialog: setEditPresetDialog,
    setTriggerVibration: setTriggerVibration,
    setOpenFullScreenDialog: setOpenFullScreenDialog,
  };

  return <ComparisonContext.Provider value={contextValues}>{children}</ComparisonContext.Provider>;
};

export const useComparisonPostContext = (): IComparisonContext => {
  const context = useContext(ComparisonContext);

  if (!context) {
    throw new Error("useComparisonPostContext must be used within a PostComparisonContextProvider");
  }

  return context;
};
