import React, { useState } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import { IInstaPostStats } from "src/types/instagram";
import { ITikTokPostStats } from "src/types/tiktok";
import PostHeatmapDrawer from "src/components/profile//posts-heatmap-detail";
import { checkObjectNotEmpty, formatNumberWithSuffix } from "src/utils/general";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  useTheme,
  Stack,
  lighten,
  darken,
  Theme,
  Grid,
  FormControl,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { IFormDateFilter, TSocialMedia } from "src/types/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { faRectanglePro } from "@fortawesome/pro-regular-svg-icons";
import { ProColor } from "src/utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root-reducer";
import useHttp from "src/hooks/use-http";
import routes from "src/routes";
import { useNavigate } from "react-router-dom";
import { FormikProps } from "formik";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface HeatmapProps {
  postStats: IInstaPostStats[] | ITikTokPostStats[];
  socialMedia: TSocialMedia;
  formik: FormikProps<IFormDateFilter>;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  position: "relative",
  cursor: "pointer",
}));

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const times = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0"));

const getColor = (value: number, max: number, theme: Theme) => {
  const intensity = value / max;
  const { mode } = theme.palette;

  // based on intensity change color, inverse for dark mode
  if (intensity <= 0.1) return mode === "light" ? 100 : 900;
  if (intensity <= 0.2) return mode === "light" ? 200 : 800;
  if (intensity <= 0.3) return mode === "light" ? 300 : 700;
  if (intensity <= 0.4) return mode === "light" ? 400 : 600;
  if (intensity <= 0.5) return mode === "light" ? 500 : 500;
  if (intensity <= 0.6) return mode === "light" ? 600 : 400;
  if (intensity <= 0.7) return mode === "light" ? 700 : 300;
  if (intensity <= 0.9) return mode === "light" ? 800 : 200;

  return mode === "light" ? 900 : 100;
};

const TooltipContent: React.FC<{ stat: IInstaPostStats | ITikTokPostStats; metric: string; theme: Theme }> = ({ stat, theme, metric }) => {
  const highlight = "#ffe47a";

  // render the value in respective formats
  const renderMetric = (label: string, value: number | string, metricKey: string) => (
    <Typography variant="body2">
      {label}:&nbsp;&nbsp;
      <b style={{ fontSize: "16px", color: metric === metricKey || label === "Post Count" ? highlight : "white" }}>
        {typeof value === "number" ? formatNumberWithSuffix(value) : value}
      </b>
    </Typography>
  );

  return (
    <Stack spacing={0}>
      {renderMetric("Day", moment(stat.day, "ddd").format("dddd"), "day")}
      {renderMetric("Time", moment(stat.time, "HH").format("HH:mm"), "time")}
      {renderMetric("Post Count", stat.post, "post")}
      {stat.average_play !== undefined && stat.average_play !== null && renderMetric("Avg Play", stat.average_play, "average_play")}
      {renderMetric("Avg Comment", stat.average_comment, "average_comment")}
      {renderMetric("Avg Engagement Rate", `${stat.average_er.toFixed(2)}%`, "average_er")}
      {renderMetric("Avg Like", stat.average_like, "average_like")}
      {"average_share" in stat && renderMetric("Avg Share", stat.average_share, "average_share")}
      {"average_saved" in stat && renderMetric("Avg Saved", stat.average_saved, "average_saved")}
      <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-end" mt={0.5}>
        <FontAwesomeIcon icon={faCircleInfo} />
        <Typography variant="caption">Click to view posts</Typography>
      </Stack>
    </Stack>
  );
};

const PostsHeatmap: React.FC<HeatmapProps> = ({ formik, postStats, socialMedia }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { smDown } = useMediaQueries();
  const { http, apiEndpoint } = useHttp();
  const [openHeatmapDrawer, setOpenHeatmapDrawer] = useState<{ open: boolean; data: IInstaPostStats | ITikTokPostStats | null | undefined }>({
    open: false,
    data: null,
  });
  const { user } = useSelector((state: RootState) => state.auth);
  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const [activeCell, setActiveCell] = useState<string | null>(null);
  const [metric, setMetric] = useState("average_er");

  const getMaxMetric = (metric: string) => Math.max(...postStats.map(stat => (stat as any)[metric]));

  // const handleCellHover = (day: string, time: string, stat: IInstaPostStats | ITikTokPostStats | undefined) => {
  //   if (!stat) return;
  //   setActiveCell(`${day}-${time}`);
  //   setTooltipOpen(true);
  // };

  return (
    <>
      <Paper sx={{ p: 2 }} className="overview-step11">
        <Grid container alignItems="center" sx={{ mt: 1.5, mb: 3 }}>
          <Grid item xs={0} sm={4} />
          <Grid item xs={6} sm={4}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent={smDown ? "flex-start" : "center"}>
              <Tooltip
                arrow
                title={
                  <Stack spacing={2}>
                    <Typography variant="caption" lineHeight={1.3}>
                      The Posting Heatmap helps you identify the best times to post on social media by visualizing engagement levels across different
                      days and hours.
                    </Typography>
                    <Typography variant="caption" lineHeight={1.3}>
                      {theme.palette.mode === "light" ? "Darker cells" : "Lighter cells"} indicate higher engagement, while
                      {theme.palette.mode === "light" ? " lighter cells" : " darker cells"} show lower activity.
                    </Typography>
                    <Typography variant="caption" lineHeight={1.3}>
                      You can switch between metrics like likes, comments, and engagement rate to see how your content performs at different times.
                    </Typography>
                    <Typography variant="caption" lineHeight={1.3}>
                      Hover over any cell for detailed stats, and use these insights to optimize your posting schedule for maximum reach and
                      interaction.
                    </Typography>
                  </Stack>
                }
              >
                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCircleInfo} color={theme.palette.text.secondary} />
              </Tooltip>

              <Typography variant="body1" color="text.secondary" align="center" sx={{ userSelect: "none" }}>
                Best Time for Posting
              </Typography>
              {user && !user.isAdmin && checkObjectNotEmpty(user.subscription) && user.subscription.package.widget_best_time_posting === false && (
                <FontAwesomeIcon icon={faRectanglePro} color={ProColor} size={"lg"} />
              )}
            </Stack>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Stack alignItems="flex-end">
              <FormControl>
                <TextField
                  variant="outlined"
                  hiddenLabel
                  value={metric}
                  onChange={e => setMetric(e.target.value)}
                  select
                  size="small"
                  sx={{
                    width: "110px",
                    "& .MuiNativeSelect-select": { textTransform: "capitalize" },
                  }}
                  SelectProps={{ native: true }}
                >
                  <option value={"average_play"}>Play</option>
                  <option value={"average_like"}>Like</option>
                  <option value={"average_comment"}>Comment</option>
                  {socialMedia === "tiktok" && <option value={"average_share"}>Share</option>}
                  {socialMedia === "tiktok" && <option value={"average_saved"}>Saved</option>}
                  <option value={"average_er"}>Engagement Rate</option>
                </TextField>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {days.map(day => (
                  <TableCell key={day} align="center">
                    {day}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ position: "relative" }}>
              {user && !user.isAdmin && checkObjectNotEmpty(user.subscription) && user.subscription.package.widget_best_time_posting === false && (
                <>
                  <Paper
                    sx={{
                      zIndex: 2,
                      py: 2,
                      px: 4,
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      border: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <Stack spacing={2}>
                      <Typography variant="body1" color="text.secondary">
                        Want to give it a try?
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={async () => {
                          const { data } = await http.get(apiEndpoint.STRIPE_SUBSCRIPTION);
                          if (data.show_portal) {
                            window.location.href = data.customer_portal_url;
                          } else {
                            navigate(`${routes.MY_ACCOUNT}#subscription`);
                          }
                        }}
                      >
                        <Typography variant="body1">Upgrade to Pro</Typography>
                      </Button>
                    </Stack>
                  </Paper>

                  <Box
                    sx={{
                      zIndex: 1,
                      display: "grid",
                      placeItems: "center",
                      position: "absolute",
                      background: theme.palette.mode === "light" ? "#bfbfbf38" : "#2d2d2d38",
                      top: 0,
                      left: 0,
                      ml: "48px",
                      width: "calc(100% - 48px)",
                      height: "100%",
                      backdropFilter: "blur(3px)",
                    }}
                  />
                </>
              )}

              {times.map(time => (
                <TableRow key={time}>
                  <TableCell component="th" scope="row" sx={{ width: "48px", borderRight: `1px solid ${theme.palette.divider}` }}>
                    {time}
                  </TableCell>
                  {days.map(day => {
                    const stat = postStats.find(s => s.day.toLowerCase() === day.toLowerCase() && s.time === time);

                    const backgroundColor = stat
                      ? (theme.palette.mode === "light" ? theme.palette.neutral : (theme.palette as any).darkModeBlue)[
                          getColor((stat as any)[metric], getMaxMetric(metric), theme)
                        ]
                      : "transparent";

                    const hoverColor = stat
                      ? (stat as any)[metric] / getMaxMetric(metric) >= 0.4
                        ? lighten(backgroundColor, 0.1)
                        : darken(backgroundColor, 0.1)
                      : "transparent";

                    return (
                      <Tooltip
                        arrow
                        key={`${day}-${time}`}
                        title={stat ? <TooltipContent theme={theme} metric={metric} stat={stat} /> : ""}
                        // open={tooltipOpen && activeCell === `${day}-${time}`}
                        // onClose={() => setTooltipOpen(false)}
                        disableFocusListener
                        disableTouchListener
                      >
                        <StyledTableCell
                          onClick={() => stat && setOpenHeatmapDrawer({ open: true, data: stat })}
                          // onMouseEnter={() => handleCellHover(day, time, stat)}
                          sx={{
                            width: "calc(100% / 7)",
                            borderRight: `1px solid ${theme.palette.divider}`,
                            transition: "all 0.3s ease",
                            "&:hover": { backgroundColor: hoverColor },
                            backgroundColor,
                          }}
                        >
                          {stat && (
                            <Typography
                              variant="body2"
                              color={
                                theme.palette.mode === "light"
                                  ? (stat as any)[metric] / getMaxMetric(metric) >= 0.4
                                    ? "white"
                                    : "#4D4D4F"
                                  : (stat as any)[metric] / getMaxMetric(metric) >= 0.4
                                  ? "#4D4D4F"
                                  : "white"
                              }
                            >
                              {stat.post}
                              &nbsp;&nbsp;|&nbsp;
                              {metric === "average_er" ? `${(stat as any)[metric].toFixed(2)}%` : formatNumberWithSuffix((stat as any)[metric])}
                            </Typography>
                          )}
                        </StyledTableCell>
                      </Tooltip>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <PostHeatmapDrawer
        formik={formik}
        socialMedia={socialMedia}
        open={openHeatmapDrawer.open}
        data={openHeatmapDrawer.data}
        onClose={() => setOpenHeatmapDrawer({ open: false, data: null })}
      />
    </>
  );
};

export default PostsHeatmap;
