/* components */
/* 3rd party lib */
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { Stack, Dialog, DialogTitle, IconButton, Typography, DialogContent, Button, DialogActions, Box, useTheme, Paper } from "@mui/material";
/* Util */
import { ICompareTikTokProfileJsonData } from "src/types/tiktok";
import { ICompareInstaProfileJsonData } from "src/types/instagram";
import { useStrictDroppable } from "src/hooks/use-strict-droppable";
import { useComparisonProfileContext } from "src/contexts/comparison-profile-context";
import { rainbowBarColors } from "src/components/common/data-display/charts/bar-chart";

interface ProfileComparisonSortDndProps {
  open: boolean;
  onClose: () => void;
  profileData?: ICompareInstaProfileJsonData | ICompareTikTokProfileJsonData | undefined;
}

type Props = ProfileComparisonSortDndProps;

const ProfileComparisonSortDnd: React.FC<Props> = ({ onClose, open, profileData }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const { mode } = theme.palette;
  const { currentActive, profileUsernames, setProfileUsernames, handleEditPreset } = useComparisonProfileContext();
  const [enabled] = useStrictDroppable(!!!profileUsernames);
  const size = profileUsernames.length > 4 ? "100px" : "120px";

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !profileUsernames) return;

    const items = Array.from(profileUsernames);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProfileUsernames(items);
    if (currentActive.preset) {
      handleEditPreset(currentActive.preset.id, currentActive.preset.title, items);
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog open={open} onClose={onClose} maxWidth={profileUsernames.length > 6 ? "lg" : "md"} fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="inherit">Sort Profiles</Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Paper sx={{ mt: 2 }} elevation={10}>
          <DragDropContext onDragEnd={handleDragEnd}>
            {enabled && (
              <Droppable droppableId="horizontal-table" direction="horizontal">
                {provided => (
                  <Stack
                    alignItems="center"
                    justifyContent={profileUsernames.length > 4 ? "flex-start" : "center"}
                    direction="row"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    spacing={2}
                    sx={{ p: 2, overflow: "auto", ...(theme as any).custom.scroll }}
                  >
                    {profileUsernames
                      .filter(child => child !== "")
                      .map((username, index) => {
                        return (
                          <Draggable key={`row${username}`} draggableId={`row${username}`} index={index}>
                            {(provided, snapshot) => (
                              <>
                                {profileData && (
                                  <Stack
                                    alignItems="center"
                                    justifyContent={"center"}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      position: "relative",
                                      border: `1px solid ${mode === "dark" ? "white" : (theme.palette as any).neutral[300]}`,
                                      minWidth: profileUsernames.length > 4 ? "140px" : "180px",
                                      maxWidth: profileUsernames.length > 4 ? "140px" : "180px",
                                      borderRadius: "8px",
                                      p: 2,
                                      cursor: "grab",
                                      "&:hover": {
                                        background: (theme.palette as any).neutral[mode === "dark" ? 900 : 200],
                                      },
                                      background: snapshot.isDragging
                                        ? mode === "dark"
                                          ? "#000000"
                                          : (theme.palette as any).neutral[300]
                                        : (theme.palette as any).neutral[mode === "dark" ? 800 : 100],
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "grid",
                                        placeItems: "center",
                                        position: "absolute",
                                        top: "4px",
                                        left: "8px",
                                      }}
                                    >
                                      <Typography color="text.secondary">#{index + 1}</Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        border: "1px solid #dcdcdc",
                                        width: size,
                                        height: size,
                                      }}
                                    >
                                      <img
                                        alt={profileData.results[username].profile.downloaded_image}
                                        src={profileData.results[username].profile.downloaded_image}
                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                      />
                                    </Box>

                                    <Stack alignItems="center" sx={{ width: "100%" }}>
                                      <Typography
                                        variant={profileUsernames.length > 8 ? "body2" : "body1"}
                                        sx={{
                                          userSelect: "none",
                                          textAlign: "center",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          width: "100%",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "inline-block",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            background: rainbowBarColors[index % rainbowBarColors.length],
                                          }}
                                        />
                                        &nbsp;
                                        {profileData.results[username].profile.display_name}
                                      </Typography>
                                      <Typography variant={"caption"}>@{profileData.results[username].profile.username}</Typography>
                                    </Stack>
                                  </Stack>
                                )}
                              </>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            )}
          </DragDropContext>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ width: "100%" }}>
          <Button variant="contained" color="primary" size="small" onClick={() => onClose()}>
            Done
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileComparisonSortDnd;
