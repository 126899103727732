import "./App.css";
// import { useTranslation } from "react-i18next";
import routes from "src/routes";
import { useEffect } from "react";
import { Global } from "@emotion/react";
import { useCreateTheme } from "src/theme";
import { logout } from "src/slices/auth";
import { Toaster } from "react-hot-toast";
import useVersion from "src/hooks/use-version";
import { RootState } from "src/store/root-reducer";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, useTheme } from "@mui/material";
import { Routes, Navigate, Route, useLocation, Outlet } from "react-router-dom";

/* Pages */
import Login from "src/pages/login";
import Verify from "src/pages/verify";
import SignUp from "src/pages/sign-up";
import InfoFaq from "src/pages/info/faq";
import InfoTerms from "src/pages/info/terms";
import InfoAbout from "src/pages/info/about";
import Favourites from "src/pages/favourites";
import PricingPage from "src/pages/info/pricing";
import InfoPrivacy from "src/pages/info/privacy";
import MyAccountPage from "src/pages/my-account";
import ConfirmEmail from "src/pages/confirm-email";
import ListingPage from "src/pages/listing/index";
// import PaymentPage from "src/pages/payment/payment";
import ResetPassword from "src/pages/reset-password";
import SearchPage from "src/pages/profile/search-page";
import ForgotPassword from "src/pages/forgot-password";
import SuperadminPage from "src/pages/admin/superadmin";
import TikTokProfile from "src/pages/profile/tiktok/index";
import SuperadminUser from "src/pages/admin/superadmin-user";
import TikTokHashtag from "src/pages/profile/tiktok/hashtag";
import InstaHashtag from "src/pages/profile/instagram/hashtag";
import InstagramProfile from "src/pages/profile/instagram/index";
import ComparisonByPosts from "src/pages/comparisons/posts/posts";
import TikTokPostHistory from "src/pages/profile/tiktok/post-history";
import InstaPostHistory from "src/pages/profile/instagram/post-history";
import ComparisonByProfiles from "src/pages/comparisons/profiles/profiles";

const semver = require("semver");

function App(props: any) {
  const theme = useTheme();
  const version = useVersion();
  const location = useLocation();
  const { theme: mode } = useSelector((state: RootState) => state.general);

  const toastOptions = {
    gutter: 16,
    style: {
      fontSize: 14,
      fontWeight: 600,
      background: mode === "light" ? "#ffffff" : "#1F2937",
      color: mode === "light" ? theme.palette.text.secondary : "#ffffff",
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (version) {
      const localStorageVersion = localStorage.getItem("version");

      // Compare the app version with the required version using semver
      if (version && localStorageVersion && semver.lt(localStorageVersion, "0.1.13")) {
        // The current version is older than the required version
        // Remove the tableConfig from localStorage
        localStorage.removeItem("tableConfig");
        localStorage.removeItem("theme");
      }

      localStorage.setItem("version", version);
    }
  }, [version]);

  return (
    <ThemeProvider theme={useCreateTheme({ mode: mode })}>
      <Global
        styles={{
          body: {
            background: mode === "dark" ? "#1F2937" : "#fafafa",
          },
          ".center": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      />
      <Toaster toastOptions={toastOptions} />
      <div className="App">
        <Routes>
          <Route path={routes.LOGIN} element={<Login />} />
          <Route path={routes.SIGNUP} element={<SignUp />} />
          <Route path={routes.VERIFY} element={<Verify />} />
          <Route path={routes.CONFIRM_EMAIL} element={<ConfirmEmail />} />
          <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={routes.INFO_FAQ} element={<InfoFaq />} />
          <Route path={routes.INFO_TERMS} element={<InfoTerms />} />
          <Route path={routes.INFO_ABOUT} element={<InfoAbout />} />
          <Route path={routes.INFO_PRIVACY} element={<InfoPrivacy />} />
          <Route path={routes.PRICING} element={<PricingPage />} />

          <Route element={<ProtectedRoute />}>
            <Route path={routes.ROOT} element={<ListingPage />} />
            <Route path={routes.LISTING} element={<ListingPage />} />
            <Route path={routes.SUPERADMIN} element={<SuperadminPage />} />
            <Route path={`${routes.SUPERADMIN_USERS}/:user_id`} element={<SuperadminUser />} />
            <Route path={routes.MY_ACCOUNT} element={<MyAccountPage />} />
            {/* <Route path={routes.PAYMENT} element={<PaymentPage />} /> */}
            <Route path={routes.SEARCH} element={<SearchPage />} />
            <Route path={routes.FAVOURITES} element={<Favourites />} />
            <Route path={`${routes.COMPARISON_POSTS}`} element={<ComparisonByPosts />} />
            <Route path={`${routes.COMPARISON_PROFILES}`} element={<ComparisonByProfiles />} />
            {/* Instagram */}
            <Route path={`${routes.INSTA_PROFILE}/:username`} element={<InstagramProfile />} />
            <Route path={`${routes.INSTA_PROFILE}/:username/hashtags/:hashtag`} element={<InstaHashtag />} />
            <Route path={`${routes.INSTA_PROFILE}/:username/:post_id`} element={<InstaPostHistory />} />
            {/* Tik Tok */}
            <Route path={`${routes.TIKTOK_PROFILE}/:username`} element={<TikTokProfile />} />
            <Route path={`${routes.TIKTOK_PROFILE}/:username/hashtags/:hashtag`} element={<TikTokHashtag />} />
            <Route path={`${routes.TIKTOK_PROFILE}/:username/:post_id`} element={<TikTokPostHistory />} />
          </Route>

          <Route path={"*"} element={<Navigate to={routes.ROOT} replace />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;

export const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(logout());
    }
  }, [dispatch, isAuthenticated]);
  return <>{isAuthenticated ? <Outlet /> : <Navigate to={routes.LOGIN} />}</>;
};
