import { useState } from "react";
import { Box, FormControl, Paper, Stack, TextField } from "@mui/material";
import ProfilePostGrid from "src/components/profile/instagram/profile-post-grid";
import DelaySubmitInput from "src/components/common/inputs/delay-submit-input";
import { INSTA_METRICS } from "src/components/comparison/profiles/instagram/profile-top-posts";
import { FormikProps } from "formik";
import { IFormDateFilter } from "src/types/common";
import DateFilter from "src/components/common/inputs/date-filter";
import ArrowOverflowBox from "src/components/common/layout/arrow-overflow-box";

interface ProfilePostsProps {
  selection?: boolean;
  username: string | undefined;
  handleClose?: () => void;
  formik: FormikProps<IFormDateFilter>;
  onSelectPost?: (postId: string) => void;
}

type Props = ProfilePostsProps;

const ProfilePosts: React.FC<Props> = ({ username, formik }) => {
  const [search, setSearch] = useState("");
  const [metric, setMetric] = useState(INSTA_METRICS.count_play);

  return (
    <>
      <Paper sx={{ p: 1, mb: 1 }}>
        <ArrowOverflowBox>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Box>
              <DelaySubmitInput
                value={search}
                styles={{ "& .MuiOutlinedInput-root": { height: "37px !important", minWidth: "160px" } }}
                variant="outlined"
                setValue={setSearch}
                clearInput={search === ""}
                placeholder={"Search posts"}
              />
            </Box>
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
              <DateFilter formik={formik} />

              <FormControl>
                <TextField
                  variant="outlined"
                  hiddenLabel
                  value={metric}
                  onChange={e => {
                    setMetric(e.target.value);
                  }}
                  select
                  size="small"
                  sx={{
                    width: "110px",
                    "& .MuiNativeSelect-select": {
                      textTransform: "capitalize",
                    },
                  }}
                  SelectProps={{ native: true }}
                >
                  <option value={INSTA_METRICS.count_play}>Play</option>
                  <option value={INSTA_METRICS.count_like}>Like</option>
                  <option value={INSTA_METRICS.count_comment}>Comment</option>
                  <option value={INSTA_METRICS.date_posted}>Date Posted</option>
                  <option value={INSTA_METRICS.engagement_rate}>Engagement Rate</option>
                </TextField>
              </FormControl>
            </Stack>
          </Stack>
        </ArrowOverflowBox>
      </Paper>
      <ProfilePostGrid formik={formik} metric={metric} username={username} search={search} />
    </>
  );
};
export default ProfilePosts;
