import React, { useRef, useState } from "react";
/* components */
import BarChart, { rainbowBarColors, TImageDict } from "src/components/common/data-display/charts/bar-chart";
import MultiLineChart from "src/components/common/data-display/charts/multi-line-chart";
/* 3rd party lib */
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

/* Util */
import useMediaQueries from "src/hooks/use-mediaqueries";
import { ILineChartTikTokPostData } from "src/types/tiktok";
import { IBarTikTokPostData } from "src/pages/comparisons/posts/tiktok";
import { ChartData, ILineChartInstaPostData } from "src/types/instagram";
import { TBarChartData } from "src/components/comparison/profiles/profile-chart-title";
import { IBarInstaPostData } from "src/pages/comparisons/posts/instagram";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-solid-svg-icons";
import { scroll } from "src/theme/dark-theme-options";

interface PostChartTitleProps {
  title: string;
  symbol?: string;
  imageDict: TImageDict | null;
  icon?: React.ReactNode;
  targetKey: keyof ILineChartInstaPostData | keyof ILineChartTikTokPostData;
  chartHeight: string;
  lineChartData: ILineChartInstaPostData | ILineChartTikTokPostData | null;
  chartType: "line" | "bar";
  barChartData: IBarTikTokPostData | IBarInstaPostData | undefined;
  setChartType: React.Dispatch<React.SetStateAction<"line" | "bar">>;
}

type Props = PostChartTitleProps;

const PostChartTitle: React.FC<Props> = ({
  icon,
  title,
  symbol,
  targetKey,
  imageDict,
  chartHeight,
  lineChartData,
  barChartData,
  chartType,
  setChartType,
}) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { smDown, mdDown } = useMediaQueries();
  const postImageRef = useRef<HTMLDivElement | null>(null);

  const [expandDialog, setExpandDialog] = useState(false);
  // const [trigger, setTrigger] = useState(false);
  // const [hasOverflow, setHasOverflow] = useState(false);
  const gridStyle = {
    containLabel: true,
    top: "40px",
    bottom: "20px",
    left: mdDown ? "20px" : "40px",
    right: mdDown ? "20px" : "40px",
  };

  // const [chartMode, setChartMode] = useState<"linear" | "log">("linear");
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  // useEffect(() => {
  //   if (expandDialog && trigger && postImageRef.current) {
  //     const hasOverflow = postImageRef.current.scrollWidth > postImageRef.current.clientWidth;

  //     if (hasOverflow) {
  //       setHasOverflow(true);
  //     } else {
  //       setHasOverflow(false);
  //     }
  //   } else {
  //     setTrigger(false);
  //   }
  // }, [expandDialog, trigger]);
  /* ================================================== */
  /* ================================================== */

  return (
    <>
      <Paper elevation={5} sx={{ py: 3, width: "100%" }}>
        <Box sx={{ height: chartHeight }}>
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} sx={{ pr: mdDown ? 2 : 4 }}>
            <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: mdDown ? 2 : 5 }}>
              {title}
              {icon ? <>&nbsp;&nbsp;{icon}</> : null}
            </Typography>

            {/* <Box>
            <ToggleButtonGroup
              color="primary"
              value={chartMode}
              exclusive
              sx={{ height: "34px" }}
              onChange={(_e, value) => {
                if (value) {
                  setChartMode(value);
                }
              }}
              aria-label="Platform"
            >
              <ToggleButton value="linear">
                <Typography variant="caption" textTransform={"capitalize"}>
                  Normal
                </Typography>
              </ToggleButton>
              <ToggleButton value="log">
                <Typography variant="caption" textTransform={"capitalize"}>
                  Amplified
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box> */}
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <Box>
                <ToggleButtonGroup
                  color="primary"
                  value={chartType}
                  exclusive
                  sx={{ height: "34px" }}
                  onChange={(_e, value) => {
                    if (value) {
                      setChartType(value);
                    }
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton value="bar">
                    <Typography variant="caption" textTransform={"capitalize"}>
                      Bar
                    </Typography>
                  </ToggleButton>
                  <ToggleButton value="line">
                    <Typography variant="caption" textTransform={"capitalize"}>
                      Line
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box> */}
              <IconButton
                size="small"
                sx={{ p: 1 }}
                onClick={() => {
                  setExpandDialog(true);
                  // setTrigger(true);
                }}
              >
                <FontAwesomeIcon icon={faExpand} />
              </IconButton>
            </Stack>
          </Stack>

          <Box sx={{ height: "100%" }}>
            {chartType === "bar" ? (
              <>
                {barChartData && (
                  <BarChart
                    sorted
                    toolbox
                    horizontal
                    symbol={symbol}
                    imageDict={imageDict}
                    gridStyle={gridStyle}
                    yAxisImageShape="box"
                    xAxisData={barChartData.xAxis}
                    yAxisData={(barChartData as TBarChartData)[targetKey]}
                  />
                )}
              </>
            ) : (
              <>
                {lineChartData && (
                  <MultiLineChart toolbox xAxisData={lineChartData.xAxis} yAxisArrayOfArray={lineChartData[targetKey] as ChartData[]} />
                )}
              </>
            )}
          </Box>
        </Box>
      </Paper>
      <Dialog open={expandDialog} onClose={() => setExpandDialog(false)} maxWidth="md" fullWidth fullScreen={smDown}>
        <DialogTitle>
          <Stack direction="row" spacing={3}>
            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              Full View
            </Typography>
            <IconButton onClick={() => setExpandDialog(false)}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Paper sx={{ height: "80vh", mt: 2 }}>
            <Stack sx={{ height: "100%", width: "100%" }} spacing={2}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={barChartData && barChartData.xAxis.length > 6 ? "flex-start" : "center"}
                spacing={1}
                ref={postImageRef}
                sx={{ mt: 2, mx: 2, overflowX: "auto", overflowY: "hidden", ...scroll, height: "160px" }}
              >
                {barChartData &&
                  barChartData.images.map((child, index) => (
                    <Stack p={1} key={child} alignItems="center" justifyContent={"center"}>
                      <img src={child} alt={child} width="80px" height="100px" style={{ objectFit: "cover" }} />
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box
                          sx={{ height: "8px", width: "8px", borderRadius: "50%", background: rainbowBarColors[index % rainbowBarColors.length] }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          Post {index + 1}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
              </Stack>

              <Box sx={{ px: 3, alignSelf: "flex-end" }}>
                <ToggleButtonGroup
                  color="primary"
                  value={chartType}
                  exclusive
                  sx={{ height: "34px" }}
                  onChange={(_e, value) => {
                    if (value) {
                      setChartType(value);
                    }
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton value="bar">
                    <Typography variant="caption" textTransform={"capitalize"}>
                      Bar
                    </Typography>
                  </ToggleButton>
                  <ToggleButton value="line">
                    <Typography variant="caption" textTransform={"capitalize"}>
                      Line
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                {chartType === "bar" ? (
                  <>
                    {barChartData && (
                      <BarChart
                        toolbox
                        sorted
                        horizontal
                        imageDict={imageDict}
                        gridStyle={gridStyle}
                        yAxisImageShape="box"
                        xAxisData={barChartData.xAxis}
                        yAxisData={(barChartData as TBarChartData)[targetKey]}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {lineChartData && (
                      <MultiLineChart toolbox xAxisData={lineChartData.xAxis} yAxisArrayOfArray={lineChartData[targetKey] as ChartData[]} />
                    )}
                  </>
                )}
              </Box>
            </Stack>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PostChartTitle;
