import React from "react";
/* components */
/* 3rd party lib */
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton, Stack, Typography } from "@mui/material";

/* Util */
interface CustomPaginationProps {
  page: number;
  count: number;
  onChange: (value: number) => void;
}

type Props = CustomPaginationProps;

const CustomPagination: React.FC<Props> = ({ page, count, onChange }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Stack direction="row" alignItems="center">
      <IconButton disabled={page === 1} onClick={() => onChange(page - 1)} aria-label="previous page">
        <ChevronLeftIcon />
      </IconButton>
      <Typography variant="caption" color="text.secondary">
        {page} / {count}
      </Typography>

      <IconButton disabled={page === count} onClick={() => onChange(page + 1)} aria-label="next page">
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
};

export default CustomPagination;
