import { TTikTokProfilePost } from "src/types/tiktok";
import { Stack } from "@mui/material";
import TikTokPostGrid from "src/pages/listing/tiktok/tiktok-post-grid";
import TikTokProfileBanner from "src/components/profile/tiktok/profile-banner";

interface TikTokPostListingProps {
  search?: string;
  selection?: boolean;
  multiSelect?: boolean;
  username: string | undefined;
  handleClose?: () => void;
  onSelectPost?: (postId: string) => void;
}

type Props = TikTokPostListingProps;

const TikTokPostListing: React.FC<Props> = ({ search, username, selection, multiSelect = false, handleClose, onSelectPost }) => {
  const handleSelect = (rowData: TTikTokProfilePost) => {
    if (onSelectPost && handleClose) {
      onSelectPost(rowData.post_id);
      handleClose();
    }
  };

  return (
    <Stack spacing={1}>
      <TikTokProfileBanner username={username} viewOnly />
      <TikTokPostGrid
        params={{ ordering: "-date_posted" }}
        multiSelect={multiSelect}
        inDialog
        search={search}
        username={username}
        onSelectPost={handleSelect}
      />
    </Stack>
  );
};
export default TikTokPostListing;
