/* components */
import { ICreatePlanDialog, IEditDialog } from "src/pages/admin/subscription-plan-table";
/* 3rd party lib */
import React from "react";
// import moment from "moment";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAnglesDown, faAnglesRight } from "@fortawesome/pro-solid-svg-icons";
import { Grid, Stack, Typography /* useTheme */ } from "@mui/material";
/* Util */
import { TSubscriptionPlan } from "src/types/common";
// import useMediaQueries from "src/hooks/use-mediaqueries";
import PackageComponent from "src/components/common/data-display/package-component";
// import { nullUndefinedReturnDash } from "src/utils/general";
import { SUBSCRIPION_KEYS } from "src/utils/constants";

interface PlanChangeProps {
  user_id?: string;
  customPaperStyle: any;
  currentPlanData?: TSubscriptionPlan;
  currentPlanError?: Error;
  nextPlanData?: TSubscriptionPlan;
  nextPlanError?: Error;
  setShowSubscriptionDialog: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      log: string | null;
    }>
  >;
  setShowResetDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateDialogOpen: React.Dispatch<React.SetStateAction<ICreatePlanDialog>>;
  setEditDialogOpen: React.Dispatch<React.SetStateAction<IEditDialog>>;
}

type Props = PlanChangeProps;

const PlanChange: React.FC<Props> = ({
  customPaperStyle,
  currentPlanData,
  currentPlanError,
  nextPlanData,
  nextPlanError,
  setEditDialogOpen,
  setShowResetDialog,
  setCreateDialogOpen,
  setShowSubscriptionDialog,
}) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  // const theme = useTheme();
  // const { mdDown } = useMediaQueries();
  const currentPlanExist = currentPlanData && currentPlanData.account !== null;
  // const currentPlanExpired = currentPlanData && currentPlanData.subscription_status === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS;

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Grid
      container
      sx={{ "*": { boxSizing: "border-box" }, width: "100%" }}
      alignItems="stretch"
      justifyContent={currentPlanExist ? "center" : "flex-start"}
    >
      {currentPlanExist && (
        <>
          <Grid item xs={12} md={5.25}>
            <Stack spacing={1} alignItems="flex-start" width="100%">
              <Typography
                variant="body1"
                fontWeight={600}
                color={currentPlanData?.subscription_status === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS ? "error.main" : "primary.main"}
              >
                Current Plan
              </Typography>

              <PackageComponent
                data={currentPlanData}
                error={currentPlanError}
                nextPlanData={nextPlanData}
                currentPlanExist={currentPlanExist}
                customPaperStyle={customPaperStyle}
                setEditDialogOpen={setEditDialogOpen}
                setShowResetDialog={setShowResetDialog}
                setCreateDialogOpen={setCreateDialogOpen}
                setShowSubscriptionDialog={setShowSubscriptionDialog}
              />
            </Stack>
          </Grid>
          {/* <Grid item xs={12} md={1.5} sx={{ display: "grid", placeItems: "center" }}>
            <Stack sx={{ py: 2 }}>
              <FontAwesomeIcon color={theme.palette.primary.main} size="3x" icon={mdDown ? faAnglesDown : faAnglesRight} />
              <Typography variant="body2">({nullUndefinedReturnDash(moment(currentPlanData?.end_time).diff(moment(), "days"))} Days Left)</Typography>
            </Stack>
          </Grid> */}
        </>
      )}
      {/* <Grid item xs={12} md={5.25}>
        <Stack spacing={1} alignItems="flex-start" width="100%" height="100%">
          <Typography variant="body1" fontWeight={600} color="warning.main">
            {currentPlanExist && !currentPlanExpired ? "Next Plan" : "Create Plan"}
          </Typography>

          <PackageComponent
            nextPlan
            data={nextPlanData}
            error={nextPlanError}
            currentPlanExist={currentPlanExist}
            customPaperStyle={customPaperStyle}
            setEditDialogOpen={setEditDialogOpen}
            currentPlanExpired={currentPlanExpired}
            setShowResetDialog={setShowResetDialog}
            setCreateDialogOpen={setCreateDialogOpen}
            setShowSubscriptionDialog={setShowSubscriptionDialog}
          />
        </Stack>
      </Grid> */}
    </Grid>
  );
};

export default PlanChange;
