import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
/* components */
/* 3rd party lib */
import ReactEcharts from "echarts-for-react";
import NoDataFound from "src/components/common/feedback/no-data-found";
import SpinnerComponent from "src/components/common/feedback/spinner";
import { Box, Tooltip, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { Global } from "@emotion/react";
import { formatNumberWithSuffix } from "src/utils/general";
import useMediaQueries from "src/hooks/use-mediaqueries";
/* Util */

export const rainbowBarColors = ["#9ee3f4", "#93c1ef", "#8ce8ba", "#a6e89a", "#f3f498", "#ebca9d", "#f99595", "#f7a8e8", "#d096f2", "#b4aaef"];

export type TImageDict = Record<string, { oriStr: string; image: string; username: string }>;

interface BarChartProps {
  dataZoom?: boolean;
  toolbox?: boolean;
  upperLimit?: number;
  chartMode?: "linear" | "log";
  horizontal?: boolean;
  xAxisData: string[];
  imageDict?: TImageDict | null;
  animation?: boolean;
  sorted?: boolean;
  tooltip?: boolean;
  barColors?: string[];
  onClick?: (e: any) => void;
  currentActiveIndex?: number;
  yAxisData: (number | null)[];
  gridStyle?: { [key: string]: any };
  yAxisImageShape?: "circle" | "box";
  symbol?: string;
}

type Props = BarChartProps;

const BarChart: React.FC<Props> = React.memo(function BarChart({
  symbol,
  imageDict,
  xAxisData,
  yAxisData,
  gridStyle,
  onClick,
  chartMode,
  upperLimit = 10,
  sorted = false,
  tooltip = false,
  toolbox = false,
  dataZoom = false,
  animation = true,
  horizontal = false,
  currentActiveIndex,
  yAxisImageShape = "circle",
  barColors = rainbowBarColors,
}) {
  const [sortedBarColors, setSortedBarColors] = useState<string[]>([]);
  const [sortedXAxisData, setSortedXAxisData] = useState<string[]>([]);
  const [sortedYAxisData, setSortedYAxisData] = useState<(number | null)[]>([]);

  const noData = (yAxisData: (number | null)[]) => {
    return yAxisData.every((element: number | null) => element === null);
  };

  /* ================================================== */
  /*  state */
  /* ================================================== */
  const chartRef = useRef<any | null>(null);
  const itemsInViewRef = useRef(0);
  const theme = useTheme();
  const { mdDown } = useMediaQueries();
  const [xAxisObj, setXAxisObj] = useState<Record<string, string> | null>(null);

  const { mode } = theme.palette;

  // Calculate min and max dynamically as a percentage of the data range

  const xAxis = useMemo(() => {
    return {
      type: "category",
      data: sortedXAxisData,
      axisLabel: {
        show: true,
        rotate: imageDict && Object.keys(imageDict).length > 0 ? 0 : 45,
        formatter: function (value: any, index: number) {
          if (imageDict && Object.keys(imageDict).length > 0) {
            return `{${value.replace(/\./g, "_")}|}`;
          }

          if (itemsInViewRef.current > 24) {
            // Display only every second label
            return index % 2 === 0 ? value : "";
          }

          return value;
        },
        // interval: 0, // Display all labels
        color: mode === "light" ? "rgb(110, 112, 121)" : "white", // Change the font color for the xAxis labels
        rich: {
          ...xAxisObj,
        },
      },
    };
  }, [imageDict, mode, sortedXAxisData, xAxisObj]);

  const yAxis = useMemo(() => {
    return {
      show: false,
      type: "value",
      splitLine: {
        show: false,
      },
      axisLabel: {
        formatter: function (value: any) {
          return "{" + value + "| }\n{value|" + value + "}";
        },
        color: mode === "light" ? "rgb(110, 112, 121)" : "white", // Change the font color for the xAxis labels
      },
    };
  }, [mode]);

  const options = useMemo(() => {
    let horizontalStyle = horizontal
      ? {
          left: horizontal ? (mdDown ? 20 : 40) : 0,
          right: horizontal ? 60 : 0,
          top: horizontal ? 20 : 0,
        }
      : {};

    return {
      grid: {
        ...gridStyle,
        bottom: dataZoom ? "110px" : horizontal ? (toolbox ? 8 : 0) : 20,
        ...horizontalStyle,
      },
      xAxis: horizontal ? yAxis : xAxis,
      yAxis: horizontal ? xAxis : yAxis,
      series: [
        {
          data: sortedYAxisData.map((child: any) => (horizontal && chartMode === "log" ? Math.log(child) : child)),
          animation: animation,
          type: "bar",
          itemStyle: {
            color: function (params: any) {
              if (currentActiveIndex === params.dataIndex) {
                return "#96faad";
              }
              return sortedBarColors[params.dataIndex % sortedBarColors.length]; // Change the line color to blue
            },
          },
          label: {
            show: true,
            position: horizontal ? "right" : "top",
            color: theme.palette.text.secondary,
            formatter: function (params: any) {
              // Check if the data value is not 0
              if (params.value !== 0) {
                return `${formatNumberWithSuffix(horizontal ? sortedYAxisData[params.dataIndex] : params.value)}${symbol ? symbol.toString() : ""}`;
              } else {
                return ""; // Empty string to hide the label
              }
            },
          },
        },
      ],
      toolbox: {
        show: toolbox,
        feature: {
          // saveAsImage: {
          //   title: "Save",
          //   // backgroundColor: "rgba(0, 0, 0) !important", // Custom background color
          //   // color: "rgba(0, 0, 0)", // Custom background color
          // },
        },
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params: any) {
          // Customize the tooltip content based on your needs
          const dataIndex = params[0].dataIndex;

          const value = sortedYAxisData[dataIndex];
          const name = (imageDict && imageDict[sortedXAxisData[dataIndex]] && imageDict[sortedXAxisData[dataIndex]].oriStr) ?? "";
          const username =
            (imageDict && imageDict[sortedXAxisData[dataIndex]] && imageDict[sortedXAxisData[dataIndex]].username) ?? sortedXAxisData[dataIndex];
          const color = sortedBarColors[dataIndex % sortedBarColors.length]; // Change the line color to blue
          const dot = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:${color};"></span>`;

          return `${dot}${name === "" ? "" : ` ${name}`} ${username !== "" ? `@${username}` : ""} <br/>${value?.toLocaleString()}`;
        },
      },
      dataZoom: dataZoom
        ? [
            {
              type: "inside",
              start: sortedXAxisData.length > 24 ? 80 : 0, // Initial start position (0%)
              end: sortedXAxisData.length > 24 ? 100 : 100, // Initial end position (20%)
            },
            {
              type: "slider",
              start: sortedXAxisData.length > 24 ? 80 : 0, // Initial start position (0%)
              end: sortedXAxisData.length > 24 ? 100 : 100, // Initial end position (20%)
            },
          ]
        : [],
    };
  }, [
    symbol,
    mdDown,
    yAxis,
    xAxis,
    theme,
    toolbox,
    imageDict,
    chartMode,
    dataZoom,
    horizontal,
    animation,
    gridStyle,
    sortedBarColors,
    sortedXAxisData,
    sortedYAxisData,
    currentActiveIndex,
  ]);
  /* ================================================== */
  /*  method */
  /* ================================================== */

  const onChartClick = (e: any) => {
    if (onClick) {
      onClick(e);
    }
  };

  const handleDataZoomChange = useCallback(
    (_e: any) => {
      if (chartRef.current) {
        const dataZoomComponent = chartRef.current.getEchartsInstance().getOption().dataZoom[0];
        // get the start and end percentage
        const dataZoomStart = dataZoomComponent.start;
        const dataZoomEnd = dataZoomComponent.end;

        const totalItems = sortedXAxisData.length;
        const itemsInView = Math.floor(((dataZoomEnd - dataZoomStart) * totalItems) / 100);

        // update the ref, if useState, it will trigger rerender for some reason
        itemsInViewRef.current = itemsInView;
      }
    },
    [sortedXAxisData]
  );

  const onEvents = {
    click: onChartClick,
    dataZoom: handleDataZoomChange,
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    const indices = Array.from({ length: yAxisData.length }, (_, index) => {
      return index;
    });
    // Sort the indices based on the values in yAxisData in descending order

    const sortedIndices = (indices as number[]).sort((a, b) => {
      const aValue = yAxisData[a] || 0; // Use 0 as default if value is null
      const bValue = yAxisData[b] || 0; // Use 0 as default if value is null
      return aValue - bValue;
    });

    let sliceStartPosition = 0;
    if (yAxisData.length > upperLimit) {
      sliceStartPosition = yAxisData.length - upperLimit;
    }

    // Use the sorted indices to rearrange both xAxisData and yAxisData
    if (sorted) {
      setSortedXAxisData(sortedIndices.slice(sliceStartPosition, yAxisData.length).map(index => xAxisData[index]));
      setSortedYAxisData(sortedIndices.slice(sliceStartPosition, yAxisData.length).map(index => yAxisData[index]));
      setSortedBarColors(sortedIndices.slice(sliceStartPosition, yAxisData.length).map(index => barColors[index % barColors.length]));
    } else {
      setSortedXAxisData(xAxisData.slice(sliceStartPosition, yAxisData.length));
      setSortedYAxisData(yAxisData.slice(sliceStartPosition, yAxisData.length));
      setSortedBarColors(xAxisData.slice(sliceStartPosition, yAxisData.length).map((_, index) => barColors[index % barColors.length]));
    }
  }, [sorted, xAxisData, yAxisData, upperLimit, barColors]);

  useEffect(() => {
    if (imageDict && Object.keys(imageDict).length > 0) {
      const imageObj = sortedXAxisData.reduce((acc: Record<string, any>, xAxis) => {
        acc[xAxis.replace(/\./g, "_")] = {
          height: sortedXAxisData.length < 5 && yAxisImageShape === "box" ? 80 : sortedXAxisData.length < 6 ? 50 : 30,
          width: sortedXAxisData.length < 5 && yAxisImageShape === "box" ? 80 : sortedXAxisData.length < 6 ? 50 : 30,
          align: "center",
          backgroundColor: {
            image: imageDict[xAxis.replace(/\./g, "_")]?.image,
          },
        };
        return acc;
      }, {});

      setXAxisObj(imageObj);
    }
  }, [imageDict, yAxisImageShape, sortedXAxisData]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Global
        styles={{
          ".react_echarts": {
            "& image": {
              // position: "relative",
              // "&:before": {
              //   width: "800px",
              //   height: "50px",
              //   content: "''",
              //   position: "absolute",
              //   top: 0,
              //   right: 0,
              //   bottom: 0,
              //   left: 0,
              //   backgroundColor: "black", // Set the background color to black
              //   clipPath: yAxisImageShape === "box" ? "" : "circle(50% at center)",
              //   zIndex: -1, // Place the background behind the images
              // },
              objectFit: "cover",
              clipPath:
                yAxisImageShape === "box" ? "polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%)" : "circle(50% at center)" /* Create a circular clip */,
            },
          },
        }}
      />

      {sortedXAxisData ? (
        sortedXAxisData.length === 0 ? (
          <NoDataFound image />
        ) : (
          <Box
            sx={{
              width: toolbox ? `calc(100% - ${mdDown ? 20 : 34}px)` : "100%",
              height: `calc(100% - ${noData(yAxisData) ? 30 : 0}px)`,
              pt: toolbox ? 1 : 0,
            }}
          >
            <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
              <ReactEcharts
                className="react_echarts"
                ref={chartRef}
                onEvents={onEvents}
                option={options}
                style={{ width: "100%", height: "100%", WebkitTapHighlightColor: "transparent" }}
                opts={{ renderer: "svg" }} // Use SVG renderer for better resizing
                //@ts-ignore
                autoResize={true} // Enable autoResize to make the height flexible
              />
              {tooltip && (
                <Tooltip title={"Click and drag to select a specific area, or scroll to zoom in and out."} arrow>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ cursor: "pointer", color: theme.palette.text.secondary, position: "absolute", bottom: 0, right: 8 }}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        )
      ) : (
        <SpinnerComponent modifier={1.2} />
      )}
    </>
  );
});

export default BarChart;
