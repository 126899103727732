import { faBuilding, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Stack, Theme, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { FeatureLayout } from "src/pages/my-account";
import { RootState } from "src/store/root-reducer";
/* components */
/* 3rd party lib */
/* Util */
interface PlanComponentProps {
  theme: Theme;
  price: number;
  active: boolean;
  tier: string;
  subtitle: string;
  features: string[];
  enterprise?: boolean;
  buttonText: string;
}

type Props = PlanComponentProps;

const PlanComponent: React.FC<Props> = ({ theme, enterprise = false, price, tier, subtitle, features, active, buttonText }) => {
  const { mode } = theme.palette;
  const { user } = useSelector((state: RootState) => state.auth);
  return (
    <Stack
      justifyContent={"space-between"}
      sx={{
        position: "relative",
        flexGrow: 1,
        border: `1px solid ${(theme.palette.neutral as any)[mode === "dark" ? 700 : 300]}`,
        py: 2,
        px: 1.5,
        height: "calc(100% - 32px)",
        background: active ? (theme.palette.neutral as any)[mode === "dark" ? 800 : 100] : "none",
      }}
    >
      {active && (
        <Box sx={{ position: "absolute", right: "8px", top: "6px" }}>
          <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.success.main} />
        </Box>
      )}
      <Stack spacing={1}>
        <Stack alignItems="center" justifyContent="center">
          <Typography textAlign="center" variant="h5" fontWeight={500} color="text.secondary">
            {tier}
          </Typography>
          <Typography textAlign="center" fontSize={12.5} fontWeight={400} color={mode === "dark" ? "grey.200" : "grey.400"}>
            {subtitle}
          </Typography>
          {enterprise ? (
            <Box sx={{ py: 1 }}>
              <FontAwesomeIcon size="3x" icon={faBuilding} color={mode === "dark" ? "text.secondary" : "grey.600"} />
            </Box>
          ) : (
            <>
              <Stack direction="row">
                <Typography variant="body1" fontWeight={500} color="grey.400" sx={{ mt: 0.5, mr: 0.5 }}>
                  <sup>$</sup>
                </Typography>
                <Typography variant="h3" fontWeight={600} color={mode === "dark" ? "text.secondary" : "grey.600"}>
                  {price}
                </Typography>
                <Typography variant="caption" fontWeight={500} color="grey.400" sx={{ alignContent: "flex-end", mb: 1, ml: 0.5 }}>
                  SGD
                </Typography>
              </Stack>
              <Typography variant="caption" fontWeight={500} color="grey.400">
                PER MONTH
              </Typography>
            </>
          )}
        </Stack>
        <Stack>
          {features.map(child => (
            <FeatureLayout key={child} available={true} theme={theme} label={child} />
          ))}
        </Stack>
      </Stack>
      <Stack alignItems="center" sx={{ mt: 2 }}>
        <Button
          disabled={buttonText === "Renew Plan" ? false : active}
          sx={{ background: buttonText === "Renew Plan" ? (theme.palette.mode === "light" ? "white" : "none") : "default" }}
          variant={buttonText === "Renew Plan" ? "outlined" : "contained"}
          onClick={() => {
            if (!active) {
              // prettier-ignore
              window.location.href = `mailto:contact@gointec.io?Subject=${encodeURIComponent("Upgrading to " + tier)}&body=${encodeURIComponent(
                  `Dear goIntec,\n\nI am writing to request an upgrade to the ${tier} subscription tier for my account with the username [${user ? user.user : "-"}] and user id ${user?.account_number}.\n\nCould you please provide me with information on how to proceed with upgrading my account? I am eager to access the enhanced functionalities available with the Lite subscription.\n\nThank you for your assistance.\n\nBest regards,\n${user ? user.name : "-"}`)}`;
            } else {
              // prettier-ignore
              window.location.href = `mailto:contact@gointec.io?Subject=${encodeURIComponent("Renewing " + tier + " Plan")}&body=${encodeURIComponent(`Dear goIntec,\n\nI am writing to request to renew the ${tier} tier subscription plan for my account with the username [${user ? user.user : "-"}] and user id ${user?.account_number}.\n\nCould you please provide me with information on how to proceed with upgrading my account? \n\nBest regards,\n${user ? user.name : "-"}`)}`;
            }
          }}
        >
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};

export default PlanComponent;
