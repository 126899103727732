import { useReducer } from "react";

export interface DataInitialState {
  count: number;
  limit: number;
  data: any | null;
  filters: { [key: string]: string } | null;
  sort: { [key: string]: string } | null;
  page: number;
  totalPage: number;
}

const initialState = {
  count: 0,
  data: null,
  filters: null,
  sort: null,
  page: 1,
  totalPage: 1,
  limit: 0,
};

const reducer = (state: DataInitialState, action: { payload: any; type: string }) => {
  switch (action.type) {
    case "SET": {
      const { count, limit, data, totalPage } = action.payload;
      return { ...state, count, limit, data, totalPage };
    }

    case "APPEND": {
      return { ...state, data: [...state.data, ...action.payload] };
    }

    case "SET_COUNT": {
      return { ...state, count: action.payload };
    }

    case "SET_PAGE": {
      return { ...state, page: action.payload };
    }

    case "APPEND_FILTERS": {
      const filters = { ...action.payload };

      // Remove empty values
      Object.keys(filters).forEach(key => {
        if (!filters[key]) delete filters[key];
      });

      return { ...state, page: 1, filters: { ...state.filters, ...filters } };
    }

    case "SET_FILTERS": {
      const filters = { ...action.payload };

      // Remove empty values
      Object.keys(filters).forEach(key => {
        if (!filters[key]) delete filters[key];
      });

      if (Object.keys(filters).length === 0) {
        return { ...state, page: 1, filters: null };
      } else {
        return { ...state, page: 1, filters };
      }
    }

    case "REMOVE_FILTER": {
      const filters = { ...state.filters };

      delete filters[action.payload];

      return { ...state, filters: Object.keys(filters).length === 0 ? null : filters };
    }

    case "RESET_FILTERS": {
      return { ...state, page: 1, filters: null };
    }

    case "SET_SORT": {
      let sort = null;
      sort = { ...state.sort };
      const key = action.payload;

      if (!sort[key]) {
        sort[key] = "asc";
      } else {
        if (sort[key] === "desc") {
          delete sort[key];
        } else {
          sort[key] = "desc";
        }
      }

      if (Object.keys(sort).length === 0) {
        sort = null;
      }

      return { ...state, sort };
    }

    case "SET_SORT_WITH_ORDER": {
      const sort = action.payload;
      return { ...state, sort: Object.keys(sort).length === 0 ? null : sort };
    }

    case "REMOVE_SORT": {
      const sort = { ...state.sort };

      delete sort[action.payload];

      return { ...state, sort };
    }

    case "RESET": {
      return initialState;
    }

    default:
      throw new Error("Invalid action type.");
  }
};

const useData = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return { ...state, dispatchData: dispatch };
};

export default useData;
