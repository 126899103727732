/* components */
/* 3rd party lib */
import moment from "moment";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarClock } from "@fortawesome/pro-solid-svg-icons";
import { Box, Drawer, Grid, IconButton, Paper, Skeleton, Stack, Typography, useTheme } from "@mui/material";
/* Util */
import { scroll } from "src/theme/dark-theme-options";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { IFormDateFilter, TSocialMedia } from "src/types/common";
import InstaProfilePostGrid from "src/components/profile/instagram/profile-post-grid";
import TiktokProfilePostGrid from "src/components/profile/tiktok/profile-post-grid";
import { INSTA_METRICS } from "src/components/comparison/profiles/instagram/profile-top-posts";
import { faPhotoFilm } from "@fortawesome/pro-regular-svg-icons";

interface PostCalendarDetailProps {
  open: boolean;
  onClose: () => void;
  socialMedia: TSocialMedia;
  data: { postNum: number; date: string } | null;
}

type Props = PostCalendarDetailProps;

const PostCalendarDetail: React.FC<Props> = ({ data, open, onClose, socialMedia }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { mode } = theme.palette;
  const params = useParams();
  const { ssDown, smDown, mdDown } = useMediaQueries();

  const formik = useFormik<IFormDateFilter>({
    validateOnChange: false,
    initialValues: {
      start_min: data ? moment(data.date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
      start_max: data ? moment(data.date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
    },
    onSubmit: () => {},
  });

  /* ================================================== */
  /*  method */
  /* ================================================== */

  useEffect(() => {
    if (data) {
      formik.setValues({ start_min: moment(data.date).format("YYYY-MM-DD"), start_max: moment(data.date).format("YYYY-MM-DD") });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Drawer
      PaperProps={{ sx: { width: smDown ? "90vw" : mdDown ? "82vw" : "650px", ...scroll } }}
      anchor={"right"}
      open={open}
      onClose={onClose}
      sx={{ position: "relative", maxWidth: mdDown ? "80%" : "1000px" }}
    >
      <IconButton onClick={onClose} sx={{ position: "absolute", right: "16px", top: "16px" }}>
        <CloseRoundedIcon fontSize="small" />
      </IconButton>
      {data ? (
        <Stack sx={{ p: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems="center" spacing={2} sx={{ pl: 1.5, pt: 2, pb: 1 }}>
                <FontAwesomeIcon size={smDown ? "xl" : "2x"} icon={faCalendarClock} color={theme.palette.text.secondary} />
                <Stack direction={"row"} alignItems="center" spacing={1}>
                  <Typography variant={smDown ? "h6" : "h5"} color="text.secondary">
                    {moment(data.date, "ddd").format("dddd - DD MMM YYYY")}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems="center" spacing={2} sx={{ pl: 1.5, pt: 2, pb: 1 }}>
                <FontAwesomeIcon icon={faPhotoFilm} size={smDown ? "xl" : "2x"} color={theme.palette.text.secondary} />
                <Typography variant={smDown ? "h6" : "h5"} color="text.secondary">
                  Number of Post: {data.postNum}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1} pt={2}>
                <Typography variant="h5" color="primary.main" mt={1}>
                  Posts
                </Typography>

                <Paper sx={{ p: 1, background: (theme.palette.neutral as any)[mode === "light" ? 100 : 800] }}>
                  {socialMedia === "instagram" ? (
                    <InstaProfilePostGrid
                      formik={formik}
                      height="auto"
                      metric={INSTA_METRICS.count_play}
                      username={params.username}
                      customGridSize={{ xs: 6, sm: 4, md: 3, lg: 3, xl: 3 }}
                    />
                  ) : (
                    <TiktokProfilePostGrid
                      formik={formik}
                      height="auto"
                      metric={INSTA_METRICS.count_play}
                      username={params.username}
                      customGridSize={{ xs: 6, sm: 4, md: 3, lg: 3, xl: 3 }}
                    />
                  )}
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      ) : (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={1} justifyContent="space-around">
            <Grid item xs={12}>
              <Skeleton width="200px" sx={{ pt: 2 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary.main" mt={1}>
                Statistics
              </Typography>
            </Grid>
            {Array.from({ length: 4 }).map((_, index) => (
              <Grid key={`'loading_grid_${index}`} item xs={12} sm={6} sx={{ display: "grid", placeItems: "center", padding: 0, margin: 0 }}>
                <Skeleton
                  sx={{
                    transform: "scale(1)",
                    width: "100%",
                    height: "100px",
                    margin: 0,
                    borderRadius: "8px",
                  }}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Typography variant="h5" color="primary.main" mt={1}>
                Posts
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <Grid container spacing={1} justifyContent="space-around">
                  {Array.from({ length: smDown ? 6 : 4 }).map((_, index) => (
                    <Grid
                      key={`'loading_grid_${index}`}
                      item
                      xs={ssDown ? 6 : 4}
                      sm={3}
                      md={3}
                      lg={3}
                      sx={{ display: "grid", placeItems: "center", padding: 0, margin: 0 }}
                    >
                      <Skeleton
                        sx={{
                          transform: "scale(1)",
                          width: "100%",
                          height: ssDown ? "50vw" : smDown ? "180px" : "200px",
                          margin: 0,
                          borderRadius: "8px",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Drawer>
  );
};

export default PostCalendarDetail;
