/* components */
/* 3rd party lib */
import {
  faPlay as SolidFaPlay,
  faThumbsUp as SolidFaThumbsUp,
  faComment as SolidFaComment,
  faShare as SolidFaShare,
  faBookmark as SolidFaBookmark,
  faClapperboardPlay,
  faSubtitles as SolidFaSubtitles,
  faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";

import React from "react";
import { faImage } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link, Paper, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { faBookmark, faComment, faPlay, faShare, faSubtitles, faThumbsUp, IconDefinition } from "@fortawesome/pro-regular-svg-icons";

/* Util */
import routes from "src/routes";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { TTikTokProfilePost } from "src/types/tiktok";
import { formatNumberWithSuffix, getDynamicIndex } from "src/utils/general";
import { largestNumColor } from "src/components/comparison/posts/table-row-cell";
import { TIKTOK_METRICS } from "src/components/comparison/profiles/tiktok/profile-top-posts";
import moment from "moment";
import { TPagination } from "src/types/common";
import { RootState } from "src/store/root-reducer";
import { useSelector } from "react-redux";

interface TikTokProfilePostCardProps {
  data: TTikTokProfilePost;
  setPopover?: React.Dispatch<
    React.SetStateAction<{
      anchorEl: HTMLElement | null;
      title: string | null;
    }>
  >;
  metric?: string;
  index?: number;
  username?: string;
  inDialog?: boolean;
  postFullSize?: boolean;
  showProfile?: boolean;
  pagination?: TPagination;
  isProfilePost?: boolean;
  disableClick?: boolean;
  handlePopoverClose?: () => void;
  handlePopoverOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  onClickAction?: any;
}

type Props = TikTokProfilePostCardProps;

const TikTokProfilePostCard: React.FC<Props> = ({
  data,
  index,
  metric,
  username,
  pagination,
  setPopover,
  onClickAction,
  inDialog = false,
  showProfile = false,
  postFullSize = false,
  disableClick = false,
  isProfilePost = false,
  handlePopoverClose,
  handlePopoverOpen,
}) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { smDown } = useMediaQueries();
  const { multiPost } = useSelector((state: RootState) => state.compare);
  const { engagement_rate, count_play, count_like, count_comment, count_saved, count_share, date_posted, downloaded_image, title } = data;

  const tempUsername = username ? username : data.profile.username;

  const cardHeight = postFullSize ? "200px" : "150px";
  const cardWidth = postFullSize ? "160px" : "120px";
  const iconWidth = postFullSize ? "20px" : "14px";

  let playTextColor =
    metric && TIKTOK_METRICS.count_play === metric ? (index === 0 ? largestNumColor : theme.palette.primary.main) : theme.palette.text.secondary;
  let likeTextColor =
    metric && TIKTOK_METRICS.count_like === metric ? (index === 0 ? largestNumColor : theme.palette.primary.main) : theme.palette.text.secondary;
  let commentTextColor =
    metric && TIKTOK_METRICS.count_comment === metric ? (index === 0 ? largestNumColor : theme.palette.primary.main) : theme.palette.text.secondary;
  let shareTextColor =
    metric && TIKTOK_METRICS.count_share === metric ? (index === 0 ? largestNumColor : theme.palette.primary.main) : theme.palette.text.secondary;
  let savedTextColor =
    metric && TIKTOK_METRICS.count_saved === metric ? (index === 0 ? largestNumColor : theme.palette.primary.main) : theme.palette.text.secondary;
  let erTextColor =
    metric && TIKTOK_METRICS.engagement_rate === metric ? (index === 0 ? largestNumColor : theme.palette.primary.main) : theme.palette.text.secondary;

  let playIcon = metric && TIKTOK_METRICS.count_play === metric ? SolidFaPlay : faPlay;
  let likeIcon = metric && TIKTOK_METRICS.count_like === metric ? SolidFaThumbsUp : faThumbsUp;
  let commentIcon = metric && TIKTOK_METRICS.count_comment === metric ? SolidFaComment : faComment;
  let savedIcon = metric && TIKTOK_METRICS.count_saved === metric ? SolidFaBookmark : faBookmark;
  let shareIcon = metric && TIKTOK_METRICS.count_share === metric ? SolidFaShare : faShare;
  let subtitleIcon = metric && TIKTOK_METRICS.date_posted === metric ? SolidFaSubtitles : faSubtitles;

  const componentSequence = [
    <GridItem
      iconWidth={iconWidth}
      postFullSize={postFullSize}
      targetKey="count_play"
      metric={metric}
      color={playTextColor}
      icon={playIcon}
      count={count_play}
    />,
    <GridItem
      iconWidth={iconWidth}
      postFullSize={postFullSize}
      targetKey="count_like"
      metric={metric}
      color={likeTextColor}
      icon={likeIcon}
      count={count_like}
    />,
    <GridItem
      iconWidth={iconWidth}
      postFullSize={postFullSize}
      targetKey="count_comment"
      metric={metric}
      color={commentTextColor}
      icon={commentIcon}
      count={count_comment}
    />,
    <GridItem
      iconWidth={iconWidth}
      postFullSize={postFullSize}
      targetKey="count_saved"
      metric={metric}
      color={savedTextColor}
      icon={savedIcon}
      count={count_saved}
    />,
    <GridItem
      iconWidth={iconWidth}
      postFullSize={postFullSize}
      targetKey="count_share"
      metric={metric}
      color={shareTextColor}
      icon={shareIcon}
      count={count_share}
    />,
    <GridItem
      iconWidth={iconWidth}
      postFullSize={postFullSize}
      targetKey="engagement_rate"
      metric={metric}
      color={erTextColor}
      icon={commentIcon}
      count={engagement_rate}
    />,
    <>
      {title && title !== "" && (
        <Stack direction="row" spacing={postFullSize ? 1 : 0.2} alignItems="center">
          <Box
            onMouseEnter={e => {
              if (handlePopoverOpen && setPopover) {
                handlePopoverOpen(e);
                setPopover(prevState => {
                  return { ...prevState, title: title };
                });
              }
            }}
            onMouseLeave={handlePopoverClose}
          >
            <Stack alignItems="center" justifyContent={"center"} sx={{ width: iconWidth, height: iconWidth }}>
              <FontAwesomeIcon size={postFullSize ? "sm" : "xs"} color={"white"} icon={subtitleIcon} />
            </Stack>
          </Box>
          <Typography variant={"caption"} fontSize={postFullSize ? "12px" : "10px"} fontWeight={400} color={"#ffffff"}>
            {moment(date_posted).format("DD MMM YYYY")}
          </Typography>
        </Stack>
      )}
    </>,
  ];

  let gridItem = null;
  let statsPosition = postFullSize ? "-120px" : "-101px";
  let statsPositionDescNotExist = postFullSize ? "-102px" : "-85px";
  let numberPosition = postFullSize ? "4px" : "3px";

  switch (metric) {
    case TIKTOK_METRICS.count_play:
      gridItem = (
        <>
          {componentSequence[0]}
          {componentSequence[1]}
          {componentSequence[2]}
          {componentSequence[3]}
          {componentSequence[4]}
          {componentSequence[5]}
          {componentSequence[6]}
        </>
      );
      break;
    case TIKTOK_METRICS.count_like:
      gridItem = (
        <>
          {componentSequence[1]}
          {componentSequence[0]}
          {componentSequence[2]}
          {componentSequence[3]}
          {componentSequence[4]}
          {componentSequence[5]}
          {componentSequence[6]}
        </>
      );
      break;
    case TIKTOK_METRICS.count_comment:
      gridItem = (
        <>
          {componentSequence[2]}
          {componentSequence[0]}
          {componentSequence[1]}
          {componentSequence[3]}
          {componentSequence[4]}
          {componentSequence[5]}
          {componentSequence[6]}
        </>
      );
      break;
    case TIKTOK_METRICS.count_saved:
      gridItem = (
        <>
          {componentSequence[3]}
          {componentSequence[0]}
          {componentSequence[1]}
          {componentSequence[2]}
          {componentSequence[4]}
          {componentSequence[5]}
          {componentSequence[6]}
        </>
      );
      break;
    case TIKTOK_METRICS.count_share:
      gridItem = (
        <>
          {componentSequence[4]}
          {componentSequence[0]}
          {componentSequence[1]}
          {componentSequence[2]}
          {componentSequence[3]}
          {componentSequence[5]}
          {componentSequence[6]}
        </>
      );
      break;
    case TIKTOK_METRICS.date_posted:
      gridItem = (
        <>
          {componentSequence[6]}
          {componentSequence[0]}
          {componentSequence[1]}
          {componentSequence[2]}
          {componentSequence[3]}
          {componentSequence[4]}
          {componentSequence[5]}
        </>
      );
      break;
    case TIKTOK_METRICS.engagement_rate:
      gridItem = (
        <>
          {componentSequence[5]}
          {componentSequence[0]}
          {componentSequence[1]}
          {componentSequence[2]}
          {componentSequence[3]}
          {componentSequence[4]}
          {componentSequence[6]}
        </>
      );
      break;
    default:
      gridItem = (
        <>
          {componentSequence[0]}
          {componentSequence[1]}
          {componentSequence[2]}
          {componentSequence[3]}
          {componentSequence[4]}
          {componentSequence[5]}
          {componentSequence[6]}
        </>
      );
      break;
  }

  const postIdMatches = tempUsername && multiPost && multiPost[tempUsername] && multiPost[tempUsername][data.post_id];

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleContextMenu = (e: any) => {
    e.preventDefault();
    if (!disableClick) {
      window.open(`${routes.TIKTOK_PROFILE}/${tempUsername}/${data.post_id}`, "_blank");
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Link
        onClick={e => {
          e.stopPropagation();

          if (disableClick) return;
          if (onClickAction) {
            onClickAction(data);
          } else {
            window.open(`${routes.TIKTOK_PROFILE}/${tempUsername}/${data.post_id}`, "_blank");
          }
        }}
        onContextMenu={handleContextMenu}
        href={onClickAction || disableClick ? undefined : `${routes.TIKTOK_PROFILE}/${postIdMatches}/${data.post_id}`}
        rel="noopener noreferrer"
        target="_blank"
        variant="caption"
        underline="none"
        sx={{
          pointerEvents: disableClick ? "none" : "initial",
          whiteSpace: "nowwrap",
          cursor: disableClick ? "default" : "pointer",
          width: inDialog || isProfilePost ? "100%" : "unset",
        }}
      >
        <Paper
          elevation={10}
          sx={{
            maxWidth: metric ? "300px" : "auto",
            // p: "12px 16px 12px 16px",
            position: "relative",

            // border: "1px solid transparent",
            "&:hover": {
              transition: "all 0.3s ease",
              transform: "translateY(-2px)",
              // border: mode === "dark" ? "1px solid white" : `1px solid ${(theme.palette as any).neutral[300]}`,
            },
          }}
        >
          {showProfile && (
            <Tooltip arrow title={postIdMatches}>
              <Box
                sx={{
                  position: "absolute",
                  top: "8px",
                  left: "8px",
                  zIndex: 3,
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "50%",
                  overflow: "hidden",
                  border: "1px solid #dcdcdc",
                  width: "30px",
                  height: "30px",
                }}
              >
                {data.profile.downloaded_image ? (
                  <img
                    alt={data.profile.downloaded_image}
                    src={data.profile.downloaded_image}
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                ) : null}
              </Box>
            </Tooltip>
          )}

          <Stack direction="row" alignItems="center" justifyContent={"space-between"}></Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            // sx={{
            //   mt: 0.8,
            // }}
          >
            <Box sx={{ position: "relative", width: inDialog ? "100%" : cardWidth }}>
              <Box
                sx={{
                  background: "black",
                  border: postIdMatches ? `5px solid ${theme.palette.primary.main}` : "none",
                  boxSizing: "border-box",
                  borderRadius: "8px",
                  width: inDialog ? "100%" : cardWidth,
                  height: cardHeight,
                  display: "grid",
                  placeItems: "center",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {postIdMatches && (
                  <Box
                    sx={{
                      opacity: postIdMatches ? 1 : 0,
                      position: "absolute",
                      zIndex: 3,
                      top: postIdMatches && showProfile ? "20px" : "6px",
                      left: postIdMatches && showProfile ? "20px" : "6px",
                      background: "white",
                      borderRadius: "50%",

                      padding: 0.3,
                      display: "grid",
                      placeItems: "center",
                      border: `1px solid ${(theme.palette.neutral as any)[300]}`,
                    }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.success.main} size="lg" />
                  </Box>
                )}

                <>
                  <Box
                    sx={{
                      width: "100%",
                      position: "absolute",
                      height: "100%" /* Set the height of your div */,
                      background: showProfile
                        ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0, transparent ${showProfile ? "30%" : "20%"})`
                        : "none",
                    }}
                  />

                  <Box
                    sx={{
                      width: "100%",
                      position: "absolute",
                      height: "100%" /* Set the height of your div */,
                      background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0, transparent 20%)",
                    }}
                  />
                  <Box sx={{ position: "absolute", zIndex: 2, top: "8px", right: "8px" }}>
                    <FontAwesomeIcon color="white" icon={faClapperboardPlay} size="lg" />
                  </Box>
                  {index !== undefined && (
                    <Box sx={{ position: "absolute", zIndex: 2, bottom: numberPosition, right: "8px" }}>
                      <Typography variant="caption" color="white">
                        #{pagination ? getDynamicIndex(pagination.currPage - 1, pagination.limit, index + 1) : index + 1}
                      </Typography>
                    </Box>
                  )}
                  {!disableClick && (
                    <Stack
                      sx={{
                        width: "100%",
                        position: "absolute",
                        justifyContent: "flex-end",
                        height: "100%" /* Set the height of your div */,
                        background: "linear-gradient(to top, rgba(0, 0, 0, 0.6) 0 , transparent 40%)",
                        transition: "all 0.5s ease",
                        "&:hover": {
                          background: "linear-gradient(to top, rgba(0, 0, 0, 0.8) 10% , transparent 100%)",
                          ".stats": {
                            transition: "all 0.5s ease",
                            bottom: "0",
                          },
                        },
                      }}
                    >
                      <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                        <Box
                          className="stats"
                          sx={{ p: 0.5, position: "absolute", bottom: title && title !== "" ? statsPosition : statsPositionDescNotExist }}
                        >
                          {/* <GridItem targetKey="count_play" metric={metric} color={playTextColor} icon={faPlay} count={count_play} /> */}
                          {gridItem}
                        </Box>
                      </Box>
                    </Stack>
                  )}
                </>

                <Box
                  sx={{
                    display: "grid",
                    // borderRadius: "8px",
                    placeItems: "center",
                    // width: "80%",
                    // width: "50%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  {downloaded_image ? (
                    <img alt={downloaded_image} src={downloaded_image} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                  ) : (
                    <FontAwesomeIcon icon={faImage} color={theme.palette.text.secondary} size={smDown ? "xl" : "2x"} />
                  )}
                </Box>
              </Box>
            </Box>
          </Stack>
        </Paper>
      </Link>
    </>
  );
};

export default TikTokProfilePostCard;

export const GridItem = ({
  color,
  icon,
  count,
  targetKey,
  metric,
  iconWidth,
  postFullSize,
}: {
  targetKey: keyof typeof TIKTOK_METRICS;
  color: string;
  icon: IconDefinition;
  count: number;
  metric?: string | undefined;
  postFullSize: boolean;
  iconWidth: string;
}) => {
  let primaryFontFullSize = postFullSize ? "14px" : "12px";
  let secondaryFontFullSize = postFullSize ? "12px" : "10px";

  return (
    <Stack direction="row" spacing={postFullSize ? 1 : 0.2} alignItems="center" justifyContent={"flex-start"}>
      <Stack alignItems="center" justifyContent={"center"} sx={{ width: iconWidth, height: iconWidth }}>
        {targetKey === "engagement_rate" ? (
          <Typography
            color="white"
            fontSize={metric && TIKTOK_METRICS[targetKey] === metric ? primaryFontFullSize : secondaryFontFullSize}
            variant={metric && TIKTOK_METRICS[targetKey] === metric ? "body2" : "caption"}
            fontWeight={metric && TIKTOK_METRICS[targetKey] === metric ? "bolder" : "normal"}
          >
            ER
          </Typography>
        ) : (
          <FontAwesomeIcon size={postFullSize ? "1x" : "sm"} color={"#ffffff"} icon={icon} />
        )}
      </Stack>

      <Typography
        // variant={metric && TIKTOK_METRICS[targetKey] === metric ? "h6" : "body2"}
        fontSize={metric && TIKTOK_METRICS[targetKey] === metric ? primaryFontFullSize : secondaryFontFullSize}
        variant={metric && TIKTOK_METRICS[targetKey] === metric ? "body2" : "caption"}
        fontWeight={metric && TIKTOK_METRICS[targetKey] === metric ? 600 : 400}
        color={"#ffffff"}
      >
        {formatNumberWithSuffix(count) ?? "-"}
      </Typography>
    </Stack>
  );
};
