import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
/* components */
import SpinnerComponent from "src/components/common/feedback/spinner";
import NoDataFound from "src/components/common/feedback/no-data-found";
import InstaProfilePostCard from "src/components/comparison/profiles/instagram/profile-post-card";
import FullAndMobilePagination from "src/components/common/navigation/full-mobile-pagination";

/* 3rd party lib */
import moment from "moment";
import { FormikProps } from "formik";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Popover, Stack, Typography, useTheme } from "@mui/material";

/* Util */
import routes from "src/routes";
import useHttp from "src/hooks/use-http";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { IFormDateFilter, TPagination } from "src/types/common";
import { IInstaProfilePostData, TInstaProfilePost } from "src/types/instagram";

interface ProfilePostGridProps {
  height?: string;
  search?: string;
  metric?: string;
  username?: string;
  formik: FormikProps<IFormDateFilter>;
  onSelectPost?: (rowData: TInstaProfilePost) => void;
  apiConfig?: any;
  customGridSize?: { xs?: number; sm: number; md: number; lg: number; xl: number };
}

type Props = ProfilePostGridProps;

const ProfilePostGrid: React.FC<Props> = ({ height, metric, formik, search, username, onSelectPost, apiConfig, customGridSize }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const navigate = useNavigate();
  const { apiEndpoint } = useHttp();
  const { ssDown, ssUp, xsUp, smUp, mdUp, lgUp, xlUp } = useMediaQueries();
  const [pagination, setPagination] = useState<TPagination>({
    currPage: 1,
    count: 0,
    limit: 0,
    total_pages: 0,
  });
  const config = useMemo(() => {
    let pageSize = 25;

    if (xlUp) {
      pageSize = 30;
    } else if (lgUp) {
      pageSize = 30;
    } else if (mdUp) {
      pageSize = 18;
    } else if (smUp) {
      pageSize = 16;
    } else if (ssUp) {
      pageSize = 9;
    } else if (xsUp) {
      pageSize = 8;
    }

    return {
      params: {
        q: search,
        page_size: pageSize,
        ordering: `-${metric}`,
        page: pagination.currPage,
        date_posted_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
        date_posted_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
        ...apiConfig,
      },
    };
  }, [xsUp, ssUp, smUp, mdUp, lgUp, xlUp, formik, metric, search, apiConfig, pagination]);

  const [popover, setPopover] = useState<{ anchorEl: HTMLElement | null; title: string | null }>({ anchorEl: null, title: null });

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopover({ ...popover, anchorEl: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setPopover({ ...popover, anchorEl: null });
  };

  const open = Boolean(popover.anchorEl);

  const { data } = useSWR<IInstaProfilePostData>(username && [apiEndpoint.INSTAGRAM_PROFILE_POSTS(username), config]);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleNavigate = (rowData: TInstaProfilePost) => {
    navigate(`${routes.INSTA_PROFILE}/${username}/${rowData.post_id}`);
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    setPagination(prev => {
      return { ...prev, currPage: 1 };
    });
  }, [search]);

  useEffect(() => {
    if (data) {
      setPagination(prev => {
        return { ...prev, count: data.count, limit: data.limit, total_pages: data.total_pages };
      });
    }
  }, [data]);

  /* ================================================== */
  /* ================================================== */

  return (
    <>
      <Paper sx={{ minHeight: height ?? (data && data.results.length > 0 ? "610px" : "auto"), p: 1 }}>
        <Stack direction="row" justifyContent={"flex-end"} sx={{ my: 1 }}>
          <FullAndMobilePagination pagination={pagination} setPagination={setPagination} />
        </Stack>
        {data ? (
          data.results.length === 0 ? (
            <Box>
              <Paper sx={{ paddingTop: 2, paddingBottom: 2 }}>
                <NoDataFound image />
              </Paper>
            </Box>
          ) : (
            <Grid container spacing={1} justifyContent={"center"}>
              {data.results.map((child, index) => {
                return (
                  <Grid
                    key={`'insta_grid_${index}`}
                    item
                    xs={customGridSize ? customGridSize.xs : ssDown ? 6 : 4}
                    sm={customGridSize ? customGridSize.sm : 3}
                    md={customGridSize ? customGridSize.md : 2}
                    lg={customGridSize ? customGridSize.lg : 1.2}
                    xl={customGridSize ? customGridSize.xl : 1.2}
                    sx={{ display: "grid", placeItems: "center" }}
                  >
                    <InstaProfilePostCard
                      inDialog
                      pagination={pagination}
                      data={child}
                      index={index}
                      postFullSize
                      isProfilePost
                      metric={metric}
                      username={username}
                      setPopover={setPopover}
                      onClickAction={handleNavigate}
                      handlePopoverOpen={handlePopoverOpen}
                      handlePopoverClose={handlePopoverClose}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )
        ) : (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              minHeight: "610px",
              width: "100%",
              background: theme.palette.mode === "dark" ? (theme.palette.neutral as any)[800] : "white",
            }}
          >
            <SpinnerComponent color="white" />
          </Box>
        )}
        <Stack direction="row" justifyContent={"flex-end"} sx={{ my: 1 }}>
          <FullAndMobilePagination pagination={pagination} setPagination={setPagination} />
        </Stack>
      </Paper>
      <Popover
        id="insta-grid-post-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={popover.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {popover.title && (
          <Typography variant="body2" sx={{ p: 1, maxWidth: "500px" }}>
            <div style={{ whiteSpace: "normal" }} dangerouslySetInnerHTML={{ __html: popover.title.replace(/\n/g, "<br/> ") }} />
          </Typography>
        )}
      </Popover>
    </>
  );
};

export default ProfilePostGrid;
