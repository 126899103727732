/* components */
import NoDataFound from "src/components/common/feedback/no-data-found";
import InstaProfilePostCard from "src/components/comparison/profiles/instagram/profile-post-card";
/* 3rd party lib */
import {
  Box,
  FormControl,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import useSWR from "swr";
import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";

/* Util */
import useHttp from "src/hooks/use-http";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { rainbowBarColors } from "src/components/common/data-display/charts/bar-chart";
import { ICompareInstaProfileJsonData, IInstaProfilePostData } from "src/types/instagram";

import moment from "moment";
import Insta from "src/images/insta.png";
import { useComparisonProfileContext } from "src/contexts/comparison-profile-context";
import { useStrictDroppable } from "src/hooks/use-strict-droppable";

interface ProfileTopPostsProps {
  profileUsernames: string[];
  statisticData: ICompareInstaProfileJsonData | undefined;
}

type Props = ProfileTopPostsProps;

export const INSTA_METRICS = {
  count_play: "count_play",
  count_like: "count_like",
  count_comment: "count_comment",
  date_posted: "date_posted",
  engagement_rate: "engagement_rate",
};

const ProfileTopPosts: React.FC<Props> = ({ statisticData, profileUsernames }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const upperLimit = 10;
  const { mdDown } = useMediaQueries();
  const theme = useTheme();
  const { mode } = theme.palette;
  const [filterSize, setFilterSize] = useState(5);
  const [postFullSize, setPostFullSize] = useState(false);
  const [mouseEntered, setMouseEntered] = useState({ entered: false, index: -1 });
  const [metric, setMetric] = useState(INSTA_METRICS.count_play);
  const [tempProfileUsernames, setTempProfileUsernames] = useState<{ id: string; content: string }[] | null>(null);
  const [enabled] = useStrictDroppable(!!!tempProfileUsernames);
  const [popover, setPopover] = useState<{ anchorEl: HTMLElement | null; title: string | null }>({ anchorEl: null, title: null });

  const profileSize = postFullSize ? "100px" : "80px";
  const iconSize = postFullSize ? "20px" : "14px";

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopover({ ...popover, anchorEl: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setPopover({ ...popover, anchorEl: null });
  };

  const open = Boolean(popover.anchorEl);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !tempProfileUsernames) return;

    const items = Array.from(tempProfileUsernames);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTempProfileUsernames(items);
  };

  const handleOnMouseEnter = (index: number) => {
    setMouseEntered({ entered: true, index: index });
  };
  const handleOnMouseExit = () => {
    setMouseEntered({ entered: false, index: -1 });
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    const sortFunction = (a: { id: string; content: string }, b: { id: string; content: string }) => {
      if (!!!statisticData) return 0;
      // extract out the data based on the profile username
      const aValue = ((statisticData as any).results[a.id]?.date_last?.[metric.replace("count", "total")] ?? 0) || 0;
      const bValue = ((statisticData as any).results[b.id]?.date_last?.[metric.replace("count", "total")] ?? 0) || 0;

      return bValue - aValue;
    };

    const tempUsernames = profileUsernames.reduce((acc: { id: string; content: string }[], username: string) => {
      acc.push({ id: username, content: username });
      return acc;
    }, []);
    setTempProfileUsernames(tempUsernames.sort(sortFunction));
  }, [profileUsernames, metric, statisticData]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Paper elevation={5} sx={{ py: 3, minHeight: "400px" }}>
        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} sx={{ pr: mdDown ? 2 : 4 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box>
              <Tooltip arrow title={!postFullSize ? "Switch to full size" : "Switch to compact size"}>
                <ToggleButton
                  value="check"
                  onClick={() => setPostFullSize(!postFullSize)}
                  sx={{
                    "&:hover": {
                      background: theme.palette.primary.main,
                      "& .line_color": { borderColor: "white" },
                    },
                    "&:active": {
                      background: "white",
                      "& .line_color": { transition: "all 0.5s ease", borderColor: theme.palette.primary.main },
                    },
                    p: 0,
                    width: "38px",
                    height: "38px",
                    "& .line_color": {
                      transition: "all 0.5s ease",
                      borderColor: theme.palette.primary.main,
                    },
                  }}
                >
                  <Stack>
                    <Box className="line_color" sx={{ borderTop: "1px solid white", width: "15px", mb: postFullSize ? "30%" : "60%" }} />
                    <Box className="line_color" sx={{ borderTop: "1px solid white", width: "15px", mt: postFullSize ? "30%" : "60%" }} />
                  </Stack>
                </ToggleButton>
              </Tooltip>
            </Box>

            <Box>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  hiddenLabel
                  value={metric}
                  onChange={e => {
                    setMetric(e.target.value);
                  }}
                  select
                  size="small"
                  sx={{
                    width: "110px",
                    "& .MuiNativeSelect-select": {
                      textTransform: "capitalize",
                    },
                  }}
                  SelectProps={{ native: true }}
                >
                  <option value={INSTA_METRICS.count_play}>Play</option>
                  <option value={INSTA_METRICS.count_like}>Like</option>
                  <option value={INSTA_METRICS.count_comment}>Comment</option>
                  <option value={INSTA_METRICS.engagement_rate}>Engagement Rate</option>
                </TextField>
              </FormControl>
            </Box>

            <Box>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  hiddenLabel
                  value={filterSize}
                  onChange={e => {
                    setFilterSize(parseInt(e.target.value));
                  }}
                  select
                  size="small"
                  sx={{
                    width: "110px",
                    "& .MuiNativeSelect-select": {
                      textTransform: "capitalize",
                    },
                  }}
                  SelectProps={{ native: true }}
                >
                  <option value={5}>Top 5</option>
                  <option value={10}>Top 10</option>
                  <option value={25}>Top 25</option>
                </TextField>
              </FormControl>
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ p: 2 }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            {enabled && (
              <Droppable droppableId="horizontal-table">
                {provided => (
                  <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ width: "100%" }}>
                    <TableContainer sx={{ width: "100%" }}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow
                            sx={{
                              ".MuiTableRow-root": {
                                transition: "none !important",
                              },
                              "& .pinned-column": {
                                position: "sticky",
                                left: 0,
                                zIndex: 3,
                                backgroundColor: (theme.palette.neutral as any)[mode === "dark" ? 800 : 100],
                              },
                            }}
                          >
                            <TableCell sx={{ width: "180px" }} className={`pinned-column ${mode === "dark" ? "tablecell-dark" : "tablecell"}`}>
                              Profile
                            </TableCell>
                            <TableCell>Posts</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {statisticData &&
                            Object.keys(statisticData.results).length > 0 &&
                            tempProfileUsernames &&
                            tempProfileUsernames.length > 0 &&
                            tempProfileUsernames.slice(0, tempProfileUsernames.length > upperLimit ? upperLimit : Infinity).map((username, index) => {
                              return (
                                statisticData.results[username.id] && (
                                  <Draggable key={`row${username.id}`} draggableId={`row${username.id}`} index={index}>
                                    {(provided, snapshot) => (
                                      <tr
                                        onMouseEnter={() => handleOnMouseEnter(index)}
                                        onMouseLeave={handleOnMouseExit}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={`top_post_profile_${index}`}
                                        style={{
                                          cursor: "grab",
                                          backgroundColor:
                                            (mouseEntered.entered && mouseEntered.index === index) || snapshot.isDragging
                                              ? (theme.palette.neutral as any)[mode === "dark" ? 700 : 100]
                                              : "inherit",

                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <TableCell
                                          className={`pinned-column ${mode === "dark" ? "tablecell-dark" : "tablecell"}`}
                                          sx={{
                                            position: "sticky",
                                            left: 0,
                                            zIndex: 3,
                                            background:
                                              (mouseEntered.entered && mouseEntered.index === index) || snapshot.isDragging
                                                ? (theme.palette.neutral as any)[mode === "dark" ? 700 : 100]
                                                : mode === "dark"
                                                ? (theme.palette.neutral as any)[900]
                                                : "white",
                                          }}
                                        >
                                          <Box className={`${mode === "light" ? "tablecell" : "tablecell-dark"} text-center`} sx={{ width: "180px" }}>
                                            <Stack sx={{ display: "grid", placeItems: "center", pt: 1.5 }}>
                                              <Box sx={{ position: "relative", width: profileSize, height: profileSize, mb: 1 }}>
                                                <Box
                                                  sx={{
                                                    borderRadius: "50%",
                                                    overflow: "hidden",
                                                    border: "1px solid #dcdcdc",
                                                    width: profileSize,
                                                    height: profileSize,
                                                    display: "grid",
                                                    placeItems: "center",
                                                  }}
                                                >
                                                  <img
                                                    alt={statisticData.results[username.id].profile.downloaded_image}
                                                    src={statisticData.results[username.id].profile.downloaded_image}
                                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                  />
                                                </Box>

                                                <Box
                                                  sx={{
                                                    position: "absolute",
                                                    bottom: 0,
                                                    right: 0,
                                                    width: iconSize,
                                                    height: iconSize,
                                                    background: "white",
                                                    border: "1px solid #dcdcdc",
                                                    borderRadius: "50%",
                                                    padding: 0.5,
                                                    display: "grid",
                                                    placeItems: "center",
                                                  }}
                                                >
                                                  <img src={Insta} alt="insta" width="100%" height="100%" />
                                                </Box>
                                              </Box>
                                              <Typography
                                                variant={postFullSize ? "body1" : "body2"}
                                                sx={{
                                                  width: "90%",
                                                  textTransform: "capitalize",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "inline-block",
                                                    width: "10px",
                                                    height: "10px",
                                                    borderRadius: "50%",
                                                    background: rainbowBarColors[index % rainbowBarColors.length],
                                                  }}
                                                />
                                                &nbsp;
                                                {statisticData.results[username.id].profile.display_name}
                                              </Typography>
                                              <Typography
                                                variant={"caption"}
                                                sx={{
                                                  width: "90%",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  textTransform: "lowercase",
                                                }}
                                              >
                                                @{statisticData.results[username.id].profile.username}
                                              </Typography>
                                            </Stack>
                                          </Box>
                                        </TableCell>

                                        <TableCell
                                          sx={{ verticalAlign: "top" }}
                                          className={`${mode === "light" ? "tablecell" : "tablecell-dark"} text-center`}
                                        >
                                          <ProfilePostsComponent
                                            metric={metric}
                                            username={username.id}
                                            setPopover={setPopover}
                                            filterSize={filterSize}
                                            postFullSize={postFullSize}
                                            handlePopoverOpen={handlePopoverOpen}
                                            handlePopoverClose={handlePopoverClose}
                                          />
                                        </TableCell>
                                      </tr>
                                    )}
                                  </Draggable>
                                )
                              );
                            })}
                          {provided.placeholder}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Droppable>
            )}
          </DragDropContext>
        </Stack>
      </Paper>

      <Popover
        id="post-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={popover.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {popover.title && (
          <Typography variant="body2" sx={{ p: 2, maxWidth: "500px" }}>
            <div style={{ whiteSpace: "normal", fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: popover.title.replace(/\n/g, "<br/> ") }} />
          </Typography>
        )}
      </Popover>
    </>
  );
};

export default ProfileTopPosts;

interface IProfilePostsComponent {
  metric: string;
  filterSize: number;
  postFullSize: boolean;
  username: string;
  setPopover: React.Dispatch<
    React.SetStateAction<{
      anchorEl: HTMLElement | null;
      title: string | null;
    }>
  >;
  handlePopoverClose?: () => void;
  handlePopoverOpen?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ProfilePostsComponent = ({
  metric,
  postFullSize,
  filterSize,
  username,
  setPopover,
  handlePopoverOpen,
  handlePopoverClose,
}: IProfilePostsComponent) => {
  const { formik } = useComparisonProfileContext();
  const { apiEndpoint } = useHttp();
  // const [pagination, setPagination] = useState<TPagination>({
  //   currPage: 1,
  //   count: 0,
  //   limit: 0,
  //   total_pages: 0,
  // });
  const config = useMemo(() => {
    return {
      params: {
        ordering: `-${metric}`,
        date_posted_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
        date_posted_max: moment(formik.values.start_max).format("YYYY-MM-DD"),

        /* page: pagination.currPage */
      },
    };
  }, [metric, formik.values /*  pagination */]);
  const { data } = useSWR<IInstaProfilePostData>([apiEndpoint.INSTAGRAM_PROFILE_POSTS(username), config]);

  return (
    <Stack direction={"row"} spacing={1} alignItems="flex-start" sx={{ width: "100%", height: "100%", my: 1 }}>
      {data && (
        <>
          {data.results.length === 0 ? (
            <NoDataFound height={"140px"} image />
          ) : (
            data.results
              .slice(0, filterSize)
              .map((child, index) => (
                <InstaProfilePostCard
                  key={`insta_profile_post_card_${index}`}
                  data={child}
                  index={index}
                  metric={metric}
                  username={username}
                  setPopover={setPopover}
                  postFullSize={postFullSize}
                  handlePopoverOpen={handlePopoverOpen}
                  handlePopoverClose={handlePopoverClose}
                />
              ))
          )}
        </>
      )}
    </Stack>
  );
};
