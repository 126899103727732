import { FormikProps } from "formik";
import { IFormDateFilter } from "src/types/common";
import { Stack } from "@mui/material";
import InstaPostHashtagGrid from "src/pages/listing/instagram/insta-post-hashtag-grid";

interface InstaPostHashtagListingProps {
  hashtagSearch: string;
  handleClose?: () => void;
  onSelectPost?: (postId: string) => void;
  formik: FormikProps<IFormDateFilter>;
}

type Props = InstaPostHashtagListingProps;

const InstaPostHashtagListing: React.FC<Props> = ({ formik, hashtagSearch, handleClose, onSelectPost }) => {
  return (
    <Stack spacing={1}>
      <InstaPostHashtagGrid hashtagSearch={hashtagSearch} handleClose={handleClose} formik={formik} onSelectPost={onSelectPost} />
    </Stack>
  );
};
export default InstaPostHashtagListing;
