import React from "react";
/* components */
/* 3rd party lib */
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, useTheme } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

/* Util */
import { IPostPresetData } from "src/types/instagram";
import { IEditPostPresetDialog } from "src/pages/comparisons/posts/posts";
import { ICurrentActivePostPreset } from "src/pages/comparisons/posts/posts";
import PostPresetSelectionList from "src/components/comparison/posts/preset-selection-list";

interface PostPresetDialogProps {
  open: boolean;
  onClose: () => void;
  presetData?: IPostPresetData[];
  currentActive: ICurrentActivePostPreset;
  setPostIds: React.Dispatch<React.SetStateAction<string[]>>;
  setCurrentActive: React.Dispatch<React.SetStateAction<ICurrentActivePostPreset>>;
  setEditPresetDialog: React.Dispatch<React.SetStateAction<IEditPostPresetDialog>>;
}

type Props = PostPresetDialogProps;

const PostPresetDialog: React.FC<Props> = ({ open, presetData, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Stack direction="row" spacing={3}>
            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              Select Preset
            </Typography>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            ...(theme as any).custom.scroll,
          }}
        >
          <Box sx={{ pt: 2 }}>
            <PostPresetSelectionList onClose={onClose} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PostPresetDialog;
