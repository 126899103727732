import React, { useEffect, useMemo, useState } from "react";
/* components */
import ComparisonTable from "src/components/comparison/posts/instagram/comparison-table";
import PostsComparisonCharts from "src/components/comparison/posts/instagram/post-charts";
/* 3rd party lib */
import useSWR from "swr";
import moment from "moment";
import { Box, Grid, Stack, Tab, Tabs, Theme, Typography } from "@mui/material";
/* Util */
import useHttp from "src/hooks/use-http";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { useComparisonPostContext } from "src/contexts/comparison-post-context";
import { ICompareInstaPostJsonData, ILineChartInstaPostData } from "src/types/instagram";
import PostPresetSelectionList from "src/components/comparison/posts/preset-selection-list";
import PostComparisonSortDnd from "src/components/comparison/posts/comparison-sort-dnd";
import { IPostAvatarGroup } from "src/types/common";
import { IMultiPost, setMultiPost, setPostAvatarGroup } from "src/slices/compare";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/pro-solid-svg-icons";
import useBackground from "src/hooks/use-background";
import { checkObjectNotEmpty } from "src/utils/general";

export interface IBarInstaPostData {
  [key: string]: string[] | (number | null)[];
  usernames: string[];
  xAxis: string[];
  images: string[];
  count_play: (number | null)[];
  count_like: (number | null)[];
  count_comment: (number | null)[];
  engagement_rate: (number | null)[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  target: TCompareTab;
  theme: Theme;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, theme, target, ...other } = props;

  const background = useBackground({});

  return (
    <div
      role="tabpanel"
      hidden={value !== target}
      id={`simple-tabpanel-${target}`}
      aria-labelledby={`simple-tab-${target}`}
      {...other}
      style={{
        background: background,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {value === target && <Box sx={{ p: 1, width: "100%", minWidth: "300px" }}>{children}</Box>}
    </div>
  );
}

interface InstagramPostCompareProps {}

type Props = InstagramPostCompareProps;

const InstagramPostCompare: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const dispatch = useDispatch();
  const { lgUp } = useMediaQueries();
  const [view, setView] = useState<TCompareTab>("charts");
  const {
    theme,
    formik,
    presetData,
    postIds,
    postDialog,
    openSortPost,
    postExists,
    minCompare,
    tableHeight,
    addMoreDialog,
    currentActive,
    addMoreDisabled,
    setPostIds,
    setOpenSortPost,
    handleEditPreset,
  } = useComparisonPostContext();

  const [lineChartData, setLineChartData] = useState<ILineChartInstaPostData | null>(null);

  const { apiEndpoint } = useHttp();

  const config = useMemo(() => {
    const maxIsLessThanMin = moment(formik.values.start_max).isBefore(moment(formik.values.start_min));
    const minIsGreaterThanMax = moment(formik.values.start_min).isAfter(moment(formik.values.start_max));

    let params = {
      ordering: "date",
      post_ids: postIds.length > 0 ? postIds.join(",") : "",
      date_min: moment(minIsGreaterThanMax ? formik.values.start_max : formik.values.start_min).format("YYYY-MM-DD"),
      date_max: moment(maxIsLessThanMin ? formik.values.start_min : formik.values.start_max).format("YYYY-MM-DD"),
    };
    return {
      params: params,
    };
  }, [formik, postIds]);

  const { data } = useSWR<ICompareInstaPostJsonData>(postIds.length > 0 && [apiEndpoint.INSTAGRAM_COMPARE_POST_STATISTICS, config]);

  const [statisticData, setStatisticData] = useState<ICompareInstaPostJsonData | undefined>(undefined);
  const [barChartData, setBarChartData] = useState<IBarInstaPostData | undefined>(undefined);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleDeletePost = (index: number) => {
    const removePostId = (postIds: string[]) => {
      let temp = postIds.slice();
      if (postIds.length > minCompare) {
        // have to actually remove the item instead of set to null if it's more than 3
        temp.splice(index, 1);
      } else {
        temp[index] = "";
      }
      return temp;
    };

    setPostIds(prevState => {
      const temp = removePostId(prevState);
      return temp;
    });

    if (currentActive.preset) {
      const tmpProfileUsernames = removePostId(currentActive.preset.posts.map(child => child.post_id)).filter(child => child !== "");
      handleEditPreset(currentActive.preset.id, currentActive.preset.title, tmpProfileUsernames);
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (data) {
      setStatisticData(data);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      // initialize values for avatar and multipost dict
      let result: IPostAvatarGroup[] = [];
      let tempMultiPost: IMultiPost = {};
      checkObjectNotEmpty(data.results) &&
        Object.values(data.results).forEach(child => {
          result.push({
            postId: child.post.post_id,
            username: child.post.profile_username,
            title: child.post.title,
            downloaded_image: child.post.image,
          });

          // if username doesnt exist then create and add post_id, update if exist
          tempMultiPost[child.post.profile_username] = tempMultiPost[child.post.profile_username] || {};
          tempMultiPost[child.post.profile_username][child.post.post_id] = child.post.post_id;
        });

      if (!currentActive.preset) {
        dispatch(setPostAvatarGroup(result.reverse()));
      }

      dispatch(setMultiPost(tempMultiPost));
    }
  }, [data, dispatch, currentActive, addMoreDialog, postDialog]);

  useEffect(() => {
    // if preset exist, follow the reverse order of the preset
    if (presetData && currentActive.preset && (addMoreDialog || postDialog.open)) {
      const rearrangedAvatarGroup = presetData
        .find(child => child.id === currentActive.preset?.id)
        ?.posts.map(child => {
          return {
            postId: child.post_id,
            username: child.profile_username,
            title: child.title,
            downloaded_image: child.downloaded_image,
          };
        });

      if (rearrangedAvatarGroup) {
        dispatch(setPostAvatarGroup(rearrangedAvatarGroup.slice().reverse()));
      }
    }
  }, [dispatch, presetData, currentActive, addMoreDialog, postDialog.open]);

  useEffect(() => {
    let result: ILineChartInstaPostData = {
      xAxis: [],
      count_play: [],
      count_comment: [],
      count_like: [],
    };

    let tmpBarChartData: IBarInstaPostData = {
      xAxis: [],
      images: [],
      usernames: [],
      count_play: [],
      count_like: [],
      count_comment: [],
      engagement_rate: [],
    };

    // const sortXAxisByDate = (a: string, b: string) => {
    //   return moment(a).isAfter(moment(b)) ? 1 : moment(a).isBefore(moment(b)) ? -1 : 0;
    // };

    if (statisticData && checkObjectNotEmpty(statisticData)) {
      postIds.forEach(child => {
        if (child) {
          const profileObj = statisticData.results[child];
          if (!profileObj) return;

          // concat all the available dates
          // result.xAxis = [...new Set(result.xAxis.concat(profileObj.statistics.map(child => child.date)))];
          // result.xAxis = result.xAxis.sort(sortXAxisByDate);

          // const lastItemExist = profileObj.statistics.length > 0;

          tmpBarChartData.xAxis.push(child);
          tmpBarChartData.images.push(profileObj.post.image);
          tmpBarChartData.usernames.push(profileObj.post.profile_username);
          tmpBarChartData.count_play.push(profileObj.date_last.count_play ?? 0);
          tmpBarChartData.count_like.push(profileObj.date_last.count_like ?? 0);
          tmpBarChartData.count_comment.push(profileObj.date_last.count_comment ?? 0);
          tmpBarChartData.engagement_rate.push(profileObj.post.engagement_stats.engagement_rate ?? 0);
        }
      });

      setBarChartData(tmpBarChartData);

      // HIDE LINE CHART FOR NOW

      // postIds.forEach((child, index) => {
      //   if (child) {
      //     const profileObj = statisticData.results[child];
      //     if (!profileObj) return;

      //     profileObj.statistics.sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? 1 : moment(a.date).isBefore(moment(b.date)) ? -1 : 0));

      //     let count_play: (number | null)[] = [];
      //     let count_like: (number | null)[] = [];
      //     let count_comment: (number | null)[] = [];
      //     const dateToDataMap: Record<string, any> = {};

      //     // Create a mapping of dates to their corresponding data
      //     profileObj.statistics.forEach(item => {
      //       dateToDataMap[item.date] = item;
      //     });

      //     result.xAxis.forEach(date => {
      //       // check whether data exist against the dict using date
      //       const existingData = dateToDataMap[date];
      //       count_play.push(existingData?.count_play ?? null);
      //       count_like.push(existingData?.count_like ?? null);
      //       count_comment.push(existingData?.count_comment ?? null);
      //     });

      //     result.count_play.push({
      //       name: `Post ${index + 1}`,
      //       type: "line",
      //       data: count_play,
      //     });
      //     result.count_like.push({
      //       name: `Post ${index + 1}`,
      //       type: "line",
      //       data: count_like,
      //     });
      //     result.count_comment.push({
      //       name: `Post ${index + 1}`,
      //       type: "line",
      //       data: count_comment,
      //     });
      //   }
      // });

      setLineChartData(result);
    }
  }, [statisticData, postIds]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={10}>
        <Box
          sx={{
            pr: {
              xs: 0,
              sm: 0,
              lg: !addMoreDisabled ? 3.5 : 0,
            },
          }}
        >
          <ComparisonTable postData={statisticData} onDeleteProfile={handleDeletePost} />
        </Box>
      </Grid>
      {lgUp && (
        <Grid item xs={12} lg={2}>
          <PostPresetSelectionList tableHeight={tableHeight} />
        </Grid>
      )}

      <Grid item xs={12}>
        {postExists && (
          <>
            <ComparisonTab
              tab={view}
              handleChangeTab={(event: any, nextView: TCompareTab) => {
                if (nextView) {
                  setView(nextView);
                }
              }}
              theme={theme}
            />
            <CustomTabPanel theme={theme} value={view} target={"charts"}>
              <PostsComparisonCharts barChartData={barChartData} lineChartData={lineChartData} />
            </CustomTabPanel>
          </>
        )}
      </Grid>

      <PostComparisonSortDnd postData={statisticData} open={openSortPost} onClose={() => setOpenSortPost(false)} />
    </Grid>
  );
};

export default InstagramPostCompare;

type TCompareTab = "charts";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ComparisonTab = ({
  tab,
  handleChangeTab,
  theme,
}: {
  tab: TCompareTab;
  handleChangeTab: (event: React.SyntheticEvent, newValue: TCompareTab) => void;
  theme: Theme;
}) => {
  const { smDown } = useMediaQueries();
  return (
    <Stack
      direction="row"
      justifyContent={"center"}
      sx={{ borderRadius: "8px 8px 0 0", background: theme.palette.mode === "light" ? "white" : (theme.palette.neutral as any)[900], width: "100%" }}
    >
      <Tabs variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChangeTab} aria-label="wrapped label tabs example" sx={{ pb: 1 }}>
        <Tab
          {...a11yProps(0)}
          value={"charts"}
          label={
            <Stack direction="row" alignItems={"center"} spacing={1} className="active-step6">
              <FontAwesomeIcon icon={faChartBar} size={smDown ? "lg" : "xl"} />
              <Typography variant={"body1"} fontWeight={500} color={tab === "charts" ? theme.palette.primary.main : theme.palette.text.secondary}>
                Charts
              </Typography>
            </Stack>
          }
        />
      </Tabs>
    </Stack>
  );
};
