import { Box, /*  Grid, */ Link, Paper, Stack, Typography /* useTheme */ } from "@mui/material";
import React /* useState */ from "react";
import GoToTopButton from "src/components/common/inputs/go-to-top-button";
import OuterLayout from "src/components/common/layout/outer-layout";
// import useMediaQueries from "src/hooks/use-mediaqueries";
// import InfoTab, { CustomTabPanel, TInfoTab } from "src/pages/info/info-tab";
// import InfoToggleGroup from "src/pages/info/info-toggle-group";

interface InfoTermsProps {}

type Props = InfoTermsProps;

const InfoTerms: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  // const theme = useTheme();
  // const { mdDown } = useMediaQueries();
  // const [view, setView] = useState<TInfoTab>("terms");

  const content = (
    <Paper elevation={10} sx={{ width: "100%" }}>
      <Box sx={{ p: 4 }}>
        <Stack direction="row" alignItems="center" justifyContent={"space-between"} mb={4}>
          <Typography textAlign="left" variant="h4" fontWeight={500} color="primary.main">
            TERMS OF SERVICE
          </Typography>
        </Stack>

        <Stack
          spacing={2}
          alignItems="flex-start"
          textAlign={"left"}
          sx={{
            ol: {
              li: {
                marginBottom: "10px",
              },
            },
            ul: {
              li: {
                "list-style-type": "disc",
                marginBottom: "10px",
              },
            },
          }}
        >
          <Typography variant="h6">Last updated: July 23, 2024</Typography>

          <Typography textAlign="left" variant="h5" fontWeight={600} color="primary.main">
            AGREEMENT TO OUR LEGAL TERMS
          </Typography>

          <Typography variant="body1">
            We are&nbsp;
            <Link href="https://triomine.com/" rel="external nofollow noopener noreferrer" target="_blank">
              Triomine Pte Ltd
            </Link>
            &nbsp;("<b>Company</b>," "<b>we</b>," "<b>us</b>," "<b>our</b>"), a company registered in Singapore.
          </Typography>

          <Typography variant="body1">
            We operate the website&nbsp;
            <Link href="https://gointec.io" rel="external nofollow noopener noreferrer" target="_blank">
              https://gointec.io
            </Link>
            &nbsp;(the "<b>Site</b>"), as well as any other related products and services that refer or link to these legal terms (the "
            <b>Legal Terms</b>") (collectively, the "<b>Services</b>").
          </Typography>
          <Typography variant="body1">A website that lets user track social media accounts statistics</Typography>
          <Typography variant="body1">You can contact us by email at contact@gointec.io.</Typography>
          <Typography variant="body1">
            These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and&nbsp;
            <Link href="https://triomine.com/" rel="external nofollow noopener noreferrer" target="_blank">
              Triomine Pte Ltd
            </Link>
            &nbsp;, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed
            to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
            THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </Typography>
          <Typography variant="body1">
            We will provide you with prior notice of any scheduled changes to the Services you are using. The modified Legal Terms will become
            effective upon posting or notifying you by contact@gointec.io, as stated in the email message. By continuing to use the Services after the
            effective date of any changes, you agree to be bound by the modified terms.
          </Typography>
          <Typography variant="body1">
            The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for
            the Services.
          </Typography>
          <Typography variant="body1">We recommend that you print a copy of these Legal Terms for your records.</Typography>

          <Typography textAlign="left" variant="h5" fontWeight={600} color="primary.main">
            TABLE OF CONTENTS
          </Typography>
          <Box sx={{ ol: { li: { margin: 0 }, margin: 0 } }}>
            <ol>
              <Link href="#our-services">
                <li>OUR SERVICES</li>
              </Link>
              <Link href="#intellectual-property-rights">
                <li>INTELLECTUAL PROPERTY RIGHTS</li>
              </Link>
              <Link href="#user-representations">
                <li>USER REPRESENTATIONS</li>
              </Link>
              <Link href="#user-registration">
                <li>USER REGISTRATION</li>
              </Link>
              <Link href="#purchases-and-payment">
                <li>PURCHASES AND PAYMENT</li>
              </Link>
              <Link href="#subscriptions">
                <li>SUBSCRIPTIONS</li>
              </Link>
              <Link href="#prohibited-activities">
                <li>PROHIBITED ACTIVITES</li>
              </Link>
              <Link href="#user-generated-contributions">
                <li>USER GENERATED CONTRIBUTIONS</li>
              </Link>
              <Link href="#contribution-license">
                <li>CONTRIBUTION LICENSE</li>
              </Link>
              <Link href="#services-management">
                <li>SERVICES MANAGEMENT</li>
              </Link>
              <Link href="#privacy-policy">
                <li>PRIVACY POLICY</li>
              </Link>
              <Link href="#term-and-termination">
                <li>TERM AND TERMINATION</li>
              </Link>
              <Link href="#modifications-and-interruptions">
                <li>MODIFICATIONS AND INTERRUPTIONS</li>
              </Link>
              <Link href="#governing-law">
                <li>GOVERNING LAW</li>
              </Link>
              <Link href="#dispute-resolution">
                <li>DISPUTE RESOLUTION</li>
              </Link>
              <Link href="#corrections">
                <li>CORRECTIONS</li>
              </Link>
              <Link href="#disclaimer">
                <li>DISCLAIMER</li>
              </Link>
              <Link href="#limitations-of-liability">
                <li>LIMITATIONS OF LIABILITY</li>
              </Link>
              <Link href="#indemnification">
                <li>INDEMNIFICATION</li>
              </Link>
              <Link href="#user-data">
                <li>USER DATA</li>
              </Link>
              <Link href="#electronic-communications">
                <li>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
              </Link>
              <Link href="#miscellaneous">
                <li>MISCELLANEOUS</li>
              </Link>
              <Link href="#contact-us">
                <li>CONTACT US</li>
              </Link>
            </ol>
          </Box>

          <Box sx={{ pt: 2, ol: { li: { marginBottom: "30px" } } }}>
            <ol>
              <li>
                <Typography id="our-services" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  OUR SERVICES
                </Typography>
                <Stack spacing={1}>
                  <Typography variant="body1">
                    The information provided when using the Services is not intended for distribution to or use by any person or entity in any
                    jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any
                    registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from
                    other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent
                    local laws are applicable.
                  </Typography>
                </Stack>
              </li>
              <li>
                <Typography id="intellectual-property-rights" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  INTELLECTUAL PROPERTY RIGHTS
                </Typography>
                <Stack spacing={3}>
                  <Typography variant="body1" fontWeight={600} fontSize={18}>
                    Our intellectual property
                  </Typography>
                  <Typography variant="body1">
                    We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases,
                    functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the
                    "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").
                  </Typography>
                  <Typography variant="body1">
                    Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair
                    competition laws) and treaties in the United States and around the world.
                  </Typography>
                  <Typography variant="body1">
                    The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use or internal business
                    purpose only.
                  </Typography>

                  <Typography variant="body1" fontWeight={600} fontSize={18}>
                    Your use of our Services
                  </Typography>
                  <Typography variant="body1">
                    Subject to your compliance with these Legal Terms, including the "<Link href="#prohibited-activities">PROHIBITED ACTIVITIES</Link>
                    " section below, we grant you a non-exclusive, non-transferable, revocable license to:
                  </Typography>

                  <Box sx={{ ul: { li: { margin: 0 } } }}>
                    <ul>
                      <li>access the Services; and</li>
                      <li>download or print a copy of any portion of the Content to which you have properly gained access.</li>
                    </ul>
                  </Box>

                  <Typography variant="body1">
                    Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied,
                    reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold,
                    licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
                  </Typography>
                  <Typography variant="body1">
                    If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal
                    Terms, please address your request to: contact@gointec.io. If we ever grant you the permission to post, reproduce, or publicly
                    display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks
                    and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
                  </Typography>
                  <Typography variant="body1">
                    We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
                  </Typography>
                  <Typography variant="body1">
                    Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our
                    Services will terminate immediately.
                  </Typography>
                  <Typography variant="body1" fontWeight={600} fontSize={18}>
                    Your submissions
                  </Typography>

                  <Typography variant="body1">
                    Please review this section and the "<Link href="#prohibited-activities">PROHIBITED ACTIVITIES</Link>" section carefully prior to
                    using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content
                    through the Services.
                  </Typography>
                  <Typography variant="body1">
                    <b> Submissions:</b> By directly sending us any question, comment, suggestion, idea, feedback, or other information about the
                    Services ("Submissions"), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall
                    own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise,
                    without acknowledgment or compensation to you.
                  </Typography>
                  <Typography variant="body1">
                    <b>You are responsible for what you post or upload:</b> By sending us Submissions through any part of the Services you:
                  </Typography>

                  <Box sx={{ ul: { li: { margin: 0 } } }}>
                    <ul>
                      <li>
                        confirm that you have read and agree with our "<Link href="#prohibited-activities">PROHIBITED ACTIVITIES</Link>" and will not
                        post, send, publish, upload, or transmit through the Services any Submission that is illegal, harassing, hateful, harmful,
                        defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false,
                        inaccurate, deceitful, or misleading;
                      </li>
                      <li>to the extent permissible by applicable law, waive any and all moral rights to any such Submission;</li>
                      <li>
                        warrant that any such Submission are original to you or that you have the necessary rights and licenses to submit such
                        Submissions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions; and
                      </li>
                      <li>warrant and represent that your Submissions do not constitute confidential information.</li>
                    </ul>
                  </Box>

                  <Typography variant="body1">
                    You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all losses that we may suffer
                    because of your breach of (a) this section, (b) any third party's intellectual property rights, or (c) applicable law.
                  </Typography>
                </Stack>
              </li>

              <li>
                <Typography id="user-representations" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  USER REPRESENTATIONS
                </Typography>

                <Typography variant="body1">
                  By using the Services, you represent and warrant that: (1) all registration information you submit will be true, accurate, current,
                  and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary;
                  (3) you have the legal capacity and you agree to comply with these Legal Terms; (4) you are not a minor in the jurisdiction in which
                  you reside; (5) you will not access the Services through automated or non-human means, whether through a bot, script or otherwise;
                  (6) you will not use the Services for any illegal or unauthorized purpose; and (7) your use of the Services will not violate any
                  applicable law or regulation.
                </Typography>
                <Typography variant="body1">
                  If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate
                  your account and refuse any and all current or future use of the Services (or any portion thereof).
                </Typography>
              </li>

              <li>
                <Typography id="user-registration" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  USER REGISTRATION
                </Typography>

                <Typography variant="body1">
                  You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all
                  use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our
                  sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
                </Typography>
              </li>

              <li>
                <Typography id="purchases-and-payment" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  PURCHASES AND PAYMENT
                </Typography>

                <Stack spacing={2}>
                  <Typography variant="body1">We accept the following forms of payment: </Typography>
                  <Stack pl={2} mt={2}>
                    <Typography variant="body1">- Mastercard</Typography>
                    <Typography variant="body1">- Visa</Typography>
                  </Stack>
                  <Typography variant="body1">
                    You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You
                    further agree to promptly update account and payment information, including email address, payment method, and payment card
                    expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of
                    purchases as deemed required by us. We may change prices at any time. All payments shall be in SGD.
                  </Typography>
                  <Typography variant="body1">
                    You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorize
                    us to charge your chosen payment provider for any such amounts upon placing your order. We reserve the right to correct any errors
                    or mistakes in pricing, even if we have already requested or received payment.
                  </Typography>
                  <Typography variant="body1">
                    We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities
                    purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer
                    account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or
                    prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.
                  </Typography>
                </Stack>
              </li>

              <li>
                <Typography id="subscriptions" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  SUBSCRIPTIONS
                </Typography>

                <Stack spacing={2}>
                  <Typography variant="body1" fontWeight={600}>
                    Billing and Renewal
                  </Typography>

                  <Typography variant="body1">
                    Your subscription will continue and automatically renew unless canceled. You consent to our charging your payment method on a
                    recurring basis without requiring your prior approval for each recurring charge, until such time as you cancel the applicable
                    order. The length of your billing cycle will depend on the type of subscription plan you choose when you subscribed to the
                    Services.
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    Free Trial
                  </Typography>

                  <Typography variant="body1">
                    We offer a 21-day free trial to new users who register with the Services. The account will not be charged and the subscription
                    will be suspended until upgraded to a paid version at the end of the free trial.
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    Cancellation
                  </Typography>

                  <Typography variant="body1">
                    You can cancel your subscription at any time by logging into your account. Your cancellation will take effect at the end of the
                    current paid term. If you have any questions or are unsatisfied with our Services, please email us at contact@gointec.io.
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    Fee Changes
                  </Typography>

                  <Typography variant="body1">
                    We may, from time to time, make changes to the subscription fee and will communicate any price changes to you in accordance with
                    applicable law.
                  </Typography>
                </Stack>
              </li>

              <li>
                <Typography id="prohibited-activities" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  PROHIBITED ACTIVITIES
                </Typography>
                <Stack spacing={2}>
                  <Typography variant="body1">
                    You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may
                    not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
                  </Typography>
                  <Typography variant="body1">As a user of the Services, you agree not to: </Typography>
                  <Box sx={{ ul: { li: { marginBottom: "8px" } } }}>
                    <ul>
                      <li>
                        <Typography variant="body1">
                          Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection,
                          compilation, database, or directory without written permission from us.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user
                          passwords.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent
                          or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained
                          therein.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Use any information obtained from the Services in order to harass, abuse, or harm another person.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Make improper use of our support services or submit false reports of abuse or misconduct.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">Use the Services in a manner inconsistent with any applicable laws or regulations.</Typography>
                      </li>

                      <li>
                        <Typography variant="body1">Engage in unauthorized framing of or linking to the Services.</Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use
                          of capital letters and spamming (continuous posting of repetitive text), that interferes with any party's uninterrupted use
                          and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions,
                          operation, or maintenance of the Services.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining,
                          robots, or similar data gathering and extraction tools.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">Delete the copyright or other proprietary rights notice from any Content.</Typography>
                      </li>
                      <li>
                        <Typography variant="body1"> Attempt to impersonate another user or person or use the username of another user.</Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information
                          collection or transmission mechanism, including without limitation, clear graphics interchange formats ("gifs"), 1x1 pixels,
                          web bugs, cookies, or other similar devices (sometimes referred to as "spyware" or "passive collection mechanisms" or
                          "pcms"). Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the
                          Services.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to
                          you.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the
                          Services.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code. Except as
                          permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any
                          way making up a part of the Services.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any
                          automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses
                          the Services, or use or launch any unauthorized script or other software.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Use a buying agent or purchasing agent to make purchases on the Services. • Make any unauthorized use of the Services,
                          including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending
                          unsolicited email, or creating user accounts by automated means or under false pretenses.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any
                          revenue-generating endeavor or commercial enterprise.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Stack>
              </li>
              <li>
                <Typography id="user-generated-contributions" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  USER GENERATED CONTRIBUTIONS
                </Typography>
                <Stack spacing={2}>
                  <Typography variant="body1">
                    The Services does not offer users to submit or post content. We may provide you with the opportunity to create, submit, post,
                    display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Services, including but not
                    limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material
                    (collectively, "Contributions"). Contributions may be viewable by other users of the Services and through third-party websites. As
                    such, any Contributions you transmit may be treated in accordance with the Services' Privacy Policy. When you create or make
                    available any Contributions, you thereby represent and warrant that:
                  </Typography>

                  <Box sx={{ ul: { li: { marginBottom: "8px" } } }}>
                    <ul>
                      <li>
                        <Typography variant="body1">
                          The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your
                          Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent,
                          trademark, trade secret, or moral rights of any third party.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to
                          authorize us, the Services, and other users of the Services to use your Contributions in any manner contemplated by the
                          Services and these Legal Terms.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          You have the written consent, release, and/or permission of each and every identifiable individual person in your
                          Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use
                          of your Contributions in any manner contemplated by the Services and these Legal Terms.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">Your Contributions are not false, inaccurate, or misleading.</Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Your Contributions are not unsolicited or unauthorized advertising, promotional materials. pyramid schemes. chain letters.
                          spam, mass mailings, or other forms of solicitation.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise
                          objectionable (as determined by us).
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote
                          violence against a specific person or class of people.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">Your Contributions do not violate any applicable law, regulation, or rule. </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">Your Contributions do not violate the privacy or publicity rights of any third party.</Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual
                          preference, or physical handicap.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any
                          applicable law or regulation.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Stack>

                <Typography variant="body1">
                  Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, termination
                  or suspension of your rights to use the Services.
                </Typography>
              </li>

              <li>
                <Typography id="contribution-license" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  CONTRIBUTION LICENSE
                </Typography>
                <Stack spacing={2}>
                  <Typography variant="body1">
                    You and Services agree that we may access, store, process, and use any information and personal data that you provide following
                    the terms of the Privacy Policy and your choices (including settings).
                  </Typography>
                  <Typography variant="body1">
                    By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such feedback for any
                    purpose without compensation to you.
                  </Typography>
                  <Typography variant="body1">
                    We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any
                    intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements
                    or representations in your Contributions provided by you in any area on the Services. You are solely responsible for your
                    Contributions to the Services and you expressly agree to exonerate us from any and all responsibility and to refrain from any
                    legal action against us regarding your Contributions.
                  </Typography>
                </Stack>
              </li>
              <li>
                <Typography id="services-management" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  SERVICES MANAGEMENT
                </Typography>
                <Typography variant="body1">
                  We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate
                  legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation,
                  reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to,
                  limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4)
                  in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and
                  content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner
                  designed to protect our rights and property and to facilitate the proper functioning of the Services.
                </Typography>
              </li>

              <li>
                <Typography id="privacy-policy" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  PRIVACY POLICY
                </Typography>
                <Typography variant="body1">
                  We care about data privacy and security. Please review our Privacy Policy:&nbsp;
                  <Link href="https://gointec.io/info/privacy" rel="external nofollow noopener noreferrer" target="_blank">
                    https://gointec.io/info/privacy
                  </Link>
                  . By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be
                  advised the Services are hosted in Singapore. If you access the Services from any other region of the world with laws or other
                  requirements governing personal data collection, use, or disclosure that differ from applicable laws in Singapore, then through your
                  continued use of the Services, you are transferring your data to Singapore, and you expressly consent to have your data transferred
                  to and processed in Singapore.
                </Typography>
              </li>

              <li>
                <Typography id="term-and-termination" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  TERM AND TERMINATION
                </Typography>

                <Stack spacing={2}>
                  <Typography variant="body1">
                    These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
                    LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
                    SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION
                    FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE
                    MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
                    ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                  </Typography>

                  <Typography variant="body1">
                    If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your
                    name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition
                    to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation
                    pursuing civil, criminal, and injunctive redress.
                  </Typography>
                </Stack>
              </li>

              <li>
                <Typography id="modifications-and-interruptions" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  MODIFICATIONS AND INTERRUPTIONS
                </Typography>
                <Stack spacing={2}>
                  <Typography variant="body1">
                    We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole
                    discretion without notice. However, we have no obligation to update any information on our Services. We will not be liable to you
                    or any third party for any modification, price change, suspension, or discontinuance of the Services.
                  </Typography>
                  <Typography variant="body1">
                    We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need
                    to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change,
                    revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You
                    agree that we have no liability whatsoever for any loss damage or inconvenience caused by your inability to access or use the
                    services auring any downtime or discontinuance of the services. Nothing in these Legal Terms will be construed to obligate us to
                    maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.
                  </Typography>
                </Stack>
              </li>
              <li>
                <Typography id="governing-law" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  GOVERNING LAW
                </Typography>

                <Typography variant="body1">
                  These Legal Terms shall be governed by and defined following the laws of Singapore.&nbsp;
                  <Link href="https://triomine.com/" rel="external nofollow noopener noreferrer" target="_blank">
                    Triomine Pte Ltd
                  </Link>
                  &nbsp;and yourself irrevocably consent that the courts of Singapore shall have exclusive jurisdiction to resolve any dispute which
                  may arise in connection with these Legal Terms.
                </Typography>
              </li>

              <li>
                <Typography id="dispute-resolution" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  DISPUTE RESOLUTION
                </Typography>

                <Stack spacing={2}>
                  <Typography variant="body1" fontWeight={600}>
                    Binding Arbitration
                  </Typography>
                  <Typography variant="body1">
                    Any dispute arising out of or in connection with these Legal Terms, including any question regarding its existence, validity, or
                    termination, shall be referred to and finally resolved by the International Commercial Arbitration Court under the European
                    Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules of this ICAC, which, as a result of referring
                    to it, is considered as the part of this clause. The number of arbitrators shall be three (3). The seat, or legal place, or
                    arbitration shall be Singapore, Singapore. The language of the proceedings shall be English. The governing law of these Legal
                    Terms shall be substantive law of Singapore.
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    Restrictions
                  </Typography>
                  <Typography variant="body1">
                    The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent
                    permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute
                    to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any
                    Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    Exceptions to Arbitration
                  </Typography>
                  <Typography variant="body1">
                    The Parties agree that the following Disputes are not subject to the above provisions concerning binding arbitration: (a) any
                    Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any
                    Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for
                    injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any
                    Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a
                    court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal
                    jurisdiction of that court.
                  </Typography>
                </Stack>
              </li>

              <li>
                <Typography id="corrections" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  CORRECTIONS
                </Typography>

                <Typography variant="body1">
                  There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions,
                  pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to
                  change or update the information on the Services at any time, without prior notice.
                </Typography>
              </li>

              <li>
                <Typography id="disclaimer" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  DISCLAIMER
                </Typography>

                <Typography variant="body1">
                  THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
                  THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
                  THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-
                  INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
                  ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY OR ANY (1) ERRORS,
                  MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
                  YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                  INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,
                  (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6)
                  ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
                  CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                  RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR
                  ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
                  RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
                  OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
                  APPROPRIATE.
                </Typography>
              </li>
              <li>
                <Typography id="limitations-of-liability" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  LIMITATIONS OF LIABILITY
                </Typography>

                <Typography variant="body1">
                  IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                  CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
                  DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
                  ANYTHING TO THE CONTRARY CONTAINED HEREIN, QUID LIABILITY TO VOLLFOR ANY CALISE WHATSOEVER AND DECARDLESS OF THE FORM OF THE ACTION,
                  WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
                  ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
                  OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU
                  MAY HAVE ADDITIONAL RIGHTS.
                </Typography>
              </li>
              <li>
                <Typography id="indemnification" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  INDEMNIFICATION
                </Typography>

                <Typography variant="body1">
                  You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers,
                  agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys'
                  fees and expenses, made by any third party due to or arising out of: (1) use of the Services; (2) breach of these Legal Terms; (3)
                  any breach of your representations and warranties set forth in these Legal Terms; (4) your violation of the rights of a third party,
                  including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Services with
                  whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive
                  defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our
                  defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to
                  this indemnification upon becoming aware of it.
                </Typography>
              </li>
              <li>
                <Typography id="user-data" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  USER DATA
                </Typography>

                <Typography variant="body1">
                  We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well
                  as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for
                  all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no
                  liability to you for against us arising from any such loss or corruption of such data.
                </Typography>
              </li>
              <li>
                <Typography id="electronic-communications" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                </Typography>

                <Typography variant="body1">
                  Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive
                  electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you
                  electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE
                  TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
                  RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any
                  statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or
                  retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
                </Typography>
              </li>
              <li>
                <Typography id="miscellaneous" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  MISCELLANEOUS
                </Typography>

                <Typography variant="body1">
                  These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the
                  entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal
                  Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law.
                  We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss,
                  damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these
                  Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from
                  these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture,
                  partnership, employment or agency relationship created between you and us as a result of these Legal Terms or use of the Services.
                  You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all
                  defenses you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute
                  these Legal Terms.
                </Typography>
              </li>
              <li>
                <Typography id="contact-us" textAlign="left" variant="h6" fontWeight={600} color="primary.main" mb={2}>
                  CONTACT US
                </Typography>
                <Stack spacing={2}>
                  <Typography variant="body1">
                    In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please
                    contact us at:
                  </Typography>
                  <Stack>
                    <Link href="https://triomine.com/" rel="external nofollow noopener noreferrer" target="_blank">
                      <Typography variant="body1">Triomine Pte Ltd</Typography>
                    </Link>

                    <Typography variant="body1">Email: contact@gointec.io</Typography>
                  </Stack>
                </Stack>
              </li>
            </ol>
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <OuterLayout maxWidth={"lg"}>
      <GoToTopButton />
      {content}
      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={2.5}>
          {!mdDown && (
            <Paper elevation={10} sx={{ py: 2 }}>
              <Stack spacing={2} alignItems="center" justifyContent={"space-between"} sx={{ py: 1, px: 2 }}>
                <Box sx={{ mt: 2, width: "100%" }}>
                  <InfoToggleGroup view={view} setView={setView} />
                </Box>
              </Stack>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={9.5}>
          {mdDown && <InfoTab tab={view} />}
          {mdDown ? (
            <>
              <CustomTabPanel theme={theme} value={view} target={"terms"}>
                {content}
              </CustomTabPanel>
            </>
          ) : (
            <>{view === "terms" && content}</>
          )}
        </Grid>
      </Grid> */}
    </OuterLayout>
  );
};

export default InfoTerms;
