const BUTTON_ADD = { key: "common:button.add", backup: "Add" };
const BUTTON_BACK = { key: "common:button.back", backup: "Back" };
const BUTTON_START = { key: "common:button.start", backup: "Start" };
const BUTTON_STOP = { key: "common:button.stop", backup: "Stop" };
const BUTTON_NEXT = { key: "common:button.next", backup: "Next" };
const BUTTON_DELETE = { key: "common:button.delete", backup: "Delete" };
const BUTTON_SUBMIT = { key: "common:button.submit", backup: "Submit" };
const BUTTON_BACK_HOME = { key: "common:button.home", backup: "Back To Home" };
const BUTTON_RESET = { key: "common:button.reset", backup: "Reset" };
const BUTTON_FILTER = { key: "common:button.filter", backup: "Filter" };
const BUTTON_CANCEL = { key: "common:button.cancel", backup: "Cancel" };
const BUTTON_CONFIRM = { key: "common:button.confirm", backup: "Confirm" };
const BUTTON_SAVE = { key: "common:button.save", backup: "Save" };
const BUTTON_SENDING = { key: "common:button.sending", backup: "Sending" };
const BUTTON_REMOVE = { key: "common:button.remove", backup: "Remove" };
const BUTTON_REPLACE = { key: "common:button.replace", backup: "Replace" };
const BUTTON_APPLY = { key: "common:button.apply", backup: "Apply" };
const BUTTON_APPLY_TEMPLATE = {
  key: "common:button.apply_template",
  backup: "Apply Template",
};
const BUTTON_DONE_EDITING = {
  key: "common:button.done_editing",
  backup: "Done Editing",
};
const BUTTON_UPLOAD = { key: "common:button.upload", backup: "Upload" };
const BUTTON_CLOSE = { key: "common:button.close", backup: "Close" };
const BUTTON_GENERATE = { key: "common:button.generate", backup: "Generate" };
const BUTTON_LOGIN = { key: "common:button.login", backup: "Login" };
const BUTTON_CLEAR = { key: "common:button.clear", backup: "Clear" };
const BUTTON_CLEAR_ALL = {
  key: "common:button.clear_all",
  backup: "Clear All",
};
const BUTTON_REMOVE_ALL = {
  key: "common:button.remove_all",
  backup: "Remove All",
};
const BUTTON_UPDATE_STATUS = {
  key: "common:button.update_status",
  backup: "Update Status",
};
const BUTTON_CONTNUE = { key: "common:button.continue", backup: "Continue" };
const BUTTON_CLEAR_FILTERS = { key: "common:button.clear_filters", backup: "Clear Filters" };
const BUTTON_CLEAR_SORTING = { key: "common:button.clear_sorting", backup: "Clear Sorting" };

const FORM_TITLE = { key: "common:form.label_title", backup: "Title" };
const FORM_JOB_TITLE = {
  key: "common:form.label_job_title",
  backup: "Job Title",
};
const FORM_DESCRIPTION = {
  key: "common:form.label_description",
  backup: "Description",
};
const FORM_NAME = { key: "common:form.label_name", backup: "Name" };
const FORM_NAMING = { key: "common:form.label_naming", backup: "Name" };
const FORM_ABBR = {
  key: "common:form.label_abbr",
  backup: "Unit Abbreviation",
};
const FORM_EMAIL = { key: "common:form.label_email", backup: "Email" };
const FORM_CONTACT = {
  key: "common:form.label_contact",
  backup: "Contact No.",
};
const FORM_ADDRESS = { key: "common:form.label_address", backup: "Address" };
const FORM_ADDRESS_1 = {
  key: "common:form.label_address_1",
  backup: "Address line 1",
};
const FORM_ADDRESS_2 = {
  key: "common:form.label_address_2",
  backup: "Address line 2",
};
const FORM_STATE = { key: "common:form.label_state", backup: "State" };
const FORM_POSTAL = { key: "common:form.label_postal", backup: "Postal Code" };
const FORM_COUNTRY = { key: "common:form.label_country", backup: "Country" };
const FORM_START_DATE = {
  key: "common:form.label_start_date",
  backup: "Start Date",
};
const FORM_END_DATE = { key: "common:form.label_end_date", backup: "End Date" };
const FORM_CONFIRM_DATE = {
  key: "common:form.label_confirm_date",
  backup: "Confirm Date",
};
const FORM_REF_NO = { key: "common:form.label_ref_no", backup: "Ref. No." };
const FORM_EXCLUDE_STATUS = {
  key: "common:form.label_exclude_status",
  backup: "Exclude Status",
};
const FORM_PRIORITY = { key: "common:form.label_priority", backup: "Priority" };
const FORM_CATEGORY = { key: "common:form.label_category", backup: "Category" };
const FORM_DEPARTMENT = {
  key: "common:form.label_department",
  backup: "Department",
};
const FORM_DURATION = { key: "common:form.label_duration", backup: "Duration" };
const FORM_FIELD_AMOUNT = {
  key: "common:form.label_field_amount",
  backup: "Field Amount",
};
const FORM_USERNAME = { key: "common:form.label_username", backup: "Username" };
const FORM_PASSWORD = { key: "common:form.label_password", backup: "Password" };
const FORM_CURRENT_PASSWORD = {
  key: "common:form.label_current_password",
  backup: "Current Password",
};
const FORM_NEW_PASSWORD = {
  key: "common:form.label_new_password",
  backup: "New Password",
};
const FORM_CONFIRM_NEW_PASSWORD = {
  key: "common:form.label_confirm_new_password",
  backup: "Confirm New Password",
};

const FORM_GENDER = { key: "common:form.gender.title", backup: "Gender" };
const FORM_MALE = { key: "common:form.gender.male", backup: "Male" };
const FORM_FEMALE = { key: "common:form.gender.female", backup: "Female" };

const FORM_WARNING_REQUIRED = {
  key: "common:form.warning.required",
  backup: "This is required.",
};
const FORM_WARNING_MAX_NUM = {
  key: "common:form.warning.max_num",
  backup: "Maximum of {{subject}} characters allowed.",
};
const FORM_WARNING_MIN_NUM = {
  key: "common:form.warning.min_num",
  backup: "Must be minimum of {{subject}}.",
};
const FORM_WARNING_VALID_EMAIL = {
  key: "common:form.warning.valid_email",
  backup: "Must be a valid email.",
};
const FORM_WARNING_VALID_PHONE = {
  key: "common:form.warning.valid_phone",
  backup: "Must be a valid phone number.",
};
const FORM_WARNING_VALID_POSTCODE = {
  key: "common:form.warning.valid_postcode",
  backup: "Must be a valid postal code.",
};
const FORM_WARNING_PASSWORD_NOT_MET = {
  key: "common:form.warning.password_not_met",
  backup: "Password requirement is not met",
};
const FORM_WARNING_PASSWORD_MUST_MATCH = {
  key: "common:form.warning.password_must_match",
  backup: "Passwords must match each other",
};
const FORM_WARNING_TWO_OPTIONS = {
  key: "common:form.warning.two_options",
  backup: "At least 2 options are required.",
};
const FORM_WARNING_OPTIONS_REQUIRED = {
  key: "common:form.warning.options_required",
  backup: "Options are required.",
};
const FORM_ERROR = {
  key: "common:form.warning.error",
  backup: "Please check your form, something is wrong with the submission",
};
const FORM_TYPE_PLACEHOLDER = {
  key: "common:form.placeholder.type",
  backup: "Type {{subject}} here.",
};

const UPLOAD_IMAGE_TITLE = {
  key: "common:upload.image.title",
  backup: "Image",
};
const UPLOAD_IMAGE_REMOVE = {
  key: "common:upload.image.remove_img",
  backup: "Remove Image",
};
const UPLOAD_IMAGE_DND_IMG = {
  key: "common:upload.image.dnd_img",
  backup: "Drag 'n' drop an image here, or click to select image",
};
const UPLOAD_IMAGE_DND_IMGS = {
  key: "common:upload.image.dnd_imgs",
  backup: "Drag 'n' drop some images here, or click to select images",
};
const UPLOAD_IMAGE_SELECT_IMG = {
  key: "common:upload.image.select_img",
  backup: "Click to select an image from device",
};
const UPLOAD_IMAGE_SELECT_IMGS = {
  key: "common:upload.image.select_imgs",
  backup: "Click to select images from device",
};
const UPLOAD_IMAGE_DROP_IMG = {
  key: "common:upload.image.drop_img",
  backup: "Drop image here",
};
const UPLOAD_IMAGE_DELETE_IMG = {
  key: "common:upload.image.delete_img",
  backup: "Delete this image",
};
const UPLOAD_IMAGE_SIZE_WARNING = {
  key: "common:upload.image.warning",
  backup: "Image is larger than",
};
const UPLOAD_IMAGE_NOT_FOUND = {
  key: "common:upload.image.not_found",
  backup: "No Image Found",
};
const UPLOAD_ATTACHMENTS = {
  key: "common:upload.attachments.title",
  backup: "Attachments",
};
const UPLOAD_ATTACHMENTS_NOT_FOUND = {
  key: "common:upload.attachments.not_found",
  backup: "No Attachment Found",
};
const UPLOAD_ATTACHMENTS_DND_FILE = {
  key: "common:upload.attachments.dnd_file",
  backup: "Drag 'n' drop aa file here, or click to select a file",
};
const UPLOAD_ATTACHMENTS_DND_FILES = {
  key: "common:upload.attachments.dnd_files",
  backup: "No Drag 'n' drop some files here, or click to select files",
};

const NAVBAR_WELCOME = { key: "common:navbar.welcome", backup: "Welcome" };
const NAVBAR_ACCOUNT = { key: "common:navbar.account", backup: "Account" };
const NAVBAR_LOGOUT = { key: "common:navbar.logout", backup: "Logout" };
const NAVBAR_DASHBOARD = {
  key: "common:navbar.dashboard",
  backup: "Dashboard",
};
const NAVBAR_WORK_ORDER_CATEGORIES = {
  key: "common:navbar.work_order_categories",
  backup: "Work Order Categories",
};
const NAVBAR_WORK_ORDER_SCHEDULES = {
  key: "common:navbar.work_order_schedules",
  backup: "Work Order Schedules",
};

const SIDEBAR_MY_WORK_ORDERS = {
  key: "common:sidebar.my_work_orders",
  backup: "My Work Orders",
};
const SIDEBAR_OVERVIEW = { key: "common:sidebar.overview", backup: "Overview" };
const SIDEBAR_BED = { key: "common:sidebar.bed", backup: "Beds Management" };
const SIDEBAR_CONTACT = { key: "common:sidebar.contact", backup: "Contacts" };
const SIDEBAR_ATTENDANCE = {
  key: "common:sidebar.attendance",
  backup: "Attendance",
};

const TABLE_HEADER_ENTITY = {
  key: "common:table_header.entity",
  backup: "Entity",
};
const TABLE_HEADER_ACTIONS = {
  key: "common:table_header.actions",
  backup: "Actions",
};

const TREE_ADD_ROOT = {
  key: "common:tree.add_root",
  backup: "Add Root {{subject}}",
};
const TREE_ADD_CHILD = {
  key: "common:tree.add_child",
  backup: "Add Child {{subject}}",
};
const TREE_CREATE_ROOT = {
  key: "common:tree.create_root",
  backup: "Create Root {{subject}}",
};
const TREE_CREATE_CHILD = {
  key: "common:tree.create_child",
  backup: "Create Child {{subject}}",
};
const TREE_PARENT = { key: "common:tree.parent", backup: "Parent {{subject}}" };
const TREE_DELETE_DESCENDANTS = {
  key: "common:tree.delete_descendants",
  backup: "Are you sure you want to delete this {{subject}} and its descendants? This action is irreversible.",
};

const TOAST_ERROR_WENT_WRONG = {
  key: "common:toast.error.went_wrong",
  backup: "Something went wrong",
};
const TOAST_ERROR_WENT_WRONG_WHEN = {
  key: "common:toast.error.went_wrong_when",
  backup: "Something went wrong when {{action}}",
};
const TOAST_ERROR_ACTION_CREATING = {
  key: "common:toast.error.action_creating",
  backup: "creating {{subject}}",
};
const TOAST_ERROR_ACTION_EDITING = {
  key: "common:toast.error.action_editing",
  backup: "editing {{subject}}",
};
const TOAST_ERROR_ACTION_DELETING = {
  key: "common:toast.error.action_deleting",
  backup: "deleting {{subject}}",
};
const TOAST_ERROR_ACTION_ADDING = {
  key: "common:toast.error.action_adding",
  backup: "adding {{subject}}",
};
const TOAST_ERROR_ACTION_SORTING = {
  key: "common:toast.error.action_sorting",
  backup: "sorting {{subject}}",
};
const TOAST_ERROR_ACTION_DUPLICATING = {
  key: "common:toast.error.action_duplicating",
  backup: "duplicating {{subject}}",
};
const TOAST_ERROR_ACTION_UPDATING = {
  key: "common:toast.error.action_updating",
  backup: "updating {{subject}}",
};
const TOAST_ERROR_ACTION_REMOVING = {
  key: "common:toast.error.action_removing",
  backup: "removing {{subject}}",
};
const MARK_NOTIFICATION_AS_READ = {
  key: "common:toast.error.marking_notification_as_read",
  backup: "marking notification as read",
};
const MARK_ALL_NOTIFICATION_AS_READ = {
  key: "common:toast.error.marking_all_notifications_as_read",
  backup: "marking all notifications as read",
};
const ASSIGN_TEMPLATE_WORK_ORDER = {
  key: "common:toast.error.assign_template_work_order",
  backup: "assigning template to work order",
};
const ADD_ASSET_TO_WORK_ORDER = {
  key: "common:toast.error.add_asset_to_work_order",
  backup: "adding asset to work order",
};
const REMOVE_ASSET_FROM_WORK_ORDER = {
  key: "common:toast.error.remove_asset_from_work_order",
  backup: "removing asset from work order",
};
const SUPPORT_GEOLOCATION = {
  key: "common:toast.error.support_geolocation",
  backup: "Sorry, your browser does not support HTML5 geolocation",
};
const ENABLE_LOCATION = {
  key: "common:toast.error.enable_location",
  backup: "To change job status, please enable location service information",
};
const SUBMIT_REQUEST = {
  key: "common:toast.error.submit_request",
  backup: "submitting your request",
};
const SAME_AS_PARENT = {
  key: "common:toast.error.same_as_parent",
  backup: "You cannot select same {{subject}} to be parent {{subject}}",
};

const TOAST_SUCCESS_ACTION_SUCCESSFULLY = {
  key: "common:toast.success.action_successfully",
  backup: "{{subject}} successfully",
};
const TOAST_SUCCESS_ACTION_UPDATED = {
  key: "common:toast.success.action_updated",
  backup: "{{subject}} updated",
};
const TOAST_SUCCESS_ACTION_DELETED = {
  key: "common:toast.success.action_deleted",
  backup: "{{subject}} deleted",
};
const TOAST_SUCCESS_ACTION_CREATED = {
  key: "common:toast.success.action_created",
  backup: "{{subject}} created",
};
const TOAST_SUCCESS_ACTION_REMOVED = {
  key: "common:toast.success.action_removed",
  backup: "{{subject}} removed",
};
const TOAST_SUCCESS_ACTION_REORDERED = {
  key: "common:toast.success.action_reordered",
  backup: "{{subject}} reordered",
};
const TOAST_SUCCESS_ACTION_DUPLICATED = {
  key: "common:toast.success.action_duplicated",
  backup: "{{subject}} duplicated",
};
const TOAST_SUCCESS_ACTION_SUBMITTED = {
  key: "common:toast.success.action_submitted",
  backup: "{{subject}} submitted",
};
const TOAST_SUCCESS_ACTION_APPLIED = {
  key: "common:toast.success.action_applied",
  backup: "{{subject}} applied",
};
const TOAST_SUCCESS_ACTION_RESUMED = {
  key: "common:toast.success.action_resumed",
  backup: "{{subject}} resumed",
};
const TOAST_SUCCESS_ACTION_ADDED = {
  key: "common:toast.success.action_added",
  backup: "{{subject}} added",
};
const TOAST_SUCCESS_ACTION_EDITED = {
  key: "common:toast.success.action_edited",
  backup: "{{subject}} edited",
};
const TOAST_SUCCESS_ACTION_UPLOADED = {
  key: "common:toast.success.action_uploaded",
  backup: "{{subject}} uploaded",
};
const TOAST_SUCCESS_ACTION_ADDED_TO = {
  key: "common:toast.success.action_added_to",
  backup: "{{child}} added to {{parent}}",
};
const TOAST_SUCCESS_ACTION_REMOVED_FROM = {
  key: "common:toast.success.action_removed_from",
  backup: "{{child}} removed from {{parent}}",
};

const PROMPT_WORK_ORDER_DUPLICATE_CONFIRM_TITLE = {
  key: "common:prompt.work_order.duplicate_confirmation.title",
  backup: "Duplicate Confirmation",
};
const PROMPT_WORK_ORDER_DUPLICATE_CONFIRM_CONTENT = {
  key: "common:prompt.work_order.duplicate_confirmation.content",
  backup: "Are you sure you want to duplicate this work order?",
};
const PROMPT_WORK_ORDER_GENERATION_CONFIRM_TITLE = {
  key: "common:prompt.work_order.generation_confirmation.title",
  backup: "Pengesahan Penjanaan",
};
const PROMPT_WORK_ORDER_GENERATION_CONFIRM_CONTENT = {
  key: "common:prompt.work_order.generation_confirmation.content",
  backup: "Adakah anda pasti ingin menjana pesanan kerja menggunakan jadual ini?",
};

const PROMPT_DELETE_CONFIRMATION = {
  key: "common:prompt:delete.title",
  backup: "Delete Confirmation",
};
const PROMPT_DELETE_CONFIRMATION_CONTENT = {
  key: "common:prompt:delete.content",
  backup: "Are you sure you want to delete this {{subject}}? This action is irreversible.",
};

const ACTION_ADD = { key: "common:action.add", backup: "Add {{subject}}" };
const ACTION_CREATE = {
  key: "common:action.create",
  backup: "Create {{subject}}",
};
const ACTION_DELETE = {
  key: "common:action.delete",
  backup: "Delete {{subject}}",
};
const ACTION_EDIT = { key: "common:action.edit", backup: "Edit {{subject}}" };
const ACTION_SELECT = {
  key: "common:action.select",
  backup: "Select {{subject}}",
};
const ACTION_CANCEL = {
  key: "common:action.cancel",
  backup: "Cancel {{subject}}",
};
const ACTION_DUPLICATE = {
  key: "common:action.duplicate",
  backup: "Duplicate {{subject}}",
};
const ACTION_EXPORT = {
  key: "common:action.export",
  backup: "Duplicate {{subject}}",
};

const SUBJECT_LOYALTY = {
  key: "common:action.subject.loyalty",
  backup: "Loyalty Program",
};
const SUBJECT_OUTLET = {
  key: "common:action.subject.outlet",
  backup: "Outlet",
};
const SUBJECT_CONFIGURATION = {
  key: "common:action.subject.configuration",
  backup: "Configuration",
};
const SUBJECT_MEMBER = {
  key: "common:action.subject.member",
  backup: "Member",
};

const SEARCH = { key: "common:search.title", backup: "Search" };
const SEARCH_PLACEHOLDER = {
  key: "common:search.placeholder",
  backup: "Search {{subject}}",
};

const PASSWORD_TITLE = { key: "common:password.title", backup: "Password" };
const PASSWORD_REQUIREMENT = {
  key: "common:password.requirement",
  backup: "Password must match the following requirements:",
};
const PASSWORD_EIGHT_CHAR = {
  key: "common:password.eight_char",
  backup: "At least 8 characters",
};
const PASSWORD_ONE_UPPER = {
  key: "common:password.one_upper",
  backup: "At least one uppercase character",
};
const PASSWORD_ONE_NUMBER = {
  key: "common:password.one_number",
  backup: "At least one number character",
};

const NOTIFICATIONS = {
  key: "common:notifications.title",
  backup: "Notifications",
};
const NOTIFICATIONS_MARK_READ = {
  key: "common:notifications.mark_read",
  backup: "Mark All As Read",
};
const NOTIFICATIONS_VIEW_ALL = {
  key: "common:notifications.view_all_button",
  backup: "View All",
};
const NO_NOTIFICATIONS = {
  key: "common:notifications.no_notifications",
  backup: "There are no notifications",
};

const REQUEST_LINK_CLICK = {
  key: "common:request_link.click_here",
  backup: "Click here",
};
const REQUEST_LINK_SUBMIT = {
  key: "common:request_link.submit",
  backup: " to submit work request",
};

const BED_STATUS_TOTAL = { key: "common:bed.status.total", backup: "Total" };
const BED_STATUS_IN_USE = { key: "common:bed.status.in_use", backup: "In Use" };
const BED_STATUS_RESERVED = {
  key: "common:bed.status.reserved",
  backup: "Reserved",
};
const BED_STATUS_EMPTY = { key: "common:bed.status.empty", backup: "Empty" };
const BED_STATUS_CLEAN_UP = {
  key: "common:bed.status.clean_up",
  backup: "Clean Up",
};
const BED_STATUS_OTHER = { key: "common:bed.status.other", backup: "Other" };

const DASHBOARD_EMPLOYEE_PLACEHOLDER = {
  key: "common:dashboard.employee_placeholder",
  backup: "Type at least 3 characters",
};
const DASHBOARD_OVERDUE = {
  key: "common:dashboard.active_workorder_status.overdue",
  backup: "Overdue",
};
const DASHBOARD_TODAY = {
  key: "common:dashboard.active_workorder_status.today",
  backup: "Today ({{subject}})",
};
const DASHBOARD_NEXT7 = {
  key: "common:dashboard.active_workorder_status.next_7",
  backup: "Next 7 Days (until {{subject}})",
};
const DASHBOARD_UNASSIGNED = {
  key: "common:dashboard.active_workorder_status.unassigned",
  backup: "Unassigned",
};
const DASHBOARD_TITLE_BED = {
  key: "common:dashboard.title.bed_usage",
  backup: "Bed Usage",
};
const DASHBOARD_EMPLOYEES_PERIOD = {
  key: "common:dashboard.employees_period.period",
  backup: "Period",
};
const DASHBOARD_EMPLOYEES_PERIOD_TODAY = {
  key: "common:dashboard.employees_period.today",
  backup: "Today",
};
const DASHBOARD_EMPLOYEES_PERIOD_LAST_WEEK = {
  key: "common:dashboard.employees_period.last_week",
  backup: "Last Week",
};
const DASHBOARD_EMPLOYEES_PERIOD_THIS_MONTH = {
  key: "common:dashboard.employees_period.this_month",
  backup: "This Month",
};
const DASHBOARD_EMPLOYEES_PERIOD_LAST_MONTH = {
  key: "common:dashboard.employees_period.last_month",
  backup: "Last Month",
};

const ASSET_SOURCE_DOCUMENT = {
  key: "common:asset.source_document",
  backup: "Source Document",
};
const ASSET_PRODUCT_LINE = {
  key: "common:asset.product_line",
  backup: "Product Line",
};
const ASSET_VEHICLE_LIST = {
  key: "common:asset.vehicle_list",
  backup: "Vehicle List",
};
const ASSET_EQUIPMENT_LIST = {
  key: "common:asset.equipment_list",
  backup: "Equipment List",
};
const ASSET_PRODUCT_CATEGORY = {
  key: "common:asset.product_category",
  backup: "Product Category",
};
const ASSET_PRODUCT = { key: "common:asset.product", backup: "Product" };
const ASSET_SERIAL_NUMBER = { key: "common:asset.sn", backup: "S/N" };
const ASSET_QUANTITY = { key: "common:asset.quantity", backup: "Quantity" };
const ASSET_EQUIPMENT_RENTAL = {
  key: "common:asset.equipment_rental",
  backup: "Equipment Rental",
};
const ASSET_VEHICLE_RENTAL = {
  key: "common:asset.vehicle_rental",
  backup: "Vehicle Rental",
};
const ASSET_FLEET = { key: "common:asset.fleet", backup: "Fleet" };
const ASSET_LICENSE_PLATE = {
  key: "common:asset.license_plate",
  backup: "License Plate",
};
const ASSET_VARIANT = { key: "common:asset.variant", backup: "Variant" };
const ASSET_OPTION_NAME = {
  key: "common:asset.option_name",
  backup: "Option Name",
};
const ASSET_OPTION_VALUES = {
  key: "common:asset.option_values",
  backup: "Option Values",
};
const ASSET_MODIFY_VARIANT = {
  key: "common:asset.modify_variant_values",
  backup: "Modify Variant Values",
};
const ASSET_PRESS_ENTER = {
  key: "common:asset.press_enter",
  backup: "Press enter to add variant",
};
const ASSET_ADD_VARIANTS = {
  key: "common:asset.add_variants",
  backup: "Press enter to add variant",
};

const LATITUDE = { key: "common:attendance.latitude", backup: "Latitude" };
const LONGITUDE = { key: "common:attendance.longitude", backup: "Longitude" };
const ATTENDANCE_LOGS = {
  key: "common:attendance.attendance_logs",
  backup: "Attendance Logs",
};
const TIMESTAMP = { key: "common:attendance.timestamp", backup: "Timestamp" };
const CHECK_IN = { key: "common:attendance.check_in", backup: "Check In" };
const CHECK_IN_SUCCESSFUL = {
  key: "common:attendance.check_in_successful",
  backup: "Checked in at <1>{{time}}</1>",
};
const ENABLE_REFRESH_PAGE = {
  key: "common:attendance.enable_refresh_page",
  backup: "Enable and refresh the page",
};

const PERMISSION = { key: "common:permission.title", backup: "Permission" };
const PERMISSION_ACCESS_DENIED = { key: "common:permission.access_denied", backup: "Access Denied" };
const NO_PERMISSION = { key: "common:permission.no_permission", backup: "You have no permission to access this page" };

const MY = { key: "common:my", backup: "My {{subject}}" };
const UPDATE = { key: "common:update", backup: "Update" };
const NO_DETAIL = { key: "common:no_detail", backup: "No Detail" };
const GENERAL = { key: "common:general", backup: "General" };
const REQUEST = { key: "common:request", backup: "Request" };
const NETWORK_ERROR = { key: "common:network_error", backup: "Network Error" };
const FILE = { key: "common:file", backup: "File" };
const USER_DETAILS = { key: "common:user_details", backup: "User Details" };
const VIEW = { key: "common:view", backup: "View" };
const LOAD_MORE = { key: "common:load_more", backup: "Load More" };
const DAYS = { key: "common:days", backup: "Days" };
const NOTES = { key: "common:notes", backup: "Notes" };
const ACTIVE = { key: "common:active", backup: "Active" };
const INACTIVE = { key: "common:inactive", backup: "Inactive" };
const EXPIRED = { key: "common:expired", backup: "Expired" };
const DEFAULT = { key: "common:default", backup: "Default" };
const TYPE = { key: "common:type", backup: "Type" };
const YES = { key: "common:yes", backup: "Yes" };
const NO = { key: "common:no", backup: "No" };
const NA = { key: "common:na", backup: "N/A" };
const DETAILS = { key: "common:details", backup: "Details" };
const LOADING = { key: "common:loading", backup: "Loading" };
const SORT = { key: "common:sort", backup: "Sort" };
const FILTER = { key: "common:filter", backup: "Filter" };
const EDIT = { key: "common:edit", backup: "Edit" };
const SELECT = { key: "common:select", backup: "Select" };
const CREATE = { key: "common:create", backup: "Create" };
const DUPLICATE = { key: "common:duplicate", backup: "Duplicate" };
const CUSTOMER = { key: "common:customer", backup: "Customer" };
const VENDOR = { key: "common:vendor", backup: "Vendor" };
const NO_DATA_FOUND = { key: "common:no_data_found", backup: "No Data Found" };
const SCROLL_MORE = { key: "common:scroll_more", backup: "Scroll to see more" };
const END_OF_LIST = { key: "common:end_of_list", backup: "End of list" };
const BASIC_DETAILS = { key: "common:basic_details", backup: "Basic Details" };
const LOCATION_NAME = { key: "common:location_name", backup: "Location Name" };
const SET_AS_DEFAULT = {
  key: "common:set_as_default",
  backup: "Set as default",
};
const CHANGE_PASSWORD = {
  key: "common:change_password",
  backup: "Change Password",
};
const SAME_AS_PARENT_ADDRESS = {
  key: "common:same_as_parent_address",
  backup: "Same as parent address",
};
const PARENT_LOCATION = {
  key: "common:parent_location",
  backup: "Parent Location",
};
const CALENDAR_VIEW = { key: "common:calendar_view", backup: "Calendar View" };
const LISTING_VIEW = { key: "common:listing_view", backup: "Listing View" };
const TOMORROW = { key: "common:tomorrow", backup: "Tomorrow" };
const FUTURE = { key: "common:future", backup: "Future" };
const REMEMBER_ME = {
  key: "common:REMEMBER_ME",
  backup: "Remember me for 30 days.",
};
const SHOW = { key: "common:SHOW", backup: "Show" };
const HIDE = { key: "common:HIDE", backup: "Hide" };

export {
  BUTTON_ADD,
  BUTTON_BACK,
  BUTTON_START,
  BUTTON_STOP,
  BUTTON_NEXT,
  BUTTON_SUBMIT,
  BUTTON_DELETE,
  BUTTON_BACK_HOME,
  BUTTON_RESET,
  BUTTON_FILTER,
  BUTTON_CANCEL,
  BUTTON_CONFIRM,
  BUTTON_SAVE,
  BUTTON_SENDING,
  BUTTON_REMOVE,
  BUTTON_REPLACE,
  BUTTON_APPLY,
  BUTTON_APPLY_TEMPLATE,
  BUTTON_DONE_EDITING,
  BUTTON_UPLOAD,
  BUTTON_CLOSE,
  BUTTON_GENERATE,
  BUTTON_LOGIN,
  BUTTON_CLEAR,
  BUTTON_CLEAR_ALL,
  BUTTON_REMOVE_ALL,
  BUTTON_UPDATE_STATUS,
  BUTTON_CONTNUE,
  BUTTON_CLEAR_FILTERS,
  BUTTON_CLEAR_SORTING,
  FORM_NAME,
  FORM_NAMING,
  FORM_ABBR,
  FORM_EMAIL,
  FORM_CONTACT,
  FORM_ADDRESS,
  FORM_ADDRESS_1,
  FORM_ADDRESS_2,
  FORM_STATE,
  FORM_POSTAL,
  FORM_COUNTRY,
  FORM_TITLE,
  FORM_JOB_TITLE,
  FORM_DESCRIPTION,
  FORM_START_DATE,
  FORM_END_DATE,
  FORM_CONFIRM_DATE,
  FORM_REF_NO,
  FORM_EXCLUDE_STATUS,
  FORM_PRIORITY,
  FORM_CATEGORY,
  FORM_DEPARTMENT,
  FORM_DURATION,
  FORM_FIELD_AMOUNT,
  FORM_USERNAME,
  FORM_PASSWORD,
  FORM_CURRENT_PASSWORD,
  FORM_NEW_PASSWORD,
  FORM_CONFIRM_NEW_PASSWORD,
  FORM_GENDER,
  FORM_MALE,
  FORM_FEMALE,
  FORM_WARNING_REQUIRED,
  FORM_WARNING_MAX_NUM,
  FORM_WARNING_MIN_NUM,
  FORM_WARNING_VALID_EMAIL,
  FORM_WARNING_VALID_PHONE,
  FORM_WARNING_VALID_POSTCODE,
  FORM_WARNING_PASSWORD_NOT_MET,
  FORM_WARNING_PASSWORD_MUST_MATCH,
  FORM_WARNING_TWO_OPTIONS,
  FORM_WARNING_OPTIONS_REQUIRED,
  FORM_ERROR,
  FORM_TYPE_PLACEHOLDER,
  UPLOAD_IMAGE_TITLE,
  UPLOAD_IMAGE_REMOVE,
  UPLOAD_IMAGE_DND_IMG,
  UPLOAD_IMAGE_DND_IMGS,
  UPLOAD_IMAGE_SELECT_IMG,
  UPLOAD_IMAGE_SELECT_IMGS,
  UPLOAD_IMAGE_DROP_IMG,
  UPLOAD_IMAGE_DELETE_IMG,
  UPLOAD_IMAGE_SIZE_WARNING,
  UPLOAD_IMAGE_NOT_FOUND,
  UPLOAD_ATTACHMENTS,
  UPLOAD_ATTACHMENTS_NOT_FOUND,
  UPLOAD_ATTACHMENTS_DND_FILE,
  UPLOAD_ATTACHMENTS_DND_FILES,
  NAVBAR_WELCOME,
  NAVBAR_ACCOUNT,
  NAVBAR_LOGOUT,
  NAVBAR_DASHBOARD,
  NAVBAR_WORK_ORDER_CATEGORIES,
  NAVBAR_WORK_ORDER_SCHEDULES,
  SIDEBAR_MY_WORK_ORDERS,
  SIDEBAR_OVERVIEW,
  SIDEBAR_BED,
  SIDEBAR_CONTACT,
  SIDEBAR_ATTENDANCE,
  TABLE_HEADER_ENTITY,
  TABLE_HEADER_ACTIONS,
  TREE_ADD_ROOT,
  TREE_ADD_CHILD,
  TREE_CREATE_ROOT,
  TREE_CREATE_CHILD,
  TREE_PARENT,
  TREE_DELETE_DESCENDANTS,
  TOAST_ERROR_WENT_WRONG,
  TOAST_ERROR_WENT_WRONG_WHEN,
  TOAST_ERROR_ACTION_CREATING,
  TOAST_ERROR_ACTION_EDITING,
  TOAST_ERROR_ACTION_DELETING,
  TOAST_ERROR_ACTION_ADDING,
  TOAST_ERROR_ACTION_SORTING,
  TOAST_ERROR_ACTION_DUPLICATING,
  TOAST_ERROR_ACTION_UPDATING,
  TOAST_ERROR_ACTION_REMOVING,
  MARK_NOTIFICATION_AS_READ,
  MARK_ALL_NOTIFICATION_AS_READ,
  ASSIGN_TEMPLATE_WORK_ORDER,
  ADD_ASSET_TO_WORK_ORDER,
  REMOVE_ASSET_FROM_WORK_ORDER,
  SUPPORT_GEOLOCATION,
  ENABLE_LOCATION,
  SUBMIT_REQUEST,
  SAME_AS_PARENT,
  TOAST_SUCCESS_ACTION_SUCCESSFULLY,
  TOAST_SUCCESS_ACTION_UPDATED,
  TOAST_SUCCESS_ACTION_DELETED,
  TOAST_SUCCESS_ACTION_CREATED,
  TOAST_SUCCESS_ACTION_REMOVED,
  TOAST_SUCCESS_ACTION_REORDERED,
  TOAST_SUCCESS_ACTION_DUPLICATED,
  TOAST_SUCCESS_ACTION_SUBMITTED,
  TOAST_SUCCESS_ACTION_APPLIED,
  TOAST_SUCCESS_ACTION_RESUMED,
  TOAST_SUCCESS_ACTION_ADDED,
  TOAST_SUCCESS_ACTION_EDITED,
  TOAST_SUCCESS_ACTION_UPLOADED,
  TOAST_SUCCESS_ACTION_ADDED_TO,
  TOAST_SUCCESS_ACTION_REMOVED_FROM,
  PROMPT_WORK_ORDER_DUPLICATE_CONFIRM_TITLE,
  PROMPT_WORK_ORDER_DUPLICATE_CONFIRM_CONTENT,
  PROMPT_WORK_ORDER_GENERATION_CONFIRM_TITLE,
  PROMPT_WORK_ORDER_GENERATION_CONFIRM_CONTENT,
  PROMPT_DELETE_CONFIRMATION,
  PROMPT_DELETE_CONFIRMATION_CONTENT,
  ACTION_ADD,
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_EDIT,
  ACTION_SELECT,
  ACTION_CANCEL,
  ACTION_DUPLICATE,
  ACTION_EXPORT,
  SUBJECT_LOYALTY,
  SUBJECT_OUTLET,
  SUBJECT_CONFIGURATION,
  SUBJECT_MEMBER,
  PASSWORD_TITLE,
  PASSWORD_REQUIREMENT,
  PASSWORD_EIGHT_CHAR,
  PASSWORD_ONE_UPPER,
  PASSWORD_ONE_NUMBER,
  NOTIFICATIONS,
  NOTIFICATIONS_MARK_READ,
  NOTIFICATIONS_VIEW_ALL,
  NO_NOTIFICATIONS,
  REQUEST_LINK_CLICK,
  REQUEST_LINK_SUBMIT,
  BED_STATUS_TOTAL,
  BED_STATUS_IN_USE,
  BED_STATUS_RESERVED,
  BED_STATUS_EMPTY,
  BED_STATUS_CLEAN_UP,
  BED_STATUS_OTHER,
  DASHBOARD_EMPLOYEE_PLACEHOLDER,
  DASHBOARD_OVERDUE,
  DASHBOARD_TODAY,
  DASHBOARD_NEXT7,
  DASHBOARD_UNASSIGNED,
  DASHBOARD_TITLE_BED,
  DASHBOARD_EMPLOYEES_PERIOD,
  DASHBOARD_EMPLOYEES_PERIOD_TODAY,
  DASHBOARD_EMPLOYEES_PERIOD_LAST_WEEK,
  DASHBOARD_EMPLOYEES_PERIOD_THIS_MONTH,
  DASHBOARD_EMPLOYEES_PERIOD_LAST_MONTH,
  ASSET_SOURCE_DOCUMENT,
  ASSET_PRODUCT_LINE,
  ASSET_VEHICLE_LIST,
  ASSET_EQUIPMENT_LIST,
  ASSET_PRODUCT_CATEGORY,
  ASSET_PRODUCT,
  ASSET_SERIAL_NUMBER,
  ASSET_QUANTITY,
  ASSET_EQUIPMENT_RENTAL,
  ASSET_VEHICLE_RENTAL,
  ASSET_FLEET,
  ASSET_LICENSE_PLATE,
  ASSET_VARIANT,
  ASSET_OPTION_NAME,
  ASSET_OPTION_VALUES,
  ASSET_MODIFY_VARIANT,
  ASSET_PRESS_ENTER,
  ASSET_ADD_VARIANTS,
  LATITUDE,
  LONGITUDE,
  ATTENDANCE_LOGS,
  TIMESTAMP,
  CHECK_IN,
  CHECK_IN_SUCCESSFUL,
  ENABLE_REFRESH_PAGE,
  PERMISSION,
  PERMISSION_ACCESS_DENIED,
  NO_PERMISSION,
  MY,
  UPDATE,
  NO_DETAIL,
  GENERAL,
  REQUEST,
  NETWORK_ERROR,
  FILE,
  USER_DETAILS,
  VIEW,
  LOAD_MORE,
  DAYS,
  NOTES,
  ACTIVE,
  INACTIVE,
  EXPIRED,
  DEFAULT,
  TYPE,
  YES,
  NO,
  NA,
  DETAILS,
  LOADING,
  SORT,
  SEARCH,
  SEARCH_PLACEHOLDER,
  FILTER,
  EDIT,
  SELECT,
  CREATE,
  DUPLICATE,
  CUSTOMER,
  VENDOR,
  NO_DATA_FOUND,
  SCROLL_MORE,
  END_OF_LIST,
  BASIC_DETAILS,
  LOCATION_NAME,
  SET_AS_DEFAULT,
  CHANGE_PASSWORD,
  SAME_AS_PARENT_ADDRESS,
  PARENT_LOCATION,
  CALENDAR_VIEW,
  LISTING_VIEW,
  TOMORROW,
  FUTURE,
  REMEMBER_ME,
  SHOW,
  HIDE,
};

export interface ITranslateObj {
  key: string;
  backup: string;
}
