import React from "react";
/* components */
/* 3rd party lib */
import ReactEcharts from "echarts-for-react";
/* Util */
import NoDataFound from "src/components/common/feedback/no-data-found";
import SpinnerComponent from "src/components/common/feedback/spinner";
import { rainbowBarColors } from "./bar-chart";
import { useTheme } from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";

export type TPieChartData = {
  name: string;
  value: number;
}[];

interface PieChartProps {
  barColors?: string[];
  data: TPieChartData | null;
}

type Props = PieChartProps;

const PieChart: React.FC<Props> = React.memo(function PieChart({ data, barColors = rainbowBarColors }) {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const { mdDown } = useMediaQueries();
  const options = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      type: "scroll",
      // orient: "vertical",
      // right: 10,
      // top: 20,
      // bottom: 20,
      orient: "horizontal",
      bottom: 0,
      // bottom: "20px",
      data: data
        ? data
            .filter(child => child.name !== null)
            .map(child => {
              return child.name ?? "";
            })
        : [],
      textStyle: {
        color: theme.palette.text.secondary, // Change the color of the legend text
      },
      formatter: function (name: string) {
        // Add an ellipsis if the legend label is too long
        if (name.length > 10) {
          return name.substring(0, 10) + "...";
        } else {
          return name;
        }
      },
    },
    series: [
      {
        name: "Hashtags",
        type: "pie",
        radius: "55%",
        bottom: 30,
        center: "center",
        // center: ["40%", "50%"],
        data: data?.filter(child => child.name !== null) ?? [],
        selectedMode: "single",
        itemStyle: {
          color: function (params: any) {
            return barColors[params.dataIndex % barColors.length]; // Change the line color to blue
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        labelLine: {
          show: true,

          length: mdDown ? 20 : 50,
        },
        label: {
          textStyle: {
            color: theme.palette.text.secondary, // Change the label text color
          },
        },
      },
    ],
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      {data ? (
        data.length === 0 ? (
          <NoDataFound image />
        ) : (
          <ReactEcharts
            option={options}
            style={{ width: "100%", height: "100%", WebkitTapHighlightColor: "transparent" }}
            opts={{ renderer: "svg" }} // Use SVG renderer for better resizing
            //@ts-ignore
            autoResize={true} // Enable autoResize to make the height flexible
          />
        )
      ) : (
        <SpinnerComponent modifier={1.2} />
      )}
    </>
  );
});

export default PieChart;
