import React, { useCallback, useEffect, useState } from "react";
/* components */
/* 3rd party lib */
import { Box, Stack, Button, Typography, IconButton, useTheme, Paper, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
import { useDispatch } from "react-redux";
import { setTheme } from "src/slices/general";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import useMediaQueries from "src/hooks/use-mediaqueries";
import verifyImg from "src/images/verify.png";
import LogoLight from "src/images/logo_light.png";
import LogoDark from "src/images/logo_dark.png";
import useHttp from "src/hooks/use-http";
import { toast } from "react-hot-toast";
import SpinnerComponent from "src/components/common/feedback/spinner";
import useEffectAfterMount from "src/hooks/use-effectaftermount";
import moment from "moment";
import useAuth from "src/hooks/use-auth";
import axios from "axios";

/* Util */
interface VerifyProps {}

type Props = VerifyProps;

const Verify: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const navigate = useNavigate();
  const { smDown } = useMediaQueries();
  const { http, apiEndpoint } = useHttp();
  const theme = useTheme();
  const { mode } = theme.palette;
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const [email, setEmail] = useState<string | null>(null);
  const [showCooldown, setShowCooldown] = useState(false);

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const startTimer = () => {
    const timerEndAt = moment().add("60", "seconds"); // Get current system time
    localStorage.setItem("timerEndAt", timerEndAt.toString()); // Store start time in local storage
    setShowCooldown(true);
    setCooldown(60); // Set cooldown time in seconds
  };

  const sendEmailVerification = useCallback(async () => {
    setLoading(true);

    try {
      await http.post(apiEndpoint.SEND_EMAIL_VERIFICATION);
      setLoading(false);
      startTimer();

      toast.success("Verification email sent.");
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast.error("Something went wrong when sending email");
    }
  }, [http, apiEndpoint]);

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffectAfterMount(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiEndpoint.USER_PROFILE.substring(1)}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });

        setEmail(response.data.email);
      } catch (err) {
        console.error(err);
      }
    };

    // Call the async function
    fetchUserProfile();

    const timerEndAt = localStorage.getItem("timerEndAt");
    if (timerEndAt !== undefined && timerEndAt !== null) {
      const differenceInSeconds = moment(timerEndAt).diff(moment(), "seconds");
      if (differenceInSeconds <= 60 && differenceInSeconds > 0) {
        setShowCooldown(true);
        setCooldown(differenceInSeconds); // Set cooldown time in seconds
      } else {
        setCooldown(0);
        setShowCooldown(false);
        localStorage.removeItem("timerEndAt");
      }
    }
  }, []);

  useEffect(() => {
    let timer: any;

    if (cooldown < 0) {
      setCooldown(0);
      setShowCooldown(false);
      localStorage.removeItem("timerEndAt");
    }

    if (showCooldown && cooldown > 0) {
      timer = setInterval(() => {
        setCooldown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            setShowCooldown(false);
            localStorage.removeItem("timerEndAt");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [showCooldown, cooldown]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Box>
        <Button onClick={() => navigate(routes.HOME)} sx={{ position: "absolute", left: "16px", top: "16px" }}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <img src={mode === "light" ? LogoLight : LogoDark} alt="logo" height="40px" />
          </Stack>
        </Button>

        <IconButton
          sx={{ ml: 1, position: "absolute", right: "16px", top: "16px" }}
          onClick={() => {
            if (mode === "dark") {
              dispatch(setTheme("light"));
            } else {
              dispatch(setTheme("dark"));
            }
          }}
        >
          {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>

        <Box sx={{ height: "100vh", width: "100vw", display: "grid", placeItems: "center" }}>
          <Paper elevation={10} sx={{ width: smDown ? "85%" : "500px", px: smDown ? 2 : 3, py: 5 }}>
            <img alt="verify" style={{ borderRadius: "8px" }} src={verifyImg} width="150px" />

            <Typography variant={smDown ? "h5" : "h3"}>Verify your email address</Typography>

            <Stack sx={{ mt: 2 }} spacing={2}>
              <Typography variant="body1">
                We have sent a verification link to <br />
                {email ? <b style={{ color: theme.palette.primary.main }}>{email}</b> : "your email"}
              </Typography>
              <Typography variant="body1">
                Click on the verify button to complete the verification process. You may need to check your spam folder if you don't see it.
              </Typography>
            </Stack>
            <Stack spacing={3} sx={{ mt: 3 }}>
              <Typography variant="body1">Still can't find the email?</Typography>
              <Box>
                <Button
                  disabled={loading || showCooldown}
                  endIcon={loading ? <SpinnerComponent color="white" modifier={0.4} /> : null}
                  onClick={() => sendEmailVerification()}
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  <Typography variant="body1" color={loading || showCooldown ? "grey.400" : "white"}>
                    {showCooldown ? (
                      <>
                        Resend Verification Email in <b>{cooldown}</b>&nbsp;(s)
                      </>
                    ) : (
                      "Resend Verification Email"
                    )}
                  </Typography>
                </Button>
              </Box>
              <Box>
                <Link onClick={() => logout()} href={routes.LOGIN}>
                  Go to login page
                </Link>
              </Box>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default Verify;
