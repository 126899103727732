import { TTikTokData, TTikTokProfilePost } from "src/types/tiktok";
import { TInstaData, TInstaProfilePost } from "src/types/instagram";
import { RootState } from "src/store/root-reducer";
import { useDispatch, useSelector } from "react-redux";
import { setProfileAvatarGroup, setMultiProfile, setMultiPost, setPostAvatarGroup, IMultiProfile, IMultiPost } from "src/slices/compare";
import { toast } from "react-hot-toast";
import { IPostAvatarGroup, IProfileAvatarGroup } from "src/types/common";
import { checkObjectNotEmpty } from "src/utils/general";

const useCompare = (username?: string) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { multiPost, multiProfile, postAvatarGroup, profileAvatarGroup } = useSelector((state: RootState) => state.compare);

  /* ========================= Handle multi profile select ========================= */

  const handleMultiProfileSelect = (rowData: TInstaData | TTikTokData) => {
    if (!user) return;
    const totalProfileUsernamesLength = (multiProfile: Record<string, string>) => {
      return Object.keys(multiProfile).length;
    };

    const addProfileToMultiProfile = (username: string, multiProfile: IMultiProfile) => {
      return { ...multiProfile, [username]: username };
    };
    const removeProfileFromMultiProfile = (username: string, multiProfile: IMultiProfile) => {
      let tempMultiProfile = { ...multiProfile };
      delete tempMultiProfile[username];
      return tempMultiProfile;
    };
    const addProfileToAvatarGroup = (rowData: TInstaData | TTikTokData, profileAvatarGroup: IProfileAvatarGroup[]) => {
      let tempAvatarGroup = [...profileAvatarGroup];
      tempAvatarGroup.unshift({ username: rowData.username, display_name: rowData.display_name, downloaded_image: rowData.downloaded_image });
      return tempAvatarGroup;
    };

    // make sure to only check limit if user is not admin
    const profileLimitReached =
      !user?.isAdmin &&
      totalProfileUsernamesLength(multiProfile) >=
        (checkObjectNotEmpty(user.subscription) && user.subscription.package.max_compare_profile_limit
          ? user.subscription.package.max_compare_profile_limit
          : 0);

    if (multiProfile[rowData.username]) {
      // REMOVE PROCEDURE
      // Remove profile from multiProfile
      const removedMultiProfile = removeProfileFromMultiProfile(rowData.username, multiProfile);
      dispatch(setMultiProfile(removedMultiProfile));

      if (profileAvatarGroup && profileAvatarGroup.find(child => child.username === rowData.username)) {
        // Remove profile from profileAvatarGroup
        const removedAvatarGroup = profileAvatarGroup.filter(child => child.username !== rowData.username);
        dispatch(setProfileAvatarGroup(removedAvatarGroup));
      }
    } else if (!profileLimitReached) {
      // ADD PROCEDURE
      const addedMultiProfile = addProfileToMultiProfile(rowData.username, multiProfile);
      dispatch(setMultiProfile(addedMultiProfile));
      if (profileAvatarGroup) {
        const removedAvatarGroup = addProfileToAvatarGroup(rowData, profileAvatarGroup);
        dispatch(setProfileAvatarGroup(removedAvatarGroup));
      }
    } else {
      toast.error("Profile Comparison limit reached!");
    }
  };

  /* ========================= Handle multi post select ========================= */

  const handleMultiPostSelect = (rowData: TInstaProfilePost | TTikTokProfilePost) => {
    const tmpUsername = username || rowData.profile.username;

    if (!tmpUsername || !multiPost || !user) return;

    const totalPostIdsLength = (multiPost: IMultiPost) => {
      return Object.keys(multiPost).reduce((acc, tmpUsername) => {
        return acc + Object.keys(multiPost[tmpUsername]).length;
      }, 0);
    };

    const removePostFromMultiPost = (tmpUsername: string, postId: string, multiPost: IMultiPost) => {
      const updatedUsernameLevel = { ...multiPost[tmpUsername] };
      delete updatedUsernameLevel[postId];
      let tempMultiPost = { ...multiPost, [tmpUsername]: updatedUsernameLevel };

      if (Object.keys(tempMultiPost[tmpUsername]).length === 0) delete tempMultiPost[tmpUsername];
      return tempMultiPost;
    };

    const addPostToMultiPost = (tmpUsername: string, postId: string, multiPost: IMultiPost) => {
      return { ...multiPost, [tmpUsername]: { ...multiPost[tmpUsername], [postId]: postId } };
    };

    const addPostToAvatarGroup = (
      username: string,
      postId: string,
      rowData: TInstaProfilePost | TTikTokProfilePost,
      postAvatarGroup: IPostAvatarGroup[]
    ) => {
      return [
        {
          postId,
          username,
          title: rowData.title,
          downloaded_image: rowData.downloaded_image,
        },
        ...postAvatarGroup,
      ];
    };

    const postId = rowData.post_id;
    const postLimitReached =
      !user?.isAdmin &&
      totalPostIdsLength(multiPost) >=
        (checkObjectNotEmpty(user.subscription) && user.subscription.package.max_compare_post_limit
          ? user.subscription.package.max_compare_post_limit
          : 0);

    if (multiPost[tmpUsername] && multiPost[tmpUsername][postId]) {
      // Remove post from multiPost
      const removedMultiPost = removePostFromMultiPost(tmpUsername, postId, multiPost);
      dispatch(setMultiPost(removedMultiPost));

      if (postAvatarGroup && postAvatarGroup.find(child => child.postId === postId)) {
        // Remove post from postAvatarGroup
        const removedAvatarGroup = postAvatarGroup.filter(child => child.postId !== postId);
        dispatch(setPostAvatarGroup(removedAvatarGroup));
      }
    } else if (!postLimitReached) {
      // Add post to multiPost and postAvatarGroup
      const addedMultiPost = addPostToMultiPost(tmpUsername, postId, multiPost);
      dispatch(setMultiPost(addedMultiPost));

      if (postAvatarGroup) {
        const addedAvatarGroup = addPostToAvatarGroup(tmpUsername, postId, rowData, postAvatarGroup);

        dispatch(setPostAvatarGroup(addedAvatarGroup));
      }
    } else {
      toast.error("Post Comparison limit reached!");
    }
  };

  return { handleMultiProfileSelect, handleMultiPostSelect };
};

export default useCompare;
