import React from "react";
/* components */
/* 3rd party lib */
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

/* Util */
import { IProfilePresetData } from "src/types/instagram";
import { ICurrentActiveProfilePreset, IEditPresetDialog } from "src/pages/comparisons/profiles/profiles";
import ProfilePresetSelectionList from "src/components/comparison/profiles/preset-selection-list";
import { useComparisonProfileContext } from "src/contexts/comparison-profile-context";

interface ProfilePresetDialogProps {
  open: boolean;
  onClose: () => void;
  currentActive: ICurrentActiveProfilePreset;
  presetData?: IProfilePresetData[];
  setProfileUsernames: React.Dispatch<React.SetStateAction<string[]>>;
  setCurrentActive: React.Dispatch<React.SetStateAction<ICurrentActiveProfilePreset>>;
  setEditPresetDialog: React.Dispatch<React.SetStateAction<IEditPresetDialog>>;
}

type Props = ProfilePresetDialogProps;

const ProfilePresetDialog: React.FC<Props> = ({ open, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { theme } = useComparisonProfileContext();

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Stack direction="row" spacing={3}>
            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              Select Preset
            </Typography>
            <IconButton onClick={onClose}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            ...(theme as any).custom.scroll,
          }}
        >
          <Box sx={{ pt: 2 }}>
            <ProfilePresetSelectionList onClose={onClose} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfilePresetDialog;
