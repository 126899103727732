/* components */
import NoDataFound from "src/components/common/feedback/no-data-found";
import SpinnerComponent from "src/components/common/feedback/spinner";
/* 3rd party lib */
import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, FormControl, Grid, Pagination, Paper, Stack, TextField, Theme, Typography } from "@mui/material";
import { faComment, faPlay, faThumbsUp, faCalendar, faBookmark, faShare } from "@fortawesome/pro-regular-svg-icons";

/* Util */
import { TPagination } from "src/types/common";
import { TTikTokProfilePost } from "src/types/tiktok";
import { scroll } from "src/theme/dark-theme-options";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { formatNumberWithSuffix } from "src/utils/general";
import { useResizeObserver } from "src/hooks/use-resizeobserver";
import { TSortPost } from "src/components/profile/tiktok/profile-overview/index";
import CustomPagination from "src/components/common/navigation/custom-pagination";
import { faHistory } from "@fortawesome/pro-solid-svg-icons";
import routes from "src/routes";
import { useParams } from "react-router-dom";
import ArrowOverflowBox from "src/components/common/layout/arrow-overflow-box";

interface PostViewerProps {
  data: any;
  theme: Theme;
  convertedTitle: string;
  selected: TTikTokProfilePost | null;
  pagination: TPagination;
  sortPost: TSortPost;
  setPagination: React.Dispatch<React.SetStateAction<TPagination>>;
  setSelected: React.Dispatch<React.SetStateAction<TTikTokProfilePost | null>>;
  setSortPost: React.Dispatch<React.SetStateAction<TSortPost>>;
}

type Props = PostViewerProps;

const PostViewer: React.FC<Props> = ({ data, theme, sortPost, setSortPost, selected, setSelected, pagination, setPagination, convertedTitle }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const params = useParams();
  const { username } = params;

  const { height: gridHeight, observedDivRef } = useResizeObserver();

  const styles = {
    fullSize: {
      width: "100%",
      height: "100%",
    },
  };

  const { smDown, mdDown, lgDown } = useMediaQueries();

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handlePageChange = (page: number) => {
    if (pagination && page !== pagination.currPage) {
      if (data && data.results.length > 0) {
        setSelected(data.results[0]);
      }
      setPagination({ ...pagination, currPage: page });
    }
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Paper sx={{ ...styles.fullSize, mt: 1 }}>
        <Grid container spacing={1} sx={{ p: smDown ? 1 : 2, height: !selected && data && data.results.length === 0 ? "500px" : "810px" }}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent={"space-between"} alignItems="center">
              <Typography variant="body1" color="text.secondary" textAlign="left">
                Videos
              </Typography>

              <Box>
                <FormControl fullWidth>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body1" color="text.secondary">
                      Sort:
                    </Typography>
                    <TextField
                      variant="outlined"
                      hiddenLabel
                      value={sortPost}
                      onChange={e => {
                        setSortPost(e.target.value as TSortPost);
                      }}
                      select
                      size="small"
                      sx={{
                        width: "110px",
                        "& .MuiNativeSelect-select": {
                          textTransform: "capitalize",
                        },
                        "& .MuiOutlinedInput-input": {
                          height: "auto",
                          py: 0.9,
                        },
                      }}
                      SelectProps={{ native: true }}
                    >
                      <option value={"count_play"}>Plays</option>
                      <option value={"count_like"}>Likes</option>
                      <option value={"count_comment"}>Comments</option>
                      <option value={"count_saved"}>Saved</option>
                      <option value={"count_share"}>Share</option>
                      <option value={"date_posted"}>Date Posted</option>
                    </TextField>
                  </Stack>
                </FormControl>
              </Box>
            </Stack>
          </Grid>

          {!selected && data && data.results.length === 0 ? (
            <Grid item xs={12} sx={{ height: "100%" }}>
              <NoDataFound image />
            </Grid>
          ) : (
            <>
              <Grid item xs={8} lg={6}>
                <Stack sx={styles.fullSize}>
                  {selected ? (
                    <Stack alignItems="center" spacing={1}>
                      <Stack
                        spacing={1}
                        sx={{
                          pt: 1,
                          position: "relative",
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <Stack
                          alignItems="center"
                          spacing={1}
                          sx={{ position: "relative", width: "100%", height: "260px", background: "black", borderRadius: "8px" }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            endIcon={<FontAwesomeIcon icon={faHistory} style={{ fontSize: "14px" }} />}
                            sx={{ zIndex: 2, position: "absolute", right: "8px", bottom: "8px" }}
                            onClick={() => window.open(`${routes.TIKTOK_PROFILE}/${username}/${selected.post_id}`, "_blank")}
                          >
                            <Typography variant="body2">History</Typography>
                          </Button>

                          <Box
                            sx={{
                              height: "100%",
                              position: "relative",
                              overflow: "hidden",
                              border: `1px solid ${(theme.palette.neutral as any)[200]}`,
                            }}
                          >
                            <img
                              onClick={() => window.open(selected.tiktok_link, "_blank")}
                              alt={`${selected.post_id}`}
                              src={selected.downloaded_image}
                              width="100%"
                              height="100%"
                              style={{ cursor: "pointer", objectFit: "contain" }}
                            />
                          </Box>
                        </Stack>

                        <ArrowOverflowBox justifyContent="center">
                          <Stack direction="row" spacing={2} justifyContent="center" sx={{ position: "relative", height: "34px" }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <FontAwesomeIcon icon={faPlay} />
                              <Typography variant="body1">{formatNumberWithSuffix(selected.count_play) ?? "-"}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <FontAwesomeIcon icon={faThumbsUp} />
                              <Typography variant="body1">{formatNumberWithSuffix(selected.count_like) ?? "-"}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <FontAwesomeIcon icon={faComment} />
                              <Typography variant="body1">{formatNumberWithSuffix(selected.count_comment) ?? "-"}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <FontAwesomeIcon icon={faBookmark} />
                              <Typography variant="body1">{formatNumberWithSuffix(selected.count_saved) ?? "-"}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <FontAwesomeIcon icon={faShare} />
                              <Typography variant="body1">{formatNumberWithSuffix(selected.count_share) ?? "-"}</Typography>
                            </Stack>
                          </Stack>
                        </ArrowOverflowBox>
                      </Stack>

                      <PostDescription theme={theme} convertedTitle={convertedTitle} />
                    </Stack>
                  ) : (
                    <NoDataFound image />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={4} lg={6} ref={observedDivRef} sx={{ height: "calc(100% - 32px)", mt: 1 }}>
                <Grid
                  container
                  spacing={1}
                  alignContent="flex-start"
                  sx={{ ...scroll, px: lgDown ? "8px" : 0, height: lgDown ? "100%" : "auto", overflow: lgDown ? "auto" : "visible" }}
                >
                  {data ? (
                    data.results.length === 0 ? (
                      <Box sx={{ display: "grid", placeItems: "center", width: "100%", height: gridHeight - 24 }}>
                        <NoDataFound image />
                      </Box>
                    ) : (
                      data.results.map((child: TTikTokProfilePost) => {
                        return (
                          child.downloaded_image && (
                            <Grid
                              key={child.post_id}
                              item
                              xs={12}
                              md={6}
                              lg={2.4}
                              sx={{ height: smDown ? "100px" : lgDown ? "180px" : `${(gridHeight - 52) / 5}px` }}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "8px",
                                  overflow: "hidden",

                                  border: `4px solid ${selected?.post_id === child.post_id ? theme.palette.secondary.main : "transparent"}`,
                                }}
                              >
                                <img
                                  // onClick={() => window.open(child.insta_link, "_blank")}
                                  onClick={() => setSelected(child)}
                                  alt={`${child.post_id}`}
                                  src={child.downloaded_image}
                                  width="100%"
                                  height="100%"
                                  style={{ cursor: "pointer", objectFit: "cover" }}
                                />
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  justifyContent="center"
                                  sx={{
                                    pointerEvents: "none",
                                    position: "absolute",
                                    bottom: "4px",
                                    left: "4px",
                                    background: theme.palette.primary.main,
                                    px: 1,
                                    borderRadius: "8px",
                                  }}
                                >
                                  {sortPost === "count_play" && (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <FontAwesomeIcon size={smDown ? "xs" : "sm"} icon={faPlay} style={{ color: "white" }} />
                                      <Typography variant="caption" color="white">
                                        {formatNumberWithSuffix(child.count_play) ?? "-"}
                                      </Typography>
                                    </Stack>
                                  )}
                                  {sortPost === "count_like" && (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <FontAwesomeIcon size={smDown ? "xs" : "sm"} icon={faThumbsUp} style={{ color: "white" }} />
                                      <Typography variant="caption" color="white">
                                        {formatNumberWithSuffix(child.count_like) ?? "-"}
                                      </Typography>
                                    </Stack>
                                  )}

                                  {sortPost === "count_comment" && (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <FontAwesomeIcon size={smDown ? "xs" : "sm"} icon={faComment} style={{ color: "white" }} />
                                      <Typography variant="caption" color="white">
                                        {formatNumberWithSuffix(child.count_comment) ?? "-"}
                                      </Typography>
                                    </Stack>
                                  )}

                                  {sortPost === "count_saved" && (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <FontAwesomeIcon size={smDown ? "xs" : "sm"} icon={faBookmark} style={{ color: "white" }} />
                                      <Typography variant="caption" color="white">
                                        {formatNumberWithSuffix(child.count_saved) ?? "-"}
                                      </Typography>
                                    </Stack>
                                  )}

                                  {sortPost === "count_share" && (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <FontAwesomeIcon size={smDown ? "xs" : "sm"} icon={faShare} style={{ color: "white" }} />
                                      <Typography variant="caption" color="white">
                                        {formatNumberWithSuffix(child.count_share) ?? "-"}
                                      </Typography>
                                    </Stack>
                                  )}
                                  {sortPost === "date_posted" && (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      {!smDown && <FontAwesomeIcon size={smDown ? "xs" : "sm"} icon={faCalendar} style={{ color: "white" }} />}
                                      <Typography variant="caption" color="white">
                                        {moment(child.date_posted).format(smDown ? "DDMMMYY" : "DD/MM/YYYY") ?? "-"}
                                      </Typography>
                                    </Stack>
                                  )}
                                </Stack>
                              </Box>
                            </Grid>
                          )
                        );
                      })
                    )
                  ) : (
                    <Box sx={{ display: "grid", placeItems: "center", width: "100%", height: gridHeight - 24 }}>
                      <SpinnerComponent modifier={1.2} />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {mdDown ? (
                    <Stack alignItems="center">
                      <CustomPagination page={pagination?.currPage} count={pagination?.total_pages ?? 0} onChange={handlePageChange} />
                    </Stack>
                  ) : (
                    <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 1 }}>
                      <Pagination
                        page={pagination?.currPage}
                        onChange={(e, page) => {
                          handlePageChange(page);
                        }}
                        count={pagination?.total_pages ?? 0}
                        showFirstButton
                        showLastButton
                      />
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </>
  );
};

export default PostViewer;

const PostDescription = ({ theme, convertedTitle }: { theme: Theme; convertedTitle: string }) => {
  return (
    <Box sx={{ whiteSpace: "nowrap", width: "100%" }}>
      <Typography
        variant="body2"
        textAlign="left"
        sx={{
          p: 1,
          overflow: "auto",
          height: "395px",
          borderRadius: "8px",
          background: (theme.palette.neutral as any)[theme.palette.mode === "light" ? 100 : 800],
          ...scroll,
        }}
      >
        <div style={{ whiteSpace: "normal" }} dangerouslySetInnerHTML={{ __html: convertedTitle }} />
      </Typography>
    </Box>
  );
};
