import useSWR from "swr";
import moment from "moment";
import useData from "src/hooks/use-data";
import useHttp from "src/hooks/use-http";
import { useParams } from "react-router-dom";
import { TABLE_ID } from "src/utils/constants";
import { IFormDateFilter, TPagination } from "src/types/common";
import { MRT_ColumnDef } from "material-react-table";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { useInView } from "react-intersection-observer";
import { faLink } from "@fortawesome/pro-solid-svg-icons";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { TTikTokProfileHashtag } from "src/types/tiktok";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HashtagChart from "src/components/profile/tiktok/hashtag-chart";
import SpinnerComponent from "src/components/common/feedback/spinner";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { formatNumberWithSuffix, getDynamicIndex } from "src/utils/general";
import DataTableList from "src/components/common/data-display/data-table-list";
import { NUMBER_CELL_TRANSLATE } from "src/components/common/data-display/dnd-table";
import { faBookmark, faShare, faComment, faPlay, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Paper, Slide, Stack, Typography, useTheme } from "@mui/material";
import { ITikTokHashtagPostData, ITikTokProfileHashtagData } from "src/types/tiktok";
import { FormikProps } from "formik";

export type TTikTokSortHashtag = "median_play" | "median_like" | "median_comment" | "median_share" | "median_saved";

interface ProfileHashtagsProps {
  formik: FormikProps<IFormDateFilter>;
}

type Props = ProfileHashtagsProps;

const ProfileHashtags: React.FC<Props> = ({ formik }) => {
  const theme = useTheme();
  const params = useParams();
  const { apiEndpoint } = useHttp();
  const { smDown, mdDown } = useMediaQueries();
  const dataTableListRef = useRef(null);
  const [pagination, setPagination] = useState<TPagination>({
    currPage: 1,
    count: 0,
    limit: 0,
    total_pages: 0,
  });
  const upperLimit = 10; //the number of barchart

  const [renderSlide, setRenderSlide] = useState(false);
  const [chartDialogOpen, setChartDialogOpen] = useState(false);
  const [checkPagination, setCheckPagination] = useState({ currentPage: 0, rowsPerPage: 0 });
  const { count, data, dispatchData, limit, totalPage } = useData();
  const [sortHashtag, setSortHashtag] = useState<TTikTokSortHashtag>("median_play");
  const config = useMemo(() => {
    let metric = "play";
    let ordering = "-median_play";
    switch (sortHashtag) {
      case "median_play":
        metric = "play";
        ordering = "-median_play";
        break;
      case "median_like":
        metric = "like";
        ordering = "-median_like";
        break;
      case "median_comment":
        metric = "comment";
        ordering = "-median_comment";
        break;
      case "median_share":
        metric = "share";
        ordering = "-median_share";
        break;
      case "median_saved":
        metric = "saved";
        ordering = "-median_saved";
        break;
      default:
    }

    let params = {
      metric: metric,
      ordering: ordering,
      page_size: upperLimit,
      date_posted_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
      date_posted_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
    };
    return { params };
  }, [formik, upperLimit, sortHashtag]);

  const { data: apiData } = useSWR<ITikTokProfileHashtagData>(
    params && [apiEndpoint.TIKTOK_HASHTAGS(params.username) + `?page=${pagination?.currPage ?? 1}`, config]
  );
  const { ref, inView: chartInView } = useInView({ initialInView: false, threshold: 0 });
  const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
  const [currentActiveHashtag, setCurrentActiveHashtag] = useState<string | null>(null);
  const [barChartData, setBarChartData] = useState<{ xAxis: string[]; data: number[] } | null>(null);

  useEffect(() => {
    if (apiData) {
      const { count, limit, results, total_pages } = apiData;
      dispatchData({
        type: "SET",
        payload: { count, data: results, limit, totalPage: total_pages },
      });
    }
  }, [dispatchData, apiData]);

  const columns: MRT_ColumnDef<{}>[] = useMemo(
    () => [
      {
        accessorKey: "no",
        header: "#",
        size: 0,
        muiTableHeadCellProps: {
          sx: {
            ...NUMBER_CELL_TRANSLATE,
          },
        },
        enablePinning: false,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnDragging: false,

        Cell: ({ row }) => {
          const { currentPage, rowsPerPage } = checkPagination;
          return (
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
              {getDynamicIndex(currentPage - 1, rowsPerPage, row.index + 1)}.
            </Typography>
          );
        },
      },

      {
        accessorKey: "downloaded_image",
        size: 80,
        header: "",
        enableColumnFilter: false,
        Cell: ({ row }: { row: any }) => {
          const rowData = row.original as ITikTokHashtagPostData;
          return (
            <Stack alignItems="center" justifyContent="center" sx={{ pt: 1 }}>
              {rowData.downloaded_image && (
                <Box sx={{ position: "relative" }}>
                  <img
                    onClick={() => window.open(rowData.tiktok_link, "_blank")}
                    alt={`${rowData.post_id}`}
                    src={rowData.downloaded_image}
                    width="120px"
                    height="120px"
                    style={{ cursor: "pointer", objectFit: "cover", borderRadius: "8px" }}
                  />
                </Box>
              )}
              <Box pt={1}>
                <Link
                  onClick={() => window.open(rowData.tiktok_link, "_blank")}
                  // href={routes.SIGNUP}
                  variant="body1"
                  color="primary.main"
                  underline="hover"
                  sx={{ whiteSpace: "nowwrap" }}
                >
                  <FontAwesomeIcon icon={faLink} /> Link
                </Link>
              </Box>
            </Stack>
          );
        },
      },
      {
        accessorKey: "title",
        header: "Post",
        enableColumnFilter: false,
        size: 300,
        Cell: ({ row }: { row: any }) => {
          const rowData = row.original as ITikTokHashtagPostData;

          const title = rowData.title.replace(/\n/g, "<br/> ");
          // Split the description into words
          const words = title.split(/\s+/);

          // Define a function to convert hashtags to links
          function convertHashtagsToLinks(word: string) {
            if (word.startsWith("#") && params) {
              const hashtag = word.slice(1); // Remove the '#' character
              return `<a style="color:${theme.palette.primary.main}" href="/profile/tiktok/${params.username}/hashtags/${hashtag}">#${hashtag}</a>`;
            }
            return word;
          }

          // Map the words, converting hashtags to links where needed
          const convertedWords = words?.map(convertHashtagsToLinks);

          // Join the words back into a single string
          const convertedTitle = convertedWords?.join(" ");

          return (
            title && (
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "auto",
                  height: "200px",
                  "&::-webkit-scrollbar": {
                    width: "4px",
                    height: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: theme.palette.primary.main,
                    borderRadius: "1rem",
                  },

                  "&::-webkit-scrollbar-thumb:hover": {
                    background: theme.palette.primary.light,
                  },
                }}
              >
                <div style={{ whiteSpace: "normal" }} dangerouslySetInnerHTML={{ __html: convertedTitle }} />
              </Box>
            )
          );
        },
      },
      {
        accessorKey: "count_like",
        header: "Stats",
        enableColumnFilter: false,
        size: 60,
        Cell: ({ row }) => {
          const rowData = row.original as ITikTokHashtagPostData;
          return (
            <Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <FontAwesomeIcon icon={faPlay} />
                <Typography variant="body1">{formatNumberWithSuffix(rowData.count_play) ?? "-"}</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <FontAwesomeIcon icon={faThumbsUp} />
                <Typography variant="body1">{formatNumberWithSuffix(rowData.count_like) ?? "-"}</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <FontAwesomeIcon icon={faComment} />
                <Typography variant="body1">{formatNumberWithSuffix(rowData.count_comment) ?? "-"}</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <FontAwesomeIcon icon={faBookmark} />
                <Typography variant="body1">{rowData.count_saved ?? "-"}</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <FontAwesomeIcon icon={faShare} />
                <Typography variant="body1">{rowData.count_share ?? "-"}</Typography>
              </Stack>
            </Stack>
          );
        },
      },
      {
        accessorKey: "date_posted",
        header: "Date Posted",
        size: 80,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as ITikTokHashtagPostData;
          return moment(rowData.date_posted).format("DD/MM/YYYY");
        },
      },
    ],
    [theme, params, checkPagination]
  );

  const listMetadata = {
    primary: { path: "name" },
    sortFields: [
      { key: "name", label: "Tag" },
      { key: "post_count", label: "Posts" },
      { key: "median_list", label: "Median Like" },
      { key: "median_comment", label: "Median Comment" },
      { key: "median_view", label: "Median View" },
    ],
    fields: [
      {
        path: "post_count",
        label: "Posts",
      },
      {
        path: "median_like",
        label: "Median Like",
      },
      {
        path: "median_comment",
        label: "Median Comment",
      },
      {
        path: "median_view",
        label: "Median View",
      },
      {
        path: "median_share",
        label: "Median Share",
      },
      {
        path: "median_saved",
        label: "Median Saved",
      },
    ],
    content: (d: ITikTokHashtagPostData, index: number) => {
      const { currentPage, rowsPerPage } = checkPagination;

      const title = d.title.replace(/\n/g, "<br/> ");
      // Split the description into words
      const words = title.split(/\s+/);

      // Define a function to convert hashtags to links
      function convertHashtagsToLinks(word: string) {
        if (word.startsWith("#") && params) {
          const hashtag = word.slice(1); // Remove the '#' character
          return `<a style="color:${theme.palette.primary.main}" href="/profile/tiktok/${params.username}/hashtags/${hashtag}">#${hashtag}</a>`;
        }
        return word;
      }

      // Map the words, converting hashtags to links where needed
      const convertedWords = words?.map(convertHashtagsToLinks);

      // Join the words back into a single string
      const convertedTitle = convertedWords?.join(" ");

      return (
        <Stack>
          <Stack direction="row" alignItems="center" spacing={2} width={"100%"}>
            <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: "80px" }}>
              <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
                {getDynamicIndex(currentPage - 1, rowsPerPage, index + 1)}.
              </Typography>
              {d.downloaded_image && (
                <img
                  onClick={e => {
                    e.stopPropagation();
                    window.open(d.tiktok_link, "_blank");
                  }}
                  alt={`${d.post_id}`}
                  src={d.downloaded_image}
                  width="80px"
                  height="80px"
                  style={{ cursor: "pointer", objectFit: "cover" }}
                />
              )}
              <Box pt={1}>
                <Link
                  onClick={e => {
                    e.stopPropagation();
                    window.open(d.tiktok_link, "_blank");
                  }}
                  // href={routes.SIGNUP}
                  variant="body1"
                  color="primary.main"
                  underline="hover"
                  sx={{ whiteSpace: "nowwrap" }}
                >
                  <FontAwesomeIcon icon={faLink} /> Link
                </Link>
              </Box>
            </Stack>
            <Stack sx={{ textAlign: "left", width: "calc(100% - 70px)" }}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                {/* <Typography variant={"body1"} fontWeight={700} color="black">
                {d ? d.display_name : "-"}
              </Typography> */}
              </Stack>
              {d ? (
                <Typography variant={"caption"} fontWeight={500} my={0.5}>
                  {/* @{d.username} {d.category_name ? ` | ${d.category_name}` : null} */}
                </Typography>
              ) : (
                "-"
              )}

              {convertedTitle && <Typography variant={"caption"} dangerouslySetInnerHTML={{ __html: convertedTitle }} />}

              <Typography variant="caption" color="text.secondary" pt={1}>
                Posted on: {moment(d.date_posted).format("DD MMM YYYY")}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent={"center"} sx={{ mt: 1.5, pt: 1.5, borderTop: "0.5px solid #bcbcbc" }}>
            <Stack direction="row" spacing={2}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant={"body2"}
                color="text.secondary"
              >
                <FontAwesomeIcon icon={faPlay} />
                &nbsp;<span style={{ fontWeight: "bolder" }}>{formatNumberWithSuffix(d.count_play)}</span>
              </Typography>

              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant={"body2"}
                color="text.secondary"
              >
                <FontAwesomeIcon icon={faThumbsUp} />
                &nbsp;<span style={{ fontWeight: "bolder" }}>{formatNumberWithSuffix(d.count_like)}</span>
              </Typography>

              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant={"body2"}
                color="text.secondary"
              >
                <FontAwesomeIcon icon={faComment} />
                &nbsp;<span style={{ fontWeight: "bolder" }}>{formatNumberWithSuffix(d.count_comment)}</span>
              </Typography>

              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant={"body2"}
                color="text.secondary"
              >
                <FontAwesomeIcon icon={faBookmark} />
                &nbsp;<span style={{ fontWeight: "bolder" }}>{formatNumberWithSuffix(d.count_saved)}</span>
              </Typography>

              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant={"body2"}
                color="text.secondary"
              >
                <FontAwesomeIcon icon={faShare} />
                &nbsp;<span style={{ fontWeight: "bolder" }}>{formatNumberWithSuffix(d.count_share)}</span>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      );
    },
  };

  const gridStyle = {
    containLabel: true,
    top: "40px",
    bottom: "20px",
    left: mdDown ? "20px" : "0",
    right: mdDown ? "20px" : "40px",
  };

  useEffect(() => {
    if (data) {
      const result = (data as TTikTokProfileHashtag[])
        .sort((a, b) => parseInt((b as any)[sortHashtag]) - parseInt((a as any)[sortHashtag]))
        .reduce(
          (acc: { xAxis: string[]; data: number[] }, child) => {
            acc["xAxis"].push(child.name);
            acc["data"].push(parseInt((child as any)[sortHashtag]));

            return acc;
          },
          { xAxis: [], data: [] }
        );
      if (data.length > 0) {
        setCurrentActiveHashtag(data[0].name);
      }

      setBarChartData(result);

      setPagination(prev => {
        return { ...prev, count: count, limit: limit, total_pages: totalPage };
      });
    }
  }, [data, count, limit, totalPage, sortHashtag]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setRenderSlide(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Box>
        {data && barChartData ? (
          <Grid container spacing={1}>
            <Grid item xs={12} ref={ref}>
              <HashtagChart
                data={data}
                mdDown={mdDown}
                formik={formik}
                upperLimit={upperLimit}
                gridStyle={gridStyle}
                pagination={pagination}
                sortHashtag={sortHashtag}
                barChartData={barChartData}
                setPagination={setPagination}
                setSortHashtag={setSortHashtag}
                currentActiveIndex={currentActiveIndex}
                currentActiveHashtag={currentActiveHashtag}
                setCurrentActiveHashtag={setCurrentActiveHashtag}
                setCurrentActiveIndex={setCurrentActiveIndex}
              />
            </Grid>
            {currentActiveHashtag && (
              <Grid item xs={12}>
                <Paper elevation={5} sx={{ py: 3, width: "100%" }}>
                  <DataTableList
                    id={TABLE_ID.PROFILE_HASHTAG_POST}
                    columns={columns}
                    columnOrder={columns.map(c => c.accessorKey)}
                    setCheckPagination={setCheckPagination}
                    dataTableListRef={dataTableListRef}
                    listMetadata={listMetadata}
                    showGlobalFilter={false}
                    // rowAction={(rowData: ITikTokHashtagPostData) => window.open(rowData.tiktok_link, "_blank")}
                    // rowActions={[
                    //   {
                    //     icon: faAngleRight,
                    //     label: "More",
                    //     onClick: (rowData: TInstaData) => {
                    //       navigate(`${routes.INSTA_PROFILE}/${rowData.username}`);
                    //     },
                    //     tooltip: () => "More",
                    //   },
                    // ]}
                    listItemStyle={{
                      "&:nth-of-type(odd)": {
                        backgroundColor: "#f4f4f4c0",
                      },
                    }}
                    search={{
                      key: "q",
                      label: "Search post",
                    }}
                    url={`${apiEndpoint.TIKTOK_PROFILE_POSTS(params.username)}?hashtags=${currentActiveHashtag}`}
                  />
                </Paper>
              </Grid>
            )}
          </Grid>
        ) : (
          <Box
            sx={{
              height: `calc(100vh - ${mdDown ? "140px" : "280px"})`,
              display: "grid",
              placeItems: "center",
              background: theme.palette.mode === "dark" ? (theme.palette.neutral as any)[800] : "white",
            }}
          >
            <SpinnerComponent color={theme.palette.mode === "dark" ? "white" : theme.palette.primary.main} />
          </Box>
        )}
      </Box>
      {renderSlide && (
        <Slide direction="up" in={!chartInView} mountOnEnter unmountOnExit>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setChartDialogOpen(true);
            }}
            sx={{
              zIndex: 999,
              position: "fixed",
              right: "64px",
              bottom: "60px",
            }}
          >
            View Chart
          </Button>
        </Slide>
      )}

      <Dialog open={chartDialogOpen} onClose={() => setChartDialogOpen(false)} maxWidth="xl" fullWidth fullScreen={smDown}>
        <DialogTitle>
          <Stack direction="row" spacing={3}>
            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              Hashtags
            </Typography>
            <IconButton onClick={() => setChartDialogOpen(false)}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <HashtagChart
              data={data}
              formik={formik}
              mdDown={mdDown}
              gridStyle={gridStyle}
              pagination={pagination}
              sortHashtag={sortHashtag}
              barChartData={barChartData}
              setPagination={setPagination}
              setSortHashtag={setSortHashtag}
              currentActiveIndex={currentActiveIndex}
              currentActiveHashtag={currentActiveHashtag}
              setCurrentActiveHashtag={setCurrentActiveHashtag}
              setCurrentActiveIndex={setCurrentActiveIndex}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfileHashtags;
