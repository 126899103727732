import React, { useEffect, useState } from "react";
/* components */
import OuterLayout from "src/components/common/layout/outer-layout";
import ProfilePosts from "src/components/profile/tiktok/profile-posts";
import ProfileHashtags from "src/components/profile/tiktok/profile-hashtags";
import TikTokProfileBanner from "src/components/profile/tiktok/profile-banner";
import ExpiredOverlay from "src/components/common/data-display/expired-overlay";
import ProfileStatistics from "src/components/profile/tiktok/profile-statistics";
import ProfileOverview from "src/components/profile/tiktok/profile-overview/index";
/* 3rd party lib */
import moment from "moment";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Paper, Stack, Tab, Tabs, Theme, Typography, useTheme } from "@mui/material";
import { faChartColumn, faChartPie, faGrid, faHashtag } from "@fortawesome/pro-regular-svg-icons";
/* Util */
import { IFormDateFilter } from "src/types/common";
import useBackground from "src/hooks/use-background";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { PROFILE_TAB_PANEL } from "src/utils/constants";
import { GeneralRootState, setDateFilter, setMuiTab } from "src/slices/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotFound404 from "src/components/common/feedback/404";
import { RootState } from "src/store/root-reducer";

interface TikTokProfileProps {}

type Props = TikTokProfileProps;

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  target: string;
  theme: Theme;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, theme, target, ...other } = props;

  const background = useBackground({});

  return (
    <div
      role="tabpanel"
      hidden={value !== target}
      id={`simple-tabpanel-${target}`}
      aria-labelledby={`simple-tab-${target}`}
      {...other}
      style={{ background: background }}
    >
      {value === target && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TikTokProfile: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const { mode } = theme.palette;
  const params = useParams();
  const dispatch = useDispatch();
  const { smDown } = useMediaQueries();
  const [show404, setShow404] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const { muiTab } = useSelector((state: { general: GeneralRootState }) => state.general);
  // last 7 days
  const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
  // get the end of the current month
  const endDate = moment().format("YYYY-MM-DD");

  const formik = useFormik<IFormDateFilter>({
    validateOnChange: false,
    initialValues: {
      start_min: startDate,
      start_max: endDate,
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    if (formik.values.start_min && formik.values.start_max) {
      dispatch(
        setDateFilter({
          start_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
          start_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
        })
      );
    }
  }, [dispatch, formik.values]);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // setValue(newValue);
    dispatch(setMuiTab(newValue));
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  const enableSwipeNavigate = false;

  let touchStartX = 0;
  let touchEndX = 0;
  const sensitivityThreshold = 200; // Adjust this value as needed

  let allTabs = [PROFILE_TAB_PANEL.OVERVIEW, PROFILE_TAB_PANEL.POSTS, PROFILE_TAB_PANEL.STATISTICS, PROFILE_TAB_PANEL.HASHTAG];

  function handleTouchStart(e: any) {
    if (!enableSwipeNavigate) return;
    touchStartX = e.touches[0].clientX;
  }

  function handleTouchEnd(e: any) {
    if (!enableSwipeNavigate) return;
    touchEndX = e.changedTouches[0].clientX;
    handleSwipe();
  }

  let currentTab = allTabs.findIndex(child => child === muiTab);
  function handleSwipe() {
    const swipeDistance = touchEndX - touchStartX;

    if (swipeDistance > sensitivityThreshold) {
      // Swiped right
      currentTab = (currentTab - 1 + allTabs.length) % allTabs.length;
    } else if (swipeDistance < -sensitivityThreshold) {
      // Swiped left
      currentTab = (currentTab + 1) % allTabs.length;
    }

    dispatch(setMuiTab(allTabs[currentTab]));
  }

  /* ================================================== */
  /* ================================================== */

  return (
    <OuterLayout maxWidth={"xl"}>
      {show404 ? (
        <NotFound404 />
      ) : (
        <Grid container spacing={2} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
          <Grid item xs={12}>
            <TikTokProfileBanner setShow404={setShow404} />
          </Grid>
          <Grid item xs={12}>
            <Paper
              elevation={5}
              sx={{
                width: "100%",
                mb: "50px",
                borderRadius: "8px",
                overflow: "hidden",
                position: "relative",
                transition: "all 0.5s ease",
              }}
            >
              {user && user.expired && <ExpiredOverlay />}
              <Stack
                direction="row"
                justifyContent={"center"}
                sx={{ background: mode === "light" ? "white" : (theme.palette.neutral as any)[900], width: "100%" }}
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  value={muiTab}
                  onChange={handleChange}
                  aria-label="wrapped label tabs example"
                  sx={{ pb: 1 }}
                >
                  <Tab
                    className="overview-step3"
                    {...a11yProps(0)}
                    value={PROFILE_TAB_PANEL.OVERVIEW}
                    label={
                      <Stack direction="row" alignItems={"center"} spacing={1}>
                        <FontAwesomeIcon icon={faChartColumn} size={smDown ? "lg" : "xl"} />
                        <Typography
                          variant={smDown ? "body2" : "h6"}
                          fontWeight={500}
                          color={PROFILE_TAB_PANEL.OVERVIEW === muiTab ? theme.palette.primary.main : theme.palette.text.secondary}
                        >
                          Overview
                        </Typography>
                      </Stack>
                    }
                  />
                  {/* <Tab
                  {...a11yProps(1)}
                  value={PROFILE_TAB_PANEL.GROWTH}
                  label={
                    <Stack direction="row" alignItems={"center"} spacing={1}>
                      <FontAwesomeIcon icon={faChartLineUp} size={smDown ? "lg" : "xl"} />
                      <Typography  variant={smDown ? "body2" : "h6"} fontWeight={500}>
                        Growth
                      </Typography>
                    </Stack>
                  }
                /> */}
                  <Tab
                    {...a11yProps(1)}
                    className="overview-step4"
                    value={PROFILE_TAB_PANEL.POSTS}
                    label={
                      <Stack direction="row" alignItems={"center"} spacing={1}>
                        <FontAwesomeIcon icon={faGrid} size={smDown ? "lg" : "xl"} />
                        <Typography
                          variant={smDown ? "body2" : "h6"}
                          fontWeight={500}
                          color={PROFILE_TAB_PANEL.POSTS === muiTab ? theme.palette.primary.main : theme.palette.text.secondary}
                        >
                          Posts
                        </Typography>
                      </Stack>
                    }
                  />
                  <Tab
                    {...a11yProps(2)}
                    className="overview-step5"
                    value={PROFILE_TAB_PANEL.STATISTICS}
                    label={
                      <Stack direction="row" alignItems={"center"} spacing={1}>
                        <FontAwesomeIcon icon={faChartPie} size={smDown ? "lg" : "xl"} />
                        <Typography
                          variant={smDown ? "body2" : "h6"}
                          fontWeight={500}
                          color={PROFILE_TAB_PANEL.STATISTICS === muiTab ? theme.palette.primary.main : theme.palette.text.secondary}
                        >
                          Statistics
                        </Typography>
                      </Stack>
                    }
                  />
                  <Tab
                    {...a11yProps(3)}
                    className="overview-step6"
                    value={PROFILE_TAB_PANEL.HASHTAG}
                    label={
                      <Stack direction="row" alignItems={"center"} spacing={1}>
                        <FontAwesomeIcon icon={faHashtag} size={smDown ? "lg" : "xl"} />
                        <Typography
                          variant={smDown ? "body2" : "h6"}
                          fontWeight={500}
                          color={PROFILE_TAB_PANEL.HASHTAG === muiTab ? theme.palette.primary.main : theme.palette.text.secondary}
                        >
                          Hashtags
                        </Typography>
                      </Stack>
                    }
                  />
                </Tabs>
              </Stack>
              <CustomTabPanel theme={theme} value={muiTab} target={PROFILE_TAB_PANEL.OVERVIEW}>
                <ProfileOverview formik={formik} socialMedia="tiktok" />
              </CustomTabPanel>
              <CustomTabPanel theme={theme} value={muiTab} target={PROFILE_TAB_PANEL.GROWTH}>
                Growth
              </CustomTabPanel>
              <CustomTabPanel theme={theme} value={muiTab} target={PROFILE_TAB_PANEL.POSTS}>
                <ProfilePosts formik={formik} username={params.username} />
              </CustomTabPanel>
              <CustomTabPanel theme={theme} value={muiTab} target={PROFILE_TAB_PANEL.STATISTICS}>
                <ProfileStatistics username={params.username} formik={formik} />
              </CustomTabPanel>
              <CustomTabPanel theme={theme} value={muiTab} target={PROFILE_TAB_PANEL.HASHTAG}>
                <ProfileHashtags formik={formik} />
              </CustomTabPanel>
            </Paper>
          </Grid>
        </Grid>
      )}
    </OuterLayout>
  );
};

export default TikTokProfile;
