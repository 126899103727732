import React, { useEffect, useMemo, useState } from "react";
/* components */
import ProfileSelection from "src/components/comparison/profiles/profile-selection";
import ComparisonTableComponent from "src/components/comparison/profiles/comparison-table-component";
/* 3rd party lib */
import moment from "moment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";

/* Util */
import { ICompareTikTokProfileJsonData, ICompareTikTokProfile } from "src/types/tiktok";
import { useComparisonProfileContext } from "src/contexts/comparison-profile-context";

interface ComparisonTableProps {
  profileData?: ICompareTikTokProfileJsonData;
  onDeleteProfile: (index: number) => void;
}

type Props = ComparisonTableProps;

const ComparisonTable: React.FC<Props> = ({ profileData, onDeleteProfile }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { formik, tableDirection, tableHeight, profileUsernames, openFullScreenDialog, setOpenFullScreenDialog } = useComparisonProfileContext();
  const contentColumnWidth = `${(100 - 216) / profileUsernames.length}%`;
  const [largestNumDict, setLargestNumDict] = useState<{ [key: string]: number } | {}>({});

  const columnDefinition = useMemo(
    () => [
      {
        key: "profile",
        label: "",
        cellStyle: {
          width: tableDirection === "horizontal" ? "220px" : contentColumnWidth,
          minWidth: tableDirection === "horizontal" ? "220px" : contentColumnWidth,
          textAlign: tableDirection === "horizontal" ? "left" : "center",
        },
        content: (data: ICompareTikTokProfile | null | undefined, index: number) => (
          <Stack
            justifyContent={
              tableDirection === "horizontal" ? "center" : profileData && Object.keys(profileData).length === 0 ? "center" : "flex-start"
            }
            alignItems={tableDirection === "horizontal" ? "flex-start" : "center"}
            sx={{
              width: tableDirection === "horizontal" ? "150px" : "auto",
              height: tableDirection === "horizontal" ? "30px" : `calc(100% - ${profileUsernames.length > 6 ? 30 : 30}px)`,
            }}
          >
            <ProfileSelection onDelete={onDeleteProfile} data={data} index={index} />
          </Stack>
        ),
      },
      {
        key: "total_play",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Plays
            </Stack>
          ) : (
            "Total Plays"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "total_like",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Likes
            </Stack>
          ) : (
            "Total Likes"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "total_comment",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Comments
            </Stack>
          ) : (
            "Total Comments"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "total_saved",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Saved
            </Stack>
          ) : (
            "Total Saved"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "total_share",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Shared
            </Stack>
          ) : (
            "Total Shared"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "count_video",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Total</Box>Videos
            </Stack>
          ) : (
            "Total Videos"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },

      {
        key: "count_following",
        label: "Following",
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "count_follower",
        label: "Followers",
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "median_engagement_rate",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Engagement</Box>Rate
            </Stack>
          ) : (
            "Engagement Rate"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
        },
      },
      {
        key: "date_range",
        label:
          tableDirection === "horizontal" ? (
            <Stack>
              <Box>Tracking</Box>Period
            </Stack>
          ) : (
            "Tracking Period"
          ),
        cellStyle: {
          width: contentColumnWidth,
          textAlign: "center",
          minWidth: tableDirection === "horizontal" ? "80px" : "160px",
          lineHeight: "16px",
        },
        content: (data: ICompareTikTokProfile | null | undefined, index: number) => {
          return (
            <Typography variant="caption" sx={{ fontSize: "14px", lineHeight: tableDirection === "horizontal" ? "0px" : "initial" }}>
              {data && "date_first" in data && "date" in data.date_first && (
                <>
                  {(!moment(formik.values.start_min).isSame(moment(data.date_first.date), "day") ||
                    !moment(formik.values.start_max).isSame(moment(data.date_last.date), "day")) && (
                    <>
                      {data.date_first.date && moment(data.date_first.date).format("DD MMM YY")}
                      {data.date_last.date && (
                        <>
                          &nbsp;-&nbsp;{tableDirection === "horizontal" && <br style={{ lineHeight: "6px" }} />}
                          {moment(data.date_last.date).format("DD MMM YY")}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Typography>
          );
        },
      },
      // {
      //   key: "count_engagement",
      //   label: "Engagement",
      //   cellStyle: {
      //     width: contentColumnWidth,
      //     textAlign: "center",
      //   },
      // },
      // {
      //   key: "count_growth",
      //   label: "Growth Rate",
      //   cellStyle: {
      //     width: contentColumnWidth,
      //     textAlign: "center",
      //   },
      // },
      // {
      //   key: "hashtags",
      //   label: "Top Hashtags",
      //   cellStyle: {
      //     width: contentColumnWidth,
      //     textAlign: "center",
      //   },
      // },
    ],
    [formik.values, tableDirection, profileData, onDeleteProfile, profileUsernames, contentColumnWidth]
  );
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    let tmpDict = columnDefinition.reduce((acc, col) => {
      const largestNum = profileUsernames.reduce((max, username) => {
        const profileObj: any = profileData?.results[username];

        if (profileObj && profileObj.date_last[col.key] && (profileObj.date_last as any)[col.key] > max) {
          return (profileObj.date_last as any)[col.key];
        }

        if (col.key === "median_engagement_rate") {
          if (profileObj && profileObj.profile.engagement_stats[col.key] > max) {
            return profileObj.profile.engagement_stats[col.key];
          }
        }

        return max;
      }, 0);
      (acc as any)[col.key] = largestNum;
      return acc;
    }, {});

    setLargestNumDict(tmpDict);
  }, [columnDefinition, profileData, profileUsernames]);

  // useEffect(() => {
  //   if (!(checkArrayExist(profileUsernames) && presetData && presetData.length > 0)) {
  //     setCurrentActive({ index: -1, preset: null });
  //   }
  // }, [checkArrayExist, profileUsernames, presetData, setCurrentActive]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <ComparisonTableComponent
        profileData={profileData}
        tableHeight={tableHeight}
        columnDefinition={columnDefinition}
        largestNumDict={largestNumDict}
      />

      <Dialog fullScreen fullWidth={true} open={openFullScreenDialog} onClose={() => setOpenFullScreenDialog(false)}>
        <DialogTitle sx={{ p: 2 }}>
          <Stack direction="row" justifyContent={"space-between"} alignItems="center" width="100%">
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <Typography variant="inherit">Full Screen</Typography>
            </Stack>
            <IconButton onClick={() => setOpenFullScreenDialog(false)}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <ComparisonTableComponent
              tableHeight={tableHeight}
              profileData={profileData}
              columnDefinition={columnDefinition}
              largestNumDict={largestNumDict}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ComparisonTable;
