/* components */
import { SimpleDataDisplay } from "src/pages/admin/superadmin-user";
import EditProfileInformationDialog from "src/components/common/dialog/edit-profile-information-dialog";
/* 3rd party lib */
import moment from "moment";
import { KeyedMutator } from "swr";
import React, { useState } from "react";
import { Box, Button, Paper, Stack, Typography, useTheme } from "@mui/material";
/* Util */
import { TSubscriptionAccount } from "src/types/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { nullUndefinedReturnDash } from "src/utils/general";
import { industries } from "src/components/common/inputs/industries-select";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface ProfileInformationProps {
  user_id?: string;
  data?: TSubscriptionAccount;
  mutate?: KeyedMutator<TSubscriptionAccount>;
}

type Props = ProfileInformationProps;

const ProfileInformation: React.FC<Props> = ({ data, user_id, mutate }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { smDown } = useMediaQueries();
  const [profileInformationDialog, setProfileInformationDialog] = useState(false);
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Paper elevation={10} sx={{ width: "100%" }}>
        <Box sx={{ p: smDown ? 2 : 4 }}>
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} mb={smDown ? 2 : 4}>
            <Typography textAlign="left" variant="h5" fontWeight={500} color="primary.main">
              Profile Information
            </Typography>
            {mutate && user_id && (
              <Button
                variant="outlined"
                endIcon={<FontAwesomeIcon icon={faPen} style={{ fontSize: "14px" }} />}
                onClick={() => setProfileInformationDialog(true)}
              >
                Edit
              </Button>
            )}
          </Stack>
          {data && (
            <Stack spacing={2} alignItems="flex-start">
              <SimpleDataDisplay label={"Name"} content={nullUndefinedReturnDash(data?.name)} />
              <SimpleDataDisplay
                label={"Active"}
                content={
                  data?.account_status ? (
                    <Box sx={{ background: theme.palette.success.main, width: "12px", height: "12px", borderRadius: "50%" }} />
                  ) : (
                    <Box sx={{ background: theme.palette.error.main, width: "12px", height: "12px", borderRadius: "50%" }} />
                  )
                }
              />
              <SimpleDataDisplay label={"Email"} content={nullUndefinedReturnDash(data?.email)} />
              <SimpleDataDisplay label={"Account No."} content={nullUndefinedReturnDash(data.account_number)} />
              <SimpleDataDisplay label={"Mobile Number"} content={nullUndefinedReturnDash(data?.phone_num)} />
              <SimpleDataDisplay label={"Company"} content={nullUndefinedReturnDash(data?.company_name)} />
              <SimpleDataDisplay
                label={"Industry"}
                content={nullUndefinedReturnDash(
                  (data?.industry && industries.find(child => data?.industry === child.value)?.label) || data?.industry
                )}
              />
              <SimpleDataDisplay label={"Country"} content={nullUndefinedReturnDash(data?.country)} />
              <SimpleDataDisplay label={"Created on"} content={nullUndefinedReturnDash(moment(data?.date_opened).format("DD/MM/YYYY"))} />
              <SimpleDataDisplay label={"Remark"} content={nullUndefinedReturnDash(data.remark)} />
            </Stack>
          )}
        </Box>
      </Paper>
      {data && user_id && profileInformationDialog && (
        <EditProfileInformationDialog
          mutate={mutate}
          user_id={user_id}
          profileInformation={data}
          open={profileInformationDialog}
          onClose={() => setProfileInformationDialog(false)}
        />
      )}
    </>
  );
};

export default ProfileInformation;
