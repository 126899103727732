/* components */
/* 3rd party lib */
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CircularProgress, IconButton, Stack, TextField, Typography, useTheme } from "@mui/material";
/* Util */
import { IUserProfile } from "src/slices/auth";
import { TSocialMedia } from "src/types/common";
import Insta from "src/images/insta.png";
import Tiktok from "src/images/darkModeTikTok.png";
import GreyOutTikTokIcon from "src/images/grey_tiktok.png";
import GreyOutInstaIcon from "src/images/grey_insta.png";
interface TrackInputProps {
  error: {
    show: boolean;
    message: string;
  };
  inputRef: any;
  user: IUserProfile | null;
  formik: FormikProps<{ search: string }>;
  placeholder: string;
  searchLoading: boolean;
  disableInput: boolean;
  socialMedia: TSocialMedia;
}

type Props = TrackInputProps;

const TrackInput: React.FC<Props> = ({ user, error, inputRef, formik, disableInput, socialMedia, searchLoading, placeholder }) => {
  const theme = useTheme();
  const { search } = formik.values;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      // Clear the input value when the Escape key is pressed
      formik.setFieldValue("search", "");
    }
  };
  const onClear = () => {
    formik.setFieldValue("search", "");
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <>
      <form id="searchForm" onSubmit={formik.handleSubmit}>
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            autoFocus
            value={search}
            variant="outlined"
            hiddenLabel
            fullWidth
            required
            onKeyDown={handleKeyDown}
            onChange={e => formik.setFieldValue("search", e.target.value)}
            sx={{
              "& .MuiOutlinedInput-input": {
                py: "14px !important",
              },
            }}
            inputRef={inputRef}
            disabled={!user?.isAdmin && (searchLoading || disableInput)}
            error={formik.errors.search ? true : false}
            helperText={formik.errors.search}
            placeholder={placeholder}
            size="small"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <>
                  {search !== "" && !searchLoading ? (
                    <IconButton onClick={onClear} sx={{ padding: 0 }}>
                      <FontAwesomeIcon icon={faCircleXmark} style={{ height: "16px", width: "16px", color: theme.palette.text.secondary }} />
                    </IconButton>
                  ) : (
                    <SearchRoundedIcon fontSize="medium" />
                  )}
                </>
              ),
            }}
          />
          <Button
            sx={{
              height: "47px",
            }}
            endIcon={
              !searchLoading && (
                <>
                  {socialMedia === "instagram" ? (
                    <img src={!user?.isAdmin && (searchLoading || disableInput) ? GreyOutInstaIcon : Insta} alt="insta" width="24px" height="24px" />
                  ) : (
                    <Box
                      sx={{
                        width: "28px",
                        height: "28px",
                        background: "white",
                        border: "0.5px solid #808080",
                        borderRadius: "50%",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <img
                        src={!user?.isAdmin && (searchLoading || disableInput) ? GreyOutTikTokIcon : Tiktok}
                        alt="tiktok"
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  )}
                </>
              )
            }
            size="large"
            type="submit"
            form="searchForm"
            disabled={!user?.isAdmin && (searchLoading || disableInput)}
            variant="contained"
            color={"primary"}
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="body2">Track</Typography>
              {searchLoading && <CircularProgress sx={{ ml: 1 }} size={18} color="inherit" />}
            </Stack>
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default TrackInput;
