import React, { useEffect, useState } from "react";
/* components */
import ProfileChartTitle from "src/components/comparison/profiles/profile-chart-title";
/* 3rd party lib */
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faPlay, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
/* Util */
import { IInstaBarData } from "src/pages/comparisons/profiles/instagram";
import { TImageDict } from "src/components/common/data-display/charts/bar-chart";

interface ProfileComparisonChartsProps {
  barChartData: IInstaBarData | null;
}

type Props = ProfileComparisonChartsProps;

const ProfileComparisonCharts: React.FC<Props> = React.memo(function ProfileComparisonCharts({ barChartData }) {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const [chartMode, setChartMode] = useState<"linear" | "log">("linear");
  const [imageDict, setImageDict] = useState<TImageDict | null>(null);

  let chartHeight = "280px";

  if (barChartData) {
    if (barChartData.xAxis.length > 8) {
      chartHeight = "500px";
    } else if (barChartData.xAxis.length > 4) {
      chartHeight = "350px";
    }
  }

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (barChartData && barChartData.images.length === barChartData.xAxis.length) {
      const tempImageDict = barChartData.xAxis.reduce((acc: TImageDict, xAxis, index) => {
        const newStr = xAxis.replace(/\./g, "_");
        acc[`${newStr}`] = { oriStr: "", image: barChartData.images[index], username: barChartData.usernames[index] };
        return acc;
      }, {});

      setImageDict(tempImageDict);
    }
  }, [barChartData]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Grid item xs={12} sx={{ p: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <ProfileChartTitle
            chartMode={chartMode}
            setChartMode={setChartMode}
            title={"Total Plays"}
            icon={<FontAwesomeIcon icon={faPlay} />}
            targetKey={"total_play"}
            chartHeight={chartHeight}
            barChartData={barChartData}
            imageDict={imageDict}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ProfileChartTitle
            chartMode={chartMode}
            setChartMode={setChartMode}
            title={"Total Likes"}
            icon={<FontAwesomeIcon icon={faThumbsUp} />}
            targetKey={"total_like"}
            chartHeight={chartHeight}
            barChartData={barChartData}
            imageDict={imageDict}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ProfileChartTitle
            chartMode={chartMode}
            setChartMode={setChartMode}
            title={"Total Comments"}
            icon={<FontAwesomeIcon icon={faComment} />}
            targetKey={"total_comment"}
            chartHeight={chartHeight}
            barChartData={barChartData}
            imageDict={imageDict}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={8}>
          <ProfileChartTitle
            chartMode={chartMode}
            setChartMode={setChartMode}
            title={"Followers"}
            targetKey={"count_follower"}
            chartHeight={chartHeight}
            barChartData={barChartData}
            imageDict={imageDict}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ProfileChartTitle
            chartMode={chartMode}
            title={"Posts"}
            setChartMode={setChartMode}
            targetKey={"count_post"}
            chartHeight={chartHeight}
            barChartData={barChartData}
            imageDict={imageDict}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={8}>
          <ProfileChartTitle
            chartMode={chartMode}
            setChartMode={setChartMode}
            title={"Engagement Rate"}
            targetKey={"engagement_rate"}
            chartHeight={chartHeight}
            barChartData={barChartData}
            imageDict={imageDict}
            symbol="%"
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <ProfileChartTitle
            chartMode={chartMode}
            setChartMode={setChartMode}
            title={"Following"}
            targetKey={"count_following"}
            chartHeight={chartHeight}
            barChartData={barChartData}
            imageDict={imageDict}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ProfileComparisonCharts;
