import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import useMediaQueries from "src/hooks/use-mediaqueries";
/* components */
/* 3rd party lib */
import { Link } from "react-router-dom";
/* Util */
import NotFound404Img from "src/images/404_2.png";
import DarkNotFound404Img from "src/images/404_2_dark.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/pro-solid-svg-icons";

interface NotFound404Props {
  post?: boolean;
}

type Props = NotFound404Props;

const NotFound404: React.FC<Props> = ({ post }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { smDown, mdDown } = useMediaQueries();
  const theme = useTheme();
  const { mode } = theme.palette;
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Stack
      sx={{
        width: "100%",
        height: "calc(100vh - 130px)",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Stack
        alignItems={"center"}
        spacing={4}
        sx={{
          padding: "20px",
          borderRadius: "20px",
          // background: mode === "dark" ? "none" : (theme.palette.neutral as any)[800],
        }}
      >
        <Stack alignItems={"center"}>
          <Typography variant="h1" color="error.light" sx={{ fontSize: smDown ? "50px" : "80px" }}>
            Not Found
          </Typography>
          <Typography variant="h6" color={mode === "dark" ? "#e2f5fd" : "#749eeb"} sx={{ mb: 1 }}>
            {post
              ? "Post doesn't exist or you are not subscribed to this post's profile!"
              : "Profile not found or you are not subscribed to this profile!"}
          </Typography>
          <Box sx={{ my: 2 }}>
            <Link to="/" style={{ color: theme.palette.primary.main, display: "flex", alignItems: "center", gap: "8px" }}>
              <FontAwesomeIcon icon={faCircleArrowLeft} size="lg" />
              <Typography variant="body1" color="primary.main">
                Back to home
              </Typography>
            </Link>
          </Box>
        </Stack>
        <Box sx={{ width: mdDown ? (smDown ? "80%" : "60vw") : "700px" }}>
          <img
            src={mode === "dark" ? DarkNotFound404Img : NotFound404Img}
            alt="404"
            width="100%"
            height="100%"
            style={{ transform: mdDown ? (smDown ? "translateX(0)" : "translateX(-10px)") : "translateX(-50px)" }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default NotFound404;
