import React, { useEffect, useRef, useState } from "react";
/* components */
import NavigationDrawer from "src/components/common/navigation/navigation-drawer";
/* 3rd party lib */
import useSWR from "swr";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Link, useLocation, /*  useLocation, */ useNavigate } from "react-router-dom";
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography, Stack, useTheme, Popover, Paper } from "@mui/material";

/* Util */
import routes from "src/routes";
import useAuth from "src/hooks/use-auth";
import useHttp from "src/hooks/use-http";
import { keyframes } from "@emotion/react";
import { setTheme } from "src/slices/general";
import LogoDark from "src/images/logo_dark.png";
import LogoLight from "src/images/logo_light.png";
import { RootState } from "src/store/root-reducer";
import { setNavbarHeight } from "src/slices/layout";
import { IStripeSubscription } from "src/types/common";
import useMediaQueries from "src/hooks/use-mediaqueries";
import ProfileSearch from "src/components/common/inputs/profile-search";
import { checkObjectNotEmpty } from "src/utils/general";
import useEffectAfterMount from "src/hooks/use-effectaftermount";
import { SUBSCRIPION_KEYS } from "src/utils/constants";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -50px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 50px);
  }
`;

interface TopNavbarProps {
  back?: boolean;
  version?: string;
}

type Props = TopNavbarProps;

const TopNavbar: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { apiEndpoint } = useHttp();
  const { mode } = theme.palette;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout } = useAuth();

  // const [socialMedia, setSocialMedia] = useState<TSocialMedia>("instagram");

  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { tutorialInProgress } = useSelector((state: RootState) => state.general);

  const { smDown, mdDown } = useMediaQueries();
  const navbarRef = useRef<any | null>(null);

  const location = useLocation();
  // Get the current route path
  const currentPath = location.pathname;
  const { data: subscriptionData } = useSWR<IStripeSubscription>(
    user && !checkObjectNotEmpty(user.subscription) && !currentPath.includes("account") && apiEndpoint.STRIPE_SUBSCRIPTION
  );

  // const superAdminUserPathPattern = /^\/superadmin\/users\/\d+$/;
  // const hideNavbarSearch =
  //   currentPath.includes("listing") || currentPath.includes("search") || currentPath.includes("comparison") || currentPath === "/";
  const hideNavbarSearch = true;
  const [openDrawer, setOpenDrawer] = useState(false);
  const pages = [
    // { label: "By Posts", url: routes.COMPARISON_POSTS },
    { label: "By Profiles", url: routes.COMPARISON_PROFILES },
  ];
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const { data: creditData } = useSWR<{ id: number; current_balance: number }>([apiEndpoint.USER_CREDIT]);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }

    setOpenDrawer(open);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (navbarRef.current) {
      dispatch(setNavbarHeight(navbarRef.current.clientHeight));
    }
  }, [dispatch]);

  useEffectAfterMount(() => {
    if (localStorage.getItem("theme") === undefined || localStorage.getItem("theme") === null) {
      localStorage.setItem("theme", "light");
    } else {
      dispatch(setTheme(localStorage.getItem("theme")));
    }
  }, [dispatch]);

  /* ================================================== */
  /* ================================================== */
  const fontColor = "black";
  return (
    <>
      {user &&
        !tutorialInProgress &&
        !checkObjectNotEmpty(user.subscription) &&
        (user.subscription.subscription_status === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS || !("subscription_status" in user.subscription)) &&
        !currentPath.includes("pricing") &&
        !currentPath.includes("account") &&
        !currentPath.includes("info") && (
          <Paper
            elevation={10}
            sx={{
              px: 2,
              py: 1,
              zIndex: 1199,
              position: "fixed",
              left: "50%",
              top: 0,
              animation: `${fadeInUp} 0.5s ease-in-out forwards`,
              minWidth: mdDown ? "unset" : "720px",
              borderRadius: "8px",
              background: (theme.palette.neutral as any)[mode === "dark" ? 800 : 100],
              border: `1px solid ${mode === "light" ? (theme.palette.grey as any)[200] : (theme.palette.grey as any)[500]}`,
              width: smDown ? "80%" : mdDown ? "60%" : "auto",
            }}
          >
            <Stack direction={mdDown ? "column" : "row"} spacing={2} alignItems="center">
              {user.subscription.subscription_status === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS && (
                <>
                  <Typography variant="body1" color="text.secondary">
                    You currently do not have
                    {smDown && <br />} any subscription plan. Choose a plan now.
                  </Typography>
                  <Button
                    sx={{ whiteSpace: "nowrap", minWidth: "180px" }}
                    variant="contained"
                    onClick={() => {
                      navigate(routes.PRICING, { state: { subscriptionData } });
                    }}
                  >
                    View Subscription Plan
                  </Button>
                </>
              )}

              {!("subscription_status" in user.subscription) && (
                <>
                  <Typography variant="body1" color="text.secondary">
                    Your account has expired, you can manage your subscription
                    {smDown && <br />} in 'My Account' page by clicking the button here.
                  </Typography>
                  <Button
                    sx={{ whiteSpace: "nowrap", minWidth: "180px" }}
                    variant="contained"
                    onClick={() => {
                      navigate(routes.PRICING, { state: { subscriptionData } });
                    }}
                  >
                    Go to 'My Account'
                  </Button>
                </>
              )}
            </Stack>
          </Paper>
        )}

      <AppBar ref={navbarRef} position={tutorialInProgress ? "static" : "fixed"} sx={{ color: fontColor }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <IconButton size="large" aria-controls="menu-appbar" aria-haspopup="true" onClick={toggleDrawer(true)} color="inherit" sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>

            <Stack direction="row" alignItems="center" spacing={0.5} mr={1}>
              {!smDown && (
                <Link to="/">
                  <img src={mode === "light" ? LogoLight : LogoDark} alt="logo" /* width="34px" */ height="28px" />
                </Link>
              )}
              {/* <Typography
                variant="h6"
                noWrap
                color="text.secondary"
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", lg: "flex" },
                  visibility: { xs: "hidden", lg: "visible" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  textDecoration: "none",
                }}
              >
                Intec
              </Typography> */}
            </Stack>

            {!mdDown && (
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                sx={{
                  display: { md: "none" },
                  visibility: { md: "hidden" },
                }}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Link to="/">
                    <img src={mode === "light" ? LogoLight : LogoDark} alt="logo" /* width="34px" */ height="28px" />
                  </Link>
                  {/* 
                 <Typography
                    variant="h5"
                    noWrap
                    color="text.secondary"
                    component="a"
                    href="/"
                    sx={{
                      fontFamily: "monospace",
                      fontWeight: 700,
                      letterSpacing: ".3rem",
                      textDecoration: "none",
                    }}
                  >
                    Intec
                  </Typography> */}
                </Stack>
              </Stack>
            )}

            <Box sx={{ flexGrow: 1, display: { md: "flex" }, justifyContent: "flex-end" }}>
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                {/* <Stack direction="row" spacing={2} alignItems="center">
                  {smDown ? (
                    <Button aria-describedby={id} variant="text" sx={{ minWidth: "30px", px: 0 }} onClick={handleClick}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                  ) : (
                    <>{!hideNavbarSearch && <ProfileSearch />}</>
                  )}
                </Stack> */}

                <Button variant="text" onClick={() => navigate(`${routes.MY_ACCOUNT}#profile`)}>
                  {user && (
                    <Typography variant="body1" color="text.secondary">
                      {user.name}
                    </Typography>
                  )}
                </Button>
                <IconButton
                  sx={{ ml: 1, color: theme.palette.text.secondary }}
                  onClick={() => {
                    if (mode === "dark") {
                      dispatch(setTheme("light"));
                      localStorage.setItem("theme", "light");
                    } else {
                      dispatch(setTheme("dark"));
                      localStorage.setItem("theme", "dark");
                    }
                  }}
                >
                  {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                {isAuthenticated ? (
                  <Button variant="outlined" color="primary" onClick={() => logout()}>
                    <Typography variant="body2" textAlign="center">
                      Logout
                    </Typography>
                  </Button>
                ) : (
                  <>
                    <Button variant="contained" color="primary" onClick={() => navigate(routes.LOGIN)}>
                      <Typography variant="body2" textAlign="center">
                        Login
                      </Typography>
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => navigate(routes.SIGNUP)}>
                      <Typography variant="body2" textAlign="center" whiteSpace="nowrap">
                        Sign Up
                      </Typography>
                    </Button>
                  </>
                )}
              </Stack>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                {pages.map(page => (
                  <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                    <Link to={page.url} style={{ textDecoration: "none" }}>
                      <Typography textAlign="center" color="text.secondary" variant="body2">
                        {page.label}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {smDown && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ width: "80vw" }}>{!hideNavbarSearch && <ProfileSearch handleClose={handleClose} />}</Box>
        </Popover>
      )}
      <NavigationDrawer open={openDrawer} onClose={toggleDrawer(false)} />
    </>
  );
};

export default TopNavbar;
