import React, { useEffect, useState } from "react";
/* components */
/* 3rd party lib */
import * as Yup from "yup";
//@ts-ignore
import ReactRecaptcha3 from "react-google-recaptcha3";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Box, Button, FormControl, Grid, IconButton, Paper, Stack, styled, TextField, Typography, useTheme } from "@mui/material";
/* Util */
import LogoLight from "src/images/logo_light.png";
import LogoDark from "src/images/logo_dark.png";
import routes from "src/routes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "src/components/common/feedback/spinner";
import { setTheme } from "src/slices/general";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockKeyholeOpen } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { getTranslateString } from "src/utils/translate";
import { FORM_WARNING_REQUIRED, FORM_WARNING_VALID_EMAIL } from "src/constants/translate-keys/common";
import useHttp from "src/hooks/use-http";
import { toast } from "react-hot-toast";
import useEffectAfterMount from "src/hooks/use-effectaftermount";
import moment from "moment";
import axios from "axios";

const StyledTextField = styled(TextField)(() => ({
  "& input": {
    overflow: "hidden",
    borderRadius: "8px",
    padding: "20px 8px",
  },
  "& .MuiInput-root": {
    overflow: "hidden",
    borderRadius: "8px",
  },
}));

interface ForgotPasswordProps {}

type Props = ForgotPasswordProps;

const schema = (t: any) =>
  Yup.object().shape({
    email: Yup.string().email(getTranslateString(t, FORM_WARNING_VALID_EMAIL)).required(getTranslateString(t, FORM_WARNING_REQUIRED)),
  });

const ForgotPassword: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const isDevelopment = process.env.NODE_ENV === "development";

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mode } = theme.palette;
  const { t } = useTranslation();
  const { apiEndpoint } = useHttp();
  const { smDown } = useMediaQueries();
  const [linkSent, setLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cooldown, setCooldown] = useState(0);
  const [showCooldown, setShowCooldown] = useState(false);

  const startTimer = () => {
    const timerEndAt = moment().add("60", "seconds"); // Get current system time
    localStorage.setItem("timerEndAt", timerEndAt.toString()); // Store start time in local storage
    setShowCooldown(true);
    setCooldown(60); // Set cooldown time in seconds
  };

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: schema(t),
    initialValues: {
      email: "",
    },
    onSubmit: async values => {
      setLoading(true);
      try {
        let token = "";

        // Get reCAPTCHA token only if not in development mode
        if (!isDevelopment) {
          token = await ReactRecaptcha3.getToken();
        }

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiEndpoint.FORGOT_PASSWORD.substring(1)}`, {
          email: values.email,
          ...(token && { recaptcha_token: token }), // Include recaptcha_token only if it's present
        });

        const data = response.data;

        setLoading(false);
        startTimer();
        setLinkSent(true);

        if (data && data.detail) {
          toast.success(data.detail);
        }
      } catch (err: any) {
        console.error(err);
        setLoading(false);

        if (err.response && err.response.data) {
          if (err.response.data.non_field_errors) {
            toast.error(err.response.data.non_field_errors[0]);
          } else {
            toast.error("Something went wrong when sending email");
          }
        } else {
          toast.error("Something went wrong when sending email");
        }
      }
    },
  });

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_KEY).then((status: any) => {
      // status: success/error
      // success - script is loaded and greaptcha is ready
      // error - script is not loaded
    });
  }, []);

  useEffectAfterMount(() => {
    const timerEndAt = localStorage.getItem("timerEndAt");
    if (timerEndAt !== undefined && timerEndAt !== null) {
      const differenceInSeconds = moment(timerEndAt).diff(moment(), "seconds");
      if (differenceInSeconds <= 60 && differenceInSeconds > 0) {
        setShowCooldown(true);
        setCooldown(differenceInSeconds); // Set cooldown time in seconds
      } else {
        setCooldown(0);
        setShowCooldown(false);
        localStorage.removeItem("timerEndAt");
      }
    }
  }, []);

  useEffect(() => {
    let timer: any;
    if (cooldown < 0) {
      setCooldown(0);
      setShowCooldown(false);
      localStorage.removeItem("timerEndAt");
    }

    if (showCooldown && cooldown > 0) {
      timer = setInterval(() => {
        setCooldown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            setShowCooldown(false);
            localStorage.removeItem("timerEndAt");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [showCooldown, cooldown]);
  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Box>
        <Button onClick={() => navigate(routes.HOME)} sx={{ position: "absolute", left: "16px", top: "16px" }}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <img src={mode === "light" ? LogoLight : LogoDark} alt="logo" height="40px" />
          </Stack>
        </Button>

        <IconButton
          sx={{ ml: 1, position: "absolute", right: "16px", top: "16px" }}
          onClick={() => {
            if (mode === "dark") {
              dispatch(setTheme("light"));
            } else {
              dispatch(setTheme("dark"));
            }
          }}
        >
          {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>

        <Box sx={{ height: "100vh", width: "100vw", display: "grid", placeItems: "center" }}>
          <Paper elevation={10} sx={{ width: smDown ? "85%" : "500px", px: smDown ? 2 : 3, py: 5 }}>
            <form noValidate onSubmit={formik.handleSubmit}>
              <FontAwesomeIcon icon={faLockKeyholeOpen} color={theme.palette.primary.main} size="4x" />
              <Typography variant={smDown ? "h5" : "h3"} sx={{ mt: 2 }}>
                Forgot Password
              </Typography>
              <Stack sx={{ mt: 2 }} spacing={2}>
                {linkSent ? (
                  <Stack>
                    <Typography variant="body1">
                      A password reset link has been sent to your email
                      <br />
                      <span style={{ fontSize: "18px", color: theme.palette.primary.main }}>
                        &nbsp;
                        <b>{formik.values.email}</b>
                      </span>
                    </Typography>
                  </Stack>
                ) : (
                  <Typography variant="body1">Enter your email below and a link will be sent to your email to reset your password.</Typography>
                )}

                {!linkSent && (
                  <FormControl fullWidth>
                    <StyledTextField
                      hiddenLabel
                      variant="outlined"
                      name="email"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.errors.email ? true : false}
                      helperText={formik.errors.email}
                      autoComplete="current-password"
                    />
                  </FormControl>
                )}
              </Stack>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                {linkSent ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Button onClick={() => navigate(routes.LOGIN)} fullWidth type="submit" size="large" variant="contained" color="primary">
                        <Typography variant="body1">Go to Login</Typography>
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        type="submit"
                        disabled={loading || showCooldown}
                        endIcon={loading ? <SpinnerComponent color="white" modifier={0.4} /> : null}
                        size="large"
                        variant="contained"
                        color="secondary"
                      >
                        <Typography variant="body1" color={loading || showCooldown ? "grey.400" : "white"}>
                          {showCooldown ? (
                            <>
                              Resend Link in <b>{cooldown}</b>&nbsp;(s)
                            </>
                          ) : (
                            "Resend Link"
                          )}
                        </Typography>
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      type="submit"
                      disabled={loading || showCooldown}
                      endIcon={loading ? <SpinnerComponent color="white" modifier={0.4} /> : null}
                      size="large"
                      variant="contained"
                      color="secondary"
                    >
                      <Typography variant="body1" color={loading || showCooldown ? "grey.400" : "white"}>
                        {showCooldown ? (
                          <>
                            Resend Link in <b>{cooldown}</b>&nbsp;(s)
                          </>
                        ) : (
                          "Send Link"
                        )}
                      </Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
