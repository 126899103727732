import React from "react";
/* components */
/* 3rd party lib */
import PresetSelectionList from "src/components/comparison/preset-selection-list";
/* Util */
import { useComparisonPostContext } from "src/contexts/comparison-post-context";
import { ICurrentActivePostPreset } from "src/pages/comparisons/posts/posts";
import { ICurrentActiveProfilePreset } from "src/pages/comparisons/profiles/profiles";
import { IPostPresetData } from "src/types/instagram";

interface PostPresetSelectionListProps {
  tableHeight?: number;
  onClose?: () => void;
}

type Props = PostPresetSelectionListProps;

const PostPresetSelectionList: React.FC<Props> = ({ onClose, tableHeight }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const {
    collapse,
    triggerVibration,
    currentActive,
    presetData,
    setPostIds,
    setCollapse,
    setPresetDialog,
    setCurrentActive,
    setCrudPresetDialog,
    setEditPresetDialog,
  } = useComparisonPostContext();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <PresetSelectionList
      collapse={collapse}
      setCollapse={setCollapse}
      tableHeight={tableHeight}
      currentActive={currentActive}
      setPresetDialog={setPresetDialog}
      triggerVibration={triggerVibration}
      setCrudPresetDialog={setCrudPresetDialog}
      presetData={presetData as IPostPresetData[]}
      setCurrentActive={setCurrentActive as React.Dispatch<React.SetStateAction<ICurrentActiveProfilePreset | ICurrentActivePostPreset>>}
      handlePresetClick={(index, preset) => {
        if (preset && "posts" in preset) {
          setCurrentActive({ index: index, preset: preset });

          if (preset.posts.length === 0) {
            // if the length is 0, set the array to empty strings
            setPostIds(["", ""]);
          } else {
            if (preset.posts.length === 1) {
              setPostIds([preset.posts[0].post_id, ""]);
            } else {
              setPostIds(preset.posts.map(child => child.post_id));
            }
          }
        }
        if (onClose) {
          onClose();
        }
      }}
      handleEditPreset={(e, preset) => {
        if (preset && "posts" in preset) {
          e.stopPropagation();
          setEditPresetDialog({ open: true, preset });
        }
      }}
    />
  );
};

export default PostPresetSelectionList;
