/* components */
/* 3rd party lib */
import moment from "moment";
import { FormikProps } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarClock } from "@fortawesome/pro-solid-svg-icons";
import { Box, Drawer, Grid, IconButton, Paper, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { faComment, faPlay, faPhotoFilm, faThumbsUp, faBookmark, faShare, faMobile } from "@fortawesome/pro-regular-svg-icons";
/* Util */
import { ITikTokPostStats } from "src/types/tiktok";
import { IInstaPostStats } from "src/types/instagram";
import { scroll } from "src/theme/dark-theme-options";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { formatNumberWithSuffix } from "src/utils/general";
import { IFormDateFilter, TSocialMedia } from "src/types/common";
import InstaProfilePostGrid from "src/components/profile/instagram/profile-post-grid";
import TiktokProfilePostGrid from "src/components/profile/tiktok/profile-post-grid";
import { INSTA_METRICS } from "src/components/comparison/profiles/instagram/profile-top-posts";

interface PostHeatmapDrawerProps {
  open: boolean;
  onClose: () => void;
  socialMedia: TSocialMedia;
  formik: FormikProps<IFormDateFilter>;
  data: IInstaPostStats | ITikTokPostStats | null | undefined;
}

type Props = PostHeatmapDrawerProps;

const PostHeatmapDrawer: React.FC<Props> = ({ data, formik, open, onClose, socialMedia }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { mode } = theme.palette;
  const params = useParams();
  const { ssDown, smDown, mdDown } = useMediaQueries();

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const renderValue = (value: string | number) => {
    return typeof value === "number" ? formatNumberWithSuffix(value) : value;
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Drawer
      PaperProps={{ sx: { width: smDown ? "90vw" : mdDown ? "82vw" : "650px", ...scroll } }}
      anchor={"right"}
      open={open}
      onClose={onClose}
      sx={{ position: "relative", maxWidth: mdDown ? "80%" : "1000px" }}
    >
      <IconButton onClick={onClose} sx={{ position: "absolute", right: "16px", top: "16px" }}>
        <CloseRoundedIcon fontSize="small" />
      </IconButton>
      {data ? (
        <Stack sx={{ p: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems="center" spacing={2} sx={{ pl: 1.5, pt: 2, pb: 1 }}>
                <FontAwesomeIcon size={smDown ? "xl" : "2x"} icon={faCalendarClock} color={theme.palette.text.secondary} />
                <Stack direction={"row"} alignItems="center" spacing={1}>
                  <Typography variant={smDown ? "h6" : "h5"} color="text.secondary">
                    {moment(data.day, "ddd").format("dddd")}
                  </Typography>
                  <Typography variant={smDown ? "h6" : "h5"} color="text.secondary">
                    {moment(data.time, "HH").format("HH:mm")}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary.main" mt={1}>
                Statistics
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ px: 2, py: 1.5, background: (theme.palette.neutral as any)[mode === "light" ? 100 : 800] }} variant="outlined">
                <Stack>
                  <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                    <Typography variant={smDown ? "body1" : "h6"} color="text.secondary">
                      Post Count
                    </Typography>
                    <FontAwesomeIcon icon={faPhotoFilm} color={theme.palette.text.secondary} />
                  </Stack>
                  <Typography variant={smDown ? "h5" : "h4"} color="primary.main">
                    {renderValue(data.post)}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ px: 2, py: 1.5, background: (theme.palette.neutral as any)[mode === "light" ? 100 : 800] }} variant="outlined">
                <Stack>
                  <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                    <Typography variant={smDown ? "body1" : "h6"} color="text.secondary">
                      Average Play
                    </Typography>
                    <FontAwesomeIcon icon={faPlay} color={theme.palette.text.secondary} />
                  </Stack>

                  <Typography variant={smDown ? "h5" : "h4"} color="primary.main">
                    {renderValue(data.average_play)}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ px: 2, py: 1.5, background: (theme.palette.neutral as any)[mode === "light" ? 100 : 800] }} variant="outlined">
                <Stack>
                  <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                    <Typography variant={smDown ? "body1" : "h6"} color="text.secondary">
                      Average Like
                    </Typography>
                    <FontAwesomeIcon icon={faThumbsUp} color={theme.palette.text.secondary} />
                  </Stack>

                  <Typography variant={smDown ? "h5" : "h4"} color="primary.main">
                    {renderValue(data.average_like)}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ px: 2, py: 1.5, background: (theme.palette.neutral as any)[mode === "light" ? 100 : 800] }} variant="outlined">
                <Stack>
                  <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                    <Typography variant={smDown ? "body1" : "h6"} color="text.secondary">
                      Average Comment
                    </Typography>
                    <FontAwesomeIcon icon={faComment} color={theme.palette.text.secondary} />
                  </Stack>
                  <Typography variant={smDown ? "h5" : "h4"} color="primary.main">
                    {renderValue(data.average_comment)}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            {"average_share" in data && (
              <Grid item xs={12} sm={6}>
                <Paper sx={{ px: 2, py: 1.5, background: (theme.palette.neutral as any)[mode === "light" ? 100 : 800] }} variant="outlined">
                  <Stack>
                    <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                      <Typography variant={smDown ? "body1" : "h6"} color="text.secondary">
                        Average Share
                      </Typography>
                      <FontAwesomeIcon icon={faShare} color={theme.palette.text.secondary} />
                    </Stack>

                    <Typography variant={smDown ? "h5" : "h4"} color="primary.main">
                      {renderValue(data.average_share)}
                    </Typography>
                  </Stack>
                </Paper>
              </Grid>
            )}
            {"average_saved" in data && (
              <Grid item xs={12} sm={6}>
                <Paper sx={{ px: 2, py: 1.5, background: (theme.palette.neutral as any)[mode === "light" ? 100 : 800] }} variant="outlined">
                  <Stack>
                    <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                      <Typography variant={smDown ? "body1" : "h6"} color="text.secondary">
                        Average Saved
                      </Typography>
                      <FontAwesomeIcon icon={faBookmark} color={theme.palette.text.secondary} />
                    </Stack>
                    <Typography variant={smDown ? "h5" : "h4"} color="primary.main">
                      {renderValue(data.average_saved)}
                    </Typography>
                  </Stack>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Paper sx={{ px: 2, py: 1.5, background: (theme.palette.neutral as any)[mode === "light" ? 100 : 800] }} variant="outlined">
                <Stack>
                  <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                    <Typography variant={smDown ? "body1" : "h6"} color="text.secondary">
                      Average Engagement Rate
                    </Typography>
                    <FontAwesomeIcon icon={faMobile} color={theme.palette.text.secondary} />
                  </Stack>

                  <Typography variant={smDown ? "h5" : "h4"} color="primary.main">
                    {data.average_er.toFixed(2)}%
                  </Typography>
                </Stack>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1} pt={2}>
                <Typography variant="h5" color="primary.main" mt={1}>
                  Posts
                </Typography>

                <Paper sx={{ p: 1, background: (theme.palette.neutral as any)[mode === "light" ? 100 : 800] }}>
                  {socialMedia === "instagram" ? (
                    <InstaProfilePostGrid
                      formik={formik}
                      height="auto"
                      metric={INSTA_METRICS.count_play}
                      username={params.username}
                      customGridSize={{ xs: 6, sm: 4, md: 3, lg: 3, xl: 3 }}
                      apiConfig={{ day_of_week: data.day.toLowerCase(), hour_of_day: data.time }}
                    />
                  ) : (
                    <TiktokProfilePostGrid
                      formik={formik}
                      height="auto"
                      metric={INSTA_METRICS.count_play}
                      username={params.username}
                      customGridSize={{ xs: 6, sm: 4, md: 3, lg: 3, xl: 3 }}
                      apiConfig={{ day_of_week: data.day.toLowerCase(), hour_of_day: data.time }}
                    />
                  )}
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      ) : (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={1} justifyContent="space-around">
            <Grid item xs={12}>
              <Skeleton width="200px" sx={{ pt: 2 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary.main" mt={1}>
                Statistics
              </Typography>
            </Grid>
            {Array.from({ length: 4 }).map((_, index) => (
              <Grid key={`'loading_grid_${index}`} item xs={12} sm={6} sx={{ display: "grid", placeItems: "center", padding: 0, margin: 0 }}>
                <Skeleton
                  sx={{
                    transform: "scale(1)",
                    width: "100%",
                    height: "100px",
                    margin: 0,
                    borderRadius: "8px",
                  }}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Typography variant="h5" color="primary.main" mt={1}>
                Posts
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <Grid container spacing={1} justifyContent="space-around">
                  {Array.from({ length: smDown ? 6 : 4 }).map((_, index) => (
                    <Grid
                      key={`'loading_grid_${index}`}
                      item
                      xs={ssDown ? 6 : 4}
                      sm={3}
                      md={3}
                      lg={3}
                      sx={{ display: "grid", placeItems: "center", padding: 0, margin: 0 }}
                    >
                      <Skeleton
                        sx={{
                          transform: "scale(1)",
                          width: "100%",
                          height: ssDown ? "50vw" : smDown ? "180px" : "200px",
                          margin: 0,
                          borderRadius: "8px",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Drawer>
  );
};

export default PostHeatmapDrawer;
