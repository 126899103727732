import { Stack } from "@mui/material";
import { TInstaProfilePost } from "src/types/instagram";
import InstaPostGrid from "src/pages/listing/instagram/insta-post-grid";
import InstaProfileBanner from "src/components/profile/instagram/profile-banner";

interface InstaPostListingProps {
  search?: string;
  selection?: boolean;
  multiSelect?: boolean;
  username: string | undefined;
  handleClose?: () => void;
  onSelectPost?: (postId: string) => void;
}

type Props = InstaPostListingProps;

const InstaPostListing: React.FC<Props> = ({ search, username, selection, multiSelect = false, handleClose, onSelectPost }) => {
  const handleSelect = (rowData: TInstaProfilePost) => {
    if (onSelectPost && handleClose) {
      onSelectPost(rowData.post_id);
      handleClose();
    }
  };

  return (
    <Stack spacing={1}>
      <InstaProfileBanner username={username} viewOnly />
      <InstaPostGrid
        params={{ ordering: "-date_posted" }}
        multiSelect={multiSelect}
        inDialog
        search={search}
        username={username}
        onSelectPost={handleSelect}
      />
    </Stack>
  );
};
export default InstaPostListing;
