import { ITranslateObj } from "src/constants/translate-keys/common";

/** Example:
 *
 *  Json - {error:"Something went wrong when {{action}}", action:"updating {{subject}}", subject:"name"}
 *
 *  getToastError(error, action, subject)
 *
 *  result - "Something went wrong when updating name"
 *
 *  mainObj will be error, actionObj will be action, subjectObj will be subject, lowerCase will default to true
 *
 *  @param t - t from useTranslation
 *  @param mainObj - The main string that has the {{action}} variable
 *  @param actionObj - The action of the error that contains {{subject}} variable
 *  @param subjectObj - The subject variable of the action string
 *  @param plural - default false, bool to determine whether string should be plural
 *  @param lowerCase - default true, bool to determine letter case
 *  @returns Result string with action and subject within the main string
 */

export const getToastError = (
  t: any,
  mainObj: ITranslateObj,
  actionObj: ITranslateObj,
  subjectObj: ITranslateObj,
  plural = false,
  lowerCase = true
) => {
  let subject_str = plural ? t(subjectObj.key, subjectObj.backup, { count: 2 }) : t(subjectObj.key, subjectObj.backup);
  subject_str = lowerCase ? subject_str.toLowerCase() : subject_str;

  let result_str = "";
  if (actionObj === undefined) {
    // if no action available, then use subject_str
    result_str = t(mainObj.key, mainObj.backup, { action: subject_str });
  } else {
    const action_str = t(actionObj.key, actionObj.backup, {
      subject: subject_str,
    });
    result_str = t(mainObj.key, mainObj.backup, { action: action_str });
  }
  return result_str;
};

/** Example:
 *
 *  Json - {error:"Something went wrong when {{action}}", action:"updating {{subject}}", subject:"name"}
 *
 *  getToastError(error, action, subject)
 *
 *  result - "Something went wrong when updating name"
 *
 *  mainObj will be error, actionObj will be action, subjectObj will be subject, lowerCase will default to true
 *
 *  @param t - t from useTranslation
 *  @param mainObj - The main string that has the {{action}} variable
 *  @param actionObj - The action of the error that contains {{subject}} variable
 *  @param subjectObj - The subject variable of the action string
 *  @param plural - default false, bool to determine whether string should be plural
 *  @param lowerCase - default true, bool to determine letter case
 *  @param custom - default false, bool to determine whether to use custom string or translate key for subjectObj
 *  @returns Result string with action and subject within the main string
 */
export const getToastSuccess = (
  t: any,
  mainObj: ITranslateObj,
  actionObj: ITranslateObj,
  subjectObj: any, //can be object or string
  plural = false,
  lowerCase = true,
  custom = false
) => {
  // if custom true, use the subjectObj directly because it should be a string, if not then proceed to use the translate obj
  let subject_str = "";
  subject_str = custom ? subjectObj : plural ? t(subjectObj.key, subjectObj.backup, { count: 2 }) : t(subjectObj.key, subjectObj.backup);
  subject_str = lowerCase ? subject_str.toLowerCase() : subject_str;

  let result_str = "";
  if (actionObj === undefined) {
    // if no action available, then use subject_str
    result_str = t(mainObj.key, mainObj.backup, { action: subject_str });
  } else {
    const action_str = t(actionObj.key, actionObj.backup, {
      subject: subject_str,
    });
    result_str = t(mainObj.key, mainObj.backup, { action: action_str });
  }
  return result_str;
};

/** Example:
 *
 *  Json - {search:"Search {{subject}}", title:"Title"}
 *
 *  getTranslateStringWithSubject(search, title)
 *
 *  result - "Search Title"
 *
 *  mainObj will be search and subjectObj will be title, lowerCase will default to true
 *
 *  @param t - t from useTranslation
 *  @param mainObj - The main string that has the {{subject}} variable
 *  @param subjectObj - The subject variable of the main string
 *  @param plural - default false, bool to determine whether string should be plural
 *  @param lowerCase - default true, bool to determine letter case
 *  @returns Result string with subject within the main string
 */
export const getTranslateStringWithSubject = (t: any, mainObj: ITranslateObj, subjectObj: ITranslateObj, plural = false, lowerCase = true) => {
  let subject_str = plural ? t(subjectObj.key, subjectObj.backup, { count: 2 }) : t(subjectObj.key, subjectObj.backup);
  subject_str = lowerCase ? subject_str.toLowerCase() : subject_str;
  const result_str = t(mainObj.key, mainObj.backup, { subject: subject_str });
  return result_str;
};

/** Example:
 *
 *  Json - {edit: "Edit {{subjectStr}}"}
 *
 *  getTranslateStringWithCustomSubject(edit, "CUSTOM STRING FROM SERVER")
 *
 *  result - "Edit CUSTOM STRING FROM SERVER"
 *
 *  mainObj will be edit and subjectStr will be custom string, lowerCase will default to false
 *
 *  @param t - t from useTranslation
 *  @param mainObj - The main string that has the {{subject}} variable
 *  @param subjectStr - The subject variable of the main string
 *  @param lowerCase - default true, bool to determine letter case
 *  @returns Result string with main string and custom subject behind
 */
export const getTranslateStringWithCustomSubject = (t: any, mainObj: ITranslateObj, subjectStr: string, lowerCase = false) => {
  let subject_str = lowerCase ? subjectStr.toLowerCase() : subjectStr;
  const result_str = t(mainObj.key, mainObj.backup, { subject: subject_str });
  return result_str;
};

/** Example:
 *
 *  Json - {search:"Search"}
 *
 *  getTranslateString(t, search)
 *
 *  result - "Search"
 *
 *  @param t - t from useTranslation
 *  @param mainObj - The main string
 *  @param plural - default false, bool to determine whether string should be plural
 *  @param lowerCase - default false, bool to determine letter case
 *  @returns Result translated main string
 */
export const getTranslateString = (t: any, mainObj: ITranslateObj, plural = false, lowerCase = false) => {
  let result_str = plural ? t(mainObj.key, mainObj.backup, { count: 2 }) : t(mainObj.key, mainObj.backup);
  result_str = lowerCase ? result_str.toLowerCase() : result_str;
  return result_str;
};

/**
 *
 * Json - {action: "{{child}} removed from {{parent}} "}
 *
 * getTranslateChildParent(t, action, "Asset","Work Order")
 *
 * Return - Asset removed from Work Order
 *
 * @param {*} t - t from useTranslation
 * @param {*} actionObj - the action that has both variables
 * @param {*} childObj
 * @param {*} parentObj
 * @param {*} lowerCase - default false, bool to determine letter case
 * @returns {string}
 */
export const getTranslateChildParent = (t: any, actionObj: ITranslateObj, childObj: ITranslateObj, parentObj: ITranslateObj) => {
  let result_str = t(actionObj.key, actionObj.backup, {
    child: t(childObj.key, childObj.backup),
    parent: t(parentObj.key, parentObj.backup),
  });
  return result_str;
};
