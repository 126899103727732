import { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { BUTTON_CANCEL, SORT } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { getTranslateString } from "src/utils/translate";

interface MobileViewListSortDialogProps {
  open: boolean;
  onClose: () => void;
  sort: any;
  sortFields: any;
  onSort: any;
}

type Props = MobileViewListSortDialogProps;

const MobileViewListSortDialog: React.FC<Props> = ({ open, onClose, sort, sortFields, onSort }) => {
  const { t } = useTranslation();
  const [result, setResult] = useState<any | null>(sort || null);

  const handleCheckboxChange = (key: any, value: any) => {
    const newResult = { ...result };

    if (!value) {
      delete newResult[key];
    } else {
      newResult[key] = "asc";
    }

    setResult(newResult);
  };

  const handleSortOrderChange = (key: string, order: any) => {
    if (order !== null) {
      if (!result[key]) return;
      setResult((state: any) => ({ ...state, [key]: order }));
    }
  };

  const handleSortSubmit = () => {
    onSort(result);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{getTranslateString(t, SORT)}</DialogTitle>
      <DialogContent>
        <List dense disablePadding>
          {sortFields.map((field: any) => (
            <ListItem
              key={field.key}
              disablePadding
              secondaryAction={
                result?.[field.key] && (
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={result[field.key]}
                    onChange={(e, value) => handleSortOrderChange(field.key, value)}
                    disabled={result[field.key] ? false : true}
                    size="small"
                  >
                    <ToggleButton value="asc" aria-label="ascending">
                      <ArrowUpwardRoundedIcon fontSize="small" />
                    </ToggleButton>
                    <ToggleButton value="desc" aria-label="descending">
                      <ArrowDownwardRoundedIcon fontSize="small" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                )
              }
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={result?.[field.key] ? true : false} onChange={e => handleCheckboxChange(field.key, e.target.checked)} />
                  }
                  label={field.label}
                  componentsProps={{ typography: { fontSize: 14 } }}
                />
              </FormGroup>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} size="small">
          {getTranslateString(t, BUTTON_CANCEL)}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSortSubmit} fullWidth size="small">
          {getTranslateString(t, SORT)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileViewListSortDialog;
