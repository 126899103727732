import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, SxProps, TextField, TextFieldVariants, Theme, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";

interface DelaySubmitInputProps {
  variant: TextFieldVariants;
  clearInput: boolean;
  value: string;
  clearIcon?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  placeholder?: string;
  styles?: SxProps<Theme>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

type Props = DelaySubmitInputProps;

const DelaySubmitInput: React.FC<Props> = ({
  styles = {},
  value,
  setValue,
  loading,
  placeholder = "",
  clearInput,
  icon,
  clearIcon = true,
  variant = "outlined",
  ...props
}) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const [term, setTerm] = useState("");
  const [typing, setTyping] = useState({
    isTyping: false,
    typingTimeout: 0,
  });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      // Clear the input value when the Escape key is pressed
      setTerm("");
      setValue("");
    }
  };

  const handleDelayUpdate = (value: string) => {
    if (typing.typingTimeout) {
      clearTimeout(typing.typingTimeout);
    }

    setTerm(value);

    setTyping({
      ...typing,
      isTyping: false,
      typingTimeout: setTimeout(function () {
        // after x seconds, send target value to backend
        setValue(value);
      }, 500) as any,
    });
  };

  const onClear = () => {
    setTerm("");
    setValue("");
  };

  useEffect(() => {
    if (clearInput) {
      setTerm("");
      setValue("");
    }
  }, [clearInput, setValue]);

  useEffect(() => {
    if (value) {
      setTerm(value);
      setValue(value);
    }
  }, [value, setValue]);

  return (
    <>
      <TextField
        value={term}
        variant={variant}
        hiddenLabel
        fullWidth
        onKeyDown={handleKeyDown}
        disabled={loading}
        onChange={e => handleDelayUpdate(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-input": {
            py: "14px !important",
            boxShadow: `0px 0px 0px 100px ${mode === "dark" ? (theme.palette as any).neutral[900] : "#ffffff"} inset`,
          },

          ...styles,
        }}
        placeholder={placeholder}
        size="small"
        {...props}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          ...(props as any).InputProps,
          // startAdornment: (
          // ),
          endAdornment: clearIcon ? (
            <>
              {clearIcon && value !== "" && !loading ? (
                <IconButton onClick={onClear} sx={{ padding: 0 }}>
                  <FontAwesomeIcon icon={faCircleXmark} style={{ height: "16px", width: "16px", color: theme.palette.text.secondary }} />
                </IconButton>
              ) : (
                <IconButton>{icon ? icon : <SearchIcon />}</IconButton>
              )}
            </>
          ) : (
            (props as any).InputProps.endAdornment
          ),
        }}
      />
    </>
  );
};

export default DelaySubmitInput;
