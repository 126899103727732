/* components */
import StockTicker from "src/components/profile/instagram/stock-ticker";
import PostCalendarDetail from "src/components/profile/posts-calendar-detail";
import PostsStatistics from "src/components/profile/instagram/profile-overview/posts-statistics";
/* 3rd party lib */
import moment from "moment";
import { FormikProps } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { DatesSetArg, EventSourceInput } from "@fullcalendar/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, lighten, Paper, Stack, Theme, Typography } from "@mui/material";
/* Util */
import { Global } from "@emotion/react";
import { IFormDateFilter, TSocialMedia } from "src/types/common";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { TInstaProfileStatistics } from "src/types/instagram";

interface PostsCalendarProps {
  data?: TInstaProfileStatistics;
  theme: Theme;
  socialMedia: TSocialMedia;
  formik: FormikProps<IFormDateFilter>;
}

type Props = PostsCalendarProps;

const PostsCalendar: React.FC<Props> = React.memo(function PostsCalendar({ data, theme, formik }) {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { mode } = theme.palette;
  const calendarRef = useRef<any | null>(null);

  const { smDown, mdDown } = useMediaQueries();

  const calendarHeight = mdDown ? 420 : 600;

  const [calendarEvents, setCalendarEvents] = useState<{ event: EventSourceInput; minPostCount: number; maxPostCount: number } | undefined>();

  const [openCalendarDetail, setOpenCalendarDetail] = useState<{ open: boolean; data: { postNum: number; date: string } | null }>({
    open: false,
    data: null,
  });

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleRenderCalendarColor = useCallback(() => {
    const cells = document.querySelectorAll(".fc-daygrid-body td[data-date]");
    cells.forEach(cell => {
      if (moment((cell as any).dataset.date).isBefore(moment(formik.values.start_min).format("YYYY-MM-DD"))) {
        //  date before start
        // (cell as any).style.backgroundColor = (theme.palette.neutral as any)[400];
        if (theme.palette.mode === "light") {
          (cell as any).style.backgroundColor = (theme.palette.grey as any)[100];
        } else {
          (cell as any).style.backgroundColor = "#000000";
        }
      } else if (moment((cell as any).dataset.date).isAfter(moment(formik.values.start_max).format("YYYY-MM-DD"))) {
        if (theme.palette.mode === "light") {
          //  date after end
          (cell as any).style.backgroundColor = (theme.palette.grey as any)[100];
        } else {
          (cell as any).style.backgroundColor = "#000000";
        }
      } else if (moment((cell as any).dataset.date).isSame(moment().format("YYYY-MM-DD"))) {
        // check if it's today
        (cell as any).style.backgroundColor = theme.palette.mode === "light" ? (theme.palette.neutral as any)[200] : "#3D4E73";
      } else {
        // (cell as any).style.backgroundColor = theme.palette.mode === "light" ? "initial" : (theme.palette.neutral as any)[800];
        (cell as any).style.backgroundColor = theme.palette.mode === "light" ? "white" : (theme.palette.neutral as any)[800];
      }
    });
  }, [formik.values.start_max, formik.values.start_min, theme]);

  const handleEventClick = (arg: any) => {
    setOpenCalendarDetail({ open: true, data: { postNum: arg.event.title, date: arg.event.start } });
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (calendarRef.current) {
      handleRenderCalendarColor();
    }
  }, [handleRenderCalendarColor, formik.values.start_max, formik.values.start_min]);

  useEffect(() => {
    if (data) {
      let tempCalendarEvents: EventSourceInput = [];

      let minPostCount = Infinity; // Initialize with positive infinity to ensure any value will be smaller
      let maxPostCount = -Infinity; // Initialize with negative infinity to ensure any value will be larger

      for (const [date, postAmount] of Object.entries(data.day)) {
        // Update min and max values
        minPostCount = Math.min(minPostCount, postAmount);
        maxPostCount = Math.max(maxPostCount, postAmount);

        tempCalendarEvents.push({
          title: postAmount.toString(),
          start: date, // Use the date as the start date
          allDay: true, // Set to true for all-day events
          color: "transparent", // Customize the color of the events
        });
      }

      setCalendarEvents({ event: tempCalendarEvents, minPostCount: minPostCount, maxPostCount: maxPostCount });
    }
  }, [theme, data]);

  function renderEventContent(eventInfo: any) {
    if (!calendarEvents) return null;

    const { maxPostCount, minPostCount } = calendarEvents;
    const postAmount = parseInt(eventInfo.event.title);
    const percentage = (postAmount - minPostCount) / (maxPostCount - minPostCount);

    let bgColor = "#0ABAB5";
    let fontColor = (theme.palette.neutral as any)[900];

    if (percentage > 0.9) {
      bgColor = "#0f7773";
      fontColor = "white";
    } else if (percentage > 0.7 && percentage < 0.9) {
      bgColor = "#3bb8b4";
    } else if (percentage > 0.5 && percentage < 0.7) {
      bgColor = "#2ad9d3";
    } else if (percentage > 0.3 && percentage < 0.5) {
      bgColor = "#5cebaf";
    } else if (percentage > 0.15 && percentage < 0.3) {
      bgColor = "#7fe98b";
    } else if (percentage >= 0 && percentage < 0.15) {
      bgColor = "#94efc6";
    }
    return (
      <>
        <Box
          sx={{
            background: bgColor, //set a min num
            width: smDown ? "25px" : "40px",
            height: smDown ? "25px" : "40px",
            borderRadius: "50%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Typography variant="h6" color={mode === "dark" ? fontColor : "white"} fontWeight={600}>
            {eventInfo.event.title}
          </Typography>
        </Box>
      </>
    );
  }

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Global
        styles={{
          ".fc-scroller::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
          },
          ".fc-scroller::-webkit-scrollbar-thumb": {
            background: `${theme.palette.primary.main} !important`,
            borderRadius: "1rem",
          },
          ".fc-scroller::-webkit-scrollbar-thumb:hover": { background: `${theme.palette.primary.dark} !important` },
          ".fc-toolbar": {
            marginBottom: "0 !important",
            backgroundColor:
              theme.palette.mode === "light"
                ? `#ffffff !important`
                : `${(theme.palette.neutral as any)[800]} !important` /* Change the background color */,
            color: "white" /* Change the text color */,
          },
          /* Target the buttons within the header toolbar */
          ".fc-button": {
            backgroundColor: `${theme.palette.primary.main} !important` /* Change the button background color */,
            color: "white" /* Change the button text color */,
          },
          ".fc-button-primary:disabled": {
            pointerEvents: "none",
          },
          ".fc-button:hover": {
            backgroundColor: `${lighten(theme.palette.primary.main, 0.2)} !important` /* Change the button background color */,
            color: "white" /* Change the button text color */,
          },

          ".fc .fc-daygrid-day.fc-day-today": {
            backgroundColor: theme.palette.mode === "light" ? (theme.palette.neutral as any)[200] : (theme.palette.neutral as any)[700],
          },
          ".fc-button-primary": {
            fontSize: "12px !important",
          },

          ".fc .fc-button .fc-icon": {
            fontSize: "12px !important",
          },

          ".fc .fc-toolbar.fc-header-toolbar": {
            padding: "10px !important",
          },
          /* Target the title within the header toolbar */
          ".fc-toolbar-title": {
            color: theme.palette.text.secondary,
            textTransform: "uppercase",
            fontSize: "14px !important" /* Change the title font size */,
          },
        }}
      />
      <Grid className="overview-step8" container spacing={1} sx={{ marginTop: "0 !important" }}>
        <Grid item xs={12} sx={{ pt: "0 !important" }}>
          <Paper elevation={5} sx={{ px: 1, mb: 1 }}>
            <StockTicker formik={formik} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9} sx={{ pt: "0 !important" }}>
          <Stack spacing={1}>
            <Paper sx={{ height: calendarHeight, borderRadius: "8px", overflow: "hidden" }}>
              <Box sx={{ height: "100%", userSelect: "none" }}>
                {calendarEvents && (
                  <FullCalendar
                    ref={calendarRef}
                    eventClick={handleEventClick}
                    editable={false}
                    height="calc(100% - 10px)"
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={calendarEvents?.event}
                    // headerToolbar={false}
                    headerToolbar={{
                      left: "title",
                      center: "",
                      right: "today prev,next", // Only the "next" button is enabled
                    }}
                    eventContent={renderEventContent}
                    datesSet={(dateInfo: DatesSetArg) => {
                      handleRenderCalendarColor();
                    }}
                    titleFormat={{ year: "numeric", month: smDown ? "short" : "long" }}
                    validRange={
                      {
                        // // start: moment(formik.values.start_min).startOf("month").format("YYYY-MM-DD"), // Replace with your minimum date
                        // // end: moment(formik.values.start_max).endOf("month").add("1", "days").format("YYYY-MM-DD"), // Replace with your maximum date
                        // start: moment(formik.values.start_min).format("YYYY-MM-DD"), // Replace with your minimum date
                        // end: moment(formik.values.start_max).format("YYYY-MM-DD"), // Replace with your maximum date
                      }
                    }
                  />
                )}
              </Box>
            </Paper>
          </Stack>
        </Grid>
        <Grid item xs={12} md={3} sx={{ pt: mdDown ? 1 : "0 !important" }}>
          <PostsStatistics data={data} theme={theme} />
        </Grid>
      </Grid>

      <PostCalendarDetail
        socialMedia={"instagram"}
        open={openCalendarDetail.open}
        data={openCalendarDetail.data}
        onClose={() => setOpenCalendarDetail({ open: false, data: null })}
      />
    </>
  );
});

export default PostsCalendar;
