import React from "react";
/* components */
/* 3rd party lib */
import { useDispatch, useSelector } from "react-redux";
import { FormControl, MenuItem, Select, Stack, SxProps, Typography } from "@mui/material";

/* Util */
import Insta from "src/images/insta.png";
import Tiktok from "src/images/darkModeTikTok.png";
import { RootState } from "src/store/root-reducer";
import { setSocialMedia } from "src/slices/general";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface SocialMediaDropdownProps {
  inputStyle?: SxProps;
}

type Props = SocialMediaDropdownProps;

const SocialMediaDropdown: React.FC<Props> = ({ inputStyle }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const dispatch = useDispatch();
  const { smDown } = useMediaQueries();
  const { socialMedia } = useSelector((state: RootState) => state.general);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <FormControl sx={{ minWidth: smDown ? "auto" : 150 }} className="step-3">
      <Select
        value={socialMedia}
        onChange={e => {
          dispatch(setSocialMedia(e.target.value));
        }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={
          inputStyle ?? {
            "& .MuiOutlinedInput-input": {
              py: "12px !important",
            },
          }
        }
      >
        <MenuItem value={"instagram"}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img src={Insta} alt="insta" width="18px" height="18px" />
            {!smDown && <Typography variant="body1">Instagram</Typography>}
          </Stack>
        </MenuItem>
        <MenuItem value={"tiktok"}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img src={Tiktok} alt="insta" width="18px" height="18px" />
            {!smDown && <Typography variant="body1">Tik Tok</Typography>}
          </Stack>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SocialMediaDropdown;
