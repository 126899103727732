import React, { useCallback, useEffect, useMemo, useState } from "react";
/* components */
/* 3rd party lib */
import moment from "moment";
import { FormikProps } from "formik";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRight, faCalendar } from "@fortawesome/pro-solid-svg-icons";
import {
  Box,
  darken,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

/* Util */
import { IFormDateFilter } from "src/types/common";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root-reducer";
import { checkObjectNotEmpty } from "src/utils/general";
import { faLock } from "@fortawesome/pro-regular-svg-icons";

interface RootProps {
  maxWidth?: number | string;
}

const StyledDateTextField = styled(TextField, { shouldForwardProp: props => props !== "disablePadding" })<RootProps>(({ maxWidth }) => ({
  "& .MuiInputBase-input": {
    paddingBottom: "9px !important",
    paddingTop: "8px !important",
    maxWidth: maxWidth,
  },
}));

interface DateFilterProps {
  fullExpand?: boolean;
  clearableDate?: boolean;
  maxWidth?: number | string;
  formik: FormikProps<IFormDateFilter>;
  additionalSlideInComponent?: React.ReactNode;
}
type Props = DateFilterProps;

const DateFilter: React.FC<Props> = ({ formik, maxWidth, clearableDate = false, additionalSlideInComponent, fullExpand = false }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { mode } = theme.palette;
  const { smDown, mdUp } = useMediaQueries();
  const { user } = useSelector((state: RootState) => state.auth);
  const [period, setPeriod] = useState<string | null | undefined>("");
  const { ref, inView } = useInView({ initialInView: false, threshold: 0 });

  const { values } = formik;

  const PERIODS = useMemo(() => {
    let options: {
      ALL: string | undefined;
      TODAY: string;
      LAST_7_DAYS: string;
      LAST_30_DAYS: string;
      LAST_90_DAYS: string;
      THIS_MONTH: string;
      LAST_MONTH: string;
      CUSTOM: string;
    } = {
      ALL: "ALL",
      TODAY: "TODAY",
      LAST_7_DAYS: "LAST_7_DAYS",
      LAST_30_DAYS: "LAST_30_DAYS",
      LAST_90_DAYS: "LAST_90_DAYS",
      THIS_MONTH: "THIS_MONTH",
      LAST_MONTH: "LAST_MONTH",
      CUSTOM: "CUSTOM",
    };

    if (clearableDate) {
      delete options["ALL"];
    }

    return options;
  }, [clearableDate]);

  const disableStyle = {
    background: theme.palette.grey[mode === "dark" ? 800 : 200],
    color: theme.palette.grey[mode === "dark" ? 600 : 400],
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */

  // get the end of the current month

  const checkAndSetPeriod = useCallback(
    (start: any, end: any) => {
      const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
      if (moment(start).isSame(end, "day") && moment(start).isSame(moment(), "day")) {
        setPeriod(PERIODS.TODAY);
      }

      // else if (start === null && end === null) {
      //   setPeriod(PERIODS.ALL);
      // }
      else if (moment(start).isSame(moment().subtract(6, "days"), "day") && moment(end).isSame(moment(), "day")) {
        setPeriod(PERIODS.LAST_7_DAYS);
      } else if (moment(start).isSame(moment().subtract(30, "days"), "day") && moment(end).isSame(moment(), "day")) {
        setPeriod(PERIODS.LAST_30_DAYS);
      } else if (moment(start).isSame(moment().subtract(90, "days"), "day") && moment(end).isSame(moment(), "day")) {
        setPeriod(PERIODS.LAST_90_DAYS);
      } else if (moment(start).isSame(startOfMonth, "day") && moment(end).isSame(endOfMonth, "day")) {
        setPeriod(PERIODS.THIS_MONTH);
      } else if (
        moment(start).isSame(moment().subtract(1, "months").startOf("month"), "day") &&
        moment(end).isSame(moment().subtract(1, "months").endOf("month"), "day")
      ) {
        setPeriod(PERIODS.LAST_MONTH);
      } else {
        setPeriod(PERIODS.CUSTOM);
      }
    },
    [PERIODS]
  );

  const handleChangeStartDate = (date: any) => {
    const minIsGreaterThanMax = moment(date).isAfter(moment(formik.values.start_max));
    formik.setFieldValue("start_min", minIsGreaterThanMax ? formik.values.start_max : date);

    checkAndSetPeriod(date, values.start_max);
  };

  const handleChangeEndDate = (date: any) => {
    const maxIsLessThanMin = moment(date).isBefore(moment(formik.values.start_min));
    formik.setFieldValue("start_max", date);
    checkAndSetPeriod(values.start_min, maxIsLessThanMin ? formik.values.start_min : date);
  };

  const handlePeriodChange = (e: any) => {
    let tempStartDate = null;
    let tempEndDate = null;
    if (e.target.value === PERIODS.TODAY) {
      tempStartDate = moment();
      tempEndDate = moment();
    } else if (e.target.value === PERIODS.LAST_7_DAYS) {
      tempStartDate = moment().subtract(6, "days");
      tempEndDate = moment();
    } else if (e.target.value === PERIODS.LAST_30_DAYS) {
      tempStartDate = moment().subtract(30, "days");
      tempEndDate = moment();
    } else if (e.target.value === PERIODS.LAST_90_DAYS) {
      tempStartDate = moment().subtract(90, "days");
      tempEndDate = moment();
    } else if (e.target.value === PERIODS.THIS_MONTH) {
      tempStartDate = moment().startOf("month");
      tempEndDate = moment().endOf("month");
    } else if (e.target.value === PERIODS.LAST_MONTH) {
      tempStartDate = moment().subtract(1, "months").startOf("month");
      tempEndDate = moment().subtract(1, "months").endOf("month");
    }

    formik.setFieldValue("start_min", tempStartDate?.format("YYYY-MM-DD") ?? null);
    formik.setFieldValue("start_max", tempEndDate?.format("YYYY-MM-DD") ?? null);
  };

  const checkShouldDisable = (range: string) => {
    const maxDayRangeLimit = (user && checkObjectNotEmpty(user.subscription) && user.subscription.package.max_day_range_limit) ?? Infinity;
    if (user && user.isAdmin) return false;

    if (range === PERIODS.LAST_7_DAYS) {
      return maxDayRangeLimit < 7;
    } else if (range === PERIODS.LAST_30_DAYS) {
      return maxDayRangeLimit < 30;
    } else if (range === PERIODS.LAST_90_DAYS) {
      return maxDayRangeLimit < 90;
    } else if (range === PERIODS.THIS_MONTH) {
      return maxDayRangeLimit < moment().daysInMonth();
    } else if (range === PERIODS.LAST_MONTH) {
      return maxDayRangeLimit < moment().subtract(1, "month").daysInMonth();
    }
  };

  const clearableDateComponent = (values: IFormDateFilter, key: "start_min" | "start_max") => {
    // Add the icon as the end adornment
    return {
      endAdornment:
        clearableDate && values[key] !== null ? (
          <>
            <InputAdornment
              position="end"
              onClick={e => {
                e.stopPropagation();
                formik.setFieldValue(key, null);
              }}
            >
              <Box
                sx={{
                  svg: {
                    cursor: "pointer",
                    "&:hover": {
                      color: darken(theme.palette.text.secondary, 0.2),
                    },
                  },
                }}
              >
                <FontAwesomeIcon icon={faCircleXmark} color={theme.palette.text.secondary} />
              </Box>
            </InputAdornment>
          </>
        ) : (
          <InputAdornment position="end">
            <FontAwesomeIcon icon={faCalendar} />
          </InputAdornment>
        ),
    };
  };

  const dateFilter = (
    <Grid container alignItems="center" sx={{ height: "100%", width: "300px" }}>
      <Grid item xs={5.5}>
        <FormControl fullWidth>
          <MobileDatePicker
            closeOnSelect
            mask="__-__-____"
            // name="start_min"

            value={values.start_min}
            inputFormat={"DD-MM-YYYY"}
            minDate={
              user && !user.isAdmin && checkObjectNotEmpty(user.subscription) && user.subscription.package.max_day_range_limit
                ? moment().subtract(user.subscription.package.max_day_range_limit - 1, "days")
                : ""
            }
            maxDate={moment().format("YYYY-MM-DD")}
            onChange={handleChangeStartDate}
            renderInput={params => (
              <StyledDateTextField
                {...params}
                error={false}
                variant="outlined"
                name="start_min"
                hiddenLabel
                maxWidth={maxWidth}
                value={values.start_min ? moment(values.start_min).format("YYYY-MM-DD") : null}
                placeholder={smDown ? "Start Date" : "Click to enter start date"}
              />
            )}
            InputProps={clearableDateComponent(values, "start_min")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <Box sx={{ display: "grid", placeItems: "center", height: "100%" }}>
          <FontAwesomeIcon icon={faArrowRight} />
        </Box>
      </Grid>
      <Grid item xs={5.5}>
        <FormControl fullWidth>
          <MobileDatePicker
            closeOnSelect
            mask="__-__-____"
            // name="start_max"
            minDate={values.start_min}
            maxDate={moment().format("YYYY-MM-DD")}
            value={values.start_max}
            inputFormat={"DD-MM-YYYY"}
            onChange={handleChangeEndDate}
            renderInput={params => (
              <StyledDateTextField
                {...params}
                error={false}
                variant="outlined"
                name="start_max"
                hiddenLabel
                maxWidth={maxWidth}
                placeholder={smDown ? "End Date" : "Click to enter end date"}
                value={values.start_max ? moment(values.start_max).format("YYYY-MM-DD") : null}
              />
            )}
            InputProps={clearableDateComponent(values, "start_max")}
          />
        </FormControl>
      </Grid>
    </Grid>
  );

  const LockIcon = () => (
    <Tooltip
      title={`Your package can only view data from the past ${
        (user && !user.isAdmin && checkObjectNotEmpty(user.subscription) && user.subscription.package.max_day_range_limit) ?? 999
      } days`}
    >
      <FontAwesomeIcon icon={faLock} />
    </Tooltip>
  );

  const MenuItemContent = ({ targetKey, title }: { title: string; targetKey: string }) => (
    <Stack width="100%" direction="row" alignItems="center" justifyContent={"space-between"}>
      <Typography variant="body2">{title}</Typography>
      <Box>{checkShouldDisable(targetKey) && <LockIcon />}</Box>
    </Stack>
  );

  const FilterAndDropdown = ({ additionalSlideInComponent }: { additionalSlideInComponent?: React.ReactNode }) => (
    <Stack direction="row" spacing={1}>
      {additionalSlideInComponent && additionalSlideInComponent}
      <Stack direction="row" spacing={1} sx={{ width: "100%", maxWidth: "450px" }}>
        {dateFilter}
        {!mdUp && (
          <Stack justifyContent={"center"}>
            <FormControl fullWidth>
              <Select
                variant="outlined"
                labelId="select-label"
                value={period}
                onChange={e => {
                  setPeriod(e.target.value);
                  handlePeriodChange(e);
                }}
                sx={{
                  height: "37px",
                  width: "140px",
                  textAlign: "left",
                }}
              >
                {/* {clearableDate && (
                  <MenuItem value={PERIODS.ALL}>
                    <Typography variant="body2">All</Typography>
                  </MenuItem>
                )} */}
                <MenuItem value={PERIODS.TODAY}>
                  <Typography variant="body2">Today</Typography>
                </MenuItem>
                <MenuItem
                  value={PERIODS.LAST_7_DAYS}
                  disabled={checkShouldDisable(PERIODS.LAST_7_DAYS)}
                  style={checkShouldDisable(PERIODS.LAST_7_DAYS) ? disableStyle : {}}
                >
                  <MenuItemContent title="Last 7 Days" targetKey={PERIODS.LAST_7_DAYS} />
                </MenuItem>
                <MenuItem
                  value={PERIODS.LAST_30_DAYS}
                  disabled={checkShouldDisable(PERIODS.LAST_30_DAYS)}
                  style={checkShouldDisable(PERIODS.LAST_30_DAYS) ? disableStyle : {}}
                >
                  <MenuItemContent title="Last 30 Days" targetKey={PERIODS.LAST_30_DAYS} />
                </MenuItem>
                <MenuItem
                  value={PERIODS.LAST_90_DAYS}
                  disabled={checkShouldDisable(PERIODS.LAST_90_DAYS)}
                  style={checkShouldDisable(PERIODS.LAST_90_DAYS) ? disableStyle : {}}
                >
                  <MenuItemContent title="Last 90 Days" targetKey={PERIODS.LAST_90_DAYS} />
                </MenuItem>
                <MenuItem
                  value={PERIODS.THIS_MONTH}
                  disabled={checkShouldDisable(PERIODS.THIS_MONTH)}
                  style={checkShouldDisable(PERIODS.THIS_MONTH) ? disableStyle : {}}
                >
                  <MenuItemContent title="This Month" targetKey={PERIODS.THIS_MONTH} />
                </MenuItem>
                <MenuItem
                  value={PERIODS.LAST_MONTH}
                  disabled={checkShouldDisable(PERIODS.LAST_MONTH)}
                  style={checkShouldDisable(PERIODS.LAST_MONTH) ? disableStyle : {}}
                >
                  <MenuItemContent title="Last Month" targetKey={PERIODS.LAST_MONTH} />
                </MenuItem>
                <MenuItem
                  value={PERIODS.CUSTOM}
                  style={{ display: "none", visibility: "hidden", height: 0, padding: 0, margin: 0, lineHeight: 0, textTransform: "capitalize" }}
                  disabled
                >
                  <Typography variant="body2">Custom</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        )}
      </Stack>

      {mdUp && (
        <Stack justifyContent={"center"}>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              labelId="select-label"
              value={period}
              onChange={e => {
                setPeriod(e.target.value);
                handlePeriodChange(e);
              }}
              sx={{
                height: "37px",
                width: "140px",
                textAlign: "left",
              }}
            >
              {clearableDate && (
                <MenuItem value={PERIODS.ALL}>
                  <Typography variant="body2">All</Typography>
                </MenuItem>
              )}
              <MenuItem value={PERIODS.TODAY}>
                <Typography variant="body2">Today</Typography>
              </MenuItem>

              <MenuItem
                value={PERIODS.LAST_7_DAYS}
                disabled={checkShouldDisable(PERIODS.LAST_7_DAYS)}
                style={checkShouldDisable(PERIODS.LAST_7_DAYS) ? disableStyle : {}}
              >
                <MenuItemContent title="Last 7 Days" targetKey={PERIODS.LAST_7_DAYS} />
              </MenuItem>
              <MenuItem
                value={PERIODS.LAST_30_DAYS}
                disabled={checkShouldDisable(PERIODS.LAST_30_DAYS)}
                style={checkShouldDisable(PERIODS.LAST_30_DAYS) ? disableStyle : {}}
              >
                <MenuItemContent title="Last 30 Days" targetKey={PERIODS.LAST_30_DAYS} />
              </MenuItem>
              <MenuItem
                value={PERIODS.LAST_90_DAYS}
                disabled={checkShouldDisable(PERIODS.LAST_90_DAYS)}
                style={checkShouldDisable(PERIODS.LAST_90_DAYS) ? disableStyle : {}}
              >
                <MenuItemContent title="Last 90 Days" targetKey={PERIODS.LAST_90_DAYS} />
              </MenuItem>
              <MenuItem
                value={PERIODS.THIS_MONTH}
                disabled={checkShouldDisable(PERIODS.THIS_MONTH)}
                style={checkShouldDisable(PERIODS.THIS_MONTH) ? disableStyle : {}}
              >
                <MenuItemContent title="This Month" targetKey={PERIODS.THIS_MONTH} />
              </MenuItem>
              <MenuItem
                value={PERIODS.LAST_MONTH}
                disabled={checkShouldDisable(PERIODS.LAST_MONTH)}
                style={checkShouldDisable(PERIODS.LAST_MONTH) ? disableStyle : {}}
              >
                <MenuItemContent title="Last Month" targetKey={PERIODS.LAST_MONTH} />
              </MenuItem>

              <MenuItem
                value={PERIODS.CUSTOM}
                style={{ display: "none", visibility: "hidden", height: 0, padding: 0, margin: 0, lineHeight: 0, textTransform: "capitalize" }}
                disabled
              >
                <Typography variant="body2">Custom</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      )}
    </Stack>
  );

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    checkAndSetPeriod(values.start_min, values.start_max);
  }, [values, checkAndSetPeriod]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Stack direction="row" spacing={1} justifyContent={"flex-end"} ref={ref} sx={{ width: fullExpand ? "100%" : "auto" }}>
        <FilterAndDropdown />
      </Stack>

      {user && !user.expired && (
        <Slide direction="up" in={!inView} mountOnEnter unmountOnExit>
          <Paper
            sx={{
              p: 1,
              width: smDown ? "calc(100% - 100px)" : "auto",
              zIndex: 999,
              position: "fixed",
              right: "60px",
              bottom: "4px",
            }}
          >
            <FilterAndDropdown additionalSlideInComponent={additionalSlideInComponent} />
          </Paper>
        </Slide>
      )}
    </>
  );
};

export default DateFilter;
