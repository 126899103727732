import React, { useState, useEffect, useMemo } from "react";
import "src/styles/stock-ticker.scss";
/* components */
import { IInstaPerctObj } from "src/components/profile/instagram/profile-widgets";
import LineChart from "src/components/common/data-display/charts/line-chart";
/* 3rd party lib */
import useSWR from "swr";
import moment from "moment";
import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import { Box, Skeleton, Stack, Typography, useTheme } from "@mui/material";
/* Util */
import useHttp from "src/hooks/use-http";
import { useDispatch } from "react-redux";
import { setMuiTab } from "src/slices/general";
import { IFormDateFilter } from "src/types/common";
import { PROFILE_TAB_PANEL } from "src/utils/constants";
import { formatNumberWithSuffix } from "src/utils/general";
import { DateData, TInstaProfileWidgetData } from "src/types/instagram";

interface StockTickerProps {
  formik: FormikProps<IFormDateFilter>;
}

type Props = StockTickerProps;

const StockTicker: React.FC<Props> = ({ formik }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { apiEndpoint } = useHttp();
  const params = useParams();
  const config = useMemo(() => {
    let params = {
      date_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
      date_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
    };
    return { params };
  }, [formik]);

  const [amountOfCards, setAmountOfCards] = useState(0);
  const [percentageDiff, setPercentageDiff] = useState<IInstaPerctObj | null>(null);
  const { data } = useSWR<TInstaProfileWidgetData>(params && [apiEndpoint.INSTAGRAM_PROFILE_WIDGET(params.username), config]);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  useEffect(() => {
    // If a user hasn't opted for reduced motion, then we add the animation
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      const scroller = document.querySelector(".scroller") as Element | null;
      if (scroller) {
        // add data-animated="true" to the `.scroller` element
        scroller.setAttribute("data-animated", "true");

        const scrollerInner = scroller.querySelector(".scroller__inner") as Element | null;
        if (scrollerInner) {
          const scrollerContent = Array.from(scrollerInner.children);
          setAmountOfCards(scrollerContent.length);
        }
      }
    }
  }, []);
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (data) {
      function removeNullValues(obj: any): any {
        return Object.fromEntries(
          Object.entries(obj).filter(([_key, value]) => {
            return (value as any).count !== null;
          })
        );
      }

      function findEarliestAndLatest(data: DateData) {
        if (Object.keys(data).length === 0) return null;
        let earliestDate = null;
        let latestDate = null;

        for (const dateStr in data) {
          if (data.hasOwnProperty(dateStr)) {
            const currentDate = moment(dateStr);
            if (!earliestDate || currentDate.isBefore(earliestDate)) {
              earliestDate = currentDate;
            }

            if (!latestDate || currentDate.isAfter(latestDate)) {
              latestDate = currentDate;
            }
          }
        }

        const earliestData = earliestDate ? (data as any)[earliestDate.format("YYYY-MM-DD")] : null;
        const latestData = latestDate ? (data as any)[latestDate.format("YYYY-MM-DD")] : null;

        const earliestCount = earliestData?.count || 0; // Default to 0 if null

        return {
          earliestDate: moment(earliestDate).format("YYYY-MM-DD") ?? null,
          latestDate: moment(latestDate).format("YYYY-MM-DD") ?? null,
          earliestData: earliestData.count,
          latestData: latestData.count,
          difference: latestData.count - earliestCount,
          percentage: earliestData.count === 0 ? 100 : ((latestData.count - earliestData.count) / earliestData.count) * 100,
        };
      }

      function processResult(data: any, key: string) {
        const initializeValue = {
          earliestDate: moment().format("YYYY-MM-DD"),
          latestDate: moment().format("YYYY-MM-DD"),
          earliestData: 0,
          latestData: 0,
          difference: 0,
          percentage: 0,
        };

        const result =
          Object.keys(data.results).includes(key) && Object.keys(data.results[key]).length > 0
            ? findEarliestAndLatest(removeNullValues(data.results[key]))
            : initializeValue;
        return [key, result];
      }

      const keysToProcess = ["total_play", "total_like", "total_comment", "follower", "post", "following"];

      const perctObj = Object.fromEntries(keysToProcess.map(key => processResult(data, key)));

      setAmountOfCards(Object.values(perctObj).filter(child => child !== null).length);
      setPercentageDiff(perctObj);
    }
  }, [data]);

  /* ================================================== */
  /* ================================================== */

  const charts = (
    <>
      {data && percentageDiff && percentageDiff.total_play && (
        <StockComponent
          percentage={percentageDiff.total_play.percentage}
          title={"Plays"}
          total={percentageDiff.total_play.difference}
          data={data.results.total_play}
          difference={percentageDiff.total_play.difference}
        />
      )}

      {data && percentageDiff && percentageDiff.total_like && (
        <StockComponent
          percentage={percentageDiff.total_like.percentage}
          title={"Likes"}
          total={percentageDiff.total_like.difference}
          data={data.results.total_like}
          difference={percentageDiff.total_like.difference}
        />
      )}

      {data && percentageDiff && percentageDiff.follower && (
        <StockComponent
          percentage={percentageDiff.follower.percentage}
          title={"Followers"}
          total={percentageDiff.follower.difference}
          data={data.results.follower}
          difference={percentageDiff.follower.difference}
        />
      )}

      {data && percentageDiff && percentageDiff.total_comment && (
        <StockComponent
          percentage={percentageDiff.total_comment.percentage}
          title={"Comments"}
          total={percentageDiff.total_comment.difference}
          data={data.results.total_comment}
          difference={percentageDiff.total_comment.difference}
        />
      )}

      {data && percentageDiff && percentageDiff.post && (
        <StockComponent
          percentage={percentageDiff.post.percentage}
          title={"Posts"}
          total={percentageDiff.post.difference}
          data={data.results.post}
          difference={percentageDiff.post.difference}
        />
      )}
      {data && percentageDiff && percentageDiff.following && (
        <StockComponent
          percentage={percentageDiff.following.percentage}
          title={"Followings"}
          total={percentageDiff.following.difference}
          data={data.results.following}
          difference={percentageDiff.following.difference}
        />
      )}
    </>
  );

  return (
    <>
      <div className={`scroller`} data-direction={"right"}>
        <ul className="tag-list scroller__inner">
          {data && percentageDiff && amountOfCards > 0 ? (
            <>
              {Array.from({ length: Math.floor(12 / amountOfCards) }, (_, index) => (
                <React.Fragment key={`stock__${index}`}>{charts}</React.Fragment>
              ))}
            </>
          ) : (
            <>
              {Array.from({ length: 12 }, (_, index) => (
                <div key={index + 1}>
                  <Skeleton
                    sx={{ bgcolor: theme.palette.mode === "dark" ? (theme.palette.neutral as any)[700] : (theme.palette.neutral as any)[200] }}
                    animation="wave"
                    variant="rounded"
                    width={150}
                    height={50.38}
                  />
                </div>
              ))}
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default StockTicker;

const StockComponent = ({
  total,
  title,
  difference,
  percentage,
  data,
}: {
  title: string;
  total: number;
  difference: number;
  percentage: number;
  data: DateData;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const positiveColor = { line: "#16F65A", area: "#16F65A" };
  const negativeColor = { line: "#FE3B23", area: "#FE3B23" };

  let symbol = "";
  let bgColor = theme.palette.primary.main;
  if (percentage > 0) {
    symbol = "+";
    bgColor = positiveColor.line;
  } else if (percentage < 0) {
    bgColor = negativeColor.line;
  }

  return (
    <li style={{ background: bgColor, cursor: "pointer" }} onClick={() => dispatch(setMuiTab(PROFILE_TAB_PANEL.STATISTICS))}>
      <Stack spacing={1}>
        <Stack spacing={1} alignItems="flex-start">
          <Stack direction="row" alignItems="flex-start" justifyContent={"space-between"} sx={{ width: "100%" }}>
            <Typography variant="body1" fontWeight={300} color="white" sx={{ textAlign: "left" }}>
              {title}
            </Typography>

            <Stack spacing={1}>
              <Typography variant="body1" pl={0.5} whiteSpace="nowrap" color="white" fontWeight={600}>
                {symbol} {formatNumberWithSuffix(total)}
              </Typography>
              {/* <PercentDiff
                showZero
                bgOpacity={1}
                backgroundColor={"#ffffff"}
                showIcon={false}
                difference={difference}
                percentage={percentage}
                displayMode="percentage"
              /> */}
            </Stack>
          </Stack>
        </Stack>
        <Box sx={{ display: "grid", placeItems: "center", width: "120px", height: "8px", pb: "4px" }}>
          <LineChart
            simple
            emptyChart={difference === 0}
            color={{ line: "#ffffff", area: "#ffffff" }}
            xAxisData={Object.keys(data).map(child => moment(child).format("DD MMM"))}
            yAxisData={Object.values(data).map(child => (difference === 0 ? 0.5 : child.count))} // difference is 0, set the num to 0.5 so the line will float in the middle
          />
        </Box>
      </Stack>
    </li>
  );
};
