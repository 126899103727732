import React, { useEffect } from "react";
/* components */
import TikTok from "src/images/tiktok.png";
/* 3rd party lib */
import useSWR from "swr";
import moment from "moment";
// import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faRegularStar } from "@fortawesome/pro-regular-svg-icons";
import { faCircleInfo, faImage, faScaleBalanced, faStar } from "@fortawesome/pro-solid-svg-icons";
import { Box, Button, Grid, IconButton, Link, Paper, Stack, Tooltip, Typography, useTheme } from "@mui/material";
/* Util */
import useHttp from "src/hooks/use-http";
import { TInstaData } from "src/types/instagram";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatNumberWithSuffix /* countUpSuffix,  hasDecimalPlaces */ } from "src/utils/general";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { toast } from "react-hot-toast";
import { getTranslateString } from "src/utils/translate";
import { TOAST_ERROR_WENT_WRONG } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import routes from "src/routes";
import { TTikTokData } from "src/types/tiktok";
import { TSocialMedia } from "src/types/common";
import { apiEndpoint as APIENDPOINT } from "src/contexts/http-context";
import { RootState } from "src/store/root-reducer";
import { useSelector } from "react-redux";
import ExpiredOverlay from "src/components/common/data-display/expired-overlay";

interface TikTokProfileBannerProps {
  viewOnly?: boolean;
  username?: string;
  setShow404?: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = TikTokProfileBannerProps;

export const handleAddFavourite = async ({
  username,
  data,
  http,
  apiEndpoint,
  mutate,
  t,
  socialMedia,
}: {
  t: any;
  http: any;
  mutate: any | null;
  apiEndpoint: typeof APIENDPOINT;
  socialMedia: TSocialMedia;
  data: TInstaData | TTikTokData;
  username: string | undefined;
}) => {
  if (!username || !data) return;

  try {
    const result = await http.post(socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_FAVOURITE(username) : apiEndpoint.TIKTOK_FAVOURITE(username), {
      action: data.favourite ? "remove" : "add",
    });

    if (typeof mutate === "function") {
      mutate();
    }
    // function capitalizeFirstLetter(str: string) {
    //   return str.charAt(0).toUpperCase() + str.slice(1);
    // }

    toast.success(username + " " + result.data.status);
  } catch (error) {
    console.error({ error });
    toast.error(getTranslateString(t, TOAST_ERROR_WENT_WRONG));
  }
};

const TikTokProfileBanner: React.FC<Props> = ({ setShow404, viewOnly, username }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const params = useParams();
  const theme = useTheme();
  const location = useLocation();
  const locationParams = location.state;
  const { t } = useTranslation();
  const { mode } = theme.palette;
  const navigate = useNavigate();
  const { http, apiEndpoint } = useHttp();
  const { user } = useSelector((state: RootState) => state.auth);
  const { smDown, mdDown, lgDown, mdUp } = useMediaQueries();

  const tempUsername = params && params.username ? params.username : username;
  const { data, error, mutate } = useSWR<TTikTokData>([apiEndpoint.TIKTOK_PROFILE(tempUsername)]);

  const description = data?.description?.replace(/\n/g, "<br/>") ?? "";

  /* ================================================== */
  /*  method */
  /* ================================================== */
  // const followers = parseFloat(countUpSuffix(data?.count_follower).num.toString());
  // const followings = parseFloat(countUpSuffix(data?.count_following).num.toString());
  // const posts = parseFloat(countUpSuffix(data?.count_post).num.toString());

  const ERTooltip = (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant={smDown ? "body2" : "body1"}>Engagement Rate</Typography>
      <Tooltip
        arrow
        title={
          <Stack spacing={2}>
            <Typography variant="body1">Engagement Rate (ER)</Typography>
            <Typography variant="caption" lineHeight={1.3}>
              Our Engagement Rate is calculated based on the total number of likes and comments from the most recent&nbsp;
              <b>15</b>&nbsp;posts.
            </Typography>
            {data && (
              <Stack spacing={0.5}>
                <Typography variant="caption" lineHeight={1.3}>
                  The average interacts per post are
                </Typography>
                <Stack>
                  <Typography variant="caption" lineHeight={1.3}>
                    <b>{formatNumberWithSuffix(data.engagement_stats.median_likes).toString()}</b>&nbsp;Likes
                  </Typography>
                  <Typography variant="caption" lineHeight={1.3}>
                    <b>{formatNumberWithSuffix(data.engagement_stats.median_comments).toString()}</b>&nbsp;Comments
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        }
      >
        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCircleInfo} color={theme.palette.text.secondary} />
      </Tooltip>
    </Stack>
  );

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (setShow404 && error && error.response && (error.response.status === 404 || error.response.status === 500)) {
      setShow404(true);
    }
  }, [error, setShow404]);
  /* ================================================== */
  /* ================================================== */
  return (
    <Stack spacing={smDown ? 2 : 0}>
      <Paper elevation={5} sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
        {user && user.expired && <ExpiredOverlay />}
        <Grid container>
          {!viewOnly && (
            <Grid item xs={1.5} sm={0.8}>
              <Stack
                sx={{
                  cursor: "pointer",
                  height: "100%",
                  display: "grid",
                  placeItems: "center",
                  transition: "all 0.5s ease",
                  borderRight: smDown ? `1px solid ${(theme.palette as any).neutral[200]}` : "none",
                  "&:hover": {
                    background: mode === "light" ? (theme.palette as any).neutral[200] : (theme.palette as any).neutral[700],
                  },
                }}
                onClick={() => {
                  if (locationParams && locationParams.prevPage) {
                    navigate(locationParams.prevPage);
                  } else {
                    navigate(routes.ROOT);
                  }
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </Stack>
            </Grid>
          )}
          <Grid item xs={viewOnly ? 12 : 10.5} sm={viewOnly ? 12 : 11.2}>
            <Stack direction={mdDown ? "column" : "row"} alignItems="center" p={2} spacing={2} sx={{ position: "relative" }}>
              {data && (
                <IconButton
                  className="overview-step2"
                  onClick={() =>
                    handleAddFavourite({
                      username: params.username,
                      data: data,
                      http: http,
                      apiEndpoint: apiEndpoint,
                      mutate: mutate,
                      t: t,
                      socialMedia: "tiktok",
                    })
                  }
                  sx={{ position: "absolute", top: "8px", right: "8px" }}
                >
                  <FontAwesomeIcon icon={data.favourite ? faStar : faRegularStar} color={theme.palette.primary.main} />
                </IconButton>
              )}

              <Stack
                direction="row"
                spacing={4}
                alignItems="center"
                justifyContent={smDown ? "center" : "flex-start"}
                width={mdDown ? "100%" : "50%"}
              >
                <Stack spacing={1} alignItems="center" sx={{ width: "190px" }}>
                  <Box sx={{ position: "relative", width: "120px", height: "120px" }}>
                    <Box
                      sx={{
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "50%",
                        overflow: "hidden",
                        border: "1px solid #dcdcdc",
                        width: "120px",
                        height: "120px",
                      }}
                    >
                      {data?.downloaded_image ? (
                        <img src={data?.downloaded_image} alt={data?.username} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                      ) : (
                        <FontAwesomeIcon icon={faImage} color={theme.palette.text.secondary} size={smDown ? "xl" : "2x"} />
                      )}
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        width: "25px",
                        height: "25px",
                        background: "white",
                        border: "1px solid #dcdcdc",
                        borderRadius: "50%",
                        padding: 0.5,
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <img src={TikTok} alt="tiktok" width="100%" height="100%" />
                    </Box>
                  </Box>
                  {!viewOnly && (
                    <Button
                      className="overview-step1"
                      color="secondary"
                      variant="contained"
                      startIcon={<FontAwesomeIcon icon={faScaleBalanced} />}
                      onClick={() => navigate(routes.COMPARISON_PROFILES, { state: { profile: tempUsername } })}
                    >
                      <Typography variant="h6" whiteSpace="nowrap" fontWeight={600}>
                        Compare Profile
                      </Typography>
                    </Button>
                  )}
                </Stack>

                {!smDown && (
                  <Stack sx={{ textAlign: "left" }}>
                    <Typography variant={smDown ? "h6" : "h5"} color="text.secondary">
                      {data ? data.display_name : "-"}
                    </Typography>
                    {data ? (
                      viewOnly ? (
                        <Typography color="primary.main" variant={smDown ? "body2" : "body1"} fontWeight={500} my={0.5}>
                          @{data.username}
                        </Typography>
                      ) : (
                        <Link
                          onClick={e => {
                            e.stopPropagation();
                            window.open(`${data.profile_url}`, "_blank");
                          }}
                          href={`${data.profile_url}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          variant="caption"
                          color="primary.main"
                          underline="always"
                          sx={{ whiteSpace: "nowwrap", cursor: "pointer" }}
                        >
                          <Typography color="primary.main" variant={smDown ? "body2" : "body1"} fontWeight={500} my={0.5}>
                            @{data.username}
                          </Typography>
                        </Link>
                      )
                    ) : (
                      "-"
                    )}
                    {description && <Typography variant={smDown ? "caption" : "body2"} dangerouslySetInnerHTML={{ __html: description }} />}
                  </Stack>
                )}
              </Stack>

              {!smDown && (
                <>
                  <Stack sx={{ width: mdDown ? "100%" : "50%", pb: mdDown ? 4 : 0 }}>
                    <Grid container>
                      <Grid item xs={6} lg={3} sx={{ display: "flex", justifyContent: "center" }}>
                        <Stack>
                          <Typography variant={smDown ? "body2" : "body1"}>Followers</Typography>
                          <Typography
                            color={theme.palette.primary.main}
                            variant={smDown || lgDown ? "h3" : "h2"}
                            fontSize={mdUp ? "3vw" : "2.25rem"}
                            fontWeight={600}
                          >
                            {data ? (
                              <>
                                {/* <CountUp
                          end={hasDecimalPlaces(followers) ? parseFloat(countUpSuffix(data.count_follower).num.toString()) : data.count_follower}
                          duration={2}
                          separator=","
                          decimals={hasDecimalPlaces(followers) ? 1 : 0}
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />
                              {hasDecimalPlaces(followers) ? countUpSuffix(data.count_follower).unit : ""}
                            </div>
                          )}
                        </CountUp> */}
                                {formatNumberWithSuffix(data.count_follower)}
                              </>
                            ) : (
                              "-"
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} lg={3} sx={{ display: "flex", justifyContent: "center" }}>
                        <Stack>
                          <Typography variant={smDown ? "body2" : "body1"}>Following</Typography>
                          <Typography
                            color={theme.palette.primary.main}
                            variant={smDown || lgDown ? "h3" : "h2"}
                            fontSize={mdUp ? "3vw" : "2.25rem"}
                            fontWeight={600}
                          >
                            {data ? (
                              <>
                                {/* <CountUp
                          end={hasDecimalPlaces(followings) ? parseFloat(countUpSuffix(data.count_following).num.toString()) : data.count_following}
                          duration={2}
                          separator=","
                          decimals={hasDecimalPlaces(followings) ? 1 : 0}
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />
                              {hasDecimalPlaces(followings) ? countUpSuffix(data.count_following).unit : ""}
                            </div>
                          )}
                        </CountUp> */}

                                {formatNumberWithSuffix(data.count_following)}
                              </>
                            ) : (
                              "-"
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} lg={3} sx={{ display: "flex", justifyContent: "center" }}>
                        <Stack>
                          <Typography variant={smDown ? "body2" : "body1"}>Videos</Typography>
                          <Typography
                            color={theme.palette.primary.main}
                            variant={smDown || lgDown ? "h3" : "h2"}
                            fontSize={mdUp ? "3vw" : "2.25rem"}
                            fontWeight={600}
                          >
                            {data ? (
                              <>
                                {/* <CountUp
                          end={hasDecimalPlaces(posts) ? parseFloat(countUpSuffix(data.count_post).num.toString()) : data.count_post}
                          duration={2}
                          separator=","
                          decimals={hasDecimalPlaces(posts) ? 1 : 0}
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />
                              {hasDecimalPlaces(posts) ? countUpSuffix(data.count_post).unit : ""}
                            </div>
                          )}
                        </CountUp> */}
                                {formatNumberWithSuffix(data.count_video)}
                              </>
                            ) : (
                              "-"
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} lg={3} sx={{ display: "flex", justifyContent: "center" }}>
                        <Stack alignItems="center">
                          {ERTooltip}
                          <Typography
                            color={theme.palette.primary.main}
                            variant={smDown || lgDown ? "h3" : "h2"}
                            fontSize={mdUp ? "3vw" : "2.25rem"}
                            fontWeight={600}
                          >
                            {data ? <>{formatNumberWithSuffix(data.engagement_stats.median_engagement_rate)}%</> : "-"}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Typography
                    variant={smDown ? "caption" : "body2"}
                    fontWeight={400}
                    color="text.secondary"
                    sx={{ position: "absolute", bottom: mdDown ? "16px" : "8px", right: "16px" }}
                  >
                    Last Updated: {data && moment(data?.modified).format("DD/MM/YYYY hh:mm A")}
                  </Typography>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Paper>

      {smDown && (
        <Paper elevation={5} sx={{ position: "relative", pt: 1 }}>
          {user && user.expired && <ExpiredOverlay />}
          <Stack sx={{ width: mdDown ? "100%" : "50%", pb: mdDown ? 4 : 0 }}>
            <Stack sx={{ textAlign: "left", p: 2, mb: 2, borderBottom: `1px solid ${theme.palette.divider}` }}>
              <Typography variant={smDown ? "h6" : "h5"} color="text.secondary">
                {data ? data.display_name : "-"}
              </Typography>
              {data ? (
                viewOnly ? (
                  <Typography color="primary.main" variant={smDown ? "body2" : "body1"} fontWeight={500} my={0.5}>
                    @{data.username}
                  </Typography>
                ) : (
                  <Link
                    onClick={e => {
                      e.stopPropagation();
                      window.open(`https://www.tiktok.com/${data.username}`, "_blank");
                    }}
                    href={`https://www.tiktok.com/${data.username}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    variant="caption"
                    color="primary.main"
                    underline="always"
                    sx={{ whiteSpace: "nowwrap", cursor: "pointer" }}
                  >
                    <Typography color="primary.main" variant={smDown ? "body2" : "body1"} fontWeight={500} my={0.5}>
                      @{data.username}
                    </Typography>
                  </Link>
                )
              ) : (
                "-"
              )}
              {description && <Typography variant={smDown ? "caption" : "body2"} dangerouslySetInnerHTML={{ __html: description }} />}
            </Stack>

            <Grid container>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack>
                  <Typography variant={smDown ? "body2" : "body1"}>Followers</Typography>
                  <Typography color={theme.palette.primary.main} variant={smDown ? "h5" : "h2"} fontWeight={600}>
                    {data ? (
                      <>
                        {/* <CountUp
                          end={hasDecimalPlaces(followers) ? parseFloat(countUpSuffix(data.count_follower).num.toString()) : data.count_follower}
                          duration={2}
                          separator=","
                          decimals={hasDecimalPlaces(followers) ? 1 : 0}
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />
                              {hasDecimalPlaces(followers) ? countUpSuffix(data.count_follower).unit : ""}
                            </div>
                          )}
                        </CountUp> */}
                        {formatNumberWithSuffix(data.count_follower)}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack>
                  <Typography variant={smDown ? "body2" : "body1"}>Following</Typography>
                  <Typography color={theme.palette.primary.main} variant={smDown ? "h5" : "h2"} fontWeight={600}>
                    {data ? (
                      <>
                        {/* <CountUp
                          end={hasDecimalPlaces(followings) ? parseFloat(countUpSuffix(data.count_following).num.toString()) : data.count_following}
                          duration={2}
                          separator=","
                          decimals={hasDecimalPlaces(followings) ? 1 : 0}
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />
                              {hasDecimalPlaces(followings) ? countUpSuffix(data.count_following).unit : ""}
                            </div>
                          )}
                        </CountUp> */}

                        {formatNumberWithSuffix(data.count_following)}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack>
                  <Typography variant={smDown ? "body2" : "body1"}>Posts</Typography>
                  <Typography color={theme.palette.primary.main} variant={smDown ? "h5" : "h2"} fontWeight={600}>
                    {data ? (
                      <>
                        {/* <CountUp
                          end={hasDecimalPlaces(posts) ? parseFloat(countUpSuffix(data.count_post).num.toString()) : data.count_post}
                          duration={2}
                          separator=","
                          decimals={hasDecimalPlaces(posts) ? 1 : 0}
                        >
                          {({ countUpRef }) => (
                            <div>
                              <span ref={countUpRef} />
                              {hasDecimalPlaces(posts) ? countUpSuffix(data.count_post).unit : ""}
                            </div>
                          )}
                        </CountUp> */}
                        {formatNumberWithSuffix(data.count_video)}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack alignItems="center">
                  {ERTooltip}
                  <Typography color={theme.palette.primary.main} variant={smDown ? "h5" : "h2"} fontWeight={600}>
                    {data ? <>{formatNumberWithSuffix(data.engagement_stats.median_engagement_rate)}%</> : "-"}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Typography
            variant={smDown ? "caption" : "body2"}
            fontWeight={400}
            color="text.secondary"
            sx={{ position: "absolute", bottom: "8px", right: "16px" }}
          >
            Last Updated: {data && moment(data?.modified).format("DD/MM/YYYY hh:mm A")}
          </Typography>
        </Paper>
      )}
    </Stack>
  );
};

export default TikTokProfileBanner;
