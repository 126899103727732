import { FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import React from "react";
/* components */
/* 3rd party lib */
/* Util */
interface InfluencerTypeFilterProps {
  influencerType: string;
  setInfluencerType: React.Dispatch<React.SetStateAction<string>>;
}

type Props = InfluencerTypeFilterProps;

const InfluencerTypeFilter: React.FC<Props> = ({ influencerType, setInfluencerType }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Stack justifyContent={"center"}>
        <FormControl fullWidth>
          <Select
            variant="outlined"
            labelId="select-label"
            value={influencerType}
            renderValue={value => {
              switch (value) {
                case "nano":
                  return "Nano";
                case "micro":
                  return "Micro";
                case "macro":
                  return "Macro";
                case "mega":
                  return "Mega";
                case "unknown":
                  return "Unknown";
                default:
                  return "Influencer Type"; // Display when 'null' or no value is selected
              }
            }}
            onChange={e => {
              setInfluencerType(e.target.value);
            }}
            sx={{
              height: "48px",
              width: "140px",
              textAlign: "left",
            }}
          >
            <MenuItem value={"null"}>
              <Typography variant="body2">Influencer Type</Typography>
            </MenuItem>
            <MenuItem value={"nano"}>
              <Typography variant="body2">Nano (1,000 - 10,000)</Typography>
            </MenuItem>
            <MenuItem value={"micro"}>
              <Typography variant="body2">Micro (10,000 - 100,000)</Typography>
            </MenuItem>
            <MenuItem value={"macro"}>
              <Typography variant="body2">Macro (100,000 - 1 million)</Typography>
            </MenuItem>
            <MenuItem value={"mega"}>
              <Typography variant="body2">Mega (Over 1 million)</Typography>
            </MenuItem>
            <MenuItem value={"unknown"}>
              <Typography variant="body2">Unknown</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </>
  );
};

export default InfluencerTypeFilter;
