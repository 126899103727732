import { lighten, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root-reducer";
import { themeColors } from "src/utils/constants";

const useBackground = ({ solidColor = true }: { solidColor?: boolean }) => {
  const theme = useTheme();
  const { socialMedia } = useSelector((state: RootState) => state.general);

  let background = solidColor
    ? theme.palette.mode === "dark"
      ? (theme.palette.neutral as any)[600]
      : lighten((theme.palette.neutral as any)[200], 0.2)
    : `linear-gradient(to right, ${themeColors[socialMedia]})`;

  return background;
};

export default useBackground;
