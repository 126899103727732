import { createSlice } from "@reduxjs/toolkit";
import { IPostAvatarGroup, IProfileAvatarGroup } from "src/types/common";

export type IMultiProfile = {
  [username: string]: string;
};
export type IMultiPost = {
  [username: string]: { [postId: string]: string };
};

export interface CompareRootState {
  multiProfile: IMultiProfile;
  multiPost: IMultiPost;
  profileAvatarGroup: IProfileAvatarGroup[] | null;
  postAvatarGroup: IPostAvatarGroup[] | null;
}

const initialState: CompareRootState = {
  multiProfile: {},
  multiPost: {},
  profileAvatarGroup: null,
  postAvatarGroup: null,
};

const slice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    setMultiProfile: (state, action) => {
      state.multiProfile = action.payload;
    },
    setMultiPost: (state, action) => {
      state.multiPost = action.payload;
    },
    setProfileAvatarGroup: (state, action) => {
      state.profileAvatarGroup = action.payload;
    },
    setPostAvatarGroup: (state, action) => {
      state.postAvatarGroup = action.payload;
    },
  },
});

export default slice.reducer;

export const { setMultiProfile, setMultiPost, setProfileAvatarGroup, setPostAvatarGroup } = slice.actions;
