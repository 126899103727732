import React from "react";
import { IFormDateFilter, TPagination } from "src/types/common";
import { TInstaProfileHashtag } from "src/types/instagram";
import { faPlay, faComments, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BarChart from "src/components/common/data-display/charts/bar-chart";
import { Box, Grid, Pagination, Paper, Stack, TextField, Typography } from "@mui/material";
import { TInstaSortHashtag } from "src/components/profile/instagram/profile-hashtags";
import { FormikProps } from "formik";
import DateFilter from "src/components/common/inputs/date-filter";
import useMediaQueries from "src/hooks/use-mediaqueries";
import ArrowOverflowBox from "src/components/common/layout/arrow-overflow-box";

interface HashtagChartProps {
  gridStyle: {
    containLabel: boolean;
    top: string;
    bottom: string;
    left: string;
    right: string;
  };
  mdDown: boolean;
  pagination: TPagination;
  upperLimit?: number;
  currentActiveIndex: number;
  formik: FormikProps<IFormDateFilter>;
  currentActiveHashtag: string | null;
  barChartData: { xAxis: string[]; data: number[] } | null;
  setPagination: React.Dispatch<React.SetStateAction<TPagination>>;
  setCurrentActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  setCurrentActiveHashtag: React.Dispatch<React.SetStateAction<string | null>>;
  data: TInstaProfileHashtag[] | undefined;
  sortHashtag: TInstaSortHashtag;
  setSortHashtag: React.Dispatch<React.SetStateAction<TInstaSortHashtag>>;
}

type Props = HashtagChartProps;

const HashtagChart: React.FC<Props> = ({
  data,
  mdDown,
  formik,
  upperLimit,
  gridStyle,
  pagination,
  barChartData,
  sortHashtag,
  setPagination,
  setSortHashtag,
  currentActiveIndex,
  currentActiveHashtag,
  setCurrentActiveHashtag,
  setCurrentActiveIndex,
}) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { smDown } = useMediaQueries();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper elevation={5} sx={{ py: 3, width: "100%", minHeight: "500px" }}>
          <Typography variant="h5" fontWeight={500} color="text.secondary" sx={{ textAlign: "center" }}>
            {currentActiveHashtag ? `#${currentActiveHashtag}` : ""}
          </Typography>
          <Box sx={{ height: "500px" }}>
            <Stack
              direction={smDown ? "column" : "row"}
              spacing={1}
              alignItems="center"
              justifyContent={"space-between"}
              sx={{ mt: 1, px: 1, width: "100%", boxSizing: "border-box" }}
            >
              {sortHashtag === "median_play" && (
                <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: mdDown ? 2 : 5 }}>
                  Median Plays&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faPlay} />
                </Typography>
              )}
              {sortHashtag === "median_like" && (
                <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: mdDown ? 2 : 5 }}>
                  Median Likes&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faThumbsUp} />
                </Typography>
              )}
              {sortHashtag === "median_comment" && (
                <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: mdDown ? 2 : 5 }}>
                  Median Comments&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faComments} />
                </Typography>
              )}

              <ArrowOverflowBox>
                <Stack direction="row" alignItems="center" spacing={1} sx={{ pr: mdDown ? 2 : 5, width: "100%" }}>
                  <DateFilter formik={formik} />
                  <Typography variant="body1" color="text.secondary">
                    Sort:
                  </Typography>
                  <TextField
                    variant="outlined"
                    hiddenLabel
                    select
                    value={sortHashtag}
                    size="small"
                    sx={{
                      width: "110px",
                      "& .MuiInputBase-root": {
                        minWidth: "120px",
                      },
                      "& .MuiNativeSelect-select": {
                        textTransform: "capitalize",
                      },
                    }}
                    SelectProps={{ native: true }}
                    onChange={e => {
                      setSortHashtag(e.target.value as TInstaSortHashtag);
                    }}
                  >
                    <option value={"median_play"}>Plays</option>
                    <option value={"median_like"}>Likes</option>
                    <option value={"median_comment"}>Comments</option>
                  </TextField>
                </Stack>
              </ArrowOverflowBox>
            </Stack>
            <Box sx={{ height: "100%" }}>
              {barChartData && (
                <BarChart
                  animation={false}
                  upperLimit={upperLimit}
                  barColors={["#77b6f0"]}
                  gridStyle={gridStyle}
                  xAxisData={barChartData.xAxis}
                  yAxisData={barChartData.data}
                  currentActiveIndex={currentActiveIndex}
                  onClick={e => {
                    setCurrentActiveHashtag(e.name);
                    setCurrentActiveIndex(e.dataIndex);
                  }}
                />
              )}
            </Box>
          </Box>

          <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 4, pr: 3 }}>
            <Pagination
              page={pagination?.currPage}
              onChange={(e, page) => {
                if (pagination && page !== pagination.currPage) {
                  if (data && data.length > 0) {
                    setCurrentActiveIndex(0);
                  }
                  setCurrentActiveHashtag(null); //reset active hashtag
                  setPagination({ ...pagination, currPage: page });
                }
              }}
              count={pagination?.total_pages ?? 0}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default HashtagChart;
