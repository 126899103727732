import React from "react";
/* components */
import PlanComponent from "src/components/common/data-display/plan-component";
/* 3rd party lib */
import useMediaQueries from "src/hooks/use-mediaqueries";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Stack, useTheme } from "@mui/material";

/* Util */

interface SubscriptionPlanDialogProps {
  open: boolean;
  onClose: () => void;
}

type Props = SubscriptionPlanDialogProps;

const SubscriptionPlanDialog: React.FC<Props> = ({ open, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { smDown } = useMediaQueries();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth fullScreen={smDown}>
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            Subscription Plan
            <IconButton onClick={onClose}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} pt={2}>
            <Paper elevation={10} sx={{ width: "100%", pt: 4 }}>
              <Box sx={{ p: "0 32px 32px 32px" }}>
                <Grid container spacing={2}>
                  <Grid alignItems="stretch" item xs={12} sm={6} md={3}>
                    <PlanComponent
                      active={true}
                      price={0}
                      theme={theme}
                      tier="Free"
                      subtitle="Explore with no commitment, no strings attached."
                      features={["2 Available Slots", "10-day Historical Data"]}
                      buttonText={"Currently Active"}
                    />
                  </Grid>
                  <Grid alignItems="stretch" item xs={12} sm={6} md={3}>
                    <PlanComponent
                      active={false}
                      price={9.99}
                      theme={theme}
                      tier="Lite"
                      subtitle="Enhance your journey with advanced tools and features."
                      features={[
                        "4 Available Slots",
                        "2 Saved Presets",
                        "Report Download",
                        "Profiles Comparison",
                        "30-day Historical Data",
                        "6 Profile Swaps",
                      ]}
                      buttonText="Contact Us"
                    />
                  </Grid>
                  <Grid alignItems="stretch" item xs={12} sm={6} md={3}>
                    <PlanComponent
                      active={false}
                      price={39.99}
                      theme={theme}
                      tier="Pro"
                      subtitle="Dive deep into unlimited possibilities and support."
                      features={[
                        "20 Available Slots",
                        "5 Saved Presets",
                        "Report Download",
                        "Profiles Comparison",
                        "Posts Comparison",
                        "Unrestricted Historical Data",
                        "30 Profile Swaps",
                        "Hashtag Favourites",
                        "Customer Support",
                      ]}
                      buttonText="Contact Us"
                    />
                  </Grid>
                  <Grid alignItems="stretch" item xs={12} sm={6} md={3}>
                    <PlanComponent
                      enterprise
                      active={false}
                      price={99.99}
                      theme={theme}
                      tier="Enterprise"
                      subtitle="Create a more tailored solutions for your business."
                      features={[
                        "Everything in Pro",
                        "Customized Number of Slots",
                        "Customized Number of Saved Presets",
                        "Customized Number of Profile Swaps",
                      ]}
                      buttonText="Contact Us"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubscriptionPlanDialog;
