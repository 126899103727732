import React, { useEffect, useState } from "react";
/* components */
/* 3rd party lib */
// import moment from "moment";
import { Box, Link, Skeleton, Stack, Theme, Typography } from "@mui/material";
import { faArrowsRotate, faClapperboardPlay, faImageSlash, faPhotoFilm } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* Util */
// import routes from "src/routes";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root-reducer";
import { ICompareInstaPost } from "src/types/instagram";
import { rainbowBarColors } from "src/components/common/data-display/charts/bar-chart";
import { useComparisonPostContext } from "src/contexts/comparison-post-context";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { ICompareTikTokPost } from "src/types/tiktok";
import routes from "src/routes";
import { vibratingAnimation } from "src/utils/constants";

interface PostSelectionProps {
  index: number;
  data: ICompareInstaPost | ICompareTikTokPost | null | undefined; //@TODO
  onDelete: (index: number) => void;
}

type Props = PostSelectionProps;

const PostSelection: React.FC<Props> = ({ data, index, onDelete }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { socialMedia } = useSelector((state: RootState) => state.general);
  const { theme, /* formik */ tableDirection, triggerVibration, setAddMoreDialog, setPostDialog, postIds } = useComparisonPostContext();
  // const trackIsAfterMax = data && moment(data.profile.track_since).isAfter(moment(formik.values.start_max));
  const { lgUp } = useMediaQueries();
  const [imageError, setImageError] = useState<{ [postId: string]: boolean }>({});
  /* ================================================== */
  /*  method */
  /* ================================================== */

  let descPadding = 0;

  if (lgUp) {
    if (postIds.length < 3) {
      descPadding = 3;
    } else if (postIds.length > 3 && postIds.length < 5) {
      descPadding = 2;
    } else {
      descPadding = 0;
    }
  }
  function onError(e: any, username: string) {
    setImageError({ [username]: true });
  }

  // descPadding = lgUp && postIds.length < 3 ? 3 : 2;
  /* ================================================== */
  /*  useEffect */

  /* ================================================== */
  /* ================================================== */
  /* ================================================== */

  const { mode } = theme.palette;
  const imageBackground = data && imageError[data.post.post_id] ? "#ff6b6bff" : "black";
  const noDataHoverStyle = !data
    ? {
        cursor: "pointer",
        background: `${(theme.palette.neutral as any)[mode === "dark" ? 600 : 800]} !important`,
        svg: {
          color: "white",
        },
      }
    : null;

  const widthSize = tableDirection === "horizontal" ? "35px" : postIds.length > 6 ? "150px" : "130px";
  const heightSize = tableDirection === "horizontal" ? "40px" : postIds.length > 6 ? "200px" : "180px";

  let animation = triggerVibration === "select" ? vibratingAnimation : {};

  useEffect(() => {
    setImageError({});
  }, [data]);

  return (
    <Box
      sx={{
        ...animation,
        position: "relative",
        ".deleteButton": {
          display: "none",
        },

        "& .userCircle": {
          background: data ? imageBackground : (theme.palette.neutral as any)[theme.palette.mode === "light" ? 100 : 800],
        },
        "&:hover": {
          ".deleteButton": {
            display: "grid",
          },
          "& .userCircle": {
            // transition: "all 0.5s ease",
            // backgroundColor: (theme.palette.neutral as any)[600],
            // svg: {
            //   color: "white",
            // },
            // transform: data ? "translateY(-5px)" : "translateY(0)",
            background: `${data ? imageBackground : (theme.palette.neutral as any)[theme.palette.mode === "light" ? 600 : 700]} !important`,
          },
        },
      }}
      className="center"
      py={2}
    >
      <Stack
        direction={tableDirection === "horizontal" ? "row" : "column"}
        alignItems="center"
        spacing={tableDirection === "horizontal" ? 1.5 : 1}
        justifyContent={tableDirection === "horizontal" ? "flex-start" : "center"}
        className="step-1 active-step4"
      >
        {data === undefined ? (
          <Skeleton
            variant="rectangular"
            width={widthSize}
            height={heightSize}
            sx={{ borderRadius: tableDirection === "horizontal" ? "4px" : "8px" }}
          />
        ) : (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {tableDirection === "horizontal" && <Typography variant="body2">{index + 1}.</Typography>}
            <Box sx={{ position: "relative", width: widthSize, height: heightSize }}>
              <Box
                className="userCircle"
                onClick={() => !data && setAddMoreDialog(true)}
                sx={{
                  border: `1px solid #dcdcdc`,
                  borderRadius: tableDirection === "horizontal" ? "4px" : "8px",
                  width: widthSize,
                  height: heightSize,
                  display: "grid",
                  placeItems: "center",
                  transition: "all 0.5s ease",

                  "&:hover": {
                    ...noDataHoverStyle,
                    " .select__modal": {
                      display: "grid",
                    },
                    ".deleteButton": {
                      display: "grid",
                    },
                  },
                }}
              >
                {data && (
                  <Box
                    className="select__modal"
                    sx={{
                      zIndex: 2,
                      top: 1,
                      left: 0.5,
                      width: widthSize,
                      height: heightSize,
                      display: "none",
                      position: "absolute",
                      borderRadius: tableDirection === "horizontal" ? "4px" : "8px",
                      placeItems: "center",
                      background: "rgba(0, 0, 0, 0.408)",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={
                        tableDirection === "horizontal"
                          ? {
                              background: (theme.palette.neutral as any)[mode === "dark" ? 800 : 500],
                              padding: "4px 8px",
                              borderRadius: tableDirection === "horizontal" ? "4px" : "8px",
                              transform: "translateX(14px)",
                            }
                          : {}
                      }
                    >
                      <RefreshButton index={index} setPostDialog={setPostDialog} theme={theme} />
                      <DeleteButton data={data} index={index} onDelete={onDelete} theme={theme} />
                    </Stack>
                  </Box>
                )}

                <Box
                  sx={{
                    borderRadius: tableDirection === "horizontal" ? "4px" : "8px",
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    placeItems: "center",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  {data ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          position: "absolute",
                          height: "100%" /* Set the height of your div */,
                          background: data.post.is_video ? "linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0, transparent 20%)" : "none",
                        }}
                      />
                      <Box
                        sx={{
                          width: "100%",
                          position: "absolute",
                          height: "100%" /* Set the height of your div */,
                          background: "linear-gradient(to top, rgba(0, 0, 0, 0.6) 0, transparent 20%)",
                        }}
                      />
                      {data.post.is_video && (
                        <Box
                          sx={{
                            position: "absolute",
                            zIndex: 2,
                            top: tableDirection === "horizontal" ? "-2px" : "8px",
                            right: tableDirection === "horizontal" ? "4px" : "8px",
                          }}
                        >
                          <FontAwesomeIcon color="white" icon={faClapperboardPlay} size={tableDirection === "horizontal" ? "xs" : "lg"} />
                        </Box>
                      )}

                      {index !== undefined && tableDirection === "vertical" && (
                        <Box sx={{ position: "absolute", zIndex: 2, bottom: "4px", right: "8px" }}>
                          <Typography variant="caption" color="white">
                            #{index + 1}
                          </Typography>
                        </Box>
                      )}
                      {imageError[data.post.post_id] ? (
                        <FontAwesomeIcon icon={faImageSlash} size={tableDirection === "horizontal" ? "lg" : "3x"} color="#ffffff" />
                      ) : (
                        <img
                          alt={data.post.image}
                          src={data.post.image}
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                          onError={e => onError(e, data.post.post_id)}
                        />
                      )}
                    </>
                  ) : (
                    <FontAwesomeIcon icon={faPhotoFilm} size="3x" />
                  )}
                </Box>
              </Box>
            </Box>
          </Stack>
        )}
        {data && (
          <>
            <Stack
              sx={{ width: "100%", pt: tableDirection === "horizontal" ? "6px" : 0 }}
              alignItems={tableDirection === "horizontal" ? "flex-start" : "center"}
              textAlign={tableDirection === "horizontal" ? "left" : "center"}
            >
              <Link
                onClick={e => {
                  e.stopPropagation();
                  // window.open(
                  //   `${socialMedia === "instagram" ? routes.INSTA_PROFILE : routes.TIKTOK_PROFILE}/${data.username}${data.post_id}`,
                  //   "_blank"
                  // );
                }}
                href={`${socialMedia === "instagram" ? routes.INSTA_PROFILE : routes.TIKTOK_PROFILE}/${data.post.profile_username}/${
                  data.post.post_id
                }`}
                rel="noopener noreferrer"
                target="_blank"
                variant="caption"
                color="primary.main"
                underline="always"
                sx={{ whiteSpace: "nowwrap", cursor: "pointer" }}
              >
                <Typography
                  lineHeight={tableDirection === "horizontal" ? "18px" : "initial"}
                  variant={postIds.length > 8 ? "body2" : "body1"}
                  sx={tableDirection === "horizontal" ? { width: "150px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" } : {}}
                >
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: rainbowBarColors[index % rainbowBarColors.length],
                    }}
                  />
                  &nbsp;Post {index + 1}
                </Typography>
              </Link>

              <Link
                onClick={e => {
                  e.stopPropagation();
                  window.open(
                    `${socialMedia === "instagram" ? routes.INSTA_PROFILE : routes.TIKTOK_PROFILE}/${data.post.profile_username}`,
                    "_blank"
                  );
                }}
                href={`${socialMedia === "instagram" ? routes.INSTA_PROFILE : routes.TIKTOK_PROFILE}/${data.post.profile_username}`}
                rel="noopener noreferrer"
                target="_blank"
                variant="caption"
                color="primary.main"
                underline="always"
                sx={{ whiteSpace: "nowwrap", cursor: "pointer" }}
              >
                <Typography color="primary.main" variant={"body2"}>
                  @{data.post.profile_username}
                </Typography>
              </Link>
              {tableDirection === "vertical" && (
                <Typography
                  variant={"caption"}
                  color="inherit"
                  sx={{
                    height: "60px",
                    px: descPadding,
                    textAlign: "left",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    wordBreak: "break-word",
                  }}
                  dangerouslySetInnerHTML={{ __html: data.post.title }}
                />
              )}
            </Stack>
            {/* {trackIsAfterMax && (
              <Stack alignItems="center" sx={{ position: "absolute", bottom: 0 }}>
                <Typography variant="caption" color="text.secondary">
                  Tracked since {moment(data.profile.track_since).format("DD MMM YY")}
                </Typography>
              </Stack>
            )} */}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default PostSelection;

const DeleteButton = ({
  data,
  theme,
  onDelete,
  index,
}: {
  data: ICompareInstaPost | ICompareTikTokPost | null | undefined;
  theme: Theme;
  onDelete: (index: number) => void;
  index: number;
}) => {
  return (
    <>
      {data && (
        <Box
          className="deleteButton"
          sx={{
            // position: "absolute",
            // top: 0,
            // right: 0,
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
            width: "30px",
            height: "30px",
            border: "1px solid #dcdcdc",
            borderRadius: "50%",
            background: "white",
            transition: "all 0.5s ease",
            "&:hover": {
              svg: {
                color: "white !important",
              },
              background: theme.palette.error.main,
            },
            "&:active": {
              transition: "none",
              svg: {
                color: `${theme.palette.error.main} !important`,
              },
              background: "white",
            },
          }}
          onClick={e => {
            e.stopPropagation();
            onDelete(index);
          }}
        >
          <FontAwesomeIcon icon={faTrash} size="lg" style={{ color: theme.palette.error.main }} />
        </Box>
      )}
    </>
  );
};

const RefreshButton = ({
  theme,
  setPostDialog,
  index,
}: {
  theme: Theme;
  index: number;
  setPostDialog: React.Dispatch<
    React.SetStateAction<{
      index: number;
      open: boolean;
    }>
  >;
}) => {
  return (
    <>
      <Box
        className="deleteButton"
        sx={{
          // position: "absolute",
          // top: 0,
          // right: 0,
          display: "grid",
          placeItems: "center",
          cursor: "pointer",
          width: "30px",
          height: "30px",
          border: "1px solid #dcdcdc",
          borderRadius: "50%",
          background: "white",
          transition: "all 0.5s ease",
          "&:hover": {
            svg: {
              color: "white !important",
            },
            background: theme.palette.primary.main,
          },
          "&:active": {
            transition: "none",
            svg: {
              color: `${theme.palette.primary.main} !important`,
            },
            background: "white",
          },
        }}
        onClick={e => {
          e.stopPropagation();
          setPostDialog({ index: index, open: true });
        }}
      >
        <FontAwesomeIcon icon={faArrowsRotate} size="lg" style={{ color: theme.palette.primary.main }} />
      </Box>
    </>
  );
};
