import React from "react";
import { alpha, Box, Stack, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { formatNumberWithSuffix } from "src/utils/general";
import { TTableDirection } from "src/pages/comparisons/posts/posts";

interface PercentDiffProps {
  percentage: number | null;
  difference: number | null;
  displayMode?: "percentage" | "number" | "both";
  simpleDisplay?: boolean; //simple display is without the fancy background color
  fontSize?: number;
  showIcon?: boolean;
  backgroundColor?: string;
  fontColor?: string;
  bgOpacity?: number;
  showZero?: boolean;
  tableDirection?: TTableDirection;
}

export const brighterBlue = "rgb(12, 140, 231)";

type Props = PercentDiffProps;

const PercentDiff: React.FC<Props> = ({
  fontColor,
  bgOpacity,
  tableDirection,
  showZero = false,
  backgroundColor,
  showIcon = true,
  fontSize = 12,
  simpleDisplay = false,
  displayMode = "both",
  percentage,
  difference,
}) => {
  const theme = useTheme();
  const { mode: themeMode } = theme.palette;

  let color = "neutral.400";
  let bgColor = backgroundColor ? backgroundColor : theme.palette.success.main;
  let icon = null;

  if (!percentage || !difference) return null;

  if (percentage > 0) {
    color = simpleDisplay ? brighterBlue : fontColor ? fontColor : "success.main";
    icon = <FontAwesomeIcon data-testid="icon-arrow-up" icon={faArrowUp} />;
  } else if (percentage < 0) {
    color = themeMode === "light" ? "error.main" : "error.light";
    bgColor = backgroundColor ? backgroundColor : themeMode === "light" ? theme.palette.error.main : theme.palette.error.light;
    icon = <FontAwesomeIcon data-testid="icon-arrow-down" icon={faArrowDown} />;
  } else if (percentage === 0) {
    bgColor = "#b7b7b788";
    color = "white";
  }

  return (
    <>
      {((percentage !== 0 && difference !== 0 && !showZero) || showZero) && (
        <Stack direction={"row"} spacing={1} alignItems="center">
          <Box
            data-testid="background-box"
            sx={{
              whiteSpace: "nowrap",
              background: simpleDisplay ? "none" : alpha(bgColor, bgOpacity ? bgOpacity : themeMode === "light" ? 0.1 : 0.18),
              px: simpleDisplay ? 0 : 1,
              borderRadius: "8px",
              minWidth: "35px",
              py: tableDirection && tableDirection === "horizontal" ? 0 : 0.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <>
              {(displayMode === "percentage" || displayMode === "both") && (
                <Typography
                  data-testid="percentage-num"
                  variant="caption"
                  fontWeight={500}
                  color={color}
                  sx={{ fontSize: `${fontSize}px`, lineHeight: "16px" }}
                >
                  {difference === 0 ? "-" : `${Math.abs(percentage).toFixed(simpleDisplay ? 1 : 2)}%`}
                  &nbsp;
                  {showIcon ? icon : null}
                </Typography>
              )}

              {(displayMode === "number" || displayMode === "both") && (
                <>
                  {simpleDisplay ? (
                    <Typography variant="caption" fontWeight={500} color={color} sx={{ fontSize: `${fontSize}px`, lineHeight: "16px" }}>
                      {formatNumberWithSuffix(Math.abs(difference))}&nbsp;{icon && icon}
                    </Typography>
                  ) : (
                    <Typography variant="caption" fontWeight={500} color={color} sx={{ fontSize: `${fontSize}px` }}>
                      ({formatNumberWithSuffix(Math.abs(difference))})
                    </Typography>
                  )}
                </>
              )}
            </>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default PercentDiff;
