import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { useNavigate } from "react-router-dom";

export type TMoreActionsMenu = { anchorEl: any; rowData: any };

interface MoreActionsMenuProps {
  rowData: any;
  onClose: () => void;
  anchorEl: any;
  rowActions: any;
  moreActionsMenu: TMoreActionsMenu;
  setMoreActionsMenu: React.Dispatch<React.SetStateAction<TMoreActionsMenu>>;
}

type Props = MoreActionsMenuProps;

const MoreActionsMenu: React.FC<Props> = props => {
  const { onClose, anchorEl, rowActions, rowData, moreActionsMenu, setMoreActionsMenu } = props;
  const navigate = useNavigate();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      onClose={onClose}
      open={anchorEl !== null}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuList disablePadding>
        {rowActions &&
          rowActions.map((action: any, index: number) => (
            <MenuItem
              divider={rowActions.length !== index + 1}
              key={index}
              onClick={() => {
                if (action.navigation) {
                  navigate(action.navigation.url(rowData));
                } else {
                  action.onClick(rowData);
                  setMoreActionsMenu({ ...moreActionsMenu, anchorEl: null }); //close the menu
                }
              }}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={action.icon} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ fontWeight: 600, variant: "body2" }}>{action.label}</ListItemText>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};

export default MoreActionsMenu;
