import React from "react";
/* components */
/* 3rd party lib */
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import DataDisplay from "src/components/common/inputs/data-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faInfoCircle, faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { IAdjustDialog } from "src/pages/admin/superadmin-user";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getTranslateString } from "src/utils/translate";
import { FORM_WARNING_REQUIRED } from "src/constants/translate-keys/common";

interface RootProps {
  maxWidth?: number | string;
}
const StyledDateTextField = styled(TextField, { shouldForwardProp: props => props !== "disablePadding" })<RootProps>(({ maxWidth }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    paddingBottom: "9px !important",
    paddingTop: "8px !important",
    maxWidth: maxWidth,
    width: "100%",
  },
}));

export interface IAdjustCreditFormValues {
  expired_date: string;
  credit: number;
  remark: string;
}
interface AdjustCreditDialogProps {
  adjustDialog: IAdjustDialog;
  loading?: boolean;
  onClose: () => void;
  onConfirm: any;
}

const schema = (adjustDialog: IAdjustDialog, t: any) =>
  Yup.object().shape({
    credit: Yup.string()
      .test("not-zero", "Credit cannot be 0", value => value !== "0")
      .required(getTranslateString(t, FORM_WARNING_REQUIRED)),
    expired_date: adjustDialog.type === "addition" ? Yup.string().required(getTranslateString(t, FORM_WARNING_REQUIRED)) : Yup.string(),
  });

type Props = AdjustCreditDialogProps;

const AdjustCreditDialog: React.FC<Props> = ({ adjustDialog, loading, onClose, onConfirm }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { t } = useTranslation();

  const { mdDown } = useMediaQueries();
  const formik = useFormik<IAdjustCreditFormValues>({
    validateOnChange: false,
    validationSchema: schema(adjustDialog, t),
    initialValues: {
      credit: 0,
      expired_date: "",
      remark: "",
    },
    onSubmit: values => {
      onConfirm(adjustDialog.type, formik);
    },
  });

  let note = "";
  if (adjustDialog.type === "addition") {
    note = "added to";
  } else if (adjustDialog.type === "minus") {
    note = "deducted from";
  } else {
    note = "be overwritten to";
  }

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleFocus = (event: any) => {
    event.target.select();
  };

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog maxWidth="md" fullWidth={mdDown} open={adjustDialog.open} onClose={handleClose}>
      <DialogTitle sx={{ p: 2 }}>
        <Stack direction="row" justifyContent={"space-between"} alignItems="center" width="100%">
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Typography variant="inherit">{adjustDialog.type === "addition" ? "Add Credit" : "Deduct Credit"}</Typography>

            {adjustDialog.type === "addition" ? (
              <Box
                sx={{
                  background: theme.palette.success.main,

                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <FontAwesomeIcon size="sm" icon={faPlus} color={"white"} />
              </Box>
            ) : (
              <Box
                sx={{
                  background: theme.palette.error.main,
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <FontAwesomeIcon size="sm" icon={faMinus} color={"white"} />
              </Box>
            )}
          </Stack>
          <IconButton onClick={handleClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <form id="adjustForm" onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ py: 2 }}>
            <Box sx={{ width: mdDown ? "auto" : "600px" }}>
              <DataDisplay
                alignCenter={false}
                label={"Credit"}
                variant={"body2"}
                value={
                  <Stack spacing={1} sx={{ width: "100%" }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      Transcation Type: {adjustDialog.type === "addition" ? "Increment (+)" : "Decrement (-)"}
                    </Stack>
                    <TextField
                      value={formik.values.credit}
                      fullWidth
                      type="number"
                      variant="outlined"
                      hiddenLabel
                      name="credit"
                      size="small"
                      required
                      autoFocus
                      placeholder="Enter credit"
                      onChange={formik.handleChange}
                      error={formik.errors.credit ? true : false}
                      helperText={formik.errors.credit}
                      // inputRef={creditRef}
                      InputProps={{
                        // native: true,
                        onFocus: handleFocus,
                        onClick: handleFocus,
                      }}
                    />
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <FontAwesomeIcon icon={faInfoCircle} color={theme.palette.text.secondary} />
                      <Typography variant="caption" color="text.secondary">
                        {formik.values.credit} credit(s) will be {note} this account
                      </Typography>
                    </Stack>
                  </Stack>
                }
              />
            </Box>

            {adjustDialog.type === "addition" && (
              <DataDisplay
                alignCenter={false}
                label={"Expired Date"}
                variant={"body2"}
                value={
                  <MobileDatePicker
                    closeOnSelect
                    mask="__-__-____"
                    // name="start_max"
                    minDate={moment().format("YYYY-MM-DD")}
                    value={formik.values.expired_date}
                    inputFormat={"DD-MM-YYYY"}
                    onChange={date => formik.setFieldValue("expired_date", date)}
                    renderInput={params => (
                      <StyledDateTextField
                        {...params}
                        variant="outlined"
                        name="expired_date"
                        hiddenLabel
                        maxWidth={"100%"}
                        error={formik.errors.expired_date ? true : false}
                        helperText={formik.errors.expired_date}
                        placeholder={mdDown ? "End Date" : "Click to enter end date"}
                        value={moment(formik.values.expired_date).format("YYYY-MM-DD")}
                      />
                    )}
                    InputProps={{
                      required: true,
                      // Add the icon as the end adornment
                      endAdornment: (
                        <InputAdornment position="end">
                          <FontAwesomeIcon icon={faCalendar} />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
            )}

            <DataDisplay
              alignCenter={false}
              label={"Remark"}
              variant={"body2"}
              value={
                <TextField
                  multiline={true}
                  minRows={3}
                  value={formik.values.remark}
                  fullWidth
                  placeholder="Enter Remark"
                  variant="outlined"
                  hiddenLabel
                  name="remark"
                  size="small"
                  onChange={formik.handleChange}
                />
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" form="adjustForm" disabled={loading} variant="contained" color={"primary"}>
            {adjustDialog.type === "addition" ? "Add (+)" : "Deduct (-) "}
            {loading && <CircularProgress sx={{ ml: 1 }} size={18} color="inherit" />}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AdjustCreditDialog;
