import React, { useEffect, useRef, useState } from "react";
/* components */
import TikTokProfileListing from "src/pages/listing/tiktok/tiktok-profile-listing";
import InstaProfileListing from "src/pages/listing/instagram/insta-profile-listing";
import InstaPostListing from "src/pages/listing/instagram/insta-post-listing";
import TikTokPostListing from "src/pages/listing/tiktok/tiktok-post-listing";
import DelaySubmitInput from "src/components/common/inputs/delay-submit-input";
import MobileSortDrawer from "src/components/common/inputs/mobile-sort-drawer";
import InstaPostHashtagListing from "src/pages/listing/instagram/insta-post-hashtag-listing";
import TiktokPostHashtagListing from "src/pages/listing/tiktok/tiktok-post-hashtag-listing";
import ComparisonAvatarGroup from "src/components/comparison/comparison-avatar-group";
import DataDisplay from "src/components/common/inputs/data-display";
/* 3rd party lib */
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { faArrowLeft, faArrowRight, faLeft } from "@fortawesome/pro-solid-svg-icons";
/* Util */
import Insta from "src/images/insta.png";
import TikTok from "src/images/darkModeTikTok.png";
import useData from "src/hooks/use-data";
import { RootState } from "src/store/root-reducer";
import { IPostAvatarGroup, IFormDateFilter, TSocialMedia, TUserSlot } from "src/types/common";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { useComparisonPostContext } from "src/contexts/comparison-post-context";
import ComparisonAddAccount from "src/components/comparison/profiles/comparison-add-account";
import useSWR from "swr";
import useHttp from "src/hooks/use-http";

interface MultiPostDialogProps {
  open: boolean;
  onClose: () => void;
  onSelectPosts: (postIds: IPostAvatarGroup[]) => void;
  handleSavePreset: (name: string, createPresetWithPosts: boolean, avatarGroups?: string[]) => Promise<void>;
}

type Props = MultiPostDialogProps;

const MultiPostDialog: React.FC<Props> = ({ open, onClose, onSelectPosts, handleSavePreset }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const sortFields = [
    { key: "title", label: "Post Title" },
    { key: "engagement_rate", label: "Engagement Rate" },
    { key: "count_play", label: "Plays" },
    { key: "count_like", label: "Likes" },
    { key: "count_comment", label: "Comments" },
    { key: "date_posted", label: "Date Posted" },
  ];

  const theme = useTheme();
  const { apiEndpoint } = useHttp();
  const { sort, dispatchData } = useData();
  const [search, setSearch] = useState("");
  const [sortDialog, setSortDialog] = useState(false);
  const [postSearch, setPostSearch] = useState("");
  const { smDown } = useMediaQueries();
  const dataTableListRef = useRef(null);
  const { currentActive } = useComparisonPostContext();
  const nameFieldRef = useRef<HTMLDivElement | null>(null);
  const { socialMedia } = useSelector((state: RootState) => state.general);
  const { multiPost, postAvatarGroup } = useSelector((state: RootState) => state.compare);

  const [expandProfile, setExpandProfile] = useState<string | null>(null);
  const [showHashtagSearch, setShowHashtagSearch] = useState(false);
  const [hashtagSearch, setHashtagSearch] = useState("");
  const [nextStep, setNextStep] = useState(false);

  const [compareAddAccountDialog, setCompareAddAccountDialog] = useState<{ open: boolean; socialMedia: TSocialMedia }>({
    open: false,
    socialMedia: "instagram",
  });

  const { data: userSlotData } = useSWR<TUserSlot>([apiEndpoint.USER_SLOT]);
  useEffect(() => {
    if (showHashtagSearch) {
      setExpandProfile(null);
    }
  }, [showHashtagSearch]);

  // last 7 days
  // const startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
  // get the end of the current month
  // const endDate = moment().format("YYYY-MM-DD");

  const formik = useFormik<IFormDateFilter>({
    validateOnChange: false,
    initialValues: {
      start_min: null,
      start_max: null,
    },
    onSubmit: () => {},
  });

  const handleResetAndCloseForm = (resetForm: any) => {
    resetForm();
    onClose();
    setNextStep(false);
  };

  const presetFormik = useFormik<{ name: string }>({
    validateOnChange: false,
    initialValues: {
      name: "",
    },
    onSubmit: values => {
      if (postAvatarGroup && onSelectPosts) {
        onSelectPosts(postAvatarGroup.slice().reverse());
        handleSavePreset(
          values.name,
          true,
          postAvatarGroup
            .slice()
            .reverse()
            .map(child => child.postId)
        );
        setNextStep(false);
      }
      handleResetAndCloseForm(() => formik.resetForm());
    },
  });

  const nextStepComponent = (
    <Paper sx={{ p: 2 }} elevation={5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataDisplay
            label={"Title"}
            variant={smDown ? "caption" : "body2"}
            value={
              currentActive.preset ? (
                currentActive.preset.title
              ) : (
                <TextField
                  // inputRef={nameFieldRef}
                  inputProps={{
                    ref: nameFieldRef,
                  }}
                  // ref={nameFieldRef}
                  // autoFocus={true}
                  placeholder="Enter title for the preset here"
                  fullWidth
                  variant="outlined"
                  hiddenLabel
                  name="name"
                  size="small"
                  required
                  onChange={presetFormik.handleChange}
                />
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <DataDisplay
            alignCenter={false}
            label={"Selected Posts"}
            variant={smDown ? "caption" : "body2"}
            value={
              postAvatarGroup && (
                <AvatarGroup
                  spacing={-2}
                  max={postAvatarGroup.length}
                  sx={{
                    flexWrap: "wrap",
                    flexDirection: "row !important",
                    ".MuiAvatarGroup-root": {
                      flexDirection: "row !important",
                    },
                    ".MuiAvatar-root:last-of-type": {
                      // marginLeft: "-8px",
                    },
                    ".MuiAvatar-root:first-of-type": {
                      background: "none",
                      border: "none",
                      color: theme.palette.text.secondary,
                      fontSize: "20px",
                      marginRight: "4px",
                    },
                  }}
                >
                  {postAvatarGroup.map((child, index) => (
                    <Tooltip
                      arrow
                      key={`avatar_${index}`}
                      title={
                        "title" in child && (
                          <Stack>
                            <Typography variant="caption">@{child.username}</Typography>
                            <Typography variant="caption">{child.title}</Typography>
                          </Stack>
                        )
                      }
                    >
                      <Avatar
                        variant={"rounded"}
                        alt={child.username}
                        src={child.downloaded_image}
                        sx={{ width: "60px", height: "90px", border: `1px solid ${theme.palette.text.secondary} !important` }}
                      />
                    </Tooltip>
                  ))}
                </AvatarGroup>
              )
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleClose = () => {
    onClose();
    setSearch("");
    formik.resetForm();
    setPostSearch("");
    setHashtagSearch("");
    setExpandProfile(null);
    setNextStep(false);
    setCompareAddAccountDialog({ open: false, socialMedia: socialMedia });
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  const toggleText = `${(theme.palette.grey as any)[400]} !important`;

  useEffect(() => {
    if (nextStep && nameFieldRef.current) {
      nameFieldRef.current.focus();
    }
  }, [nextStep]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Dialog
        disableEscapeKeyDown={multiPost && Object.keys(multiPost).length > 0}
        open={open}
        onClose={(_, reason) => {
          if (reason && reason === "backdropClick" && multiPost && Object.keys(multiPost).length > 0) return;
          handleClose();
        }}
        maxWidth="lg"
        fullWidth
        fullScreen={smDown}
      >
        <DialogTitle>
          <Stack direction="row" spacing={1} alignItems="center">
            {socialMedia === "instagram" ? (
              <img src={Insta} alt="insta" width="25px" height="25px" />
            ) : (
              <img src={TikTok} alt="tiktok" width="25px" height="25px" />
            )}
            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              {nextStep ? (currentActive.preset ? "Edit Preset" : "Create Preset") : "Select Post(s)"}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ maxHeight: smDown ? "calc(100vh - 160px)" : "calc(100vh - 250px)", overflowY: "auto" }}>
          {compareAddAccountDialog.open ? (
            <Paper elevation={10} sx={{ px: 1, pb: 4, pt: 2, mt: 2 }}>
              <Button
                startIcon={<FontAwesomeIcon icon={faLeft} />}
                variant="text"
                sx={{ mt: 0, mb: 2 }}
                onClick={() => setCompareAddAccountDialog({ open: false, socialMedia: socialMedia })}
              >
                Back
              </Button>
              <ComparisonAddAccount
                onSuccess={() => {
                  setCompareAddAccountDialog({ open: false, socialMedia: socialMedia });
                }}
                userSlotData={userSlotData}
                socialMedia={socialMedia}
                filterSearch={search}
              />
            </Paper>
          ) : (
            <Stack spacing={1} sx={{ my: 2 }}>
              {!nextStep && (
                <>
                  <Stack alignItems="center">
                    <ToggleButtonGroup
                      value={showHashtagSearch}
                      exclusive
                      sx={{ height: "46px", "& .MuiButtonBase-root": { border: `1px solid ${(theme.palette as any).neutral[400]}` } }}
                      onChange={(_e, value) => {
                        if (value) {
                          if (value === "true") {
                            setShowHashtagSearch(true);
                          } else {
                            setShowHashtagSearch(false);
                          }
                        }
                      }}
                      aria-label="Platform"
                    >
                      <ToggleButton
                        title="Search Username"
                        value="false"
                        sx={{
                          background: !showHashtagSearch ? `${theme.palette.secondary.main} !important` : "",
                          color: !showHashtagSearch ? "white !important" : toggleText,
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography textTransform={"capitalize"} variant="body2" color={!showHashtagSearch ? "white !important" : toggleText}>
                            Search by Username @
                          </Typography>
                        </Stack>
                      </ToggleButton>
                      <ToggleButton
                        title="Search Hashtag"
                        value="true"
                        sx={{
                          background: showHashtagSearch ? `${theme.palette.secondary.main} !important` : "",
                          color: showHashtagSearch ? "white !important" : toggleText,
                        }}
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography textTransform={"capitalize"} variant="body2" color={showHashtagSearch ? "white !important" : toggleText}>
                            Search by Hashtag #
                          </Typography>
                        </Stack>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>

                  <Paper sx={{ p: 1 }}>
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        {expandProfile && (
                          <Button
                            startIcon={<FontAwesomeIcon icon={faLeft} />}
                            variant="contained"
                            sx={{ pr: 5, pl: 4 }}
                            onClick={() => setExpandProfile(null)}
                          >
                            Back
                          </Button>
                        )}
                        <Box sx={{ width: { lg: "250px" } }}>
                          <DelaySubmitInput
                            value={showHashtagSearch ? hashtagSearch : expandProfile ? postSearch : search}
                            styles={{ "& .MuiOutlinedInput-root": { height: "37px !important" } }}
                            variant="outlined"
                            setValue={showHashtagSearch ? setHashtagSearch : expandProfile ? setPostSearch : setSearch}
                            clearInput={showHashtagSearch ? hashtagSearch === "" : expandProfile ? postSearch === "" : search === ""}
                            placeholder={
                              showHashtagSearch ? "Search post by hashtag" : `Search ${socialMedia} ${expandProfile ? "post" : "username"}`
                            }
                          />
                        </Box>
                      </Stack>
                      <ComparisonAvatarGroup type="post" avatarGroup={postAvatarGroup} />
                    </Stack>
                  </Paper>
                </>
              )}

              {showHashtagSearch ? (
                socialMedia === "instagram" ? (
                  <InstaPostHashtagListing hashtagSearch={hashtagSearch} handleClose={handleClose} formik={formik} onSelectPost={() => {}} />
                ) : (
                  <TiktokPostHashtagListing hashtagSearch={hashtagSearch} handleClose={handleClose} formik={formik} onSelectPost={() => {}} />
                )
              ) : (
                <>
                  {expandProfile ? (
                    nextStep ? (
                      <>
                        {
                          <form id="submitPreset" onSubmit={presetFormik.handleSubmit}>
                            {nextStepComponent}
                          </form>
                        }
                      </>
                    ) : (
                      <>
                        <Stack spacing={1}>
                          {socialMedia === "instagram" && (
                            <InstaPostListing
                              multiSelect
                              search={postSearch}
                              selection
                              username={expandProfile}
                              onSelectPost={() => {}}
                              handleClose={handleClose}
                            />
                          )}
                          {socialMedia === "tiktok" && (
                            <TikTokPostListing
                              multiSelect
                              search={postSearch}
                              selection
                              username={expandProfile}
                              onSelectPost={() => {}}
                              handleClose={handleClose}
                            />
                          )}
                        </Stack>
                      </>
                    )
                  ) : (
                    <>
                      {socialMedia === "instagram" && (
                        <InstaProfileListing
                          search={search}
                          id="post_dialog"
                          multiPostSelect
                          showTrackButton={false}
                          showGlobalFilter={false}
                          columnVisibility={{ action: false }}
                          dataTableListRef={dataTableListRef}
                          onSelectProfile={profile => {
                            setExpandProfile(profile.username);
                          }}
                          setCompareAddAccountDialog={setCompareAddAccountDialog}
                        />
                      )}
                      {socialMedia === "tiktok" && (
                        <TikTokProfileListing
                          search={search}
                          id="post_dialog"
                          multiPostSelect
                          columnVisibility={{ action: false }}
                          showGlobalFilter={false}
                          showTrackButton={false}
                          dataTableListRef={dataTableListRef}
                          onSelectProfile={profile => {
                            setExpandProfile(profile.username);
                          }}
                          setCompareAddAccountDialog={setCompareAddAccountDialog}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Stack>
          )}
        </DialogContent>

        <DialogActions sx={{ p: 1 }}>
          {nextStep ? (
            <Button variant="outlined" onClick={() => setNextStep(false)} startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
              Back
            </Button>
          ) : (
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          )}
          {/* only show this button for the expand profile component */}
          {!nextStep && !showHashtagSearch && expandProfile && (
            <Button
              disabled={!!(postAvatarGroup && postAvatarGroup.length === 0)}
              variant="contained"
              color={"success"}
              onClick={() => {
                setExpandProfile(null);
              }}
            >
              Add More
            </Button>
          )}
          {nextStep ? (
            currentActive.preset ? (
              <Button
                disabled={!!(postAvatarGroup && postAvatarGroup.length === 0)}
                variant="contained"
                color={"primary"}
                onClick={() => {
                  if (postAvatarGroup && onSelectPosts) {
                    onSelectPosts(postAvatarGroup);
                    setNextStep(false);
                  }
                }}
              >
                Submit
              </Button>
            ) : (
              <Button
                form="submitPreset"
                type="submit"
                disabled={!!(postAvatarGroup && postAvatarGroup.length === 0)}
                variant="contained"
                color={"primary"}
              >
                Submit
              </Button>
            )
          ) : (
            <Button
              disabled={!!(postAvatarGroup && postAvatarGroup.length === 0)}
              variant="contained"
              color={"primary"}
              onClick={() => {
                setNextStep(true);
              }}
              endIcon={<FontAwesomeIcon icon={faArrowRight} />}
            >
              Continue
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <MobileSortDrawer
        open={sortDialog}
        sort={sort}
        sortFields={sortFields}
        onSort={key => {
          dispatchData({ type: "SET_SORT", payload: key });
          // dispatch(setPage(1));
        }}
        onDrawerClose={() => setSortDialog(false)}
      />
    </>
  );
};

export default MultiPostDialog;
