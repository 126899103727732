import { faGrid, faTable } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setViewMode } from "src/slices/layout";
import { RootState } from "src/store/root-reducer";
/* components */
/* 3rd party lib */
/* Util */
interface ViewModeToggleProps {
  height?: number;
}

type Props = ViewModeToggleProps;

const ViewModeToggle: React.FC<Props> = ({ height = 46 }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const dispatch = useDispatch();
  const { viewMode } = useSelector((state: RootState) => state.layout);
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Box>
      <ToggleButtonGroup
        color="primary"
        value={viewMode}
        exclusive
        sx={{ height: height }}
        onChange={(_e, value) => {
          if (value) {
            dispatch(setViewMode(value));
          }
        }}
        aria-label="Platform"
      >
        <ToggleButton value="table">
          <FontAwesomeIcon icon={faTable} />
        </ToggleButton>
        <ToggleButton value="grid">
          <FontAwesomeIcon icon={faGrid} />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ViewModeToggle;
