import React, { useState } from "react";
/* components */
/* 3rd party lib */
import * as Yup from "yup";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
/* Util */
import LogoLight from "src/images/logo_light.png";
import LogoDark from "src/images/logo_dark.png";
import routes from "src/routes";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import SpinnerComponent from "src/components/common/feedback/spinner";
import { setTheme } from "src/slices/general";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { getTranslateString } from "src/utils/translate";
import { FORM_WARNING_PASSWORD_MUST_MATCH, FORM_WARNING_REQUIRED } from "src/constants/translate-keys/common";
import useHttp from "src/hooks/use-http";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark, faLockKeyhole } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";

const StyledTextField = styled(TextField)(() => ({
  "& input": {
    overflow: "hidden",
    borderRadius: "8px",
    padding: "20px 8px",
  },
  "& .MuiInput-root": {
    overflow: "hidden",
    borderRadius: "8px",
  },
}));

interface ResetPasswordProps {}

const schema = (t: any) =>
  Yup.object().shape({
    new_password1: Yup.string()
      .required(getTranslateString(t, FORM_WARNING_REQUIRED))
      .oneOf([Yup.ref("new_password2")], getTranslateString(t, FORM_WARNING_PASSWORD_MUST_MATCH)),
    new_password2: Yup.string()
      .required(getTranslateString(t, FORM_WARNING_REQUIRED))
      .oneOf([Yup.ref("new_password1")], getTranslateString(t, FORM_WARNING_PASSWORD_MUST_MATCH)),
  });

type Props = ResetPasswordProps;

const ResetPassword: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const uid = searchParams.get("uid");

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mode } = theme.palette;
  const { t } = useTranslation();
  const { apiEndpoint } = useHttp();
  const { smDown } = useMediaQueries();

  const [loading, setLoading] = useState(false);
  const [sessionInvalid, setSessionInvalid] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  /**
   * Formik configuration for form handling.
   */
  const formik = useFormik({
    validateOnChange: false,
    validationSchema: schema(t),
    initialValues: {
      new_password1: "",
      new_password2: "",
    },
    onSubmit: async values => {
      setLoading(true);

      try {
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiEndpoint.RESET_PASSWORD.substring(1)}`, { ...values, token, uid });
        setLoading(false);
        if (data && data.detail) {
          toast.success(data.detail);
        }
        setResetSuccess(true);
      } catch (err: any) {
        console.error(err);
        setLoading(false);

        if (err.response && err.response.data) {
          if (err.response.data.new_password2) {
            toast.error(err.response.data.new_password2.join(" "));
          } else if (
            err.response.data.token &&
            Array.isArray(err.response.data.token) &&
            err.response.data.token.length > 0 &&
            err.response.data.token[0] === "Invalid value"
          ) {
            setSessionInvalid(true);
          } else {
            toast.error("Something went wrong when sending email");
          }
        }
      }
    },
  });

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Box>
        <Button onClick={() => navigate(routes.HOME)} sx={{ position: "absolute", left: "16px", top: "16px" }}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <img src={mode === "light" ? LogoLight : LogoDark} alt="logo" height="40px" />
          </Stack>
        </Button>

        <IconButton
          sx={{ ml: 1, position: "absolute", right: "16px", top: "16px" }}
          onClick={() => {
            if (mode === "dark") {
              dispatch(setTheme("light"));
            } else {
              dispatch(setTheme("dark"));
            }
          }}
        >
          {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>

        <Box sx={{ height: "100vh", width: "100vw", display: "grid", placeItems: "center" }}>
          <Paper elevation={10} sx={{ width: smDown ? "85%" : "500px", px: smDown ? 2 : 3, py: 5 }}>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack sx={{ mb: 2 }} alignItems="center">
                <Box sx={{ position: "relative" }}>
                  {sessionInvalid ? (
                    <FontAwesomeIcon icon={faCircleXmark} color={theme.palette.error.main} size={"4x"} />
                  ) : (
                    <FontAwesomeIcon icon={faLockKeyhole} color={theme.palette.primary.main} size="4x" />
                  )}

                  {resetSuccess && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: "-8px",
                        right: "-16px",
                        background: (theme.palette.neutral as any)[100],
                        borderRadius: "50%",
                        padding: 0.5,
                      }}
                    >
                      <FontAwesomeIcon icon={faCircleCheck} color={theme.palette.success.main} size={"xl"} />
                    </Box>
                  )}
                </Box>

                <Typography fontWeight={smDown ? 700 : 700} variant={smDown ? "h5" : resetSuccess ? "h4" : "h3"} sx={{ mt: 2 }}>
                  {resetSuccess ? "Password Reset Successfully" : sessionInvalid ? "Invalid Session" : "Reset Password"}
                </Typography>
                {!resetSuccess && !sessionInvalid && (
                  <Typography variant="body2" mt={1}>
                    Do not refresh this page
                  </Typography>
                )}
              </Stack>

              {!resetSuccess && !sessionInvalid && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <StyledTextField
                          variant="outlined"
                          hiddenLabel
                          name="new_password1"
                          placeholder="Password"
                          value={formik.values.new_password1}
                          onChange={formik.handleChange}
                          error={formik.errors.new_password1 ? true : false}
                          helperText={formik.errors.new_password1}
                          InputProps={{
                            autoComplete: "current-password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  tabIndex={-1}
                                  sx={{ color: theme.palette.primary.main }}
                                  onClick={() => setShowPassword1(!showPassword1)}
                                  edge="end"
                                >
                                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showPassword1 ? "text" : "password"}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <StyledTextField
                          variant="outlined"
                          hiddenLabel
                          name="new_password2"
                          placeholder="Confirm Password"
                          value={formik.values.new_password2}
                          onChange={formik.handleChange}
                          error={formik.errors.new_password2 ? true : false}
                          helperText={formik.errors.new_password2}
                          InputProps={{
                            autoComplete: "current-password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  tabIndex={-1}
                                  sx={{ color: theme.palette.primary.main }}
                                  onClick={() => setShowPassword2(!showPassword2)}
                                  edge="end"
                                >
                                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          type={showPassword2 ? "text" : "password"}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={formik.isSubmitting || loading}
                      endIcon={loading ? <SpinnerComponent color="white" modifier={0.4} /> : null}
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              )}
            </form>
            {sessionInvalid && (
              <Stack spacing={2}>
                <Typography variant="body1">Your session might have expired or something else went wrong.</Typography>

                <Link href={routes.FORGOT_PASSWORD}>Try again</Link>
              </Stack>
            )}
            {resetSuccess && (
              <Stack spacing={2}>
                <Typography variant="body1">You may now login to your account with the new password.</Typography>
                <Link href={routes.LOGIN}>Go to login page</Link>
              </Stack>
            )}
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
