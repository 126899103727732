import React, { useEffect, useState } from "react";
/* components */
import LogoLight from "src/images/logo_light.png";
import LogoDark from "src/images/logo_dark.png";
import SpinnerComponent from "src/components/common/feedback/spinner";
/* 3rd party lib */
import axios from "axios";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useSearchParams } from "react-router-dom";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { Box, Stack, Button, Typography, IconButton, useTheme, Paper, Link } from "@mui/material";

/* Util */
import routes from "src/routes";
import useHttp from "src/hooks/use-http";
import { updateUser } from "src/slices/auth";
import { setTheme } from "src/slices/general";
import useMediaQueries from "src/hooks/use-mediaqueries";
import useEffectAfterMount from "src/hooks/use-effectaftermount";

interface ConfirmEmailProps {}

type Props = ConfirmEmailProps;

const ConfirmEmail: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const uid = searchParams.get("uid");
  const v = searchParams.get("v");

  const navigate = useNavigate();
  const { smDown } = useMediaQueries();
  const { http, apiEndpoint } = useHttp();
  const theme = useTheme();
  const { mode } = theme.palette;
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string | null>(null);
  const [confirmEmailData, setConfirmEmailData] = useState<any | null>(null);
  const [confirmEmailError, setConfirmEmailError] = useState<any | null>(null);
  const [showError, setShowError] = useState(false);

  // const { data: confirmEmailData, error: confirmEmailError } = useSWR([
  //   token && uid && v && apiEndpoint.CONFIRM_EMAIL,
  //   { params: { token, uid, v } },
  // ]);

  const isLoading = !confirmEmailError && !confirmEmailData;

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (confirmEmailError) {
      setShowError(true);
    }
  }, [confirmEmailError]);

  useEffectAfterMount(() => {
    if (token && uid && v) {
      const handleConfirmEmail = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}${apiEndpoint.CONFIRM_EMAIL.substring(1)}?token=${token}&uid=${uid}&v=${v}`
            // {
            //   headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            // }
          );

          setConfirmEmailData(response.data);
        } catch (err: any) {
          console.error(err);
          if (err && err.response && err.response.data) {
            setConfirmEmailError(err.response.data);
          }
        }
      };

      // Call the async function
      handleConfirmEmail();
    }
  }, [apiEndpoint, token, uid, v]);

  useEffect(() => {
    if (confirmEmailData) {
      const fetchUserProfile = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiEndpoint.USER_PROFILE.substring(1)}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
          });

          setEmail(response.data.email);

          dispatch(updateUser(response.data));
        } catch (err) {
          console.error(err);
        }
      };

      // Call the async function
      fetchUserProfile();
    }
  }, [http, apiEndpoint, confirmEmailData, dispatch]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Box>
        <Button onClick={() => navigate(routes.HOME)} sx={{ position: "absolute", left: "16px", top: "16px" }}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <img src={mode === "light" ? LogoLight : LogoDark} alt="logo" height="40px" />
          </Stack>
        </Button>

        <IconButton
          sx={{ ml: 1, position: "absolute", right: "16px", top: "16px" }}
          onClick={() => {
            if (mode === "dark") {
              dispatch(setTheme("light"));
            } else {
              dispatch(setTheme("dark"));
            }
          }}
        >
          {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>

        <Box sx={{ height: "100vh", width: "100vw", display: "grid", placeItems: "center" }}>
          <Paper elevation={10} sx={{ width: smDown ? "85%" : "500px", px: smDown ? 2 : 3, py: 5 }}>
            {isLoading ? (
              <Box sx={{ minHeight: "270px", display: "grid", placeItems: "center" }}>
                <SpinnerComponent modifier={1.2} />
              </Box>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={showError ? faCircleXmark : faCircleCheck}
                  color={showError ? theme.palette.error.main : theme.palette.success.main}
                  size={"4x"}
                />
                <Typography variant={smDown ? "h5" : "h3"}>{showError ? "Invalid Session" : "Congratulations"}</Typography>

                <Stack sx={{ mt: 2 }} spacing={0}>
                  {showError ? (
                    <Typography variant="body1">
                      Your session might have expired or is invalid.
                      <br />
                    </Typography>
                  ) : (
                    <Typography variant="body1">
                      Your email{email ? <b style={{ color: theme.palette.primary.main }}>{` ${email} `}</b> : " "}
                      has been verified.
                      <br />
                    </Typography>
                  )}

                  {!showError && <Typography variant="body1">You can now login to the application. </Typography>}
                </Stack>
                <Stack spacing={3} sx={{ mt: 3 }}>
                  <Box>
                    <Button
                      onClick={async () => {
                        if (showError) {
                          try {
                            const timerEndAt = moment().add("60", "seconds"); // Get current system time
                            localStorage.setItem("timerEndAt", timerEndAt.toString()); // Store start time in local storage
                            await http.post(apiEndpoint.SEND_EMAIL_VERIFICATION);
                            navigate(routes.VERIFY);
                          } catch (err) {
                            console.error(err);
                            toast.error("Something went wrong when sending email");
                          }
                        } else {
                          navigate(routes.ROOT);
                        }
                      }}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      {showError ? "Resend Verification Email" : "Go to dashboard"}
                    </Button>
                  </Box>
                  {showError && <Link href={routes.LOGIN}>Go to login page</Link>}
                </Stack>
              </>
            )}
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default ConfirmEmail;
