/* components */
import ComparisonInputs from "src/components/comparison/posts/comparison-inputs";
import SocialMediaDropdown from "src/components/common/inputs/social-media-dropdown";
/* 3rd party lib */
import React, { useEffect, useRef, useState } from "react";
import { faCirclePlus, faCircleX } from "@fortawesome/pro-solid-svg-icons";
import {
  Box,
  Button,
  Grid,
  lighten,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
/* Util */
import { ICompareInstaPostJsonData } from "src/types/instagram";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useComparisonPostContext } from "src/contexts/comparison-post-context";
import { ICompareTikTokPostJsonData } from "src/types/tiktok";
import TableRowCell from "./table-row-cell";
import { faArrowDown, faArrowUp, faTrashCanList } from "@fortawesome/pro-regular-svg-icons";
import { checkObjectNotEmpty } from "src/utils/general";

interface ComparisonTableComponentProps {
  tableHeight: number;
  postData?: ICompareInstaPostJsonData | ICompareTikTokPostJsonData;
  columnDefinition: any[];
  largestNumDict:
    | {}
    | {
        [key: string]: number;
      };
}

type Props = ComparisonTableComponentProps;

const ComparisonTableComponent: React.FC<Props> = ({ largestNumDict, postData, columnDefinition, tableHeight }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { smDown, lgDown } = useMediaQueries();
  const {
    theme,
    postExists,
    currentActive,
    openFullScreenDialog,
    tableDirection,
    displayMode,
    handleDeselectAll,
    addMoreDisabled,
    postIds,
    setAddMoreDialog,
  } = useComparisonPostContext();
  const { mode } = theme.palette;
  const labelColumnWidth = "16%";

  const overflowRef = useRef<any | null>(null);
  const [sort, setSort] = useState<{ key: string | null; direction: "asc" | "desc" | null }>({ key: null, direction: null });
  const [showRemoveAllDialog, setShowRemoveAllDialog] = useState(false);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleSort = (columnKey: string) => {
    if (sort.key !== columnKey) {
      setSort({ key: columnKey, direction: "desc" });
    } else {
      if (sort.direction === "desc") {
        setSort({ key: columnKey, direction: "asc" });
      } else if (sort.direction === "asc") {
        setSort({ key: null, direction: null });
      } else {
        setSort({ key: columnKey, direction: "desc" });
      }
    }
  };

  const sortFunction = (a: string, b: string) => {
    if (a === "" || b === "" || sort.key === null || !!!postData) return 0;

    const getValue = (key: string, data: any) => (key === "engagement_rate" ? data?.post.engagement_stats?.[key] ?? 0 : data?.date_last?.[key] ?? 0);

    // extract out the data based on the profile username
    const aValue = getValue(sort.key, (postData as any).results[a]);
    const bValue = getValue(sort.key, (postData as any).results[b]);

    if (sort.direction === "asc") {
      return aValue - bValue;
    } else {
      return bValue - aValue;
    }
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    // everytime when new profile usernames are added, scroll to most right
    if (overflowRef.current) {
      overflowRef.current.scrollLeft = overflowRef.current.scrollWidth;
    }
  }, [postIds]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Paper
      elevation={5}
      sx={{
        height: openFullScreenDialog ? "100%" : tableHeight,
        borderRadius: "8px",
        width: "100%",
        position: "relative",
      }}
    >
      <Stack alignItems={"center"} direction="row" justifyContent={"space-between"} sx={{ pt: 2, px: 2 }}>
        <SocialMediaDropdown
          inputStyle={{
            "& .MuiOutlinedInput-input": {
              py: "7px !important",
            },
          }}
        />
        <ComparisonInputs showRemoveAllDialog={showRemoveAllDialog} setShowRemoveAllDialog={setShowRemoveAllDialog} />
      </Stack>

      <Stack spacing={1} sx={{ right: lgDown ? "-12px" : "-28px", top: "20%", position: "absolute" }}>
        {!addMoreDisabled && (
          <Box
            sx={{
              height: lgDown ? "55px" : "70px",
              width: lgDown ? "55px" : "70px",
              display: "grid",
              placeItems: "center",

              background: mode === "dark" ? (theme.palette.neutral as any)[900] : "white",
              borderRadius: "50%",
              cursor: "pointer",
              // transformOrigin: "0 0",
              zIndex: 10,
              boxShadow: "4px 0px 8px rgba(0, 0, 0, 0.1)", // Shadow on the right side
              "&:hover": {
                transition: "all 0.5s ease",
                boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.08)", // Shadow on the right side
                svg: {
                  color: lighten(theme.palette.secondary.main, 0.3),
                },
                transform: "scale(1.05)",
              },
            }}
            onClick={() => setAddMoreDialog(true)}
          >
            <FontAwesomeIcon icon={faCirclePlus} size={lgDown ? "2x" : "3x"} color={theme.palette.secondary.main} />
          </Box>
        )}

        {postExists && (
          <Tooltip title={`Remove all posts from preset ${currentActive.preset?.title}`}>
            <Box
              className="active-step5"
              sx={{
                height: lgDown ? "55px" : "70px",
                width: lgDown ? "55px" : "70px",
                display: "grid",
                placeItems: "center",
                background: mode === "dark" ? (theme.palette.neutral as any)[900] : "white",
                borderRadius: "50%",
                cursor: "pointer",
                // transformOrigin: "0 0",
                zIndex: 10,
                boxShadow: "4px 0px 8px rgba(0, 0, 0, 0.1)", // Shadow on the right side
                "&:hover": {
                  transition: "all 0.5s ease",
                  boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.08)", // Shadow on the right side
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => setShowRemoveAllDialog(true)}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  height: lgDown ? "35px" : "50px",
                  width: lgDown ? "35px" : "50px",
                  borderRadius: "50%",
                  background: theme.palette.error.main,
                  "&:hover": {
                    transition: "all 0.5s ease",
                    boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.08)", // Shadow on the right side
                    background: lighten(theme.palette.error.main, 0.3),
                    transform: "scale(1.05)",
                  },
                }}
              >
                <FontAwesomeIcon icon={faTrashCanList} size={lgDown ? "lg" : "xl"} color={"white"} />
              </Box>
            </Box>
          </Tooltip>
        )}
      </Stack>

      <Box sx={{ p: 2, height: `calc(100% - ${currentActive && currentActive.preset ? "180px" : "85px"})` }}>
        {currentActive && currentActive.preset && (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              py: 2,
              mb: 2,
              my: 2,
              px: 2,
              background: (theme.palette.neutral as any)[mode === "dark" ? 700 : 200],
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={0} sm={4}></Grid>
              <Grid item xs={8} sm={4}>
                <Typography textAlign={smDown ? "left" : "center"} variant="body1" color="text.secondary" fontWeight={"bolder"}>
                  Preset: {currentActive.preset?.title}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} display="flex" justifyContent={"flex-end"}>
                <Button
                  onClick={handleDeselectAll}
                  variant="outlined"
                  endIcon={<FontAwesomeIcon icon={faCircleX} color={theme.palette.primary.main} style={{ fontSize: "14px" }} />}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        {tableDirection === "vertical" ? (
          <TableContainer
            ref={overflowRef}
            sx={{ height: `calc(100% - ${currentActive.preset ? 16 : 0}px)`, overflowY: "hidden !important", overflowX: "auto !important" }}
          >
            <Table aria-label="simple table" sx={{ height: "100%" }}>
              <TableBody>
                {columnDefinition.map((column, index) => {
                  return (
                    <TableRow
                      key={`row_${index}`}
                      sx={{
                        transition: "none",
                        "& .pinned-column": {
                          position: "sticky",
                          left: 0,
                          zIndex: 3,
                        },

                        "&:nth-of-type(odd)": {
                          backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[900] : "white",
                        },
                        "&:nth-of-type(even)": {
                          backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[800] : "white",
                        },
                        "&:nth-of-type(odd) .pinned-column": {
                          backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[900] : "white",
                        },
                        "&:nth-of-type(even) .pinned-column": {
                          backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[800] : "white",
                        },

                        // "&:hover:not(:first-of-type)": {
                        //   "& .pinned-column": {
                        //     background: mode === "dark" ? darken(theme.palette.primary.dark, 0.4) : (theme.palette.neutral as any)[200],
                        //   },
                        //   background: mode === "dark" ? darken(theme.palette.primary.dark, 0.4) : (theme.palette.neutral as any)[200],
                        // },
                      }}
                    >
                      <TableCell
                        sx={{
                          cursor: column.key !== "post" && column.key !== "date_range" ? "pointer" : "default",
                          userSelect: "none",
                          width: `calc(${labelColumnWidth})px`,
                          minWidth: "120px",
                          whiteSpace: "nowrap",
                          position: "relative",
                          "& .arrow": {
                            position: "absolute",
                            right: "8px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          },
                          "&:hover": {
                            "& .shadow_arrow": {
                              display: column.key !== sort.key || sort.direction === null ? "block" : "none",
                            },
                          },
                        }}
                        className={mode === "light" ? "tablecell pinned-column" : "tablecell-dark pinned-column"}
                        variant="head"
                        onClick={() => {
                          handleSort(column.key);
                        }}
                      >
                        {column.label}
                        {column.key !== "post" && column.key !== "date_range" && (
                          <Box className="arrow shadow_arrow" sx={{ display: "none" }}>
                            &nbsp;
                            <FontAwesomeIcon icon={faArrowDown} color={(theme.palette.neutral as any)[500]} />
                          </Box>
                        )}
                        {sort.direction === "asc" && sort.key === column.key && sort.key !== "date_range" && sort.key !== "profile" && (
                          <Box className="arrow">
                            &nbsp;
                            <FontAwesomeIcon icon={faArrowUp} />
                          </Box>
                        )}
                        {sort.direction === "desc" && sort.key === column.key && sort.key !== "date_range" && sort.key !== "profile" && (
                          <Box className="arrow">
                            &nbsp;
                            <FontAwesomeIcon icon={faArrowDown} />
                          </Box>
                        )}
                      </TableCell>

                      {postIds
                        .slice()
                        .sort(sortFunction)
                        .map((child, index) => {
                          let postObj = (child && postData && checkObjectNotEmpty(postData) && postData.results[child]) || null;

                          return (
                            <TableRowCell
                              key={`table_component_row_cell_${index}`}
                              column={column}
                              columnWidth={`calc((100% /${postIds.length}))`}
                              index={index}
                              postObj={postObj as any}
                              percentageDiff={displayMode}
                              largestNumDict={largestNumDict}
                            />
                          );
                        })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer
            ref={overflowRef}
            sx={{ height: "100%", overflowY: tableDirection === "horizontal" ? (openFullScreenDialog ? "hidden" : "auto") : "hidden" }}
          >
            <Table aria-label="simple table" sx={{ height: "100%" }}>
              <TableHead>
                <TableRow
                  sx={{
                    transition: "none",
                    "& .pinned-column": {
                      position: "sticky",
                      top: 0,
                      zIndex: 3,
                    },
                    "& .left-pinned": {
                      left: 0,
                      zIndex: 4,
                    },
                  }}
                >
                  <TableCell
                    sx={{ textAlign: "center", backgroundColor: (theme.palette.neutral as any)[mode === "dark" ? 800 : 100] }}
                    className={`${mode === "light" ? "tablecell pinned-column" : "tablecell-dark pinned-column"} left-pinned`}
                  >
                    {columnDefinition[0].label}
                  </TableCell>
                  {columnDefinition.slice(1).map(column => {
                    return (
                      <TableCell
                        key={column.key}
                        sx={{
                          cursor: "pointer",
                          userSelect: "none",
                          textAlign: "center",
                          backgroundColor: (theme.palette.neutral as any)[mode === "dark" ? 800 : 100],
                          position: "relative",
                          "& .arrow": {
                            position: "absolute",
                            right: "8px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          },
                          "&:hover": {
                            "& .shadow_arrow": {
                              display: column.key !== sort.key || sort.direction === null ? "block" : "none",
                            },
                          },
                        }}
                        className={mode === "light" ? "tablecell pinned-column" : "tablecell-dark pinned-column"}
                        onClick={() => handleSort(column.key)}
                      >
                        <Stack direction="row" justifyContent={"center"} spacing={1} alignItems="center">
                          {column.label}
                          {column.key !== "profile" && column.key !== "date_range" && (
                            <Box className="arrow shadow_arrow" sx={{ display: "none" }}>
                              &nbsp;
                              <FontAwesomeIcon icon={faArrowDown} color={(theme.palette.neutral as any)[500]} />
                            </Box>
                          )}
                          {sort.direction === "asc" && sort.key === column.key && sort.key !== "date_range" && (
                            <Box className="arrow">
                              &nbsp;
                              <FontAwesomeIcon icon={faArrowUp} />
                            </Box>
                          )}
                          {sort.direction === "desc" && sort.key === column.key && sort.key !== "date_range" && (
                            <Box className="arrow">
                              &nbsp;
                              <FontAwesomeIcon icon={faArrowDown} />
                            </Box>
                          )}
                        </Stack>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {postIds
                  .slice()
                  .sort(sortFunction)
                  .map((child, index) => {
                    let postObj = child && postData && postData.results[child];

                    return (
                      <TableRow
                        key={`profile_${index}`}
                        sx={{
                          transition: "none",
                          "& .pinned-column": {
                            position: "sticky",
                            left: 0,
                            zIndex: 2,
                          },

                          "&:nth-of-type(odd)": {
                            backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[900] : "white",
                          },
                          "&:nth-of-type(even)": {
                            backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[800] : "white",
                          },
                          "&:nth-of-type(odd) .pinned-column": {
                            backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[900] : "white",
                          },
                          "&:nth-of-type(even) .pinned-column": {
                            backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[800] : "white",
                          },

                          // "&:hover": {
                          //   "& .pinned-column": {
                          //     background: mode === "dark" ? darken(theme.palette.primary.dark, 0.4) : (theme.palette.neutral as any)[200],
                          //   },
                          //   background: mode === "dark" ? darken(theme.palette.primary.dark, 0.4) : (theme.palette.neutral as any)[200],
                          // },
                        }}
                      >
                        <TableRowCell
                          className="pinned-column"
                          column={columnDefinition[0]}
                          index={index}
                          postObj={postObj as any}
                          percentageDiff={displayMode}
                          largestNumDict={largestNumDict}
                        />
                        {columnDefinition.slice(1).map(column => (
                          <TableRowCell
                            column={column}
                            index={index}
                            postObj={postObj as any}
                            percentageDiff={displayMode}
                            largestNumDict={largestNumDict}
                          />
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Paper>
  );
};

export default ComparisonTableComponent;
