import React, { useEffect, useMemo, useState } from "react";
/* components */
import DelaySubmitInput from "src/components/common/inputs/delay-submit-input";
/* 3rd party lib */
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProfileSearchData, TSocialMedia } from "src/types/common";
import { faImage, faInfoCircle, faMagnifyingGlass, faUserGroup } from "@fortawesome/pro-solid-svg-icons";
import { Box, Typography, Stack, useTheme, styled, alpha, Autocomplete, FormControl, Select, MenuItem, SxProps, Tooltip } from "@mui/material";
/* Util */
import routes from "src/routes";
import useHttp from "src/hooks/use-http";
import Insta from "src/images/insta.png";
import DarkModeTikTok from "src/images/darkModeTikTok.png";
import { RootState } from "src/store/root-reducer";
import { setSocialMedia } from "src/slices/general";
import { getTranslateString } from "src/utils/translate";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { formatNumberWithSuffix } from "src/utils/general";
import { NO_DATA_FOUND } from "src/constants/translate-keys/common";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor:
    theme.palette.mode === "light" ? alpha((theme.palette.neutral as any)[400], 0.15) : alpha((theme.palette.neutral as any)[500], 0.15),
  "&:hover": {
    backgroundColor:
      theme.palette.mode === "light" ? alpha((theme.palette.neutral as any)[500], 0.15) : alpha((theme.palette.neutral as any)[400], 0.15),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(1),
    width: "100%",
  },
}));

interface ProfileSearchProps {
  searchBarStyle?: SxProps;
  dropdownStyle?: SxProps;
  outerWidth?: SxProps;
  handleClose?: () => void;
}

type Props = ProfileSearchProps;

const ProfileSearch: React.FC<Props> = ({ searchBarStyle, outerWidth, handleClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const { mode } = theme.palette;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { smDown } = useMediaQueries();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { http, apiEndpoint } = useHttp();
  const { socialMedia } = useSelector((state: RootState) => state.general);
  const [autoComplete, setAutoComplete] = useState<IProfileSearchData | string>("");

  let defaultSearchbarStyle = {
    background: mode === "light" ? "white" : "",
    "& .MuiOutlinedInput-input": {
      py: "1px !important",
    },

    "& .MuiInputBase-root": {
      pl: "8px",
      pr: 0.8,
    },
    "& .MuiInputBase-input": {
      transition: theme.transitions.create("width"),
      width: "100% !important",
      [theme.breakpoints.down("sm")]: {
        width: "50px !important",
      },
      [theme.breakpoints.up("sm")]: {
        width: "23.5ch !important",
      },
    },
  };

  let defaultOption = useMemo(() => {
    return {
      category_name: "default",
      count_follower: -1,
      count_following: -1,
      description: "default",
      display_name: "default",
      downloaded_image: "default",
      profile_id: "default",
      username: "default",
      is_private: false,
      is_tracked: false,
    };
  }, []);
  const [options, setOptions] = useState<IProfileSearchData[]>([defaultOption]);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleCloseAutocomplete = () => {
    if (handleClose) {
      handleClose();
      setOpen(false);
    }
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    const getSearchResult = async () => {
      try {
        let apiUrl = socialMedia === "instagram" ? apiEndpoint.INSTAGRAM_SEARCH : apiEndpoint.TIKTOK_SEARCH;

        const { data } = await http.get(apiUrl, {
          params: { q: search },
        });

        if (search !== "" && data && data.length > 0) {
          setOptions([defaultOption, ...data]);
        } else {
          setOptions([defaultOption]);
        }
      } catch (err) {
        console.error(err);
      }
    };

    getSearchResult();
  }, [socialMedia, defaultOption, http, apiEndpoint, search]);

  useEffect(() => {
    setAutoComplete(search);
  }, [search]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Stack direction="row" spacing={0} alignItems="center" sx={outerWidth}>
      <Tooltip title="Select social media platform for the profile you are looking for">
        <FontAwesomeIcon icon={faInfoCircle} color={theme.palette.text.secondary} style={{ marginRight: "8px", cursor: "pointer" }} />
      </Tooltip>
      <Stack alignItems="flex-start">
        {socialMedia && (
          <FormControl
            sx={{
              p: 0,
              "& .MuiInputBase-root": {
                borderRadius: "8px 0 0 8px !important",
                height: "40px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${theme.palette.mode === "light" ? theme.palette.divider : (theme.palette.neutral as any)[400]} !important`,
                borderRight: "none !important",

                "&:focus": {
                  border: "none !important",
                },
              },
            }}
          >
            <Select
              value={socialMedia}
              onChange={e => {
                dispatch(setSocialMedia(e.target.value as TSocialMedia));
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                "& .MuiInputBase-input": {
                  p: "10px",
                },
              }}
            >
              <MenuItem value={"instagram"}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <img src={Insta} alt="insta" width="18px" height="18px" />
                </Stack>
              </MenuItem>
              <MenuItem value={"tiktok"}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <img src={DarkModeTikTok} alt="insta" width="18px" height="18px" />
                </Stack>
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>

      <Search>
        <Autocomplete
          freeSolo
          open={open && search !== ""}
          onOpen={() => setOpen(true)}
          onClose={handleCloseAutocomplete}
          id="search-bar"
          sx={{
            "& .MuiIconButton-root": {
              py: "0",
              pl: "0px",
              pr: "0",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "0 8px 8px 0",
              pr: "8px !important",
              height: "40px",
            },
          }}
          clearOnEscape
          options={options}
          noOptionsText={<Typography variant="caption">{getTranslateString(t, NO_DATA_FOUND)}</Typography>}
          // clearIcon={search !== "" && <FontAwesomeIcon icon={faCircleXmark} color={theme.palette.text.secondary} size="xs" />}
          isOptionEqualToValue={(option, value) => {
            return option && value && option.profile_id === value.profile_id;
          }}
          // filterOptions={(localOptions, state) => {
          //   const inputValue = state.inputValue.toLowerCase().trim().replace(/\s/g, "");

          //   if (inputValue === "") {
          //     return options; // Return the options in their original order when the input value is empty
          //   }

          //   // Custom filter logic using multiple criteria
          //   const filteredOptions = localOptions.filter(option => {
          //     if (option.profile_id === "default") return true;
          //     // Match based on multiple criteria
          //     const sameDisplayName =
          //       option && option.display_name && option.display_name.toLowerCase().trim().replace(/\s/g, "").includes(inputValue);
          //     const sameDescription =
          //       option &&
          //       option.description &&
          //       option.description &&
          //       option.description.toLowerCase().trim().replace(/\s/g, "").includes(inputValue);
          //     const sameCategoryName =
          //       option && option.category_name && option.category_name.toLowerCase().trim().replace(/\s/g, "").includes(inputValue);

          //     // Return true if any of the criteria matches
          //     return sameDisplayName || sameDescription || sameCategoryName;
          //   });

          //   return filteredOptions;
          // }}
          value={autoComplete}
          onChange={(_event, profile) => {
            if (profile && typeof profile === "object") {
              setSearch("");
              if (profile.username === "default") {
                // navigate to find new username page
                navigate(routes.SEARCH, { state: { searchKey: search } });
              } else {
                setAutoComplete(profile);
                navigate(`/profile/${socialMedia}/${profile.username}`);
              }
            } else {
              handleCloseAutocomplete();
              setSearch("");
              setAutoComplete("");
            }
          }}
          getOptionLabel={option => (typeof option === "string" ? option : !!option.username ? option.username : "")}
          renderOption={(props, option) => {
            let tempProps = { ...props, key: option.profile_id };
            const { downloaded_image, username, profile_id } = option;

            return profile_id === "default" ? (
              <Stack
                direction="row"
                spacing={1}
                component="li"
                {...tempProps}
                sx={{ px: "4px !important", "&:not(:first-of-type)": { borderTop: `1px solid ${theme.palette.divider}` } }}
              >
                <Box sx={{ position: "relative", width: "40px", height: "40px" }}>
                  <Box
                    sx={{
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "1px solid #dcdcdc",
                      width: "40px",
                      height: "40px",
                      background: mode === "light" ? (theme.palette.neutral as any)[100] : (theme.palette.neutral as any)[800],
                    }}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} color={theme.palette.text.secondary} />
                  </Box>
                </Box>

                <Stack
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    variant="caption"
                    fontWeight={500}
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {search}
                  </Typography>
                  <Typography
                    variant="caption"
                    color={mode === "light" ? "text.secondary" : "neutral.300"}
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "calc(100% - 4px)",
                    }}
                  >
                    Click to search profile
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <Stack
                direction="row"
                spacing={1}
                component="li"
                {...tempProps}
                sx={{ px: "4px !important", "&:not(:first-of-type)": { borderTop: `1px solid ${theme.palette.divider}` } }}
              >
                <Box sx={{ position: "relative", width: "40px", height: "40px" }}>
                  <Box
                    sx={{
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "1px solid #dcdcdc",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    {downloaded_image ? (
                      <img alt={`${username}`} src={downloaded_image} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                    ) : (
                      <FontAwesomeIcon icon={faImage} color={theme.palette.text.secondary} size={smDown ? "xl" : "2x"} />
                    )}
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -4,
                      right: -4,
                      width: "12px",
                      height: "12px",
                      background: "white",
                      border: "1px solid #dcdcdc",
                      borderRadius: "50%",
                      padding: 0.3,
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {socialMedia === "instagram" ? (
                      <img src={Insta} alt="insta" width="100%" height="100%" />
                    ) : (
                      <img src={DarkModeTikTok} alt="tiktok" width="100%" height="100%" />
                    )}
                  </Box>
                </Box>
                <Stack
                  sx={{
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    variant="caption"
                    fontWeight={500}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {option.display_name}
                  </Typography>
                  <Typography variant="caption" color={mode === "light" ? "text.secondary" : "neutral.300"}>
                    <FontAwesomeIcon icon={faUserGroup} color={(theme.palette.neutral as any)[mode === "light" ? 400 : 300]} />
                    &nbsp;
                    {formatNumberWithSuffix(option.count_follower)} Followers
                  </Typography>
                  <Typography variant="caption" color={mode === "light" ? "text.secondary" : "neutral.300"}>
                    @{option.username}
                  </Typography>
                </Stack>
              </Stack>
            );
          }}
          renderInput={params => (
            <DelaySubmitInput
              // clearIcon={false}
              value={search}
              // params={params}
              variant="outlined"
              setValue={setSearch}
              clearInput={search === ""}
              // loading={profileSearchLoading}
              placeholder={`Track ${socialMedia} username`}
              styles={searchBarStyle ?? defaultSearchbarStyle}
              {...params}
            />
          )}
        />
      </Search>
    </Stack>
  );
};

export default ProfileSearch;
