import React, { useState } from "react";
/* components */
import SubscriptionPlanDialog from "src/components/common/dialog/subscription-plan-dialog";
/* 3rd party lib */
import { Box, useTheme } from "@mui/material";
/* Util */

interface ExpiredOverlayProps {}

type Props = ExpiredOverlayProps;

const ExpiredOverlay: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 99,
          borderRadius: "8px",
        }}
      ></Box>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          position: "absolute",
          background: theme.palette.mode === "light" ? "#bfbfbf38" : "#2d2d2d38",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 99,
          borderRadius: "8px",
          backdropFilter: "blur(2.5px)",
        }}
      />

      <SubscriptionPlanDialog open={showSubscriptionDialog} onClose={() => setShowSubscriptionDialog(false)} />
    </>
  );
};

export default ExpiredOverlay;
