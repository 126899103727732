import { Theme } from "@mui/material";

export interface IProfileTab {
  OVERVIEW: "OVERVIEW";
  GROWTH: "GROWTH";
  POSTS: "POSTS";
  STATISTICS: "STATISTICS";
  HASHTAG: "HASHTAG";
}

export const PROFILE_TAB_PANEL = {
  OVERVIEW: "OVERVIEW",
  GROWTH: "GROWTH",
  POSTS: "POSTS",
  STATISTICS: "STATISTICS",
  HASHTAG: "HASHTAG",
};

// ID to be able to target and store state
export const TABLE_ID = {
  HOMEPAGE: "HOMEPAGE",
  ACTIVITY_LOG: "ACTIVITY_LOG",
  USER_ACTIVITY_LOG: "USER_ACTIVITY_LOG",
  INSTA_LISTING: "INSTA_LISTING",
  TIKTOK_LISTING: "TIKTOK_LISTING",
  PROFILE_HASHTAG: "PROFILE_HASHTAG",
  PROFILE_POST: "PROFILE_POST",
  PROFILE_HISTORY: "PROFILE_HISTORY",
  TIKTOK_PROFILE_HISTORY: "TIKTOK_PROFILE_HISTORY",
  PROFILE_HASHTAG_POST: "PROFILE_HASHTAG_POST",
  SUBSCRIPTION_ACCOUNTS: "SUBSCRIPTION_ACCOUNTS",
  PROFILE_POST_HISTORY: "PROFILE_POST_HISTORY",
  CREDIT_TRANSACTION_HISTORY: "CREDIT_TRANSACTION_HISTORY",
  TIKTOK_PROFILE_POST_HISTORY: "TIKTOK_PROFILE_POST_HISTORY",
  SUBSCRIPTION_PLAN_TABLE: "SUBSCRIPTION_PLAN_TABLE",
  ACCOUNT_TRACKING_STATUS: "ACCOUNT_TRACKING_STATUS",
};

export const themeColors = {
  instagram: "#515BD4,#8134AF,#DD2A7B,#FEDA77,#F58529",
  facebook: "#1877F2,#1877F2",
  youtube: "#ea8c8c,#FF0000,#8e1c1c",
  tiktok: "#000000,#FE2C55,#25F4EE",
};
export const solidColors = {
  instagram: "#FE0B81",
  facebook: "#1877F2",
  youtube: "#FF0000",
  tiktok: "#000000",
};

// export const themeColors = {
//   instagram: "rgba(81, 91, 212, 0.6),rgba(129, 52, 175, 0.6),rgba(221, 42, 123, 0.6),rgba(254, 218, 119, 0.6),rgba(245, 133, 41, 0.6)",
//   facebook: "rgba(24, 119, 242, 0.6),rgba(24, 119, 242, 0.6)",
//   youtube: "rgba(234, 140, 140, 0.6),rgba(255, 0, 0, 0.6),rgba(142, 28, 28, 0.6)",
//   tiktok: "rgba(0, 0, 0, 0.6),rgba(254, 44, 85, 0.6),rgba(37, 244, 238, 0.6)",
// };

interface IChartColors {
  line: string;
  area: string;
}

export const instaLineColors = {
  total_view: {
    line: "#62d46a",
    area: "#a1eaaf",
  },
  total_like: {
    line: "#5289e8",
    area: "#8eb1ed",
  },
  follower: {
    line: "#ebd93b",
    area: "#e8ee94",
  },
  total_comment: {
    line: "#f2905c",
    area: "#e4ab8d",
  },
  post: {
    line: "#d33a89",
    area: "#e89cc3",
  },
  following: {
    line: "#986ce0",
    area: "#bea7e3",
  },
};

export const tiktokLineColors: {
  [key: string]: IChartColors;
  total_like: IChartColors;
  video: IChartColors;
  total_play: IChartColors;
  total_comment: IChartColors;
  follower: IChartColors;
  total_saved: IChartColors;
  total_share: IChartColors;
  following: IChartColors;
} = {
  total_play: {
    line: "#62d46a",
    area: "#a1eaaf",
  },
  total_like: {
    line: "#5289e8",
    area: "#8eb1ed",
  },
  follower: {
    line: "#ebd93b",
    area: "#e8ee94",
  },
  total_comment: {
    line: "#f2905c",
    area: "#e4ab8d",
  },
  video: {
    line: "#d33a89",
    area: "#e89cc3",
  },
  following: {
    line: "#986ce0",
    area: "#bea7e3",
  },
  total_saved: {
    line: "#e4b858",
    area: "#e3d0a7",
  },
  total_share: {
    line: "#6ce0de",
    area: "#a2e6e5",
  },
};

export const vibratingAnimation = {
  animation: "vibrate 0.3s infinite",

  "@keyframes vibrate": {
    "0%": {
      transform: "translateX(0) rotate(0deg)",
    },
    "25%": {
      transform: "translateX(-3px) rotate(-1deg)",
    },
    "50%": {
      transform: "translateX(3px) rotate(1deg)",
    },
    "75%": {
      transform: "translateX(-3px) rotate(-1deg)",
    },
    "100%": {
      transform: "translateX(3px) rotate(1deg)",
    },
  },
};

export const subscriptionStatus = (theme: Theme): { [key: string]: { label: string; color: string; info: string | null } } => {
  return {
    active_subscriptions: {
      label: "Active",
      color: theme.palette.success.main,
      info: "The account's plan is currently active and in good standing.",
    },
    expired_subscriptions: {
      label: "Expired",
      color: theme.palette.error.main,
      info: "The account's plan has expired.",
    },
    expiring_soon: {
      label: "Expiring Soon",
      color: theme.palette.warning.main,
      info: "The account's plan will expire within the next 7 days.",
    },
    none: {
      label: "None",
      color: (theme.palette.grey as any)[500],
      info: "The account does not have a subscription plan assigned.",
    },
  };
};

export const SUBSCRIPION_KEYS = {
  ACTIVE_SUBSCRIPTIONS: "active_subscriptions",
  EXPIRED_SUBSCRIPTIONS: "expired_subscriptions",
  EXPIRING_SOON: "expiring_soon",
  NONE: "none",
};

export const planJson = [
  {
    id: "prod_QOk0lFHEzzYBPb",
    name: "Basic",
    description: null,
    active: true,
    price_id: "price_1PXwWpJpRJqS83lCSdaUyLUT",
    currency: "sgd",
    unit_amount: 0.0,
    billing_interval: "month",
    interval_count: 1,
  },
  {
    id: "prod_QOU92MBhXrI70c",
    name: "Pro Plan",
    description: "Pro Plan",
    active: true,
    price_id: "price_1PXhOBJpRJqS83lCTeuCYwBu",
    currency: "sgd",
    unit_amount: 100.0,
    billing_interval: "month",
    interval_count: 3,
  },
  {
    id: "prod_QOU92MBhXrI70c",
    name: "Pro Plan",
    description: "Pro Plan",
    active: true,
    price_id: "price_1PXhAxJpRJqS83lCxkuDzmRB",
    currency: "sgd",
    unit_amount: 300.0,
    billing_interval: "year",
    interval_count: 1,
  },
  {
    id: "prod_QOU92MBhXrI70c",
    name: "Pro Plan",
    description: "Pro Plan",
    active: true,
    price_id: "price_1PXhAxJpRJqS83lC7NeARZvS",
    currency: "sgd",
    unit_amount: 39.99,
    billing_interval: "month",
    interval_count: 1,
  },
  {
    id: "prod_QOSqcNmy4oNcRA",
    name: "Lite Plan",
    description: "Lite Plan",
    active: true,
    price_id: "price_1PXhBiJpRJqS83lCHfGkha8Z",
    currency: "sgd",
    unit_amount: 10.0,
    billing_interval: "month",
    interval_count: 1,
  },
  {
    id: "prod_QOSqcNmy4oNcRA",
    name: "Lite Plan",
    description: "Lite Plan",
    active: true,
    price_id: "price_1PXhBiJpRJqS83lC5SakrcBi",
    currency: "sgd",
    unit_amount: 100.0,
    billing_interval: "year",
    interval_count: 1,
  },
  {
    id: "prod_QOSqcNmy4oNcRA",
    name: "Lite Plan",
    description: "Lite Plan",
    active: true,
    price_id: "price_1PXfv9JpRJqS83lCyOegVJ3U",
    currency: "sgd",
    unit_amount: 30.0,
    billing_interval: "month",
    interval_count: 3,
  },
  {
    id: "prod_QDzFt1yFtzjvjR",
    name: "Lite Plan (Monthly Renewal)",
    description: null,
    active: false,
    price_id: "price_1PNXGpJpRJqS83lCyrbnaSjq",
    currency: "sgd",
    unit_amount: 10.0,
    billing_interval: "month",
    interval_count: 1,
  },
];

export const TUTORIALS = {
  DASHBOARD: "dashboard",
  COMPARE: "compare",
  OVERVIEW: "overview",
  COMPARE_WIDGET: "compare_widget",
};

export const ProColor = "#ffaa3b";
