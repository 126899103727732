import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import WordCloud from "react-d3-cloud";
import { scaleLinear } from "d3-scale";
import SpinnerComponent from "src/components/common/feedback/spinner";
import useMediaQueries from "src/hooks/use-mediaqueries";
import NoDataFound from "src/components/common/feedback/no-data-found";

export interface WordData {
  text: string;
  value: number;
}

interface IWordCloudChartProps {
  data: WordData[];
  height: number;
  width: number;
}

type Props = IWordCloudChartProps;

const WordCloudChart: React.FC<Props> = React.memo(function WordCloudChart({ data: incomingData, width, height }) {
  const { smDown, lgUp } = useMediaQueries();
  const [data, setData] = useState(undefined as unknown as WordData[]);

  useEffect(() => {
    setData(incomingData);
  }, [incomingData]);

  // @ts-ignore

  // Step 1: Find the minimum and maximum values
  const minDataValue = data && Math.min(...data.map(d => d.value));
  const maxDataValue = data && Math.max(...data.map(d => d.value));

  // Step 2: Define the minimum and maximum font sizes
  const minFontSize = 14; // Minimum font size
  const maxFontSize = 60; // Maximum font size

  // Step 3: Use the scaling function to map values to font sizes
  const fontSizeScale =
    data &&
    scaleLinear()
      .domain([minDataValue, maxDataValue]) // Input domain (your data range)
      .range([minFontSize, maxFontSize]); // Output range (font size range)

  // Now you can use fontSizeScale to calculate font sizes based on data values
  const fontSize = (d: any) => (data ? fontSizeScale(d.value) : Math.log(d.value) * 10);

  // const fontSize = d => Math.log(d.value) * 10;
  // const fontSize = d => Math.log2(d.value) * 20;
  // @ts-ignore

  if (incomingData === undefined) {
    return <div>Loading..</div>;
  }

  return (
    <>
      {data ? (
        data.length === 0 ? (
          <NoDataFound image />
        ) : (
          <WordCloud
            width={lgUp ? width + 50 : width + 100}
            height={lgUp ? 380 : smDown ? height - 20 : height - 60}
            data={data}
            fontSize={fontSize}
            font="Impact"
            padding={3}
            rotate={() => 0}
            // fontStyle="italic"
            // fontWeight="light"
            spiral="archimedean"
            // random={Math.random}

            // fill={(d: any, i: any) => schemeCategory10ScaleOrdinal(i)}
          />
        )
      ) : (
        <Box sx={{ height: `calc(${height}px - 100px)`, display: "grid", placeItems: "center" }}>
          <SpinnerComponent modifier={1.2} />
        </Box>
      )}
    </>
  );
});

export default WordCloudChart;
