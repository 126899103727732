import React from "react";
/* components */
import PercentDiff from "src/components/common/data-display/percent-diff";
/* 3rd party lib */
import { faCrown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Stack, TableCell, useTheme } from "@mui/material";
/* Util */
import { ICompareInstaProfile } from "src/types/instagram";
import { ICompareTikTokProfile } from "src/types/tiktok";
import { formatNumberWithSuffix } from "src/utils/general";
import { TDisplayMode } from "src/pages/comparisons/profiles/profiles";
import { largestNumColor } from "src/components/comparison/posts/table-row-cell";
import { useComparisonProfileContext } from "src/contexts/comparison-profile-context";

interface TableRowCellProps {
  className?: string;
  column:
    | {
        key: string;
        label: string;
        cellStyle: any;
        content: any;
      }
    | {
        key: string;
        label: string;
        cellStyle: any;
        content: () => string;
      }
    | {
        key: string;
        label: string;
        cellStyle: any;
        content?: undefined;
      };
  // profileObj: TInstaData | TTikTokData | null;
  profileObj?: ICompareInstaProfile | ICompareTikTokProfile | null;
  index: number;
  columnWidth?: string;
  largestNumDict: { [key: string]: number } | {};
  percentageDiff?: TDisplayMode;
}

type Props = TableRowCellProps;

const TableRowCell: React.FC<Props> = ({ className, columnWidth, percentageDiff, column, profileObj, index, largestNumDict }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  let largestNum = (largestNumDict as any)[column.key];

  const profileWithEngagement = (key: string) => {
    if (key === "median_engagement_rate") {
      return profileObj && profileObj.profile.engagement_stats[key];
    }

    return profileObj && profileObj.date_last[key];
  };

  let actualNum = profileWithEngagement(column.key);
  const { tableDirection } = useComparisonProfileContext();

  const stat = profileWithEngagement(column.key);

  if (column.content) {
    return (
      <TableCell sx={column.cellStyle} className={`${mode === "light" ? "tablecell" : "tablecell-dark"} text-center ${className}`}>
        {column.content(profileObj, index)}
      </TableCell>
    );
  } else {
    return (
      <TableCell
        sx={{
          ...column.cellStyle,
          position: "relative",
          width: columnWidth,
          fontWeight: largestNum === actualNum ? 600 : 500,
          color: largestNum === actualNum ? largestNumColor : theme.palette.text.secondary,
          minWidth: tableDirection === "horizontal" ? "60px" : "160px",
        }}
        className={`${mode === "light" ? "tablecell" : "tablecell-dark"} text-center ${className}`}
      >
        {/* original absolute crown */}
        {/* {largestNum === actualNum && (
          <Box
            sx={{
              position: "absolute",
              right: "8px",
              top: "50%",
              transform: "translateY(-50%)",
              "& svg": {
                color: "#ffd83b",
                // transform: "rotate(30deg)",
              },
            }}
          >
            <FontAwesomeIcon icon={faCrown} size="lg" />
          </Box>
        )} */}
        <Grid container>
          {tableDirection === "vertical" && (
            <Grid item xs={2}>
              {largestNum === actualNum ? (
                <Box
                  sx={{
                    "& svg": {
                      color: "#ffd83b",
                      // transform: "rotate(30deg)",
                    },
                  }}
                >
                  <FontAwesomeIcon icon={faCrown} size="lg" />
                </Box>
              ) : (
                <Box />
              )}
            </Grid>
          )}
          <Grid item xs={tableDirection === "horizontal" ? 12 : 8} alignItems="center" justifyContent={"center"} display="flex">
            <Stack spacing={0.5} direction="row" alignItems="center">
              {tableDirection === "horizontal" && (
                <>
                  {largestNum === actualNum ? (
                    <Box
                      sx={{
                        lineHeight: "14px",
                        "& svg": {
                          color: "#ffd83b",
                          // transform: "rotate(30deg)",
                        },
                      }}
                    >
                      <FontAwesomeIcon icon={faCrown} size={tableDirection === "horizontal" ? "sm" : "lg"} />
                    </Box>
                  ) : (
                    <Box />
                  )}
                </>
              )}
              <Box sx={{ fontSize: tableDirection === "horizontal" ? "14px" : "15px", lineHeight: "14px" }}>
                {/* 
            @TODO
              attempting to add total posts for tiktok in comparison
              {profileObj
                ? column.key === "count_post"
                  ? (profileObj as any)["category_name" in profileObj ? "count_post" : "count_video"] &&
                    formatNumberWithSuffix((profileObj as any)["category_name" in profileObj ? "count_post" : "count_video"])
                  : ((profileObj as any)[column.key] && formatNumberWithSuffix((profileObj as any)[column.key])) ?? ""
                : ""}

              */}

                {profileObj
                  ? (stat && typeof stat === "number" && `${formatNumberWithSuffix(stat)}${column.key === "median_engagement_rate" ? "%" : ""}`) ?? ""
                  : ""}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={tableDirection === "horizontal" ? 12 : 2}>
            <Stack direction="row" justifyContent={tableDirection === "horizontal" ? "center" : "flex-end"}>
              {percentageDiff && profileObj && (
                <PercentDiff
                  simpleDisplay
                  fontSize={11}
                  tableDirection={tableDirection}
                  displayMode={percentageDiff}
                  difference={profileObj.gained[`${column.key}_num`]}
                  percentage={profileObj.gained[`${column.key}_percentage`]}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </TableCell>
    );
  }
};

export default TableRowCell;
