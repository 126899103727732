/* components */
import DataDisplay from "src/components/common/inputs/data-display";
import OuterLayout from "src/components/common/layout/outer-layout";
import ProfileInformation from "src/pages/admin/profile-information";
import DataTableList from "src/components/common/data-display/data-table-list";
/* 3rd party lib */
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import useSWR from "swr";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FormikProps, useFormik } from "formik";
import { MRT_ColumnDef } from "material-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faInfoCircle, faXmark } from "@fortawesome/pro-solid-svg-icons";
/* Util */
import useHttp from "src/hooks/use-http";
import { TABLE_ID } from "src/utils/constants";
import useBackground from "src/hooks/use-background";
import { getTranslateString } from "src/utils/translate";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { IActivityLog, IStripeSubscription, TSubscriptionAccount, TUserProfile } from "src/types/common";
import { FORM_WARNING_PASSWORD_MUST_MATCH, FORM_WARNING_REQUIRED } from "src/constants/translate-keys/common";
import { showActionWhenHovered } from "src/components/common/data-display/dnd-table";
import { checkObjectNotEmpty } from "src/utils/general";
import PackageComponent from "src/components/common/data-display/package-component";
import SubscriptionLogDialog from "src/components/common/dialog/subscription-log-dialog";
import routes from "src/routes";
import { useLocation, useNavigate } from "react-router-dom";
type TViewProfile = "profile" | "credential" | "subscription" | "activity";

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  target: TViewProfile;
  theme: Theme;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, theme, target, ...other } = props;

  const background = useBackground({});

  return (
    <div
      role="tabpanel"
      hidden={value !== target}
      id={`simple-tabpanel-${target}`}
      aria-labelledby={`simple-tab-${target}`}
      {...other}
      style={{
        background: background,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {value === target && <Box sx={{ p: 1, width: "100%", minWidth: "300px" }}>{children}</Box>}
    </div>
  );
}

interface IFormValues {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

interface MyAccountProps {}

const schema = (t: any) =>
  Yup.object().shape({
    confirm_password: Yup.string()
      .required(getTranslateString(t, FORM_WARNING_REQUIRED))
      .oneOf([Yup.ref("new_password")], getTranslateString(t, FORM_WARNING_PASSWORD_MUST_MATCH)),
    new_password: Yup.string()
      .required(getTranslateString(t, FORM_WARNING_REQUIRED))
      .oneOf([Yup.ref("confirm_password")], getTranslateString(t, FORM_WARNING_PASSWORD_MUST_MATCH)),
  });

type Props = MyAccountProps;

const MyAccount: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const { t } = useTranslation();
  const { mdDown } = useMediaQueries();
  const location = useLocation();
  const navigate = useNavigate();
  const { clickedPricing } = location.state || {};
  const { http, apiEndpoint } = useHttp();
  const dataTableListRef = useRef<any>(null);
  const [view, setView] = useState<TViewProfile>("profile");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [autoSelected, setAutoSelected] = useState(true);
  const { data, mutate } = useSWR<TUserProfile>(apiEndpoint.USER_PROFILE);

  const handleChange = (event: any, nextView: TViewProfile) => {
    if (nextView) {
      setView(nextView);
      navigate(`${routes.MY_ACCOUNT}#${nextView}`);
    }
  };

  const handleChangePassword = async (values: IFormValues) => {
    try {
      await http.patch(apiEndpoint.USER_PROFILE_CHANGE_PASSWORD, JSON.stringify(values));
      toast.success("Password Updated Successfully");
      formik.resetForm();
    } catch (err) {
      console.error(err);
      formik.resetForm();
      toast.error("Something went wrong when reseting password");
    }
  };

  const handleTogglePasswordVisibility = (type: "new" | "old" | "confirm") => {
    switch (type) {
      case "old":
        setShowOldPassword(!showOldPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const formik = useFormik<IFormValues>({
    validateOnChange: false,
    validationSchema: schema(t),
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    onSubmit: values => {
      handleChangePassword(values);
    },
  });

  const activityLogColDefinition: MRT_ColumnDef<{}>[] = useMemo(
    () => [
      // {
      //   accessorKey: "no",
      //   header: "#",
      //   size: 0,
      //   muiTableHeadCellProps: {
      //     sx: {
      //       ...NUMBER_CELL_TRANSLATE,
      //     },
      //   },
      //   enablePinning: false,
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnFilter: false,
      //   enableColumnOrdering: false,
      //   enableColumnDragging: false,

      //   Cell: ({ row }) => {
      //     const { currentPage, rowsPerPage } = checkActivityLogPagination;
      //     return (
      //       <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
      //         {getDynamicIndex(currentPage - 1, rowsPerPage, row.index + 1)}.
      //       </Typography>
      //     );
      //   },
      // },
      {
        accessorKey: "type",
        header: "Type",
        size: 30,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        Cell: ({ row }) => {
          const rowData = row.original as IActivityLog;
          return (
            <Typography variant="body2" fontWeight={500} color={rowData.type === "add" ? "success.main" : "error.main"}>
              {rowData.type === "add" ? "Add" : "Remove"}
            </Typography>
          );
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        enableColumnFilter: false,

        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
      },
      {
        accessorKey: "created",
        header: "Created",
        enableColumnFilter: false,
        size: 80,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        Cell: ({ row }) => {
          const rowData = row.original as IActivityLog;
          return <Typography variant="body2">{moment(rowData.created).format("DD MMM YYYY (hh:mm A)")}</Typography>;
        },
      },
    ],
    []
  );

  const activityLogMetaData = {
    primary: {
      path: "id",
      content: (rowData: IActivityLog) => (
        <Typography variant="h6" fontWeight={500} color={rowData.type === "add" ? "success.main" : "error.main"}>
          {rowData.type === "add" ? "Add" : "Remove"} #{rowData.id}
        </Typography>
      ),
    },
    sortFields: [],
    fields: [
      {
        path: "created",
        label: "Created",
        content: (rowData: IActivityLog) =>
          rowData.created && (
            <Typography variant="body2" color="text.secondary">
              {moment(rowData.created).format("DD MMM YYYY (hh:mm A)")}
            </Typography>
          ),
      },
      {
        path: "description",
        label: "Description",
        content: (rowData: IActivityLog) => (
          <Typography variant="body2" color="text.secondary">
            {rowData.description}
          </Typography>
        ),
      },
    ],
  };

  // const paymentComponent = (
  //   <Paper elevation={10} sx={{ p: 2 }}>
  //     <Stack spacing={1}>
  //       <Typography variant="body2" color="text.secondary">
  //         Account active until
  //       </Typography>
  //       <Typography variant="h6" fontWeight={500} color="text.secondary">
  //         11/03/2024
  //       </Typography>

  //       <Box>
  //         <Button variant="contained">Next Payment</Button>
  //       </Box>
  //     </Stack>
  //   </Paper>
  // );

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (location.hash) {
      const viewTab = ["profile", "credential", "subscription", "activity"];

      const locationHash = location.hash.replace("#", "");

      if (!viewTab.includes(locationHash)) {
        setView("profile");
      } else {
        setView(locationHash as TViewProfile);
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (clickedPricing) {
      setView("subscription");
      // remove the clickedPricing state
      navigate(`${routes.MY_ACCOUNT}#subscription`, { replace: true, state: {} });
    }
  }, [navigate, clickedPricing]);
  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <OuterLayout maxWidth="lg">
        <Stack direction="row" alignItems="center" justifyContent={"space-between"} sx={{}}>
          <Typography textAlign="left" variant="h6" fontWeight={600} color="text.secondary" sx={{ my: 2 }}>
            My Account
          </Typography>

          <Button variant="contained" onClick={() => navigate(routes.ROOT)}>
            Go to Dashboard
          </Button>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Paper elevation={10} sx={{ py: 2 }}>
              <Stack spacing={2} alignItems="center" justifyContent={"space-between"} sx={{ py: 1, px: 2 }}>
                <Stack spacing={1} alignItems="center">
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      display: "grid",
                      placeItems: "center",
                      backgroundColor: (theme.palette.neutral as any)[200],
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} size="3x" color={(theme.palette.neutral as any)[600]} />
                  </Box>
                  <Box>
                    <Typography textAlign="left" variant="h6" fontWeight={600} color="text.secondary">
                      {data && data.name}
                    </Typography>
                  </Box>
                </Stack>

                {!mdDown && (
                  <Box sx={{ mt: 2, width: "100%" }}>
                    <ToggleButtonGroup color={"primary"} orientation="vertical" value={view} exclusive onChange={handleChange} sx={{ width: "100%" }}>
                      <ToggleButton
                        value="profile"
                        aria-label="profile"
                        sx={{
                          color: view === "profile" ? "#ffffff !important" : theme.palette.text.secondary,
                          background: view === "profile" ? `${theme.palette.primary.main} !important` : "none",
                        }}
                      >
                        <Typography variant="body2" textTransform={"capitalize"}>
                          Profile Information
                        </Typography>
                      </ToggleButton>
                      <ToggleButton
                        value="credential"
                        aria-label="credential"
                        sx={{
                          color: view === "credential" ? "#ffffff !important" : theme.palette.text.secondary,
                          background: view === "credential" ? `${theme.palette.primary.main} !important` : "none",
                        }}
                      >
                        <Typography variant="body2" textTransform={"capitalize"}>
                          Credentials
                        </Typography>
                      </ToggleButton>
                      <ToggleButton
                        value="subscription"
                        aria-label="subscription"
                        sx={{
                          color: view === "subscription" ? "#ffffff !important" : theme.palette.text.secondary,
                          background: view === "subscription" ? `${theme.palette.primary.main} !important` : "none",
                        }}
                      >
                        <Typography variant="body2" textTransform={"capitalize"}>
                          Subscription Plan
                        </Typography>
                      </ToggleButton>

                      <ToggleButton
                        value="activity"
                        aria-label="activity"
                        sx={{
                          color: view === "activity" ? "#ffffff !important" : theme.palette.text.secondary,
                          background: view === "activity" ? `${theme.palette.primary.main} !important` : "none",
                        }}
                      >
                        <Typography variant="body2" textTransform={"capitalize"}>
                          Activity Log
                        </Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                )}
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            {mdDown && <UserTab tab={view} handleChangeTab={handleChange} theme={theme} />}
            {mdDown ? (
              <>
                <CustomTabPanel theme={theme} value={view} target={"profile"}>
                  <ProfileInformation mutate={mutate as any} user_id={data?.user.toString()} data={data as TSubscriptionAccount} />
                </CustomTabPanel>
                <CustomTabPanel theme={theme} value={view} target={"credential"}>
                  <UserCredential
                    formik={formik}
                    showNewPassword={showNewPassword}
                    showOldPassword={showOldPassword}
                    showConfirmPassword={showConfirmPassword}
                    handleTogglePasswordVisibility={handleTogglePasswordVisibility}
                  />
                </CustomTabPanel>
                <CustomTabPanel theme={theme} value={view} target={"subscription"}>
                  <CurrentPlan data={data} autoSelected={autoSelected} setAutoSelected={setAutoSelected} theme={theme} />
                </CustomTabPanel>
                <CustomTabPanel theme={theme} value={view} target={"activity"}>
                  <ActivityLog
                    apiEndpoint={apiEndpoint}
                    dataTableListRef={dataTableListRef}
                    activityLogMetaData={activityLogMetaData}
                    activityLogColDefinition={activityLogColDefinition}
                  />
                </CustomTabPanel>
              </>
            ) : (
              <>
                {view === "profile" && (
                  <ProfileInformation mutate={mutate as any} user_id={data?.user.toString()} data={data as TSubscriptionAccount} />
                )}
                {view === "credential" && (
                  <UserCredential
                    formik={formik}
                    showNewPassword={showNewPassword}
                    showOldPassword={showOldPassword}
                    showConfirmPassword={showConfirmPassword}
                    handleTogglePasswordVisibility={handleTogglePasswordVisibility}
                  />
                )}

                {view === "subscription" && <CurrentPlan data={data} autoSelected={autoSelected} setAutoSelected={setAutoSelected} theme={theme} />}
                {view === "activity" && (
                  <ActivityLog
                    apiEndpoint={apiEndpoint}
                    dataTableListRef={dataTableListRef}
                    activityLogMetaData={activityLogMetaData}
                    activityLogColDefinition={activityLogColDefinition}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </OuterLayout>
    </>
  );
};

export default MyAccount;

export const FeatureLayout = ({ available, theme, label }: { available: boolean; theme: Theme; label: string }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box width="20px">
        {available ? (
          <FontAwesomeIcon size="xs" icon={faCircleCheck} color={theme.palette.grey[500]} />
        ) : (
          <FontAwesomeIcon icon={faXmark} color={theme.palette.error.main} />
        )}
      </Box>
      <Typography textAlign="left" variant="subtitle2" fontWeight={500} color={theme.palette.mode === "dark" ? "grey.300" : "grey.600"}>
        {label}
      </Typography>
    </Stack>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserTab = ({
  tab,
  handleChangeTab,
  theme,
}: {
  tab: TViewProfile;
  handleChangeTab: (event: React.SyntheticEvent, newValue: TViewProfile) => void;
  theme: Theme;
}) => {
  return (
    <Tabs variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChangeTab} aria-label="wrapped label tabs example" sx={{ pb: 1 }}>
      <Tab
        {...a11yProps(0)}
        value={"profile"}
        label={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
              Profile
            </Typography>
          </Stack>
        }
      />
      <Tab
        {...a11yProps(1)}
        value={"credential"}
        label={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
              Credentials
            </Typography>
          </Stack>
        }
      />
      <Tab
        {...a11yProps(1)}
        value={"subscription"}
        label={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
              Subscription Plan
            </Typography>
          </Stack>
        }
      />
      <Tab
        {...a11yProps(1)}
        value={"activity"}
        label={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
              Activity Log
            </Typography>
          </Stack>
        }
      />
    </Tabs>
  );
};

const UserCredential = ({
  formik,
  showNewPassword,
  showOldPassword,
  showConfirmPassword,
  handleTogglePasswordVisibility,
}: {
  formik: FormikProps<IFormValues>;
  showNewPassword: boolean;
  showOldPassword: boolean;
  showConfirmPassword: boolean;
  handleTogglePasswordVisibility: (type: "new" | "old" | "confirm") => void;
}) => {
  const { smDown } = useMediaQueries();
  return (
    <Paper elevation={10} sx={{ width: "100%" }}>
      <Box sx={{ p: smDown ? 2 : 4 }}>
        <Typography textAlign="left" variant="h5" fontWeight={500} color="primary.main" sx={{ mb: smDown ? 2 : 4 }}>
          User Credentials
        </Typography>
        <form id="change-password" onSubmit={formik.handleSubmit}>
          <Stack spacing={1}>
            <DataDisplay
              label={"Old Password"}
              variant={"body2"}
              value={
                <TextField
                  value={formik.values.old_password}
                  fullWidth
                  variant="outlined"
                  hiddenLabel
                  name="old_password"
                  size="small"
                  required
                  error={formik.errors.old_password ? true : false}
                  helperText={formik.errors.old_password}
                  onChange={formik.handleChange}
                  type={showOldPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: formik.values.old_password !== "" && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleTogglePasswordVisibility("old")} edge="end">
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
            <DataDisplay
              label={"New Password"}
              variant={"body2"}
              value={
                <TextField
                  value={formik.values.new_password}
                  fullWidth
                  variant="outlined"
                  hiddenLabel
                  name="new_password"
                  size="small"
                  required
                  error={formik.errors.new_password ? true : false}
                  helperText={formik.errors.new_password}
                  onChange={formik.handleChange}
                  type={showNewPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: formik.values.new_password !== "" && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleTogglePasswordVisibility("new")} edge="end">
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
            <DataDisplay
              label={"Confirm Password"}
              variant={"body2"}
              value={
                <TextField
                  value={formik.values.confirm_password}
                  fullWidth
                  variant="outlined"
                  hiddenLabel
                  name="confirm_password"
                  size="small"
                  required
                  error={formik.errors.confirm_password ? true : false}
                  helperText={formik.errors.confirm_password}
                  onChange={formik.handleChange}
                  type={showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: formik.values.confirm_password !== "" && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleTogglePasswordVisibility("confirm")} edge="end">
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
            <Box sx={{ pt: 1 }}>
              <Button form="change-password" type="submit" variant="contained">
                Reset Password
              </Button>
            </Box>
          </Stack>
        </form>
      </Box>
    </Paper>
  );
};

const CurrentPlan = ({
  theme,
  data,
}: // autoSelected,
// setAutoSelected,
{
  data?: TUserProfile;
  theme: Theme;
  autoSelected: boolean;
  setAutoSelected: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { mode } = theme.palette;
  const navigate = useNavigate();
  const { smDown } = useMediaQueries();
  const { apiEndpoint } = useHttp();
  const { data: subscriptionData } = useSWR<IStripeSubscription>(apiEndpoint.STRIPE_SUBSCRIPTION);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState<{ open: boolean; log: string | null }>({ open: false, log: null });

  const customPaperStyle = {
    background: (theme.palette.neutral as any)[mode === "dark" ? 800 : 100],
    borderRadius: "8px",
    border: `1px solid ${(theme.palette.neutral as any)[mode === "dark" ? 600 : 300]}`,
  };
  return (
    <>
      <Stack spacing={2}>
        <Paper elevation={10} sx={{ width: "100%" }}>
          <Box sx={{ p: smDown ? 2 : 4 }}>
            <Stack direction="row" alignItems="center" justifyContent={"space-between"} mb={smDown ? 2 : 4}>
              <Typography textAlign="left" variant="h5" fontWeight={500} color="primary.main">
                Subscription Plan
              </Typography>
            </Stack>

            <Stack spacing={2}>
              {subscriptionData ? (
                <Stack direction={smDown || (subscriptionData && !subscriptionData.show_portal) ? "column" : "row"} alignItems="center" spacing={2}>
                  <Stack spacing={1}>
                    {subscriptionData && (
                      <>
                        {subscriptionData.show_portal ? (
                          <Typography textAlign={"left"}>
                            You already have a subscription. You can&nbsp;
                            <Link href={subscriptionData.customer_portal_url} target="_blank" rel="noreferrer">
                              view/manage
                            </Link>
                            &nbsp;your existing subscription.
                          </Typography>
                        ) : (
                          <Typography textAlign={"left"}>
                            You currently do not have any subscription plan. Click the button below to subscribe for free.
                          </Typography>
                        )}
                      </>
                    )}

                    {subscriptionData && subscriptionData.subscription_messages && (
                      <Typography textAlign={"left"} variant="body2" fontStyle={"italic"} color="grey.500">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        &nbsp; {subscriptionData.subscription_messages}
                      </Typography>
                    )}
                  </Stack>
                  <Box>
                    {subscriptionData && (
                      <Button
                        variant="outlined"
                        sx={{ whiteSpace: "nowrap" }}
                        onClick={() => {
                          if (subscriptionData.show_portal) {
                            window.location.href = subscriptionData.customer_portal_url;
                            // window.open(subscriptionData.customer_portal_url);
                          } else {
                            navigate(routes.PRICING, { state: { subscriptionData } });
                          }
                        }}
                      >
                        {subscriptionData.show_portal ? "MANAGE SUBSCRIPTION" : "VIEW SUBSCRIPTION PLAN"}
                      </Button>
                    )}
                  </Box>
                </Stack>
              ) : (
                <Stack spacing={2}>
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                  <Skeleton variant="rectangular" width={"100%"} height={20} />
                </Stack>
              )}
              {data && checkObjectNotEmpty(data.subscription) && (
                <Stack sx={{ display: "flex", alignItems: "center", pt: 3 }}>
                  <Box sx={{ width: smDown ? "100%" : "450px" }}>
                    <PackageComponent
                      readOnly
                      data={data.subscription}
                      error={null}
                      customPaperStyle={customPaperStyle}
                      setShowSubscriptionDialog={setShowSubscriptionDialog}
                    />
                  </Box>
                </Stack>
              )}
            </Stack>
          </Box>
        </Paper>
      </Stack>
      <SubscriptionLogDialog
        log={showSubscriptionDialog.log}
        open={showSubscriptionDialog.open}
        onClose={() => {
          setShowSubscriptionDialog({ open: false, log: null });
        }}
      />
    </>
  );
};

const ActivityLog = ({
  apiEndpoint,
  dataTableListRef,
  activityLogMetaData,
  activityLogColDefinition,
}: {
  apiEndpoint: any;
  dataTableListRef: any;
  activityLogMetaData: any;
  activityLogColDefinition: MRT_ColumnDef<{}>[];
}) => {
  const { smDown } = useMediaQueries();
  return (
    <Paper elevation={10} sx={{ width: "100%" }}>
      <Box sx={{ p: smDown ? 2 : 4 }}>
        <Typography textAlign="left" variant="h5" fontWeight={500} color="primary.main" sx={{ mb: smDown ? 2 : 4 }}>
          Activity Log
        </Typography>

        <DataTableList
          mobilePaginationStickBottom={false}
          id={TABLE_ID.USER_ACTIVITY_LOG}
          columns={activityLogColDefinition}
          columnOrder={activityLogColDefinition.map(c => c.accessorKey)}
          listMetadata={activityLogMetaData}
          dataTableListRef={dataTableListRef}
          showGlobalFilter
          search={{
            key: "q",
            label: "Search",
          }}
          listItemStyle={{
            "&:nth-of-type(odd)": {
              backgroundColor: "#f4f4f4c0",
            },
          }}
          url={apiEndpoint.USER_PROFILE_ACTIVITY_LOG}
        />
      </Box>
    </Paper>
  );
};
