const neutral = {
  100: "#f0f8ff", // Very light blue
  200: "#ddebfa", // Light blue-gray
  300: "#cdddfc", // Soft blue-gray
  400: "#b7d5ff", // Medium blue-gray
  500: "#8ab8f7", // Slightly deeper blue-gray
  550: "#6c9de2", // Slightly deeper blue-gray
  600: "#5c7fc2", // Dark blue-gray
  700: "#4e6b9d", // Deeper blue
  800: "#354676", // Very dark blue
  900: "#1e2e53", // Near-black blue
};
const grey = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

const background = {
  default: "#F9FAFC",
  paper: "#FFFFFF",
};

const gradient = {
  main: "linear-gradient(to right, #0075C9 30%, #00B098 80%)",
};

const gradient_secondary = {
  main: "#00B098",
};
const merchant = {
  main: "#0ABAB5",
};

const divider = "#E6E8F0";

export const primary = {
  main: "#5683e5",
  light: "#c7e2fb",
  dark: "#4b5ebc",
  contrastText: "#FFFFFF",
};

// export const primary = {
//   main: "#248ae9",
//   light: "#C7EAFB",
//   dark: "#2480BE",
//   contrastText: "#FFFFFF",
// };

// const primary = {
//   main: "#2A363B",
//   light: "#545e62",
//   dark: "#1d2529",
//   contrastText: "#FFFFFF",
// };

export const secondary = {
  main: "#0ABAB5",
  light: "#E4F7F5",
  dark: "#067975",
  contrastText: "#FFFFFF",
};

export const success = {
  main: "#28A745",
  light: "#43C6B7",
  dark: "#0E8074",
  contrastText: "#FFFFFF",
};

export const info = {
  main: "#0D99FF",
  light: "#64B6F7",
  dark: "#0B79D0",
  contrastText: "#FFFFFF",
};

export const warning = {
  main: "#FFB020",
  light: "#FFBF4C",
  dark: "#B27B16",
  contrastText: "#FFFFFF",
};

export const error = {
  main: "#D14343",
  light: "#DA6868",
  dark: "#922E2E",
  contrastText: "#FFFFFF",
};

export const text = {
  primary: "#4D4D4F",
  secondary: "#65748B",
  disabled: "rgba(55, 65, 81, 0.48)",
};

const scroll = {
  "&::-webkit-scrollbar": {
    width: `8px`,
    height: `8px`,
  },
  "&::-webkit-scrollbar-thumb": {
    background: primary.main,
    borderRadius: "1rem",
  },

  "&::-webkit-scrollbar-thumb:hover": {
    background: primary.light,
  },
};

export const lightThemeOptions = {
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: "#FFFFFF",
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "subsection" },
          style: {
            border: `1px solid ${divider}`,
            "& .MuiCardHeader-root": {
              alignItems: "flex-start",
              padding: "16px 16px 0px",
            },
            "& .MuiCardHeader-title": {
              fontSize: 14,
              fontWeight: 700,
            },
            "& .MuiCardContent-root": {
              padding: 16,
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-root": {
            "& .MuiAvatar-root": {
              color: "#FFFFFF",
            },
          },
          "&.MuiChip-filledDefault": {
            backgroundColor: neutral[200],
            "& .MuiChip-deleteIcon": {
              color: neutral[400],
            },
          },
          "&.MuiChip-outlinedDefault": {
            "& .MuiChip-deleteIcon": {
              color: neutral[300],
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 0.5,
            color: text.secondary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: error.main,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        background: "white",
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: divider,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[500],
        },
        track: {
          backgroundColor: neutral[400],
          opacity: 1,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: `${"white"} !important`, // Change the background color
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${divider}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // color: "white",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[100],
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          background: neutral[100],
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: neutral[400],
            borderRadius: "1rem",
          },

          "&::-webkit-scrollbar-thumb:hover": {
            background: neutral[300],
          },
        },
      },
    },
  },
  palette: {
    action: {
      active: neutral[500],
      focus: "rgba(55, 65, 81, 0.12)",
      hover: "rgba(55, 65, 81, 0.04)",
      selected: "rgba(55, 65, 81, 0.08)",
      disabledBackground: "rgba(55, 65, 81, 0.12)",
      disabled: "rgba(55, 65, 81, 0.26)",
    },
    background,
    divider,
    error,
    info,
    mode: "light",
    gradient,
    gradient_secondary,
    merchant,
    neutral,
    grey,
    primary,
    secondary,
    success,
    text,
    warning,
  },
  custom: {
    scroll,
  },
  shadows: [
    "none",
    "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
    "0px 1px 2px rgba(100, 116, 139, 0.12)",
    "0px 1px 4px rgba(100, 116, 139, 0.12)",
    "0px 1px 5px rgba(100, 116, 139, 0.12)",
    "0px 1px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 6px rgba(100, 116, 139, 0.12)",
    "0px 3px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
    "0px 5px 12px rgba(100, 116, 139, 0.12)",
    "0px 5px 14px rgba(100, 116, 139, 0.12)",
    "0px 5px 15px rgba(100, 116, 139, 0.12)",
    "0px 6px 15px rgba(100, 116, 139, 0.12)",
    "0px 7px 15px rgba(100, 116, 139, 0.12)",
    "0px 8px 15px rgba(100, 116, 139, 0.12)",
    "0px 9px 15px rgba(100, 116, 139, 0.12)",
    "0px 10px 15px rgba(100, 116, 139, 0.12)",
    "0px 12px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 13px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 14px 24px -8px rgba(100, 116, 139, 0.25)",
    "0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
  ],
};
