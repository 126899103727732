import { Accordion, AccordionDetails, AccordionSummary, Box, darken, /*  Grid, */ Paper, Stack, Typography, useTheme } from "@mui/material";
import React /* useState */ from "react";
import OuterLayout from "src/components/common/layout/outer-layout";
// import useMediaQueries from "src/hooks/use-mediaqueries";
// import InfoTab, { CustomTabPanel, TInfoTab } from "src/pages/info/info-tab";
// import InfoToggleGroup from "src/pages/info/info-toggle-group";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GoToTopButton from "src/components/common/inputs/go-to-top-button";

interface InfoFaqProps {}

type Props = InfoFaqProps;

const InfoFaq: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  // const { mdDown } = useMediaQueries();
  // const [view, setView] = useState<TInfoTab>("faq");

  const faqContent = [
    {
      title: "What is goIntec?",
      content: (
        <>
          <Stack spacing={2}>
            <Typography>
              goIntec is a social media analytics tool providing useful valuable insights on the growth of your own brand and competitors' social
              media accounts.
            </Typography>
            <Typography>
              Built for busy marketers and key opinion leaders alike, discover competitor monitoring done right with this efficient tool that tracks
              performances on Instagram and TikTok.
            </Typography>
          </Stack>

          {/* <Stack spacing={2}>
          <Typography>
            goIntec is a social media analytics and intelligence tool that provides actionable data and insights for social marketers and brand
            owners.
          </Typography>
          <Typography>
            In just a couple of clicks, discover what are specific topics and hashtags trending in your industry, determine the demographics of your
            competitors' followers, and see what are their' top-performing posts at a glance.
          </Typography>
          <ul>
            <li>
              <Typography>
                Our strong filtering options allow deeper and more meaningful analysis according to your own Key Performance Indicators.
              </Typography>
            </li>
            <li>
              <Typography>Our report generation tool offers quick insights into effectiveness of brands’ content strategies.</Typography>
            </li>
            <li>
              <Typography>
                Our side-by-side comparison allows you to develop a longer-term picture of your competitors’ strategies beyond the now.
              </Typography>
            </li>
          </ul>
          <Typography>
            In a nutshell, goIntec helps you master competitor analysis to strengthen your understanding of what resonates with your target audience
            and works for your brand, so you can better craft and fine-tune your own social strategy for success!
          </Typography>
        </Stack> */}
        </>
      ),
    },
    {
      title: "How can goIntec benefit my business?",
      content: (
        <Stack spacing={2}>
          <Typography>goIntec allows an intuitive comparison between social profiles and campaigns/posts that are of interest to you.</Typography>
          <Typography>
            By keeping an eye on direct/indirect competitors and industry leaders, you can fine-tune your content strategy and ride on potential
            trends to grow more followers, build greater brand loyalty & awareness, and help unlock customer revenue!
          </Typography>
        </Stack>
      ),
    },
    {
      title: "What pricing plans do you offer?",
      content: "We offer two main plans: Lite and Pro, each available on a monthly or yearly basis.",
    },
    {
      title: "Is there a trial plan?",
      content:
        "Yes, you can try the Pro plan features for 21 days. After the trial period ends, access will be deactivated until you sign up for a plan.",
    },

    {
      title: "How long does a plan last?",
      content: "A standard plan lasts for 30 days, but you can also choose a yearly plan for better value.",
    },
    {
      title: "Which social media platforms are goIntec tracking?",
      content: "Currently, goIntec is actively tracking data from both Instagram and TikTok.",
    },
    {
      title: "How does goIntec get its data?",
      content: "goIntec gets its data from social media platforms like Instagram and TikTok via their public api.",
    },
    {
      title: "How often does goIntec update the statistics?",
      content: "Actively tracked account statistics are updated every 24 hours.",
    },
    {
      title: "How far back in time can I see the statistics?",
      content:
        "Depending on your plan, you can access your statistics ranging from the past 30 days to the maximum amount of data available in our database.",
    },

    {
      title: "How do I upgrade to a higher tier plan?",
      content:
        "You may upgrade your plan at any time by going to the My Account page and selecting a different plan under Subscription Plan > Manage Subscription.",
    },

    {
      title: "Why do the statistics differ from the analytics page of my social media account?",
      content:
        "Since we collect data from these platforms' public APIs, we do not have access to the complete historical data of any social media accounts. Instead, we aggregate and analyze data daily, presenting it in a more user-friendly and useful format for users.",
    },
    {
      title: "Why am I not seeing any historical data after the account is tracked?",
      content: "The account might be new to our database. Historical data will start appearing after 24 hours of tracking.",
    },
    {
      title: "What is Profile Swap?",
      content:
        "Typically, tracking new profiles would require review and approval first, but to make the process smoother and safer, we've introduced Profile Swap. Profile Swap allows you to switch profiles a certain number of times within your allocated slots without needing prior approval. This feature helps prevent misuse by bad actors and ensures fair usage for all users across the board.",
    },
    {
      title: "How do profile slots and swaps work?",
      content:
        "Simply put, if you have 4 slots available, you're able to add up to 4 social profiles across the two available platforms. You can fill your slots by adding ➕ profiles of interest from your dashboard. Profile swaps allow you to free up your slot by🗑️switching out existing profiles from your dashboard, and adding➕ a new one in its place. This can be done from the actions available in the existing profile,  using the 🗑️ little bin icon. ",
    },
  ];

  const content = (
    <Paper elevation={10} sx={{ width: "100%" }}>
      <Box sx={{ p: 4 }}>
        <Stack direction="row" alignItems="center" justifyContent={"space-between"} mb={4}>
          <Typography textAlign="left" variant="h4" fontWeight={500} color="primary.main">
            FREQUENTLY ASKED QUESTIONS
          </Typography>
        </Stack>

        <Paper sx={{ p: 1, background: (theme.palette.neutral as any)[theme.palette.mode === "light" ? 100 : 800] }}>
          {faqContent.map((child, index) => (
            <Accordion key={child.title}>
              <AccordionSummary
                sx={{ color: theme.palette.primary.main }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant="h6" color="primary">
                  {child.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: theme.palette.mode === "light" ? "white" : darken((theme.palette.neutral as any)[800], 0.2),
                  borderTop: "1px solid #e7e7e7",
                  textAlign: "left",
                  py: 4,
                }}
              >
                {child.content}
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
      </Box>
    </Paper>
  );
  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <OuterLayout maxWidth={"lg"}>
      <GoToTopButton />

      {content}

      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={2.5}>
          {!mdDown && (
            <Paper elevation={10} sx={{ py: 2 }}>
              <Stack spacing={2} alignItems="center" justifyContent={"space-between"} sx={{ py: 1, px: 2 }}>
                <Box sx={{ mt: 2, width: "100%" }}>
                  <InfoToggleGroup view={view} setView={setView} />
                </Box>
              </Stack>
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={9.5}>
          {mdDown && <InfoTab tab={view} />}
          {mdDown ? (
            <>
              <CustomTabPanel theme={theme} value={view} target={"faq"}>
                {content}
              </CustomTabPanel>
            </>
          ) : (
            <>{view === "faq" && content}</>
          )}
        </Grid>
      </Grid> */}
    </OuterLayout>
  );
};

export default InfoFaq;
