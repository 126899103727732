import React from "react";
/* components */
import Insta from "src/images/insta.png";
import { TInstaData } from "src/types/instagram";
/* 3rd party lib */
import useSWR from "swr";
import useHttp from "src/hooks/use-http";
import { useNavigate, useParams } from "react-router-dom";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { Box, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
/* Util */
interface ProfileHashtagBannerProps {}

type Props = ProfileHashtagBannerProps;

const ProfileHashtagBanner: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const params = useParams();
  const theme = useTheme();
  const { mode } = theme.palette;
  const navigate = useNavigate();
  const { apiEndpoint } = useHttp();
  const { smDown } = useMediaQueries();
  const { username, hashtag } = params;
  const { data } = useSWR<TInstaData>(params && [apiEndpoint.INSTAGRAM_PROFILE(username)]);
  const { data: hashtagData } = useSWR(params && [`${apiEndpoint.INSTAGRAM_PROFILE_POSTS(username)}?hashtags=${hashtag}`]);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Paper elevation={5} sx={{ width: "100%", overflow: "hidden" }}>
      <Grid container>
        <Grid item xs={1.5} sm={0.8}>
          <Stack
            sx={{
              mr: smDown ? 0 : 2,
              cursor: "pointer",
              height: "100%",
              display: "grid",
              placeItems: "center",
              transition: "all 0.5s ease",
              "&:hover": {
                background: mode === "light" ? (theme.palette as any).neutral[200] : (theme.palette as any).neutral[700],
              },
            }}
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Stack>
        </Grid>
        <Grid item xs={10.5} sm={11.2}>
          <Stack direction={"row"} alignItems="center" py={2} spacing={2}>
            <Stack width={smDown ? "auto" : "50%"} alignItems="flex-start">
              <Typography variant={smDown ? "h5" : "h4"} color="text.secondary">
                #{params.hashtag}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box sx={{ position: "relative", width: "50px", height: "50px" }}>
                  <Box sx={{ borderRadius: "50%", overflow: "hidden", border: "1px solid #dcdcdc", width: "50px", height: "50px" }}>
                    <img src={data?.downloaded_image} alt={data?.username} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -3,
                      right: -3,
                      width: "12px",
                      height: "12px",
                      background: "white",
                      border: "1px solid #dcdcdc",
                      borderRadius: "50%",
                      padding: 0.5,
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <img src={Insta} alt="insta" width="100%" height="100%" />
                  </Box>
                </Box>

                <Stack sx={{ textAlign: "left" }}>
                  <Typography variant={smDown ? "body2" : "h6"} fontWeight={500} color="text.secondary">
                    {data ? data.display_name : "-"}
                  </Typography>
                  {data ? (
                    <Typography variant={smDown ? "caption" : "body2"} fontWeight={300} color="text.secondary">
                      @{data.username} {data.category_name ? ` | ${data.category_name}` : null}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Stack>
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent={smDown ? "center" : "flex-end"} sx={{ width: "50%", pr: smDown ? 0 : 5 }}>
              <Stack>
                <Typography variant="body1">Total Posts</Typography>
                <Typography variant={smDown ? "h3" : "h1"} fontWeight={600}>
                  {hashtagData ? hashtagData.count : "-"}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProfileHashtagBanner;
