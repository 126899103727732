import React, { useCallback, useEffect } from "react";
// import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { AuthRootState, initialize as authInit, IUserProfile, login as authLogin, logout as authLogout } from "src/slices/auth";
import useHttp from "src/hooks/use-http";
import toast from "react-hot-toast";
import { getToastSuccess } from "src/utils/translate";
import { BUTTON_LOGIN, TOAST_SUCCESS_ACTION_SUCCESSFULLY } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { RootState } from "src/store/root-reducer";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
import { SUBSCRIPION_KEYS } from "src/utils/constants";
import axios from "axios";
import { googleLogout } from "@react-oauth/google";

export type AuthContextContent = {
  auth: AuthRootState;
  logout: () => void;
  login: (email: string, password: string) => void;
  googleLogin: (access: string, refresh: string) => void;
};

export const AuthContext = React.createContext<AuthContextContent>({} as AuthContextContent);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  const { http, apiEndpoint } = useHttp();
  // const router = useRouter();

  const getCurrentUser = useCallback(async () => {
    const { data: user } = await http.get(apiEndpoint.USER_PROFILE);

    // add new attributes, isAdmin and expired (subscription expiry)
    let tempUser: IUserProfile = { ...user, isAdmin: false, expired: false };

    if (user && user.user_groups.includes("2ezasia_admin")) {
      tempUser.isAdmin = true;
    }
    if (
      user &&
      user.subscription &&
      (user.subscription.subscription_status === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS || !("subscription_status" in user.subscription))
    ) {
      tempUser.expired = true;
    }

    return tempUser;
  }, [http, apiEndpoint]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = globalThis.localStorage.getItem("accessToken");
        if (accessToken) {
          http.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

          const user = await getCurrentUser();

          // const permissions = await getPermissions();
          // const merchants = await getAllMerchants();
          const permissions = null;
          const configs = null;
          // const configs = await getConfigs();

          dispatch(authInit({ isAuthenticated: true, user, permissions, configs }));
        } else {
          dispatch(
            authInit({
              isAuthenticated: false,
              user: null,
              permissions: null,
              configs: null,
            })
          );
        }
      } catch (error) {
        console.error(error);
        dispatch(authLogout());
      }
    };

    // if (auth.configs === undefined || auth.configs === null) {
    //   initialize();
    // }
    if (auth.user === undefined || auth.user === null) {
      initialize();
    }
  }, [navigate, http.defaults.headers.common, getCurrentUser, dispatch, auth.user]);

  // const getPermissions = async () => {
  //   const result: { [key: string]: boolean } = {};
  //   const { data } = await http.get(apiEndpoint.PERMISSIONS);

  //   result["OVERVIEW"] = true;
  //   result["ACCOUNT"] = true;

  //   Object.keys(permissions).forEach(key => {
  //     result[key] = data[(permissions as any)[key]];
  //   });

  //   return result;
  // };

  // const getConfigs = async () => {
  //   const { data } = await http.get(apiEndpoint.CONFIGS);
  //   return data;
  // };

  const googleLogin = async (access: string, refresh: string) => {
    try {
      // Store tokens in localStorage
      localStorage.setItem("accessToken", access);
      localStorage.setItem("refreshToken", refresh);

      // Set default headers for http
      http.defaults.headers.common["Authorization"] = `Bearer ${access}`;

      // Get permissions if needed
      const permissions = null; // Or await getPermissions();

      // Get the current user
      const user = await getCurrentUser();
      // Dispatch authentication action

      dispatch(authLogin({ user, permissions }));

      // Show success message if the user's email is verified
      if (user.email_verified) {
        toast.success(getToastSuccess(t, TOAST_SUCCESS_ACTION_SUCCESSFULLY, BUTTON_LOGIN, "", false, false));
      }
    } catch (error: any) {
      console.error(error); // Log the full error for debugging
      if (error.response && error.response.data) {
        if (error.response.data.non_field_errors) {
          toast.error(error.response.data.non_field_errors[0]);
        } else {
          toast.error(error.response.data.detail);
        }
      }
    }
  };

  const login = async (email: string, password: string) => {
    try {
      // Make the POST request to log in
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiEndpoint.TOKEN.substring(1)}`, { email, password });

      // Store tokens in localStorage
      localStorage.setItem("accessToken", response.data.access);
      localStorage.setItem("refreshToken", response.data.refresh);

      // Set default headers for http
      http.defaults.headers.common["Authorization"] = `Bearer ${response.data.access}`;

      // Get permissions if needed
      const permissions = null; // Or await getPermissions();

      // Get the current user
      const user = await getCurrentUser();
      // Dispatch authentication action

      dispatch(authLogin({ user, permissions }));

      // Show success message if the user's email is verified
      if (user.email_verified) {
        toast.success(getToastSuccess(t, TOAST_SUCCESS_ACTION_SUCCESSFULLY, BUTTON_LOGIN, "", false, false));
      }
    } catch (error: any) {
      console.error(error); // Log the full error for debugging
      if (error.response && error.response.data) {
        if (error.response.data.non_field_errors) {
          toast.error(error.response.data.non_field_errors[0]);
        } else {
          toast.error(error.response.data.detail);
        }
      }
    }
  };

  const logout = () => {
    googleLogout();

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("remember_me");

    // clear all cookies

    document.cookie.split(";").forEach(cookie => {
      const name = cookie.split("=")[0].trim();
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    });

    // Removes default Authorization in request header
    delete http.defaults.headers.common["Authorization"];

    navigate(routes.LOGIN);

    dispatch(authLogout());
  };

  return <AuthContext.Provider value={{ auth, login, logout, googleLogin }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const AuthConsumer: any = AuthContext.Consumer;
