import React from "react";
/* components */
/* 3rd party lib */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faPercent } from "@fortawesome/pro-regular-svg-icons";
import { Stack, ToggleButton, ToggleButtonGroup, Tooltip, useTheme } from "@mui/material";
/* Util */
import { TDisplayMode, TTableDirection } from "src/pages/comparisons/profiles/profiles";
import { faGripHorizontal, faGripVertical } from "@fortawesome/pro-solid-svg-icons";

interface ToggleComponentProps {
  profileOrPosts: string[];
  displayMode: TDisplayMode;
  tableDirection: TTableDirection;
  setTableDirection: React.Dispatch<React.SetStateAction<TTableDirection>>;
  setDisplayMode: React.Dispatch<React.SetStateAction<TDisplayMode>>;
}

type Props = ToggleComponentProps;

const ToggleComponent: React.FC<Props> = ({ profileOrPosts, displayMode, tableDirection, setTableDirection, setDisplayMode }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <ToggleButtonGroup
        className="active-step2"
        color="primary"
        value={tableDirection}
        exclusive
        onChange={(_e, value) => {
          if (value) {
            setTableDirection(value);
          }
        }}
        aria-label="Platform"
        sx={{
          "& .Mui-selected": {
            background: `${theme.palette.primary.main} !important`,
            color: "white !important",
            "&:hover": {
              color: "white !important",
              background: `${theme.palette.primary.main} !important`,
            },
          },
        }}
      >
        <ToggleButton value="vertical">
          <Tooltip title="Vertical View" arrow>
            <FontAwesomeIcon icon={faGripVertical} />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="horizontal">
          <Tooltip title="Horizontal View" arrow>
            <FontAwesomeIcon icon={faGripHorizontal} />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        color="primary"
        value={displayMode}
        exclusive
        className="active-step3"
        onChange={(_e, value) => {
          setDisplayMode(value);
        }}
        aria-label="Platform"
        sx={{
          "& .Mui-selected": {
            background: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              color: "white",
              background: theme.palette.primary.dark,
            },
          },
        }}
      >
        <ToggleButton value="percentage">
          <Tooltip title="Difference in percentage" arrow>
            <FontAwesomeIcon icon={faPercent} />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="number">
          <Tooltip title="Difference in number" arrow>
            <FontAwesomeIcon icon={faHashtag} />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default ToggleComponent;
