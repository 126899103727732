import { createSlice } from "@reduxjs/toolkit";

export interface LayoutRootState {
  navbar: { title: string; height: number; show: boolean; pageHeaderHeight: number };
  sidebar: { open: boolean };
  viewMode: "table" | "grid";
}

const initialState: LayoutRootState = {
  navbar: { title: "", height: 0, show: true, pageHeaderHeight: 0 },
  sidebar: { open: false },
  viewMode: "table",
};

const slice = createSlice({
  name: "layout",
  initialState: initialState,
  reducers: {
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    toggleSidebar: state => {
      state.sidebar.open = !state.sidebar.open;
    },
    closeSidebar: state => {
      state.sidebar.open = false;
    },
    openSidebar: state => {
      state.sidebar.open = true;
    },

    showNavbar: (state, action) => {
      state.navbar.show = action.payload;
    },
    setNavbarHeight: (state, action) => {
      state.navbar.height = action.payload;
    },
    setPageHeaderHeight: (state, action) => {
      state.navbar.pageHeaderHeight = action.payload;
    },
  },
});

export default slice.reducer;

export const { setViewMode, openSidebar, toggleSidebar, closeSidebar, showNavbar, setNavbarHeight, setPageHeaderHeight } = slice.actions;
