import React, { useRef, useState, useEffect, useCallback } from "react";
import { Stack, IconButton, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";

interface ArrowOverflowBoxProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  justifyContent?: string;
}

type Props = ArrowOverflowBoxProps;

const ArrowOverflowBox: React.FC<Props> = ({ children, fullWidth = false, justifyContent = "flex-end" }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const threshold = 10; // Threshold value for boundary checks

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const scrollContainer = (direction: "left" | "right") => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -100 : 100;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (containerRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - containerRef.current.offsetLeft);
      setScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  /**
   * Handles the mouse move event for the container.
   *
   * @param {React.MouseEvent} e - The mouse move event.
   * @return {void}
   */
  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    if (containerRef.current) {
      const x = e.pageX - containerRef.current.offsetLeft;
      const walk = (x - startX) * 1; // Adjust the scroll speed multiplier as needed
      const newScrollLeft = scrollLeft - walk;
      containerRef.current.scrollLeft = Math.max(0, Math.min(newScrollLeft, containerRef.current.scrollWidth - containerRef.current.clientWidth));
    }
  };

  /**
   * Handles the touch start event for the container.
   *
   * @param {React.TouchEvent} e - The touch start event.
   * @return {void}
   */
  const handleTouchStart = (e: React.TouchEvent) => {
    if (containerRef.current) {
      setIsDragging(true);
      setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
      setScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging) return;
    if (containerRef.current) {
      const x = e.touches[0].pageX - containerRef.current.offsetLeft;
      const walk = (x - startX) * 1; // Adjust the scroll speed multiplier as needed
      const newScrollLeft = scrollLeft - walk;
      containerRef.current.scrollLeft = Math.max(0, Math.min(newScrollLeft, containerRef.current.scrollWidth - containerRef.current.clientWidth));
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const updateArrows = useCallback(() => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setShowLeftArrow(scrollLeft > threshold);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth - threshold);
    }
  }, [threshold]);

  useEffect(() => {
    updateArrows();
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", updateArrows);
      return () => {
        container.removeEventListener("scroll", updateArrows);
      };
    }
  }, [updateArrows]);

  useEffect(() => {
    /**
     * Handles the resize event and updates the arrows.
     *
     * @return {void} No return value.
     */
    const handleResize = () => {
      updateArrows();
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateArrows]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Stack width="100%" direction="row" alignItems="center" justifyContent={justifyContent} spacing={1}>
      {showLeftArrow && (
        <IconButton onClick={() => scrollContainer("left")}>
          <FontAwesomeIcon icon={faAngleLeft} color={theme.palette.primary.main} />
        </IconButton>
      )}
      <div
        ref={containerRef}
        style={{
          width: fullWidth ? "100%" : "auto",
          display: "flex",
          overflow: "hidden",
          whiteSpace: "nowrap",
          cursor: isDragging ? "grabbing" : "grab",
        }}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {children}
      </div>
      {showRightArrow && (
        <IconButton onClick={() => scrollContainer("right")}>
          <FontAwesomeIcon icon={faAngleRight} color={theme.palette.primary.main} />
        </IconButton>
      )}
    </Stack>
  );
};

export default ArrowOverflowBox;
