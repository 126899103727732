import { Box, Stack, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { NO_DATA_FOUND } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { getTranslateString } from "src/utils/translate";
import DataNotFoundImage from "src/images/DataNotFound.png";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface NoDataFoundProps {
  image?: boolean;
  height?: string;
  width?: string;
  style?: { [key: string]: string };
}

type Props = NoDataFoundProps;

const NoDataFound: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const { image = false, width, height = smDown ? "150px" : "200px", style } = props;

  return (
    <Stack spacing={2} sx={{ height: "100%", width: "100%", ...style }} alignItems="center" justifyContent={"center"}>
      {image && (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: height, position: "relative" }}>
          <img alt="nodata" src={DataNotFoundImage} height={height ?? "100%"} width={width ?? "auto"} />
        </Box>
      )}
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ color: "text.secondary" }}>
        <ErrorOutlineIcon fontSize={"small"} />
        <Typography variant={"subtitle1"}>{getTranslateString(t, NO_DATA_FOUND)}</Typography>
      </Stack>
    </Stack>
  );
};

export default NoDataFound;
