/* components */
import TikTokProfileListing from "src/pages/listing/tiktok/tiktok-profile-listing";
import InstaProfileListing from "src/pages/listing/instagram/insta-profile-listing";
import OuterLayout from "src/components/common/layout/outer-layout";
import ViewModeToggle from "src/components/common/inputs/view-mode-toggle";
import SocialMediaDropdown from "src/components/common/inputs/social-media-dropdown";
/* 3rd party lib */
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
/* Util */
import { setMuiTab } from "src/slices/general";
import { RootState } from "src/store/root-reducer";
import { PROFILE_TAB_PANEL } from "src/utils/constants";
import ExpiredOverlay from "src/components/common/data-display/expired-overlay";

interface FavouritesProps {}

type Props = FavouritesProps;

const Favourites: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const dispatch = useDispatch();
  const dataTableListRef = useRef<any | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { socialMedia } = useSelector((state: RootState) => state.general);
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    dispatch(setMuiTab(PROFILE_TAB_PANEL.OVERVIEW));
  }, [dispatch]);

  /* ================================================== */
  /* ================================================== */
  return (
    <OuterLayout maxWidth={"xl"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {/* <Paper elevation={5} sx={{ py: 0.8, px: 1 }}> */}
            <SocialMediaDropdown
              inputStyle={{
                "& .MuiOutlinedInput-input": {
                  py: "14px !important",
                  boxShadow: "1px 1px 7px 0.1px #0000000e",
                },
              }}
            />
            {/* </Paper> */}

            <Paper elevation={5} sx={{ width: "100%" }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} px={2}>
                <Stack direction="row" py={1.7} alignItems="center" spacing={1} px={2}>
                  <FontAwesomeIcon icon={faStar} size="xl" style={{ color: theme.palette.primary.main }} />
                  <Typography aria-labelledby="home__title" variant="h6" fontWeight="normal" color="text.secondary">
                    Favourites
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {/* {socialMedia && <SocialMediaDropdown />} */}
                  <ViewModeToggle height={38} />
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ position: "relative" }}>
            {user && user.expired && <ExpiredOverlay />}
            {socialMedia === "instagram" && (
              <InstaProfileListing
                search=""
                favourite
                showGlobalFilter
                id="favourite_page"
                dataTableListRef={dataTableListRef}
                columnVisibility={{ action: false }}
              />
            )}
            {socialMedia === "tiktok" && (
              <TikTokProfileListing
                id="favourite_page"
                columnVisibility={{ action: false }}
                showGlobalFilter
                favourite
                dataTableListRef={dataTableListRef}
                search=""
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </OuterLayout>
  );
};

export default Favourites;
