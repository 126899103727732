/* components */
import TikTokProfileListing from "src/pages/listing/tiktok/tiktok-profile-listing";
import InstaProfileListing from "src/pages/listing/instagram/insta-profile-listing";
import DelaySubmitInput from "src/components/common/inputs/delay-submit-input";
/* 3rd party lib */
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
/* Util */
import Insta from "src/images/insta.png";
import TikTok from "src/images/darkModeTikTok.png";
import { RootState } from "src/store/root-reducer";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { IProfileAvatarGroup, TSocialMedia, TUserSlot } from "src/types/common";
import ComparisonAvatarGroup from "src/components/comparison/comparison-avatar-group";
import { useComparisonProfileContext } from "src/contexts/comparison-profile-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faLeft } from "@fortawesome/pro-solid-svg-icons";
import DataDisplay from "../inputs/data-display";
import { useFormik } from "formik";
import ComparisonAddAccount from "src/components/comparison/profiles/comparison-add-account";
import useHttp from "src/hooks/use-http";
import useSWR from "swr";

interface IFormValues {
  name: string;
}

interface ProfileDialoMultiSelectProps {
  open: boolean;
  onClose: () => void;
  handleSavePreset: (name: string, createPresetWithProfiles: boolean, avatarGroups?: string[]) => Promise<void>;
  onSelectProfiles?: (profiles: IProfileAvatarGroup[]) => void;
}

type Props = ProfileDialoMultiSelectProps;

const ProfileMultiSelectDialog: React.FC<Props> = ({ open, onClose, onSelectProfiles, handleSavePreset }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { apiEndpoint } = useHttp();
  const { smDown } = useMediaQueries();
  const [search, setSearch] = useState("");
  const [nextStep, setNextStep] = useState(false);
  const instaProfileGridRef = useRef<any | null>(null);
  const tiktokProfileGridRef = useRef<any | null>(null);
  const nameFieldRef = useRef<HTMLDivElement | null>(null);
  const { currentActive } = useComparisonProfileContext();
  const { socialMedia } = useSelector((state: RootState) => state.general);
  const { profileAvatarGroup } = useSelector((state: RootState) => state.compare);
  const [compareAddAccountDialog, setCompareAddAccountDialog] = useState<{ open: boolean; socialMedia: TSocialMedia }>({
    open: false,
    socialMedia: "instagram",
  });
  const { data: userSlotData } = useSWR<TUserSlot>([apiEndpoint.USER_SLOT]);

  const handleResetAndCloseForm = (resetForm: any) => {
    resetForm();
    onClose();
    setNextStep(false);
  };

  const formik = useFormik<IFormValues>({
    validateOnChange: false,
    initialValues: {
      name: "",
    },
    onSubmit: values => {
      if (profileAvatarGroup && onSelectProfiles) {
        onSelectProfiles(profileAvatarGroup);
        handleSavePreset(
          values.name,
          true,
          profileAvatarGroup
            .slice()
            .reverse()
            .map(child => child.username)
        );
      }
      setNextStep(false);
      handleResetAndCloseForm(() => formik.resetForm());
    },
  });

  const nextStepComponent = (
    <Paper sx={{ p: 2 }} elevation={5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataDisplay
            label={"Title"}
            variant={smDown ? "caption" : "body2"}
            value={
              currentActive.preset ? (
                currentActive.preset.title
              ) : (
                <TextField
                  // inputRef={nameFieldRef}
                  inputProps={{
                    ref: nameFieldRef,
                  }}
                  // ref={nameFieldRef}
                  // autoFocus={true}
                  placeholder="Enter title for the preset here"
                  fullWidth
                  variant="outlined"
                  hiddenLabel
                  name="name"
                  size="small"
                  required
                  onChange={formik.handleChange}
                />
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          <DataDisplay
            label={"Selected Profiles"}
            alignCenter={false}
            variant={smDown ? "caption" : "body2"}
            value={
              profileAvatarGroup && (
                <AvatarGroup
                  max={profileAvatarGroup.length}
                  sx={{
                    flexWrap: "wrap",
                    flexDirection: "row !important",
                    ".MuiAvatarGroup-root": {
                      flexDirection: "row !important",
                    },
                    ".MuiAvatar-root:last-of-type": {
                      // marginLeft: "-8px",
                    },
                    ".MuiAvatar-root:first-of-type": {
                      background: "none",
                      border: "none",
                      color: theme.palette.text.secondary,
                      fontSize: "20px",
                      marginRight: "4px",
                    },
                  }}
                >
                  {profileAvatarGroup.map((child, index) => (
                    <Tooltip
                      arrow
                      key={`avatar_${index}`}
                      title={
                        "display_name" in child && (
                          <Box sx={{ textAlign: "center" }}>
                            {child.display_name}
                            <br />@{child.username}
                          </Box>
                        )
                      }
                    >
                      <Avatar
                        variant={"circular"}
                        alt={child.username}
                        src={child.downloaded_image}
                        sx={{ width: "60px", height: "60px", border: `1px solid ${theme.palette.text.secondary} !important` }}
                      />
                    </Tooltip>
                  ))}
                </AvatarGroup>
              )
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleClose = () => {
    onClose();
    setSearch("");
    setNextStep(false);
    setCompareAddAccountDialog({ open: false, socialMedia: "instagram" });
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (nextStep && nameFieldRef.current) {
      nameFieldRef.current.focus();
    }
  }, [nextStep]);

  useEffect(() => {
    return () => {
      // clear when unmount
      setSearch("");
      setNextStep(false);
    };
  }, []);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason && reason === "backdropClick") return;
          handleClose();
        }}
        maxWidth="lg"
        fullWidth
        fullScreen={smDown}
      >
        <DialogTitle>
          <Stack direction="row" spacing={1} alignItems="center">
            {socialMedia === "instagram" ? (
              <img src={Insta} alt="insta" width="25px" height="25px" />
            ) : (
              <img src={TikTok} alt="tiktok" width="25px" height="25px" />
            )}

            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              {nextStep ? (currentActive.preset ? "Edit Preset" : "Create Preset") : "Select Profile(s)"}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent
          sx={{
            maxHeight: smDown ? "calc(100vh - 160px)" : "calc(100vh - 300px)", // Adjust the height as needed
            overflowY: "auto",
          }}
        >
          <Stack spacing={1} sx={{ my: 2 }}>
            {nextStep ? (
              <form id="submitPreset" onSubmit={formik.handleSubmit}>
                <>{nextStepComponent}</>
              </form>
            ) : (
              <>
                {compareAddAccountDialog.open ? (
                  <Paper elevation={10} sx={{ px: 1, pb: 4, pt: 2 }}>
                    <Button
                      startIcon={<FontAwesomeIcon icon={faLeft} />}
                      variant="text"
                      sx={{ mt: 0, mb: 2 }}
                      onClick={() => setCompareAddAccountDialog({ open: false, socialMedia: "instagram" })}
                    >
                      Back
                    </Button>
                    <ComparisonAddAccount
                      onSuccess={() => {
                        setCompareAddAccountDialog({ open: false, socialMedia: "instagram" });
                      }}
                      userSlotData={userSlotData}
                      socialMedia={socialMedia}
                      filterSearch={search}
                    />
                  </Paper>
                ) : (
                  <>
                    <Paper elevation={10} sx={{ p: 1 }}>
                      <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                        <Box sx={{ width: { lg: "300px" } }}>
                          <DelaySubmitInput
                            value={search}
                            variant="outlined"
                            setValue={setSearch}
                            clearInput={search === ""}
                            placeholder={`Filter ${socialMedia} username`}
                          />
                        </Box>
                        <ComparisonAvatarGroup type="profile" avatarGroup={profileAvatarGroup} />
                      </Stack>
                    </Paper>
                    {socialMedia === "instagram" && (
                      <InstaProfileListing
                        search={search}
                        multiSelect={true}
                        id="profile_dialog"
                        showTrackButton={false}
                        showGlobalFilter={false}
                        columnVisibility={{ action: false }}
                        onSelectProfile={profile => {}}
                        instaProfileGridRef={instaProfileGridRef}
                        setCompareAddAccountDialog={setCompareAddAccountDialog}
                      />
                    )}
                    {socialMedia === "tiktok" && (
                      <TikTokProfileListing
                        multiSelect
                        search={search}
                        id="profile_dialog"
                        columnVisibility={{ action: false }}
                        showTrackButton={false}
                        showGlobalFilter={false}
                        onSelectProfile={profile => {}}
                        tiktokProfileGridRef={tiktokProfileGridRef}
                        setCompareAddAccountDialog={setCompareAddAccountDialog}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 1 }}>
          {nextStep ? (
            <Button variant="outlined" onClick={() => setNextStep(false)} startIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
              Back
            </Button>
          ) : (
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          )}
          {/* if preset doesnt exist, then user will be brought to the next step to create preset after done selecting profiles */}
          {nextStep ? (
            currentActive.preset ? (
              // if there is a preset, then replace the current preset
              <Button
                disabled={!!(profileAvatarGroup && profileAvatarGroup.length === 0)}
                variant="contained"
                color={"primary"}
                onClick={() => {
                  if (profileAvatarGroup && onSelectProfiles) {
                    onSelectProfiles(profileAvatarGroup);
                    setNextStep(false);
                  }
                }}
              >
                Submit
              </Button>
            ) : (
              <Button
                form="submitPreset"
                type="submit"
                disabled={!!(profileAvatarGroup && profileAvatarGroup.length === 0)}
                variant="contained"
                color={"primary"}
              >
                Submit
              </Button>
            )
          ) : (
            <Button
              disabled={!!(profileAvatarGroup && profileAvatarGroup.length === 0)}
              variant="contained"
              color={"primary"}
              onClick={() => {
                setNextStep(true);
              }}
              endIcon={<FontAwesomeIcon icon={faArrowRight} />}
            >
              Continue
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileMultiSelectDialog;
