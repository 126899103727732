import React, { useRef, useState } from "react";
/* components */
import UserCredential from "src/pages/admin/user-credential";
import ActivityLogTable from "src/pages/admin/activity-log-table";
import OuterLayout from "src/components/common/layout/outer-layout";
import ProfileInformation from "src/pages/admin/profile-information";
import TrackingStatusTable from "src/pages/admin/tracking-status-table";
import SubscriptionPlanTable from "src/pages/admin/subscription-plan-table";
import AdminEditDialog from "src/components/common/dialog/edit-account-dialog";
import CreditBalanceDialog from "src/components/common/dialog/credit-balance-dialog";
import AdjustCreditDialog, { IAdjustCreditFormValues } from "src/components/common/dialog/adjust-credit-dialog.";
/* 3rd party lib */
import useSWR from "swr";
import moment from "moment";
import { FormikProps } from "formik";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { faUser /* faAngleRight, faMinus, faPlus,  faWallet */ } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import {
  Box,
  Button,
  Grid,
  /* lighten */ Paper,
  Stack,
  Tab,
  Tabs,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
/* Util */
import routes from "src/routes";
import useHttp from "src/hooks/use-http";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { useNavigate, useParams } from "react-router-dom";
import { getTranslateString } from "src/utils/translate";
import { TOAST_ERROR_WENT_WRONG } from "src/constants/translate-keys/common";
import { ISubscriptionPlanData, TSubscriptionAccount, TSubscriptionPlan } from "src/types/common";
import useBackground from "src/hooks/use-background";

interface SuperadminUserProps {}

type Props = SuperadminUserProps;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  target: TViewProfile;
  theme: Theme;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, theme, target, ...other } = props;

  const background = useBackground({});

  return (
    <div
      role="tabpanel"
      hidden={value !== target}
      id={`simple-tabpanel-${target}`}
      aria-labelledby={`simple-tab-${target}`}
      {...other}
      style={{
        background: background,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {value === target && <Box sx={{ p: 1, width: "100%", minWidth: "300px" }}>{children}</Box>}
    </div>
  );
}

export interface IAdjustDialog {
  open: boolean;
  type: "addition" | "minus" | "";
}

export type IAdjustBalance = "addition" | "minus" | "adjust_balance";
type TViewProfile = "profile" | "credential" | "status" | "subscription" | "activity" | "tracking";

const SuperadminUser: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const params = useParams();
  const { user_id } = params;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mdDown } = useMediaQueries();
  const { http, apiEndpoint } = useHttp();
  const dataTableListRef = useRef<any | null>(null);
  const [editDialog, setEditDialog] = useState(false);
  const [adjustDialog, setAdjustDialog] = useState<IAdjustDialog>({ open: false, type: "" });
  const [creditBalanceDialogOpen, setCreditBalanceDialogOpen] = useState(false);
  const { data, mutate: creditBalanceMutate } = useSWR<TSubscriptionAccount>([user_id && apiEndpoint.SUBSCRIPTION_ACCOUNT_DETAIL(user_id)]);
  const { data: subscriptionPlanData, mutate: subscriptionPlanMutate } = useSWR<ISubscriptionPlanData>([
    apiEndpoint.SUBSCRIPTION_PLAN(user_id),
    { params: { active: true } },
  ]);
  const {
    data: currentPlanData,
    mutate: currentPlanMutate,
    error: currentPlanError,
  } = useSWR<TSubscriptionPlan>([apiEndpoint.CURRENT_SUBSCRIPTION(user_id)]);
  const { data: nextPlanData, mutate: nextPlanMutate, error: nextPlanError } = useSWR<TSubscriptionPlan>([apiEndpoint.NEXT_SUBSCRIPTION(user_id)]);
  const [view, setView] = useState<TViewProfile>("profile");

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: TViewProfile) => {
    if (nextView) {
      setView(nextView);
      if (nextView === "subscription") {
        currentPlanMutate();
        nextPlanMutate();
      }
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TViewProfile) => {
    // setValue(newValue);
    setView(newValue);
    if (newValue === "subscription") {
      currentPlanMutate();
      nextPlanMutate();
    }
  };

  const handleAdjustCredit = async (type: IAdjustBalance, formik: FormikProps<IAdjustCreditFormValues>) => {
    const { values, resetForm } = formik;

    let submitData: { account: string | undefined; expired?: string; description: string; quantity: number; action_type: IAdjustBalance } = {
      account: user_id,
      expired: moment(values.expired_date).format("YYYY-MM-DD"),
      description: values.remark,
      quantity: values.credit,
      action_type: type,
    };

    if (type === "minus") {
      delete submitData.expired;
    }

    try {
      await http.post(apiEndpoint.SUBSCRIPTION_ACCOUNT_TRANSACTION, submitData);

      if (dataTableListRef.current) {
        // update the transaction table
        dataTableListRef.current.refreshData();
      }
      creditBalanceMutate();
      resetForm();

      toast.success("Update successful");
      setAdjustDialog({ open: false, type: "" });
    } catch (error) {
      console.error({ error });
      toast.error(getTranslateString(t, TOAST_ERROR_WENT_WRONG));
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */

  return (
    <>
      <OuterLayout maxWidth={"lg"}>
        <Stack alignItems="flex-start">
          <Button
            sx={{ mb: 1, color: theme.palette.primary.main }}
            variant="text"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={() => navigate(routes.SUPERADMIN)}
          >
            <Typography variant="body1" fontWeight={500}>
              Back
            </Typography>
          </Button>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2.5}>
            <Paper elevation={10} sx={{ py: 2 }}>
              <Stack spacing={2} alignItems="center" justifyContent={"space-between"} sx={{ py: 1, px: 2 }}>
                <Stack spacing={1} alignItems="center">
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      display: "grid",
                      placeItems: "center",
                      backgroundColor: (theme.palette.neutral as any)[200],
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} size="3x" color={(theme.palette.neutral as any)[600]} />
                  </Box>
                  <Box>
                    <Typography textAlign="left" variant="h6" fontWeight={600} color="text.secondary">
                      {data && data.name}
                    </Typography>
                  </Box>
                </Stack>

                {!mdDown && (
                  <Box sx={{ mt: 2, width: "100%" }}>
                    <ToggleButtonGroup color={"primary"} orientation="vertical" value={view} exclusive onChange={handleChange} sx={{ width: "100%" }}>
                      <ToggleButton
                        value="profile"
                        aria-label="profile"
                        sx={{
                          color: view === "profile" ? "#ffffff !important" : theme.palette.text.secondary,
                          background: view === "profile" ? `${theme.palette.primary.main} !important` : "none",
                        }}
                      >
                        <Typography variant="body2" textTransform={"capitalize"}>
                          Profile Information
                        </Typography>
                      </ToggleButton>
                      <ToggleButton
                        value="credential"
                        aria-label="credential"
                        sx={{
                          color: view === "credential" ? "#ffffff !important" : theme.palette.text.secondary,
                          background: view === "credential" ? `${theme.palette.primary.main} !important` : "none",
                        }}
                      >
                        <Typography variant="body2" textTransform={"capitalize"}>
                          Credentials
                        </Typography>
                      </ToggleButton>
                      {/* <ToggleButton
                        value="status"
                        aria-label="status"
                        sx={{
                          color: view === "status" ? "#ffffff !important" : theme.palette.text.secondary,
                          background: view === "status" ? `${theme.palette.primary.main} !important` : "none",
                        }}
                      >
                        <Typography variant="body2" textTransform={"capitalize"}>
                          Account Status
                        </Typography>
                      </ToggleButton> */}
                      <ToggleButton
                        value="subscription"
                        aria-label="subscription"
                        sx={{
                          color: view === "subscription" ? "#ffffff !important" : theme.palette.text.secondary,
                          background: view === "subscription" ? `${theme.palette.primary.main} !important` : "none",
                        }}
                      >
                        <Typography variant="body2" textTransform={"capitalize"}>
                          Subscription Plan
                        </Typography>
                      </ToggleButton>
                      <ToggleButton
                        value="tracking"
                        aria-label="tracking"
                        sx={{
                          color: view === "tracking" ? "#ffffff !important" : theme.palette.text.secondary,
                          background: view === "tracking" ? `${theme.palette.primary.main} !important` : "none",
                        }}
                      >
                        <Typography variant="body2" textTransform={"capitalize"}>
                          Tracking Status
                        </Typography>
                      </ToggleButton>
                      <ToggleButton
                        value="activity"
                        aria-label="activity"
                        sx={{
                          color: view === "activity" ? "#ffffff !important" : theme.palette.text.secondary,
                          background: view === "activity" ? `${theme.palette.primary.main} !important` : "none",
                        }}
                      >
                        <Typography variant="body2" textTransform={"capitalize"}>
                          Activity Log
                        </Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                )}
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9.5}>
            {mdDown && <SuperAdminUserTab tab={view} handleChangeTab={handleChangeTab} theme={theme} />}
            {mdDown ? (
              <>
                <CustomTabPanel theme={theme} value={view} target={"profile"}>
                  <ProfileInformation mutate={creditBalanceMutate} user_id={user_id} data={data} />
                </CustomTabPanel>
                <CustomTabPanel theme={theme} value={view} target={"credential"}>
                  <UserCredential user_id={user_id} />
                </CustomTabPanel>
                <CustomTabPanel theme={theme} value={view} target={"subscription"}>
                  <SubscriptionPlanTable
                    user_id={user_id}
                    nextPlanData={nextPlanData}
                    nextPlanError={nextPlanError}
                    nextPlanMutate={nextPlanMutate}
                    mutate={subscriptionPlanMutate}
                    currentPlanData={currentPlanData}
                    currentPlanError={currentPlanError}
                    currentPlanMutate={currentPlanMutate}
                    subscriptionPlanData={subscriptionPlanData}
                  />
                </CustomTabPanel>
                <CustomTabPanel theme={theme} value={view} target={"activity"}>
                  <ActivityLogTable user_id={user_id} />
                </CustomTabPanel>
                <CustomTabPanel theme={theme} value={view} target={"tracking"}>
                  <TrackingStatusTable user_id={user_id} />
                </CustomTabPanel>
              </>
            ) : (
              <>
                {view === "profile" && <ProfileInformation mutate={creditBalanceMutate} user_id={user_id} data={data} />}
                {view === "credential" && <UserCredential user_id={user_id} />}
                {view === "subscription" && (
                  <SubscriptionPlanTable
                    user_id={user_id}
                    nextPlanData={nextPlanData}
                    nextPlanError={nextPlanError}
                    nextPlanMutate={nextPlanMutate}
                    currentPlanData={currentPlanData}
                    currentPlanError={currentPlanError}
                    currentPlanMutate={currentPlanMutate}
                    subscriptionPlanData={subscriptionPlanData}
                    mutate={subscriptionPlanMutate}
                  />
                )}
                {view === "activity" && <ActivityLogTable user_id={user_id} />}
                {view === "tracking" && <TrackingStatusTable user_id={user_id} />}
              </>
            )}
          </Grid>
        </Grid>
      </OuterLayout>
      <CreditBalanceDialog open={creditBalanceDialogOpen} onClose={() => setCreditBalanceDialogOpen(false)} />
      <AdminEditDialog open={editDialog} onClose={() => setEditDialog(false)} onConfirm={() => alert("submit")} />
      <AdjustCreditDialog adjustDialog={adjustDialog} onClose={() => setAdjustDialog({ open: false, type: "" })} onConfirm={handleAdjustCredit} />
    </>
  );
};

export default SuperadminUser;

export const SimpleDataDisplay = ({ label, content }: { label: string; content: string | number | React.ReactNode }) => {
  const { smDown, mdDown } = useMediaQueries();
  return (
    <Stack direction={smDown ? "column" : "row"} alignItems={smDown ? "flex-start" : "center"}>
      <Typography variant="body2" fontWeight={500} textAlign="left" sx={{ width: mdDown ? "200px" : "280px" }}>
        {label}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {content}
      </Typography>
    </Stack>
  );
};

const SuperAdminUserTab = ({
  tab,
  handleChangeTab,
  theme,
}: {
  tab: TViewProfile;
  handleChangeTab: (event: React.SyntheticEvent, newValue: TViewProfile) => void;
  theme: Theme;
}) => {
  return (
    <Tabs variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChangeTab} aria-label="wrapped label tabs example" sx={{ pb: 1 }}>
      <Tab
        {...a11yProps(0)}
        value={"profile"}
        label={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
              Profile
            </Typography>
          </Stack>
        }
      />
      <Tab
        {...a11yProps(1)}
        value={"credential"}
        label={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
              Credential
            </Typography>
          </Stack>
        }
      />

      <Tab
        {...a11yProps(1)}
        value={"subscription"}
        label={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
              Subscription
            </Typography>
          </Stack>
        }
      />
      <Tab
        {...a11yProps(1)}
        value={"tracking"}
        label={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
              Tracking
            </Typography>
          </Stack>
        }
      />
      <Tab
        {...a11yProps(1)}
        value={"activity"}
        label={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary}>
              Activity
            </Typography>
          </Stack>
        }
      />
    </Tabs>
  );
};
