/* components */
/* 3rd party lib */
import useSWR from "swr";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
/* Util */
import useHttp from "src/hooks/use-http";
import { IWalkthrough } from "src/types/common";
import { setTutorialInProgress } from "src/slices/general";
import { TUTORIALS } from "src/utils/constants";

interface JoyrideComponentProps {
  steps: Step[];
  run: boolean;
  stepIndex: number;
  tutorialSection: string;
  setRun: React.Dispatch<React.SetStateAction<boolean>>;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
}

type Props = JoyrideComponentProps;

const JoyrideComponent: React.FC<Props> = ({ steps, run, setRun, stepIndex, setStepIndex, tutorialSection }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const dispatch = useDispatch();
  const { http, apiEndpoint } = useHttp();
  const { data: walkthrough, mutate } = useSWR<IWalkthrough[]>([apiEndpoint.INTERACTIVE_WALKTHROUGH_STATUS]);

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { status, index, action, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
      setRun(false);
      try {
        let config = {
          section: tutorialSection,
        };
        await http.post(apiEndpoint.INTERACTIVE_WALKTHROUGH_VIEW, config);
        mutate();
      } catch (err) {
        console.error(err);
      }
    } else if (type === "step:after" || type === "error:target_not_found") {
      setStepIndex(index + (action === "prev" ? -1 : 1));
    }
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (walkthrough) {
      let tempWalkthrough = walkthrough.find(child => child.section === tutorialSection);

      // if viewed is true, then set run to false else true
      if (tempWalkthrough) {
        setRun(!tempWalkthrough.viewed);
      } else {
        // ignore this section for compare widget
        if (tutorialSection !== TUTORIALS.COMPARE_WIDGET) {
          // if walkthrough is not found or undefined, then set run to true
          setRun(true);
        }
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [walkthrough, tutorialSection]);

  useEffect(() => {
    if (run) {
      dispatch(setTutorialInProgress(true));
    } else {
      dispatch(setTutorialInProgress(false));
    }
  }, [run, dispatch]);
  /* ================================================== */
  /* ================================================== */

  return (
    <Joyride
      continuous
      disableOverlayClose
      scrollToFirstStep
      showProgress
      hideCloseButton
      showSkipButton={walkthrough ? walkthrough.find(child => child.section === tutorialSection)?.viewed : false}
      run={run}
      steps={steps}
      stepIndex={stepIndex}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          arrowColor: (theme.palette.neutral as any)[theme.palette.mode === "dark" ? 800 : 100],
          backgroundColor: (theme.palette.neutral as any)[theme.palette.mode === "dark" ? 800 : 100],
          textColor: theme.palette.text.secondary,
          primaryColor: theme.palette.primary.main,
        },
      }}
    />
  );
};

export default JoyrideComponent;
