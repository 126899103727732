/* components */
/* 3rd party lib */
import React, { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { Stack, Dialog, DialogTitle, IconButton, Typography, DialogContent, Button, DialogActions, Box, useTheme, Paper } from "@mui/material";
/* Util */
import { ICompareTikTokPostJsonData } from "src/types/tiktok";
import { ICompareInstaPostJsonData } from "src/types/instagram";
import { useStrictDroppable } from "src/hooks/use-strict-droppable";
import { useComparisonPostContext } from "src/contexts/comparison-post-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClapperboardPlay, faImageSlash } from "@fortawesome/pro-solid-svg-icons";

interface PostComparisonSortDndProps {
  open: boolean;
  onClose: () => void;
  postData?: ICompareInstaPostJsonData | ICompareTikTokPostJsonData | undefined;
}

type Props = PostComparisonSortDndProps;

const PostComparisonSortDnd: React.FC<Props> = ({ onClose, open, postData }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { mode } = theme.palette;
  const { postIds, setPostIds, currentActive, handleEditPreset } = useComparisonPostContext();
  const [enabled] = useStrictDroppable(!!!postIds);
  const [imageError, setImageError] = useState(false);

  /* ================================================== */
  /*  method */
  /* ================================================== */

  function onError(e: any) {
    setImageError(true);
  }
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !postIds) return;

    const items = Array.from(postIds);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPostIds(items);

    if (currentActive.preset) {
      handleEditPreset(currentActive.preset.id, currentActive.preset.title, items);
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog open={open} onClose={onClose} maxWidth={postIds.length > 6 ? "lg" : "md"} fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="inherit">Sort Posts</Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Paper sx={{ mt: 2 }} elevation={10}>
          <DragDropContext onDragEnd={handleDragEnd}>
            {enabled && (
              <Droppable droppableId="horizontal-table" direction="horizontal">
                {provided => (
                  <Stack
                    alignItems="center"
                    justifyContent={postIds.length > 5 ? "flex-start" : "center"}
                    direction="row"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    spacing={postIds.length > 10 ? 1 : 2}
                    sx={{
                      m: 2,
                      py: 2,
                      overflow: "auto",
                      ...(theme as any).custom.scroll,
                    }}
                  >
                    {postIds
                      .filter(child => child !== "")
                      .map((postId, index) => {
                        return (
                          <Draggable key={`row${postId}`} draggableId={`row${postId}`} index={index}>
                            {(provided, snapshot) => (
                              <>
                                {postData && (
                                  <Stack
                                    alignItems="center"
                                    justifyContent={"center"}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      position: "relative",
                                      border: `1px solid ${mode === "dark" ? "white" : (theme.palette as any).neutral[300]}`,
                                      width: postIds.length > 10 ? "85px" : "150px",
                                      height: postIds.length > 10 ? "100px" : "200px",
                                      borderRadius: "8px",
                                      oveflow: "hidden",
                                      cursor: "grab",
                                      "& .select__modal": {
                                        display: snapshot.isDragging ? "grid" : "none",
                                      },
                                      "&:hover": {
                                        "& .select__modal": {
                                          display: "grid",
                                        },
                                        background: (theme.palette as any).neutral[mode === "dark" ? 900 : 200],
                                      },
                                      background: snapshot.isDragging
                                        ? mode === "dark"
                                          ? "#000000"
                                          : (theme.palette as any).neutral[300]
                                        : (theme.palette as any).neutral[mode === "dark" ? 800 : 100],
                                    }}
                                  >
                                    <>
                                      <Box
                                        className="select__modal"
                                        sx={{
                                          zIndex: 2,
                                          top: 1,
                                          left: 0.5,
                                          width: postIds.length > 10 ? "85px" : "150px",
                                          height: postIds.length > 10 ? "100px" : "200px",

                                          display: "none",
                                          position: "absolute",
                                          borderRadius: "8px",
                                          placeItems: "center",
                                          background: "rgba(0, 0, 0, 0.408)",
                                        }}
                                      />
                                      <Box
                                        sx={{
                                          width: postIds.length > 10 ? "85px" : "150px",
                                          position: "absolute",
                                          height: "100%" /* Set the height of your div */,
                                          zIndex: 2,
                                          borderRadius: "8px",
                                          background:
                                            "is_video" in postData.results[postId].post && postData.results[postId].post.is_video
                                              ? "linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0, transparent 20%)"
                                              : "none",
                                        }}
                                      />

                                      <Box
                                        sx={{
                                          width: postIds.length > 10 ? "85px" : "150px",
                                          position: "absolute",
                                          zIndex: 2,
                                          borderRadius: "8px",
                                          height: "100%" /* Set the height of your div */,
                                          background: "linear-gradient(to top, rgba(0, 0, 0, 0.6) 0, transparent 20%)",
                                        }}
                                      />
                                      {"is_video" in postData.results[postId].post && postData.results[postId].post.is_video && (
                                        <Box sx={{ position: "absolute", zIndex: 2, top: "8px", right: "8px" }}>
                                          <FontAwesomeIcon color="white" icon={faClapperboardPlay} size="lg" />
                                        </Box>
                                      )}

                                      {index !== undefined && (
                                        <Box sx={{ position: "absolute", zIndex: 2, bottom: "4px", right: "8px" }}>
                                          <Typography variant="caption" color="white">
                                            #{index + 1}
                                          </Typography>
                                        </Box>
                                      )}
                                      {imageError ? (
                                        <Box
                                          sx={{
                                            width: postIds.length > 10 ? "85px" : "150px",
                                            height: "100%",
                                            background: "#ff6b6bff",
                                            borderRadius: "8px",
                                            display: "grid",
                                            placeItems: "center",
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faImageSlash} size="3x" color="#ffffff" />
                                        </Box>
                                      ) : (
                                        <img
                                          alt={postData.results[postId].post.image}
                                          src={postData.results[postId].post.image}
                                          style={{
                                            width: postIds.length > 10 ? "85px" : "150px",
                                            height: "100%",
                                            objectFit: "cover",
                                            borderRadius: "8px",
                                          }}
                                          onError={e => onError(e)}
                                        />
                                      )}
                                    </>
                                  </Stack>
                                )}
                              </>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            )}
          </DragDropContext>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ width: "100%" }}>
          <Button variant="contained" color="primary" size="small" onClick={() => onClose()}>
            Done
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default PostComparisonSortDnd;
