import React from "react";
/* components */
/* 3rd party lib */
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from "@mui/material";
import NoDataFound from "../feedback/no-data-found";

/* Util */
interface SubscriptionLogDialogProps {
  open: boolean;
  log: null | string;
  onClose: () => void;
}

type Props = SubscriptionLogDialogProps;

const SubscriptionLogDialog: React.FC<Props> = ({ open, log, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent={"space-between"} spacing={3}>
          <Typography variant="inherit" sx={{ flexGrow: 1 }}>
            Subscription Log
          </Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <Paper sx={{ p: 2 }}>
            {log ? (
              <Typography variant={"body2"}>
                <div style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: log }} />
              </Typography>
            ) : (
              <NoDataFound image />
            )}
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionLogDialog;
