export const getSortParams = (values: { [key: string]: string }) => {
  const sort = values;
  const result = [];

  if (!sort || Object.keys(sort).length === 0) return;

  for (const key in sort) {
    let temp = "";

    if (sort[key] === "desc") {
      temp = `-${key}`;
    } else {
      temp = key;
    }

    result.push(temp);
  }

  return result.join(",");
};
