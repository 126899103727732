/* components */
import DataDisplay from "src/components/common/inputs/data-display";

/* 3rd party lib */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
/* Util */
import { TSubscriptionAccount } from "src/types/common";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { getTranslateString } from "src/utils/translate";
import { BUTTON_CANCEL } from "src/constants/translate-keys/common";
import useHttp from "src/hooks/use-http";
import { toast } from "react-hot-toast";
import { KeyedMutator } from "swr";
import IndustriesSelect from "src/components/common/inputs/industries-select";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/root-reducer";
import { updateUser } from "src/slices/auth";
import { nullUndefinedReturnDash } from "src/utils/general";

export interface IProfileInfoFormValues {
  name: string;
  email: string;
  account_number: string;
  phone_num: string;
  company_name: string;
  industry: string;
  country: string;
  remark: string;
}

interface EditProfileInformationDialogProps {
  open: boolean;
  onClose: () => void;
  user_id: string | undefined;
  mutate?: KeyedMutator<TSubscriptionAccount>;
  profileInformation: TSubscriptionAccount;
}

type Props = EditProfileInformationDialogProps;

const EditProfileInformationDialog: React.FC<Props> = ({ open, onClose, mutate, user_id, profileInformation }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const { http, apiEndpoint } = useHttp();
  const dispatch = useDispatch();
  const [industry, setIndustry] = useState<{ label: string; value: string } | undefined | null>(null);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const formik = useFormik<IProfileInfoFormValues>({
    validateOnChange: false,
    initialValues: {
      name: "",
      email: "",
      account_number: "",
      phone_num: "",
      company_name: "",
      industry: "",
      country: "",
      remark: "",
    },
    onSubmit: async values => {
      if (user_id && mutate) {
        values.industry = industry?.value ?? "";

        try {
          if (user?.isAdmin) {
            await http.patch(apiEndpoint.SUBSCRIPTION_ACCOUNT_DETAIL(user_id), JSON.stringify(values));
          } else {
            await http.patch(apiEndpoint.USER_PROFILE, JSON.stringify(values));
            const { data: user } = await http.get(apiEndpoint.USER_PROFILE);
            if (user) {
              dispatch(updateUser(user));
            }
          }
          toast.success("Profile Information Updated Successfully");
          onClose();
          mutate();
        } catch (err) {
          console.error(err);
          toast.error("Something went wrong when updating profile information.");
        }
      }
    },
  });

  const handleOnClose = () => {
    onClose();
    formik.resetForm();
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (open) {
      formik.setValues({
        name: profileInformation.name,
        email: profileInformation.email ?? "",
        account_number: profileInformation.account_number ?? "",
        phone_num: profileInformation.phone_num ?? "",
        company_name: profileInformation.company_name,
        industry: profileInformation.industry ?? "",
        country: profileInformation.country,
        remark: profileInformation.remark,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, profileInformation]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="inherit">Edit Profile Information</Typography>
          <IconButton onClick={handleOnClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <form id="profileInformationForm" onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            ...(theme as any).custom.scroll,
          }}
        >
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataDisplay
                  label={"Name"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <TextField
                      value={formik.values.name}
                      fullWidth
                      variant="outlined"
                      hiddenLabel
                      name="name"
                      size="small"
                      required
                      onChange={formik.handleChange}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  label={"Email"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <>
                      <Typography variant="body2" color="text.secondary">
                        {nullUndefinedReturnDash(formik.values.email)}
                      </Typography>
                      {/* <TextField
                        disabled
                        value={formik.values.email}
                        fullWidth
                        type="email"
                        variant="outlined"
                        hiddenLabel
                        name="email"
                        size="small"
                        onChange={formik.handleChange}
                      /> */}
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  label={"Account No."}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <>
                      <Typography variant="body2" color="text.secondary">
                        {nullUndefinedReturnDash(formik.values.account_number)}
                      </Typography>
                      {/*
                      <TextField
                      disabled
                      value={formik.values.account_number}
                      fullWidth
                      variant="outlined"
                      hiddenLabel
                      name="account_number"
                      size="small"
                      onChange={formik.handleChange}
                    />
                    */}
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  label={"Mobile Number"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <TextField
                      value={formik.values.phone_num}
                      fullWidth
                      type="tel"
                      variant="outlined"
                      hiddenLabel
                      name="phone_num"
                      size="small"
                      onChange={formik.handleChange}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  label={"Company"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <TextField
                      value={formik.values.company_name}
                      fullWidth
                      variant="outlined"
                      hiddenLabel
                      name="company_name"
                      size="small"
                      onChange={formik.handleChange}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  label={"Industry"}
                  variant={smDown ? "caption" : "body2"}
                  value={<IndustriesSelect formik={formik} industry={industry} setIndustry={setIndustry} />}
                />
                {/* <TextField
                    value={formik.values.industry}
                    fullWidth
                    variant="outlined"
                    hiddenLabel
                    name="industry"
                    size="small"
                    onChange={formik.handleChange}
                  /> */}
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  label={"Country"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <TextField
                      value={formik.values.country}
                      fullWidth
                      variant="outlined"
                      hiddenLabel
                      name="country"
                      size="small"
                      onChange={formik.handleChange}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  alignCenter={false}
                  label={"Remark"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <TextField
                      multiline
                      minRows={2}
                      value={formik.values.remark}
                      fullWidth
                      variant="outlined"
                      hiddenLabel
                      name="remark"
                      size="small"
                      onChange={formik.handleChange}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ width: "100%" }}>
            <Stack direction="row" spacing={1}>
              <Button variant="outlined" onClick={onClose} size="small">
                {getTranslateString(t, BUTTON_CANCEL)}
              </Button>
              <Button form="profileInformationForm" type="submit" variant="contained" color="primary" size="small">
                Confirm
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditProfileInformationDialog;
