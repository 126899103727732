import React, { useState } from "react";
/* components */
import BarChart, { rainbowBarColors, TImageDict } from "src/components/common/data-display/charts/bar-chart";
import { IInstaBarData } from "src/pages/comparisons/profiles/instagram";
import { ITikTokBarData } from "src/pages/comparisons/profiles/tiktok";
/* 3rd party lib */
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-solid-svg-icons";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
/* Util */
import { scroll } from "src/theme/dark-theme-options";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface ProfileChartTitleProps {
  title: string;
  symbol?: string;
  icon?: React.ReactNode;
  targetKey: keyof IInstaBarData | keyof ITikTokBarData;
  chartHeight: string;
  barChartData: IInstaBarData | ITikTokBarData | null;
  imageDict: TImageDict | null;
  chartMode: "linear" | "log";
  setChartMode: React.Dispatch<React.SetStateAction<"linear" | "log">>;
}

export interface TBarChartData {
  [key: string]: (number | null)[];
}

type Props = ProfileChartTitleProps;

const ProfileChartTitle: React.FC<Props> = ({ symbol, title, icon, imageDict, targetKey, chartHeight, barChartData, chartMode, setChartMode }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { smDown, mdDown } = useMediaQueries();
  const [expandDialog, setExpandDialog] = useState(false);

  const gridStyle = {
    containLabel: true,
    top: "40px",
    bottom: "20px",
    left: mdDown ? "20px" : "40px",
    right: mdDown ? "20px" : "40px",
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */

  return (
    <>
      <Paper elevation={5} sx={{ py: 3, width: "100%" }}>
        <Box sx={{ height: chartHeight }}>
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} sx={{ pr: mdDown ? 2 : 4 }}>
            <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left", pl: mdDown ? 2 : 5 }}>
              {title}
              {icon ? <>&nbsp;&nbsp;{icon}</> : null}
            </Typography>

            <Stack direction="row" alignItems="center" spacing={1}>
              <ToggleButtonGroup
                color="primary"
                value={chartMode}
                exclusive
                sx={{ height: "34px" }}
                onChange={(_e, value) => {
                  if (value) {
                    setChartMode(value);
                  }
                }}
                aria-label="Platform"
              >
                <ToggleButton value="linear">
                  <Typography variant="caption" textTransform={"capitalize"}>
                    Normal
                  </Typography>
                </ToggleButton>
                <ToggleButton value="log">
                  <Typography variant="caption" textTransform={"capitalize"}>
                    Amplified
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
              <IconButton
                size="small"
                sx={{ p: 1 }}
                onClick={() => {
                  setExpandDialog(true);
                }}
              >
                <FontAwesomeIcon icon={faExpand} />
              </IconButton>
            </Stack>
          </Stack>

          <Box sx={{ height: "100%" }}>
            {barChartData && (
              <BarChart
                sorted
                toolbox
                horizontal
                chartMode={chartMode}
                gridStyle={gridStyle}
                xAxisData={barChartData.xAxis}
                yAxisData={(barChartData as TBarChartData)[targetKey]}
                imageDict={imageDict}
                symbol={symbol}
              />
            )}
          </Box>
        </Box>
      </Paper>

      <Dialog open={expandDialog} onClose={() => setExpandDialog(false)} maxWidth="md" fullWidth fullScreen={smDown}>
        <DialogTitle>
          <Stack direction="row" spacing={3}>
            <Typography variant="inherit" sx={{ flexGrow: 1 }}>
              Full View
            </Typography>
            <IconButton onClick={() => setExpandDialog(false)}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Paper sx={{ height: "80vh", mt: 2 }}>
            <Stack sx={{ height: "100%", width: "100%" }} spacing={2}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={barChartData && barChartData.xAxis.length > 6 ? "flex-start" : "center"}
                spacing={1}
                sx={{ mt: 2, mx: 2, overflowX: "auto", overflowY: "hidden", ...scroll, height: "160px" }}
              >
                {barChartData &&
                  barChartData.images.map((child, index) => (
                    <Stack p={1} key={child} alignItems="center" justifyContent={"center"}>
                      <Box
                        sx={{
                          width: "100px",
                          height: "100px",
                          background: "white",
                          border: "1px solid #dcdcdc",
                          borderRadius: "50%",
                          display: "grid",
                          placeItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <img src={child} alt={child} width="100px" height="100px" style={{ objectFit: "cover" }} />
                      </Box>

                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Box
                          sx={{ height: "8px", width: "8px", borderRadius: "50%", background: rainbowBarColors[index % rainbowBarColors.length] }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          @{barChartData.xAxis[index]}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
              </Stack>
              <Box sx={{ px: 3, alignSelf: "flex-end" }}>
                <ToggleButtonGroup
                  color="primary"
                  value={chartMode}
                  exclusive
                  sx={{ height: "34px" }}
                  onChange={(_e, value) => {
                    if (value) {
                      setChartMode(value);
                    }
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton value="linear">
                    <Typography variant="caption" textTransform={"capitalize"}>
                      Normal
                    </Typography>
                  </ToggleButton>
                  <ToggleButton value="log">
                    <Typography variant="caption" textTransform={"capitalize"}>
                      Amplified
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                {barChartData && (
                  <BarChart
                    sorted
                    toolbox
                    horizontal
                    chartMode={chartMode}
                    gridStyle={gridStyle}
                    xAxisData={barChartData.xAxis}
                    yAxisData={(barChartData as TBarChartData)[targetKey]}
                    imageDict={imageDict}
                    symbol={symbol}
                  />
                )}
              </Box>
            </Stack>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfileChartTitle;
