import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import useData from "src/hooks/use-data";
import useMounted from "src/hooks/use-mounted";
import { getSortParams } from "src/utils/sort";
import { useDispatch, useSelector } from "react-redux";
import NoPermission from "src/components/no-permission";
import useMediaQueries from "src/hooks/use-mediaqueries";
import SearchBar from "src/components/common/inputs/search-bar";
import DNDTable from "src/components/common/data-display/dnd-table";
import { TRowAction } from "src/components/common/data-display/table";
import SpinnerComponent from "src/components/common/feedback/spinner";
import GoToTopButton from "src/components/common/inputs/go-to-top-button";
import PaginationList from "src/components/common/data-display/pagination-list";
import MobilePagination from "src/components/common/navigation/mobile-pagination";
import { Box, Divider, Drawer, Grid, Paper, Stack, useTheme } from "@mui/material";
import { GeneralRootState, resetFilters, resetSort, setSearch, setSortDNDTable, setSortWithOrder } from "src/slices/general";
import { MRT_ColumnPinningState, MRT_RowSelectionState } from "material-react-table";
import { useLocation } from "react-router-dom";
import { TABLE_ID } from "src/utils/constants";

/**
 * Fetches data and display in table and list view.
 */

interface DataTableListProps {
  id: string;
  url: string;
  search: { key: string; label: string };
  columns: any;
  rowAction?: TRowAction;
  rowActions?: any;
  customFilters?: any;
  listMetadata?: any;
  inputRef?: any;
  columnOrder: any;
  columnVisibility?: any;
  dataTableListRef?: any;
  rowClick?: boolean;
  filters?: any;
  extremeCompact?: boolean;
  showGlobalFilter?: boolean;
  filterType?: "drawer" | "dialog";
  setCheckPagination?: any;
  listItemStyle?: any;
  disableAdvance?: boolean;
  enableMobileList?: boolean;
  enableStickyHeader?: boolean;
  customActions?: React.ReactNode;
  dataFilter?: (dataChild: any) => void;
  rowSelectKey?: string;
  onRowSelect?: (key: any) => void;
  rowSelection?: MRT_RowSelectionState;
  customNoDataComponent?: React.ReactNode;
  anchorRedirect?: (rowData: any) => void;
  mobilePaginationStickBottom?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasData?: React.Dispatch<React.SetStateAction<boolean>>;
  columnPinning?: MRT_ColumnPinningState | undefined;
}

type Props = DataTableListProps;

const DataTableList: React.FC<Props> = props => {
  const {
    id,
    url,
    search,
    columns,
    dataFilter,
    rowAction,
    setLoading,
    setHasData,
    rowActions,
    columnOrder,
    customFilters,
    listMetadata,
    columnPinning,
    columnVisibility,
    dataTableListRef,
    rowClick = false,
    listItemStyle = {},
    rowSelectKey,
    rowSelection,
    onRowSelect,
    anchorRedirect,
    customActions = null,
    setCheckPagination,
    customNoDataComponent,
    disableAdvance = false,
    enableMobileList = true,
    extremeCompact = false,
    showGlobalFilter = true,
    enableStickyHeader = false,
    mobilePaginationStickBottom = true,
  } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationParam = location.state;

  const [noPermission, setNoPermission] = useState(false);
  const [previousTablePage, setPreviousTablePage] = useState<number | null>(null);
  const { count, data, dispatchData, filters, limit, page, totalPage } = useData();
  const { sort, search: ReduxSearch } = useSelector((state: { general: GeneralRootState }) => state.general);

  const isMounted = useMounted();
  const config = useMemo(() => {
    let params: any = {};

    if (ReduxSearch) {
      params = {
        ...filters,
        [search?.key]: ReduxSearch,
        ordering: getSortParams(sort),
        page,
        ...customFilters,
      };
    } else {
      params = {
        ...filters,
        ordering: getSortParams(sort),
        page,
        ...customFilters,
      };
    }

    return { params: params };
  }, [search?.key, ReduxSearch, customFilters, filters, page, sort]);

  const { data: apiData, mutate, error /* isValidating */ } = useSWR(isMounted ? [url, config] : null);

  const { mdDown } = useMediaQueries();

  useEffect(() => {
    if (id === TABLE_ID.HOMEPAGE && locationParam) {
      setPreviousTablePage(locationParam.tablePage);
    }
  }, [id, dispatchData, locationParam]);

  useEffect(() => {
    if (apiData) {
      const { count, limit, results, total_pages } = apiData;

      if (setHasData) {
        if (count > 0) {
          setHasData(true);
        } else {
          setHasData(false);
        }
      }

      // if (id === TABLE_ID.HOMEPAGE && previousTablePage) {
      //   dispatchData({
      //     type: "SET",
      //     payload: { count, data: results, limit, totalPage: total_pages },
      //   });
      //   dispatchData({ type: "SET_PAGE", payload: previousTablePage });
      //   setPreviousTablePage(null);
      // } else {
      dispatchData({
        type: "SET",
        payload: { count, data: results, limit, totalPage: total_pages },
      });
      // }
    }
  }, [dispatchData, setHasData, apiData, id, previousTablePage, locationParam]);

  useEffect(() => {
    dispatchData({ type: "SET_PAGE", payload: 1 });
  }, [dispatchData, mdDown, url]);

  if (dataTableListRef) {
    dataTableListRef.current = {
      refreshData: mutate,
      data: apiData,
      isLoading: data === null,
      count: count,
    };
  }

  useEffect(() => {
    if (setCheckPagination) {
      setCheckPagination({ currentPage: page, rowsPerPage: limit });
    }
  }, [limit, page, setCheckPagination]);

  useEffect(() => {
    if (error) {
      // Check if the error response contains a status code
      const errorCode = error.response?.status || "Unknown";
      if (errorCode === 403) {
        setNoPermission(true);
      }
    }
  }, [error]);

  useEffect(() => {
    return () => {
      // everytime dismount, clear search and sort
      dispatch(resetSort());
      dispatch(resetFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!setLoading) return;
    if (data) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [data, setLoading]);

  // Reset page to 1 whenever there's view change
  useEffect(() => {
    if (id !== TABLE_ID.HOMEPAGE) {
      dispatchData({ type: "SET_PAGE", payload: 1 });
    }
  }, [id, dispatchData, mdDown, url, previousTablePage]);

  const handleSearch = useCallback(
    (value: string) => dispatchData({ type: "SET_FILTERS", payload: { [search?.key]: value } }),
    [dispatchData, search?.key]
  );
  const handleDNDTableSearch = (value: string) => {
    if (search) dispatch(setSearch(value));
  };
  const handleFilter = useCallback((values: any) => dispatchData({ type: "SET_FILTERS", payload: values }), [dispatchData]);
  const handleResetFilters = useCallback(() => dispatchData({ type: "RESET_FILTERS", payload: undefined }), [dispatchData]);
  const handleSort = useCallback((values: any) => dispatch(setSortDNDTable(values)), [dispatch]);

  if (noPermission) {
    return <NoPermission shadow={false} fullWidth />;
  }

  return (
    <>
      {data ? (
        data.length === 0 && customNoDataComponent ? (
          customNoDataComponent
        ) : (
          <Paper>
            {enableMobileList && mdDown && search && showGlobalFilter && (
              <>
                <Box padding={2}>
                  <Grid alignItems="center" container spacing={2}>
                    <Grid item xs>
                      <SearchBar onSearch={handleSearch} placeholder={search?.label || "Search"} />
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </>
            )}
            {enableMobileList && mdDown ? (
              <>
                <PaginationList
                  data={dataFilter ? data.slice().filter(dataFilter) : data}
                  page={page}
                  rowsPerPage={limit}
                  rowClick={rowClick}
                  rowAction={rowAction}
                  metadata={listMetadata}
                  rowActions={rowActions}
                  checkboxSelection={false}
                  listItemStyle={listItemStyle}
                  onSort={key => {
                    dispatch(setSortWithOrder(key));
                  }}
                  sort={sort}
                />
                {!mobilePaginationStickBottom && (
                  <Stack alignItems="center">
                    <MobilePagination
                      count={count}
                      page={page}
                      limit={limit}
                      totalPage={totalPage}
                      onPageChange={(e, page) => dispatchData({ type: "SET_PAGE", payload: page + 1 })}
                    />
                  </Stack>
                )}
              </>
            ) : (
              <DNDTable
                id={id} //in order to know which table is which
                page={page}
                data={dataFilter ? data.slice().filter(dataFilter) : data}
                count={count}
                error={error}
                columns={columns}
                rowClick={rowClick}
                rowsPerPage={limit}
                rowAction={rowAction}
                rowActions={rowActions}
                // isLoading={isValidating}
                handleSort={handleSort}
                handleFilter={handleFilter}
                columnOrder={columnOrder}
                rowSelectKey={rowSelectKey}
                rowSelection={rowSelection}
                customActions={customActions}
                onRowSelect={onRowSelect}
                columnPinning={columnPinning}
                anchorRedirect={anchorRedirect}
                disableAdvance={disableAdvance}
                extremeCompact={extremeCompact}
                resetFilters={handleResetFilters}
                columnVisibility={columnVisibility}
                handleSearch={handleDNDTableSearch}
                showGlobalFilter={showGlobalFilter}
                enableStickyHeader={enableStickyHeader}
                onPageChange={(page: number) => dispatchData({ type: "SET_PAGE", payload: page })}
              />
            )}
          </Paper>
        )
      ) : (
        <Box
          sx={{
            height: `calc(100vh - ${mdDown ? "140px" : "280px"})`,
            display: "grid",
            placeItems: "center",
            background: theme.palette.mode === "dark" ? (theme.palette.neutral as any)[800] : "white",
          }}
        >
          <SpinnerComponent color={theme.palette.mode === "dark" ? "white" : theme.palette.primary.main} />
          {/* <SpinnerComponent color={theme.palette.mode === "dark" ? "white" : (theme.palette.neutral as any)[300]} /> */}
        </Box>
      )}

      {!mdDown && <GoToTopButton />}

      {mdDown && mobilePaginationStickBottom && data && (dataFilter ? data.slice().filter(dataFilter).length > 0 : data.length > 0) && (
        <Drawer sx={{ zIndex: theme.zIndex.appBar - 1 }} variant="persistent" anchor="bottom" open={true}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"center"}
            spacing={1}
            sx={{
              position: "relative",
              overflow: "hidden",
              paddingBottom: "env(safe-area-inset-bottom)",
            }}
          >
            <MobilePagination
              count={count}
              page={page}
              limit={limit}
              totalPage={totalPage}
              onPageChange={(e, page) => dispatchData({ type: "SET_PAGE", payload: page + 1 })}
            />
            <GoToTopButton positionStyle={{ position: "absolute", right: "16px" }} />
          </Stack>
        </Drawer>
      )}
    </>
  );
};

export default DataTableList;
