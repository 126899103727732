import React, { useState } from "react";
/* components */
// import PlanComponent from "src/components/common/data-display/plan-component";
/* 3rd party lib */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  darken,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
/* Util */
import useMediaQueries from "src/hooks/use-mediaqueries";
import OuterLayout from "src/components/common/layout/outer-layout";
import PlanComponent2 from "src/components/common/data-display/plan-component-2";
import useSWR from "swr";
import useHttp from "src/hooks/use-http";
import { IPlanDetail, IStripeSubscription } from "src/types/common";
import { checkObjectNotEmpty } from "src/utils/general";
import useEffectAfterMount from "src/hooks/use-effectaftermount";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root-reducer";

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  target: string;
  theme: Theme;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, theme, target, ...other } = props;
  const { smDown } = useMediaQueries();
  return (
    <div
      role="tabpanel"
      hidden={value !== target}
      id={`simple-tabpanel-${target}`}
      aria-labelledby={`simple-tab-${target}`}
      {...other}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {value === target && (
        <Box sx={{ p: 1, width: "100%", minWidth: smDown ? "auto" : "300px", display: "grid", placeItems: "center" }}>{children}</Box>
      )}
    </div>
  );
}

interface PricingProps {}

type Props = PricingProps;

const Pricing: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  // const location = useLocation();
  // const { subscriptionData } = location.state || {};

  const { smDown, mdDown } = useMediaQueries();
  const [view, setView] = useState<string | null>(null);
  const { apiEndpoint } = useHttp();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [planData, setPlanData] = useState<{ interval: string; plans: IPlanDetail[] }[] | null>(null);
  const { data: subscriptionData } = useSWR<IStripeSubscription>(apiEndpoint.STRIPE_SUBSCRIPTION);

  const faqContent = [
    {
      title: "Do you offer a free trial?",
      content:
        "Yes, you can try the Pro plan features for 21 days. After the trial period ends, access will be deactivated until you sign up for a plan.",
    },

    {
      title: "Can I upgrade my plan at any time?",
      content: `Yes, you can upgrade your plan at any time via the My Account page > Subscription Plan > Manage Subscription`,
    },
    {
      title: "What payment methods do you accept?",
      content: "We use Stripe, so any payment method supported by Stripe will be accepted.",
    },
    {
      title: "Do you offer any discounts?",
      content:
        "While we don’t offer discounts at the moment, signing up for a yearly plan essentially gives you two months of subscriptions for free.",
    },
    {
      title: "Do you offer refunds?",
      content: "While we do not offer refunds, you can cancel your subscription via My Account page.",
    },
    {
      title: "How can I cancel my subscription?",
      content: "You can cancel your subscription anytime by visiting the My Account page > Subscription Plan > Manage Subscription.",
    },
    {
      title: "What happens when I cancel my subscription?",
      content:
        "You can continue using your current plan until your subscription expires. After that, your automatic monthly or yearly payments will stop, and your access will be limited.",
    },
    {
      title: "What happens after my subscription ends?",
      content:
        "When you manually cancel your subscription or payment failed for next cycle of billing, your subscription will end and you will no longer have access to most of the features until you upgrade or renew your subscription.",
    },
    {
      title: "What happens after my trial period ends?",
      content:
        "After your trial period ends, you will be prompt to choose a plan and you will not be able to access to any of the features until you upgrade or renew your subscription.",
    },
  ];
  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleChangeTab = (event: any, nextView: string) => {
    if (nextView) {
      setView(nextView);
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffectAfterMount(() => {
    const getSubscriptionPlan = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiEndpoint.STRIPE_SUBSCRIPTION_PLAN.substring(1)}`, {
          headers: isAuthenticated ? { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } : {},
        });

        if (response.data) {
          function groupByBillingInterval(objects: IPlanDetail[]) {
            const result: { interval: string; plans: IPlanDetail[] }[] = [];

            objects.forEach(obj => {
              let intervalLabel = "";

              if (obj.billing_interval === "month") {
                intervalLabel = obj.interval_count === 1 ? "monthly" : `every ${obj.interval_count} months`;
              } else if (obj.billing_interval === "year") {
                intervalLabel = obj.interval_count === 1 ? "yearly" : `every ${obj.interval_count} years`;
              }

              let group = result.find(item => item.interval === intervalLabel);

              if (!group) {
                group = { interval: intervalLabel, plans: [] };
                result.push(group);
              }

              group.plans.push(obj);
            });

            return result;
          }

          const planObjArray = groupByBillingInterval(response.data);

          setPlanData(planObjArray);

          if (planObjArray.length > 0) {
            // initialize the tab
            setView(planObjArray[0].interval);
          }
        }
      } catch (err: any) {
        console.error(err);
      }
    };

    // Call the async function
    getSubscriptionPlan();
  }, [apiEndpoint]);

  /* ================================================== */
  /* ================================================== */
  return (
    <OuterLayout maxWidth={"lg"}>
      <Stack mb={5} py={6}>
        <Typography variant="h2" color="primary" sx={{ my: 2 }}>
          Up Your Social Game
        </Typography>
        <Typography variant="h3" color="text.secondary">
          Gain time-saving insights with our social media
          <br /> intelligence and stay ahead of the curve!
        </Typography>
      </Stack>

      <Paper elevation={10} sx={{ "*": { boxSizing: "border-box" }, py: 2, px: mdDown ? 1 : 8 }}>
        <Typography variant="h5" color="primary" sx={{ my: 5 }}>
          Pick the right pricing plan for you.
        </Typography>

        {subscriptionData && checkObjectNotEmpty(subscriptionData) && subscriptionData.show_pricing_table ? (
          <>
            <stripe-pricing-table
              // customer-email="kaijun.tong@2ezasia.com"
              pricing-table-id={subscriptionData.pricing_table_id}
              publishable-key={subscriptionData.publishable_key}
              customer-session-client-secret={subscriptionData.customer_session_id}
            ></stripe-pricing-table>
          </>
        ) : (
          <>
            <Stack alignItems={smDown ? "unset" : "center"} sx={{ width: "100%" }}>
              {planData && view && (
                <PricingPlanTab tabs={planData.map(child => child.interval)} tab={view} handleChangeTab={handleChangeTab} theme={theme} />
              )}
            </Stack>
            {planData && view ? (
              planData.map((plan, index: number) => (
                <CustomTabPanel key={`tabContent${plan.interval}`} theme={theme} value={view} target={plan.interval}>
                  <Grid container spacing={2} sx={{ width: "100%" }} justifyContent="center">
                    {plan.plans.map((child, childIndex) => (
                      <Grid
                        sx={{ display: "flex", justifyContent: "center" }}
                        key={`${planData[index].interval}_${child.id}`}
                        alignItems="stretch"
                        item
                        xs={12}
                        sm={mdDown ? 12 : 4}
                        md={4}
                      >
                        <Box
                          sx={{
                            width: smDown ? "100%" : mdDown ? "400px !important" : "100%",
                            display: "flex",
                            placeItems: smDown ? "center" : "unset",
                          }}
                        >
                          <PlanComponent2
                            discount={undefined}
                            highlight={childIndex === plan.plans.length - 1}
                            active={false}
                            price={child.unit_amount}
                            theme={theme}
                            currency={child.currency}
                            tier={child.name}
                            billing_interval={child.billing_interval}
                            subtitle={child.description}
                            features={child.marketing_features}
                            buttonText={"Subscribe"}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>
              ))
            ) : (
              <Stack alignItems="center" justifyContent={"center"} sx={{ width: "100%" }}>
                <Grid container spacing={3} sx={{ mt: 2, width: "100%" }}>
                  <Grid xs={12} sm={mdDown ? 12 : 4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Stack spacing={3} sx={{ mx: 2, width: smDown ? "100%" : mdDown ? "400px !important" : "100%" }}>
                      <Skeleton variant="rectangular" width={100} height={30} />
                      <Skeleton variant="rectangular" width={80} height={20} />
                      <Skeleton variant="rectangular" width={180} height={50} />
                      {/* For other variants, adjust the size with `width` and `height` */}
                      <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={"80%"} height={20} />
                        <Skeleton variant="rectangular" width={"80%"} height={20} />
                        <Skeleton variant="rectangular" width={"80%"} height={20} />
                        <Skeleton variant="rectangular" width={"80%"} height={20} />
                        <Skeleton variant="rectangular" width={"80%"} height={20} />
                      </Stack>
                    </Stack>
                  </Grid>
                  {!mdDown && !smDown && (
                    <>
                      <Grid xs={12} sm={mdDown ? 12 : 4}>
                        <Stack spacing={3} sx={{ mx: 3 }}>
                          <Skeleton variant="rectangular" width={100} height={30} />
                          <Skeleton variant="rectangular" width={80} height={20} />
                          <Skeleton variant="rectangular" width={180} height={50} />
                          {/* For other variants, adjust the size with `width` and `height` */}
                          <Stack spacing={1}>
                            <Skeleton variant="rectangular" width={"80%"} height={20} />
                            <Skeleton variant="rectangular" width={"80%"} height={20} />
                            <Skeleton variant="rectangular" width={"80%"} height={20} />
                            <Skeleton variant="rectangular" width={"80%"} height={20} />
                            <Skeleton variant="rectangular" width={"80%"} height={20} />
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid xs={12} sm={mdDown ? 12 : 4}>
                        <Stack spacing={3} sx={{ mx: 3 }}>
                          <Skeleton variant="rectangular" width={100} height={30} />
                          <Skeleton variant="rectangular" width={80} height={20} />
                          <Skeleton variant="rectangular" width={180} height={50} />
                          {/* For other variants, adjust the size with `width` and `height` */}
                          <Stack spacing={1}>
                            <Skeleton variant="rectangular" width={"80%"} height={20} />
                            <Skeleton variant="rectangular" width={"80%"} height={20} />
                            <Skeleton variant="rectangular" width={"80%"} height={20} />
                            <Skeleton variant="rectangular" width={"80%"} height={20} />
                            <Skeleton variant="rectangular" width={"80%"} height={20} />
                          </Stack>
                        </Stack>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>
            )}
          </>
        )}
      </Paper>

      <Stack spacing={3} pt={2} sx={{ display: "grid", placeItems: "center" }}>
        {/*
        <Paper elevation={10} sx={{ width: "100%" }}>
          <Box sx={{ p: "0 32px 32px 32px" }}>
            <Typography variant="h5" color="primary" sx={{ my: 2 }}>
              Pricing Plans
            </Typography>
            <Grid container spacing={2}>
              <Grid alignItems="stretch" item xs={12} sm={6} md={3}>
                <PlanComponent
                  active={true}
                  price={0}
                  theme={theme}
                  tier="Free"
                  subtitle="Explore with no commitment, no strings attached."
                  features={["Slots: 2", "Slot-Change: 4", "Presets: 2", "Historical Data: 10 Days"]}
                  features={["Slots: 2", "Slot-Change: 4", "Presets: 2", "Historical Data: 10 Days"]}
                  buttonText={"Currently Active"}
                />
              </Grid>
              <Grid alignItems="stretch" item xs={12} sm={6} md={3}>
                <PlanComponent
                  active={false}
                  price={9.99}
                  theme={theme}
                  tier="Lite"
                  subtitle="Enhance your journey with advanced tools and features."
                  features={["Slots: 4", "Slot-Change: 6", "Presets: 10", "Historical Data: 30 days", "Report Download", "Profiles Comparison"]}
                  buttonText="Contact Us"
                />
              </Grid>
              <Grid alignItems="stretch" item xs={12} sm={6} md={3}>
                <PlanComponent
                  active={false}
                  price={39.99}
                  theme={theme}
                  tier="Pro"
                  subtitle="Dive deep into unlimited possibilities and support."
                  features={[
                    "Slots: 20",
                    "Slot-Change: 30",
                    "Presets: Unlimited",
                    "Historical Data: Unlimited",
                    "Profiles Comparison",
                    "Posts Comparison",
                    "Customer Support",
                    // "Coming Soon: Report Download",
                    // "Hashtag Favourites",
                  ]}
                  buttonText="Contact Us"
                />
              </Grid>
              <Grid alignItems="stretch" item xs={12} sm={6} md={3}>
                <PlanComponent
                  enterprise
                  active={false}
                  price={99.99}
                  theme={theme}
                  tier="Enterprise"
                  subtitle="Create a more tailored solutions for your business."
                  features={["Everything in Pro", "Slots: Customized", "Slot-Change: Customized", "Presets: Customized"]}
                  buttonText="Contact Us"
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        */}
        <Paper elevation={10} sx={{ width: "100%" }}>
          {faqContent.map((child, index) => (
            <Accordion
              disableGutters
              key={child.title}
              sx={{
                width: "100%",
                marginTop: "1px !important",
              }}
            >
              <AccordionSummary
                sx={{
                  color: theme.palette.primary.main,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant="h6" color="primary" textAlign={"left"}>
                  {child.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: theme.palette.mode === "light" ? "white" : darken((theme.palette.neutral as any)[800], 0.2),
                  borderTop: "1px solid #e7e7e7",
                  textAlign: "left",
                  py: 4,
                }}
              >
                <Typography variant="body1" color={"text.secondary"} textAlign={"left"}>
                  {child.content.split("\n").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
      </Stack>
    </OuterLayout>
  );
};

export default Pricing;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PricingPlanTab = ({
  tab,
  tabs,
  handleChangeTab,
  theme,
}: {
  tabs: string[];
  tab: string;
  handleChangeTab: (event: React.SyntheticEvent, newValue: string) => void;
  theme: Theme;
}) => {
  return (
    <Tabs variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChangeTab} aria-label="wrapped label tabs example" sx={{ pb: 1 }}>
      {tabs.map((child, index) => (
        <Tab
          key={`tab${index}`}
          {...a11yProps(index)}
          value={child}
          label={
            <Stack direction="row" alignItems={"center"} spacing={1}>
              <Typography variant={"body1"} fontWeight={500} color={theme.palette.text.secondary} textTransform="capitalize">
                {child}
              </Typography>
            </Stack>
          }
        />
      ))}
    </Tabs>
  );
};
