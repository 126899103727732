import React, { useEffect, useState } from "react";
/* components */
import PostChartTitle from "src/components/comparison/posts/post-chart-title";
/* 3rd party lib */
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faPlay, faThumbsUp, faBookmark, faShare, faMobile } from "@fortawesome/pro-solid-svg-icons";
/* Util */
import { ILineChartTikTokPostData } from "src/types/tiktok";
import { IBarTikTokPostData } from "src/pages/comparisons/posts/tiktok";
import { TImageDict } from "src/components/common/data-display/charts/bar-chart";

interface PostComparisonChartsProps {
  lineChartData: ILineChartTikTokPostData | null;
  barChartData: IBarTikTokPostData | undefined;
}

type Props = PostComparisonChartsProps;

const PostComparisonCharts: React.FC<Props> = ({ lineChartData, barChartData }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const [imageDict, setImageDict] = useState<TImageDict>({});
  const [chartType, setChartType] = useState<"line" | "bar">("bar");

  let chartHeight = "350px";

  if (barChartData) {
    if (barChartData.xAxis.length > 8) {
      chartHeight = "500px";
    } else if (barChartData.xAxis.length > 4) {
      chartHeight = "400px";
    }
  }

  const gridItems = [
    {
      title: "Plays",
      targetKey: "count_play",
      icon: faPlay,
    },
    {
      title: "Likes",
      targetKey: "count_like",
      icon: faThumbsUp,
    },
    {
      title: "Comments",
      targetKey: "count_comment",
      icon: faComment,
    },
    {
      title: "Saved",
      targetKey: "count_saved",
      icon: faBookmark,
    },
    {
      title: "Share",
      targetKey: "count_share",
      icon: faShare,
    },
    {
      title: "Engagement Rate",
      targetKey: "engagement_rate",
      icon: faMobile,
    },
  ];
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (barChartData && barChartData.images.length === barChartData.xAxis.length) {
      const tempImageDict = barChartData.xAxis.reduce((acc: TImageDict, xAxis, index) => {
        const newStr = xAxis.replace(/\./g, "_");
        acc[`${newStr}`] = { oriStr: `Post ${index + 1}`, image: barChartData.images[index], username: barChartData.usernames[index] };
        return acc;
      }, {});

      setImageDict(tempImageDict);
    }
  }, [barChartData]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Grid item xs={12} sx={{ p: 1 }}>
      <Grid container spacing={2} justifyContent="center">
        {gridItems.map(child => (
          <Grid item xs={12} sm={6} lg={4} key={child.targetKey}>
            <PostChartTitle
              title={child.title}
              chartType={chartType}
              setChartType={setChartType}
              icon={<FontAwesomeIcon icon={child.icon} />}
              targetKey={child.targetKey}
              chartHeight={chartHeight}
              lineChartData={lineChartData}
              barChartData={barChartData}
              imageDict={imageDict}
              symbol={child.targetKey === "engagement_rate" ? "%" : ""}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default PostComparisonCharts;
