/* components */
import PercentDiff from "src/components//common/data-display/percent-diff";
import LineChart from "src/components/common/data-display/charts/line-chart";
/* 3rd party lib */
import useSWR from "swr";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Box } from "@mui/system";
import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import { Grid, Paper, Stack, Typography } from "@mui/material";
/* Util */
import "src/styles/widget.scss";
import useHttp from "src/hooks/use-http";
import { IFormDateFilter } from "src/types/common";
import { instaLineColors } from "src/utils/constants";
import { DateData, TInstaProfileWidgetData } from "src/types/instagram";
import DateFilter from "src/components/common/inputs/date-filter";
import { checkAllChildrenEmpty } from "src/utils/general";
import NoDataFound from "src/components/common/feedback/no-data-found";
import ArrowOverflowBox from "src/components/common/layout/arrow-overflow-box";

export type TPerct = {
  earliestDate: string | null;
  latestDate: string | null;
  earliestData: number | null;
  latestData: number | null;
  difference: number;
  percentage: number;
};

export interface IInstaPerctObj {
  total_play: TPerct | null;
  total_like: TPerct | null;
  follower: TPerct | null;
  total_comment: TPerct | null;
  post: TPerct | null;
  following: TPerct | null;
}

interface ProfileWidgetsProps {
  formik: FormikProps<IFormDateFilter>;
}

type Props = ProfileWidgetsProps;

const ProfileWidgets: React.FC<Props> = ({ formik }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { apiEndpoint } = useHttp();

  const params = useParams();
  const config = useMemo(() => {
    let params = {
      date_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
      date_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
    };
    return { params };
  }, [formik]);

  const { data } = useSWR<TInstaProfileWidgetData>(params && [apiEndpoint.INSTAGRAM_PROFILE_WIDGET(params.username), config]);

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const [percentageDiff, setPercentageDiff] = useState<IInstaPerctObj | null>(null);
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    let perctObj: IInstaPerctObj | null = null;

    if (data) {
      function removeNullValues(obj: any): any {
        return Object.fromEntries(
          Object.entries(obj).filter(([_key, value]) => {
            return (value as any).count !== null;
          })
        );
      }

      function findEarliestAndLatest(data: DateData) {
        if (Object.keys(data).length === 0) return null;
        let earliestDate = null;
        let latestDate = null;

        for (const dateStr in data) {
          if (data.hasOwnProperty(dateStr)) {
            const currentDate = moment(dateStr);
            if (!earliestDate || currentDate.isBefore(earliestDate)) {
              earliestDate = currentDate;
            }

            if (!latestDate || currentDate.isAfter(latestDate)) {
              latestDate = currentDate;
            }
          }
        }

        const earliestData = earliestDate ? (data as any)[earliestDate.format("YYYY-MM-DD")] : null;
        const latestData = latestDate ? (data as any)[latestDate.format("YYYY-MM-DD")] : null;

        const earliestCount = earliestData?.count || 0; // Default to 0 if null

        return {
          earliestDate: moment(earliestDate).format("YYYY-MM-DD") ?? null,
          latestDate: moment(latestDate).format("YYYY-MM-DD") ?? null,
          earliestData: earliestData.count,
          latestData: latestData.count,
          difference: latestData.count - earliestCount,
          percentage: earliestData.count === 0 ? 100 : ((latestData.count - earliestData.count) / earliestData.count) * 100,
        };
      }

      perctObj = {
        total_play: Object.keys(data.results.total_play).length > 0 ? findEarliestAndLatest(removeNullValues(data.results.total_play)) : null,
        total_like: Object.keys(data.results.total_like).length > 0 ? findEarliestAndLatest(removeNullValues(data.results.total_like)) : null,
        total_comment:
          Object.keys(data.results.total_comment).length > 0 ? findEarliestAndLatest(removeNullValues(data.results.total_comment)) : null,
        follower: Object.keys(data.results.follower).length > 0 ? findEarliestAndLatest(removeNullValues(data.results.follower)) : null,
        post: Object.keys(data.results.post).length > 0 ? findEarliestAndLatest(removeNullValues(data.results.post)) : null,
        following: Object.keys(data.results.following).length > 0 ? findEarliestAndLatest(removeNullValues(data.results.following)) : null,
      };

      setPercentageDiff(perctObj);
    }
  }, [data]);

  /* ================================================== */
  /* ================================================== */

  const handleTotalPlayZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.total_play).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.total_play).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            total_play: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );
  const handleTotalLikeZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.total_like).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.total_like).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            total_like: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );
  const handleTotalFollowerZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.follower).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.follower).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            follower: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );
  const handleTotalCommentZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.total_comment).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.total_comment).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            total_comment: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );
  const handleTotalPostZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.post).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.post).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            post: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );
  const handleTotalFollowingZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.following).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.following).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            following: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );

  return (
    <Paper>
      <ArrowOverflowBox>
        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={1} pt={2} px={2} mb={1}>
          <DateFilter formik={formik} />
        </Stack>
      </ArrowOverflowBox>
      {data && checkAllChildrenEmpty(data.results) ? (
        <Box sx={{ py: 2 }}>
          <NoDataFound image />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12} sm={6} lg={4} xl={2}>
            <Box className="widget__div">
              <Stack sx={{ pl: { xs: 5, lg: 1 } }}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left" }}>
                    Total Plays
                  </Typography>
                  {percentageDiff && percentageDiff.total_play && (
                    <PercentDiff difference={percentageDiff.total_play.difference} percentage={percentageDiff.total_play.percentage} />
                  )}
                </Stack>

                <Typography variant="caption" color="text.secondary" textAlign={"left"}>
                  {moment(percentageDiff?.total_play?.earliestDate).format("DD MMM YY")}&nbsp;-&nbsp;
                  {moment(percentageDiff?.total_play?.latestDate).format("DD MMM YY")}
                </Typography>
              </Stack>
              {data && (
                <LineChart
                  dataZoom
                  color={instaLineColors.total_view}
                  onDataZoom={handleTotalPlayZoom}
                  xAxisData={Object.keys(data.results.total_play).map(child => moment(child).format("DD MMM YY"))}
                  yAxisData={Object.values(data.results.total_play).map(child => child.count)}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={2}>
            <Box className="widget__div">
              <Stack sx={{ pl: { xs: 5, lg: 1 } }}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left" }}>
                    Total Likes
                  </Typography>
                  {percentageDiff && percentageDiff.total_like && (
                    <PercentDiff difference={percentageDiff.total_like.difference} percentage={percentageDiff.total_like.percentage} />
                  )}
                </Stack>
                <Typography variant="caption" color="text.secondary" textAlign={"left"}>
                  {moment(percentageDiff?.total_like?.earliestDate).format("DD MMM YY")}&nbsp;-&nbsp;
                  {moment(percentageDiff?.total_like?.latestDate).format("DD MMM YY")}&nbsp;
                </Typography>
              </Stack>

              {data && (
                <LineChart
                  dataZoom
                  color={instaLineColors.total_like}
                  onDataZoom={handleTotalLikeZoom}
                  xAxisData={Object.keys(data.results.total_like).map(child => moment(child).format("DD MMM"))}
                  yAxisData={Object.values(data.results.total_like).map(child => child.count)}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={2}>
            <Box className="widget__div">
              <Stack sx={{ pl: { xs: 5, lg: 1 } }}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left" }}>
                    Followers
                  </Typography>
                  {percentageDiff && percentageDiff.follower && (
                    <PercentDiff difference={percentageDiff.follower.difference} percentage={percentageDiff.follower.percentage} />
                  )}
                </Stack>
                <Typography variant="caption" color="text.secondary" textAlign={"left"}>
                  {moment(percentageDiff?.follower?.earliestDate).format("DD MMM YY")}&nbsp;-&nbsp;
                  {moment(percentageDiff?.follower?.latestDate).format("DD MMM YY")}
                </Typography>
              </Stack>

              {data && (
                <LineChart
                  dataZoom
                  color={instaLineColors.follower}
                  onDataZoom={handleTotalFollowerZoom}
                  xAxisData={Object.keys(data.results.follower).map(child => moment(child).format("DD MMM"))}
                  yAxisData={Object.values(data.results.follower).map(child => child.count)}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={2}>
            <Box className="widget__div">
              <Stack sx={{ pl: { xs: 5, lg: 1 } }}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left" }}>
                    Total Comments
                  </Typography>
                  {percentageDiff && percentageDiff.total_comment && (
                    <PercentDiff difference={percentageDiff.total_comment.difference} percentage={percentageDiff.total_comment.percentage} />
                  )}
                </Stack>
                <Typography variant="caption" color="text.secondary" textAlign={"left"}>
                  {moment(percentageDiff?.total_comment?.earliestDate).format("DD MMM YY")}&nbsp;-&nbsp;
                  {moment(percentageDiff?.total_comment?.latestDate).format("DD MMM YY")}
                </Typography>
              </Stack>

              {data && (
                <LineChart
                  dataZoom
                  color={instaLineColors.total_comment}
                  onDataZoom={handleTotalCommentZoom}
                  xAxisData={Object.keys(data.results.total_comment).map(child => moment(child).format("DD MMM"))}
                  yAxisData={Object.values(data.results.total_comment).map(child => child.count)}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={2}>
            <Box className="widget__div">
              <Stack sx={{ pl: { xs: 5, lg: 1 } }}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left" }}>
                    Posts
                  </Typography>
                  {percentageDiff && percentageDiff.post && (
                    <PercentDiff difference={percentageDiff.post.difference} percentage={percentageDiff.post.percentage} />
                  )}
                </Stack>
                <Typography variant="caption" color="text.secondary" textAlign={"left"}>
                  {moment(percentageDiff?.post?.earliestDate).format("DD MMM YY")}&nbsp;-&nbsp;
                  {moment(percentageDiff?.post?.latestDate).format("DD MMM YY")}
                </Typography>
              </Stack>

              {data && (
                <LineChart
                  dataZoom
                  color={instaLineColors.post}
                  onDataZoom={handleTotalPostZoom}
                  xAxisData={Object.keys(data.results.post).map(child => moment(child).format("DD MMM"))}
                  yAxisData={Object.values(data.results.post).map(child => child.count)}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={2}>
            <Box className="widget__div">
              <Stack sx={{ pl: { xs: 5, lg: 1 } }}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left" }}>
                    Following
                  </Typography>
                  {percentageDiff && percentageDiff.following && (
                    <PercentDiff difference={percentageDiff.following.difference} percentage={percentageDiff.following.percentage} />
                  )}
                </Stack>
                <Typography variant="caption" color="text.secondary" textAlign={"left"}>
                  {moment(percentageDiff?.following?.earliestDate).format("DD MMM YY")}&nbsp;-&nbsp;
                  {moment(percentageDiff?.following?.latestDate).format("DD MMM YY")}
                </Typography>
              </Stack>

              {data && (
                <LineChart
                  dataZoom
                  color={instaLineColors.following}
                  onDataZoom={handleTotalFollowingZoom}
                  xAxisData={Object.keys(data.results.following).map(child => moment(child).format("DD MMM"))}
                  yAxisData={Object.values(data.results.following).map(child => child.count)}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default ProfileWidgets;
