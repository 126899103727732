import { Autocomplete, styled, TextField } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
/* components */
/* 3rd party lib */
/* Util */
import { IProfileInfoFormValues } from "src/components/common/dialog/edit-profile-information-dialog";

interface IndustriesSelectProps {
  formik: FormikProps<IProfileInfoFormValues>;
  industry: { value: string; label: string } | undefined | null;
  setIndustry: React.Dispatch<
    React.SetStateAction<
      | {
          value: string;
          label: string;
        }
      | null
      | undefined
    >
  >;
}

type Props = IndustriesSelectProps;

const StyledTextField = styled(TextField)(() => {
  return {
    "& .MuiInputBase-root": {
      paddingBottom: "4px !important",
      paddingTop: "0px !important",
    },
  };
});

const IndustriesSelect: React.FC<Props> = ({ formik, industry, setIndustry }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (formik.values.industry) {
      setIndustry(industries.find(child => child.value === formik.values.industry));
    }
  }, [formik.values, setIndustry]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Autocomplete
      getOptionLabel={option => (option.label ? option.label : "")}
      isOptionEqualToValue={(option, value) => {
        if (value === undefined) return false;
        return option.value === value.value;
      }}
      options={industries}
      value={industry}
      fullWidth
      renderInput={params => (
        <StyledTextField
          variant="outlined"
          {...params}
          placeholder="Select Industry"
          hiddenLabel
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
      onChange={(_, value) => {
        setIndustry(value);
        formik.setFieldValue("industry", value?.value);
      }}
    />
  );
};

export default IndustriesSelect;

export const industries = [
  { value: "advertising", label: "Advertising" },
  { value: "agriculture", label: "Agriculture" },
  { value: "automotive", label: "Automotive" },
  { value: "aviation", label: "Aviation" },
  { value: "banking", label: "Banking" },
  { value: "biotechnology", label: "Biotechnology" },
  { value: "construction", label: "Construction" },
  { value: "consulting", label: "Consulting" },
  { value: "consumer_goods", label: "Consumer Goods" },
  { value: "education", label: "Education" },
  { value: "energy", label: "Energy" },
  { value: "entertainment", label: "Entertainment" },
  { value: "environmental_services", label: "Environmental Services" },
  { value: "fashion", label: "Fashion" },
  { value: "finance", label: "Finance" },
  { value: "food_and_beverage", label: "Food and Beverage" },
  { value: "gaming", label: "Gaming" },
  { value: "government", label: "Government" },
  { value: "healthcare", label: "Healthcare" },
  { value: "hospitality", label: "Hospitality" },
  { value: "human_resources", label: "Human Resources" },
  { value: "information_technology", label: "Information Technology" },
  { value: "insurance", label: "Insurance" },
  { value: "legal_services", label: "Legal Services" },
  { value: "manufacturing", label: "Manufacturing" },
  { value: "marketing", label: "Marketing" },
  { value: "media", label: "Media" },
  { value: "nonprofit", label: "Nonprofit" },
  { value: "pharmaceuticals", label: "Pharmaceuticals" },
  { value: "public_relations", label: "Public Relations" },
  { value: "publishing", label: "Publishing" },
  { value: "real_estate", label: "Real Estate" },
  { value: "retail", label: "Retail" },
  { value: "sports", label: "Sports" },
  { value: "telecommunications", label: "Telecommunications" },
  { value: "transportation", label: "Transportation" },
  { value: "travel_and_tourism", label: "Travel and Tourism" },
  { value: "utilities", label: "Utilities" },
  { value: "veterinary_services", label: "Veterinary Services" },
  { value: "wholesale", label: "Wholesale" },
  { value: "arts_and_culture", label: "Arts and Culture" },
  { value: "architecture", label: "Architecture" },
  { value: "science_and_research", label: "Science and Research" },
  { value: "mining_and_metals", label: "Mining and Metals" },
  { value: "logistics_and_supply_chain", label: "Logistics and Supply Chain" },
  { value: "security_and_investigations", label: "Security and Investigations" },
  { value: "event_management", label: "Event Management" },
  { value: "e_commerce", label: "E-commerce" },
  { value: "fitness_and_wellness", label: "Fitness and Wellness" },
  { value: "music_industry", label: "Music Industry" },
  { value: "home_services", label: "Home Services" },
  { value: "marine_and_maritime", label: "Marine and Maritime" },
  { value: "personal_services", label: "Personal Services" },
  { value: "property_management", label: "Property Management" },
  { value: "engineering", label: "Engineering" },
  { value: "social_services", label: "Social Services" },
  { value: "space_and_aerospace", label: "Space and Aerospace" },
  { value: "interior_design", label: "Interior Design" },
  { value: "pet_services", label: "Pet Services" },
];
