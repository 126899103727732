import React from "react";
import { alpha, Box, Stack, Typography, useTheme } from "@mui/material";
import { formatNumberWithSuffix } from "src/utils/general";

interface DiffCountProps {
  count: number;
  diff: number;
}

type Props = DiffCountProps;

const DiffCount: React.FC<Props> = ({ count, diff }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  let color = "neutral.400";
  let bgColor = theme.palette.success.main;
  let symbol = "";

  if (diff) {
    if (diff > 0) {
      color = "success.main";
      symbol = "+";
    } else if (diff < 0) {
      color = mode === "light" ? "error.main" : "error.light";
      bgColor = mode === "light" ? theme.palette.error.main : theme.palette.error.light;
    }
  }

  return (
    <Stack direction={"row"} spacing={1} alignItems="center">
      <Typography variant="body2" color="text.secondary">
        {formatNumberWithSuffix(count)}
      </Typography>
      <Box sx={{ background: alpha(bgColor, mode === "light" ? 0.1 : 0.18), px: 1, borderRadius: "8px" }}>
        <Typography variant="caption" color={color}>
          {symbol}
          {diff ? formatNumberWithSuffix(diff) : ""}
        </Typography>
      </Box>
    </Stack>
  );
};

export default DiffCount;
