/* components */
/* 3rd party lib */
import React from "react";
// import CountUp from "react-countup";
import { Box, Grid, Paper, Stack, Theme, Typography } from "@mui/material";
import { faBookmark, faComment, faFilm, faPlay, faShare, faThumbsUp, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
/* Util */
import { TTikTokProfileStatistics } from "src/types/tiktok";
import { formatNumberWithSuffix /* countUpSuffix, hasDecimalPlaces */ } from "src/utils/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface PostsStatisticsProps {
  data?: TTikTokProfileStatistics;
  theme: Theme;
}

type Props = PostsStatisticsProps;

const PostsStatistics: React.FC<Props> = ({ data, theme }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { smDown, mdDown } = useMediaQueries();

  const IconWrapper = ({ children }: { children: React.ReactNode }) => (
    <Box
      sx={{
        background: theme.palette.mode === "light" ? "white" : (theme.palette.neutral as any)[700],
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        display: "grid",
        placeItems: "center",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.3)",
      }}
    >
      {children}
    </Box>
  );

  const StatsDiv = ({ title, stats, icon, iconColor }: { title: string; stats: number | undefined; icon: IconDefinition; iconColor: string }) => {
    return (
      <Paper sx={{ height: "100%" }}>
        <Stack
          direction="row"
          sx={{ height: "100%", pb: mdDown ? 2 : 0, px: { xs: 1, sm: 2, lg: 3, xl: 5 } }}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Stack alignItems={"flex-start"}>
            <Typography variant={smDown ? "body2" : "body1"}>{title}</Typography>
            <Typography variant={mdDown ? "h5" : "h4"}>{formatNumberWithSuffix(stats)}</Typography>
          </Stack>

          <IconWrapper>
            <FontAwesomeIcon icon={icon} color={iconColor} size="xl" />
          </IconWrapper>
        </Stack>
      </Paper>
    );
  };

  // const posts = parseFloat(countUpSuffix(data?.stats.post).num.toString());
  // const videos = parseFloat(countUpSuffix(data?.stats.video).num.toString());
  // const plays = parseFloat(countUpSuffix(data?.stats.play).num.toString());
  // const likes = parseFloat(countUpSuffix(data?.stats.like).num.toString());
  // const comments = parseFloat(countUpSuffix(data?.stats.comment).num.toString());

  /* ================================================== */
  /*  method */
  /* ================================================== */

  // const NumCounter = ({ modifiedNum, oriNum }: { modifiedNum: number; oriNum: number | undefined }) => (
  //   <>
  //     {data && oriNum ? (
  //       <CountUp
  //         end={hasDecimalPlaces(modifiedNum) || countUpSuffix(oriNum).unit !== "" ? parseFloat(countUpSuffix(oriNum).num.toString()) : oriNum}
  //         duration={2}
  //         separator=","
  //         decimals={hasDecimalPlaces(modifiedNum) ? 1 : 0}
  //       >
  //         {({ countUpRef }) => (
  //           <div>
  //             <span ref={countUpRef} />

  //             {hasDecimalPlaces(modifiedNum) || countUpSuffix(oriNum).unit !== "" ? countUpSuffix(oriNum).unit : ""}
  //           </div>
  //         )}
  //       </CountUp>
  //     ) : (
  //       "-"
  //     )}
  //   </>
  // );

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      {mdDown ? (
        <Grid container spacing={1} sx={{ height: "100%" }}>
          <Grid item xs={6}>
            <StatsDiv iconColor={"#5ea9d1"} stats={data?.stats.video} title={"Videos"} icon={faFilm} />
          </Grid>
          <Grid item xs={6}>
            <StatsDiv iconColor={"#5ea9d1"} stats={data?.stats.saved} title={"Saved"} icon={faBookmark} />
          </Grid>
          <Grid item xs={6}>
            <StatsDiv iconColor={"#5ea9d1"} stats={data?.stats.share} title={"Shares"} icon={faShare} />
          </Grid>
          <Grid item xs={6}>
            <StatsDiv iconColor={theme.palette.text.secondary} stats={data?.stats.play} title={"Play"} icon={faPlay} />
          </Grid>
          <Grid item xs={6}>
            <StatsDiv iconColor={theme.palette.text.secondary} stats={data?.stats.like} title={"Likes"} icon={faThumbsUp} />
          </Grid>
          <Grid item xs={6}>
            <StatsDiv iconColor={theme.palette.text.secondary} stats={data?.stats.comment} title={"Comments"} icon={faComment} />
          </Grid>
        </Grid>
      ) : (
        <Stack spacing={1} sx={{ height: "100%" }}>
          <StatsDiv iconColor={"#5ea9d1"} stats={data?.stats.video} title={"Videos"} icon={faFilm} />
          <StatsDiv iconColor={"#5ea9d1"} stats={data?.stats.saved} title={"Saved"} icon={faBookmark} />
          <StatsDiv iconColor={"#5ea9d1"} stats={data?.stats.share} title={"Shares"} icon={faShare} />
          <StatsDiv iconColor={theme.palette.text.secondary} stats={data?.stats.play} title={"Play"} icon={faPlay} />
          <StatsDiv iconColor={theme.palette.text.secondary} stats={data?.stats.like} title={"Likes"} icon={faThumbsUp} />
          <StatsDiv iconColor={theme.palette.text.secondary} stats={data?.stats.comment} title={"Comments"} icon={faComment} />
        </Stack>
      )}
    </>
  );
};

export default PostsStatistics;
