import { TablePagination } from "@mui/material";
import React from "react";
interface MobilePaginationProps {
  count: number;
  page: number;
  totalPage: number;
  limit: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
}

type Props = MobilePaginationProps;

const MobilePagination: React.FC<Props> = ({ count, page, totalPage, limit, onPageChange }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <TablePagination
        component="div"
        count={count}
        page={page - 1}
        labelDisplayedRows={({ from, to, count, page }) => {
          if (from === count) {
            // if its the last page with last single item, then show "no of no"
            return `${from} of ${count !== -1 ? count : `more than ${to}`}`;
          }
          // return `${from} – ${to} of ${count !== -1 ? count : `more than ${to}`}`;
          // return <Typography color="text.secondary" variant="body2">{`Page ${page + 1} / ${totalPage}`}</Typography>;
          return `Page ${page + 1} / ${totalPage}`;
        }}
        rowsPerPageOptions={[]}
        rowsPerPage={limit}
        // showFirstButton
        // showLastButton
        onPageChange={onPageChange}
        sx={{
          ".MuiTypography-root": {
            // position: "absolute",
            // left: "8px",
            // top: "50%",
            // transform: "translateY(-50%)",
          },
          ".MuiToolbar-root": {
            position: "static",
            padding: 0,
          },
          ".MuiTablePagination-actions": {
            // position: "absolute",
            // left: "50%",
            // transform: "translateX(-50%)",
            // marginLeft: "8px",
            "& .MuiButtonBase-root": {
              py: 0,
              px: "30px",
            },
          },
        }}
      />
    </>
  );
};

export default MobilePagination;
