/* components */
import PercentDiff from "src/components//common/data-display/percent-diff";
import LineChart from "src/components/common/data-display/charts/line-chart";
import NoDataFound from "src/components/common/feedback/no-data-found";
/* 3rd party lib */
import useSWR from "swr";
import moment from "moment";
import { Box } from "@mui/system";
import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
/* Util */
import "src/styles/widget.scss";
import useHttp from "src/hooks/use-http";
import { DateData } from "src/types/tiktok";
import { tiktokLineColors } from "src/utils/constants";
import DateFilter from "src/components/common/inputs/date-filter";
import { TTikTokProfileWidgetData } from "src/types/tiktok";
import { IFormDateFilter } from "src/types/common";
import { checkAllChildrenEmpty } from "src/utils/general";
import ArrowOverflowBox from "src/components/common/layout/arrow-overflow-box";

export type TPerct = {
  earliestDate: string | null;
  latestDate: string | null;
  earliestData: number | null;
  latestData: number | null;
  difference: number;
  percentage: number;
};

interface TikTokPerctObj {
  total_play: TPerct | null;
  total_like: TPerct | null;
  follower: TPerct | null;
  total_comment: TPerct | null;
  video: TPerct | null;
  total_share: TPerct | null;
  total_saved: TPerct | null;
  following: TPerct | null;
}

interface TargetKey {
  [key: string]: TPerct | null;
}

export type ITikTokPerctObj = TikTokPerctObj & TargetKey;

interface ProfileWidgetsProps {
  formik: FormikProps<IFormDateFilter>;
}

type Props = ProfileWidgetsProps;

const ProfileWidgets: React.FC<Props> = ({ formik }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { apiEndpoint } = useHttp();

  const params = useParams();
  const config = useMemo(() => {
    let params = {
      date_min: moment(formik.values.start_min).format("YYYY-MM-DD"),
      date_max: moment(formik.values.start_max).format("YYYY-MM-DD"),
    };
    return { params };
  }, [formik]);

  const { data } = useSWR<TTikTokProfileWidgetData>(params && [apiEndpoint.TIKTOK_PROFILE_WIDGET(params.username), config]);

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const [percentageDiff, setPercentageDiff] = useState<ITikTokPerctObj | null>(null);
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    if (data) {
      function removeNullValues(obj: any): any {
        return Object.fromEntries(
          Object.entries(obj).filter(([_key, value]) => {
            return (value as any).count !== null;
          })
        );
      }

      function findEarliestAndLatest(data: DateData) {
        if (Object.keys(data).length === 0) return null;
        let earliestDate = null;
        let latestDate = null;

        for (const dateStr in data) {
          if (data.hasOwnProperty(dateStr)) {
            const currentDate = moment(dateStr);
            if (!earliestDate || currentDate.isBefore(earliestDate)) {
              earliestDate = currentDate;
            }

            if (!latestDate || currentDate.isAfter(latestDate)) {
              latestDate = currentDate;
            }
          }
        }

        const earliestData = earliestDate ? (data as any)[earliestDate.format("YYYY-MM-DD")] : null;
        const latestData = latestDate ? (data as any)[latestDate.format("YYYY-MM-DD")] : null;

        const earliestCount = earliestData?.count || 0; // Default to 0 if null

        return {
          earliestDate: moment(earliestDate).format("YYYY-MM-DD") ?? null,
          latestDate: moment(latestDate).format("YYYY-MM-DD") ?? null,
          earliestData: earliestData.count,
          latestData: latestData.count,
          difference: latestData.count - earliestCount,
          percentage: earliestData.count === 0 ? 100 : ((latestData.count - earliestData.count) / earliestData.count) * 100,
        };
      }
      function processResult(data: any, key: string) {
        const initializeValue = {
          earliestDate: moment().format("YYYY-MM-DD"),
          latestDate: moment().format("YYYY-MM-DD"),
          earliestData: 0,
          latestData: 0,
          difference: 0,
          percentage: 0,
        };

        const result =
          Object.keys(data.results).includes(key) && Object.keys(data.results[key]).length > 0
            ? findEarliestAndLatest(removeNullValues(data.results[key]))
            : initializeValue;
        return [key, result];
      }

      const keysToProcess = ["video", "total_play", "total_like", "total_comment", "follower", "total_saved", "total_share", "following"];

      const perctObj = Object.fromEntries(keysToProcess.map(key => processResult(data, key)));

      setPercentageDiff(perctObj);
    }
  }, [data]);

  /* ================================================== */
  /* ================================================== */

  const handleTotalPlayZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.total_play).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.total_play).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            total_play: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );

  const handleTotalSavedZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.total_saved).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.total_saved).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            total_saved: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );

  const handleTotalSharedZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.total_share).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.total_share).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            total_share: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );

  const handleTotalLikeZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.total_like).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.total_like).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            total_like: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );
  const handleTotalFollowerZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.follower).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.follower).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            follower: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );
  const handleTotalCommentZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.total_comment).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.total_comment).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            total_comment: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );
  const handleTotalVideoZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.video).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.video).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            post: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );
  const handleTotalFollowingZoom = useCallback(
    (zoomedData: any) => {
      let start = zoomedData.start;
      let end = zoomedData.end;
      if (zoomedData.hasOwnProperty("batch")) {
        start = zoomedData.batch[0].start;
        end = zoomedData.batch[0].end;
      }

      if (data && start !== undefined && end !== undefined) {
        const xAxisData = Object.keys(data.results.following).map(child => moment(child).format("DD MMM"));
        const yAxisData = Object.values(data.results.following).map(child => child.count);
        const startIndex = Math.floor((start / 100) * xAxisData.length);
        const endIndex = Math.floor((end / 100) * xAxisData.length);
        const newXAxisDataArray = xAxisData.slice(startIndex, endIndex + 1);
        const newYAxisDataArray = yAxisData.slice(startIndex, endIndex + 1).filter(child => child !== null);
        const earliestData = newYAxisDataArray[0];
        const latestData = newYAxisDataArray[newYAxisDataArray.length - 1];

        setPercentageDiff((prevState: any) => {
          return {
            ...prevState,
            following: {
              earliestDate: moment(newXAxisDataArray[0], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              latestDate: moment(newXAxisDataArray[newXAxisDataArray.length - 1], "DD MMM YY").format("YYYY-MM-DD") ?? null,
              earliestData: newYAxisDataArray[0],
              latestData: newYAxisDataArray[newYAxisDataArray.length - 1],
              difference: latestData - earliestData,
              percentage: earliestData === 0 ? 100 : ((latestData - earliestData) / earliestData) * 100,
            },
          };
        });
      }
    },
    [data]
  );

  return (
    <Paper>
      <ArrowOverflowBox>
        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={1} pt={2} px={2} mb={1}>
          <DateFilter formik={formik} />
        </Stack>
      </ArrowOverflowBox>
      {data && checkAllChildrenEmpty(data.results) ? (
        <Box sx={{ py: 2 }}>
          <NoDataFound image />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12} sm={6} lg={3}>
            {data && percentageDiff && (
              <ChartComponent
                targetKey={"total_play"}
                title={"Total Plays"}
                handleZoom={handleTotalPlayZoom}
                data={data}
                percentageDiff={percentageDiff}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {data && percentageDiff && (
              <ChartComponent
                targetKey={"total_like"}
                title={"Total Likes"}
                handleZoom={handleTotalLikeZoom}
                data={data}
                percentageDiff={percentageDiff}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {data && percentageDiff && (
              <ChartComponent
                targetKey={"follower"}
                title={"Followers"}
                handleZoom={handleTotalFollowerZoom}
                data={data}
                percentageDiff={percentageDiff}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {data && percentageDiff && (
              <ChartComponent
                targetKey={"total_comment"}
                title={"Total Comments"}
                handleZoom={handleTotalCommentZoom}
                data={data}
                percentageDiff={percentageDiff}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {data && percentageDiff && (
              <ChartComponent
                targetKey={"total_saved"}
                title={"Total Saved"}
                handleZoom={handleTotalSavedZoom}
                data={data}
                percentageDiff={percentageDiff}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {data && percentageDiff && (
              <ChartComponent
                targetKey={"total_share"}
                title={"Total Shared"}
                handleZoom={handleTotalSharedZoom}
                data={data}
                percentageDiff={percentageDiff}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            {data && percentageDiff && (
              <ChartComponent targetKey={"video"} title={"Videos"} handleZoom={handleTotalVideoZoom} data={data} percentageDiff={percentageDiff} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {data && percentageDiff && (
              <ChartComponent
                targetKey={"following"}
                title={"Following"}
                handleZoom={handleTotalFollowingZoom}
                data={data}
                percentageDiff={percentageDiff}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default ProfileWidgets;

interface IChartComponentProps {
  targetKey: keyof TikTokPerctObj;
  title: string;
  handleZoom: (event: any) => void;
  data: TTikTokProfileWidgetData;
  percentageDiff: ITikTokPerctObj;
}
const ChartComponent: React.FC<IChartComponentProps> = ({ targetKey, title, data, handleZoom, percentageDiff }) => {
  return (
    <Box className="widget__div">
      <Stack sx={{ pl: { xs: 5, lg: 1 } }}>
        <Stack direction="row" spacing={1}>
          <Typography variant="h6" fontWeight={500} color="text.secondary" sx={{ textAlign: "left" }}>
            {title}
          </Typography>
          {percentageDiff[targetKey] && (
            <PercentDiff difference={percentageDiff[targetKey]!.difference} percentage={percentageDiff[targetKey]!.percentage} />
          )}
        </Stack>
        <Typography variant="caption" color="text.secondary" textAlign={"left"}>
          {moment(percentageDiff?.[targetKey]?.earliestDate).format("DD MMM YY")}&nbsp;-&nbsp;
          {moment(percentageDiff?.[targetKey]?.latestDate).format("DD MMM YY")}
        </Typography>
      </Stack>
      <LineChart
        dataZoom
        color={tiktokLineColors[targetKey]}
        onDataZoom={handleZoom}
        xAxisData={Object.keys(data.results[targetKey]).map(child => moment(child).format("DD MMM"))}
        yAxisData={Object.values(data.results[targetKey]).map(child => child.count)}
      />
    </Box>
  );
};
