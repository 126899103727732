/* components */
/* 3rd party lib */
import React from "react";
import moment from "moment";
import { faPlus } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { faCircleInfo, faEye, faPen, faRefresh } from "@fortawesome/pro-solid-svg-icons";
import { Box, Button, darken, lighten, Paper, Stack, Tooltip, Typography, useTheme } from "@mui/material";
/* Util */
import { TSubscriptionPlan } from "src/types/common";
import { SUBSCRIPION_KEYS, subscriptionStatus } from "src/utils/constants";
import { nullUndefinedReturnDash } from "src/utils/general";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { ICreatePlanDialog, IEditDialog } from "src/pages/admin/subscription-plan-table";

interface PackageComponentProps {
  error: any;
  nextPlan?: boolean;
  readOnly?: boolean;
  customPaperStyle: any;
  currentPlanExist?: boolean;
  currentPlanExpired?: boolean;
  data?: TSubscriptionPlan;
  nextPlanData?: TSubscriptionPlan;
  setShowSubscriptionDialog?: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      log: string | null;
    }>
  >;
  setShowResetDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateDialogOpen?: React.Dispatch<React.SetStateAction<ICreatePlanDialog>>;
  setEditDialogOpen?: React.Dispatch<React.SetStateAction<IEditDialog>>;
}

type Props = PackageComponentProps;

const PackageComponent: React.FC<Props> = ({
  error,
  data,
  readOnly = false,
  customPaperStyle,
  nextPlan = false,
  currentPlanExpired,
  setShowSubscriptionDialog,
  setShowResetDialog,
  setCreateDialogOpen,
  setEditDialogOpen,
  currentPlanExist,
}) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { mode } = theme.palette;
  const { mdDown } = useMediaQueries();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  const divider = <Box sx={{ width: "100%", border: `1px solid ${(theme.palette.neutral as any)[300]}` }} />;
  const CustomBadge = ({ children }: { children: React.ReactNode }) => (
    <span
      style={{
        fontSize: "16px",
        textTransform: "capitalize",
        color: theme.palette.primary.main,
        fontWeight: 700,
      }}
    >
      {children}
    </span>
  );

  return (
    <>
      {data && !error ? (
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            width: "100%",
          }}
        >
          {data.subscription_status && (
            <Box
              sx={{
                zIndex: 2,
                top: "8px",
                right: "-8px",
                textAlign: "center",
                position: "absolute",
                borderRadius: "0px 0px 5px 5px",
                textTransform: "uppercase",
                color: "rgb(255, 255, 255)",
                background: data.subscription_status
                  ? darken(
                      subscriptionStatus(theme)[data.subscription_status].color,
                      mode === "dark" && data.subscription_status !== SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS ? 0.1 : 0
                    )
                  : lighten((theme.palette.neutral as any)[600], 0.3),
                clipPath: "polygon(0px 0px, 100% 0px, 100% 100%, 20% 100%)",
                minWidth: "100px",
              }}
            >
              <Box sx={{ padding: "4px 16px 4px 32px" }}>
                <Typography variant="body2" fontWeight={600}>
                  {subscriptionStatus(theme)[data.subscription_status].label}
                </Typography>
              </Box>
            </Box>
          )}

          {data.subscription_status && (
            <Box
              sx={{
                zIndex: -1,
                top: "4px",
                right: "-8px",
                padding: "4px 16px 4px 32px",
                position: "absolute",
                background: mode === "dark" ? "rgb(110, 113, 118)" : "rgb(156, 163, 175)",
                clipPath: "polygon(0px 0px, 100% 0px, 100% 100%, 20% 100%)",
                borderRadius: "0px 5px 5px 0px",
              }}
            />
          )}
          <Paper
            onClick={() => {
              if (nextPlan && setEditDialogOpen) {
                setEditDialogOpen({ open: true, data: data });
              }
            }}
            sx={{
              position: "relative",
              minHeight: "350px !important",
              ...customPaperStyle,
              padding: 1,
              cursor: nextPlan && "pointer",
              transition: "all 0.5s ease",
              background: nextPlan && mode === "light" ? lighten(customPaperStyle.background, 0.7) : customPaperStyle.background,
              "&:hover": {
                " .select__modal": {
                  display: "grid",
                },
                ".deleteButton": {
                  display: "grid",
                },
                ".nextPlanIcon": {
                  display: "flex",
                },
                transform: nextPlan && "translateY(-2px)",
                background: nextPlan && (mode === "light" ? customPaperStyle.background : lighten(customPaperStyle.background, 0.1)),
              },
            }}
          >
            {nextPlan && (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                className="nextPlanIcon"
                sx={{ display: "none", position: "absolute", right: "8px" }}
              >
                <Typography variant="body2" color="primary.main">
                  Edit
                </Typography>
                <FontAwesomeIcon color={theme.palette.primary.main} icon={faPen} />
              </Stack>
            )}
            {/* {nextPlan && (
            <Box
              className="select__modal"
              sx={{
                zIndex: 20,
                top: 1,
                left: 1,
                width: "100%",
                height: "100%",
                display: "none",
                borderRadius: "8px",
                position: "absolute",
                placeItems: "center",
                background: "rgba(0, 0, 0, 0.6)",
                transition: "all 0.5s ease",
              }}
            >
              <Button
                onClick={() => {
                  if (nextPlanData) setEditDialogOpen({ open: true, data: nextPlanData });
                }}
                variant="contained"
                color="primary"
                endIcon={<FontAwesomeIcon icon={faRefresh} />}
                sx={{ border: "1px solid white" }}
              >
                Change Plan
              </Button>
            </Box>
          )} */}
            {data && (
              <>
                <Box
                  sx={{
                    mt: 2.5,
                    mb: 3,
                    display: "-webkit-box",
                    overflow: "hidden",
                    // whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    "-webkit-box-orient": "vertical",
                    WebkitLineClamp: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    color={
                      nextPlan ? "warning.main" : data.subscription_status === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS ? "error.main" : "primary.main"
                    }
                  >
                    {data?.package.title}
                  </Typography>
                </Box>
                {divider}
                <Stack alignItems={"flex-end"} width="100%" sx={{ my: 2 }}>
                  <Box sx={{ width: "100%" }}>
                    <Stack
                      width="100%"
                      direction="row"
                      justifyContent={"space-between"}
                      alignItems="center"
                      sx={{
                        boxSizing: "border-box",
                        py: 0.5,
                        px: 1,
                        color: "white",
                        background: data.subscription_status
                          ? darken(
                              subscriptionStatus(theme)[data.subscription_status].color,
                              mode === "dark" && data.subscription_status !== SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS ? 0.1 : 0
                            )
                          : lighten((theme.palette.neutral as any)[600], 0.3),
                      }}
                    >
                      <Stack>
                        <Typography variant="body1" fontWeight={600}>
                          Durations
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography fontWeight={600} textAlign={"right"} variant="body1" sx={{ minWidth: "100px" }}>
                          Start Date
                        </Typography>
                        <Typography fontWeight={600} textAlign={"right"} variant="body1" sx={{ minWidth: "100px" }}>
                          End Date
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      width="100%"
                      direction="row"
                      justifyContent={"space-between"}
                      alignItems="center"
                      sx={{
                        boxSizing: "border-box",
                        px: 1,
                      }}
                    >
                      <Stack>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          color={data.subscription_status === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS ? "error.main" : ""}
                        >
                          {data.duration_days ?? "-"} Days
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          textAlign={"right"}
                          variant="body2"
                          fontWeight={600}
                          color={data.subscription_status === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS ? "error.main" : ""}
                          sx={{ minWidth: "100px" }}
                        >
                          {nullUndefinedReturnDash(moment(data.start_time).format("DD MMM YYYY"))}
                        </Typography>
                        <Typography
                          textAlign={"right"}
                          variant="body2"
                          fontWeight={600}
                          color={data.subscription_status === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS ? "error.main" : ""}
                          sx={{ minWidth: "100px" }}
                        >
                          {nullUndefinedReturnDash(moment(data.end_time).format("DD MMM YYYY"))}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>

                {divider}

                <Box sx={{ my: 2 }}>
                  <Stack spacing={2}>
                    <Stack>
                      <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                        <Typography variant="body1" fontWeight={600} textAlign={"left"}>
                          Available Slot
                        </Typography>
                        <Typography variant="body1" fontWeight={600} textAlign={"right"}>
                          {data.package.slot ?? "-"}
                        </Typography>
                      </Stack>

                      <Typography variant="caption" color="text.secondary" fontStyle={"italic"} textAlign="right">
                        <FontAwesomeIcon color={theme.palette.text.secondary} icon={faCircleInfo} />
                        &nbsp;
                        {data.package.slot ?? "-"}
                        &nbsp;available slots in total.
                      </Typography>
                    </Stack>

                    <Stack>
                      <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                        <Typography variant="body1" fontWeight={600} textAlign={"left"}>
                          Remaining Profile Swaps
                        </Typography>
                        <Typography variant="body1" fontWeight={600} textAlign={"right"}>
                          {!nextPlan && !readOnly && (
                            <Tooltip arrow title="Reset Profile Swaps">
                              <Button
                                onClick={() => setShowResetDialog && setShowResetDialog(true)}
                                variant="contained"
                                sx={{ padding: "4px", minWidth: "24px !important", minHeight: "24px" }}
                              >
                                <FontAwesomeIcon icon={faRefresh} />
                              </Button>
                            </Tooltip>
                          )}
                          &nbsp;{data.slot_change_remaining}
                        </Typography>
                      </Stack>

                      <Typography variant="caption" color="text.secondary" fontStyle={"italic"} textAlign="right">
                        Resetting on&nbsp;
                        {nullUndefinedReturnDash(moment(data.slot_reset_time).format("DD MMM YYYY")) ?? "-"}, {data.package.total_slot_change}
                        &nbsp;Profile Swaps
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>

                {divider}

                <Box sx={{ my: 2 }}>
                  <Stack alignItems="flex-start" sx={{ textAlign: "left" }}>
                    <Typography variant="body1" fontWeight={600}>
                      Description
                    </Typography>
                    <ul style={{ margin: "8px 0" }}>
                      <li>
                        <Typography variant="body2">
                          Slot Given&nbsp;-&nbsp;<CustomBadge>{data.package.slot}</CustomBadge>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Max Tracking Acc Change&nbsp;-&nbsp;
                          <CustomBadge>{data.package.total_slot_change}</CustomBadge>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Profile Comparison&nbsp;-&nbsp;
                          <CustomBadge>
                            {data.package.is_compare_profiles ? (
                              <FontAwesomeIcon color={theme.palette.success.main} icon={faCircleCheck} />
                            ) : (
                              <FontAwesomeIcon color={theme.palette.error.main} icon={faCircleXmark} />
                            )}
                          </CustomBadge>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Post Comparison&nbsp;-&nbsp;
                          <CustomBadge>
                            {data.package.is_compare_profiles ? (
                              <FontAwesomeIcon color={theme.palette.success.main} icon={faCircleCheck} />
                            ) : (
                              <FontAwesomeIcon color={theme.palette.error.main} icon={faCircleXmark} />
                            )}
                          </CustomBadge>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Date View Range&nbsp;-&nbsp;<CustomBadge>Last {data.package.max_day_range_limit} Days</CustomBadge>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Account Expiry&nbsp;-&nbsp;<CustomBadge>{data.package.duration_days} Days</CustomBadge>
                        </Typography>
                      </li>

                      <li style={{ marginTop: "18px" }}>
                        <Typography variant="body2">
                          Max Compare Profile Preset Limit&nbsp;-&nbsp;<CustomBadge>{data.package.max_compare_profile_limit}</CustomBadge>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Max Compare Post Preset Limit&nbsp;-&nbsp;<CustomBadge>{data.package.max_compare_post_limit}</CustomBadge>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Max Compare Preset&nbsp;-&nbsp;<CustomBadge>{data.package.max_preset_limit}</CustomBadge>
                        </Typography>
                      </li>
                    </ul>
                  </Stack>
                </Box>

                {divider}
                <Box sx={{ my: 2 }}>
                  <Stack alignItems="flex-start" sx={{ textAlign: "left", width: "100%" }}>
                    <Typography variant="body1" fontWeight={600}>
                      Remark
                    </Typography>
                    {data.remark ? (
                      <Tooltip title={data.remark}>
                        <Typography variant="body2" sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "100%" }}>
                          {data.remark}
                        </Typography>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </Stack>
                </Box>
                {divider}
                <Box sx={{ my: 2, width: "100%" }}>
                  <Stack width="100%" alignItems="flex-start" sx={{ textAlign: "left" }}>
                    <Typography variant="body1" fontWeight={600}>
                      Log
                    </Typography>
                    <Stack sx={{ width: "100%" }} direction="row" alignItems="center" spacing={1}>
                      {data.log ? (
                        <>
                          <Box
                            onClick={e => {
                              e.stopPropagation();
                              setShowSubscriptionDialog && setShowSubscriptionDialog({ open: true, log: data.log });
                            }}
                            sx={{
                              cursor: "pointer",
                              ".fa-eye": {
                                color: theme.palette.primary.main,
                                "&:hover": {
                                  color: theme.palette.primary.dark,
                                },
                                "&:active": {
                                  color: theme.palette.text.secondary,
                                },
                              },
                            }}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Box>
                          <Tooltip
                            arrow
                            placement="top-start"
                            title={
                              <Typography variant={"body2"}>
                                <div style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: data.log }} />
                              </Typography>
                            }
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                width: "100%",
                              }}
                            >
                              {data.log}
                            </Typography>
                          </Tooltip>
                        </>
                      ) : (
                        "-"
                      )}
                    </Stack>
                  </Stack>
                </Box>
              </>
            )}
          </Paper>
        </Box>
      ) : (
        <>
          {error && error.response && error.response.status === 404 ? (
            <Paper
              onClick={() => {
                if (currentPlanExist && !currentPlanExpired) {
                  setCreateDialogOpen && setCreateDialogOpen({ open: true, planType: "choose" });
                } else {
                  // if current plan doesnt exist then show the immediate upgrade page
                  setCreateDialogOpen && setCreateDialogOpen({ open: true, planType: "immediate" });
                }
              }}
              sx={{
                ...customPaperStyle,
                width: "100%",
                padding: mdDown ? 4 : 1,
                height: "100%",
                minHeight: "200px",
                display: "grid",
                placeItems: "center",
                cursor: "pointer",
                userSelect: "none",
                transition: "all 0.2s ease",

                "&:hover": {
                  background: theme.palette.primary.main,
                  "& .MuiTypography-root": {
                    color: "white",
                  },
                  "& .fa-plus": {
                    color: "white",
                  },
                },
                "&:active": {
                  transition: "none",
                  background: customPaperStyle.background,
                  "& .MuiTypography-root": {
                    color: theme.palette.primary.main,
                  },
                  "& .fa-plus": {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            >
              <Stack
                spacing={mdDown ? 0.5 : 2}
                sx={{ width: "80%", transform: mdDown ? "none" : !currentPlanExpired && currentPlanExist ? "translateY(-30px)" : "none" }}
              >
                <Box>
                  <Typography variant="h6" fontWeight={500} color="primary.main">
                    Click to Add / Upgrade Plan
                  </Typography>
                </Box>
                <FontAwesomeIcon size="4x" icon={faPlus} color={theme.palette.primary.main} />
              </Stack>
            </Paper>
          ) : (
            "Loading"
          )}
        </>
      )}
    </>
  );
};

export default PackageComponent;
