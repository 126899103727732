import React, { useEffect } from "react";
/* components */
/* 3rd party lib */
import useSWR from "swr";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faLink } from "@fortawesome/pro-regular-svg-icons";
import { Box, Grid, Link, Paper, Stack, Typography, useTheme } from "@mui/material";
/* Util */
import useHttp from "src/hooks/use-http";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { TInstaProfilePost, TInstaData } from "src/types/instagram";
import routes from "src/routes";
import InstaProfilePostCard from "src/components/comparison/profiles/instagram/profile-post-card";

interface ProfilePostBannerProps {
  setShow404?: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = ProfilePostBannerProps;

const ProfilePostBanner: React.FC<Props> = ({ setShow404 }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const params = useParams();
  const theme = useTheme();
  const { mode } = theme.palette;
  const { apiEndpoint } = useHttp();
  const { smDown } = useMediaQueries();
  const { username, post_id } = params;
  const navigate = useNavigate();
  const { data } = useSWR<TInstaData>(params && [apiEndpoint.INSTAGRAM_PROFILE(username)]);
  const { data: postData, error } = useSWR<TInstaProfilePost>(params && [`${apiEndpoint.INSTAGRAM_PROFILE_POST_DETAIL(username, post_id)}`]);

  const rowData = postData?.title;

  const title = rowData?.replace(/\n/g, "<br/> ");
  // Split the description into words
  const words = title?.split(/\s+/);

  // Define a function to convert hashtags to links
  function convertHashtagsToLinks(word: string) {
    if (word.startsWith("#")) {
      const hashtag = word.slice(1); // Remove the '#' character
      return `<a style="color:${theme.palette.primary.main}" href="/profile/instagram/${username}/hashtags/${hashtag}">#${hashtag}</a>`;
    }
    return word;
  }

  // Map the words, converting hashtags to links where needed
  const convertedWords = words?.map(convertHashtagsToLinks);

  // Join the words back into a single string
  const convertedTitle = convertedWords?.join(" ");

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (setShow404 && error && error.response && (error.response.status === 404 || error.response.status === 500)) {
      setShow404(true);
    }
  }, [error, setShow404]);
  /* ================================================== */
  /* ================================================== */
  return (
    <Paper elevation={5} sx={{ width: "100%", overflow: "hidden" }}>
      <Grid container>
        <Grid item xs={1.5} sm={0.8}>
          <Stack
            sx={{
              cursor: "pointer",
              height: "100%",
              display: "grid",
              placeItems: "center",
              transition: "all 0.5s ease",
              borderRight: smDown ? `1px solid ${(theme.palette as any).neutral[200]}` : "none",
              "&:hover": {
                background: mode === "light" ? (theme.palette as any).neutral[200] : (theme.palette as any).neutral[700],
              },
            }}
            onClick={() => navigate(`${routes.INSTA_PROFILE}/${username}`)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Stack>
        </Grid>
        <Grid item xs={10.5} sm={11.2}>
          <Stack direction={smDown ? "column" : "row"} alignItems="center" p={2} spacing={2}>
            <Stack direction={smDown ? "column" : "row"} alignItems="center" spacing={4}>
              <Stack>
                {postData && (
                  <Link
                    onClick={() => window.open(postData.insta_link, "_blank")}
                    // href={routes.SIGNUP}
                    href={postData.insta_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="body1"
                    color="primary.main"
                    underline="hover"
                    sx={{ whiteSpace: "nowwrap", cursor: "pointer" }}
                  >
                    <Box>{postData && <InstaProfilePostCard disableClick data={postData} username={username} />}</Box>

                    <Box pt={1}>
                      <FontAwesomeIcon icon={faLink} /> Link
                    </Box>
                  </Link>
                )}
              </Stack>

              <Stack sx={{ textAlign: "left" }}>
                {data && (
                  <Link
                    onClick={e => {
                      e.stopPropagation();
                      window.open(`${routes.INSTA_PROFILE}/${data.username}`, "_blank");
                    }}
                    href={`${routes.INSTA_PROFILE}/${data.username}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    variant="caption"
                    color="primary.main"
                    underline="always"
                    sx={{ whiteSpace: "nowwrap", cursor: "pointer" }}
                  >
                    <Typography variant="h6" fontWeight={500} color="primary.main">
                      {data ? data.display_name : "-"}
                    </Typography>
                    {data ? (
                      <Typography variant="body2" fontWeight={300} color="primary.main">
                        @{data.username} {data.category_name ? ` | ${data.category_name}` : null}
                      </Typography>
                    ) : (
                      "-"
                    )}
                  </Link>
                )}

                {convertedTitle && (
                  <Typography variant={smDown ? "caption" : "body2"}>
                    <div style={{ whiteSpace: "normal" }} dangerouslySetInnerHTML={{ __html: convertedTitle }} />
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProfilePostBanner;
