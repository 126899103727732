import { createSlice } from "@reduxjs/toolkit";
import { TSocialMedia } from "src/types/common";
import { IProfileTab } from "src/utils/constants";

export interface GeneralRootState {
  sort: any;
  filters: any;
  search: string | null;
  theme: "light" | "dark";
  socialMedia: TSocialMedia;
  tutorialInProgress: boolean;
  muiTab: IProfileTab[keyof IProfileTab];
  dateFilter: { start_min: string | null; start_max: string | null };
}

export const initialState: GeneralRootState = {
  sort: null,
  filters: null,
  search: null,
  tutorialInProgress: false,
  muiTab: "OVERVIEW",
  theme: "dark",
  socialMedia: "instagram",
  dateFilter: {
    start_min: null,
    start_max: null,
  },
};

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setMuiTab: (state, action) => {
      state.muiTab = action.payload;
    },
    setSocialMedia: (state, action) => {
      state.socialMedia = action.payload;
    },

    setSort: (state, action) => {
      let sort = { ...(state.sort as any) };
      const key = action.payload;

      if (!sort[key]) {
        sort[key] = "asc";
      } else {
        if (sort[key] === "desc") {
          delete sort[key];
        } else {
          sort[key] = "desc";
        }
      }

      if (Object.keys(sort).length === 0) {
        sort = null;
      }

      state.sort = sort;
    },

    setSortDNDTable: (state, action) => {
      const sortArray = action.payload;

      const resultObj = {};

      sortArray.forEach((item: any) => {
        const value = item.desc ? "desc" : "asc";
        (resultObj as any)[item.id] = value;
      });

      state.sort = resultObj;
    },
    setFilters: (state, action) => {
      const filters = { ...action.payload };

      // Remove empty values
      Object.keys(filters).forEach(key => {
        if (!filters[key]) delete filters[key];
      });

      if (Object.keys(filters).length === 0) {
        state.filters = null;
      } else {
        state.filters = filters;
      }
    },
    resetSort: state => {
      state.sort = null;
    },
    resetFilters: state => {
      state.filters = null;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSortWithOrder: (state, action) => {
      state.sort = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
      localStorage.setItem("theme", action.payload);
    },
    setDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
    setTutorialInProgress: (state, action) => {
      state.tutorialInProgress = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setMuiTab,
  setDateFilter,
  setSocialMedia,
  setSort,
  setTheme,
  setSortDNDTable,
  setFilters,
  resetSort,
  resetFilters,
  setSearch,
  setSortWithOrder,
  setTutorialInProgress,
} = slice.actions;
