import { useState, useEffect, useRef } from "react";

export const useResizeObserver = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const observedDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!observedDivRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (observedDivRef.current && observedDivRef.current.offsetWidth !== width) {
        setWidth(observedDivRef.current.offsetWidth);
      }
      if (observedDivRef.current && observedDivRef.current.offsetHeight !== height) {
        setHeight(observedDivRef.current.offsetHeight);
      }
    });

    resizeObserver.observe(observedDivRef.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [width, height]);

  return { width, height, observedDivRef };
};
