import React from "react";
/* components */
/* 3rd party lib */
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faStar as faStarLight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, SxProps, useTheme } from "@mui/material";
/* Util */
import { TInstaData } from "src/types/instagram";
import { TTikTokData } from "src/types/tiktok";

interface FavouriteToggleProps {
  sx?: SxProps;
  height?: string;
  data: TInstaData | TTikTokData;
  handleUpdateFavourite: () => void;
}

type Props = FavouriteToggleProps;

const FavouriteToggle: React.FC<Props> = ({ sx, data, height = "100px", handleUpdateFavourite }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Box
        sx={{ cursor: "pointer", height: height, color: theme.palette.primary.main, display: "grid", placeItems: "center", ...sx }}
        onClick={e => {
          e.stopPropagation();
          handleUpdateFavourite();
        }}
      >
        {data.favourite ? (
          <FontAwesomeIcon icon={faStar} color={theme.palette.primary.main} size="xl" />
        ) : (
          <FontAwesomeIcon icon={faStarLight} color={theme.palette.primary.main} size="xl" />
        )}
      </Box>
    </>
  );
};

export default FavouriteToggle;
