import { Box, Button, Chip, darken, lighten, Paper, Popover, Stack, Tab, Tabs, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import React, { useMemo, useRef, useState, useCallback } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DataTableList from "src/components/common/data-display/data-table-list";
import { NUMBER_CELL_TRANSLATE, showActionWhenHovered } from "src/components/common/data-display/dnd-table";
import CreateAccountDialog from "src/components/common/dialog/create-account-dialog";
import OuterLayout from "src/components/common/layout/outer-layout";
import useHttp from "src/hooks/use-http";
import routes from "src/routes";
import * as Yup from "yup";
import { ISubscriptionStatusData, TSubscriptionAccount } from "src/types/common";
import { SUBSCRIPION_KEYS, subscriptionStatus, TABLE_ID } from "src/utils/constants";
import { checkObjectNotEmpty, getDynamicIndex, nullUndefinedReturnDash } from "src/utils/general";
import { getTranslateString } from "src/utils/translate";
import { FORM_WARNING_REQUIRED, FORM_WARNING_VALID_EMAIL } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import SubscriptionDropdown from "src/components/common/inputs/subscription-dropdown";
import CountrySelect from "src/components/common/inputs/country-select";
import useSWR from "swr";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
/* components */
/* 3rd party lib */

/* Util */
import { industries } from "src/components/common/inputs/industries-select";

export interface IFormikSuperadminFilter {
  subscription_status: { label: string; value: string };
  country: { code: string; label: string };
}

export interface ICreateAccountFormValues {
  account_number: string;
  account_status: boolean;
  country: string;
  email: string;
  name: string;
  remark: string;
  phone_num: string;
  company_name: string;
}

const phoneRegExp = /^\+?(?:[0-9]●?){6,14}[0-9]$/;

const schema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().required(getTranslateString(t, FORM_WARNING_REQUIRED)),
    email: Yup.string().email(getTranslateString(t, FORM_WARNING_VALID_EMAIL)),
    phone_num: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  });

interface SuperadminProps {}

type Props = SuperadminProps;

const Superadmin: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mdDown } = useMediaQueries();
  const { http, apiEndpoint } = useHttp();
  const dataTableListRef = useRef<any | null>(null);
  const [createAccountDialog, setCreateAccountDialog] = useState(false);
  const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState<keyof ISubscriptionStatusData | null>(null);

  const [checkPagination, setCheckPagination] = useState({ currentPage: 0, rowsPerPage: 0 });
  const [value, setValue] = useState<{ label: string; value: string } | undefined | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<{ label: string; code: string } | undefined | null>(null);
  const { data } = useSWR<ISubscriptionStatusData>([apiEndpoint.SUBSCRIPTION_ACCOUNT, { params: { group_by: "subscription_status" } }]);

  const handleCreateUser = async (values: ICreateAccountFormValues) => {
    try {
      await http.post(apiEndpoint.SUBSCRIPTION_ACCOUNT, JSON.stringify(values));
      toast.success("User Created Successfully");
      if (dataTableListRef.current) {
        dataTableListRef.current.refreshData();
      }
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong when creating user");
    }
  };

  const formik = useFormik<ICreateAccountFormValues>({
    initialValues: {
      account_number: "",
      account_status: true,
      country: "SG",
      email: "",
      name: "",
      remark: "",
      phone_num: "",
      company_name: "",
    },
    validationSchema: schema(t),
    validateOnChange: false,
    onSubmit: (values, { resetForm }) => {
      handleCreateUser(values);
      setCreateAccountDialog(false);
      resetForm();
    },
  });

  const filterFormik = useFormik<IFormikSuperadminFilter>({
    initialValues: {
      subscription_status: { label: "", value: "" },
      country: { code: "", label: "" },
    },
    validateOnChange: false,
    onSubmit: (values, { resetForm }) => {},
  });

  const subscriptionColor = useCallback(
    (data: TSubscriptionAccount) => {
      if (!data.subscription || (data.subscription && !data.subscription.subscription_status)) return (theme.palette.grey as any)[400];
      return data.subscription.subscription_status.toLowerCase() === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS
        ? theme.palette.error.main
        : data.subscription.subscription_status.toLowerCase() === SUBSCRIPION_KEYS.ACTIVE_SUBSCRIPTIONS
        ? theme.palette.success.main
        : theme.palette.warning.main;
    },
    [theme]
  );

  const subscriptionKeys = ["expiring_soon", "active_subscriptions", "expired_subscriptions", "none"];

  const columnDefinition: MRT_ColumnDef<{}>[] = useMemo(
    () => [
      {
        accessorKey: "no",
        header: "#",
        size: 0,
        muiTableHeadCellProps: {
          sx: {
            ...NUMBER_CELL_TRANSLATE,
          },
        },
        enablePinning: false,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableColumnDragging: false,

        Cell: ({ row }) => {
          const { currentPage, rowsPerPage } = checkPagination;
          return (
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
              {getDynamicIndex(currentPage - 1, rowsPerPage, row.index + 1)}.
            </Typography>
          );
        },
      },

      {
        accessorKey: "subscription_status",
        header: "",
        size: 0,
        enableColumnFilter: false,
        enablePinning: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableColumnDragging: false,
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionAccount;

          return (
            <Stack sx={{ display: "grid", placeItems: "center" }}>
              <Tooltip
                title={
                  <Typography variant="body2" textTransform={"capitalize"} fontWeight={500}>
                    {rowData.subscription && rowData.subscription.subscription_status
                      ? subscriptionStatus(theme)[rowData.subscription.subscription_status].label
                      : "None"}
                  </Typography>
                }
              >
                <Box sx={{ width: "15px", height: "15px", borderRadius: "50%", background: subscriptionColor(rowData) }} />
              </Tooltip>
            </Stack>
          );
        },
      },

      {
        accessorKey: "name",
        header: "Name",
        size: 120,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
      },

      {
        accessorKey: "email",
        header: "Email",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
      },

      {
        accessorKey: "subscription_package_name",
        header: "Package",
        size: 100,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionAccount;
          return (
            rowData.subscription &&
            checkObjectNotEmpty(rowData.subscription) && (
              <Typography variant="body2" color="text.secondary">
                {rowData.subscription.package.title}
              </Typography>
            )
          );
        },
      },

      {
        accessorKey: "subscription_start_time",
        header: "Start Time",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        size: 120,
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionAccount;
          return (
            rowData.subscription &&
            checkObjectNotEmpty(rowData.subscription) && (
              <Typography variant="body2" color="text.secondary">
                {moment(rowData.subscription.start_time).format("DD MMM YYYY")}
              </Typography>
            )
          );
        },
      },

      {
        accessorKey: "is_subscribed",
        header: "Status",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionAccount;

          let chipStyle = {
            borderRadius: "8px !important",
            height: `22px !important`,
            color: "white",
          };

          return (
            rowData &&
            rowData.subscription && (
              <>
                {rowData.subscription.is_trial ? (
                  <Chip
                    label={`Trial ends ${rowData.subscription.end_time && moment(rowData.subscription.end_time).format("MMM DD")}`}
                    sx={{ background: `${theme.palette.primary.main} !important`, ...chipStyle }}
                  />
                ) : (
                  <>
                    {rowData.is_subscribed ? (
                      <Chip
                        label={`Active, Renew on ${rowData.subscription.end_time && moment(rowData.subscription.end_time).format("MMM DD")}`}
                        sx={{ background: `${theme.palette.success.main} !important`, ...chipStyle }}
                      />
                    ) : (
                      <Chip
                        label={
                          moment().isBefore(rowData.subscription.end_time)
                            ? `Cancels ${rowData.subscription.end_time && moment(rowData.subscription.end_time).format("MMM DD")}`
                            : `Cancelled`
                        }
                        sx={{
                          background: `${(theme.palette.grey as any)[theme.palette.mode === "light" ? 400 : 600]} !important`,
                          ...chipStyle,
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )
          );
        },
      },

      {
        accessorKey: "subscription_end_time",
        header: "End Time",
        size: 120,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionAccount;
          return (
            rowData.subscription &&
            checkObjectNotEmpty(rowData.subscription) && (
              <Typography variant="body2" color="text.secondary">
                {moment(rowData.subscription.end_time).format("DD MMM YYYY")}
              </Typography>
            )
          );
        },
      },
      {
        accessorKey: "day_left",
        header: "Day(s) Left",
        enableColumnFilter: false,
        size: 120,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionAccount;
          return (
            rowData.subscription &&
            checkObjectNotEmpty(rowData.subscription) && (
              <Typography variant="body2" color="text.secondary">
                {rowData.subscription.day_left}
              </Typography>
            )
          );
        },
      },

      {
        accessorKey: "account_number",
        header: "Acc No.",
        size: 100,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
      },

      {
        accessorKey: "account_status",
        header: "Account Status",

        size: 100,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: { ...showActionWhenHovered(2, 2) },
        },
        Filter: ({ header }) => {
          return (
            <>
              <SubscriptionDropdown formik={filterFormik} value={value} setValue={setValue} />
            </>
          );
        },

        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionAccount;
          return (
            <Typography variant="body2" fontWeight={500} color={rowData.account_status === true ? "success.main" : "error.main"}>
              {rowData.account_status ? "Active" : "Inactive"}
            </Typography>
          );
        },
      },

      {
        accessorKey: "company_name",
        header: "Company",
        size: 120,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
      },
      {
        accessorKey: "phone_num",
        header: "Phone No.",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        size: 120,
      },
      {
        accessorKey: "country",
        header: "Country",
        size: 110,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Filter: ({ header }) => {
          return <CountrySelect formik={filterFormik} value={selectedCountry} setValue={setSelectedCountry} />;
        },
      },
      {
        accessorKey: "industry",
        header: "Industry",
        size: 110,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },

        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionAccount;
          return (
            <Typography variant="body2" color="text.secondary">
              {industries.find(child => child.value === rowData.industry)?.label ?? rowData.industry}
            </Typography>
          );
        },
      },

      {
        accessorKey: "last_login",
        header: "Last Login",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionAccount;
          return (
            rowData.subscription &&
            checkObjectNotEmpty(rowData.subscription) && (
              <Typography variant="body2" color="text.secondary">
                {nullUndefinedReturnDash(moment(rowData.last_login).format("DD MMM YYYY (hh:mm A)"))}
              </Typography>
            )
          );
        },
      },

      // {
      //   accessorKey: "tier",
      //   header: "Tier Plan",
      // },
      {
        accessorKey: "date_opened",
        header: "Created on",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionAccount;
          return (
            rowData.date_opened && (
              <Typography variant="body2" color="text.secondary">
                {moment(rowData.date_opened).format("DD MMM YYYY (hh:mm A)")}
              </Typography>
            )
          );
        },
      },

      {
        accessorKey: "remark",
        header: "Remark",
        muiTableHeadCellProps: {
          sx: {
            ...showActionWhenHovered(2, 2),
          },
        },
      },
    ],
    [value, theme, subscriptionColor, filterFormik, selectedCountry, checkPagination]
  );

  const handleNavigate = (data: TSubscriptionAccount) => {
    navigate(`${routes.SUPERADMIN_USERS}/${data.id}`);
  };

  const listMetadata = {
    primary: {
      path: "name",
      content: (data: TSubscriptionAccount) => {
        return (
          <Typography variant="h6" color="primary.main" fontSize="18px">
            {data.name}
          </Typography>
        );
      },
    },
    sortFields: [],
    fields: [
      {
        path: "subscription.package.title",
        label: "Package",
      },
      {
        path: "is_subscribed",
        label: "Status",
        content: (data: TSubscriptionAccount) => {
          return (
            data &&
            checkObjectNotEmpty(data.is_subscribed) && (
              <Typography variant="body2" color="text.secondary">
                {data.is_subscribed}
              </Typography>
            )
          );
        },
      },
      {
        path: "days_left",
        label: "Days Left",
        content: (data: TSubscriptionAccount) => {
          return (
            data.subscription &&
            checkObjectNotEmpty(data.subscription) && (
              <Typography variant="body2" color="text.secondary">
                {data.subscription.day_left}
              </Typography>
            )
          );
        },
      },
      {
        path: "account_status",
        label: "Status",
        content: (data: TSubscriptionAccount) => {
          return (
            <>
              {data && data.subscription && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box sx={{ width: "15px", height: "15px", borderRadius: "50%", background: subscriptionColor(data) }} />

                  {data.subscription.subscription_status && (
                    <Typography
                      textTransform={"capitalize"}
                      variant="body2"
                      fontWeight={500}
                      color={subscriptionStatus(theme)[data.subscription.subscription_status].color}
                    >
                      {subscriptionStatus(theme)[data.subscription.subscription_status].label}
                    </Typography>
                  )}
                </Stack>
              )}
            </>
          );
        },
      },
    ],
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <OuterLayout maxWidth={"xl"}>
        <Stack spacing={2}>
          <Paper elevation={5} sx={{ width: "100%", overflow: "hidden" }}>
            <Stack direction="row" alignItems="center" justifyContent={"space-between"} sx={{ py: 1, px: 2 }}>
              <Typography textAlign="left" variant="h6" fontWeight={600} color="text.secondary">
                Account Management
              </Typography>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Button variant="contained" onClick={() => setCreateAccountDialog(true)}>
                  Create account
                </Button>
              </Stack>
            </Stack>
          </Paper>

          <Box>
            <Paper elevation={10} sx={{ p: 2, mt: 1 }}>
              {mdDown && (
                <FilterTab
                  data={data}
                  theme={theme}
                  subscriptionKeys={subscriptionKeys}
                  currentSubscriptionStatus={currentSubscriptionStatus}
                  setCurrentSubscriptionStatus={setCurrentSubscriptionStatus}
                />
              )}
              <DataTableList
                mobilePaginationStickBottom={false}
                columnVisibility={{ account_status: false }}
                customActions={
                  <Stack direction="row" alignItems={"center"}>
                    {!mdDown && (
                      <FilterTab
                        data={data}
                        theme={theme}
                        subscriptionKeys={subscriptionKeys}
                        currentSubscriptionStatus={currentSubscriptionStatus}
                        setCurrentSubscriptionStatus={setCurrentSubscriptionStatus}
                      />
                    )}
                  </Stack>
                }
                customFilters={{
                  subscription_status: currentSubscriptionStatus,
                  country: filterFormik.values?.country?.code ?? null,
                }}
                id={TABLE_ID.SUBSCRIPTION_ACCOUNTS}
                columns={columnDefinition}
                columnOrder={columnDefinition.map(c => c.accessorKey)}
                setCheckPagination={setCheckPagination}
                listMetadata={listMetadata}
                showGlobalFilter
                rowClick
                dataTableListRef={dataTableListRef}
                rowAction={(rowData: TSubscriptionAccount) => handleNavigate(rowData)}
                search={{
                  key: "q",
                  label: "Search",
                }}
                listItemStyle={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "#f4f4f4c0",
                  },
                }}
                url={apiEndpoint.SUBSCRIPTION_ACCOUNT}
              />
            </Paper>
          </Box>
        </Stack>
      </OuterLayout>
      <CreateAccountDialog
        formik={formik}
        open={createAccountDialog}
        onClose={() => {
          formik.resetForm();
          setCreateAccountDialog(false);
        }}
      />
    </>
  );
};

export default Superadmin;

interface IFilterTab {
  theme: Theme;
  data: ISubscriptionStatusData | undefined;
  currentSubscriptionStatus: null | keyof ISubscriptionStatusData;
  setCurrentSubscriptionStatus: React.Dispatch<React.SetStateAction<null | keyof ISubscriptionStatusData>>;
  subscriptionKeys: string[];
}

const FilterTab = ({ data, theme, currentSubscriptionStatus, setCurrentSubscriptionStatus, subscriptionKeys }: IFilterTab) => {
  const [anchorEl, setAnchorEl] = React.useState<{ el: HTMLElement; title: string } | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, child: string) => {
    setAnchorEl({ el: event.currentTarget, title: child });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl?.el);

  return (
    <>
      {data && (
        <Tabs
          variant="scrollable"
          TabIndicatorProps={{ sx: { display: "none" } }}
          value={currentSubscriptionStatus}
          onChange={(e, value) => {
            setCurrentSubscriptionStatus(value);
          }}
          sx={{
            "& .MuiTabs-flexContainer": {
              gap: "8px",
            },
            "&.MuiTabs-root": {
              minHeight: "unset",
            },
          }}
        >
          {subscriptionKeys.map(child => {
            return (
              <Tab
                onClick={() => {
                  if (child === currentSubscriptionStatus) {
                    setCurrentSubscriptionStatus(null);
                  }
                }}
                onMouseEnter={e => handlePopoverOpen(e, child)}
                onMouseLeave={handlePopoverClose}
                sx={{
                  alignItems: "center",
                  padding: 0,
                  minHeight: "unset",
                  minWidth: "unset",
                  border: `2px solid transparent`,
                  "&.Mui-selected": {
                    borderRadius: "8px",
                    border: `2px solid ${child === currentSubscriptionStatus ? subscriptionStatus(theme)[child].color : "transparent"} !important`,
                  },
                }}
                value={child}
                label={
                  <Stack direction="row">
                    <Stack
                      sx={{
                        padding: "8px 14px 8px 16px",
                        borderRadius: "4px 0 0 4px",
                        color: theme.palette.text.secondary,
                        background: lighten(subscriptionStatus(theme)[child].color, 0.7),
                      }}
                    >
                      <Typography fontWeight={600} variant="body2" color={darken(subscriptionStatus(theme)[child].color, 0.15)}>
                        {subscriptionStatus(theme)[child].label}
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        color: "white",
                        borderRadius: "0 4px 4px 0",
                        background: subscriptionStatus(theme)[child].color,
                        px: 1,
                        fontSize: "20px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      {(data as any)[child]}
                    </Box>
                  </Stack>
                }
                wrapped
              />
            );
          })}
          <Tab
            value={null}
            sx={{
              visibility: "hidden",
              userSelect: "none",
              pointerEvents: "none",
              p: 0,
              height: "0",
              width: "0",
              minHeight: "0",
              minWidth: "0",
            }}
          />
        </Tabs>
      )}

      <Popover
        id="account-status-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl?.el}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {anchorEl && (
          <Stack direction="row" alignItems={"center"} sx={{ px: 1 }}>
            <FontAwesomeIcon icon={faCircleInfo} color={theme.palette.primary.main} />

            <Typography variant="body2" sx={{ p: 1 }}>
              {subscriptionStatus(theme)[anchorEl.title].info}
            </Typography>
          </Stack>
        )}
      </Popover>
    </>
  );
};
