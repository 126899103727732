import { Box, Container, Paper, styled, useTheme } from "@mui/material";
import TopNavbar from "src/components/common/navigation/top-navbar";
import PageFooter from "src/components/common/layout/page-footer";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root-reducer";

type TMaxWidth = "xs" | "sm" | "md" | "lg" | "xl" | false;

interface RootProps {
  disablePadding: boolean;
  maxWidth: TMaxWidth;
}

const StyledContainer = styled(Container, { shouldForwardProp: props => props !== "disablePadding" })<RootProps>(({ theme, disablePadding }) => {
  return (disablePadding && { height: "100%", padding: 0, [theme.breakpoints.down("lg")]: { padding: 0, height: "1000%" } }) || { height: "100%" };
});

interface OuterLayoutProps {
  children: React.ReactNode;
  maxWidth?: TMaxWidth;
  disablePadding?: boolean;
  background?: string;
}

type Props = OuterLayoutProps;

const OuterLayout: React.FC<Props> = ({ children, maxWidth = "lg", disablePadding = false }) => {
  const theme = useTheme();
  const { navbar } = useSelector((state: RootState) => state.layout);
  const { tutorialInProgress } = useSelector((state: RootState) => state.general);

  return (
    <>
      <TopNavbar />
      <Box
        component="main"
        sx={{
          minHeight: `calc(100vh - ${navbar.height + 128}px)`,
          mt: tutorialInProgress ? 0 : 7,
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          py: 3,
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            py: 2,
          },
        }}
      >
        <StyledContainer disablePadding={disablePadding} maxWidth={maxWidth === false ? false : maxWidth}>
          {children}
        </StyledContainer>
      </Box>
      <Paper sx={{ borderRadius: 0 }}>
        <StyledContainer disablePadding={disablePadding} maxWidth={"lg"}>
          <PageFooter />
        </StyledContainer>
      </Paper>
    </>
  );
};

export default OuterLayout;
