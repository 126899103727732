/* components */
/* 3rd party lib */
import React from "react";
import { Link, Stack, Typography } from "@mui/material";
/* Util */
import routes from "src/routes";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface PageFooterProps {
  copyrightNextLine?: boolean;
}

type Props = PageFooterProps;

const PageFooter: React.FC<Props> = ({ copyrightNextLine }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { smDown, mdDown, lgDown } = useMediaQueries();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <footer>
      <Stack
        direction={mdDown || (lgDown && copyrightNextLine) ? "column" : "row"}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ pt: 4, pb: 4 }}
        spacing={1}
      >
        <Stack direction={smDown ? "column" : "row"} alignItems="center" spacing={smDown ? 0.5 : 2}>
          <Link rel="noopener noreferrer" component="a" href={routes.INFO_PRIVACY} variant={"body1"} color="primary.main">
            Privacy Policy
          </Link>
          <Link rel="noopener noreferrer" component="a" href={routes.INFO_TERMS} variant={"body1"} color="primary.main">
            Terms of Service
          </Link>
          <Link
            rel="noopener noreferrer"
            component="a"
            href={`mailto:contact@gointec.io?Subject=${encodeURIComponent("Inquir")}`}
            variant={"body1"}
            color="primary.main"
          >
            Contact Us
          </Link>
          {/* <Link href={routes.INFO_ABOUT} variant={"body1"} color="primary.main">
            About
          </Link> */}
          <Link rel="noopener noreferrer" component="a" href={routes.PRICING} variant={"body1"} color="primary.main">
            Pricing
          </Link>
          <Link rel="noopener noreferrer" component="a" href={routes.INFO_FAQ} variant={"body1"} color="primary.main">
            FAQ
          </Link>
        </Stack>
        <Typography variant="body1" color="text.secondary">
          &copy; 2024 goIntec. All rights reserved.
        </Typography>
      </Stack>
    </footer>
  );
};

export default PageFooter;
