import React, { useEffect, useMemo, useState } from "react";
/* components */
import ProfileComparisonCharts from "src/components/comparison/profiles/tiktok/profile-charts";
import ProfilePresetSelectionList from "src/components/comparison/profiles/preset-selection-list";
/* 3rd party lib */
import useSWR from "swr";
import moment from "moment";
import { Box, Grid, Stack, Tab, Tabs, Theme, Typography, useTheme } from "@mui/material";
/* Util */
import "src/styles/comparison.scss";
import useHttp from "src/hooks/use-http";
import useMediaQueries from "src/hooks/use-mediaqueries";
import ComparisonTable from "src/components/comparison/profiles/tiktok/comparison-table";
import { ICompareTikTokProfileJsonData } from "src/types/tiktok";
import { useComparisonProfileContext } from "src/contexts/comparison-profile-context";
import ProfileTopPosts from "src/components/comparison/profiles/tiktok/profile-top-posts";
import useBackground from "src/hooks/use-background";
import ProfileComparisonSortDnd from "src/components/comparison/profiles/comparison-sort-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faGrid } from "@fortawesome/pro-solid-svg-icons";
import { IProfileAvatarGroup } from "src/types/common";
import { IMultiProfile, setProfileAvatarGroup, setMultiProfile } from "src/slices/compare";
import { useDispatch } from "react-redux";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
type TCompareTab = "charts" | "posts";

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  target: TCompareTab;
  theme: Theme;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, theme, target, ...other } = props;

  const background = useBackground({});

  return (
    <div
      role="tabpanel"
      hidden={value !== target}
      id={`simple-tabpanel-${target}`}
      aria-labelledby={`simple-tab-${target}`}
      {...other}
      style={{
        background: background,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {value === target && <Box sx={{ p: 1, width: "100%", minWidth: "300px" }}>{children}</Box>}
    </div>
  );
}

interface ComparisonByProfilesProps {}

export interface ITikTokBarData {
  [key: string]: string[] | (number | null)[] | string;
  usernames: string[];
  xAxis: string[];
  images: string[];
  count_follower: (number | null)[];
  count_following: (number | null)[];
  count_video: (number | null)[];
  total_comment: (number | null)[];
  total_play: (number | null)[];
  total_like: (number | null)[];
  total_saved: (number | null)[];
  total_share: (number | null)[];
  engagement_rate: (number | null)[];
}

type Props = ComparisonByProfilesProps;

const TiktokCompare: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const dispatch = useDispatch();
  const { lgUp } = useMediaQueries();
  const [view, setView] = useState<TCompareTab>("charts");
  const {
    formik,
    presetData,
    tableHeight,
    profileExist,
    addMoreDisabled,
    minCompare,
    addMoreDialog,
    profileDialog,
    currentActive,
    profileUsernames,
    openSortProfile,
    handleEditPreset,
    setOpenSortProfile,
    setProfileUsernames,
  } = useComparisonProfileContext();
  const [barChartData, setBarChartData] = useState<ITikTokBarData | null>(null);

  const { apiEndpoint } = useHttp();

  const config = useMemo(() => {
    const maxIsLessThanMin = moment(formik.values.start_max).isBefore(moment(formik.values.start_min));
    const minIsGreaterThanMax = moment(formik.values.start_min).isAfter(moment(formik.values.start_max));

    let params = {
      usernames_in: profileUsernames.length > 0 ? profileUsernames.join(",") : "",
      date_min: moment(minIsGreaterThanMax ? formik.values.start_max : formik.values.start_min).format("YYYY-MM-DD"),
      date_max: moment(maxIsLessThanMin ? formik.values.start_min : formik.values.start_max).format("YYYY-MM-DD"),
    };
    return {
      params: params,
    };
  }, [formik, profileUsernames]);

  const { data } = useSWR<ICompareTikTokProfileJsonData>(profileUsernames.length > 0 && [apiEndpoint.TIKTOK_COMPARE_PROFILE_STATISTICS, config]);

  const [statisticData, setStatisticData] = useState<ICompareTikTokProfileJsonData | undefined>(undefined);

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleDeleteProfile = (index: number) => {
    const removeProfileUsername = (profileUsernames: string[]) => {
      let temp = profileUsernames.slice();
      if (profileUsernames.length > minCompare) {
        // have to actually remove the item instead of set to null if it's more than 3
        temp.splice(index, 1);
      } else {
        temp[index] = "";
      }
      return temp;
    };

    setProfileUsernames(prevState => {
      const temp = removeProfileUsername(prevState);
      return temp;
    });

    if (currentActive.preset) {
      const tmpProfileUsernames = removeProfileUsername(currentActive.preset.profiles.map(child => child.username)).filter(child => child !== "");
      handleEditPreset(currentActive.preset.id, currentActive.preset.title, tmpProfileUsernames);
    }
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (data) {
      setStatisticData(data);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      let result: IProfileAvatarGroup[] = [];
      let tmpUsernames: IMultiProfile = {};
      Object.values(data.results).forEach(child => {
        result.push({
          username: child.profile.username,
          display_name: child.profile.display_name,
          downloaded_image: child.profile.downloaded_image,
        });

        tmpUsernames[child.profile.username] = child.profile.username;
      });
      if (!currentActive.preset) {
        dispatch(setProfileAvatarGroup(result.reverse()));
      }
      dispatch(setMultiProfile(tmpUsernames));
    }
  }, [data, dispatch, currentActive, addMoreDialog, profileDialog]);

  useEffect(() => {
    // if preset exist, follow the reverse order of the preset
    if (presetData && currentActive.preset && (addMoreDialog || profileDialog.open)) {
      const rearrangedAvatarGroup = presetData.find(child => child.id === currentActive.preset?.id)?.profiles;

      if (rearrangedAvatarGroup) {
        dispatch(setProfileAvatarGroup(rearrangedAvatarGroup.slice().reverse()));
      }
    }
  }, [dispatch, presetData, currentActive, addMoreDialog, profileDialog.open]);

  useEffect(() => {
    let result: ITikTokBarData = {
      usernames: [],
      xAxis: [],
      images: [],
      count_follower: [],
      count_following: [],
      count_video: [],
      total_comment: [],
      total_play: [],
      total_like: [],
      total_saved: [],
      total_share: [],
      engagement_rate: [],
    };

    if (statisticData) {
      profileUsernames.forEach(child => {
        if (child) {
          const profileObj = statisticData.results[child];

          if (!profileObj) return;

          result.xAxis.push(child);
          result.usernames.push(profileObj.profile.username);
          result.images.push(profileObj.profile.downloaded_image);
          result.total_play.push(profileObj.date_last.total_play ?? null);
          result.total_like.push(profileObj.date_last.total_like ?? null);
          result.total_comment.push(profileObj.date_last.total_comment ?? null);
          result.total_saved.push(profileObj.date_last.total_saved ?? null);
          result.total_share.push(profileObj.date_last.total_share ?? null);
          result.count_follower.push(profileObj.date_last.count_follower ?? null);
          result.count_following.push(profileObj.date_last.count_following ?? null);
          result.count_video.push(profileObj.date_last.count_video ?? null);
          result.engagement_rate.push(profileObj.profile.engagement_stats.median_engagement_rate ?? null);
        }
      });

      setBarChartData(result);
    }
  }, [statisticData, profileUsernames]);

  /* ================================================== */
  /* ================================== ================ */

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={10}>
        <Box
          sx={{
            pr: {
              xs: 0,
              sm: 0,
              lg: !addMoreDisabled ? 3.5 : 0,
            },
          }}
        >
          <ComparisonTable profileData={statisticData} onDeleteProfile={handleDeleteProfile} />
        </Box>
      </Grid>
      {lgUp && (
        <Grid item xs={12} lg={2}>
          <ProfilePresetSelectionList tableHeight={tableHeight} />
        </Grid>
      )}

      <Grid item xs={12}>
        {profileExist && (
          <>
            <ComparisonTab
              tab={view}
              handleChangeTab={(event: any, nextView: TCompareTab) => {
                if (nextView) {
                  setView(nextView);
                }
              }}
              theme={theme}
            />
            <CustomTabPanel theme={theme} value={view} target={"charts"}>
              <ProfileComparisonCharts barChartData={barChartData} />
            </CustomTabPanel>
            <CustomTabPanel theme={theme} value={view} target={"posts"}>
              <Grid item xs={12}>
                <ProfileTopPosts statisticData={statisticData} profileUsernames={profileUsernames} />
              </Grid>
            </CustomTabPanel>
          </>
        )}
      </Grid>

      <ProfileComparisonSortDnd profileData={statisticData} open={openSortProfile} onClose={() => setOpenSortProfile(false)} />
    </Grid>
  );
};

export default TiktokCompare;

const ComparisonTab = ({
  tab,
  handleChangeTab,
  theme,
}: {
  tab: TCompareTab;
  handleChangeTab: (event: React.SyntheticEvent, newValue: TCompareTab) => void;
  theme: Theme;
}) => {
  const { smDown } = useMediaQueries();
  return (
    <Stack
      direction="row"
      justifyContent={"center"}
      sx={{ borderRadius: "8px 8px 0 0", background: theme.palette.mode === "light" ? "white" : (theme.palette.neutral as any)[900], width: "100%" }}
    >
      <Tabs variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChangeTab} aria-label="wrapped label tabs example" sx={{ pb: 1 }}>
        <Tab
          {...a11yProps(0)}
          value={"charts"}
          label={
            <Stack direction="row" alignItems={"center"} spacing={1} className="active-step6">
              <FontAwesomeIcon icon={faChartBar} size={smDown ? "lg" : "xl"} />

              <Typography variant={"body1"} fontWeight={500} color={tab === "charts" ? theme.palette.primary.main : theme.palette.text.secondary}>
                Charts
              </Typography>
            </Stack>
          }
        />
        <Tab
          {...a11yProps(1)}
          value={"posts"}
          label={
            <Stack direction="row" alignItems={"center"} spacing={1} className="active-step7">
              <FontAwesomeIcon icon={faGrid} size={smDown ? "lg" : "xl"} />

              <Typography variant={"body1"} fontWeight={500} color={tab === "posts" ? theme.palette.primary.main : theme.palette.text.secondary}>
                Posts
              </Typography>
            </Stack>
          }
        />
      </Tabs>
    </Stack>
  );
};
