import React, { useEffect, useState } from "react";
/* components */
/* 3rd party lib */
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { faFileCirclePlus } from "@fortawesome/pro-thin-svg-icons";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { Box, Button, Collapse, Grid, List, ListItem, ListItemButton, Paper, Stack, Theme, Typography, useTheme } from "@mui/material";
/* Util */
import useMediaQueries from "src/hooks/use-mediaqueries";
import { vibratingAnimation } from "src/utils/constants";
import { useStrictDroppable } from "src/hooks/use-strict-droppable";
import { IPostPresetData, IProfilePresetData } from "src/types/instagram";
import { ICrudPresetDialog, ICurrentActivePostPreset } from "src/pages/comparisons/posts/posts";
import { ICurrentActiveProfilePreset } from "src/pages/comparisons/profiles/profiles";
import { TCollapse } from "src/contexts/comparison-profile-context";
import { TTriggerVibration } from "src/types/common";
import { RootState } from "src/store/root-reducer";
import { checkObjectNotEmpty } from "src/utils/general";

interface PresetSelectionListProps {
  tableHeight?: number;
  collapse: TCollapse;

  triggerVibration: TTriggerVibration;
  setCollapse: React.Dispatch<React.SetStateAction<TCollapse>>;
  currentActive: ICurrentActiveProfilePreset | ICurrentActivePostPreset;
  presetData: IPostPresetData[] | IProfilePresetData[];
  handlePresetClick: (index: number, preset: IPostPresetData | IProfilePresetData) => void;
  handleEditPreset: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, preset: IPostPresetData | IProfilePresetData) => void;
  setPresetDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setCrudPresetDialog: React.Dispatch<React.SetStateAction<ICrudPresetDialog>>;
  setCurrentActive: React.Dispatch<React.SetStateAction<ICurrentActiveProfilePreset | ICurrentActivePostPreset>>;
}

type Props = PresetSelectionListProps;

const PresetSelectionList: React.FC<Props> = ({
  collapse,
  setCollapse,
  tableHeight,
  presetData,
  currentActive,
  triggerVibration,
  setPresetDialog,
  setCrudPresetDialog,
  handlePresetClick,
  handleEditPreset,
  setCurrentActive,
}) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();

  const { mode } = theme.palette;
  const { lgDown, xlDown } = useMediaQueries();
  const { user } = useSelector((state: RootState) => state.auth);
  const [tempPresetData, setTempPresetData] = useState<IProfilePresetData[] | IPostPresetData[] | null>(null);
  let animation = triggerVibration === "preset" ? vibratingAnimation : {};
  const [enabled] = useStrictDroppable(!!!presetData);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !!!tempPresetData || (tempPresetData && tempPresetData.length === 0)) return;

    let items: any[] = [];
    items = Array.from(tempPresetData as any);

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem as any);

    /* this part is to maintain the preset selection highlight even after rearranged */
    // this is to get the object before reordering
    if (currentActive.preset) {
      const currentActiveObj = (tempPresetData as any).find((child: any) => child.id === currentActive.preset?.id);
      if (currentActiveObj) {
        // then we get the new index after reordering
        const activeIndexAfterRearranged = items.findIndex(child => child.id === currentActiveObj.id);
        setCurrentActive({ index: activeIndexAfterRearranged, preset: items[activeIndexAfterRearranged] });
      }
    }
    setTempPresetData(items as any);
  };

  useEffect(() => {
    if (presetData) {
      setTempPresetData(presetData);
    }
  }, [presetData]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Paper
      elevation={5}
      sx={{
        ...animation,
        p: xlDown ? 0.5 : 2,
        height: tableHeight ? (xlDown ? `calc(${tableHeight}px - 8px)` : `calc(${tableHeight}px - 32px)`) : "100%",
      }}
    >
      {presetData && presetData.length > 0 && (
        <Box sx={{ p: xlDown ? 1.5 : 0 }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={
              (presetData && user && checkObjectNotEmpty(user.subscription) && user.subscription.package.max_preset_limit === presetData.length) ??
              false
            }
            className="step-2"
            onClick={() => setCrudPresetDialog({ create: true, update: false })}
            size="small"
          >
            <Typography
              variant="body1"
              color={
                presetData && user && checkObjectNotEmpty(user.subscription) && user.subscription.package.max_preset_limit === presetData.length
                  ? mode === "dark"
                    ? "neutral.500"
                    : "grey.400"
                  : "white"
              }
            >
              {/* <Typography variant="body1"> */}
              Create Preset
            </Typography>
          </Button>
        </Box>
      )}

      {presetData && presetData.length > 0 ? (
        <Box
          sx={{
            height: tableHeight ? `calc(${tableHeight}px - 78px)` : "100%",
            overflow: "auto",
            mt: 1.5,

            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: theme.palette.primary.main,
              borderRadius: "1rem",
            },

            "&::-webkit-scrollbar-thumb:hover": {
              background: theme.palette.primary.light,
            },
          }}
        >
          <DragDropContext onDragEnd={handleDragEnd}>
            {enabled && (
              <Droppable droppableId="preset-list">
                {provided => (
                  <List {...provided.droppableProps} ref={provided.innerRef} sx={{ p: 0 }}>
                    {collapse &&
                      tempPresetData &&
                      tempPresetData.map((preset, index) => {
                        return (
                          <Draggable key={`row${preset.id}`} draggableId={`row${preset.id}`} index={index}>
                            {(provided, snapshot) => {
                              let isDraggingBackground = mode === "dark" ? "#000000" : (theme.palette as any).neutral[300];

                              return (
                                <ListItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    position: "relative",
                                    "& .fa-pen": {
                                      display: lgDown ? "block" : "none",
                                    },
                                    "&:not(:first-of-type)": {
                                      borderTop: `1px solid ${theme.palette.divider}`,
                                    },
                                    "&:hover": {
                                      "& .iconWrapper": {
                                        position: "absolute",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        p: 1,
                                        // top: "2px",
                                        right: "0",
                                        "& .fa-pen": {
                                          display: "block",
                                          color: currentActive.preset?.id === preset.id ? "#d8d8d8" : theme.palette.text.secondary,
                                        },
                                        "&:hover": {
                                          "& .fa-pen": {
                                            color: currentActive.preset?.id === preset.id ? "#ffffff" : theme.palette.primary.main,
                                          },
                                        },
                                        "&:active": {
                                          "& .fa-pen": {
                                            color: currentActive.preset?.id === preset.id ? "#ffffff" : theme.palette.primary.main,
                                          },
                                        },
                                      },
                                    },
                                    background: snapshot.isDragging
                                      ? isDraggingBackground
                                      : currentActive.preset?.id === preset.id
                                      ? theme.palette.primary.dark
                                      : mode === "light"
                                      ? "white"
                                      : "",
                                  }}
                                  onClick={() => handlePresetClick(index, preset)}
                                  disablePadding
                                >
                                  <ListItemButton sx={{ px: 1 }}>
                                    <Stack width="100%">
                                      <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                          <CollapsedToggleButton
                                            theme={theme}
                                            currentActive={currentActive}
                                            index={index}
                                            setCollapse={setCollapse}
                                            collapse={collapse}
                                            preset={preset}
                                          />

                                          <Typography
                                            variant="body1"
                                            fontWeight={500}
                                            color={currentActive.preset?.id === preset.id ? "white" : "text.secondary"}
                                          >
                                            {preset && preset.title ? preset.title : ` Preset ${index + 1}`}
                                          </Typography>
                                        </Stack>
                                        <Box className="iconWrapper" onClick={e => handleEditPreset(e, preset)}>
                                          <FontAwesomeIcon icon={faPen} />
                                        </Box>
                                      </Stack>
                                      <CollapsedComponent currentActive={currentActive} index={index} preset={preset} collapse={collapse} />
                                    </Stack>
                                  </ListItemButton>
                                </ListItem>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            )}
          </DragDropContext>
        </Box>
      ) : (
        <Box sx={{ height: "calc(100% - 100px)", display: "grid", placeItems: "center", p: 2 }}>
          <Stack spacing={1}>
            <FontAwesomeIcon icon={faFileCirclePlus} color={theme.palette.text.secondary} size="3x" />
            <Typography variant="body2" color="text.secondary">
              The list is currently empty, create one to get started.
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                (presetData && user && checkObjectNotEmpty(user.subscription) && user.subscription.package.max_preset_limit === presetData.length) ??
                false
              }
              className="step-2"
              onClick={() => setCrudPresetDialog({ create: true, update: false })}
              size="small"
            >
              <Typography
                variant="body1"
                color={
                  presetData && user && checkObjectNotEmpty(user.subscription) && user.subscription.package.max_preset_limit === presetData.length
                    ? mode === "dark"
                      ? "neutral.500"
                      : "grey.400"
                    : "white"
                }
              >
                {/* <Typography variant="body1"> */}
                Create Preset
              </Typography>
            </Button>
          </Stack>
        </Box>
      )}
    </Paper>
  );
};

export default PresetSelectionList;

interface ICollapsedToggleButton {
  currentActive: ICurrentActivePostPreset | ICurrentActiveProfilePreset;
  index: number;
  theme: Theme;
  preset: IProfilePresetData | IPostPresetData;
  setCollapse: React.Dispatch<React.SetStateAction<TCollapse>>;
  collapse: TCollapse;
}

const CollapsedToggleButton = ({ theme, currentActive, index, setCollapse, collapse, preset }: ICollapsedToggleButton) => {
  return (
    <Box
      onClick={e => {
        e.stopPropagation();
        setCollapse({ ...collapse, [preset.id]: collapse && !collapse[preset.id] });
      }}
      sx={{
        cursor: "pointer",
        borderRadius: "4px",
        display: "grid",
        placeItems: "center",
        height: "20px",
        width: "20px",
        border: `1px solid ${(theme.palette as any).neutral[200]}`,
        "&:hover": {
          transition: "all 0.3s ease",
          background: theme.palette.primary.main,
          "& .fa-angle-down": {
            color: "white",
          },
        },
        "&:active": {
          transition: "none",
          background: "white",
          "& .fa-angle-down": {
            color: theme.palette.primary.main,
          },
        },
        "& .fa-angle-down": {
          transition: "all 0.3s ease",
          transform: `rotate(${collapse && collapse[preset.id] ? 0 : -90}deg)`,
        },
      }}
    >
      <FontAwesomeIcon
        icon={faAngleDown}
        color={currentActive.preset?.id === preset.id ? "white" : theme.palette.text.secondary}
        style={{ padding: 0, lineHeight: 0 }}
      />
    </Box>
  );
};

interface ICollapsedComponent {
  currentActive: ICurrentActivePostPreset | ICurrentActiveProfilePreset;
  index: number;
  preset: IProfilePresetData | IPostPresetData;
  collapse: {
    [key: string]: boolean;
  };
}

const CollapsedComponent = ({ index, currentActive, collapse, preset }: ICollapsedComponent) => {
  return (
    <Collapse in={collapse[preset.id]}>
      {preset && (
        <Box sx={{ mt: 0.5 }}>
          {"posts" in preset
            ? preset.posts.map(
                (child, child_index) =>
                  child && (
                    <React.Fragment key={`profile_preset_${child_index}`}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={"auto"} md={1}>
                          <Box
                            sx={{
                              borderRadius: "2px",
                              overflow: "hidden",
                              border: "1px solid #dcdcdc",
                              width: "20px",
                              height: "20px",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            <img
                              alt={child.downloaded_image}
                              src={child.downloaded_image}
                              style={{ width: "20px", height: "20px", objectFit: "cover" }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={"auto"} md={11}>
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: currentActive.preset?.id === preset.id ? "white" : "text.secondary",
                            }}
                          >
                            <Typography variant="caption" color={currentActive.preset?.id === preset.id ? "white" : "text.secondary"} pl={0.5}>
                              &nbsp;@{child.profile_username}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )
              )
            : preset.profiles.map(
                (child, child_index) =>
                  child && (
                    <React.Fragment key={`profile_preset_${child_index}`}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={"auto"} md={1}>
                          <Box
                            sx={{
                              borderRadius: "50%",
                              overflow: "hidden",
                              border: "1px solid #dcdcdc",
                              width: "20px",
                              height: "20px",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            <img
                              alt={child.downloaded_image}
                              src={child.downloaded_image}
                              style={{ width: "20px", height: "20px", objectFit: "cover" }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={"auto"} md={11}>
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: currentActive.preset?.id === preset.id ? "white" : "text.secondary",
                            }}
                          >
                            <Typography variant="caption" color={currentActive.preset?.id === preset.id ? "white" : "text.secondary"} pl={0.5}>
                              &nbsp;@{child.username}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )
              )}
        </Box>
      )}
    </Collapse>
  );
};
