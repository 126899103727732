/* components */
/* 3rd party lib */
import React, { useEffect } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
/* Util */
import useMediaQueries from "src/hooks/use-mediaqueries";
import DataDisplay from "../inputs/data-display";
import { useFormik } from "formik";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { getTranslateString } from "src/utils/translate";
import { BUTTON_CANCEL, TOAST_ERROR_WENT_WRONG } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import useHttp from "src/hooks/use-http";
import useSWR, { KeyedMutator } from "swr";
import { ISubscriptionPlanData, TSubscriptionSlotReset } from "src/types/common";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "react-hot-toast";

interface RootProps {
  maxWidth?: number | string;
}
const StyledDateTextField = styled(TextField, { shouldForwardProp: props => props !== "disablePadding" })<RootProps>(({ maxWidth }) => ({
  "& .MuiInputBase-input": {
    paddingBottom: "9px !important",
    paddingTop: "8px !important",
    maxWidth: maxWidth,
  },
}));

interface IFormValues {
  overwrite: boolean;
  duration_days: number;
  slot_next_reset_time: string;
}

interface ResetSlotDialogProps {
  open: boolean;
  onClose: () => void;
  mutate: KeyedMutator<ISubscriptionPlanData>;
  subscriptionPlanData?: ISubscriptionPlanData;
}

type Props = ResetSlotDialogProps;

const ResetSlotDialog: React.FC<Props> = ({ open, onClose, mutate, subscriptionPlanData }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const params = useParams();
  const { user_id } = params;
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const { http, apiEndpoint } = useHttp();
  const { data, mutate: slotResetMutate } = useSWR<TSubscriptionSlotReset>(
    subscriptionPlanData &&
      subscriptionPlanData.results.length > 0 && [apiEndpoint.SUBSCRIPTION_SLOT_RESET(user_id, subscriptionPlanData.results[0].id)]
  );

  const handleReset = async (values: null | IFormValues = null) => {
    try {
      if (subscriptionPlanData && subscriptionPlanData.results.length > 0) {
        let config = values?.overwrite
          ? {
              overwrite_days: values.overwrite,
              days_left: values.duration_days,
              slot_next_reset_time: moment(values.slot_next_reset_time).format("YYYY-MM-DD"),
            }
          : { overwrite_days: false };

        await http.post(apiEndpoint.SUBSCRIPTION_SLOT_RESET(user_id, subscriptionPlanData.results[0].id), config);
        toast.success("Reset Profile Swaps Successfuly");
      }
      mutate();
      slotResetMutate();
      onClose();
    } catch (error) {
      console.error({ error });
      toast.error(getTranslateString(t, TOAST_ERROR_WENT_WRONG));
    }
  };

  const formik = useFormik<IFormValues>({
    validateOnChange: false,
    initialValues: {
      overwrite: false,
      duration_days: data ? data.days_left_for_slot_next_reset_time : 0,
      slot_next_reset_time: moment().format("YYYY-MM-DD"),
    },
    onSubmit: values => {
      if (values.overwrite) {
        handleReset({ overwrite: values.overwrite, duration_days: values.duration_days, slot_next_reset_time: values.slot_next_reset_time });
      } else {
        handleReset();
      }
    },
  });

  const { values } = formik;
  /* ================================================== */
  /*  method */
  /* ================================================== */

  const getDurationDifference = (start: string, end: string) => {
    return Math.abs(moment(start).diff(moment(end), "days"));
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (data) {
      formik.setValues({
        overwrite: false,
        duration_days: data.days_left_for_slot_next_reset_time,
        slot_next_reset_time: data.slot_next_reset_time,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="inherit">Reset Profile Swaps</Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <form id="reset_slot_form" onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={{
            ...(theme as any).custom.scroll,
          }}
        >
          <Paper sx={{ p: 2 }} elevation={5}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DataDisplay
                  labelGridWidth={5}
                  contentGridWidth={7}
                  label={"Plan"}
                  variant={smDown ? "caption" : "body2"}
                  value={<Typography variant={"body2"}>{data?.package.name}</Typography>}
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  labelGridWidth={5}
                  contentGridWidth={7}
                  label={"Profile Swaps count"}
                  variant={smDown ? "caption" : "body2"}
                  value={<Typography variant={"body2"}>{data?.package.slot_changes}</Typography>}
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  labelGridWidth={5}
                  contentGridWidth={7}
                  label={"Plan Duration"}
                  variant={smDown ? "caption" : "body2"}
                  value={<Typography variant={"body2"}>{data?.package.duration_days} Days</Typography>}
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  labelGridWidth={5}
                  contentGridWidth={7}
                  label={"Remaining Profile Swaps"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <Stack direction={"row"} alignItems="center" spacing={1}>
                      <Typography variant={"body2"} sx={{ textDecoration: "line-through" }}>
                        {data?.slot_change_remaining}
                      </Typography>
                      <FontAwesomeIcon icon={faArrowRight} />
                      <Typography variant={"body2"}>{data?.package.slot_changes}</Typography>
                    </Stack>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  labelGridWidth={5}
                  contentGridWidth={7}
                  fontWeight={600}
                  label={"Overwrite Profile Swaps"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <Checkbox
                      onChange={e => {
                        formik.setFieldValue("overwrite", e.target.checked);
                      }}
                      size="small"
                      value={formik.values.overwrite}
                      sx={{ p: 0 }}
                    />
                  }
                />

                {/* <FormControlLabel
                  labelPlacement="start"
                  onChange={formik.handleChange}
                  name="overwrite"
                  control={<Checkbox size="small" value={formik.values.overwrite} sx={{ py: 0 }} />}
                  label={
                    <Typography fontWeight="bolder" variant={smDown ? "caption" : "body2"}>
                      Overwrite Profile Swaps Renewal Date
                    </Typography>
                  }
                  sx={{ fontSize: "14px", marginLeft: 0 }}
                /> */}
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  labelGridWidth={5}
                  contentGridWidth={7}
                  label={"Next Renewal Date"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <MobileDatePicker
                      closeOnSelect
                      mask="__-__-____"
                      // name="start_min"
                      disabled={!values.overwrite}
                      value={values.slot_next_reset_time}
                      inputFormat={"DD-MM-YYYY"}
                      minDate={moment().format("YYYY-MM-DD")}
                      onChange={date => {
                        formik.setFieldValue("slot_next_reset_time", moment(date).format("YYYY-MM-DD"));
                        if (date) {
                          formik.setFieldValue("duration_days", getDurationDifference(date, moment().format("YYYY-MM-DD")));
                        }
                      }}
                      renderInput={params => (
                        <StyledDateTextField
                          {...params}
                          disabled={!values.overwrite}
                          error={false}
                          variant="outlined"
                          name="start_min"
                          hiddenLabel
                          value={values.slot_next_reset_time ? moment(values.slot_next_reset_time).format("YYYY-MM-DD") : null}
                          placeholder={smDown ? "Start Date" : "Click to enter start date"}
                        />
                      )}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  labelGridWidth={5}
                  contentGridWidth={7}
                  label={"Days left to renewal"}
                  variant={smDown ? "caption" : "body2"}
                  value={
                    <TextField
                      value={values.duration_days}
                      type="number"
                      variant="outlined"
                      hiddenLabel
                      name="duration_days"
                      size="small"
                      disabled={!values.overwrite}
                      placeholder="Enter duration"
                      onChange={e => {
                        formik.setFieldValue("duration_days", e.target.value);
                        formik.setFieldValue("slot_next_reset_time", moment().add(e.target.value, "days"));
                      }}
                      error={formik.errors.duration_days ? true : false}
                      helperText={formik.errors?.duration_days}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} size="small">
            {getTranslateString(t, BUTTON_CANCEL)}
          </Button>
          <Button form="reset_slot_form" type="submit" variant="contained" color="primary" fullWidth size="small">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ResetSlotDialog;
