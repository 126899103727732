const ROOT_PATH = "/";
const LOGIN_PATH = "/login";

const routes = {
  ROOT: ROOT_PATH,
  LOGIN: LOGIN_PATH,
  HOME: "/home",
  SIGNUP: "/signup",
  VERIFY: "/verify",
  LISTING: "/listing",
  SEARCH: "/search",
  PAYMENT: "/payment",
  PRICING: "/pricing",
  INFO_FAQ: "/info/faq",
  MY_ACCOUNT: "/account",
  INFO_TERMS: "/info/terms",
  INFO_ABOUT: "/info/about",
  SUPERADMIN: "/superadmin",
  FAVOURITES: "/favourites",
  INFO_PRIVACY: "/info/privacy",
  CONFIRM_EMAIL: "/confirm-email",
  TIKTOK_PROFILE: "/profile/tiktok",
  INSTA_PROFILE: "/profile/instagram",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  COMPARISON_POSTS: "/comparison/posts",
  SUPERADMIN_USERS: "/superadmin/users",
  PAYMENT_COMPLETE: "/payment/complete",
  COMPARISON_PROFILES: "/comparison/profiles",
};

export default routes;
