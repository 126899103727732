/* components */
import DataTableList from "src/components/common/data-display/data-table-list";
/* 3rd party lib */
import moment from "moment";
import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { Box, Paper, Typography } from "@mui/material";
/* Util */
import useHttp from "src/hooks/use-http";
import { TABLE_ID } from "src/utils/constants";
import { TSubscriptionTrackingRecord } from "src/types/common";
import { showActionWhenHovered } from "src/components/common/data-display/dnd-table";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface TrackingStatusTableProps {
  user_id?: string;
}

type Props = TrackingStatusTableProps;

const TrackingStatusTable: React.FC<Props> = ({ user_id }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { apiEndpoint } = useHttp();
  const { smDown } = useMediaQueries();
  const trackingColDefinition: MRT_ColumnDef<{}>[] = useMemo(
    () => [
      // {
      //   accessorKey: "no",
      //   header: "#",
      //   size: 0,
      //   muiTableHeadCellProps: {
      //     sx: {
      //       ...NUMBER_CELL_TRANSLATE,
      //     },
      //   },
      //   enablePinning: false,
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnFilter: false,
      //   enableColumnOrdering: false,
      //   enableColumnDragging: false,

      //   Cell: ({ row }) => {
      //     const { currentPage, rowsPerPage } = checkTrackingPagination;
      //     return (
      //       <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
      //         {getDynamicIndex(currentPage - 1, rowsPerPage, row.index + 1)}.
      //       </Typography>
      //     );
      //   },
      // },
      // {
      //   accessorKey: "id",
      //   header: "ID",
      //   size: 55,
      //   muiTableHeadCellProps: {
      //     sx: showActionWhenHovered(2, 2),
      //   },
      // },

      // {
      //   accessorKey: "end",
      //   header: "Expired By",
      //   muiTableHeadCellProps: {
      //     sx: showActionWhenHovered(2, 2),
      //   },
      //   Cell: ({ row }) => {
      //     const rowData = row.original as TSubscriptionTrackingRecord;
      //     return (
      //       rowData.end && (
      //         <Typography variant="body2" color="text.secondary">
      //           {moment(rowData.end).format("DD/MM/YYYY hh:mm A")}
      //         </Typography>
      //       )
      //     );
      //   },
      // },
      {
        accessorKey: "description",
        header: "Description",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
      },

      {
        accessorKey: "start",
        header: "Start From",
        size: 80,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionTrackingRecord;
          return (
            rowData.start && (
              <Typography variant="body2" color="text.secondary">
                {moment(rowData.start).format("DD MMM YYYY (hh:mm A)")}
              </Typography>
            )
          );
        },
      },
    ],
    []
  );

  const trackingMetaData = {
    primary: {
      path: "id",
      content: (rowData: TSubscriptionTrackingRecord) => (
        <Typography variant="h6" color="primary.main" fontWeight={500}>
          Tracking #{rowData.id}
        </Typography>
      ),
    },
    sortFields: [],
    fields: [
      // {
      //   path: "id",
      //   label: "ID",
      // },
      {
        path: "start",
        label: "Start From",
        content: (rowData: TSubscriptionTrackingRecord) =>
          rowData.start && (
            <Typography variant="body2" color="text.secondary">
              {moment(rowData.start).format("DD MMM YYYY (hh:mm A)")}
            </Typography>
          ),
      },
      {
        path: "description",
        label: "Description",
      },
    ],
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Paper elevation={10} sx={{ width: "100%" }}>
      <Box sx={{ p: smDown ? 2 : 4 }}>
        <Typography sx={{ mb: smDown ? 2 : 4 }} textAlign="left" variant="h5" fontWeight={500} color="primary.main">
          Tracking Status
        </Typography>

        <DataTableList
          mobilePaginationStickBottom={false}
          id={TABLE_ID.ACCOUNT_TRACKING_STATUS}
          columns={trackingColDefinition}
          columnOrder={trackingColDefinition.map(c => c.accessorKey)}
          listMetadata={trackingMetaData}
          showGlobalFilter
          search={{
            key: "q",
            label: "Search",
          }}
          listItemStyle={{
            "&:nth-of-type(odd)": {
              backgroundColor: "#f4f4f4c0",
            },
          }}
          url={apiEndpoint.SUBSCRIPTION_ACCOUNT_TRACKING_STATUS(user_id)}
        />
      </Box>
    </Paper>
  );
};

export default TrackingStatusTable;
