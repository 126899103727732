/* components */
import PlanChange from "src/pages/admin/plan-change";
import ResetSlotDialog from "src/components/common/dialog/reset-slot-dialog";
import DataTableList from "src/components/common/data-display/data-table-list";
import SubscriptionLogDialog from "src/components/common/dialog/subscription-log-dialog";
import CreateSubscriptionDialog from "src/components/common/dialog/create-subscription-dialog";
/* 3rd party lib */
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-hot-toast";
import { MRT_ColumnDef } from "material-react-table";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // Button,
  // Switch,
  // FormControlLabel,
  Box,
  Paper,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { faCalendarExclamation, faCheck, faEye, faInfoCircle, faXmark } from "@fortawesome/pro-solid-svg-icons";
/* Util */
import { useFormik } from "formik";
import { KeyedMutator } from "swr";
import useHttp from "src/hooks/use-http";
import { SUBSCRIPION_KEYS, TABLE_ID } from "src/utils/constants";
import { useTranslation } from "react-i18next";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { getTranslateString } from "src/utils/translate";
import { nullUndefinedReturnDash } from "src/utils/general";
import { FORM_WARNING_REQUIRED } from "src/constants/translate-keys/common";
import { ISubscriptionPlanData, TSubscriptionPlan } from "src/types/common";
import { showActionWhenHovered } from "src/components/common/data-display/dnd-table";
import EditSubscriptionDialog from "src/components/common/dialog/edit-subscription-dialog";

interface SubscriptionPlanTableProps {
  user_id?: string;
  subscriptionPlanData: ISubscriptionPlanData | undefined;
  mutate: KeyedMutator<ISubscriptionPlanData>;
  currentPlanData?: TSubscriptionPlan;
  currentPlanError?: Error;
  nextPlanData?: TSubscriptionPlan;
  nextPlanError?: Error;
  currentPlanMutate: KeyedMutator<TSubscriptionPlan>;
  nextPlanMutate: KeyedMutator<TSubscriptionPlan>;
}

type Props = SubscriptionPlanTableProps;

export interface IFormikSubscriptionPlan {
  start_time: string | null;
  end_time: string | null;
  package: string | null;
  account: string | null;
  remark: string;
  duration: number;
}

export interface IEditDialog {
  open: boolean;
  data: null | TSubscriptionPlan;
}

export interface ICreatePlanDialog {
  open: boolean;
  planType: "choose" | "immediate" | "after" | null;
}

const schema = (t: any) =>
  Yup.object().shape({
    start_time: Yup.string().required(getTranslateString(t, FORM_WARNING_REQUIRED)).nullable(),
    end_time: Yup.string().required(getTranslateString(t, FORM_WARNING_REQUIRED)).nullable(),
    package: Yup.string()
      .required(getTranslateString(t, FORM_WARNING_REQUIRED))
      .test("has-elements", getTranslateString(t, FORM_WARNING_REQUIRED), value => value !== "null"),
  });

const SubscriptionPlanTable: React.FC<Props> = ({
  user_id,
  mutate,
  nextPlanData,
  nextPlanError,
  currentPlanData,
  currentPlanError,
  nextPlanMutate,
  currentPlanMutate,
  subscriptionPlanData,
}) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { mode } = theme.palette;
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const dataTableListRef = useRef<any | null>(null);
  const { http, apiEndpoint } = useHttp();
  const [loading, setLoading] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState<ICreatePlanDialog>({ open: false, planType: null });
  const [editDialogOpen, setEditDialogOpen] = useState<IEditDialog>({ open: false, data: null });
  // const [autoSelected, setAutoSelected] = useState(true);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState<{ open: boolean; log: string | null }>({ open: false, log: null });

  const handleCreateSubscriptionPlan = async (values: IFormikSubscriptionPlan) => {
    setLoading(true);

    if (!user_id) return;
    try {
      await http.post(apiEndpoint.SUBSCRIPTION_PLAN(user_id), JSON.stringify({ ...values, account: parseInt(user_id) }));
      toast.success("Subscription Plan Created Successfully");
      if (dataTableListRef.current) {
        dataTableListRef.current.refreshData();
      }
      mutate();
      nextPlanMutate();
      currentPlanMutate();
      setLoading(false);
      formik.resetForm();
      setCreateDialogOpen({ open: false, planType: null });
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast.error("Something went wrong when creating subscription plan.");
    }
  };

  const handleEditSubscriptionPlan = async (values: IFormikSubscriptionPlan, plan_id: number) => {
    setLoading(true);

    if (!user_id) return;
    try {
      await http.patch(apiEndpoint.SUBSCRIPTION_PLAN_DETAIL(user_id, plan_id), JSON.stringify({ ...values, account: parseInt(user_id) }));
      toast.success("Subscription Plan Updated Successfully");
      if (dataTableListRef.current) {
        dataTableListRef.current.refreshData();
      }
      setLoading(false);
      formik.resetForm();
      nextPlanMutate();
      currentPlanMutate();
      setEditDialogOpen({ open: false, data: null });
      mutate();
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast.error("Something went wrong when updating subscription plan.");
    }
  };

  const handleDeleteSubscription = async (plan_id: number) => {
    try {
      await http.delete(apiEndpoint.SUBSCRIPTION_PLAN_DETAIL(user_id, plan_id));
      toast.success("Deleted Subscription Plan");
      setShowDeleteDialog(false);
      formik.resetForm();
      mutate();

      nextPlanMutate();
      currentPlanMutate();
      setEditDialogOpen({ open: false, data: null });
      if (dataTableListRef.current) {
        dataTableListRef.current.refreshData();
      }
    } catch (err) {
      console.error(err);
      toast.error("Something's went wrong when deleting subscription plan");
    }
  };

  const cancelledColor = mode === "light" ? theme.palette.error.main : theme.palette.error.light;

  const planCancelledTextColor = useCallback(
    (status: string) => {
      if (status === "canceled") {
        return cancelledColor;
      } else {
        return mode === "light" ? "grey.700" : "grey.300";
      }
    },
    [cancelledColor, mode]
  );

  const formik = useFormik<IFormikSubscriptionPlan>({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: schema(t),
    initialValues: {
      start_time: moment().format("YYYY-MM-DD"),
      end_time: null,
      package: "null",
      account: null,
      remark: "",
      duration: 0,
    },
    onSubmit: async values => {
      if (editDialogOpen.open && editDialogOpen.data) {
        handleEditSubscriptionPlan(values, editDialogOpen.data.id);
      } else {
        handleCreateSubscriptionPlan(values);
      }
    },
  });

  const subscriptionPlanColDef: MRT_ColumnDef<{}>[] = useMemo(
    () => [
      // {
      //   accessorKey: "no",
      //   header: "#",
      //   size: 0,
      //   muiTableHeadCellProps: {
      //     sx: {
      //       ...NUMBER_CELL_TRANSLATE,
      //     },
      //   },
      //   enablePinning: false,
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnFilter: false,
      //   enableColumnOrdering: false,
      //   enableColumnDragging: false,

      //   Cell: ({ row }) => {
      //     const { currentPage, rowsPerPage } = checkSubscriptionPagination;
      //     return (
      //       <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
      //         {getDynamicIndex(currentPage - 1, rowsPerPage, row.index + 1)}.
      //       </Typography>
      //     );
      //   },
      // },
      // {
      //   accessorKey: "id",
      //   header: "ID",
      //   size: 20,
      // },
      {
        accessorKey: "package",
        header: "Package",
        size: 150,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionPlan;

          return (
            <Tooltip
              title={
                <Stack>
                  <FeatureLayout boolType={null} theme={theme} valueType={{ label: "Durations", value: rowData.duration_days }} />
                  <FeatureLayout boolType={null} theme={theme} valueType={{ label: "Total Available Slot", value: rowData.package.slot }} />
                  <FeatureLayout
                    boolType={null}
                    theme={theme}
                    valueType={{ label: "Total Profile Swaps", value: rowData.package.total_slot_change }}
                  />
                  <FeatureLayout boolType={null} theme={theme} valueType={{ label: "Preset Limit", value: rowData.package.max_preset_limit }} />
                  <FeatureLayout
                    boolType={null}
                    theme={theme}
                    valueType={{ label: "Post Comparison Limit", value: rowData.package.max_compare_post_limit }}
                  />
                  <FeatureLayout
                    boolType={null}
                    theme={theme}
                    valueType={{ label: "Profile Comparison Limit", value: rowData.package.max_compare_profile_limit }}
                  />
                  <FeatureLayout boolType={null} theme={theme} valueType={{ label: "Description", value: rowData.package.description }} />
                  <FeatureLayout
                    boolType={{ available: rowData.package.is_compare_profiles, label: "Profiles Comparison" }}
                    theme={theme}
                    valueType={null}
                  />
                  <FeatureLayout
                    boolType={{ available: rowData.package.is_compare_posts, label: "Posts Comparison" }}
                    theme={theme}
                    valueType={null}
                  />
                </Stack>
              }
            >
              <Stack direction="row" alignItems={"center"} spacing={1}>
                <FontAwesomeIcon color={rowData.status === "canceled" ? cancelledColor : theme.palette.text.secondary} icon={faInfoCircle} />
                <Typography variant="body2" color={planCancelledTextColor(rowData.status)}>
                  {rowData.package?.title}
                </Typography>
              </Stack>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "slot_reset_time",
        header: "Slot Reset Time",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionPlan;
          return (
            <Typography variant="body2" color={planCancelledTextColor(rowData.status)}>{`${nullUndefinedReturnDash(
              moment(rowData.slot_reset_time).format("DD MMM YYYY")
            )}`}</Typography>
          );
        },
      },
      {
        accessorKey: "stripe_subscription_id",
        header: "Stripe ID",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionPlan;
          return (
            rowData.stripe_subscription_id && (
              <Tooltip title={rowData.stripe_subscription_id}>
                <Typography variant="body2" color={planCancelledTextColor(rowData.status)}>
                  {rowData.stripe_subscription_id}
                </Typography>
              </Tooltip>
            )
          );
        },
      },
      {
        accessorKey: "end_time",
        header: "Active Until",
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionPlan;
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2" color={planCancelledTextColor(rowData.status)}>{`${moment(rowData.end_time).format(
                "DD/MM/YYYY"
              )}`}</Typography>
              {rowData.status === "active" && (
                <Typography variant="body2" color={planCancelledTextColor(rowData.status)}>
                  (<span style={{ color: theme.palette.primary.main, fontWeight: "bolder" }}>{rowData.day_left}</span>&nbsp;Days Left)
                </Typography>
              )}
            </Stack>
          );
        },
      },
      // {
      //   accessorKey: "day_left",
      //   header: "Days Left",
      //   size: 110,
      //   muiTableHeadCellProps: {
      //     sx: showActionWhenHovered(2, 2),
      //   },
      //   enableColumnFilter: false,
      // },

      {
        accessorKey: "package.slot",
        header: "Slot",
        size: 100,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionPlan;
          return (
            <Typography variant="body2" color={planCancelledTextColor(rowData.status)}>{`${nullUndefinedReturnDash(
              rowData.package.slot
            )}`}</Typography>
          );
        },
      },

      {
        accessorKey: "duration_days",
        header: "Duration",
        size: 120,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionPlan;
          return (
            <Typography variant="body2" color={planCancelledTextColor(rowData.status)}>{`${nullUndefinedReturnDash(
              rowData.duration_days
            )}`}</Typography>
          );
        },
      },
      {
        accessorKey: "remark",
        header: "Remark",
        size: 100,
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionPlan;
          return (
            <Typography variant="body2" color={planCancelledTextColor(rowData.status)}>{`${nullUndefinedReturnDash(rowData.remark)}`}</Typography>
          );
        },
      },
      {
        accessorKey: "log",
        header: "Log",
        muiTableHeadCellProps: {
          sx: showActionWhenHovered(2, 2),
        },
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const rowData = row.original as TSubscriptionPlan;
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                onClick={e => {
                  e.stopPropagation();
                  setShowSubscriptionDialog({ open: true, log: rowData.log });
                }}
                sx={{
                  cursor: "pointer",
                  ".fa-eye": {
                    color: theme.palette.primary.main,
                    "&:hover": {
                      color: theme.palette.primary.dark,
                    },
                    "&:active": {
                      color: theme.palette.text.secondary,
                    },
                  },
                }}
              >
                <FontAwesomeIcon icon={faEye} />
              </Box>
              <Tooltip
                arrow
                placement="top-start"
                title={
                  rowData.log && (
                    <Typography variant={"body2"}>
                      <div style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: rowData.log }} />
                    </Typography>
                  )
                }
              >
                <Typography variant="body2" color={planCancelledTextColor(rowData.status)}>
                  {rowData.log}
                </Typography>
              </Tooltip>
            </Stack>
          );
        },
      },
    ],
    [theme, planCancelledTextColor, cancelledColor]
  );

  const activityLogMetaData = {
    primary: {
      path: "id",
      content: (rowData: TSubscriptionPlan) => (
        <Typography variant="body1" color="primary.main" fontWeight={500}>
          Subscription #{rowData.id}
        </Typography>
      ),
    },
    sortFields: [],
    fields: [
      {
        path: "package",
        label: "Package",
        content: (rowData: TSubscriptionPlan) => <Typography variant="body2">{rowData.package.title}</Typography>,
      },
      {
        path: "end_time",
        label: "Active Until",
        content: (rowData: TSubscriptionPlan) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">{`${moment(rowData.end_time).format("DD/MM/YYYY")}`}</Typography>
            <Typography variant="body2">
              <span style={{ color: theme.palette.primary.main, fontWeight: "bolder" }}>{rowData.day_left}</span>&nbsp;Days Left
            </Typography>
          </Stack>
        ),
      },
      {
        path: "package.slot",
        label: "Slot",
      },
    ],
  };

  // const endTime =
  //   subscriptionPlanData && subscriptionPlanData.results.length > 0
  //     ? nullUndefinedReturnDash(moment(subscriptionPlanData.results[0].end_time).format("DD MMM YYYY"))
  //     : " - ";

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  /* ================================================== */
  /* ================================================== */

  const customPaperStyle = {
    background: (theme.palette.neutral as any)[mode === "dark" ? 800 : 100],
    borderRadius: "8px",
    border: `1px solid ${(theme.palette.neutral as any)[mode === "dark" ? 600 : 300]}`,
  };

  return (
    <>
      <Stack spacing={2}>
        <Paper elevation={10} sx={{ width: "100%" }}>
          <Box sx={{ p: smDown ? 2 : 4 }}>
            <Stack direction="row" alignItems="center" justifyContent={"space-between"} mb={4}>
              <Typography textAlign="left" variant="h5" fontWeight={500} color="primary.main">
                Subscription Plan
              </Typography>
            </Stack>

            <Stack spacing={2}>
              {/* <Stack
                direction={smDown ? "column" : "row"}
                alignItems={smDown ? "space-between1" : "flex-start"}
                justifyContent={"space-between"}
                sx={{ mb: 4 }}
              >
                <Box>
                  <Stack alignItems="flex-start" sx={{ mt: 1.3 }}>
                    <Typography textAlign="left" variant="body1" fontWeight={500} color="text.secondary">
                      Current Plan:&nbsp;
                      <span style={{ color: theme.palette.primary.main }}>
                        {subscriptionPlanData && subscriptionPlanData.results.length > 0 && subscriptionPlanData.results[0].package
                          ? subscriptionPlanData.results[0].package.title
                          : "-"}
                      </span>
                    </Typography>
                  </Stack>
                </Box>
                <Stack alignItems="flex-end">
                  <FormControlLabel
                    labelPlacement="start"
                    control={<Switch checked={autoSelected} defaultChecked onChange={e => setAutoSelected(e.target.checked)} />}
                    label="Auto Renew"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontWeight: "normal",
                        color: theme.palette.text.secondary,
                      },
                    }}
                  />

                  <Typography variant="caption" fontWeight={400} color="text.secondary" sx={{ marginTop: "-8px" }}>
                    (
                    {subscriptionPlanData && subscriptionPlanData.results.length > 0 ? (
                      <>
                        <span style={{ color: theme.palette.primary.main, fontWeight: "bolder" }}>{subscriptionPlanData.results[0].day_left}</span>
                        &nbsp;day(s) left,&nbsp;
                      </>
                    ) : (
                      ""
                    )}
                    {autoSelected ? `plan renewing on ${endTime}` : `plan expiring on ${endTime}`})
                  </Typography>
                </Stack>
              </Stack> */}
              <Stack alignItems="flex-start" spacing={2}>
                {subscriptionPlanData &&
                  subscriptionPlanData.results.length > 0 &&
                  subscriptionPlanData.results[0].subscription_status &&
                  subscriptionPlanData.results[0].subscription_status.toLowerCase() === SUBSCRIPION_KEYS.EXPIRED_SUBSCRIPTIONS && (
                    <Box sx={{ width: smDown ? "100%" : "400px" }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          p: 1,
                          background: theme.palette.error.light,
                          borderRadius: "8px",
                          border: `1px solid ${theme.palette.mode === "dark" ? "#ffffff" : "none"}`,
                        }}
                      >
                        <FontAwesomeIcon icon={faCalendarExclamation} color="#ffffff" />
                        <Typography variant="body2" color="#ffffff">
                          Your account has expired
                        </Typography>
                      </Stack>
                    </Box>
                  )}
                <PlanChange
                  user_id={user_id}
                  currentPlanData={currentPlanData}
                  currentPlanError={currentPlanError}
                  nextPlanData={nextPlanData}
                  nextPlanError={nextPlanError}
                  setShowResetDialog={setShowResetDialog}
                  customPaperStyle={customPaperStyle}
                  setEditDialogOpen={setEditDialogOpen}
                  setCreateDialogOpen={setCreateDialogOpen}
                  setShowSubscriptionDialog={setShowSubscriptionDialog}
                />
                {/* <Box
                  sx={{
                    width: smDown ? "100%" : "398px",
                    ...customPaperStyle,
                  }}
                >
                  <Stack alignItems="flex-start" sx={{ padding: "10px" }}>
                    <Typography variant="body1" fontWeight={400} color="text.secondary">
                      Available Slots:&nbsp;
                      {subscriptionPlanData && subscriptionPlanData.results.length > 0 ? subscriptionPlanData.results[0].package.slot : "-"}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" fontStyle={"italic"} textAlign="left">
                      <FontAwesomeIcon color={theme.palette.text.secondary} icon={faCircleInfo} />
                      &nbsp;
                      {subscriptionPlanData && subscriptionPlanData.results.length > 0 ? subscriptionPlanData.results[0].package.slot : "-"}
                      &nbsp;available slots in total.
                    </Typography>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    width: smDown ? "100%" : "398px",
                    ...customPaperStyle,
                  }}
                >
                  <Stack alignItems="flex-start" sx={{ padding: "10px" }}>
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width="100%">
                      <Stack alignItems="flex-start">
                        <Typography variant="body1" fontWeight={400} color="text.secondary" textAlign="left">
                          Remaining Profile Swaps:&nbsp;
                          {subscriptionPlanData &&
                            subscriptionPlanData.results.length > 0 &&
                            nullUndefinedReturnDash(subscriptionPlanData.results[0].slot_change_remaining)}
                          &nbsp;/&nbsp;
                          {subscriptionPlanData &&
                          subscriptionPlanData.results.length > 0 &&
                          subscriptionPlanData.results[0].package.total_slot_change
                            ? subscriptionPlanData.results[0].package.total_slot_change
                            : "-"}
                        </Typography>

                        <Typography variant="body2" color={"text.secondary"} fontSize={13}>
                          Profile Swaps resetting on&nbsp;
                          {subscriptionPlanData && subscriptionPlanData.results.length > 0
                            ? nullUndefinedReturnDash(moment(subscriptionPlanData.results[0].slot_reset_time).format("YYYY-MM-DD"))
                            : "-"}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" fontStyle={"italic"} textAlign="left">
                          <FontAwesomeIcon icon={faCircleInfo} /> You can switch any of your slots up to&nbsp;
                          {subscriptionPlanData && subscriptionPlanData.results.length > 0
                            ? subscriptionPlanData.results[0].slot_change_remaining
                            : "-"}
                          &nbsp;more times.
                        </Typography>
                      </Stack>
                      <Tooltip arrow title="Reset Profile Swaps">
                        <Button
                          variant="contained"
                          sx={{ padding: "4px", minWidth: "24px !important", minHeight: "24px" }}
                          onClick={() => setShowResetDialog(true)}
                        >
                          <FontAwesomeIcon icon={faRefresh} />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Box> */}
              </Stack>
            </Stack>
          </Box>

          <Box sx={{ p: smDown ? "16px" : "0 32px 32px 32px" }}>
            <Box sx={{ overflow: "hidden", borderRadius: "8px", border: `1px solid ${(theme.palette.neutral as any)[mode === "dark" ? 600 : 300]}` }}>
              <DataTableList
                // rowClick
                // disableAdvance
                mobilePaginationStickBottom={false}
                id={TABLE_ID.SUBSCRIPTION_PLAN_TABLE}
                columns={subscriptionPlanColDef}
                columnOrder={subscriptionPlanColDef.map(c => c.accessorKey)}
                listMetadata={activityLogMetaData}
                dataTableListRef={dataTableListRef}
                // rowAction={(rowData: TSubscriptionPlan) => setEditDialogOpen({ open: true, data: rowData })}
                showGlobalFilter
                search={{
                  key: "q",
                  label: "Search",
                }}
                listItemStyle={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "#f4f4f4c0",
                  },
                }}
                url={apiEndpoint.SUBSCRIPTION_PLAN(user_id)}
              />
            </Box>
          </Box>
        </Paper>
      </Stack>
      <CreateSubscriptionDialog
        loading={loading}
        formik={formik}
        currentPlanData={currentPlanData}
        setCreateDialogOpen={setCreateDialogOpen}
        createDialogOpen={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen({ open: false, planType: null });
          formik.resetForm();
        }}
      />
      <SubscriptionLogDialog
        log={showSubscriptionDialog.log}
        open={showSubscriptionDialog.open}
        onClose={() => {
          setShowSubscriptionDialog({ open: false, log: null });
        }}
      />

      <ResetSlotDialog
        mutate={mutate}
        subscriptionPlanData={subscriptionPlanData}
        open={showResetDialog}
        onClose={() => {
          setShowResetDialog(false);
        }}
      />
      <EditSubscriptionDialog
        loading={loading}
        formik={formik}
        editDialogOpen={editDialogOpen}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        onDelete={handleDeleteSubscription}
        onClose={() => {
          setEditDialogOpen({ open: false, data: null });
          formik.resetForm();
        }}
      />
    </>
  );
};

export default SubscriptionPlanTable;

export const FeatureLayout = ({
  theme,
  boolType,
  valueType,
}: {
  boolType: { available: boolean; label: string } | null;
  valueType: { label: string; value: string | number | null } | null;
  theme: Theme;
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <>
        {boolType ? (
          <>
            <Box width="16px" height="16px" sx={{ display: "grid", placeItems: "center", borderRadius: "50%", background: "white" }}>
              {boolType.available ? (
                <FontAwesomeIcon icon={faCheck} color={theme.palette.success.main} size="lg" />
              ) : (
                <FontAwesomeIcon icon={faXmark} color={theme.palette.error.main} size="lg" />
              )}
            </Box>
            <Typography textAlign="left" variant="body2" fontWeight={500} color={"white"} sx={{ fontSize: "13px" }}>
              {boolType.label}
            </Typography>
          </>
        ) : (
          valueType && (
            <>
              <Typography textAlign="left" variant="body2" fontWeight={500} color={"white"} sx={{ fontSize: "13px" }}>
                {valueType.label} : {nullUndefinedReturnDash(valueType.value)}
              </Typography>
            </>
          )
        )}
      </>
    </Stack>
  );
};
