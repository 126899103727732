import React, { useEffect, useMemo, useState } from "react";
/* components */
/* 3rd party lib */
import ReactEcharts from "echarts-for-react";
import NoDataFound from "src/components/common/feedback/no-data-found";
import SpinnerComponent from "src/components/common/feedback/spinner";
import { ChartData } from "src/types/instagram";
import { rainbowBarColors } from "src/components/common/data-display/charts/bar-chart";
import { Box, useTheme } from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";
/* Util */
interface MultiLineChartProps {
  toolbox?: boolean;
  xAxisData: string[];
  yAxisArrayOfArray: ChartData[];
}

type Props = MultiLineChartProps;

const MultiLineChart: React.FC<Props> = ({ toolbox = false, xAxisData, yAxisArrayOfArray }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const { mode } = theme.palette;
  const { mdDown } = useMediaQueries();
  const [combined, setCombined] = useState<(number | null)[]>([]);

  useEffect(() => {
    if (yAxisArrayOfArray) {
      setCombined(([] as (number | null)[]).concat(...yAxisArrayOfArray.map(child => child.data)));
    }
  }, [yAxisArrayOfArray]);

  // const rainbowBarColors = ["#41bfdf", "#247bd2", "#3be18e", "#e3e632", "#f3a02c", "#ff3535", "#ff7de5", "#9615e1"];

  const clearedCombined = combined.filter(child => child !== null);
  // Calculate min and max dynamically as a percentage of the data range
  const dataRange = Math.max(...(clearedCombined as number[])) - Math.min(...(clearedCombined as number[]));
  const min = Math.max(0, Math.floor(Math.min(...(clearedCombined as number[])) - dataRange * 0.1)); // Ensure min is non-negative and round down to the nearest integer
  const max = Math.ceil(Math.max(...(clearedCombined as number[])) + dataRange * 0.1); // Round up to the nearest integer

  const options = useMemo(() => {
    return {
      grid: {
        left: 36,
        right: 42,
        bottom: 60,
        containLabel: true,
      },
      legend: {
        show: true, // Set to true to display legends
        data: yAxisArrayOfArray.map(child => child.name),
        orient: "horizontal",
        bottom: 20,
        left: "center",
        type: "scroll",
        textStyle: {
          color: theme.palette.text.secondary, // Change the color of the legend text
        },
        pageTextStyle: {
          color: theme.palette.text.secondary, // Specify the color for the page numbers
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: xAxisData,
      },
      yAxis: {
        type: "value",
        min: min,
        max: max,
        splitLine: {
          show: true, // Set to true to display grid lines
          lineStyle: {
            color: (theme.palette.neutral as any)[mode === "dark" ? 800 : 200], // Specify the color for the grid lines
          },
        },
      },

      toolbox: {
        show: toolbox,
        // feature: {
        //   saveAsImage: {
        //     title: "Save",
        //     // backgroundColor: "rgba(0, 0, 0) !important", // Custom background color
        //   },
        // },
      },
      series: yAxisArrayOfArray.map((child, index) => {
        return {
          showSymbol: false,
          data: child.data,
          type: "line",
          smooth: true,
          name: child.name,
          lineStyle: {
            color: rainbowBarColors[index % yAxisArrayOfArray.length], // Change the line color to blue
          },
          // areaStyle: {
          //   color: rainbowBarColors[index % yAxisArrayOfArray.length], // Change the line color to blue
          // },
          itemStyle: {
            color: rainbowBarColors[index % yAxisArrayOfArray.length], // Change the line color to blue
          },
          emphasis: {
            focus: "series",
          },
        };
      }),

      tooltip: {
        trigger: "axis",
      },
      rich: {
        alignLeft: {
          textAlign: "left",
        },
      },
    };
  }, [theme, toolbox, mode, max, min, xAxisData, yAxisArrayOfArray]);
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      {xAxisData ? (
        xAxisData.length === 0 ? (
          <NoDataFound image />
        ) : (
          <Box sx={{ width: toolbox ? `calc(100% - ${mdDown ? 20 : 34}px)` : "100%", height: "100%", pt: toolbox ? 1 : 0 }}>
            <ReactEcharts
              key={`${xAxisData.length}-${yAxisArrayOfArray.length}`}
              option={options}
              style={{ width: "100%", height: "100%", WebkitTapHighlightColor: "transparent" }}
              opts={{ renderer: "svg" }} // Use SVG renderer for better resizing
              //@ts-ignore
              autoResize={true} // Enable autoResize to make the height flexible
            />
          </Box>
        )
      ) : (
        <SpinnerComponent modifier={1.2} />
      )}
    </>
  );
};

export default MultiLineChart;
