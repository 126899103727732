import { combineReducers } from "@reduxjs/toolkit";
import authReducer, { AuthRootState } from "src/slices/auth";
import layoutReducer, { LayoutRootState } from "src/slices/layout";
import generalReducer, { GeneralRootState } from "src/slices/general";
import compareReducer, { CompareRootState } from "src/slices/compare";
// import workOrderReducer from "src/slices/work-order";

export interface RootState {
  auth: AuthRootState;
  layout: LayoutRootState;
  general: GeneralRootState;
  compare: CompareRootState;
}

const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  general: generalReducer,
  compare: compareReducer,
  // workOrder: workOrderReducer,
});
export default rootReducer;
