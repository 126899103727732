import { faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Slide, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

interface GoToTopButtonProps {
  positionStyle?: any;
}

type Props = GoToTopButtonProps;

const GoToTopButton: React.FC<Props> = ({ positionStyle = { position: "fixed", right: "16px", bottom: "8px" } }) => {
  // by default it is stuck to the bottom right of the screen
  const theme = useTheme();
  const { mode } = theme.palette;
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        // Adjust the threshold value as needed
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Slide direction="up" in={showButton}>
      <Button
        onClick={handleBackToTop}
        sx={{
          zIndex: 999,
          ...positionStyle,
          minWidth: 30,
          width: 30,
          height: 30,
          borderRadius: "50%",
          backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[700] : (theme.palette.neutral as any)[500], // Set the initial opacity here
          color: "#fff",
          textAlign: "center",
          transition: "all 0.3s ease",
          "&:hover": {
            opacity: 1,
            backgroundColor: mode === "dark" ? (theme.palette.neutral as any)[600] : (theme.palette.neutral as any)[550], // Set the initial opacity here
          },
        }}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </Button>
    </Slide>
  );
};

export default GoToTopButton;
