import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled, Drawer, IconButton, Box, Stack, Divider, Typography } from "@mui/material";
import { ReactNode } from "react";

interface MobileDrawerProps {
  header: string | ReactNode;
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  titleColor?: string;
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 200,
  "& .MuiDrawer-paper": {
    marginBottom: "16px",
    marginLeft: "8px",
    marginRight: "8px",
    borderRadius: "16px",
    maxHeight: "90vh",
  },
}));

const MobileDrawer: React.FC<MobileDrawerProps> = ({ header, children, open, onClose, titleColor = "primary.main" }) => {
  return (
    <StyledDrawer anchor={"bottom"} open={open} onClose={onClose}>
      {/* Add an empty theme object for TypeScript */}
      <Box sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {typeof header === "string" ? (
            <Typography variant="h6" color={titleColor}>
              {header}
            </Typography>
          ) : (
            header
          )}
          <IconButton onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </IconButton>
        </Stack>
        <Divider sx={{ mb: 2, mt: 1 }} />
        {children}
      </Box>
    </StyledDrawer>
  );
};

export default MobileDrawer;
