/* components */
import SpinnerComponent from "src/components/common/feedback/spinner";
import FavouriteToggle from "src/components/common/inputs/favourite-toggle";
import AddAccountButton from "src/components/common/inputs/add-account-button";
import FullAndMobilePagination from "src/components/common/navigation/full-mobile-pagination";
/* 3rd party lib */
import useSWR from "swr";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, darken, Grid, Link, Paper, Stack, Typography, useTheme } from "@mui/material";
import { faCheckCircle, faCircleX, faImage, faPlus, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
/* Util */
import routes from "src/routes";
import Insta from "src/images/insta.png";
import useHttp from "src/hooks/use-http";
import Search from "src/images/search.png";
import useCompare from "src/hooks/use-compare";
import { RootState } from "src/store/root-reducer";
import useBackground from "src/hooks/use-background";
import { TDeleteDialog } from "src/pages/listing/index";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { formatNumberWithSuffix } from "src/utils/general";
import { IInstaData, TInstaData } from "src/types/instagram";
import { TPagination, TSocialMedia, TUserSlot } from "src/types/common";
import { handleAddFavourite } from "src/components/profile/tiktok/profile-banner";

interface InstaProfileGridProps {
  id?: string;
  search?: string;
  influencerType?: string;
  favourite?: boolean;
  multiSelect?: boolean;
  multiPostSelect?: boolean;
  showTrackButton?: boolean;
  userSlotData?: TUserSlot | undefined;
  onSelectProfile?: (profile: TInstaData) => void;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  setAddAccountDialog?: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      socialMedia: TSocialMedia;
    }>
  >;
  setCompareAddAccountDialog?: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      socialMedia: TSocialMedia;
    }>
  >;
  instaProfileGridRef?: any;
  setShowDeleteDialog?: React.Dispatch<React.SetStateAction<TDeleteDialog>>;
}

type Props = InstaProfileGridProps;

const InstaProfileGrid: React.FC<Props> = ({
  id,
  search,
  setSearch,
  favourite,
  userSlotData,
  influencerType,
  onSelectProfile,
  setShowDeleteDialog,
  setAddAccountDialog,
  setCompareAddAccountDialog,
  instaProfileGridRef,
  multiSelect = false,
  multiPostSelect = false,
  showTrackButton = false,
}) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { mode } = theme.palette;
  const socialMedia = "instagram";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const background = useBackground({});
  const { http, apiEndpoint } = useHttp();
  const { user } = useSelector((state: RootState) => state.auth);
  const { smDown, xsUp, smUp, mdUp, lgUp, xlUp, mdDown } = useMediaQueries();
  const { handleMultiProfileSelect } = useCompare();
  const { viewMode } = useSelector((state: RootState) => state.layout);
  const { multiProfile, multiPost } = useSelector((state: RootState) => state.compare);
  const [pagination, setPagination] = useState<TPagination>({
    currPage: 1,
    count: 0,
    limit: 0,
    total_pages: 0,
  });

  const config = useMemo(() => {
    let pageSize = 25;

    if (xlUp) {
      pageSize = 24;
      if (id === "profile_dialog") {
        pageSize--;
      }
    } else if (lgUp) {
      pageSize = 19;
    } else if (mdUp) {
      pageSize = 14;
    } else if (smUp) {
      pageSize = 9;
    } else if (xsUp) {
      pageSize = 5;
    }

    return {
      params: {
        page_size: !showTrackButton || favourite ? pageSize + 1 : pageSize, //favourite need to add 1 because there's no add button
        page: pagination.currPage,
        q: search,
        influencer_type: influencerType === "null" ? "" : influencerType,
      },
    };
  }, [id, xsUp, mdUp, smUp, lgUp, xlUp, pagination, showTrackButton, search, influencerType, favourite]);

  const { data, mutate } = useSWR<IInstaData>([`${apiEndpoint.INSTAGRAM}${favourite ? `?favourite=true` : ""}`, config]);

  if (instaProfileGridRef) {
    instaProfileGridRef.current = {
      refreshData: mutate,
      data: data,
    };
  }

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleNavigate = (rowData: TInstaData) => {
    if (onSelectProfile) {
      onSelectProfile(rowData);
    } else {
      navigate(`${routes.INSTA_PROFILE}/${rowData.username}`, { state: { prevPage: routes.ROOT } });
    }
  };

  const handleAction = () => {
    setAddAccountDialog && setAddAccountDialog({ open: true, socialMedia: "instagram" });
  };
  const handleActionCompare = () => {
    setCompareAddAccountDialog && setCompareAddAccountDialog({ open: true, socialMedia: "instagram" });
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (data) {
      setPagination(prev => {
        return { ...prev, count: data.count, limit: data.limit, total_pages: data.total_pages };
      });
    }
  }, [data]);

  useEffect(() => {
    setPagination(prev => {
      return { ...prev, currPage: 1 };
    });
  }, [search]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Paper
        sx={{
          // background: (theme.palette.neutral as any)[theme.palette.mode === "dark" ? 600 : 100],
          background: background,
          p: 1,
        }}
      >
        {data && data.results.length !== 0 && (
          <>
            <Stack direction="row" justifyContent={"flex-end"} sx={{ mb: 1 }}>
              <FullAndMobilePagination pagination={pagination} setPagination={setPagination} />
            </Stack>
          </>
        )}
        {data ? (
          data.results.length === 0 ? (
            <>
              {showTrackButton ? (
                <Grid container spacing={1} justifyContent="center">
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
                    <Box sx={{ py: 2 }}>
                      <AddAccountButton viewMode={viewMode} handleAction={handleAction} />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Paper sx={{ p: 2 }}>
                  <Stack alignItems="center" spacing={2} sx={{ p: 2 }}>
                    <img src={Search} alt="insta" width={smDown ? "90%" : "300px"} />
                    {search === "" ? (
                      <Typography variant="body1">You have not added any profile, do you want to track a profile?</Typography>
                    ) : (
                      <Typography variant="body1">You have not added this profile, do you want to track it?</Typography>
                    )}
                    <Box>
                      <Button
                        className="step-1"
                        variant="contained"
                        sx={{
                          width: "100%",
                          display: "grid",
                          height: viewMode === "table" ? "70px" : "100%",
                          py: 1,
                          px: 2,
                          borderRadius: "8px",
                          placeItems: "center",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={handleActionCompare}
                      >
                        <Stack spacing={1} alignItems="center" direction={"row"}>
                          <FontAwesomeIcon icon={faPlus} size={"xl"} />
                          {search === "" ? (
                            <Typography variant="body2">Click to track a profile</Typography>
                          ) : (
                            <Typography variant="body2">Click to Track "{search}"</Typography>
                          )}
                        </Stack>
                      </Button>
                    </Box>
                  </Stack>
                  {/* <NoDataFound image /> */}
                </Paper>
              )}
            </>
          ) : (
            <Grid container spacing={1}>
              {showTrackButton && (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
                  <AddAccountButton viewMode={viewMode} handleAction={handleAction} />
                </Grid>
              )}
              {data.results.map((child, index) => {
                const { display_name, downloaded_image, username, count_follower } = child;
                // use showtrackbutton bool to check if it's for the main page or select profile dialog

                let profilePicSize = showTrackButton ? (mdDown ? "80px" : "90px") : "70px";
                let iconPosition = showTrackButton
                  ? { bottom: 0, right: 0, width: "20px", height: "20px" }
                  : { bottom: -2, right: -1, width: "14px", height: "14px" };

                const usernameExistInMultiProfile = multiProfile && multiProfile[child.username];

                return (
                  <Grid key={`'insta_grid_${index}`} item xs={12} sm={6} md={4} lg={3} xl={showTrackButton || favourite ? 2.4 : 3}>
                    <Paper
                      sx={{
                        p: 1,
                        display: "flex",
                        alignItems: "center",
                        height: "100px",
                        cursor: "pointer",
                        userSelect: "none",
                        position: "relative",
                        transition: usernameExistInMultiProfile ? "none" : "all 0.5s ease",
                        "& .card__delete": {
                          display: "none",
                        },
                        "@media (pointer: coarse)": {
                          "& .card__delete": {
                            display: "grid",
                          },
                        },
                        "&:hover": {
                          background:
                            (multiPost && multiPost[child.username] && multiPostSelect) || (usernameExistInMultiProfile && !multiPostSelect)
                              ? theme.palette.primary.main
                              : (theme.palette as any).neutral[mode === "dark" ? 800 : 100],
                          // background: "black",
                          "& .card__delete": {
                            display: "grid",
                          },
                          a: {
                            textDecoration: "underline !important",
                            color: `${theme.palette.primary.main} !important`,
                          },
                        },
                        "&:active": {
                          transition: "none",
                          background:
                            (multiPost && multiPost[child.username] && multiPostSelect) || (usernameExistInMultiProfile && !multiPostSelect)
                              ? darken(theme.palette.primary.main, 0.15)
                              : mode === "dark"
                              ? (theme.palette as any).neutral[900]
                              : "#ffffff",
                        },
                        color:
                          (multiPost && multiPost[child.username] && multiPostSelect) || (usernameExistInMultiProfile && !multiPostSelect)
                            ? "white"
                            : "inherit",
                        background:
                          (multiPost && multiPost[child.username] && multiPostSelect) || (usernameExistInMultiProfile && !multiPostSelect)
                            ? darken(theme.palette.primary.main, 0.15)
                            : "",
                      }}
                      onClick={() => {
                        if (multiSelect && !multiPostSelect) {
                          handleMultiProfileSelect(child);
                        } else {
                          handleNavigate(child);
                        }
                      }}
                    >
                      {usernameExistInMultiProfile && (
                        <Box
                          sx={{
                            opacity: usernameExistInMultiProfile ? 1 : 0,
                            position: "absolute",
                            bottom: "6px",
                            right: "6px",
                            background: "white",
                            borderRadius: "50%",
                            height: "20px",
                            width: "20px",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.success.main} />
                        </Box>
                      )}
                      {multiPost && multiPost[child.username] && multiPostSelect && (
                        <Box
                          sx={{
                            opacity: multiPost && multiPost[child.username] && multiPostSelect ? 1 : 0,
                            position: "absolute",
                            bottom: "6px",
                            right: "6px",
                            background: "white",
                            borderRadius: "50%",
                            height: "30px",
                            width: "30px",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <Typography variant="body1" color="primary.main" fontWeight={"bolder"}>
                            {Object.keys(multiPost[child.username]).length}
                          </Typography>
                        </Box>
                      )}
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Box
                          sx={{
                            position: "relative",
                            width: profilePicSize,
                            height: profilePicSize,
                          }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              placeItems: "center",
                              borderRadius: "50%",
                              overflow: "hidden",
                              border: "1px solid #dcdcdc",
                              width: profilePicSize,
                              height: profilePicSize,
                            }}
                          >
                            {downloaded_image ? (
                              <img alt={`${username}`} src={downloaded_image} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                            ) : (
                              <FontAwesomeIcon
                                icon={faImage}
                                color={usernameExistInMultiProfile ? "#ffffff" : theme.palette.text.secondary}
                                size={smDown ? "xl" : "2x"}
                              />
                            )}
                          </Box>
                          <Box
                            sx={{
                              position: "absolute",
                              background: "white",
                              border: "1px solid #dcdcdc",
                              borderRadius: "50%",
                              padding: 0.5,
                              display: "grid",
                              placeItems: "center",
                              ...iconPosition,
                            }}
                          >
                            <img src={Insta} alt="insta" width="100%" height="100%" />
                          </Box>
                        </Box>
                        {onSelectProfile ? (
                          <Stack textAlign="left">
                            <Typography
                              color="inherit"
                              sx={{
                                marginRight: "35px",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {display_name}
                            </Typography>
                            <Typography color="inherit" variant="caption">
                              @{username}
                            </Typography>
                            <Typography
                              variant="body1"
                              mt={1}
                              color={
                                (multiPost && multiPost[child.username] && multiPostSelect) || (usernameExistInMultiProfile && !multiPostSelect)
                                  ? "white"
                                  : "text.secondary"
                              }
                            >
                              {formatNumberWithSuffix(count_follower)} Followers
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack textAlign="left">
                            <a
                              href={`${routes.INSTA_PROFILE}/${username}`}
                              style={{
                                color: theme.palette.text.secondary,
                                textDecoration: "none",

                                fontWeight: "bold",
                                marginRight: "35px",
                              }}
                            >
                              <Typography
                                color="inherit"
                                sx={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {display_name}
                              </Typography>
                              <Typography color="inherit" variant="caption">
                                @{username}
                              </Typography>
                            </a>
                            <Typography variant="body1" mt={1} color="text.secondary">
                              {formatNumberWithSuffix(count_follower)} Followers
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                      {showTrackButton && (
                        <>
                          <Box sx={{ position: "absolute", right: "8px", top: "8px" }}>
                            <FavouriteToggle
                              sx={{ height: "auto" }}
                              data={child}
                              handleUpdateFavourite={() => {
                                handleAddFavourite({
                                  username: username,
                                  data: child,
                                  http: http,
                                  apiEndpoint: apiEndpoint,
                                  t: t,
                                  mutate: mutate,
                                  socialMedia: socialMedia,
                                });
                              }}
                            />
                          </Box>

                          {/* hide delete when available is 0 */}
                          {(user?.isAdmin || (!user?.isAdmin && userSlotData && userSlotData.slot_available <= 0)) && (
                            <Box
                              onClick={e => {
                                e.stopPropagation();
                                setShowDeleteDialog && setShowDeleteDialog({ open: true, username: username, socialMedia: "instagram" });
                              }}
                              className="card__delete"
                              sx={{
                                background: "white",
                                width: "24px",
                                height: "24px",
                                borderRadius: "50%",
                                position: "absolute",
                                right: "8px",
                                bottom: "8px",
                                placeItems: "center",
                                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                "&:hover": { svg: { color: theme.palette.error.dark } },
                                "&:active": { svg: { color: theme.palette.error.main } },
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashCan} color={theme.palette.error.main} />
                            </Box>
                          )}
                        </>
                      )}
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          )
        ) : (
          <Grid container>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                minHeight: "300px",
                py: "8px",
                width: "100%",
                // background: theme.palette.mode === "dark" ? (theme.palette.neutral as any)[800] : "white",
              }}
            >
              <SpinnerComponent color={theme.palette.mode === "dark" ? "white" : theme.palette.primary.main} />
            </Box>
          </Grid>
        )}
        {search && showTrackButton && (
          <Typography variant="body2" sx={{ pt: 1, cursor: "pointer" }} onClick={() => setSearch && setSearch("")}>
            <u>
              Clear Filter &nbsp;
              <FontAwesomeIcon icon={faCircleX} />
            </u>
          </Typography>
        )}
        {data && data.results.length !== 0 && (
          <>
            <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 1 }}>
              <FullAndMobilePagination pagination={pagination} setPagination={setPagination} />
            </Stack>
          </>
        )}
      </Paper>
      {(multiSelect || multiPostSelect) && data && data.results.length > 0 && (
        <Stack alignItems="center">
          <Box>
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setCompareAddAccountDialog && setCompareAddAccountDialog({ open: true, socialMedia: "instagram" });
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Click here to add new profiles
              </Typography>
            </Link>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default InstaProfileGrid;
