/* components */
import BarChart from "src/components/common/data-display/charts/bar-chart";
/* 3rd party lib */
import moment from "moment";
import { EventSourceInput } from "@fullcalendar/core";
import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, lighten, Paper, Theme, Typography } from "@mui/material";
/* Util */
import { Global } from "@emotion/react";
import { TInstaProfileStatistics } from "src/types/instagram";

interface PostsChartsProps {
  data?: TInstaProfileStatistics;
  theme: Theme;
}

type Props = PostsChartsProps;

const PostsCharts: React.FC<Props> = React.memo(function PostsCharts({ data, theme }) {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const generateZeroArray = (num: number) => {
    return Array.from({ length: num }, () => 0);
  };

  const initialHourlyData = useMemo(() => generateZeroArray(24), []);
  const initialWeekData = useMemo(() => generateZeroArray(7), []);
  const initialMonthlyData = useMemo(() => generateZeroArray(12), []);

  const chartHeight = 375;

  const [hourlyData, setHourlyData] = useState<{ xAxis: string[]; yAxis: number[] }>({
    xAxis: Array.from({ length: 24 }, (_, index) => `${moment(`${index}:00`, "HH:mm").format("HH:mm")}`),
    yAxis: initialHourlyData,
  });
  // this is days through out the whole week
  const [weekData, setWeekData] = useState<{ xAxis: string[]; yAxis: number[] }>({
    xAxis: ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],
    yAxis: initialWeekData,
  });
  // this is weeks throughout the whole year
  const [weeklyData, setWeeklyData] = useState<{ xAxis: string[]; yAxis: number[] }>({
    xAxis: [],
    yAxis: [],
  });
  const [dailyData, setDailyData] = useState<{ xAxis: string[]; yAxis: number[] }>({
    xAxis: [],
    yAxis: [],
  });
  const [monthlyData, setMonthlyData] = useState<{ xAxis: string[]; yAxis: number[] }>({
    xAxis: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    yAxis: initialMonthlyData,
  });

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (data) {
      let tempHourlyYAxis = initialHourlyData;
      let tempWeekYAxis = initialWeekData;
      let tempMonthlyYAxis = initialMonthlyData;
      let tempCalendarEvents: EventSourceInput = [];

      for (const [key, value] of Object.entries(data.time)) {
        tempHourlyYAxis[parseInt(key)] = value;
      }
      for (const [key, value] of Object.entries(data.daily)) {
        tempWeekYAxis[parseInt(key) - 1] = value;
      }

      let minMonthlyIndex = Infinity;
      let maxMonthlyIndex = -Infinity;
      for (const [key, value] of Object.entries(data.monthly)) {
        minMonthlyIndex = Math.min(minMonthlyIndex, parseInt(key) - 1);
        maxMonthlyIndex = Math.max(maxMonthlyIndex, parseInt(key) - 1);
        tempMonthlyYAxis[parseInt(key) - 1] = value;
      }

      let minPostCount = Infinity; // Initialize with positive infinity to ensure any value will be smaller
      let maxPostCount = -Infinity; // Initialize with negative infinity to ensure any value will be larger

      for (const [date, postAmount] of Object.entries(data.day)) {
        // Update min and max values
        minPostCount = Math.min(minPostCount, postAmount);
        maxPostCount = Math.max(maxPostCount, postAmount);

        tempCalendarEvents.push({
          title: postAmount.toString(),
          start: date, // Use the date as the start date
          allDay: true, // Set to true for all-day events
          color: "transparent", // Customize the color of the events
        });
      }

      setWeekData(prevState => {
        return { ...prevState, yAxis: tempWeekYAxis };
      });

      setHourlyData(prevState => {
        return { ...prevState, yAxis: tempHourlyYAxis };
      });
      setDailyData(prevState => {
        return { ...prevState, xAxis: Object.keys(data.day).map(child => moment(child).format("DD MMM")), yAxis: Object.values(data.day) };
      });
      setWeeklyData(prevState => {
        return { ...prevState, xAxis: Object.keys(data.weekly).map(child => `Wk ${child.split("-")[1]}`), yAxis: Object.values(data.weekly) };
      });
      setMonthlyData(prevState => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        return {
          ...prevState,
          xAxis: months.slice(minMonthlyIndex, maxMonthlyIndex + 1),
          yAxis: tempMonthlyYAxis.slice(minMonthlyIndex, maxMonthlyIndex + 1),
        };
      });
    }
  }, [theme, data, initialWeekData, initialMonthlyData, initialHourlyData]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Global
        styles={{
          ".fc-scroller::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
          },
          ".fc-scroller::-webkit-scrollbar-thumb": {
            background: `${theme.palette.primary.main} !important`,
            borderRadius: "1rem",
          },
          ".fc-scroller::-webkit-scrollbar-thumb:hover": { background: `${theme.palette.primary.dark} !important` },
          ".fc-toolbar": {
            backgroundColor:
              theme.palette.mode === "light"
                ? (theme.palette.neutral as any)[200]
                : (theme.palette.neutral as any)[800] /* Change the background color */,
            color: "white" /* Change the text color */,
          },
          /* Target the buttons within the header toolbar */
          ".fc-button": {
            backgroundColor: `${theme.palette.primary.main} !important` /* Change the button background color */,
            color: "white" /* Change the button text color */,
          },
          ".fc-button-primary:disabled": {
            pointerEvents: "none",
          },
          ".fc-button:hover": {
            backgroundColor: `${lighten(theme.palette.primary.main, 0.2)} !important` /* Change the button background color */,
            color: "white" /* Change the button text color */,
          },

          ".fc .fc-daygrid-day.fc-day-today": {
            backgroundColor: theme.palette.mode === "light" ? (theme.palette.neutral as any)[200] : (theme.palette.neutral as any)[700],
          },
          ".fc-button-primary": {
            fontSize: "12px !important",
          },

          ".fc .fc-button .fc-icon": {
            fontSize: "12px !important",
          },

          ".fc .fc-toolbar.fc-header-toolbar": {
            padding: "10px !important",
          },
          /* Target the title within the header toolbar */
          ".fc-toolbar-title": {
            color: theme.palette.text.secondary,
            textTransform: "uppercase",
            fontSize: "14px !important" /* Change the title font size */,
          },
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper sx={{ pt: 2, height: `${chartHeight}px` }}>
            <Typography variant="body1" color="text.secondary">
              Amount of post/videos of the week
            </Typography>
            <Box sx={{ height: "90%" }}>
              <BarChart
                tooltip
                dataZoom
                barColors={["#9cc7f8", "#6fb0fa", "#6d9cf6", "#3080db", "#266dbe", "#397c9f", "#59b2e2"]}
                xAxisData={weekData.xAxis}
                yAxisData={weekData.yAxis}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ pt: 2, height: `${chartHeight}px` }}>
            <Typography variant="body1" color="text.secondary">
              Posting Activity (Monthly)
            </Typography>
            <Box sx={{ height: "90%" }}>
              <BarChart
                tooltip
                dataZoom
                barColors={[
                  "#abf3dc",
                  "#a1eec1",
                  "#85e3ac",
                  "#6ad6b4",
                  "#57c886",
                  "#57c85e",
                  "#63b949",
                  "#2fb541",
                  "#14a214",
                  "#0d6429",
                  "#257e42",
                  "#2f934f",
                ]}
                xAxisData={monthlyData.xAxis}
                yAxisData={monthlyData.yAxis}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ pt: 2, height: `${chartHeight}px` }}>
            <Typography variant="body1" color="text.secondary">
              Posting Activity (Weekly)
            </Typography>
            <Box sx={{ height: "90%" }}>
              <BarChart
                tooltip
                dataZoom
                barColors={["#f87474", "#fc9292", "#ffb5b5", "#ffcbcb", "#ffdaaa", "#ffc77d", "#feae44", "#ecaa55", "#ff955c"]}
                xAxisData={weeklyData.xAxis}
                yAxisData={weeklyData.yAxis}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ pt: 2, height: `${chartHeight}px` }}>
            <Typography variant="body1" color="text.secondary">
              Posting Activity (Daily)
            </Typography>
            <Box sx={{ height: "90%" }}>
              <BarChart
                tooltip
                dataZoom
                barColors={[
                  "#675ecd",
                  "#8982d9",
                  "#9f8fe5",
                  "#b1abeb",
                  "#cdc8ed",
                  "#dec4f9",
                  "#cdacef",
                  "#bc95e4",
                  "#9f75cc",
                  "#865ab5",
                  "#6d30af",
                  "#986cc7",
                  "#b692dd",
                  "#c7abe3",
                  "#9e8bda",
                  "#8973d2",
                ]}
                xAxisData={dailyData.xAxis}
                yAxisData={dailyData.yAxis}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ pt: 2, height: `${chartHeight}px` }}>
            <Typography variant="body1" color="text.secondary">
              Posting Activity (Hourly)
            </Typography>
            <Box sx={{ height: "90%" }}>
              <BarChart
                tooltip
                upperLimit={24}
                dataZoom
                barColors={[
                  "#945f30ea",
                  "#b87840ea",
                  "#c78f57f0",
                  "#dda771f0",
                  "#ebc196f0",
                  "#edd9adf0",
                  "#f5e79ff0",
                  "#e6cc86f0",
                  "#dab267f0",
                  "#bb9247f0",
                  "#936f2bf0",
                  "#735316f0",
                  "#563109",
                  "#844e35",
                ]}
                xAxisData={hourlyData.xAxis}
                yAxisData={hourlyData.yAxis}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
});

export default PostsCharts;
