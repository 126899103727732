import { faBuilding, faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Skeleton, Stack, Theme, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useHttp from "src/hooks/use-http";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { FeatureLayout } from "src/pages/my-account";
import routes from "src/routes";
import { RootState } from "src/store/root-reducer";
import { IStripeSubscription } from "src/types/common";
import useSWR from "swr";
// import { RootState } from "src/store/root-reducer";
/* components */
/* 3rd party lib */
/* Util */
interface PlanComponent2Props {
  theme: Theme;
  price: number;
  active: boolean;
  highlight?: boolean;
  tier: string;
  subtitle: string | null;
  features: { name: string }[];
  enterprise?: boolean;
  buttonText: string;
  currency: string;
  discount?: number;
  billing_interval: string;
}

type Props = PlanComponent2Props;

const PlanComponent2: React.FC<Props> = ({
  theme,
  price,
  tier,
  discount,
  subtitle,
  features,
  currency,
  active,
  buttonText,
  billing_interval,
  highlight = false,
  enterprise = false,
}) => {
  const { mode } = theme.palette;
  const navigate = useNavigate();
  const { apiEndpoint } = useHttp();
  const { smDown } = useMediaQueries();
  const { user } = useSelector((state: RootState) => state.auth);
  const { data: subscriptionData } = useSWR<IStripeSubscription>(user && apiEndpoint.STRIPE_SUBSCRIPTION);

  return (
    <Stack
      justifyContent={"space-between"}
      sx={{
        position: "relative",
        flexGrow: 1,
        pt: 2,
        pb: 3,
        px: 3,
        background: highlight || active ? (theme.palette.neutral as any)[mode === "dark" ? 800 : 100] : "none",
      }}
    >
      {active && (
        <Box sx={{ position: "absolute", right: "8px", top: "6px" }}>
          <FontAwesomeIcon icon={faCircleCheck} color={theme.palette.success.main} />
        </Box>
      )}
      <Stack spacing={1}>
        <Stack alignItems="flex-start" justifyContent="center">
          <Stack sx={{ mb: 3 }}>
            <Box sx={{ minHeight: "24px", mb: 2 }}>
              {highlight && (
                <Skeleton
                  animation="wave"
                  sx={{
                    px: 1,
                    py: 0.5,
                    fontSize: "12px",
                    borderRadius: 0,
                    background: theme.palette.mode === "light" ? "#ffe99f" : "#ffe46e",
                    transform: "scale(1)",
                    color: theme.palette.grey[theme.palette.mode === "light" ? 700 : 800],
                    "&::after": {
                      background: `linear-gradient(90deg, transparent,${
                        theme.palette.mode === "light" ? "rgba(255, 199, 79, 0.274)" : "rgba(207, 133, 48, 0.356)"
                      }, transparent)`,
                    },
                  }}
                >
                  Most Popular
                </Skeleton>
              )}
            </Box>
            <Typography textAlign="left" fontSize={20} fontWeight={700} color={mode === "dark" ? "text.secondary" : "grey.800"} sx={{ mb: "12px" }}>
              {tier}
            </Typography>
            <Typography sx={{ minHeight: "21px" }} textAlign="left" fontSize={14} fontWeight={400} color={mode === "dark" ? "grey.200" : "grey.400"}>
              {subtitle}
            </Typography>
          </Stack>

          {enterprise ? (
            <Box sx={{ py: 1 }}>
              <FontAwesomeIcon size="3x" icon={faBuilding} color={mode === "dark" ? "text.secondary" : "grey.600"} />
            </Box>
          ) : (
            <>
              <Stack direction={smDown ? "column" : "row"} alignItems={smDown ? "flex-start" : "center"} spacing={smDown ? 0 : 1}>
                <Stack direction="row">
                  <Typography
                    textTransform={"uppercase"}
                    variant={"h3"}
                    fontWeight={700}
                    color={mode === "dark" ? "white" : "grey.700"}
                    sx={{ mr: 1.5 }}
                  >
                    {currency}
                  </Typography>
                  <Typography
                    variant={"h3"}
                    fontWeight={discount ? 500 : 700}
                    color={mode === "dark" ? (discount ? "grey.500" : "text.secondary") : discount ? "grey.500" : "grey.700"}
                    sx={{ textDecoration: discount ? "line-through" : "none" }}
                  >
                    {price.toFixed(2)}
                  </Typography>
                  {discount && (
                    <Typography variant={"h3"} fontWeight={700} color={mode === "dark" ? "text.secondary" : "grey.700"} sx={{ ml: 1 }}>
                      {discount}
                    </Typography>
                  )}
                </Stack>
                <Stack direction={smDown ? "row" : "column"}>
                  <Typography textAlign={"left"} lineHeight={"14px"} variant="caption" fontWeight={500} color="grey.400">
                    per&nbsp;
                  </Typography>
                  <Typography textAlign={"left"} lineHeight={"14px"} variant="caption" fontWeight={500} color="grey.400">
                    {billing_interval}
                  </Typography>
                </Stack>
              </Stack>
            </>
          )}
        </Stack>

        <Stack alignItems="center" sx={{ pt: 1.5 }}>
          <Button
            disabled={buttonText === "Renew Plan" ? false : active}
            sx={{
              fontSize: "16px",
              width: "100%",
              background: buttonText === "Renew Plan" ? (theme.palette.mode === "light" ? "white" : "none") : "default",
            }}
            variant={buttonText === "Renew Plan" ? "outlined" : "contained"}
            onClick={() => {
              if (user && subscriptionData) {
                window.location.href = subscriptionData.customer_portal_url;
              } else {
                navigate(routes.LOGIN, { state: { clickedPricing: true } });
              }

              // if (!active) {
              //   // prettier-ignore
              //   window.location.href = `mailto:contact@gointec.io?Subject=${encodeURIComponent("Upgrading to " + tier)}&body=${encodeURIComponent(
              //     `Dear goIntec,\n\nI am writing to request an upgrade to the ${tier} subscription tier for my account with the username [${user ? user.user : "-"}] and user id ${user?.account_number}.\n\nCould you please provide me with information on how to proceed with upgrading my account? I am eager to access the enhanced functionalities available with the Lite subscription.\n\nThank you for your assistance.\n\nBest regards,\n${user ? user.name : "-"}`)}`;
              // } else {
              //   // prettier-ignore
              //   window.location.href = `mailto:contact@gointec.io?Subject=${encodeURIComponent("Renewing " + tier + " Plan")}&body=${encodeURIComponent(`Dear goIntec,\n\nI am writing to request to renew the ${tier} tier subscription plan for my account with the username [${user ? user.user : "-"}] and user id ${user?.account_number}.\n\nCould you please provide me with information on how to proceed with upgrading my account? \n\nBest regards,\n${user ? user.name : "-"}`)}`;
              // }
            }}
          >
            {buttonText}
          </Button>
        </Stack>

        {features.length > 0 && (
          <Stack alignItems={"flex-start"} spacing={1}>
            <Typography variant="subtitle2">This includes:</Typography>
            {features.map(child => (
              <FeatureLayout key={child.name} available={true} theme={theme} label={child.name} />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default PlanComponent2;
